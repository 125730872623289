import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: ComponentDataSourceFieldConfigurationInputType!){
    componentDataSourceFieldConfigurationMutation{
        updateComponentDataSourceFieldConfiguration(data:$data){
            componentDataSourceId,
            dataSourceFieldId,
            defaultFilterSearch,
            defaultFilterSearchOrder,
            edit,
            add,
            editMassive,
            import,
            fcr,
            filterResult,
            filterResultOrder,
            filterSearch,
            filterSearchOrder,
            fum,
            list,
            view,
            mandatory,
            sortOrder,
            idSortType,
            aggregateOperation,
            idFilterType,
            idFilterControlType,
            idDefaultMatchMode,
            defaultFilterValue,   
            cellStyle,         
            displayContentMaxlength,
            autoUpdate,
            defaultValue,
            defaultValidation
        }
    }
}
`);

export const ADD = gql(`
mutation($data: ComponentDataSourceFieldConfigurationInputType!){
    componentDataSourceFieldConfigurationMutation{
        addComponentDataSourceFieldConfiguration(data:$data)
    }
}
`);



