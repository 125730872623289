//const url = new URL(process.env.VUE_APP_URLBUILD ?? '')?.host.toLowerCase().includes('localhost') ? new URL(process.env.VUE_APP_URLBUILD ?? '').origin : (process.env.VUE_APP_URLBUILD ?? '')
const url =process.env.VUE_APP_URLBUILDSHARED ?? ''

export default class Environment{
    static APPID = parseInt(process.env.VUE_APP_APPLICATIONID ?? '-1')
    static APPVERSION = parseInt(process.env.VUE_APP_APPLICATIONVERSION ?? '-1')
    static APPAPPCOLOR1 = process.env.VUE_APP_COLOR1 ?? '#1E88E5'
    static APPAPPCOLOR2 = process.env.VUE_APP_COLOR2 ?? '#7F35B2' /*'#F2607B'*/
    static VUE_APP_URLAUTH= process.env.VUE_APP_URLAUTH
    static VUE_APP_URLBUILD= process.env.VUE_APP_URLBUILD
    
    static URL_LOGO_PNG =  `${url}/StaticFiles/app/${Environment.APPID}_${Environment.APPVERSION}/img/logo.png`
    static URL_LOGO_SVG =  `${url}/StaticFiles/app/${Environment.APPID}_${Environment.APPVERSION}/img/logo.svg`
    static URL_FAVICON =  `${url}/StaticFiles/app/${Environment.APPID}_${Environment.APPVERSION}/img/favicon.ico`
    static URL_ROOT =  `${Environment.VUE_APP_URLBUILD ?? ''}`
    static URL_COMPONENT =  `${url}/StaticFiles/app/${Environment.APPID}_${Environment.APPVERSION}/component/`
    static URL_COMPONENT_ATTR =  `${url}/StaticFiles/app/${Environment.APPID}_${Environment.APPVERSION}/component/attr/`
    static URL_CATALOG =  `${ url}/StaticFiles/app/${Environment.APPID}_${Environment.APPVERSION}/catalog/`

    static URL_IMG_ENTITY =  `${url}/StaticFiles/app/${Environment.APPID}_${Environment.APPVERSION}/img/entity/`
    


    static updateAppData = (appId:number,appVersion:number) =>{
        
        this.APPID = appId;
        this.APPVERSION = appVersion;

        this.URL_LOGO_PNG =  `${url}/StaticFiles/app/${Environment.APPID}_${Environment.APPVERSION}/img/logo.png`
        this.URL_LOGO_SVG =  `${url}/StaticFiles/app/${Environment.APPID}_${Environment.APPVERSION}/img/logo.svg`
        this.URL_FAVICON =  `${url}/StaticFiles/app/${Environment.APPID}_${Environment.APPVERSION}/img/favicon.ico`
        this.URL_COMPONENT =  `${url}/StaticFiles/app/${Environment.APPID}_${Environment.APPVERSION}/component/`
        this.URL_COMPONENT_ATTR =  `${url}/StaticFiles/app/${Environment.APPID}_${Environment.APPVERSION}/component/attr/`
        this.URL_CATALOG =  `${ url}/StaticFiles/app/${Environment.APPID}_${Environment.APPVERSION}/catalog/`
        this.URL_IMG_ENTITY =  `${url}/StaticFiles/app/${Environment.APPID}_${Environment.APPVERSION}/img/entity/`

    }

    static reloadInitialAppData = () =>{
       
        this.updateAppData(parseInt(process.env.VUE_APP_APPLICATIONID ?? '-1'),parseInt(process.env.VUE_APP_APPLICATIONVERSION ?? '-1'))

    }
}