import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';

import { IDataSourceServiceConfigurationRepository } from '../domain/IDataSourceServiceConfigurationRepository';

import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { IapDataSourceServiceConfiguration } from "../domain/iapDataSourceServiceConfiguration";
import { ADD, DELETE, UPDATE } from './mutation/dataSourceServiceConfigurationMutation';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';
import { GET_ALL_DATASOURCE_SERVICE_CONFIGURATION, GET_DATASOURCE_SERVICE_CONFIGURATION_BY_ID } from './query/dataSourceServiceConfigurationQuery';



@injectable()
export class DataSourceServiceConfigurationApolloClientRepository implements IDataSourceServiceConfigurationRepository {
  private apolloClient: MyApolloClient
  private clientId: String
  constructor(apolloInstance: MyApolloClient, clientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }

   async update(dataInput: IapDataSourceServiceConfiguration): (Promise<IapDataSourceServiceConfiguration>) {
     return new Promise<IapDataSourceServiceConfiguration>(async (resolve, reject) => {
       const result = this.apolloClient.executeQuery(UPDATE, { data: dataInput }, this.clientId)
 
       watch(result, (value: any) => {
 
         try {
           HelperUtils.handleDatesApollo(value)
           resolve(value.dataSourceServiceConfigurationMutation.updateDataSourceServiceConfiguration)
         }
         catch (e) {
           reject()
         }
       });
 
     });
   }
 
   async delete(DataSourceServiceConfigurationId: number): (Promise<boolean>) {
     return new Promise<boolean>(async (resolve, reject) => {
       const result = this.apolloClient.executeQuery(DELETE, { DataSourceServiceConfigurationId: DataSourceServiceConfigurationId }, this.clientId)
 
       watch(result, (value: any) => {
         try {
           HelperUtils.handleDatesApollo(value)
           resolve(value.dataSourceServiceConfigurationMutation.deleteDataSourceServiceConfiguration)
         }
         catch (e) {
           reject()
         }
       });
     });
   }
 
 
 
 
   async add(dataInput: IapDataSourceServiceConfiguration): (Promise<IapDataSourceServiceConfiguration>) {
     return new Promise<IapDataSourceServiceConfiguration>(async (resolve, reject) => {
       const result = this.apolloClient.executeQuery(ADD, { data: dataInput }, this.clientId)
 
       watch(result, (value: any) => {
 
         try {
           HelperUtils.handleDatesApollo(value)
           resolve(value.dataSourceServiceConfigurationMutation.addDataSourceServiceConfiguration)
         }
         catch (e) {
           reject()
         }
       });
 
     });
   }
 
   async getAll(applicationId: number, applicationVersion: number): (Promise<IapDataSourceServiceConfiguration[]>) {
     return new Promise<IapDataSourceServiceConfiguration[]>(async (resolve, reject) => {
       const result = this.apolloClient.executeQuery(GET_ALL_DATASOURCE_SERVICE_CONFIGURATION, { applicationId: applicationId, applicationVersion: applicationVersion }, this.clientId)
 
       watch(result, (value: any) => {
         try {
           HelperUtils.handleDatesApollo(value)
           resolve(value.dataSourceServiceConfigurationQuery.getAll)
         }
         catch (e) {
           reject()
         }
       });
     });
   }
 
   async getById(applicationId: number, applicationVersion: number, datasourceId: number): (Promise<IapDataSourceServiceConfiguration>) {
     return new Promise<IapDataSourceServiceConfiguration>(async (resolve, reject) => {
       const result = this.apolloClient.executeQuery(GET_DATASOURCE_SERVICE_CONFIGURATION_BY_ID, { applicationId: applicationId, applicationVersion: applicationVersion, datasourceId: datasourceId }, this.clientId)
 
       watch(result, (value: any) => {
         try {
           HelperUtils.handleDatesApollo(value)
           resolve(value.dataSourceServiceConfigurationQuery.getById)
         }
         catch (e) {
           reject()
         }
       });
     });
   }
 

}
