
import gql from 'graphql-tag';

export const UPDATE = gql(`
mutation($data: DataSourceFieldInputType!){
    dataSourceFieldMutation{
        update(data:$data){
            id,
            dataSourceId,
            tableName,
            field,
            tableNameAlias,
            fieldAlias,
            primaryKey,
            foreignKey,
            identityColumn,
            dataSourceTableAliasId,
            dataSourceLookUpId
            position,
            defaultValue,
            nullable,
            encrypted,
            sqlType,
            length,
            precision,
            idDataType,
            catalogTypeId,
            foreignTableName,
            foreignTableFieldId,
            foreignTableFieldDescription,
            shortDescription,
            description,
            localizable,
            computed
            ucr
            uum
            fum
            fcr
          
        }
    }
}
`);
