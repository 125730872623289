import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import { IDataSourceMigrationRepository } from '../domain/IDataSourceMigrationRepository';


import { IapDataSourceMigration } from '../domain/iapDataSourceMigration';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { UPDATE,ADD } from './mutation/dataSourceMigrationMutation';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class DataSourceMigrationApolloClientRepository implements IDataSourceMigrationRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }
  
  

  async update (dataInput: IapDataSourceMigration): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentDataSourceMigrationMutation)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async add (dataInput: IapDataSourceMigration): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentDataSourceMigrationMutation)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

 

}


