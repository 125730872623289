
import gql from 'graphql-tag';

export const ADD = gql(`
mutation(
    $data: DataSourceServiceConfigurationInputType!
 ){
    dataSourceServiceConfigurationMutation{
        addDataSourceServiceConfiguration(data:$data){
            id,
            dataSourceId,
            idServiceConfigurationType,
            key,            
            value,
            description,
            fcr,
            ucr
        }
    }
}
`);


export const UPDATE = gql(`
mutation($data: DataSourceServiceConfigurationInputType!){
    dataSourceServiceConfigurationMutation{
        updateDataSourceServiceConfiguration(data:$data){
            id,
            dataSourceId,
            idServiceConfigurationType,
            key,            
            value,
            description,
            fcr,
            ucr
        }
    }
}
`);


export const DELETE = gql(`
mutation($dataSourceServiceConfigurationId: Int!){
    dataSourceServiceConfigurationMutation{
        deleteDataSourceServiceConfiguration(dataSourceServiceConfigurationId:$dataSourceId)
    }
}
`);



