import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'
import { IServiceWorkFlowActivityVariable } from './interface/IServiceWorkFlowActivityVariable'
import type { IWorkFlowActivityVariableRepository } from '../domain/repositorry/IWorkFlowActivityVariableRepository'
import { IapWorkFlowActivityVariable } from '../domain/service/iapWorkFlowActivityVariable'





@injectable()
export class WorkFlowActivityVariableService implements IServiceWorkFlowActivityVariable {
  private readonly repo: IWorkFlowActivityVariableRepository

  public constructor (@inject(TYPES.WORKFLOWACTIVITY_REPOSITORY) _repo: IWorkFlowActivityVariableRepository) {
    this.repo = _repo
  }

  async update (data: IapWorkFlowActivityVariable[]): (Promise<IapWorkFlowActivityVariable[]>) {
    return await this.repo.update(data);
  }

  async add (data: IapWorkFlowActivityVariable): (Promise<IapWorkFlowActivityVariable>) {
    return await this.repo.add(data);
  }

  async getAll(maxregs: number | undefined) :Promise<IapWorkFlowActivityVariable[]> {
    return await this.repo.getAll(maxregs)
  }

  async getById(id: number) :Promise<IapWorkFlowActivityVariable> {
    return await this.repo.getById(id)
  }

  
}
