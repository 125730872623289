import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0a2fe363"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-wrap align-items-center gap-2" }
const _hoisted_2 = { class: "flex align-items-center" }
const _hoisted_3 = { class: "ml-2" }
const _hoisted_4 = { class: "p-fluid formgrid grid" }
const _hoisted_5 = { class: "flex align-items-center mb-1" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["for"]
const _hoisted_8 = {
  key: 0,
  class: "p-inputgroup flex-2"
}
const _hoisted_9 = {
  key: 1,
  class: "flex",
  style: {"width":"100%"}
}
const _hoisted_10 = { class: "flex justify-content-start" }
const _hoisted_11 = { class: "p-input-icon-left" }
const _hoisted_12 = { class: "p-input-icon-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_LookUpEditor = _resolveComponent("LookUpEditor")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_Panel, {
        class: "field col-12 md:col-12",
        toggleable: true,
        collapsed: false,
        style: { padding: '0.25rem 0.5rem', margin: '0' }
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_Button, {
              id: "buscar",
              label: "Buscar",
              icon: "pi pi-search",
              class: "mr-1 p-button-primary",
              style: { padding: '0.25rem 0.5rem', margin: '0' },
              onClick: _ctx.executeSearch
            }, null, 8 /* PROPS */, ["onClick"]),
            _createVNode(_component_Button, {
              id: "limpiar",
              label: "Limpiar filtros",
              icon: "pi pi-trash",
              class: "mr-2 p-button-outlined p-button-secondary",
              style: { padding: '0.25rem 0.25rem', margin: '0' },
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearFilterData()))
            }),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.selectedSearch,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedSearch) = $event)),
              options: _ctx.fieldsData,
              filter: "",
              optionLabel: "label",
              optionGroupLabel: "label",
              optionValue: "value",
              onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectField($event))),
              optionGroupChildren: "items",
              class: "align-items-center justify-content-center w-full md:w-11rem mr-1",
              placeholder: 'Seleccione filtro'
            }, {
              optiongroup: _withCtx((slotProps) => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("i", {
                    class: _normalizeClass(slotProps.option.icon)
                  }, null, 2 /* CLASS */),
                  _createElementVNode("div", _hoisted_3, _toDisplayString(slotProps.option.label), 1 /* TEXT */)
                ])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataSearchFilter, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: (_ctx.dataBaseId + '.' + _ctx.getFieldData(item.fieldId)?.tableName + '.' + _ctx.getFieldData(item.fieldId)?.field),
                  class: _normalizeClass({ 'field': true, 'col-12': _ctx.colSize == 12, 'col-6': _ctx.colSize == 6, 'col-4': _ctx.colSize == 4, 'col-3': _ctx.colSize == 3 })
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("i", {
                      class: "pi pi-trash flex align-items-center text-gray-500 ml-1 mr-1",
                      style: {'font-size': '0.85rem'},
                      onClick: ($event: any) => (_ctx.removeField(_ctx.dataSearchFilter[index].fieldId??''))
                    }, null, 8 /* PROPS */, _hoisted_6),
                    _createElementVNode("label", {
                      for: _ctx.getFieldData(item.fieldId)?.id
                    }, _toDisplayString(_ctx.getLabel(_ctx.getFieldData(item.fieldId))), 9 /* TEXT, PROPS */, _hoisted_7),
                    (_ctx.isFilterVisible(_ctx.getFieldData(item.fieldId)))
                      ? (_openBlock(), _createBlock(_component_Dropdown, {
                          key: 0,
                          modelValue: item.filter,
                          "onUpdate:modelValue": ($event: any) => ((item.filter) = $event),
                          onChange: ($event: any) => (_ctx.onChangeDropDown($event,item)),
                          options: _ctx.getCatalogFilterByFieldType(_ctx.getFieldData(item.fieldId)),
                          optionLabel: "description",
                          optionValue: "id",
                          class: "ml-1 flex align-items-center justify-content-center wrap-input flex-grow-1"
                        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "onChange", "options"]))
                      : _createCommentVNode("v-if", true)
                  ]),
                  (!_ctx.notShowControl.includes(item.filter))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        (_ctx.getFieldData(item.fieldId)?.dataSourceLookUpId !==null)
                          ? (_openBlock(), _createBlock(_component_LookUpEditor, {
                              key: 0,
                              modelValue: item.value,
                              "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                              dataSourceLookUp: _ctx.getFieldData(item.fieldId)?.dataSourceLookUp,
                              catalogsData: _ctx.catalogsData,
                              container: _ctx.container,
                              filterConditions: [],
                              showSearch: true
                            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "dataSourceLookUp", "catalogsData", "container"]))
                          : (_ctx.isType(_ctx.getFieldData(item.fieldId), _ctx.SqlTypesConst.DATETIME) || _ctx.isType(_ctx.getFieldData(item.fieldId), _ctx.SqlTypesConst.DATE))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                (item.filter !== _ctx.FiltroBusquedaConst.FILTROBUSQUEDA_NONULO && item.filter !== _ctx.FiltroBusquedaConst.FILTROBUSQUEDA_NULO
                                && item.filter!=_ctx.FiltroBusquedaConst.FILTROBUSQUEDA_DAYSAGO)
                                  ? (_openBlock(), _createBlock(_component_Calendar, {
                                      key: 0,
                                      modelValue: item.rangeDateTime.from,
                                      "onUpdate:modelValue": ($event: any) => ((item.rangeDateTime.from) = $event),
                                      showButtonBar: true,
                                      class: "mr-1",
                                      manualInput: true,
                                      ref_for: true,
                                      ref: _ctx.calendarsFromRef[index]
                                    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
                                  : _createCommentVNode("v-if", true),
                                (_ctx.dateFilter.includes(item.filter) && item.filter!=_ctx.FiltroBusquedaConst.FILTROBUSQUEDA_DAYSAGO)
                                  ? (_openBlock(), _createBlock(_component_Calendar, {
                                      key: 1,
                                      modelValue: item.rangeDateTime.to,
                                      "onUpdate:modelValue": ($event: any) => ((item.rangeDateTime.to) = $event),
                                      showButtonBar: true,
                                      manualInput: true,
                                      ref_for: true,
                                      ref: _ctx.calendarsToRef[index],
                                      onInput: ($event: any) => (_ctx.setDateFilterValues({value:item.filter},index)),
                                      onDateSelect: ($event: any) => (_ctx.setDateFilterValues({value:item.filter},index))
                                    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "onInput", "onDateSelect"]))
                                  : _createCommentVNode("v-if", true),
                                (item.filter==_ctx.FiltroBusquedaConst.FILTROBUSQUEDA_DAYSAGO)
                                  ? (_openBlock(), _createBlock(_component_InputNumber, {
                                      key: 2,
                                      id: "inputcalen",
                                      mode: "decimal",
                                      modelValue: item.valueNumber,
                                      "onUpdate:modelValue": [($event: any) => ((item.valueNumber) = $event), ($event: any) => (_ctx.setDateFilterValues({value:item.filter},index))],
                                      showButtons: "",
                                      format: false,
                                      inputStyle: 'width: 30px',
                                      placeholder: "Días...",
                                      onInput: ($event: any) => (_ctx.setDateFilterValues({value:item.filter},index))
                                    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "onInput"]))
                                  : _createCommentVNode("v-if", true)
                              ]))
                            : (_ctx.isType(_ctx.getFieldData(item.fieldId), _ctx.SqlTypesConst.VARCHAR) && _ctx.isCatalogType(_ctx.getFieldData(item.fieldId)))
                              ? (_openBlock(), _createBlock(_component_MultiSelect, {
                                  key: 2,
                                  modelValue: item.valueList,
                                  "onUpdate:modelValue": ($event: any) => ((item.valueList) = $event),
                                  options: _ctx.getCatalogData(_ctx.getFieldData(item.fieldId)?.catalogTypeId??''),
                                  filter: true,
                                  optionLabel: "description",
                                  optionValue: "id",
                                  onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.stopPropagateEventDropDown($event)))
                                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options"]))
                              : (_ctx.isType(_ctx.getFieldData(item.fieldId), _ctx.SqlTypesConst.BIT))
                                ? (_openBlock(), _createBlock(_component_InputSwitch, {
                                    key: 3,
                                    modelValue: item.value,
                                    "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                    class: "mr-2",
                                    trueValue: '1',
                                    falseValue: '0'
                                  }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
                                : (_openBlock(), _createBlock(_component_InputText, {
                                    key: 4,
                                    modelValue: item.value,
                                    "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                    maxlength: _ctx.getFieldData(item.fieldId)?.length ?? 25,
                                    onKeyup: _withKeys(_ctx.executeSearch, ["enter"])
                                  }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "maxlength", "onKeyup"]))
                      ]))
                    : _createCommentVNode("v-if", true)
                ], 2 /* CLASS */))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ]),
          (_ctx.dataSearchFilter.length == 0)
            ? (_openBlock(), _createBlock(_component_Message, {
                key: 0,
                closable: false
              }, {
                default: _withCtx(() => [
                  _createTextVNode("No hay filtros seleccionados")
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    (_ctx.dataSearchFilter.length > 0)
      ? (_openBlock(), _createBlock(_component_DataTable, {
          key: 0,
          value: _ctx.rows,
          paginator: "",
          rows: 20,
          rowsPerPageOptions: [5, 10, 20],
          selectionMode: "single",
          onRowSelect: _ctx.onRowSelect,
          filters: _ctx.filters,
          reorderableColumns: "",
          style: { padding: '1rem 1rem', margin: '0' }
        }, {
          empty: _withCtx(() => [
            _createTextVNode(" No se han encontrado datos para los filtros indicados ")
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columnsFilteredList, (col, index) => {
              return (_openBlock(), _createBlock(_component_Column, {
                field: col.field,
                header: col.header,
                key: col.field + '_' + index
              }, {
                body: _withCtx(({ data, field }) => [
                  _createTextVNode(_toDisplayString((_ctx.columnsToFormat.includes(col.field) ? data[field + _ctx.DataSourceConst.DELIMITERFIELDFORMAT]  :data[field])), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["field", "header"]))
            }), 128 /* KEYED_FRAGMENT */)),
            _createVNode(_component_Column, { headerStyle: "width:2rem" }, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("span", _hoisted_11, [
                    _createVNode(_component_InputText, {
                      modelValue: _ctx.filters['global'].value,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filters['global'].value) = $event)),
                      placeholder: 'Buscar...'
                    }, null, 8 /* PROPS */, ["modelValue"]),
                    _createElementVNode("span", _hoisted_12, [
                      _withDirectives(_createElementVNode("i", {
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.filters['global'].value = '')),
                        style: {"font-size":"0.75rem"},
                        class: "pi pi pi-times text-gray-500"
                      }, null, 512 /* NEED_PATCH */), [
                        [
                          _directive_tooltip,
                          'Borrar búsqueda',
                          void 0,
                          { top: true }
                        ]
                      ])
                    ])
                  ])
                ])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["value", "onRowSelect", "filters"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}