import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IDataSourceTableAliasRepository } from '../domain/IDataSourceTableAliasRepository';

import { IServiceDataSourceTableAlias } from './IServiceDataSourceTableAlias';
import { IapDataSourceTableAlias } from '../domain/iapDataSourceTableAlias';





@injectable()
export class DataSourceTableAliasService implements IServiceDataSourceTableAlias {
  private readonly repo: IDataSourceTableAliasRepository

  public constructor (@inject(TYPES.DATASOURCE_ALIAS_REPOSITORY) _repo: IDataSourceTableAliasRepository) {
    this.repo = _repo
  }

  async update (data: IapDataSourceTableAlias): (Promise<IapDataSourceTableAlias>) {
    return await this.repo.update(data);
  }

  async getAll(maxregs: number | undefined) :Promise<IapDataSourceTableAlias[]> {
    return await this.repo.getAll(maxregs)
  }

  async getById(id: number, version: number) :Promise<IapDataSourceTableAlias> {
    return await this.repo.getById(id, version)
  }
}
