import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IComponentDataSourceFieldConfigurationRepository } from '../domain/IComponentDataSourceFieldConfigurationRepository';
import { IServiceComponentDataSourceFieldConfiguration } from './IServiceComponentDataSourceFieldConfiguration';
import { IapComponentDataSourceFieldConfiguration } from '../domain/iapComponentDataSourceFieldConfiguration';



@injectable()
export class ComponentDataSourceFieldConfigurationService implements IServiceComponentDataSourceFieldConfiguration {
  private readonly repo: IComponentDataSourceFieldConfigurationRepository

  public constructor (@inject(TYPES.COMPONENT_DATASOURCE_FIELD_CONF_REPOSITORY) _repo: IComponentDataSourceFieldConfigurationRepository) {
    this.repo = _repo
  }

  async update (data: IapComponentDataSourceFieldConfiguration): (Promise<IapComponentDataSourceFieldConfiguration>) {
    return await this.repo.update(data);
  }

  async add (data: IapComponentDataSourceFieldConfiguration): (Promise<boolean>) {
    return await this.repo.add(data);
  }

}
