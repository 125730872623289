import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';

import { IDocumentRepository } from '../domain/IDocumentRepository';

//import { UPDATE } from './mutation/applicationMutation';
import { GET_BY_ID, GET_BY_OBJECT_ID, GET_CONTENT_BY_ID, GET_BY_ID_WITH_CONTENT } from './query/documentQuery';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { ADD, DELETE, UPDATE } from './mutation/documentMutation';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';
import { StorageDocument } from '../domain/storageDocument';




@injectable()
export class DocumentApolloClientRepository implements IDocumentRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId=ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }
  async delete (id: number,componentId:number,source:string): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(DELETE, { id: id, componentId:componentId, source:source }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.documentMutation.deleteDocument)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


  async add (data: StorageDocument, contentFile: string,componentId:number,source:string): (Promise<StorageDocument>) {
    return new Promise<StorageDocument>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(ADD, { data: data, content:contentFile, componentId:componentId, source:source }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.documentMutation.addDocument)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  
  async update (data: StorageDocument, contentFile: string,componentId:number,source:string): (Promise<StorageDocument>) {
    return new Promise<StorageDocument>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(UPDATE, { data: data, content:contentFile, componentId:componentId, source:source }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.documentMutation.updateDocument)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


  async getById (id: number,componentId:number,source:string, withContent:boolean): (Promise<StorageDocument>) {
    return new Promise<StorageDocument>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery((withContent ? GET_BY_ID_WITH_CONTENT: GET_BY_ID) , { id: id, componentId:componentId, source:source }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(withContent ? value.documentQuery.getByIdWithContent : value.documentQuery.getById)
        }
        catch(e) {
          reject()
        }
      });
    });
  }
    

  async getContent (id: number,componentId:number,source:string): (Promise<string>) {
    return new Promise<string>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(GET_CONTENT_BY_ID, { id: id, componentId:componentId, source:source }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.documentQuery.getContentById)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getByObjectId (idObject: string, objectId: number,componentId:number,source:string): (Promise<StorageDocument[]>) {
    return new Promise<StorageDocument[]>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(GET_BY_OBJECT_ID, { idObjeto:idObject,objetoId: objectId, componentId:componentId, source:source }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.documentQuery.getByObjectId)
        }
        catch(e) {
          reject()
        }
      });
    });
  }



}


