import { LocalService } from "../../../../../common/infrastructure/servicios";
import { IapCatalogType } from "../../domain/iapCatalogType";
import { IapCatalog } from '../../domain/iapCatalog';
import { getLocalValue } from '../../../../../common/infrastructure/servicios/LocalService';
import { Container } from "inversify";
import { IServiceApplication } from "../../../application/application/IServiceApplication";
import { TYPES } from "../../../../../common/domain/types";

/*

Para saber el tamaño del localstorage 
  Number(new Blob(Object.values(window.localStorage)).size / 1024
).toFixed(2) + ' KB'

*/


const getData = (): IapCatalogType[] => {
  const localData = getLocalValue(LocalService.APP_DATA) ?? '[]';
  if (localData == undefined || localData == "") {
    return [] as IapCatalogType[]
  }
  const data = JSON.parse(localData) as IapCatalogType[]
  return data;
}

export const getAllCatalogApp = (): IapCatalogType[] => {
  return getData();

}

export const getCatalogType = (catalogTypeId: string): IapCatalogType => {
  const data = getData();
  return data?.find((x: IapCatalogType) => x.id == catalogTypeId) as IapCatalogType

}


export const getCatalogTypes = (catalogTypeId: string[]): IapCatalogType[] => {
  const data = getData();
  return data?.filter((x: IapCatalogType) => catalogTypeId.includes(x.id)) as IapCatalogType[]

}


export const getCatalog = (catalogTypeId: string): IapCatalog[] => {
  const data = getData();
  return data?.find((x: IapCatalogType) => x.id == catalogTypeId)?.iapCatalogs ?? []

}


export const setCatalogApp = (data: IapCatalogType[]) => {
  LocalService.setLocalValue(LocalService.APP_DATA, JSON.stringify(data))
}


export const clearCatalogApp = () => {
  LocalService.destroyLocal(LocalService.APP_DATA)
}



export const updateCatalog = (dataInput: IapCatalogType) => {
  const data = getData();

  let reg = data?.find((x: any) => x.key == dataInput.id) as IapCatalogType;
  if (reg) {

    reg = dataInput;
    LocalService.setValue(LocalService.APP_DATA, JSON.stringify(data))
  }


}

export const getCatalogsFromService = (container: Container | undefined, applicationId: number, applicationVersion: number): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {

    if (container) {
      const _srv = container.get<IServiceApplication>(TYPES.APPLICATION_REPOSITORY)
      _srv.getApplicationCatalogs(applicationId, applicationVersion).then(response => {
        setCatalogApp(response)
        response = {} as any
        resolve(true)
      })
        .catch(() => {
          resolve(false);
        })
        .finally(() => {
        })
    }
    else {
      resolve(false);
    }
  })

}



export default
  {
    getCatalogType,
    getCatalog,
    setCatalogApp,
    clearCatalogApp,
    getAllCatalogApp,
    updateCatalog,
    getCatalogsFromService,
    getCatalogTypes

  }



