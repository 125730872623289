import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';

import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import { UPDATE, ADD, DELETE } from './mutation/expressionMutation';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { IapExpression } from '../domain/iapExpression';
import { IExpressionRepository } from '../domain/IExpressionRepository';
import { GET_BY_ID, GET_BY_OBJECTID,GET_BY_OBJECTTYPE } from './query/expressionQuery';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class ExpressionApolloClientRepository implements IExpressionRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }
  
  

  async update (dataInput: IapExpression): (Promise<IapExpression>) {
    return new Promise<IapExpression>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.expressionMutation.updateExpression)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async add (dataInput: IapExpression): (Promise<IapExpression>) {
    return new Promise<IapExpression>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.expressionMutation.addExpression)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async delete (id: string): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(DELETE, { id: id }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.expressionMutation.deleteExpression)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getByObjectId (idObject: string, objectId:string): (Promise<IapExpression[]>) {
    return new Promise<IapExpression[]>(async (resolve, reject) => {      
      const result = this.apolloClient.executeQuery(GET_BY_OBJECTID, { idObjeto: idObject,objetoId:objectId },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.expressionQuery.getByObjectId)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


  
  async getByObjectType (idObject: string[],idObjetoRoot: string,objetoIdRoot: string): (Promise<IapExpression[]>) {
    return new Promise<IapExpression[]>(async (resolve, reject) => {      
      const result = this.apolloClient.executeQuery(GET_BY_OBJECTTYPE, { idObjeto: idObject, idObjetoRoot:idObjetoRoot, objetoIdRoot:objetoIdRoot },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.expressionQuery.getByObjectType)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


  async getById (id: string): (Promise<IapExpression>) {
    return new Promise<IapExpression>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_BY_ID, { id: id },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.expressionQuery.getById)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


}


