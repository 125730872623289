import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0f69d46e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "c",
  id: "c",
  style: {"position":"absolute"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Login = _resolveComponent("Login", true)!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("canvas", _hoisted_1, null, 512 /* NEED_PATCH */),
    _createVNode(_component_Login, {
      container: _ctx.container,
      routes: _ctx.router,
      isResetPass: _ctx.isResetPass,
      "onUpdate:isResetPass": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isResetPass) = $event)),
      onIsForgetPassChild: _ctx.updateIsResetPass
    }, null, 8 /* PROPS */, ["container", "routes", "isResetPass", "onIsForgetPassChild"])
  ], 64 /* STABLE_FRAGMENT */))
}