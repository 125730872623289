import { ClassicPreset } from "rete";
import { Container } from 'inversify';
import { IapComponentDataSource } from "../../../../../component/domain/iapComponentDataSource";
import { IapComponent } from "../../../../../component/domain/iapComponent";

export class DataSourceControl extends ClassicPreset.Control {
  constructor(public formKeyInput: String
                    ,public rootComponentId:number
                    ,public currentComponentId:number
                    ,public applicationId:number
                    , public applicationVersion:number
                    ,public variableInceptionInput:number
                    ,public variableOperationInput:number
                    ,public variableComponentIdInput:number
                    ,public variableComponentDataSourceIdInput:number            
                    ,public variableComponentDataSourceListIdInput:number[]
                    ,public variableConnectionInput: string
                    ,public variableDsIdInput: string
                    ,public variableFieldsInput:[]
                    ,public variableCfgsInput:[]
                    ,public dataSources: IapComponentDataSource[]
                    ,public componentDataInput: IapComponent[]
                    ,public container: Container | undefined
                    , public onChange: (evt: any) => void
                    ,public getNode: (evt: any) => void
                    , public showExpression:(evt:any) => void) {
          super();
  
    }
   
  }