import HtmlTypeConst from "./HtmlTypeConst"

export default class InputTypeConst extends HtmlTypeConst {
    
    static TYPE= 'type'
    static VALUE= 'value'
    static READONLY= 'readonly'
    static REQUIRED= 'required'
    static MAXLENGTH= 'maxlength'
    static SIZE= 'size'
    static MIN= 'min'
    static MAX= 'max'
    static AUTOCOMPLETE= 'autocomplete'
    static PATTERN= 'pattern'
    
  }
  