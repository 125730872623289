import { ClassicPreset } from 'rete';
import { AreaPlugin } from 'rete-area-plugin';
import { AreaExtra, Schemes } from '../rete/helperRete';

import { LocalService } from '../../../../../../../common/infrastructure/servicios';
import { Router } from 'vue-router';
import { Container } from 'inversify';
import { UbmControl } from '../controls/ubmControl';
import {  DataflowEngine } from 'rete-engine';
import HelperLoading from '../../../../../../../common/infrastructure/funciones/HelperLoading';
import { IapComponent } from '../../../../../component/domain/iapComponent';
import CatalogObjectTypeConst from '../../../../../catalog/domain/const/CatalogObjectTypeConst';
import { IapWorkFlowActivityControl } from '../../../../domain/service/iapWorkFlowActivityControl';
import CatalogExpConst from '../../../../../catalog/domain/const/CatalogExpConst';
import { ExpresionEngine } from '../../../../../expression/infrastructure/helper/expressionEngine';
import { IapWorkFlowActivity } from '../../../../domain/service/iapWorkFlowActivity';
import EventConst from '../../../../../../../common/domain/constantes/EventConst';
import EventBusCustom from '../../../../../../../common/infrastructure/event-bus-custom';
import { ContactChat } from '../../../../../chat/domain/contactChat';
import CatalogCfgUbmChatConst from '../../../../../catalog/domain/const/CatalogCfgUbmChatConst';
import UbmNodeConst from '../constants/UbmNodeConst';
import OperationDataTypeConst from '../../../../../../../common/domain/constantes/OperationDataTypeConst';
import HelperUtils from '../../../../../../../common/infrastructure/funciones/HelperUtils';

export class UbmNode extends ClassicPreset.Node<
  { ejecutar: ClassicPreset.Socket, message: ClassicPreset.Socket },
  { ejecutar: ClassicPreset.Socket },
  { value: UbmControl }
> {
  height = 310;
  width = 380;

  //private value: Number;
  private msgOutput: string='';
    private variablePhone: string;
  
  private area: AreaPlugin<Schemes, AreaExtra>;
  private updateNode: any;
  private rdControlId: '';

  private dataflow: DataflowEngine<Schemes>
  private getNodeInternalData: any;
  private showExpression: any;
  private router: Router;
  private container: Container | undefined;
  private rootComponentId: number;
  private currentComponentId: number;
  private store: any;
  private activity: IapWorkFlowActivity | undefined;
  private componentData: IapComponent[];
  private formKey:String;
  private currentElementKey:string;
  
  

  //constructor(area: AreaPlugin<Schemes, AreaExtra>, socket: ClassicPreset.Socket,  public applicationId: number, public applicationVersion: number, public variableComponentInput: number, public variableComponentHeaderInput: string, public variableComponentModalInput: boolean, public variableComponentParametersInput: [], updateNode: any = undefined) {
  constructor(area: AreaPlugin<Schemes, AreaExtra>, socket: ClassicPreset.Socket,public formKeyInput: String,public currentElementKeyInput:string,public rootComponentInputId: number, public currentComponentInputId: number, public applicationId: number, public applicationVersion: number, public variablePhoneInput: string, updateNode: any = undefined, getNodeInternalData: any = undefined, showExpressionFunction: any = undefined, router: Router, container: Container | undefined, storeInput: any, itemActivity: IapWorkFlowActivity | undefined, dataflow: DataflowEngine<Schemes>,componentDataInput: IapComponent[]) {
    super("Ubm Chat");

    this.area = area;
    this.updateNode = updateNode;
    this.variablePhone = variablePhoneInput;    
    this.router = router;
    this.dataflow = dataflow;
    

    this.getNodeInternalData = getNodeInternalData;
    this.showExpression = showExpressionFunction;
    this.container = container;
    this.rootComponentId = rootComponentInputId;
    this.currentComponentId = currentComponentInputId;
    this.store = storeInput;
    this.activity = itemActivity;    
    this.componentData = componentDataInput;
    this.formKey = formKeyInput;
    this.currentElementKey = currentElementKeyInput;

    const dsControl = new UbmControl(formKeyInput,rootComponentInputId,currentComponentInputId,applicationId, applicationVersion, variablePhoneInput,container,storeInput,itemActivity, this.updateData, this.getNode, this.showExp);

    this.rdControlId = (dsControl as any).id;

    this.addInput("ejecutar", new ClassicPreset.Input(socket, "Ejecutar", true));
    this.addControl(
      "value",
      dsControl

    
    );
    this.addInput("message", new ClassicPreset.Input(socket, "PhoneInput"));

   
    this.addOutput("ejecutar", new ClassicPreset.Output(socket, "Ejecutar"));




  }

  showExp = (evt: any) => {
    if (this.showExpression) {
      return this.showExpression(evt)
    }
    return null;
  }

  getNode = (key: string) => {
    if (this.getNodeInternalData) {
      return this.getNodeInternalData(this.id, key, true, false)
    }
    return null;
  }

  updateData = (evt: any) => {

    //this.value = evt
    //@ts-ignore:disable-next-line
    this.controls.value[evt.key] = evt.data;
    //this.controls[evt.key].valueConnection = evt.data;

    this.area.update("control", this.rdControlId)


    if (this.updateNode) {
      this.updateNode(this.id, evt.key, evt.data, (evt?.operation ?? OperationDataTypeConst.UPDATE))


  }
}

resolveExpressions = () =>{
    
  const currentComp = this.componentData.find(x => x.id == this.currentComponentId)
  const WfData = currentComp?.workFlows?.find(w => w.id == this.activity?.workFlowId)

  this.activity?.iapWorkFlowActivityControls?.forEach((wcf: IapWorkFlowActivityControl) => {

    if (currentComp && wcf) {

      
      let keyControl = CatalogObjectTypeConst.ATTRCOMP + '#' + WfData?.objetoId + '#' + wcf.id.toString();

      let exps = currentComp.expressions?.filter(x =>
        x.idObjeto == CatalogObjectTypeConst.WFAC
        && x.idTypeExpression == CatalogExpConst.EXP_SET
        && x.iapExpressionDetails?.length > 0
        && x.objetoId == keyControl);

        if ((exps?.length ?? 0) == 0){
          keyControl = CatalogObjectTypeConst.EVTCOMP + '#' + WfData?.objetoId + '#' + wcf.id.toString();

          exps = currentComp.expressions?.filter(x =>
            x.idObjeto == CatalogObjectTypeConst.WFAC
            && x.idTypeExpression == CatalogExpConst.EXP_SET
            && x.iapExpressionDetails?.length > 0
            && x.objetoId == keyControl);
        }
      if (exps?.length > 0) {
        exps?.every(exp => {
          if (exp.iapExpressionDetails?.length > 0) {            
            const localData = LocalService.getValue(this.formKey + LocalService.COMPONENTS_EXP + (this.rootComponentId ?? -1).toString());
            const data = HelperUtils.jsonParse(localData,[])
            let resu = ExpresionEngine.resolveExpressions(exp.iapExpressionDetails, data as any, this.store)
            //resu = resu?.toString();
            if (resu) {
              if (Object.keys(resu).length == 0) {
                resu = resu?.toString();
              }
            }

            switch (wcf.name) {
              case UbmNodeConst.VAR_PHONE_IN:
                // code block
                this.msgOutput = resu
                break;
              
              default:
              // code block
            }

          }
        })

      }

      
    }
    

  })

  //const wcf =  this.activity?.iapWorkFlowActivityControls.find(x=> x.name == DisplayComponentNodeConst.VAR_PARAM_IN);
  
}


  async execute(_: never, forward: (output: "ejecutar" ) => void) {

    const inputs = (await this.dataflow.fetchInputs(this.id)) as {
      message: string[];
    };

     this.msgOutput = this.variablePhone ?? '';

    const msg = inputs?.message?.find(x => x !== undefined);
    if (msg && Array.isArray(msg) && msg?.length > 0) {
      this.msgOutput = '';
      var keys = Object.keys(msg[0])
      keys.forEach(key => {
        this.msgOutput += msg[0][key]
      });
    }
    else if (msg) {
      if (typeof(msg) === 'object'){
        this.msgOutput = JSON.stringify(msg ?? '{}') as any
      }
      else{
        this.msgOutput = msg as any
      }
      
    }
    // resolvemos las expresiones
    // vamos a buscar las expresiones si las hubiera
    
  
       this.resolveExpressions();
  
    // fin resolver las expresiones


    if (this.msgOutput &&   this.store.getters.entityMainCfgValue(CatalogCfgUbmChatConst.CFGUBMCHAT_ENABLE)?.toLowerCase().trim() == 'true') {
     
        const data :ContactChat ={
            phone : this.msgOutput,
            client_name :'',
            client_lastname: '',
            client_email: ''
          }
          HelperLoading.showLoading()
          EventBusCustom.emit(EventConst.SHOWCHATUBM,data);

        // llamada a ubmchat
        forward("ejecutar");
    }
    else {
      
      forward("ejecutar");
    }



  }


  data() {
    return {};
  }
}