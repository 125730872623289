<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <Editor v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(EditorTypeConst.ID)" v-tooltip="getPropertyValue(EditorTypeConst.TOOLTIP)"
        :style="getPropertyValue(EditorTypeConst.STYLE)"
        :class="{ [getPropertyValue(EditorTypeConst.CLASS) ?? '']: true, 'p-invalid': v$.vmodel.$error && submitted,'p-readonly': getPropertyBooleanValue(EditorTypeConst.READONLY) }"
        :name="getPropertyValue(EditorTypeConst.NAME)"
        :disabled="getPropertyBooleanValue(EditorTypeConst.DISABLED) || (!canEdit)"
        :visible="getPropertyBooleanValue(EditorTypeConst.VISIBLE)"
        :placeholder="getPropertyValue(EditorTypeConst.PLACEHOLDER)"
        :readonly="getPropertyBooleanValue(EditorTypeConst.READONLY) || !canDoOperation(ObjectGroupConst.EDIT)"
        :formats="getPropertyValue(EditorTypeConst.FORMATS)"
        :editorStyle="getPropertyValue(EditorTypeConst.EDITORSTYLE)"
        v-model="vmodel" >
    </Editor>
    <CustomValidate v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE)" v-model:submitted="submitted" v-model:vObject="v$" />
</template>
<script lang="ts">


import { defineComponent, onMounted, ref, onBeforeUnmount, onUnmounted } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import EditorTypeConst from '../../../../domain/Constants/EditorTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import CustomValidate from '../../shared/CustomValidate.vue';
import Editor from 'primevue/editor';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';	

export default defineComponent({
    name: 'dynamic_editor',
    components: {
        CustomValidate,
        Editor,
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
      
        slotProps: {
            type: Object,
            default: () => ({})
        },
    
      
    
    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded,  resolveExpressions, getCatalogValue,
             baseOnUnmount, baseOnMounted, baseOnBeforeUnmount, v$, submitted, canEdit,isValidData 
             ,fieldKey,fieldKeyComponentDataSourceId,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store)


        onMounted(() => {

            baseOnMounted();

        })

        onBeforeUnmount(() => {

            baseOnBeforeUnmount();
        })


        onUnmounted(() => {

            baseOnUnmount();

        })


        return {
         
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            EditorTypeConst,
            loaded,
            BaseControlTypeConst,
            getCatalogValue,
            v$,
            submitted,
            canEdit,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
        };
    },
});
</script>
<style scoped></style>
