import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Login = _resolveComponent("Login")!
  const _component_UbmChat = _resolveComponent("UbmChat")!
  const _component_Vidle = _resolveComponent("Vidle")!
  const _component_FormBuilder = _resolveComponent("FormBuilder")!
  const _component_Cargando = _resolveComponent("Cargando")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.$route.path === '/login')
      ? (_openBlock(), _createBlock(_component_Login, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createCommentVNode("<Error v-else-if=\"$route.path === '/error'\" />"),
          _createCommentVNode("<Access v-else-if=\"$route.path === '/access'\" />\r\n    <NotFound v-else-if=\"$route.path === '/notfound'\" />"),
          _createCommentVNode("<AppPrestige  :theme=\"theme\" :layout=\"layout\"  v-else/>"),
          _createCommentVNode("<AppUltima v-else  />      "),
          _createElementVNode("div", null, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.selectedTemplate))),
            _createVNode(_component_UbmChat, { container: _ctx.container }, null, 8 /* PROPS */, ["container"]),
            _createVNode(_component_Vidle, {
              onIdle: _ctx.onidle,
              onRemind: _ctx.onremind,
              loop: false,
              reminders: [60],
              wait: 5,
              duration: 60000
            }, null, 8 /* PROPS */, ["onIdle", "onRemind"]),
            (_ctx.componentParentId > 0)
              ? (_openBlock(), _createBlock(_component_FormBuilder, {
                  key: 0,
                  container: _ctx.container,
                  applicationId: _ctx.userAppId,
                  applicationVersion: _ctx.userAppVersion,
                  componentId: _ctx.componentParentId,
                  preview: false,
                  canDestroy: true
                }, null, 8 /* PROPS */, ["container", "applicationId", "applicationVersion", "componentId"]))
              : _createCommentVNode("v-if", true)
          ])
        ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */)),
    _createVNode(_component_Cargando, { ref: "cargando" }, null, 512 /* NEED_PATCH */),
    _createCommentVNode("<AppPrestige v-if=\"isActiveTemplate(templatePrestige)\"   />"),
    _createCommentVNode("<AppUltima v-else  />      ")
  ], 64 /* STABLE_FRAGMENT */))
}