import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_CustomValidate = _resolveComponent("CustomValidate")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? _withDirectives((_openBlock(), _createBlock(_component_Dropdown, {
          key: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.DropDownTypeConst.ID),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.DropDownTypeConst.STYLE)),
          class: _normalizeClass({ [_ctx.getPropertyValue(_ctx.DropDownTypeConst.CLASS) ?? '']: true,  'p-invalid': _ctx.v$.vmodel.$error && _ctx.submitted,'p-readonly': _ctx.getPropertyBooleanValue(_ctx.DropDownTypeConst.READONLY) }),
          name: _ctx.getPropertyValue(_ctx.DropDownTypeConst.NAME),
          disabled: _ctx.getPropertyBooleanValue(_ctx.DropDownTypeConst.DISABLED) || (!_ctx.canEdit),
          visible: _ctx.getPropertyBooleanValue(_ctx.DropDownTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW),
          placeholder: _ctx.getPropertyValue(_ctx.DropDownTypeConst.PLACEHOLDER),
          options: _ctx.getPropertyValueOptions(_ctx.DropDownTypeConst.OPTIONS),
          filter: _ctx.getPropertyBooleanValue(_ctx.DropDownTypeConst.FILTER),
          filterPlaceholder: _ctx.getPropertyValue(_ctx.DropDownTypeConst.FILTERPLACEHOLDER),
          dataKey: _ctx.getPropertyValue(_ctx.DropDownTypeConst.DATAKEY),
          optionLabel: _ctx.getPropertyValueOption(_ctx.DropDownTypeConst.OPTIONLABEL,_ctx.getPropertyValueOptions(_ctx.DropDownTypeConst.OPTIONS)),
          optionValue: _ctx.getPropertyValueOption(_ctx.DropDownTypeConst.OPTIONVALUE,_ctx.getPropertyValueOptions(_ctx.DropDownTypeConst.OPTIONS)),
          optionDisabled: _ctx.getPropertyValue(_ctx.DropDownTypeConst.OPTIONDISABLED),
          optionGroupLabel: _ctx.getPropertyValue(_ctx.DropDownTypeConst.OPTIONGROUPLABEL),
          optionGroupChildren: _ctx.getPropertyValue(_ctx.DropDownTypeConst.OPTIONGROUPCHILDREN),
          showClear: _ctx.getPropertyBooleanValue(_ctx.DropDownTypeConst.CLEAR),
          inputProps: _ctx.getInputStyle,
          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.processEventComponent(_ctx.CatalogEventConst.CHANGE))),
          modelValue: _ctx.vmodel,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.vmodel) = $event))
        }, null, 8 /* PROPS */, ["id", "style", "class", "name", "disabled", "visible", "placeholder", "options", "filter", "filterPlaceholder", "dataKey", "optionLabel", "optionValue", "optionDisabled", "optionGroupLabel", "optionGroupChildren", "showClear", "inputProps", "modelValue"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.DropDownTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE))
      ? (_openBlock(), _createBlock(_component_CustomValidate, {
          key: 2,
          submitted: _ctx.submitted,
          "onUpdate:submitted": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.submitted) = $event)),
          vObject: _ctx.v$,
          "onUpdate:vObject": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.v$) = $event))
        }, null, 8 /* PROPS */, ["submitted", "vObject"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}