<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <MultiSelect v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(MultiSelectTypeConst.ID)" v-tooltip="getPropertyValue(MultiSelectTypeConst.TOOLTIP)"
        :style="getPropertyValue(MultiSelectTypeConst.STYLE)"
        :class="{ [getPropertyValue(MultiSelectTypeConst.CLASS) ?? '']: true, 'p-invalid': v$.vmodel.$error && submitted,'p-readonly': getPropertyBooleanValue(MultiSelectTypeConst.READONLY) }"
        :name="getPropertyValue(MultiSelectTypeConst.NAME)"
        :disabled="getPropertyBooleanValue(MultiSelectTypeConst.DISABLED) || (!canEdit)"
        :visible="getPropertyBooleanValue(MultiSelectTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :placeholder="getPropertyValue(MultiSelectTypeConst.PLACEHOLDER)"
        :filter="getPropertyBooleanValue(MultiSelectTypeConst.FILTER)"
        :filterPlaceholder="getPropertyValue(MultiSelectTypeConst.FILTERPLACEHOLDER)"
        :display="getDisplay()"
        :selectedItemsLabel="getPropertyValue(MultiSelectTypeConst.SELECTEDITEMSLABEL)"
        :maxSelectedLabels="getPropertyNumberValue(MultiSelectTypeConst.MAXSELECTEDLABELS)"
        :selectionLimit="getPropertyNumberValue(MultiSelectTypeConst.SELECTIONLIMIT)"
        :showToggleAll="getPropertyBooleanValue(MultiSelectTypeConst.SHOWTOGGLEALL)"
        :selectAll="getPropertyBooleanValue(MultiSelectTypeConst.SELECTALL)"
        :resetFilterOnHide="getPropertyBooleanValue(MultiSelectTypeConst.RESETFILTERONHIDE)"
        :autoOptionFocus="getPropertyBooleanValue(MultiSelectTypeConst.AUTOOPTIONFOCUS)"
        :autoFilterFocus="getPropertyBooleanValue(MultiSelectTypeConst.AUTOFILTERFOCUS)"
        :filterMessage="getPropertyValue(MultiSelectTypeConst.FILTERMESSAGE)"
        :selectionMessage="getPropertyValue(MultiSelectTypeConst.SELECTIONMESSAGE)"
        :emptyMessage="getPropertyValue(MultiSelectTypeConst.EMPTYMESSAGE)"
        :dataKey="getPropertyValue(MultiSelectTypeConst.DATAKEY)" 
        :readonly="getPropertyBooleanValue(MultiSelectTypeConst.READONLY) || !canDoOperation(ObjectGroupConst.EDIT)"
        :options="getOptions()"
        :optionLabel="getOptionLabel()"
        :optionValue="getOptionValue()"
        :optionDisabled="getPropertyValue(MultiSelectTypeConst.OPTIONDISABLED)"
        :optionGroupLabel="getOptionGroupLabel()"
        :optionGroupChildren="getOptionGroupChildren()"
        :scrollHeight="getPropertyValue(MultiSelectTypeConst.SCROLLHEIGHT)"
        v-model="arrayModel"
        @change ="setArrayToVModel()">
    </MultiSelect>
    <CustomValidate v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE)" v-model:submitted="submitted" v-model:vObject="v$" />

</template>
<script lang="ts">


import { defineComponent, onMounted, ref, onBeforeUnmount, onUnmounted, watch } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import ControlTypeConst from '../../../../domain/Constants/ControlTypeConst';
import MultiSelectTypeConst from '../../../../domain/Constants/MultiSelectTypeConst';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import CustomValidate from '../../shared/CustomValidate.vue';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_multi_select',
    components: {
        CustomValidate,
        SummaryDebug
    },

    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },     
        slotProps: {
            type: Object,
            default: () => ({})
        },
       
    },
    setup(props, context) {
        //
        const arrayModel = ref([])
        const store = useStore();
        
 
        const setVModelToArray = () =>{            
            arrayModel.value = (vmodel.value ?? '').split(',').filter(x=> x!== '')
        }

        const { vmodel, canDoOperation, getPropertyValueOption, getPropertyValueObject, getPropertyValueObjectOrFile
            , getPropertyValue, getPropertyValueOptions, getPropertyBooleanValue, getPropertyNumberValue
            , loaded,  resolveExpressions, getCatalogValue, baseOnBeforeUnmount, baseOnMounted, baseOnUnmount
            , v$, submitted, canEdit, isValidData, fieldKey, fieldKeyComponentDataSourceId
            , processEventComponent  ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store,setVModelToArray);
 
         

           
      
        const setArrayToVModel = () =>{
            if (arrayModel.value.length>0){
                vmodel.value = arrayModel.value.join(',');
            }
            else{
                vmodel.value = null
            }
            
        }
        

        onMounted(() => {
            baseOnMounted();
        })

        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })

        onUnmounted(() => {
            baseOnUnmount();
        })

        const getDisplay = () : "comma" | "chip" => {
            const value = getCatalogValue(MultiSelectTypeConst.DISPLAY) ?? 'comma'
            const display: "comma" | "chip" = value as "comma" | "chip";
            return display;
        }

        const getOptions = () : any[] | undefined => {
            let opciones = getPropertyValueOptions(MultiSelectTypeConst.OPTIONS)
            if (opciones == null) return undefined;
            if (Array.isArray(opciones))
                return opciones;
            opciones = opciones?.replace(/\'/g,'\"');
            const items = JSON.parse(opciones)
            return items;
        }

        const getOptionLabel = () : string | undefined => {
            const label = getPropertyValue(MultiSelectTypeConst.OPTIONLABEL);
            return label ?? undefined;
        }
        const getOptionValue = () : string | undefined => {
            const value = getPropertyValue(MultiSelectTypeConst.OPTIONVALUE);
            return value ?? undefined;
        }
        
        const getOptionGroupLabel = () : string | undefined => {
            const label = getPropertyValue(MultiSelectTypeConst.OPTIONGROUPLABEL);
            return label ?? undefined;
        }

        const getOptionGroupChildren = () : string | undefined => {
            const children = getPropertyValue(MultiSelectTypeConst.OPTIONGROUPCHILDREN);
            return children ?? undefined;
        }

        return {
            getPropertyValue,
            getPropertyBooleanValue,
            getPropertyValueOption,
            vmodel,
            ControlTypeConst,
            MultiSelectTypeConst,
            getPropertyNumberValue,
            loaded,
            BaseControlTypeConst,
            getCatalogValue,
            v$,
            submitted,
            canEdit,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst,
            canDoOperation,
            getDisplay,
            getOptions,
            getOptionLabel,
            getOptionValue,
            getOptionGroupLabel,
            getOptionGroupChildren,
            arrayModel,
            setArrayToVModel,
            componentKey
        };
    },

});
</script>
<style scoped></style>
