import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("i", {
    class: "fa fa-microphone",
    style: _normalizeStyle({ color: _ctx.gerIconColor }),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMic && _ctx.toggleMic(...args)))
  }, null, 4 /* STYLE */))
}