<template>    
    <div class="grid">
    <div class="col-12">
        <div class="p-toolbar p-toolbar-custom box-panel shadow-5 flex">

            <div class="p-toolbar-group-start">
                <slot name="buttons"></slot>   
                <slot name="header"></slot>             
            </div>
            
            <div class="p-toolbar-group-end">
                
                <Button v-if="!tabMode" style="cursor:pointer" icon="pi pi-arrow-left" v-tooltip="'Volver al listado'"
                    class="ml-1 p-button-secondary p-button-outlined"
                    @click="$emit('backToList')" />
                <Button v-if="tabMode" style="cursor:pointer" icon="pi pi-refresh" v-tooltip="{value: 'Recargar '+ (detailComponentId>0 ? 'detalle' : 'pestaña')+' actual'}"
                    class="ml-1 p-button-secondary p-button-outlined"
                    @click="$emit('click:reloadTab')" />
                <Button v-if="tabMode" style="cursor:pointer" icon="pi pi-times" v-tooltip.left="{value:'Cerrar '+ (detailComponentId>0 ? 'detalle' : 'pestaña')+' actual' }"
                    class="ml-1 p-button-secondary p-button-outlined"
                    @click="$emit('click:closeTab')" />              
            </div>
        </div>
    </div>

    <div class="col-12 lg:col-12">        
        <slot v-if="componentId<=0"  name="detail"></slot>
        <FormBuilder v-else-if="componentId>0 && detailComponentId<0"  :container="container" :componentId="componentId" :applicationId="applicationId"
            :applicationVersion="applicationVersion" :attrsInput="attrsInput" />                
        <FormBuilder v-else-if="detailComponentId>0"  :container="container" :componentId="detailComponentId" :applicationId="detailApplicationId"
            :applicationVersion="detailApplicationVersion" :attrsInput="detailAttrsInput" />                
    </div>
</div>
</template>
<script lang="ts">

import { Container } from 'inversify';
import { defineComponent} from 'vue';
import { IapComponentAttribute } from '../../../component/domain/iapComponentAttribute';

export default defineComponent({
    name: 'detail_crud',
    events:['backToList','click:closeTab','click:reloadTab'],
    props:{
        container: {
            type: Object as () => Container
        },
        attrsInput: {
            type: Object as () => IapComponentAttribute[],
            default: () => ({})
        },
        componentId:
        {
            type: Number,
            default: (-1)
        },
        applicationId:
        {
            type: Number,
            default: (-1)
        },
        applicationVersion:
        {
            type: Number,
            default: (-1)
        },
        tabMode:
        {
            type: Boolean,
            default: (false)
        },
        detailComponentId:
        {
            type: Number,
            default: (-1)
        },
        detailApplicationId:
        {
            type: Number,
            default: (-1)
        },
        detailApplicationVersion:
        {
            type: Number,
            default: (-1)
        },
        detailAttrsInput: {
            type: Object as () => IapComponentAttribute[],
            default: () => ({})
        },
   
    },
    setup(props) {
        
        return {
            
        };
    },
});
</script>
<style scoped>
.p-toolbar-custom {
    padding: 2px !important;
}
</style>


