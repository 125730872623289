
import { CustomMenu } from "../../../../../common/domain/modelos/menu/CustomMenu";
import store from "../../../../../common/infrastructure/almacen";
import { Actions } from "../../../../../common/infrastructure/almacen/enums/StoreEnums";
import { CookieService } from "../../../../../common/infrastructure/servicios";
import HelperUtils from "../../../../../common/infrastructure/funciones/HelperUtils";


import { useLayoutBuilder } from '../../../../builder/template/infrastructure/prime/LayoutBuilder'
import { Container } from "inversify";
import { defineAsyncComponent } from "vue";


const { changeAppLayoutTemplate } = useLayoutBuilder();

const getApplicationMenus = async (fromShared: boolean, routes: any, applicationId: number, applicationVersion:number, container: Container, routeToRedirect: string, doMFA: boolean): Promise<boolean> => {
  let showModalAuthType = false;
  return await new Promise<boolean>((resolve) => {
  store
    .dispatch(Actions.SET_MENU, [applicationId, applicationVersion, container])
    .then(() => {
      //visibleRight.value = false;      
      buildRoutes(fromShared, routes, container, store.getters.getApplicationMenus, -1);      
      store.dispatch(Actions.SET_ROUTES, routes)
    })
    .finally(() => {
      changeAppLayoutTemplate(applicationId, applicationVersion, container);

      if (routeToRedirect == '') {
        showModalAuthType = redirectCompleteLogin(routes, doMFA);
      }
      else {
        routes.push({ name: routeToRedirect });
      }
      
      resolve(showModalAuthType);
    });
  });
}

const getAppLayoutComponent = async (applicationId: number, applicationVersion:number, container: Container): Promise<void> => {
  return await new Promise<void>((resolve) => {
  store
    .dispatch(Actions.GET_APPLAYOUTCOMPONENT, [applicationId, applicationVersion, container])
    .then(() => {
      
    })
    .finally(() => {
      
      resolve();
    });
  });
}

/*
En el menu el campo 'route' es la ruta de componentes estaticos 
*/
const buildRoutes = (fromShared: boolean, routes: any, container: Container, menusBuilder: CustomMenu[], parentId: -1, parentLabel: string | null = null) => {
  
  if (menusBuilder?.length > 0) {
    menusBuilder
      .filter(
        (x) =>
          (parentId == -1 && x.parentId == null) ||
          (parentId !== -1 && x.parentId == parentId)
      )
      .sort((x) => x.order ?? 0)
      .forEach((menu) => {
        if ((menu.to?.toString()) || menu.componentId != null) {
          //********* En el menu el campo 'route' es la ruta de componentes estaticos  ******************
          const menuRoute = (menu.route?.toString() ?? 'EmptyPage.vue')
          let componentaux = getComponentFromShared(menu.componentId, menuRoute);

          if (!fromShared) {
            componentaux = getComponentFromOutsideShared(menu.componentId,menuRoute);
          }
          const route = {
            path: menu.to?.toString(),// +'/:queryOperation?' ,
            force:true,
            name:menu.id.toString(),
            meta: {
              breadcrumb: [
                {
                  parent: menu.to?.toString() && menuRoute ? parentLabel : null,
                  label:menu.label,
                },
              ],
              directivas: "",
              builtin: "",
              contrato: menu.contract,
              keepAlive:menu.keepAlive,
              tag:null
            },
            component: componentaux,
            props: {
              container: container,
              componentId: menu.componentId,
              applicationId: menu.applicationId,
              applicationVersion: menu.applicationVersion,
            },
          }

          routes?.addRoute(route);

        }
        buildRoutes(fromShared, routes, container, (menu.items as CustomMenu[] ?? []), menu.id as any, (parentLabel == null ? '' : (parentLabel + ' > ') ) + menu?.label as any);
      });
  }
};

function getComponentFromShared(componentId: number | null, route: string) {

  if (componentId != null) {
    return defineAsyncComponent(() => import('../../../../builder/form/infrastructure/FormBuilder.vue'));
  }

  switch (route) {
    case '../../../../builder/application/infrastructure/component/Applications.vue':
      return defineAsyncComponent(() => import('../../../../builder/application/infrastructure/component/Applications.vue'));
    case '../../.././../builder/designer/infrastructure/component/Container.vue':
      return defineAsyncComponent(() => import('../../../../builder/designer/infrastructure/component/Designer.vue'));

    case "../../../../otros/inicio/mipanel/infrastructure/MiPanel.vue":
      return defineAsyncComponent(() => import(
        "../../../../otros/inicio/mipanel/infrastructure/MiPanel.vue"
      ));
    case "EmptyPage.vue":
        return defineAsyncComponent(() => import('../../../../../common/infrastructure/EmptyPage.vue'));
    default:
      return defineAsyncComponent(() => import(route));
  }
}

function getComponentFromOutsideShared(componentId: number | null, route: string) {
  if (componentId != null) {
    return defineAsyncComponent(() => import("@ilinium/shared/src/entidades/builder/form/infrastructure/FormBuilder.vue"));
  }

  switch (route) {
    case '../../../../builder/application/infrastructure/component/Applications.vue':
      return defineAsyncComponent(() => import('@ilinium/shared/src/entidades/builder/application/infrastructure/component/Applications.vue'));
    case '../../.././../builder/designer/infrastructure/component/Container.vue':
      return defineAsyncComponent(() => import('@ilinium/shared/src/entidades/builder/designer/infrastructure/component/Designer.vue'));

     case "../../../../otros/inicio/mipanel/infrastructure/MiPanel.vue":
      return defineAsyncComponent(() => import(
        "@ilinium/shared/src/entidades/otros/inicio/mipanel/infrastructure/MiPanel.vue"
      ));

      case "EmptyPage.vue":
        return defineAsyncComponent(() => import('@ilinium/shared/src/common/infrastructure/EmptyPage.vue'));
    default:
      return defineAsyncComponent(() => import(route));
  }
}

function redirectCompleteLogin(routes: any, doMFA: boolean) {
  let showModalAuthType = false;

  var redirectCookie = CookieService.getCookie(
    CookieService.COOKIE_REDIRECT_URL
  );
  if (redirectCookie != null && redirectCookie != "") {
    routes?.push({ name: redirectCookie });
  } else {
    
    var cookieDobleFactor = CookieService.getCookie(CookieService.COOKIE_DOBLEFACTOR);
   
    if (!doMFA || (!store.getters.getCurrentUser.iapmUserOptions ||!store.getters.getCurrentUser.iapmUserOptions.find((c: { optionId: string }) => c.optionId == 'cfgbroker-MFA')?.value)
    ||
        ((cookieDobleFactor != null && cookieDobleFactor != '') &&
        store.getters.getCurrentUser.iapmUserOptions.find((c: { optionId: string }) => c.optionId == 'cfgbroker-MFA')?.value &&
        store.getters.getCurrentUser.multiFactorAuthentication != null &&
        store.getters.getCurrentUser.multiFactorAuthentication.code &&
        store.getters.getCurrentUser.multiFactorAuthentication.code != '' &&
        store.getters.getCurrentUser.multiFactorAuthentication.expireDateMultiFactor &&
        (new Date(store.getters.getCurrentUser.multiFactorAuthentication.expireDateMultiFactor) >= new Date()) &&
        store.getters.getCurrentUser.multiFactorAuthentication.validated)) 
        
        {
          var initUrl = store.getters.initUrlPage;
          if (initUrl == "") {
            const id = HelperUtils.newGuid();
            routes?.push({
              name: "rfps",
              params: { id: id, operation: "productos" },
            });
          } else {
            routes?.push({
              name: /*initUrl == 'default.aspx' ? */ "mipanel" /* : initUrl*/,
            });
          }
        }
    else {
        showModalAuthType = true;
    }
          
  }
  return showModalAuthType;
}


export default {
  getApplicationMenus,
  getAppLayoutComponent
}