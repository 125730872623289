/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
import { Dictionary } from "../../../expression/domain/dictionary";
import CatalogObjectTypeConst from "../../../catalog/domain/const/CatalogObjectTypeConst";
import { ProcedureData } from "../../../search/domain/procedureData";
import { IapComponentAttribute } from "../../../component/domain/iapComponentAttribute";


export default function HelperCustomComponentControls
  (
    dataInput:any
  ) {


  

    const showExpression = (variableName: string, header:string,item : null |  ProcedureData | IapComponentAttribute | Dictionary<string,string> = null ) => {
        if (dataInput) {
            const data = dataInput.getNode(variableName)
            if (data) {
                let  objectDescription = header;
                let objetoId = data['id']?.toString()
                if (objetoId) {
                    const idObjeto = CatalogObjectTypeConst.WFAC;
                    //objetoId += '#'+ variableName + '=' + item.id;
                    if (item &&  (item as any).parameterName )
                    {
                      objetoId += '#parameterName=' + (item as ProcedureData).parameterName
                    }
                    if (item &&  (item as any).idAttributeType )
                    {
                      objetoId += '#parameterId=' + (item as IapComponentAttribute).id;                     
                    }
                    if (item &&  (item as any).key )
                    {
                      objetoId += '#parameterId=' + (item as Dictionary<string,string>).key;                     
                    }
                    
                    
                    dataInput.showExpression({ objetoId: objetoId, idObjeto: idObjeto, description: objectDescription })
                }
            }
        }
    }

    
  return {
    showExpression
  };
}
