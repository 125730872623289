import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';

import { IApplicationConnectionRepository } from '../domain/IApplicationConnectionRepository';

//import { UPDATE } from './mutation/ApplicationConnectionMutation';
import { GET_ALL_APPLICATIONCONNECTION, GET_APPLICATIONCONNECTION_BY_ID } from './query/applicationConnectionQuery';
import { IapApplicationConnection } from '../domain/iapApplicationConnection';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class ApplicationConnectionApolloClientRepository implements IApplicationConnectionRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId=ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }


 

  async getAll (maxregs: number | undefined): (Promise<IapApplicationConnection[]>) {
    return new Promise<IapApplicationConnection[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_ALL_APPLICATIONCONNECTION, { maxregs: maxregs },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationConnectionQuery.getAll)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getById (id: number, version: number): (Promise<IapApplicationConnection>) {
    return new Promise<IapApplicationConnection>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_APPLICATIONCONNECTION_BY_ID, { id: id, version: version },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationConnectionQuery.getById)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


}


