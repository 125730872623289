import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-14471968"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "p-toolbar p-toolbar-custom box-panel shadow-5 flex" }
const _hoisted_4 = { class: "p-toolbar-group-start" }
const _hoisted_5 = { class: "p-toolbar-group-end" }
const _hoisted_6 = { class: "col-12 lg:col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_FormBuilder = _resolveComponent("FormBuilder")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "buttons", {}, undefined, true),
          _renderSlot(_ctx.$slots, "header", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (!_ctx.tabMode)
            ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                key: 0,
                style: {"cursor":"pointer"},
                icon: "pi pi-arrow-left",
                class: "ml-1 p-button-secondary p-button-outlined",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('backToList')))
              }, null, 512 /* NEED_PATCH */)), [
                [_directive_tooltip, 'Volver al listado']
              ])
            : _createCommentVNode("v-if", true),
          (_ctx.tabMode)
            ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                key: 1,
                style: {"cursor":"pointer"},
                icon: "pi pi-refresh",
                class: "ml-1 p-button-secondary p-button-outlined",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click:reloadTab')))
              }, null, 512 /* NEED_PATCH */)), [
                [_directive_tooltip, {value: 'Recargar '+ (_ctx.detailComponentId>0 ? 'detalle' : 'pestaña')+' actual'}]
              ])
            : _createCommentVNode("v-if", true),
          (_ctx.tabMode)
            ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                key: 2,
                style: {"cursor":"pointer"},
                icon: "pi pi-times",
                class: "ml-1 p-button-secondary p-button-outlined",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('click:closeTab')))
              }, null, 512 /* NEED_PATCH */)), [
                [
                  _directive_tooltip,
                  {value:'Cerrar '+ (_ctx.detailComponentId>0 ? 'detalle' : 'pestaña')+' actual' },
                  void 0,
                  { left: true }
                ]
              ])
            : _createCommentVNode("v-if", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.componentId<=0)
        ? _renderSlot(_ctx.$slots, "detail", { key: 0 }, undefined, true)
        : (_ctx.componentId>0 && _ctx.detailComponentId<0)
          ? (_openBlock(), _createBlock(_component_FormBuilder, {
              key: 1,
              container: _ctx.container,
              componentId: _ctx.componentId,
              applicationId: _ctx.applicationId,
              applicationVersion: _ctx.applicationVersion,
              attrsInput: _ctx.attrsInput
            }, null, 8 /* PROPS */, ["container", "componentId", "applicationId", "applicationVersion", "attrsInput"]))
          : (_ctx.detailComponentId>0)
            ? (_openBlock(), _createBlock(_component_FormBuilder, {
                key: 2,
                container: _ctx.container,
                componentId: _ctx.detailComponentId,
                applicationId: _ctx.detailApplicationId,
                applicationVersion: _ctx.detailApplicationVersion,
                attrsInput: _ctx.detailAttrsInput
              }, null, 8 /* PROPS */, ["container", "componentId", "applicationId", "applicationVersion", "attrsInput"]))
            : _createCommentVNode("v-if", true)
    ])
  ]))
}