
import gql from 'graphql-tag';



export const GET_ALL_OBJECTDATASOURCEFIELDCONFIGURATION = gql(`
query($maxregs: Int) {
    objectDataSourceFieldConfigurationQuery {
      getAll(maxregs:$maxregs) {
            idObjeto
            objetoId
            dataSourceFieldId
            defaultFilterSearch      
            defaultFilterSearchOrder
            autoUpdate
            defaultValue
            defaultValidation
            edit
            add
            editMassive
            import
            fcr
            filterResult
            filterResultOrder
            filterSearch
            filterSearchOrder
            fum
            list
            view
            mandatory
            sortOrder
            idSortType
            aggregateOperation
            idFilterType
            idFilterControlType
            defaultFilterValue
            cellStyle
            displayContentMaxlength
            dataSourceField{
                tableName
                field
                tableNameAlias,
                fieldAlias,
                dataSourceLookUpId

      }  
      }
    }
}
`);

export const GET_BY_OBJECTID = gql(`
query($idObjeto: String!,$objetoId: Int!) {
    objectDataSourceFieldConfigurationQuery {
        getByObjectId(idObjeto:$idObjeto,objetoId:$objetoId) {            
            idObjeto
            objetoId
            dataSourceFieldId
            defaultFilterSearch      
            defaultFilterSearchOrder
            autoUpdate
            defaultValue
            defaultValidation
            edit
            add
            editMassive
            import
            fcr
            filterResult
            filterResultOrder
            filterSearch
            filterSearchOrder
            fum
            list
            view
            mandatory
            sortOrder
            idSortType
            aggregateOperation
            idFilterType
            idFilterControlType
            defaultFilterValue
            cellStyle
            displayContentMaxlength
            dataSourceField{
                tableName
                field
                tableNameAlias,
                fieldAlias,
                dataSourceLookUpId

              }  
    }
  }
}
`);


