import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import { IComponentRepository } from '../domain/IComponentRepository';

import { GET_ALL_COMPONENTS, GET_COMPONENT_BY_ID, GET_COMPONENT_SIMPLE_TREE_BY_ID, GET_COMPONENT_TREE_ALLOBJCETS_BY_ID, GET_COMPONENT_TREE_BY_ID, GET_PARENTS,GET_COMPONENT_FILE, GET_APPLAYOUTCOMPONENT } from './query/componentQuery';
import { UPDATE, ADD, DELETE, SORT, ADD_COMPONENT_FILE } from './mutation/componentMutation';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { IapComponent } from '../domain/iapComponent';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';
import { IapComponentFile } from '../domain/iapComponentFile';



@injectable()
export class ComponentApolloClientRepository implements IComponentRepository {
  private apolloClient : MyApolloClient
  private apolloClientId: string
  constructor (apolloInstance: MyApolloClient, _apolloClientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.apolloClientId = _apolloClientId
  }
  
  
  
  
  async delete (id: number): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(DELETE, { id: id }, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentMutation.deleteComponent)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  

  async update (dataInput: IapComponent): (Promise<IapComponent>) {
    return new Promise<IapComponent>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE, { data:dataInput }, this.apolloClientId)    

      watch(result, (value: any) => {
        
        try {

          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentMutation.updateComponent)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async add (dataInput: IapComponent): (Promise<IapComponent[]>) {
    return new Promise<IapComponent[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD, { data:dataInput }, this.apolloClientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentMutation.addComponent)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async getAll (maxregs: number | undefined): (Promise<IapComponent[]>) {
    return new Promise<IapComponent[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_ALL_COMPONENTS, { maxregs: maxregs }, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentQuery.getAll)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getById (id: number): (Promise<IapComponent>) {
    return new Promise<IapComponent>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(GET_COMPONENT_BY_ID, { id: id }, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentQuery.getById)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


  async getTreeById (applicationId: number, applicationVersion: number, componentId: number): (Promise<IapComponent[]>) {
    return new Promise<IapComponent[]>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(GET_COMPONENT_TREE_BY_ID, { componentId: componentId,applicationId:applicationId,applicationVersion:applicationVersion }, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentQuery.getTreeById)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getTreeAllObjectsById (applicationId: number, applicationVersion: number, componentId: number,withAllAtributtes:boolean): (Promise<IapComponent[]>) {
    return new Promise<IapComponent[]>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(GET_COMPONENT_TREE_ALLOBJCETS_BY_ID, { componentId: componentId,applicationId:applicationId,applicationVersion:applicationVersion,withAllAtributtes:withAllAtributtes }, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentQuery.getTreeAllObjectsById)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  

  async getSimpleTreeById (applicationId: number, applicationVersion: number, componentId: number): (Promise<IapComponent[]>) {
    return new Promise<IapComponent[]>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(GET_COMPONENT_SIMPLE_TREE_BY_ID, { componentId: componentId,applicationId:applicationId,applicationVersion:applicationVersion }, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentQuery.getTreeById)
        }
        catch(e) {
          reject()
        }
      });
    });
  }
  
  async getAppLayoutComponent (applicationId: number, applicationVersion: number): (Promise<IapComponent[]>) {
    return new Promise<IapComponent[]>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(GET_APPLAYOUTCOMPONENT, { applicationId:applicationId,applicationVersion:applicationVersion }, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentQuery.getAppLayoutComponent.id)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getParents (applicationId: number, applicationVersion: number): (Promise<IapComponent[]>) {
    return new Promise<IapComponent[]>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(GET_PARENTS, { applicationId:applicationId,applicationVersion:applicationVersion }, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentQuery.getParents)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async sort (dataInput: IapComponent[]): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(SORT, { data:dataInput }, this.apolloClientId)    

      watch(result, (value: any) => {
        
        try {

          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentMutation.sortComponent)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async getFile (applicationId: number, applicationVersion: number, componentId: number): (Promise<IapComponent[]>) {
    return new Promise<IapComponent[]>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(GET_COMPONENT_FILE, { componentId: componentId,applicationId:applicationId,applicationVersion:applicationVersion }, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentQuery.getFile)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async addComponentFile (data: IapComponentFile[]): (Promise<IapComponentFile[]>) {
    return new Promise<IapComponentFile[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD_COMPONENT_FILE, { data:data }, this.apolloClientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentMutation.addComponentFile)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

 

}


