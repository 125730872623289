<template>    
   <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
            
    <input v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(InputTypeConst.ID)" v-tooltip="getPropertyValue(InputTypeConst.TOOLTIP)"
        :style="getPropertyValue(InputTypeConst.STYLE)"
        v-model="vmodel"
        :class="{ [getPropertyValue(InputTypeConst.CLASS) ?? '']: true, 'p-invalid': v$.vmodel.$error && submitted,'p-readonly': getPropertyBooleanValue(InputTypeConst.READONLY) }"
        :name="getPropertyValue(InputTypeConst.NAME)"
        :disabled="getPropertyBooleanValue(InputTypeConst.DISABLED) || (!canEdit)"
        :visible="getPropertyBooleanValue(InputTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :placeholder="getPropertyValue(InputTypeConst.PLACEHOLDER)" :type="getCatalogValue(InputTypeConst.TYPE)"
        :value="getPropertyValue(InputTypeConst.VALUE)" 
        :readonly="getPropertyBooleanValue(InputTypeConst.READONLY) || !canDoOperation(ObjectGroupConst.EDIT)"
        :required="getPropertyBooleanValue(InputTypeConst.REQUIRED)"
        :maxlength="getPropertyNumberValue(InputTypeConst.MAXLENGTH)" :size="getPropertyNumberValue(InputTypeConst.SIZE)"
        :min="getPropertyNumberValue(InputTypeConst.MIN)" :max="getPropertyNumberValue(InputTypeConst.MAX)"
        :autocomplete="getPropertyValue(InputTypeConst.AUTOCOMPLETE)" :pattern="getPropertyValue(InputTypeConst.PATTERN)"
        >
    <CustomValidate v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" v-model:submitted="submitted" v-model:vObject="v$" />
</template>
<script lang="ts">


import { defineComponent, onMounted,  onUnmounted } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import InputTypeConst from '../../../../domain/Constants/InputTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import CustomValidate from '../../shared/CustomValidate.vue';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_html_input',
    components: {
        CustomValidate,
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },       
        slotProps: {
            type: Object,
            default: () => ({})
        },      

    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, loaded, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue, resolveExpressions, getCatalogValue,
             baseOnUnmount, v$, submitted, canEdit,isValidData,fieldKey,fieldKeyComponentDataSourceId 
             ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);


        onMounted(() => {
            // resolveExpressions();
        })

        onUnmounted(() => {
            baseOnUnmount();
        })


        return {
            getPropertyValue,
            getPropertyBooleanValue,
            getPropertyNumberValue,
            vmodel,
            InputTypeConst,
            loaded,
            BaseControlTypeConst,
            getCatalogValue,
            v$,
            submitted,
            canEdit,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey

        };
    },
});
</script>
<style scoped></style>
