import BaseControlTypeConst from "./BaseControlTypeConst"

export default class InputTextTypeConst extends BaseControlTypeConst {
 
    static TYPE = 'type'
    static MIC = 'mic'  
    static COPYTOCLIPBOARDB = 'copyToClipboard'  
    static READONLY = 'readonly'
    static CHAT = 'chat'
    static EMAIL = 'email'
}
