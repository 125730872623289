import BaseControlTypeConst from "./BaseControlTypeConst"

export default class PanelTypeConst extends BaseControlTypeConst {
    static HEADER = 'header'
    static TOGGLEABLE = 'toggleable'
    static COLLAPSED = 'collapsed'
    
    static LAYOUT_CONTENT='layouttemp-pnl-content'
    static LAYOUT_FOOTER='layouttemp-pnl-footer'
    static LAYOUT_HEADER='layouttemp-pnl-header'
    static LAYOUT_ICON='layouttemp-pnl-icon'

    static CMD_DETAIL='commandDetail'

}
