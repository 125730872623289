<template>
    <span>Componente</span>
    <select @pointerdown.stop="" @dblclick.stop="" name="dslist" v-model="selectedComponent"
        style="border: 1px solid #999;border-radius: 30px;    font-size: 110%;" @change="changeComponent()"
        class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full ">
        <option value="">Selecciona un componente</option>
        <option v-for="(ds, index) in dtComponentsInfo" v-bind:value="ds.id" :key="index">{{ '(' + ds.id + ') - ' + ds.name
        }}

        </option>
    </select>
   
</template>
<script lang="ts">

import { computed, defineComponent, onMounted, ref } from 'vue';
import { IapComponentDataSource } from '../../../../../component/domain/iapComponentDataSource';
import Dropdown from 'primevue/dropdown';
import CatalogTypeConst from '../../../../../catalog/domain/const/CatalogTypeConst';
import helperCatalog from '../../../../../catalog/infrastructure/helper/helperCatalog';
import { Container } from 'inversify';
import { IServiceSearch } from '../../../../../search/application/IServiceSearch';
import { TYPES } from '../../../../../../../common/domain/types';
import HelperLoading from '../../../../../../../common/infrastructure/funciones/HelperLoading';
import CatalogDbType from '../../../../../catalog/domain/const/CatalogDbType';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';

import CatalogObjectTypeConst from '../../../../../catalog/domain/const/CatalogObjectTypeConst';
import { ProcedureData } from '../../../../../search/domain/procedureData';
import { IServiceComponent } from '../../../../../component/application/IServiceComponent';
import { IapComponent } from '../../../../../component/domain/iapComponent';
import { IapComponentAttribute } from '../../../../../component/domain/iapComponentAttribute';
import CatalogAttrConst from '../../../../../catalog/domain/const/CatalogAttrConst';
import HelperCustomComponentControls from '../../../functions/helperCustomComponentControls';
import ValidateComponentNodeConst from '../constants/ValidateComponentNodeConst';
import { IapComponentEvent } from '@ilinium/shared/src/entidades/builder/component/domain/iapComponentEvent';

export default defineComponent({
    name: 'customvalidatecomponentcontrol',

    props: {
        data: Object
    },
    setup(props) {

        const selectedComponent = ref(-1);
        const dtComponentsInfo = ref<IapComponent[]>([]);


        const changeComponent = () => {
            if (props.data) {
                props.data.onChange({ key: ValidateComponentNodeConst.VAR_COMP_IN, data: selectedComponent.value })
                
            }
        }


    

        const loadComponentsTree = () => {
            if (props.data && props.data.container) {
                HelperLoading.showLoading()
                const _srv = (props.data.container as Container).get<IServiceComponent>(TYPES.COMPONENT_REPOSITORY)

                _srv.getSimpleTreeById(props.data.applicationId ?? -1, props.data.applicationVersion ?? -1, props.data.rootComponentId ?? -1 ).then((response: IapComponent[]) => {
                    dtComponentsInfo.value = response;

                })
                    .finally(() => {
                        HelperLoading.hideLoading()
                    })
            }
        }



       

        onMounted(() => {            
            loadComponentsTree();
            if (props.data) {
                
                selectedComponent.value = props.data[ValidateComponentNodeConst.VAR_COMP_IN]                
            }

        })

        return {
            selectedComponent,
            changeComponent,
            dtComponentsInfo,          
            CatalogObjectTypeConst,
            ValidateComponentNodeConst
        };
    },
});
</script>
<style scoped></style>
