import { useStore } from "vuex";

export const fetchWrapper = {
    get,
    post,
    put,
    delete:_delete,
    getBinaryFile
};



function getHeaders(storeExternal:any = null) {
    const store = storeExternal ?? useStore();
    const token = store.getters.getAcessToken;     
    return {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization':`Bearer ${token}`,
        'Cache-Control':'no-cache, no-store, must-revalidate',
        'Expires':'-1'
    };
}

function get(url:string,storeExternal:any = null) {
    const requestOptions = {
        method: 'GET',
        //mode: "no-cors",
        headers:getHeaders(storeExternal)
    };
    
    return fetch(url, requestOptions as any).then(handleResponse);
}

function getBinaryFile(url:string,storeExternal:any = null) {
    const requestOptions = {
        method: 'GET',
        //mode: "no-cors",
        headers:getHeaders(storeExternal)
    };
    
    return fetch(url, requestOptions as any).then(handleResponseBinary);
}

function post(url:string, body:any,storeExternal:any = null) {
    const requestOptions = {
        method: 'POST',
        headers: getHeaders(storeExternal),
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);
}

function put(url:string, body:any,storeExternal:any = null) {
    const requestOptions = {
        method: 'PUT',
        headers: getHeaders(storeExternal),
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);    
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url:string,storeExternal:any = null) {
    const requestOptions = {
        method: 'DELETE',
        headers:getHeaders(storeExternal),
    };
    return fetch(url, requestOptions).then(handleResponse);
}

// helper functions

function handleResponse(response:any) { 
    return response.text().then( (text:any) => {
    
        const data = text && JSON.parse(text);
        
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function  handleResponseBinary(response:any) { 
    
    return response.arrayBuffer().then( (data:any) => {    
        //var binary = '';
        //var bytes = new Uint8Array( data );
        /*var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        */
        //const output =  base64.encode( binary );

        //const output =  btoa( binary );
        
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        
        //return bytes;

        return data;
        //return new Int32Array(data);
        
    });
}


