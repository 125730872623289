import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';

import { IDataSourceTableAliasRepository } from '../domain/IDataSourceTableAliasRepository';

import { GET_ALL_DATASOURCE_TABLEALIAS, GET_DATASOURCE_TABLEALIAS_BY_ID } from './query/dataSourceTableAliasQuery';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { IapDataSourceTableAlias } from '../domain/iapDataSourceTableAlias';
import {UPDATE} from './mutation/dataSourceTableAliasMutation'
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class DataSourceTableAliasApolloClientRepository implements IDataSourceTableAliasRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId=ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }

  async update (dataInput: IapDataSourceTableAlias): (Promise<IapDataSourceTableAlias>) {
    return new Promise<IapDataSourceTableAlias>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE,{data:dataInput},this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.dataSourceTableAliasMutation.update)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async getAll (maxregs: number | undefined): (Promise<IapDataSourceTableAlias[]>) {
    return new Promise<IapDataSourceTableAlias[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_ALL_DATASOURCE_TABLEALIAS, { maxregs: maxregs }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.DataSourceTableAliasQuery.getAll)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getById (id: number, version: number): (Promise<IapDataSourceTableAlias>) {
    return new Promise<IapDataSourceTableAlias>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_DATASOURCE_TABLEALIAS_BY_ID, { id: id, version: version }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.DataSourceTableAliasQuery.getById)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


}


