import BaseControlTypeConst from "./BaseControlTypeConst"

export default class ColumnTypeConst extends BaseControlTypeConst {
    static FIELD='field'
    static HEADER='header'
    static SORTABLE='sortable'

    static LAYOUT_COLUMN_BODY='layouttemp-col-body'
    static LAYOUT_COLUMN_EDITOR='layouttemp-col-editor'
    static LAYOUT_COLUMN_FILTER='layouttemp-col-filter'
    static LAYOUT_COLUMN_HEADER='layouttemp-col-header'

}
