
//import ApiNostromo from '../../servicios/ApiNostromo';
import LocalService from '../../servicios/LocalService';
import { Actions, Mutations } from '../enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
//import { requestBase } from '../../interfaces/nostromo/service/generic/requestBase';

@Module
export default class ConfigurationModule extends VuexModule {
    get getKey(): any {
        return function (key: string) {
            const cfg = LocalService.getValue(LocalService.CONF) ?? '[]';
            if (cfg) {
                const data = JSON.parse(cfg) as any;
                const filterData = data.filter((x: { id: string; })  => x.id == key);
                // si no se encuentra la ruta no se tiene acceso
                if (filterData.length > 0) {
                    return filterData[0].description;
                }
            }

            return '';
        };
    }

    @Mutation
    [Mutations.SET_CONFIG](data:any) {
        LocalService.setValue(LocalService.CONF, JSON.stringify(data));
    }

    @Action
    [Actions.GET_CONFIG]() {
        /*
        TODO
        return new Promise<void>((resolve, reject) => {
            const request: requestBase = {
                sessionID: this.context.getters.sessionID, //store.getters.sessionID
            };

            ApiNostromo.post('/configuration/getConfigurationPortal', request as any)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_CONFIG, data.data);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
        */
    }
}
