<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"            
            />
    <Divider v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" :key="componentKey"
        :id="getPropertyValue(DividerTypeConst.ID)" v-tooltip="getPropertyValue(DividerTypeConst.TOOLTIP)"
        :style="getPropertyValue(DividerTypeConst.STYLE)" :class="getPropertyValue(DividerTypeConst.CLASS)"
        :name="getPropertyValue(DividerTypeConst.NAME)" :disabled="getPropertyBooleanValue(DividerTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(DividerTypeConst.VISIBLE)"
        :placeholder="getPropertyValue(DividerTypeConst.PLACEHOLDER)" :align="getCatalogValue(DividerTypeConst.ALIGN)"
        :layout="getCatalogValue(DividerTypeConst.LAYOUT)" :type="getCatalogValue(DividerTypeConst.TYPE)" v-model="vmodel">        
    </Divider>
</template>
<script lang="ts">


import { defineComponent, onMounted, onUnmounted } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import DividerTypeConst from '../../../../domain/Constants/DividerTypeConst';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';	

export default defineComponent({
    name: 'dynamic_divider',
    components: {
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        slotProps: {
            type: Object,
            default: () => ({})
        },

    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded,  resolveExpressions, 
            getCatalogValue, baseOnUnmount,fieldKey,fieldKeyComponentDataSourceId ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);





        onMounted(() => {
            // resolveExpressions();  
        })

        onUnmounted(() => {
            baseOnUnmount();
        })


        return {

            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            DividerTypeConst,
            loaded,
            BaseControlTypeConst,
            getCatalogValue,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey

        };
    },
});
</script>
<style scoped></style>
