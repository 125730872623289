import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_openBlock(), _createBlock(_component_Dropdown, {
      key: _ctx.componentKey,
      modelValue: _ctx.$i18n.locale,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
      options: _ctx.languages,
      optionValue: "id",
      optionLabel: "description",
      filter: "",
      showClear: ""
    }, null, 8 /* PROPS */, ["modelValue", "options"]))
  ], 64 /* STABLE_FRAGMENT */))
}