import { ClassicPreset as Classic } from 'rete';


export class StartNode extends Classic.Node<{}, { ejecutar: Classic.Socket }, {}> {
    width = 180;
    height = 90;
    
  
    constructor(socket:Classic.Socket) {
      super("Inicio");
      this.addOutput("ejecutar", new Classic.Output(socket, "Ejecutar"));
    }
  
    execute(_: never, forward: (output: "ejecutar") => void) {
      forward("ejecutar");
    }
    data() {
      return {};
    }
      
  }
