import { ClassicPreset } from "rete";
import { MessageService } from '../../../../../../../common/infrastructure/servicios';

export class MessageHideNode extends ClassicPreset.Node<
  { ejecutar: ClassicPreset.Socket },
  { ejecutar: ClassicPreset.Socket },
  {  }
> {
  width = 130;
  height = 130;

  
  constructor(socket:ClassicPreset.Socket) {
    super("Hide Message");
    

    this.addInput("ejecutar", new ClassicPreset.Input(socket, "Ejecutar", true));

    this.addOutput("ejecutar", new ClassicPreset.Output(socket, "Ejecutar"));
  }

 
 
  execute(input: "ejecutar", forward: (output: "ejecutar") => void) {    
    MessageService.hideAllMessages()
    forward("ejecutar");

   


}
data() {
    return {};
  }
}