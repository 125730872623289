import BaseControlTypeConst from "./BaseControlTypeConst"

export default class CrudDocumentTypeConst extends BaseControlTypeConst {
  
    static CAPTION='caption'
    static FILEEXTENSIONS='fileExtensions'
    static MAXFILESIZE='maxFileSize'
    static MULTIPLE='multiple'
    static IDOBJECT='idObject'
    static OBJECTID='objectId'
    static CAPTIONDROPAREA='captionDropArea'
    static CANADD='canAdd'
    static CANEDIT='canEd'
    static CANDEL='canDel'
    static ONLYLISTDOCUMENT='onlyListDocument'
    static SOURCE='documentConnection'
    static SHOW_POPUP_ADD='showAddinPopUp'
    static ENT_MAIN_INT_ID ='entityMainIntegracionId'
  }
  