import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IHelpRepository } from '../domain/IHelpRepository';

import { IapHelp } from '../domain/iapHelp';
import { IServiceHelp } from './IServiceHelp';





@injectable()
export class HelpService implements IServiceHelp {
  private readonly repo: IHelpRepository

  public constructor (@inject(TYPES.HELP_REPOSITORY) _repo: IHelpRepository) {
    this.repo = _repo
  }

  async update (data: IapHelp): (Promise<IapHelp>) {
    return await this.repo.update(data);
  }

  async add (data: IapHelp): (Promise<IapHelp>) {
    return await this.repo.add(data);
  }

}
