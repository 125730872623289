import { createRouter, createWebHistory } from 'vue-router'
// import store from '@ilinium/shared/src/almacen/index';

const routes = [
  {
    path: '/',
    redirect: '/mipanel'
  },
  {
    path: '/mipanel',
    name: 'mipanel',
    meta: {
      breadcrumb: [{ parent: 'inicio.title', label: 'inicio.panel' }],
      directivas: '',
      builtin: '',
      contrato: ''
    },
    //component: () => import('./infrastructure/paginas/inicio/Panel.vue')
  },  
  {
    path: '/login',
    name: 'login',
    component: () => import('./infrastructure/paginas/login/Login.vue'),
  }

]

let router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  scrollBehavior () {
    window.scrollTo(0, 0)
    return 0
    // return { left: 0, top: 0 };
  }
})
// router.beforeEach((to, from, next) => {
router.beforeEach(async (to, next) => {
  /* .dispatch(Actions.VERIFY_AUTH);

    if (to.name !== 'login' && store.getters.isUserAuthenticated) {
        var canAccess = store.getters.hasAccess(to.name, routes);
        if (!canAccess) {
            next({
                path: 'login',
                replace: true,
            });
        }
    } */

})

export const initRouter = (routes) => {
  router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,

    scrollBehavior () {
      window.scrollTo(0, 0)
      return 0
      // return { left: 0, top: 0 };
    }
  })
}

export default router
