import { ClassicPreset as Classic, ClassicPreset } from 'rete';
import { DataflowNode } from "rete-engine";


export class EndNode extends Classic.Node<{ ejecutar: Classic.Socket },{}, {}> {
    width = 180;
    height = 90;
    
    private callBackEnd:any
  
    constructor(socket:Classic.Socket, callBackEndFunction:any=undefined) {
      super("Fin");
      this.callBackEnd = callBackEndFunction;

      this.addInput("ejecutar", new ClassicPreset.Input(socket, "Ejecutar", true));
    }
  
    execute(_: never, forward: (output: "ejecutar") => void) {
      //forward("ejecutar");
      
      if (this.callBackEnd){
        this.callBackEnd(true);
      }
      forward('ejecutar')
    }
    data() {
      return {};
    }
      
  }
