/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { Container } from "inversify"
import { IServiceDataUpdate } from "../../../entidades/builder/dataupdate/application/IServiceDataUpdate"
import { TYPES } from "../../domain/types"
import HelperLoading from "./HelperLoading"
import { DataUpdateResult } from '../../../entidades/builder/dataupdate/domain/dataUpdateResult';
import { DataUpdateOperation } from "../../../entidades/builder/dataupdate/domain/dataUpdateOperation"
import HelperDataUpdate from "../../../entidades/builder/dataupdate/infrastructure/helper/HelperDataUpdate"


export const doOperationData = async (container: Container | undefined,applicationId:number,aplicationVersion:number, data: DataUpdateOperation[], transactionOperation:boolean) => {
  return await new Promise<DataUpdateResult[] >((resolve, reject) => {
    if (container) {
      const _srv = container.get<IServiceDataUpdate>(TYPES.DATAUPDATE_REPOSITORY)
      HelperLoading.showSaving()
      _srv.update(applicationId ?? -1,aplicationVersion ?? -1, data, transactionOperation)
      .then(response => {
        if (HelperDataUpdate.hasErrorResponse(response)) {
           HelperDataUpdate.formatErrorResponse(response);
           resolve([]);
        }
        else{
          resolve(response);
        }
        
      })
        .catch(() => {
          reject(false);
        })
        .finally(() => {
          HelperLoading.hideSaving()
        })
    }
  });
}


export default { doOperationData}
