import HelperUtils from './funciones/HelperUtils'
function EventBusCustom() {
    const allHandlers = new Map();
    
    return {
        on(type, handler) {
            
            let handlers = allHandlers.get(type);

            if (!handlers) handlers = [handler];
            else handlers.push(handler);

            allHandlers.set(type, handlers);
        },

        off(type, handler) {            
            let handlers = allHandlers.get(type);

            if (handlers) {
                handlers.splice(handlers.indexOf(handler) >>> 0, 1);
            }

            if (handlers?.length == 0){
                allHandlers.delete(type);
            }
        },

        emit(type, evt) {
            
            let handlers = allHandlers.get(type);

            if (handlers) {
                handlers.slice().map((handler) => {
                    handler(evt);
                });
            }
        },
        clearAll(){            
            allHandlers.clear();
        },
        clearAllFormData(){                  
            let keys = allHandlers.keys();

            while (true) {
                let result = keys.next();
                if (result.done) break;

                if (HelperUtils.containUUID(result.value)){
                    allHandlers.delete(result.value);
                }            
            }

        
        },
    };
}

export default EventBusCustom()