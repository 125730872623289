/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
export default function ComponentUtil() {

  const nest = (items: any, id = null, link = 'parentId') =>
    items
      .filter((item: { [x: string]: any; }) => item[link] === id)
      //.sort((x: { [x: string]: any; }) => x['order'] ?? 0)
      .map((item: { id: any; }) => ({ ...item, children: nest(items, item.id).sort((a: { order: any; }, b: { order: any; }) => { return (a.order ?? 0) - (b.order ?? 0); }) }));



  const getTreeObject = (element: any, field: string, data = Array<any>()) => {
    if (element[field]) {
      data.push(element[field] as any);
      
      
    } 
    if (element.children != null) 
    {
      for (var i = 0; i < element.children.length; i++) {
        getTreeObject(element.children[i] as any, field, data);
      }
    }
    return data;
  }


  const getTreeFlat = (element: any, field: string, data = Array<any>()) => {
    
    if (element[field]) {
      data.push(element as any);
      
      
    } 
    if (element.children != null) 
    {
      for (var i = 0; i < element.children.length; i++) {
        getTreeFlat(element.children[i] as any, field, data);
      }
    }
    return data;
  }

  const convertTreeToFlat = (element: any, data = Array<any>()) => {
    
    
      data.push(element as any);
    
    if (element.children != null) 
    {
      for (var i = 0; i < element.children.length; i++) {
        convertTreeToFlat(element.children[i] as any,  data);
      }
    }
    return data;
  }
//@ts-ignore:disable-next-line  
  function searchTree (element: any, value: any, field = 'id') {
    
    if (element[field] == value) {
      return element;
    } else if (element.children != null) {
      var i;
      var result = null;
      for (i = 0; result == null && i < element.children.length; i++) {
        result = searchTree(element.children[i] as any, value, field);
      }
      return result;
    }
    return null;
  }

  function clearTreeFicticius(element: any) {
    if (element['children']) 
    {
      element['children'] = element['children'].filter((x:any) => x['children'] !== undefined);
      var i;
      var result = null;
      for (i = 0; result == null && i < element.children.length; i++) {
        clearTreeFicticius(element.children[i] as any);
      } 
    }      
  }

  return {
    nest,
    getTreeObject,
    getTreeFlat,
    convertTreeToFlat,
    searchTree,
    clearTreeFicticius
  }

}



