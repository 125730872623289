import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'
import type { IDocumentRepository } from '../domain/IDocumentRepository';
import { IServiceDocument } from './IServiceDocument';
import { StorageDocument } from '../domain/storageDocument';




@injectable()
export class ServiceDocument implements IServiceDocument {
  private readonly repo: IDocumentRepository

  public constructor (@inject(TYPES.STORAGE_REPOSITORY) _repo: IDocumentRepository) {
    this.repo = _repo
  }
  async delete (id: number,componentId:number,source:string) : Promise<boolean>{
    return await this.repo.delete(id,componentId,source)
  }
  async add (data: StorageDocument, contentFile: string,componentId:number,source:string) : Promise<StorageDocument>{
    return await this.repo.add (data, contentFile,componentId,source)
  }
    async update (data: StorageDocument, contentFile: string,componentId:number,source:string) : Promise<StorageDocument>{
        return await this.repo.update (data, contentFile,componentId,source)
    }
    async getById (id: number,componentId:number,source:string, withContent:boolean) : Promise<StorageDocument>{
        return await this.repo.getById (id,componentId,source,withContent)
    }
    async getByObjectId (idObject: string, objectId: number,componentId:number,source:string) : Promise<StorageDocument[]>{
        return await this.repo.getByObjectId (idObject, objectId,componentId,source)
    }
    async getContent (id: number,componentId:number,source:string) : Promise<string>{
        return await this.repo.getContent (id,componentId,source)
    }


}
