import BaseControlTypeConst from "./BaseControlTypeConst"

export default class FieldsetTypeConst extends BaseControlTypeConst {

    static LEGEND= 'legend'
    static TOGGLEABLE= 'toggleable'
    static COLLAPSED= 'collapsed'
    static LAYOUT_LEGEND='layouttemp-fieldset-legend'

}
