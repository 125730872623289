import HelperUtils from "../../../../../common/infrastructure/funciones/HelperUtils"
import { MessageService } from "../../../../../common/infrastructure/servicios"
import { MessageType } from "../../../../../common/infrastructure/servicios/MessageService"
import CatalogResponseSrvTypeConst from "../../../catalog/domain/const/CatalogResponseSrvTypeConst"
import { IapDataSourceServiceMethodResponseConfiguration } from "../../../datasource/domain/iapDataSourceServiceMethodResponseConfiguration"
import { DataUpdateResult } from "../../domain/dataUpdateResult"


const hasResponse = (data: DataUpdateResult[], cfg: IapDataSourceServiceMethodResponseConfiguration[], idResponseServiceType: string) => {
  const resultData = data.find(x => x != undefined)?.resultData ?? '{}'
  const oResultData = JSON.parse(resultData)
  const cfgData = cfg.filter(x => x.idResponseServiceType == idResponseServiceType && x.fieldName).map(x => HelperUtils.hasPropValue(oResultData, x.fieldName));
  return (data.find(x => x.error) !== undefined || cfgData.find(x => x == true) !== undefined)
}

// deprecated: se tiene que sustituir por hasResponse
const hasErrorResponse = (data: DataUpdateResult[]) => {
    const resultData = data.find(x => x != undefined)?.resultData ?? '{}'
  const oResultData = JSON.parse(resultData)

  
  return (data.find(x => x.error) !== undefined)
    //CatalogResponseSrvTypeConst
    //|| HelperUtils.hasPropValue('fault.pap.juan')
    || oResultData['fault']
    || oResultData['error']
    || oResultData['incidencias']
    || (oResultData['errors'] && JSON.stringify(oResultData['errors'] ?? '[]') !== '[]')
    || (oResultData['oData'] && oResultData['oData']['INCIDENCIAS'])
    || (oResultData['oData'] && (oResultData['oData']['DATOSSALIDA'] && oResultData['oData']['DATOSSALIDA']['ERRORES']))
}


const formatResponse = (data: DataUpdateResult[], cfg: IapDataSourceServiceMethodResponseConfiguration[], idResponseServiceType: string) => {

  const resultData = data.find(x => x != undefined)?.resultData ?? '{}'
  const oResultData = JSON.parse(resultData)
  const cfgData = cfg.filter(x => x.idResponseServiceType == idResponseServiceType && x.fieldName && HelperUtils.hasPropValue(oResultData, x.fieldName));
  let msg = data.filter(x => x.error && idResponseServiceType == CatalogResponseSrvTypeConst.ERR).map(x =>
  ({
    err: x.error,
    customErr: x.error?.split('#999#') ?? []

  })).map(z =>
    z.customErr.length == 3 ? z.customErr[1] : z.err

  ).join('.')

  cfgData.forEach(x => {
    msg += JSON.stringify(HelperUtils.propValue(oResultData, x.fieldName)) + '.'
  })



  if (msg) {

    let msgType = MessageType.Error;
    let msgCaption = 'Error de operación';

    switch (idResponseServiceType) {
      case CatalogResponseSrvTypeConst.WARN:
        msgType = MessageType.Aviso;
        msgCaption = 'Aviso';
        break;
      case CatalogResponseSrvTypeConst.INFO:
        msgType = MessageType.Info;
        msgCaption = '';
        break;
    }


    MessageService.showMessage(msgType, msgCaption, msg);
  }
}


// DEPRECATED: Se debe sustituir por formatResponse
const formatErrorResponse = (data: DataUpdateResult[]) => {

  const resultData = data.find(x => x != undefined)?.resultData ?? '{}'
  const oResultData = JSON.parse(resultData)

  let msg = data.filter(x => x.error).map(x =>
  ({
    err: x.error,
    customErr: x.error?.split('#999#') ?? []

  })).map(z =>
    z.customErr.length == 3 ? z.customErr[1] : z.err

  ).join('.')

  if (oResultData['error']) {
    msg += JSON.stringify(oResultData['error']) + '.'
  }

  if (oResultData['fault']) {
    msg += JSON.stringify(oResultData['fault']) + '.'
  }

  if ((oResultData['oData'] && oResultData['oData']['INCIDENCIAS'])) {
    msg += JSON.stringify(oResultData['oData']['INCIDENCIAS']) + '.'
  }

  if ((oResultData['oData'] && oResultData['oData']['DATOSSALIDA'] && oResultData['oData']['DATOSSALIDA']['ERRORES'])) {
    msg += JSON.stringify(oResultData['oData']['DATOSSALIDA']['ERRORES']) + '.'
  }


  if (oResultData['errors']) {
    msg += JSON.stringify(oResultData['errors'])
  }

  if (msg) {



    MessageService.showMessage(MessageType.Error, 'Error de operación', msg);
  }
}

export default { hasErrorResponse, formatErrorResponse, hasResponse, formatResponse }