import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: ComponentAttributeInputType!){
    componentAttributeMutation{
        updateComponentAttribute(data:$data){
            id
            componentId
            name
            description
            value
            order
            idType
            idDataType
            idAttributeType
            idModelValueType
            valueListId
            localizable
            mandatory
            valueBinaryFileName
            valueBinaryFileType
            valueBinaryString
            fcr
            ucr
            uum
            fum
        }
    }
}
`);

export const ADD = gql(`
mutation($data: ComponentAttributeInputType!){
    componentAttributeMutation{
        addComponentAttribute(data:$data){
            id
            componentId
            name
            description
            value
            order
            idType
            idDataType
            idAttributeType
            idModelValueType
            valueListId
            localizable
            mandatory
            valueBinaryFileName
            valueBinaryFileType
            valueBinaryString
            fcr
            ucr
            uum
            fum

        }
    }
}
`);

export const DELETE = gql(`
mutation($id: Guid!){
    componentAttributeMutation{
        deleteComponentAttribute(id:$id)
    }
}
`);


