import BaseControlTypeConst from "./BaseControlTypeConst"

export default class MenuItemTypeConst extends BaseControlTypeConst {

    static LABEL = 'label'
    static ICON = 'icon'
    static TO = 'to'
    static COMMAND = 'command'
    static URL = 'url'
    static ITEMS = 'items'
    static TARGET = 'target'
    static SEPARATOR = 'separator'
    static KEY = 'key'
    static DS_FIELD = 'fieldDataSource'


}
