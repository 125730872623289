import ChartTypeConst from "./ChartTypeConst"

export default class ChartBarTypeConst extends ChartTypeConst {

    static LABEL= 'label'
    static BACKGROUNDCOLOR= 'backgroundColor'
    static BORDERCOLOR= 'borderColor'
    static BORDERWIDTH= 'borderWidth'
    static TEXTCOLOR= 'textColor'
    static TEXTCOLORHORIZONTALDATA= 'textColorHorizontalData'
    static TEXTCOLORVERTICALDATA= 'textColorVerticalData'
    static BEGINATZERO= 'beginAtZero'
    static SURFACEHORIZONTALBORDER= 'surfaceHorizontalBorder'
    static SURFACEVERTICALBORDER= 'surfaceVerticalBorder'
    static LABELDATA= 'labelData'
    
  }