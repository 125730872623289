/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
import {  ref } from "vue";


export default function ExpressionTranslator(
    dataJson: any[],
) {

    const dataProperties: any = ref([]);


    //Esta función extrae todos los nodos data del arbol entero, objecto dataJSon recibido
    function extractDataProperties(dataArray: any) {

        if(dataArray!==undefined){
            dataArray.forEach((item: any) => {
                const dataProperty = item.data;
                if (dataProperty) {
                    dataProperties.value.push(dataProperty);
                }
                extractDataProperties(item.children);
            });
        }
        return dataProperties;
    };

    //Esta función extrae todos los nodos data del arbol entero, objecto dataJSon recibido
    extractDataProperties(dataJson) as any[];


    /*Devuelve el objeto donde se sitúa el valor recibido, dentro del objeto dataProperties que es un nuevo objto
    extraido de dataJson*/
    const getObjectValue = (value: string) => {

        const data=dataProperties.value.find((x:any) => x.value == value);
        return data?.path ??data?.label??'Sin Nombre' ;
    };

    //Devuelve un array de String con todos los valores entre corchetes del string pasado.
    function searchTextBetweenBrackets(input: string): string[] {
        const regex = /\[(.*?)\]/g;
        const matches: string[] = [];

        let match: RegExpExecArray | null;

        while ((match = regex.exec(input)) !== null) {
            matches.push(match[1]);
        }

        return matches;
    }


    //Va reemplazando todos los valores entre corchete del texto pasado por el valor indicado
    function replaceValuesInString(inputString: string): string {

        let resultString = inputString;

        let valuesToReplace: string[] = searchTextBetweenBrackets(inputString);

        valuesToReplace.forEach(value => {
            const regex = new RegExp(value, 'g'); // 'g' flag para reemplazar todas las ocurrencias
            resultString = resultString.replace(regex, getObjectValue('[' + value + ']'));
        });

        return resultString;
    };


    return {
        replaceValuesInString
    };
}
