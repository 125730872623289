import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: WorkFlowActivityInputType!){
    workFlowActivityMutation{
        updateWorkFlowActivity(data:$data){
            id
            workFlowId
            typeWorkFlowId
            name
            label
            color
            left
            top
            isInvalid
            canDelete
            fcr
            ucr
            uum
            fum
            group
            iapWorkFlowActivityControls{
                        id
                        workFlowActivityId
                        name
                        value
                        localizable
                        group
                    }                    

            iapWorkFlowActivityVariables{
                id
                workFlowActivityId
                name
                isInput
                defaultValue
                fcr
                ucr
                uum
                fum
                group
            }
        }
    }
}
`);

export const ADD = gql(`
mutation($data: WorkFlowActivityInputType!){
    workFlowActivityMutation{
        addWorkFlowActivity(data:$data){
            id
            workFlowId
            typeWorkFlowId
            name
            label
            color
            left
            top
            isInvalid
            canDelete
            group
            fcr
            ucr
            uum
            fum
            iapWorkFlowActivityControls{
                        id
                        workFlowActivityId
                        name
                        value
                        localizable
                        group
                    }                    

            iapWorkFlowActivityVariables{
                id
                workFlowActivityId
                name
                isInput
                defaultValue
                fcr
                ucr
                uum
                fum
                group
                iapWorkFlowActivityConnectionWorkFlowActivityVariableIdInputNavigations{
                        fcr
                        fum
                        id
                        ucr
                        uum
                        workFlowActivityVariableIdInput
                        workFlowActivityVariableIdOutput
                        externalId
                    }
                    iapWorkFlowActivityConnectionWorkFlowActivityVariableIdOutputNavigations{
                        fcr
                        fum
                        id
                        ucr
                        uum
                        workFlowActivityVariableIdInput
                        workFlowActivityVariableIdOutput
                        externalId
                    }
            }
        }
    }
}
`);



export const DELETE = gql(`
mutation($data: WorkFlowActivityInputType!){
    workFlowActivityMutation{
        deleteWorkFlowActivity(data:$data)
    }
}
`);