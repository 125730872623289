import { ClassicPreset } from 'rete';
import { AreaPlugin } from 'rete-area-plugin';
import { AreaExtra, Schemes } from '../rete/helperRete';

import { LocalService, MessageService } from '../../../../../../../common/infrastructure/servicios';
import { MessageType } from '../../../../../../../common/infrastructure/servicios/MessageService';
import { Router } from 'vue-router';
import { Container } from 'inversify';
import { SendMailControl } from '../controls/sendMailControl';
import {  DataflowEngine } from 'rete-engine';
import { IapComponent } from '../../../../../component/domain/iapComponent';
import CatalogObjectTypeConst from '../../../../../catalog/domain/const/CatalogObjectTypeConst';
import { IapWorkFlowActivityControl } from '../../../../domain/service/iapWorkFlowActivityControl';
import CatalogExpConst from '../../../../../catalog/domain/const/CatalogExpConst';
import { ExpresionEngine } from '../../../../../expression/infrastructure/helper/expressionEngine';
import { IapWorkFlowActivity } from '../../../../domain/service/iapWorkFlowActivity';
import EventConst from '../../../../../../../common/domain/constantes/EventConst';
import { Actions } from '@ilinium/shared/src/common/infrastructure/almacen/enums/StoreEnums';
import SendMailNodeConst from '../constants/SendMailNodeConst';
import HelperUtils from '../../../../../../../common/infrastructure/funciones/HelperUtils';
import OperationDataTypeConst from '../../../../../../../common/domain/constantes/OperationDataTypeConst';
import GuidConst from '../../../../../../../common/domain/constantes/guidConst';


export class SendMailNode extends ClassicPreset.Node<
  { ejecutar: ClassicPreset.Socket, dataInput: ClassicPreset.Socket },
  { ok: ClassicPreset.Socket, error: ClassicPreset.Socket },
  { value: SendMailControl }
> {
  height = 510;
  width = 380;

  //private value: Number;
  private msgOutput: string='';
  private keyEventComponent: string;
  private variableTo: string;
  private variableCc: string;
  private variableSubject: string;
  private variableBody: string;

  private area: AreaPlugin<Schemes, AreaExtra>;
  private updateNode: any;
  private rdControlId: '';

  private dataflow: DataflowEngine<Schemes>
  private getNodeInternalData: any;
  private showExpression: any;
  private router: Router;
  private container: Container | undefined;
  private rootComponentId: number;
  private currentComponentId: number;
  private store: any;
  private activity: IapWorkFlowActivity | undefined;
  private componentData: IapComponent[];
  private formKey:String;
  private currentElementKey:string;
  
  

  //constructor(area: AreaPlugin<Schemes, AreaExtra>, socket: ClassicPreset.Socket,  public applicationId: number, public applicationVersion: number, public variableComponentInput: number, public variableComponentHeaderInput: string, public variableComponentModalInput: boolean, public variableComponentParametersInput: [], updateNode: any = undefined) {
  constructor(area: AreaPlugin<Schemes, AreaExtra>, socket: ClassicPreset.Socket,public formKeyInput: String,public currentElementKeyInput:string,public rootComponentInputId: number, public currentComponentInputId: number, public applicationId: number, public applicationVersion: number, public variableToInput: string, public variableCcInput: string, public variableSubjectInput: string, public variableBodyInput: string, updateNode: any = undefined, getNodeInternalData: any = undefined, showExpressionFunction: any = undefined, router: Router, container: Container | undefined, storeInput: any, itemActivity: IapWorkFlowActivity | undefined, dataflow: DataflowEngine<Schemes>,componentDataInput: IapComponent[]) {
    super("Send Mail");

    this.area = area;
    this.updateNode = updateNode;
    this.variableTo = variableToInput;
    this.variableCc = variableCcInput;
    this.variableSubject = variableSubjectInput;
    this.variableBody = variableBodyInput;
    this.router = router;
    this.dataflow = dataflow;

    this.getNodeInternalData = getNodeInternalData;
    this.showExpression = showExpressionFunction;
    this.container = container;
    this.rootComponentId = rootComponentInputId;
    this.currentComponentId = currentComponentInputId;
    this.store = storeInput;
    this.activity = itemActivity;
    this.keyEventComponent = (rootComponentInputId ?? -1).toString() + '_' + EventConst.SHOWCOMPONENT;
    this.componentData = componentDataInput;
    this.formKey = formKeyInput;
    this.currentElementKey = currentElementKeyInput;

    const dsControl = new SendMailControl(formKeyInput,rootComponentInputId,currentComponentInputId,applicationId, applicationVersion, variableToInput, variableCcInput, variableSubjectInput, variableBodyInput, container,storeInput,itemActivity, this.updateData, this.getNode, this.showExp);

    this.rdControlId = (dsControl as any).id;

    this.addInput("ejecutar", new ClassicPreset.Input(socket, "Ejecutar", true));
    this.addControl(
      "value",
      dsControl

      //new ClassicPreset.InputControl("text", { initial })
    );
    this.addInput("dataInput", new ClassicPreset.Input(socket, "DataInput"));



    //area.update("control",dsControl.id)
    this.addOutput("ok", new ClassicPreset.Output(socket, "Ok"));
    this.addOutput("error", new ClassicPreset.Output(socket, "Error"));




  }

  showExp = (evt: any) => {
    if (this.showExpression) {
      return this.showExpression(evt)
    }
    return null;
  }

  getNode = (key: string) => {
    if (this.getNodeInternalData) {
      return this.getNodeInternalData(this.id, key, true, false)
    }
    return null;
  }

  updateData = (evt: any) => {

    //this.value = evt
    //@ts-ignore:disable-next-line
    this.controls.value[evt.key] = evt.data;
    //this.controls[evt.key].valueConnection = evt.data;

    this.area.update("control", this.rdControlId)


    if (this.updateNode) {
      this.updateNode(this.id, evt.key, evt.data, (evt?.operation ?? OperationDataTypeConst.UPDATE))
    }


  }

resolveExpressions = () => {
  const currentComp = this.componentData.find(x => x.id == this.currentComponentId)
  const WfData = currentComp?.workFlows?.find(w => w.id == this.activity?.workFlowId)

  this.activity?.iapWorkFlowActivityControls?.forEach((wcf: IapWorkFlowActivityControl) => {

    if (currentComp && wcf) {

      
      let keyControl = CatalogObjectTypeConst.ATTRCOMP + '#' + WfData?.objetoId + '#' + wcf.id.toString();

      let exps = currentComp.expressions?.filter(x =>
        x.idObjeto == CatalogObjectTypeConst.WFAC
        && x.idTypeExpression == CatalogExpConst.EXP_SET
        && x.iapExpressionDetails?.length > 0
        && x.objetoId == keyControl);

        if ((exps?.length ?? 0) == 0){
          keyControl = CatalogObjectTypeConst.EVTCOMP + '#' + WfData?.objetoId + '#' + wcf.id.toString();

          exps = currentComp.expressions?.filter(x =>
            x.idObjeto == CatalogObjectTypeConst.WFAC
            && x.idTypeExpression == CatalogExpConst.EXP_SET
            && x.iapExpressionDetails?.length > 0
            && x.objetoId == keyControl);
        }
      if (exps?.length > 0) {
        exps?.every(exp => {
          if (exp.iapExpressionDetails?.length > 0) {            
            const localData = LocalService.getValue(this.formKey + LocalService.COMPONENTS_EXP + (this.rootComponentId ?? -1).toString());
            const data = HelperUtils.jsonParse(localData,[])
            let resu = ExpresionEngine.resolveExpressions(exp.iapExpressionDetails, data as any, this.store)
            //resu = resu?.toString();
            if (resu) {
              if (Object.keys(resu).length == 0) {
                resu = resu?.toString();
              }
            }

            switch (wcf.name) {
              case SendMailNodeConst.VAR_TO_IN:
                // code block
                this.variableTo = resu
                break;
              case SendMailNodeConst.VAR_CC_IN:
                // code block
                this.variableCc = resu
                break;
              case SendMailNodeConst.VAR_SUBJECT_IN:
                // code block
                this.variableSubject = resu
                break;
              case SendMailNodeConst.VAR_BODY_IN:
                // code block
                this.variableBody = resu
                break;
              default:
              // code block
            }

          }
        })

      }

      
    }
  })
}

resolveInputs = (inputs:any) => {
  const msg = inputs?.message?.find((x:any) => x !== undefined);
  if (msg && Array.isArray(msg) && msg?.length > 0) {
    //this.msgOutput = '';
    
    this.variableTo = this.variableTo ?? '';
    //@ts-ignore:disable-next-line
    this.variableTo?.match(/#(.*?)#/g, '$1')?.forEach(m =>{
      //@ts-ignore:disable-next-line
      const _variable = m.replaceAll('#','')
      const _value = HelperUtils.getDeepvalueObjectByKey(msg[0],_variable)
      //@ts-ignore:disable-next-line
      if (_value[_variable]){
        //@ts-ignore:disable-next-line
        this.variableTo = this.variableTo.replaceAll(m,_value[_variable])
      }
    })

      this.variableCc = this.variableCc ?? '';
       //@ts-ignore:disable-next-line
       this.variableCc?.match(/#(.*?)#/g, '$1')?.forEach(m =>{
        //@ts-ignore:disable-next-line
        const _variable = m.replaceAll('#','')
        const _value = HelperUtils.getDeepvalueObjectByKey(msg[0],_variable)
        //@ts-ignore:disable-next-line
        if (_value[_variable]){
          //@ts-ignore:disable-next-line
          this.variableCc = this.variableCc.replaceAll(m,_value[_variable])
        }
      })


        this.variableSubject = this.variableSubject ?? '';
       //@ts-ignore:disable-next-line
       this.variableSubject?.match(/#(.*?)#/g, '$1')?.forEach(m =>{
        //@ts-ignore:disable-next-line
        const _variable = m.replaceAll('#','')
        const _value = HelperUtils.getDeepvalueObjectByKey(msg[0],_variable)
        //@ts-ignore:disable-next-line
        if (_value[_variable]){
          //@ts-ignore:disable-next-line
          this.variableSubject = this.variableSubject.replaceAll(m,_value[_variable])
        }
      })

        this.variableBody = this.variableBody ?? '';
          //@ts-ignore:disable-next-line
          this.variableBody?.match(/#(.*?)#/g, '$1')?.forEach(m =>{
            //@ts-ignore:disable-next-line
            const _variable = m.replaceAll('#','')
            const _value = HelperUtils.getDeepvalueObjectByKey(msg[0],_variable)
            //@ts-ignore:disable-next-line
            if (_value[_variable]){
              //@ts-ignore:disable-next-line
              this.variableBody = this.variableBody.replaceAll(m,_value[_variable])
            }
          })
  }
}

  async execute(_: never, forward: (output: "ok" | "error") => void) {
    
    const inputs = (await this.dataflow.fetchInputs(this.id)) as {
      dataInput: string[];
    };



    this.resolveInputs(inputs)
    this.resolveExpressions();

    if (this.variableTo != '') {
        
        this.store.dispatch(Actions.SENDMAIL, [this.store.getters.getCurrentUser.id, this.variableTo, this.variableCc, this.variableSubject, this.variableBody, [], this.container])
            .then((response:any) => {
                if (response && ((response ??  GuidConst.EMPTY) !==  GuidConst.EMPTY)) {
                    forward("ok");
                    //MessageService.showMessage(MessageType.Correcto, '', 'Correo enviado correctamente.');
                }
                else {
                    forward("error");
                    //MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al enviar el correo.')
                }
            })
            .finally(() => {
                //isLoading.value = false;
                //HelperLoading.hideLoading();
                
            }).catch((e:any) => {
                forward("error");
                MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al enviar el correo.')
                //console.log(e);
                throw new Error(e);
            });
    }
    else {
      forward("error");
    }
    

  }


  data() {
    return {};
  }
}