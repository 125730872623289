/* eslint-disable prefer-regex-literals */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
// import ApiAuth from '../servicios/ApiAuth'
import store from '../almacen'
import { Actions } from '../almacen/enums/StoreEnums'
import CookieService, { SEGURPASS } from '../servicios/CookieService'
import MessageService, { MessageType } from '../servicios/MessageService'
// import HelperSecurity from './HelperSecurity'
import html2canvas from 'html2canvas'
import { Router } from 'vue-router'
import { TYPES } from '../../domain/types'
import { IServiceAuth } from '../../../entidades/builderMaster/auth/application/IServiceAuth'
import { Container } from 'inversify'
import AppCache from './appCache';
import Environment from './environment';
import EventBusCustom from '../event-bus-custom';
import CompsQueue from './compsQueue'
import InteractionConst from '../../../entidades/builder/interaction/domain/interactionConst'
import { InteractionEvent } from '../../../entidades/builder/interaction/domain/interactionEvent'

const salir = (container: Container, router: Router): void => {
  //  HelperSecurity.logout(sessionId)
  MessageService.showMessage(MessageType.Info, 'Cerrando entorno', 'Por favor espere...', false)
  const _srvAuth = container.get<IServiceAuth>(TYPES.AUTH_SERVICE)
  _srvAuth.logout().finally(() => {
    store.dispatch(Actions.LOGOUT).then(() => {
      CookieService.destroy(SEGURPASS)
      MessageService.hideAllMessages()
      AppCache.clearCache()
      Environment.reloadInitialAppData();
      CompsQueue?.getAll()?.forEach(keyEventComponentRoot=>{
        var data: Partial<InteractionEvent> = {
          typeId: InteractionConst.DESTROY_ROOT_COMPONENTS,
          objectValue: keyEventComponentRoot
        };

        EventBusCustom.emit(keyEventComponentRoot, { data: data, callBackResponse: undefined });
      });

      CompsQueue?.clearAll();
      


      EventBusCustom.clearAllFormData();
      if (router) {
        router.push({ name: 'login' })
      }
    }).finally(() => {
      router.push({ name: 'login' })
    }).catch(() => {
      window.location.href = '/login';
    })

  })
}

const getWhoAmIProfiles = async (container: Container): Promise<any> => {
  return await new Promise<void>((resolve, reject) => {
    const _srvAuth = container.get<IServiceAuth>(TYPES.AUTH_SERVICE)
    _srvAuth.whoAmIProfiles()
      .then((response: any) => {
        //this.context.commit(Mutations.SET_WHOAMIPROFILES, [this, response])
        resolve(response)
      })
      .catch(() => {
        reject()
      })
  })
}

const formateaFechaHoraLarga = (value: string | number | Date) => {
  if (!value) {
    return null
  }
  const date = new Date(value)
  return date.toLocaleString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })
}

const formateaFechaHora = (value: string | number | Date,dayFirst:boolean=false) => {
  if (!value) {
    return null
  }
  const date = new Date(value)

  if(!dayFirst){
    return ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
    ("00" + date.getDate()).slice(-2) + "/" +
    date.getFullYear() + " " +
    ("00" + date.getHours()).slice(-2) + ":" +
    ("00" + date.getMinutes()).slice(-2) + ":" +
    ("00" + date.getSeconds()).slice(-2);
  }
  else
  {
    return ("00" + date.getDate()).slice(-2) + "/" +
    ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
    date.getFullYear() + " " +
    ("00" + date.getHours()).slice(-2) + ":" +
    ("00" + date.getMinutes()).slice(-2) + ":" +
    ("00" + date.getSeconds()).slice(-2);
  }


}
const formateaFecha = (value: string | number | Date) => {
  if (!value) {
    return null
  }
  const date = new Date(value)
  return date.toLocaleDateString('es-ES', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  })
}

const diferenciaFechasEnDias = (fecha1: number, fecha2: number) => {
  const diffInMs = Math.abs(fecha2 - fecha1)
  return Math.ceil(diffInMs / (1000 * 60 * 60 * 24))
}

const obtenerTextoMensajes = (response: { messages: never[] }) => {
  const msgs = response.messages ?? []
  const msgStr = msgs.map((value: { description: any }) => value.description).join(',')
  return msgStr
}

const TestServiceMail = async (sessionId: string): Promise<boolean> => {
  return await new Promise<boolean>((resolve, reject) => {
    /* ApiAuth.post('/test/testServiceMail?sessionID=' + sessionId, {})
      .then((response) => {
        resolve(response.data)
      })
      .catch(() => {
        reject()
      }) */
  })
}

const getDomUploader = (dv: { currentTarget: any }, options: string | any[] | null) => {
  const root = dv.currentTarget ?? dv
  Array.prototype.forEach.call(root.getElementsByClassName('p-fileupload-filename'), function (element) {
    if (element.previousSibling.innerHTML.indexOf('<option') === -1) {
      const div = document.createElement('div')

      const input = document.createElement('input')
      input.className = 'p-inputtext p-component'
      input.id = 'description_' + element.innerText
      input.placeholder = 'Descripción'
      input.title = 'Descripción'
      div.append(input)
      insertAfter(div, element.previousSibling)

      if (options != null) {
        const selectList = document.createElement('select')
        selectList.id = 'type_' + element.innerText
        selectList.className = 'p-dropdown p-inputtext p-placeholder'
        selectList.title = 'Tipo de documento'
        selectList.setAttribute('pepe', '')

        for (let i = 0; i < options.length; i++) {
          const option = document.createElement('option')
          option.value = options[i].id
          option.text = options[i].description
          selectList.appendChild(option)
        }

        insertAfter(selectList, element.previousSibling)
      }
    }
  })
}

const insertAfter = (newElement: HTMLDivElement | HTMLSelectElement, referenceElement: { parentNode: { insertBefore: (arg0: any, arg1: any) => void }, nextSibling: any }) => {
  referenceElement.parentNode.insertBefore(newElement, referenceElement.nextSibling)
}

const toBase64 = async (file: Blob) =>
  await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = (error) => { reject(error) }
  })

const toText = async (file: Blob) =>
  await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = (error) => { reject(error) }
  })

const toArrayBuffer = async (file: Blob) =>
  await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = (error) => { reject(error) }
  })

const formateaMoneda = (valor: number | bigint,includeZero:boolean=false) => {
  if (!valor && !includeZero) {
    return null
  }
  const formatter = new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' })
  //return formatter
  return formatter.format(valor)
}


const formateaPorcentaje = (valor: number | bigint) => {
  if (!valor) {
    return null
  }

  return (parseFloat(valor?.toString())*100).toFixed(2) + " %";
}

const navegarAlDocumento = (url: string) => {
  window.open(url, '_blank')
}

const validarEmail = (email: string | null) => {
  if (typeof email === 'undefined' || email === null || email === '') {
    return true
  }
  return /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*;{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,10})[\W]*$/.test(email)
}
function texoEstaVacio(texto: { match: (arg0: RegExp) => null } | null) {
  return texto === null || texto.match(/^ *$/) !== null
}
function onlyUnique(value: any, index: any, self: string | any[]) {
  return self.indexOf(value) === index
}
function cambiarLuminosidadColor(hex: string | any[], lum: number) {
  // esta funcion devuelve un código de color un % mas oscuro o claro del color indicado.
  // hex: el código hexadecimal del color (ej: #123456)
  // lum: el factor de luminosidad, es decir, -0,1 es un 10 % más oscuro, 0,2 es un 20 % más claro, etc.
  lum = lum || 0
  hex = String(hex).replace(/[^0-9a-f]/gi, '')
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  let rgb = '#'
  let c
  let i
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16)
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
    rgb += ('00' + c).substr(c.length)
  }

  return rgb
}

const validarIban = (iban: string | any[] | any | null) => {
  if (typeof iban === 'undefined' || iban === null || iban === '') {
    return false
  }

  if (iban.length < 20) {
    return false
  }

  return /^ES\d{22}$/.test(iban)
}

const toNumber = (valor: string | null) => {
  if (typeof valor === 'undefined' || valor === null || valor === '') {
    return 0
  }

  return Number(valor.replace('.', '').replace(',', '.'))
}

const getCurrentDateTimeLongFormat = () => {
  return new Date().toLocaleDateString('es-ES', {
    weekday: 'long',
    hour12: false,
    hour: 'numeric',
    minute: 'numeric'
  })
}

const stringToBoolean = (stringValue: string) => {
  switch (stringValue?.toLowerCase()?.trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true

    case 'false':
    case 'no':
    case '0':
    case '':
    case null:
    case undefined:
      return false

    default:
      return JSON.parse(stringValue)
  }
}

const setFocusOnFirstError = () => {
  setTimeout(() => {
    for (let i = 0; i < document.getElementsByClassName('p-invalid').length - 1; i++) {
      const ctrl = document.getElementsByClassName('p-invalid')[i] as any
      ctrl.focus()
      ctrl.scrollIntoView()
    }
  }
    , 100)
}

const isNullOrWhitespace = (input: string) => {
  try {
    return !input || !input.trim()
  } catch {
    return false
  }
}

const isNullOrNotDefined = (input: any) => {
  
    return (input == undefined || input == null)
  
}

const prettifyXml = (sourceXml: string) => {
  try {
    const xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml')
    const xsltDoc = new DOMParser().parseFromString([
      // describes how we want to modify the XML - indent everything
      '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
      '  <xsl:strip-space elements="*"/>',
      '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
      '    <xsl:value-of select="normalize-space(.)"/>',
      '  </xsl:template>',
      '  <xsl:template match="node()|@*">',
      '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
      '  </xsl:template>',
      '  <xsl:output indent="yes"/>',
      '</xsl:stylesheet>'
    ].join('\n'), 'application/xml')

    const xsltProcessor = new XSLTProcessor()
    xsltProcessor.importStylesheet(xsltDoc)
    const resultDoc = xsltProcessor.transformToDocument(xmlDoc)
    const resultXml = new XMLSerializer().serializeToString(resultDoc)
    return resultXml
  } catch (ex) {
    return sourceXml
  }
}

const getExtensionFile = (fileName: string) => {
  if (fileName) {
    const regex = new RegExp('[^.]+$')
    return fileName.match(regex)
  }

  return ''
}

const getCanvas = async (elementId: string): Promise<any> => {
  return await new Promise<any>((resolve, reject) => {
    const el = document.getElementById(elementId)

    if (el != null) {
      const options = {
        // type: 'dataURL',
        logging: true,
        backgroundColor: 'white',
        foreignObjectRendering: false,
        removeContainer: true,
        allowTaint: true,
        useCORS: true
      } as any
      html2canvas(el, options).then(data => {
        resolve(data)
      })
        .catch(() => {
          reject()
        })
    } else {
      resolve(null)
    }
  })
}

const getBase64FromUrl = async (url: string) => {
  const img = new Image()
  img.setAttribute('crossOrigin', 'anonymous')

  return await new Promise((resolve) => {
    img.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext('2d')
      if (ctx != null) {
        ctx.drawImage(img, 0, 0)
        const dataURL = canvas.toDataURL('image/png')
        resolve(dataURL)
      } else {
        resolve('')
      }
    }
    img.src = url
  })
}

const copyToClipBoard = (value: string) => {
  navigator.clipboard.writeText(value);
  MessageService.showToast(MessageType.Correcto, '', 'Se han copiado "' + value + '" al portapapeles.');
}

const evalMaxLength = (input: string,maxLength: number) => {
  return input.length <= maxLength;
}
const evalMinLength = (input: string,minLength: number) => {
  return input.length >= minLength;
}
const evalMaxValue = (input: number, maxValue: number) => {
  return input <= maxValue;
}
const evalMinValue = (input: number, minValue: number) => {
  return input >= minValue;
}
const evalUrl=(url: string): boolean=> {
  const regex = /^(ftp|http|https):\/\/[^ "]+$/;
  return regex.test(url);
}

const evalAlpha=(input: string): boolean=> {
  return /^[a-zA-Z]+$/.test(input);
}

const evalAlphaNum=(input: string): boolean =>{
  return /^[a-zA-Z0-9]+$/.test(input);
}

const evalNumeric=(input: string): boolean=> {
  return /^[0-9]+(\.[0-9]+)?$/.test(input);
}

const evalInteger=(input: string): boolean=> {
  return /^[0-9]+$/.test(input);
}

const evalDecimal=(input: string): boolean=> {
  return /^[0-9]+$/.test(input);
}

const isValidIpAddress=(ip: string): boolean =>{
  const ipv4Pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return ipv4Pattern.test(ip);
}

const evalBetween=(value: number, min: number, max: number): boolean=>{
  return value >= min && value <= max;
}

const evalSameAs=(str1: string, str2: string): boolean=> {
  return str1.toLowerCase() === str2.toLowerCase();
}

function compareArrays(arr1:any, arr2:any) {
  if ((arr1?.length ?? 0) !== (arr2?.length ?? 0)) {
      return false;
  }
  if((arr1?.length ?? 0) ==0 && (arr2?.length ?? 0)==0){
      return true;
  }

  return arr1?.every((element:any) => arr2?.includes(element));
}

const currentMonthDates = (): { first: Date, last: Date } => {
  const currentDate = new Date();
  const dateFirst = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  dateFirst.setHours(0, 0, 0, 0); 
  const dateLast = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  dateLast.setHours(23, 59, 59, 999); 


  return { first: dateFirst, last: dateLast };
}

const previousMonthDates = (): { first: Date, last: Date } => {
  const currentDate = new Date();
  const firstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const firstDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
  firstDayOfPreviousMonth.setMonth(firstDayOfPreviousMonth.getMonth() - 1);
  firstDayOfPreviousMonth.setHours(0, 0, 0, 0);
 
  const lastDayOfPreviousMonth = new Date(firstDayOfPreviousMonth.getFullYear(), firstDayOfPreviousMonth.getMonth() + 1, 0);
  lastDayOfPreviousMonth.setHours(23, 59, 59, 999); 

  return { first: firstDayOfPreviousMonth, last: lastDayOfPreviousMonth };

}

const currentYearDates = (): { first: Date, last: Date } => {
  const currentDate = new Date();

  const dateFirst = new Date(currentDate.getFullYear(), 0, 1);
  dateFirst.setHours(0, 0, 0, 0); 
  const dateLast = new Date(currentDate.getFullYear(), 11, 31);
  dateLast.setHours(23, 59, 59, 999); 
  return { first: dateFirst, last: dateLast };
}

const previousYearDates = (): { first: Date, last: Date } => {
  const currentDate = new Date();

  const dateFirst = new Date(currentDate.getFullYear() - 1, 0, 1);
  dateFirst.setHours(0, 0, 0, 0); 
  const dateLast = new Date(currentDate.getFullYear() - 1, 11, 31);
  dateLast.setHours(23, 59, 59, 999); 
  return { first: dateFirst, last: dateLast };
}


const currentWeekDates = (): { first: Date, last: Date } => {

  const today = new Date(); 
  const startOfWeek = new Date(today);
  const endOfWeek = new Date(today);

  const dayOfWeek = today.getDay();
  
  const daysToMonday = (dayOfWeek + 6) % 7;
  
  startOfWeek.setDate(today.getDate() - daysToMonday);
  startOfWeek.setHours(0, 0, 0, 0); 

  endOfWeek.setDate(startOfWeek.getDate() + 6);
  endOfWeek.setHours(23, 59, 59, 999); 

  return { first: startOfWeek, last: endOfWeek  };
 
}

const previousWeekDates = (): { first: Date, last: Date } => {

  const today = new Date(); 
  const startOfWeek = new Date(today);
  const endOfWeek = new Date(today);

  const dayOfWeek = today.getDay();
  
  const daysToMonday = (dayOfWeek + 6) % 7;

  startOfWeek.setDate(today.getDate() - daysToMonday);
  startOfWeek.setHours(0, 0, 0, 0); 

  startOfWeek.setDate(startOfWeek.getDate() - 7);
  
  endOfWeek.setDate(startOfWeek.getDate() + 6);
  endOfWeek.setHours(23, 59, 59, 999); 
  return { first: startOfWeek, last: endOfWeek   };
 
}



const daysAgoDates = (days:number): { first: Date | null, last:  Date | null}  => {

  let first:Date | null=null;
  let last:Date | null=null;

  if(days){
    const now = new Date();

    const daysAgo = new Date();
    daysAgo.setDate(now.getDate() - days);
  
    first = new Date(daysAgo);
    first.setHours(0, 0, 0, 0);
  
    last = new Date(daysAgo);
    last.setHours(23, 59, 59, 999);
  
    
  }
  
  return { first, last };
}


function convertVarbinary(str: string): string {
  return btoa(str);  
}



export default {
  salir,
  getWhoAmIProfiles,
  formateaFecha,
  formateaFechaHoraLarga,
  diferenciaFechasEnDias,
  obtenerTextoMensajes,
  TestServiceMail,
  getDomUploader,
  toBase64,
  toText,
  toArrayBuffer,
  formateaMoneda,
  formateaPorcentaje,
  navegarAlDocumento,
  validarEmail,
  texoEstaVacio,
  onlyUnique,
  cambiarLuminosidadColor,
  formateaFechaHora,  
  validarIban,
  toNumber,
  getCurrentDateTimeLongFormat,
  stringToBoolean,
  setFocusOnFirstError,
  isNullOrWhitespace,
  prettifyXml,
  getExtensionFile,
  getCanvas,
  getBase64FromUrl,
  copyToClipBoard,
  evalMaxLength,
  evalMinLength,
  evalMaxValue,
  evalMinValue,
  evalUrl,
  evalAlpha,
  evalAlphaNum,
  evalNumeric,
  evalInteger,
  evalDecimal,
  isValidIpAddress,
  evalBetween,
  evalSameAs,
  compareArrays,
  isNullOrNotDefined,
  currentMonthDates,
  previousMonthDates,
  currentYearDates,
  previousYearDates,
  currentWeekDates,
  previousWeekDates,
  daysAgoDates
  ,convertVarbinary


}
