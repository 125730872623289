import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IComponentAttributeEventRepository } from '../domain/IComponentAttributeEventRepository';
import { IServiceComponentAttributeEvent } from './IServiceComponentAttributeEvent';
import { IapComponentAttributeEvent } from '../domain/iapComponentAttributeEvent';



@injectable()
export class ComponentAttributeEventService implements IServiceComponentAttributeEvent {
  private readonly repo: IComponentAttributeEventRepository

  public constructor (@inject(TYPES.COMPONENT_ATTRIBUTE_REPOSITORY) _repo: IComponentAttributeEventRepository) {
    this.repo = _repo
  }

  async update (data: IapComponentAttributeEvent): (Promise<boolean>) {
    return await this.repo.update(data);
  }

  async add (data: IapComponentAttributeEvent): (Promise<boolean>) {
    return await this.repo.add(data);
  }


}
