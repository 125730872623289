import BaseControlTypeConst from "./BaseControlTypeConst"

export default class TabPanelTypeConst extends BaseControlTypeConst {
    static HEADER = 'header'
    static LAYOUT_HEADER ='layouttemp-tabpnl-header'   
    static LAYOUT_DEFAULT ='layouttemp-tabpnl-default'   

    static CMD_CONTENT='commandContent'

}
