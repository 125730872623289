import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: ComponentEventInputType!){
    componentEventMutation{
        updateComponentEvent(data:$data){
            id
            name
            description
            idEventType
            componentId
            active
            fcr
            ucr
            uum
            fum
        }
    }
}
`);

export const ADD = gql(`
mutation($data: ComponentEventInputType!){
    componentEventMutation{
        addComponentEvent(data:$data){
            id
            name
            description
            idEventType
            componentId
            active
            fcr
            ucr
            uum
            fum
        }
    }
}
`);


export const DELETE = gql(`
mutation($id: Guid!){
    componentEventMutation{
        deleteComponentEvent(id:$id)
    }
}
`);





