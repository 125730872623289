import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IComponentRepository } from '../domain/IComponentRepository';



import { IServiceComponent } from './IServiceComponent';
import { IapComponent } from '../domain/iapComponent';
import { IapComponentFile } from '../domain/iapComponentFile';





@injectable()
export class ComponentService implements IServiceComponent {
  private readonly repo: IComponentRepository

  public constructor (@inject(TYPES.COMPONENT_REPOSITORY) _repo: IComponentRepository) {
    this.repo = _repo
  }
    
  async sort (data: IapComponent[]): (Promise<boolean>) {
    return await this.repo.sort(data);
  }
  async delete(id: number) :Promise<boolean> {
    return await this.repo.delete(id)
  }

  async update (data: IapComponent): (Promise<IapComponent>) {
    return await this.repo.update(data);
  }

  async add (data: IapComponent): (Promise<IapComponent[]>) {
    return await this.repo.add(data);
  }

  async getAll(maxregs: number | undefined) :Promise<IapComponent[]> {
    return await this.repo.getAll(maxregs)
  }

  async getById(id: number) :Promise<IapComponent> {
    return await this.repo.getById(id)
  }

  async getTreeById(applicationId: number, applicationVersion: number, componentId: number) :Promise<IapComponent[]> {
    return await this.repo.getTreeById(applicationId, applicationVersion, componentId)
  }

  async getTreeAllObjectsById(applicationId: number, applicationVersion: number, componentId: number,withAllAtributtes:boolean) :Promise<IapComponent[]> {
    return await this.repo.getTreeAllObjectsById(applicationId, applicationVersion, componentId,withAllAtributtes)
  }
  async getParents(applicationId: number, applicationVersion: number) :Promise<IapComponent[]> {
    return await this.repo.getParents(applicationId, applicationVersion)
  }

  async getSimpleTreeById(applicationId: number, applicationVersion: number, componentId: number) :Promise<IapComponent[]> {
    return await this.repo.getSimpleTreeById(applicationId, applicationVersion, componentId)
  }

  async getFile(applicationId: number, applicationVersion: number, componentId: number) :Promise<IapComponent[]> {
    return await this.repo.getFile(applicationId, applicationVersion, componentId)
  }
  //: () => Promise<IapComponent[]>;

  async getAppLayoutComponent(applicationId: number, applicationVersion: number) :Promise<IapComponent[]> {
    return await this.repo.getAppLayoutComponent(applicationId, applicationVersion)
  }

  async addComponentFile(data:IapComponentFile[]) :Promise<IapComponentFile[]> {
    return await this.repo.addComponentFile(data)
  }
}
