<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"            
            />
    <Chip v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" :key="componentKey"
        :id="getPropertyValue(ChipTypeConst.ID)" v-tooltip="getPropertyValue(ChipTypeConst.TOOLTIP)"
        :style="getPropertyValue(ChipTypeConst.STYLE)"
        :class="{ [getPropertyValue(ChipTypeConst.CLASS) ?? '']: true, 'customrequired': getPropertyBooleanValue(ChipTypeConst.REQUIRED),'p-readonly': getPropertyBooleanValue(ChipTypeConst.READONLY) }"
        :name="getPropertyValue(ChipTypeConst.NAME)" :disabled="getPropertyBooleanValue(ChipTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(ChipTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" :placeholder="getPropertyValue(ChipTypeConst.PLACEHOLDER)"
        :separator="getPropertyValue(ChipTypeConst.SEPARATOR)" :addOnBlur="getPropertyBooleanValue(ChipTypeConst.ADDONBLUR)"
        :allowDuplicate="getPropertyBooleanValue(ChipTypeConst.ALLOWDUPLICATE)"
        :max="getPropertyNumberValue(ChipTypeConst.MAX)" :inputId="getPropertyValue(ChipTypeConst.INPUTID)"
        :readonly="getPropertyBooleanValue(ChipTypeConst.READONLY) || !canDoOperation(ObjectGroupConst.EDIT)"
        v-model="vmodel">
    </Chip>
</template>
<script lang="ts">


import { defineComponent, onMounted, onUnmounted } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import ChipTypeConst from '../../../../domain/Constants/ChipTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';

import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_chip',
    components: {
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
           slotProps: {
            type: Object,
            default: () => ({})
        },

    },
    setup(props, context) {
        //

        
        const store = useStore();
        const { vmodel,canDoOperation, loaded, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue,
             resolveExpressions, baseOnUnmount,fieldKey,fieldKeyComponentDataSourceId
             ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);



        onMounted(() => {
            // resolveExpressions();

        })

        onUnmounted(() => {
            baseOnUnmount();
        })

        return {      
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            ChipTypeConst,
            getPropertyNumberValue,
            loaded,
            BaseControlTypeConst,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey

        };
    },
});
</script>
<style scoped></style>
