export default class FilterMatchModeConst {
  
    static STARTS_WITH= 'startsWith';
    static CONTAINS= 'contains';
    static NOT_CONTAINS= 'notContains';
    static ENDS_WITH= 'endsWith';
    static EQUALS= 'equals';
    static NOT_EQUALS= 'notEquals';
    static IN= 'in';
    static LESS_THAN= 'lt';
    static LESS_THAN_OR_EQUAL_TO= 'lte';
    static GREATER_THAN= 'gt';
    static GREATER_THAN_OR_EQUAL_TO= 'gte';
    static BETWEEN= 'between';
    static DATE_IS= 'dateIs';
    static DATE_IS_NOT= 'dateIsNot';
    static DATE_BEFORE= 'dateBefore';
    static DATE_AFTER= 'dateAfter';
    static IS_NOT_NULL= 'IsNotNull';
    static IS_NULL= 'IsNull';
    static CURRENT_MONTH= 'CurrentMonth';
    static CURRENT_YEAR= 'CurrentYear';
    static CURRENT_WEEK= 'CurrentWeek';
    static PREVIOUS_MONTH= 'PreviousMonth';
    static PREVIOUS_YEAR= 'PreviousYear';
    static PREVIOUS_WEEK= 'PreviousWeek';
  

}