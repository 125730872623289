

export default class CompsQueue{
    static items = new Array();

    static enqueue = (item:string) => {
        this.items.push(item);
    }
    static dequeue = (item:string) => {
        this.items = this.items.filter(x=> x !== item);
    }


    static isEmpty = () =>{
        return (this.items.filter(x=> x).length == 0)
    }

    static getAll = () =>{   
       
        return this.items;

    }
  
    static clearAll = () =>{   
        this.items = []

    }

}