import SecureLS from '@cbcheng/secure-ls'

const ID_TOKEN_KEY = 'id_token' as string
const CONF = 'settings' as string
const CONF_MENU_TARIFICADOR = 'mnutarificador' as string
const COMPONENTS_SEE = 'appcompSee'
const COMPONENTS_CTRL = 'appcompCtrl'
const COMPONENTS_EXP = 'appcompExp'
const APP_DATA = 'appData'

const ls = new SecureLS({ isCompression: true, encodingType: 'aes' })

/**
 * @description get value form localStorage
 */
export const getValue = (key: string): string | null => {
    return ls.get(key)

  // return window.sessionStorage.getItem(key);
}

/**
 * @description save value into localStorage
 * @param token: string
 */
export const setValue = (key: string, value: string): void => {
  ls.set(key, value)
  // window.sessionStorage.setItem(key, value);
}

/**
 * @description remove value form localStorage
 */
export const destroy = (key: string): void => {
  ls.remove(key)
  // window.sessionStorage.removeItem(key);
}

/**
 * @description remove value form localStorage
 */
export const destroyAll = (): void => {
  
  
  window.sessionStorage.removeItem(ID_TOKEN_KEY)
  window.sessionStorage.removeItem(CONF)
  window.sessionStorage.removeItem(CONF_MENU_TARIFICADOR)
  
  
  window.localStorage.removeItem(COMPONENTS_SEE)
  window.localStorage.removeItem(COMPONENTS_CTRL)  
  window.localStorage.removeItem(APP_DATA)

  for (var key in localStorage){
    if (key.includes('builderapp')){
      window.localStorage.removeItem(key)
    }
 }

  ls.getAllKeys().filter(x=> x.includes(COMPONENTS_EXP)).forEach(x=>{
    window.sessionStorage.removeItem(x)
  });

}



export const getLocalValue = (key: string): string | null => {  
   return window.localStorage.getItem(key);
}

/**
 * @description save value into localStorage
 * @param token: string
 */
export const setLocalValue = (key: string, value: string): void => {
     window.localStorage.setItem(key, value);
}

/**
 * @description remove value form localStorage
 */
export const destroyLocal = (key: string): void => {
  
   window.localStorage.removeItem(key);
}


export default { getValue, setValue, destroy, destroyAll,getLocalValue,setLocalValue,destroyLocal, CONF, CONF_MENU_TARIFICADOR,COMPONENTS_SEE,COMPONENTS_EXP, APP_DATA,
  COMPONENTS_CTRL }
