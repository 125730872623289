import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import { IComponentDataSourceRepository } from '../domain/IComponentDataSourceRepository';

import { UPDATE, ADD ,DELETE} from './mutation/componentDataSourceMutation';
import { IapComponentDataSource } from '../domain/iapComponentDataSource';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { GET_ALL_BY_COMPONENTID,GET_ALL_COMPONENTDATASOURCE,GET_ALL_ASCENDANCYFROMCOMPONENT} from './query/componentDataSourceQuery';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';


@injectable()
export class ComponentDataSourceApolloClientRepository implements IComponentDataSourceRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }

  
  async delete (componentDataSourceId: number): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(DELETE, { componentDataSourceId: componentDataSourceId }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentDataSourceMutation.deleteComponentDataSource)
        }
        catch(e) {
          reject()
        }
      });
    });
  }
  
  

  async update (dataInput: IapComponentDataSource): (Promise<IapComponentDataSource>) {
    return new Promise<IapComponentDataSource>(async (resolve, reject) => {
      
      const result = this.apolloClient.executeQuery(UPDATE,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentDataSourceMutation.updateComponentDataSource)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async add (dataInput: IapComponentDataSource): (Promise<IapComponentDataSource>) {
    return new Promise<IapComponentDataSource>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentDataSourceMutation.addComponentDataSource)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async getAll (maxregs: number | undefined): (Promise<IapComponentDataSource[]>) {
    return new Promise<IapComponentDataSource[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_ALL_COMPONENTDATASOURCE, { maxregs: maxregs },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentDataSourceQuery.getAll)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

 

  async getAllByComponentId(componentId: number): (Promise<IapComponentDataSource[]>) {
    return new Promise<IapComponentDataSource[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_ALL_BY_COMPONENTID, { componentId: componentId },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentDataSourceQuery.getAllByComponentId)
        }
        catch(e) {
          reject()
        }
      });
    });
  }
  async getAllAscendancyFromComponent(componentId: number): (Promise<IapComponentDataSource[]>) {
    return new Promise<IapComponentDataSource[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_ALL_ASCENDANCYFROMCOMPONENT, { componentId: componentId },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentDataSourceQuery.getAllAscendancyFromComponent)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


  
}









