import BaseControlTypeConst from "./BaseControlTypeConst"

export default class StructuredFieldControlTypeConst extends BaseControlTypeConst {
    //static FIELDS_DEF = 'fieldsDefinition'
    static FILE_TYPE='fileType'
    static JSON_SCHEMA='fileSchemaType'
    static DS='dataSource'
    static XML_ROOT_NAME='xmlRootName'

    // campo estructurado
    static LAYOUT_ADDFORM ='layouttemp-struct-new'
    static USE_SCHEMA_EDITOR = 'useJsonEditorForm'
}
