import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../common/infrastructure/funciones/HelperApolloClient';

import { IEncryptRepository } from '../domain/IEncryptRepository';

//import { UPDATE } from './mutation/applicationMutation';
import ApolloConst from '../../../common/domain/constantes/Apollo/ApolloConst';
import { DECRYPT, ENCRYPT } from './mutation/encryptMutation';
import HelperUtils from '../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class EncryptApolloClientRepository implements IEncryptRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId=ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }
  async decrypt (data: string): (Promise<string>) {
    return new Promise<string>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(DECRYPT, { data: data }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.encryptMutation.decrypt)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async encrypt (data: string): (Promise<string>) {
    return new Promise<string>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(ENCRYPT, { data: data }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.encryptMutation.encrypt)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

}


