import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import { IServiceConfiguration } from './IServiceConfiguration';

import type { IConfigurationRepository } from '../domain/IConfigurationRepository';
import { IapmoConfiguration } from '../domain/iapmoConfiguration';



@injectable()
export class ConfigurationService implements IServiceConfiguration {
  private readonly repo: IConfigurationRepository

  public constructor (@inject(TYPES.CONFIGURATION_REPOSITORY) _repo: IConfigurationRepository) {
    this.repo = _repo
  }

  

  async  all () : Promise<IapmoConfiguration[]> {
    return await this.repo.all();
  }
  
  
  
}
