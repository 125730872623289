import gql from 'graphql-tag';

export const GET_BY_COMPONENTID = gql(`
query($componentId: Int!,$componentTypeId:String!) {
  componentAttributeQuery {
    getByComponentId(componentId:$componentId,componentTypeId:$componentTypeId) {
        properties{
            id
            componentId
            name
            description
            value
            order
            idType
            idDataType
            idAttributeType
            idModelValueType
            valueListId
            localizable
            mandatory
            valueBinaryFileName
            valueBinaryFileType
            valueBinaryString
            fcr
            ucr
            uum
            fum
        }        
        propertyTemplates{
            id
            name
            idType
            idDataType
            idAttributeType
            valueListId
            defaultValue
            localizable
            description
            order
            fcr
            ucr
            uum
            fum
        }
      }
    }
  }
`);
