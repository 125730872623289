/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
import { Container } from "inversify";
import { computed, ref } from "vue";
import fechaComp from "../../../../../common/infrastructure/funcionescompartidas/fechaComp";
import { IapCatalog } from "../../../catalog/domain/iapCatalog";
import { TYPES } from "../../../../../common/domain/types";
import HelperLoading from "../../../../../common/infrastructure/funciones/HelperLoading";
import SqlTypesConst from "../../../../../common/domain/constantes/SqlTypesConst";
import SqlClauseConst from "../../../../../common/domain/constantes/SqlClauseConst";
import HelperCommon from "../../../../../common/infrastructure/funciones/HelperCommon";
import { IServiceCatalog } from "../../../catalog/application/IServiceCatalog";
import { IapComponentDataSource } from "../../../component/domain/iapComponentDataSource";
import { IapDataSourceField } from '../../../datasource/domain/iapDataSourceField';
import { fetchWrapper } from "../../../../../common/infrastructure/funciones/helperFetch";
import DataSourceConst from "./dataSourceConst";
import { IapCatalogType } from "../../../catalog/domain/iapCatalogType";
import DataSourceUtilComp from "./dataSourceUtilComp";
import CatalogDataTypeConst from "../../../catalog/domain/const/CatalogDataTypeConst";
import CatalogExpConst from "../../../catalog/domain/const/CatalogExpConst";
import CatalogObjectTypeConst from "../../../catalog/domain/const/CatalogObjectTypeConst";
import { LocalService } from "../../../../../common/infrastructure/servicios";
import { ExpresionEngine } from "../../../expression/infrastructure/helper/expressionEngine";
import { IapExpression } from '../../../expression/domain/iapExpression';
import HelperUtils from "../../../../../common/infrastructure/funciones/HelperUtils";
import { IapDataSource } from "../../../datasource/domain/iapDataSource";
import Environment from "../../../../../common/infrastructure/funciones/environment";
import { IapDataSourceLookUp } from "../../../datasource/domain/iapDataSourceLookUp";
import { Dictionary } from "../../../expression/domain/dictionary";
import { SearchData,GroupSearch } from "../../../search/domain/search";
import FiltroBusquedaConst from "../../../../../common/domain/constantes/FiltroBusquedaConst";
import ExpressionNomenclatorConst from "../../../expression/domain/const/ExpressionNomenclatorConst";
import ControlTypeConst from "../../../form/domain/Constants/ControlTypeConst";
import { clave } from "../../../dataupdate/domain/clave";
import { IapComponentDataSourceServiceConfiguration } from "../../../component/domain/iapComponentDataSourceServiceConfiguration";
import OperatorLogicConst from "../../../search/domain/Const/OperatorLogicConst";




export default function DataSourceComp(
  container: Container,
  props: any,
  emit: any,
  t: any,
  dscInput: IapComponentDataSource,
  catalogos: IapCatalog[],
  store: any,
  dsInput?: IapDataSource
) {

  const { formateaFecha, formateaFechaHora } = fechaComp();
  /*
    const catalogos = computed({
      //get: () => props.catalogosData as IapCatalog[],
      //set: (val) => emit("update:catalogosData", val),
      
    });
    */

  const dataFilter = computed({
    get: () => props.modelValue,
    set: (val) => emit("update:modelValue", val),
  });

  const dataSearchResult = computed({
    get: () => props.dataSearch,
    set: (val) => emit("update:dataSearch", val),
  });

  const tablas = computed(() => {

    if (dsInput) {
      return dsInput.iapDataSourceTableAliases;
    }
    return dscInput?.dataSource.iapDataSourceTableAliases;
  })

  const tablasData = computed(() => {

    if (dsInput) {
      return dsInput.iapDataSourceFields;
    }
    return dscInput?.dataSource.iapDataSourceFields
  })

  const dataBaseId = computed(() => {

    if (dsInput) {
      return dsInput?.iapDataSourceDataBase?.idBaseDatos
    }
    return dscInput?.dataSource?.iapDataSourceDataBase?.idBaseDatos;
  })

  const { isType,
    isCatalogType,
    isCatalogTypeByFieldId,
    isFilterVisible,
    isIdCampoType,
    isColumnType,
    isColumnIdtipoCampoType,
    isTableType,
    hasLookUpType,
    hasLookUpByFieldId,
    hasLookUpByFieldType,
    getLookUpByFieldId,
    getFieldValidationConfig,
    getFieldFromKeyCol,
    getFieldKeyColumn,
    getFieldId } = DataSourceUtilComp(props.compDataSource, store,dsInput)

  let cleanLookUp = ref<string[]>([]);


  const getCatalog = (catalogoTipo: string) => {

    return new Promise<IapCatalog[]>((resolve) => {
      if (container) {
        const outputData = Array<IapCatalog>()
        const _srv = container.get<IServiceCatalog>(TYPES.CATALOG_REPOSITORY);
        HelperLoading.showLoading();
        fetchWrapper.get(Environment.URL_CATALOG + catalogoTipo + '.json')
          .then((response: IapCatalog) => {
            outputData.push(response);
            resolve(outputData);
          })
          .catch(() => {
            HelperLoading.showLoading();
            _srv
              .getCatalogByType(catalogoTipo)
              .then((response) => {
                resolve(response);
              })
              .finally(() => {
                HelperLoading.hideLoading();
              });

          })
          .finally(() => {
            HelperLoading.hideLoading();
          });


      }
    });

  };

  const getCatalogs = (catalogoTipos: string[]) => {

    return new Promise<IapCatalog[]>((resolve) => {

      const procs = Array()
      let outputData = Array<IapCatalog>()
      HelperLoading.showLoading();
      catalogoTipos.forEach(cat => {

        procs.push(fetchWrapper.get(Environment.URL_CATALOG + cat + '.json')
          .then((response: IapCatalogType) => {
            outputData = outputData.concat(response.iapCatalogs)
          }))
      });

      Promise.all(procs).then(() => {
        resolve(outputData);
      }).finally(() => {
        HelperLoading.hideLoading();
      })

    })


  };





  const getCatalogTypeByFieldId = (key: string) => {
    const item = getFieldFromKeyCol(key,false,tablasData.value);
    return item?.catalogTypeId ?? ''
  };


  const existeCampoFiltrado = (t: IapDataSourceField,fromSearchTree:boolean=false) => {

    if(fromSearchTree){
      return (dataFilter.value.fields.filter((h: { fieldId: string; }) => getFieldData(h.fieldId) == t).length > 0)
    }
    return (dataFilter.value.filter((h: { fieldId: string; }) => getFieldData(h.fieldId) == t).length > 0)
  }

  const getCatalogData = (tipoCatalogoid: string) => {
    return catalogos.filter(x => x.typeId == tipoCatalogoid).sort((a: { order: number; }, b: { order: number; }) => { return a.order - b.order; })
  }



  const formatData = (originaldata: any, columna: string, avoidCatalog: boolean = false, avoidLookUp: boolean = false,includeZeroMoneyFormat:boolean=false) => {

    var data = originaldata[columna];
    var field = getFieldFromKeyCol(columna,false,tablasData.value);

    // busca los customs
    if (field == undefined) {
      field = getFieldFromKeyCol(columna,true,tablasData.value);
    }

    if (field) {

      if (isIdCampoType(field, CatalogDataTypeConst.DATE)) {
        data = formateaFecha(data);
      }

      if (isIdCampoType(field, CatalogDataTypeConst.DATETIME)) {
        data = formateaFechaHora(data);
      }

      if (isType(field, SqlTypesConst.BIT)) {
        data = ((data ?? false)) === false ? 'No' : 'Sí';
      }

      if (isIdCampoType(field, CatalogDataTypeConst.MONEY)) {
        data = HelperCommon.formateaMoneda(data,includeZeroMoneyFormat);
      }

      if (isIdCampoType(field, CatalogDataTypeConst.PERCENT)) {
        data = HelperCommon.formateaPorcentaje(data);
      }

      if (!avoidCatalog && isCatalogType(field)) {
        const oldvalue = data
        data = catalogos.find(x => x.id.toLowerCase() == data?.toLowerCase())?.description ?? oldvalue;
      }

      if (!avoidLookUp && hasLookUpType(field)) {
        const keys = Object.keys(originaldata);
        const newColumn = columna + DataSourceConst.DELIMITERFIELDLOOKUPDESCRIPTION;
        if (keys.includes(newColumn)) {
          data = originaldata[newColumn];
        }
      }

    }
    return data;
  }

  const mustFormatData = (columna: string) => {

    if (tablasData.value) {
      var field = getFieldFromKeyCol(columna,false,tablasData.value);
      if (field) {
        if (isType(field, SqlTypesConst.DATETIME) || isType(field, SqlTypesConst.TIME) || isType(field, SqlTypesConst.DATE)) {
          return true
        }
        if (isType(field, SqlTypesConst.BIT)) {
          return true
        }
        if (isType(field, SqlTypesConst.MONEY) || isType(field, SqlTypesConst.DECIMAL)) {
          return true
        }
        if ((isType(field, SqlTypesConst.VARCHAR) || isType(field, SqlTypesConst.NVARCHAR)) && isCatalogType(field)) {
          return true
        }

      }
      return false;
    }
    return false;
  }


  const mustFormatDataColuns = () => {
    if (tablasData.value) {
       return (tablasData.value as IapDataSourceField[]).filter(field=>
        isType(field, SqlTypesConst.DATETIME) || isType(field, SqlTypesConst.TIME) || isType(field, SqlTypesConst.DATE)
        || isType(field, SqlTypesConst.BIT)
        || isType(field, SqlTypesConst.MONEY) || isType(field, SqlTypesConst.DECIMAL)
        || (isType(field, SqlTypesConst.VARCHAR) || isType(field,SqlTypesConst.NVARCHAR) && isCatalogType(field))
        ).map(x => getFieldKeyColumn(x));      
    }
    return [];
  }

  const moneyColumns=()=>{
        if (tablasData.value) {
          return (tablasData.value as IapDataSourceField[]).filter(field=>
          isType(field, SqlTypesConst.MONEY) 
          ).map(x => getFieldKeyColumn(x));      
      }
      return [];
  }

  const generateFormatCol = (_data: any) => {

    _data.forEach((data: {}) => {
      const keys = Object.keys(data);
      keys.forEach(k => {
        const colName = k + DataSourceConst.DELIMITERFIELDFORMAT
        const zz = data as any
        zz[colName] = formatData(data, k)
      })

    })
  }


const generateAllFormatColCrud =(_data:any,formatDate=true) =>{

const columnsToFormat = mustFormatDataColuns() 
const lookUpConfig = getLookUpColumnsConfig.value;

  _data.forEach((data: any) => {
    const keys = Object.keys(data);
    keys.forEach(k => {

      // las fechas iso
      if (formatDate && HelperUtils.isIsoDateString(data[k])){
        data[k] = new Date(data[k])
      }
      // solo se añaden las colF que se necesitan
      if (columnsToFormat.includes(k)){
        const colName = k + DataSourceConst.DELIMITERFIELDFORMAT        
        data[colName] = formatData(data, k)
      }

      // se añaden los lookup
      const column = lookUpConfig.find(col => col.columnDesc == k);


      if (column && column.field.dataSourceLookUp) {
        const newLookUpColumn = getLookUpModelValue(data, column.field.dataSourceLookUp);
        data[k + DataSourceConst.DELIMITERFIELDLOOKUPID] = newLookUpColumn;
      }

      
    })

  })

    //fuera del bucle rellenamos los filtros del lookUp, ya que es el mismo para todas las filas
    lookUpConfig.forEach(lkf=>{
      const filterExprLookUp = lookUpFilterExp( lkf.columnDesc);
      _data.forEach((obj: any) => {
        obj[lkf.columnDesc + DataSourceConst.DELIMITERFIELDLKFILTER]= { initialFilterlk: filterExprLookUp??[], lookUpFilter: filterExprLookUp??[] };
      });

  });

 
}


  const getLookUpModelValue = (data: any, lookUp: IapDataSourceLookUp) => {


    let dic: Dictionary<String, String>[] = ([]);

    const fieldMaps = lookUp.iapDataSourceLookUpFieldMaps?.filter(x => x.dataSourceFieldIdTarget != null && x.isFieldLinked);

    fieldMaps.forEach(fm => {
      const dsf = tablasData.value.find(dsf => dsf.id == fm.dataSourceFieldIdTarget);

      if (dsf) {
        const keyTarget = HelperCommon.isNullOrWhitespace(dsf.tableName)?getFieldKeyColumn(dsf,true):getFieldKeyColumn(dsf);

        dic.push({
          key: dsf.id,
          value: data[keyTarget]
        } as any)

      }
    });

    return dic;
  };


  const getLookUpColumnsConfig = computed(() => {

    let config: { columnDesc: string, field: IapDataSourceField }[] = [];
    const fields = tablasData.value?.filter(dsf => dsf.dataSourceLookUpId !== null);
    if (fields){
      fields.forEach(f => {
        const columnName: string =HelperCommon.isNullOrWhitespace(f.tableName) ?getFieldKeyColumn(f,true):getFieldKeyColumn(f);
  
        config.push({ columnDesc: columnName, field: f });
      });
  
    }
    return config;
  })



  const addLookUpColumns = (_data: any) => {

    const lookUpConfig = getLookUpColumnsConfig.value;

    if (lookUpConfig.length > 0) {
      _data.forEach((obj: any) => {
        Object.keys(obj).forEach(key => {

          const column = lookUpConfig.find(col => col.columnDesc == key);


          if (column && column.field.dataSourceLookUp) {
            const newLookUpColumn = getLookUpModelValue(obj, column.field.dataSourceLookUp);
            obj[key + DataSourceConst.DELIMITERFIELDLOOKUPID] = newLookUpColumn;
          }


        });
      });

      //fuera del bucle rellenamos los filtros del lookUp, ya que es el mismo para todas las filas

      lookUpConfig.forEach(lkf=>{
          const filterExprLookUp = lookUpFilterExp( lkf.columnDesc);
          _data.forEach((obj: any) => {
            obj[lkf.columnDesc + DataSourceConst.DELIMITERFIELDLKFILTER]= { initialFilterlk: filterExprLookUp??[], lookUpFilter: filterExprLookUp??[] };
          });

      });


    }

  }




  const getNewFiltersConditions = (fieldName: string, data: any): { fieldName: string, filters: any[] }[] => {


    let result: { fieldName: string, filters: any[] }[] = [];

    const dsfIdParentLookUp = getFieldFromKeyCol(fieldName,false,tablasData.value)?.id;

    tablasData.value.forEach(field => {
      if (field.dataSourceLookUpId != null && field.id != dsfIdParentLookUp) {
        const fieldMapsFiltered = field.dataSourceLookUp?.iapDataSourceLookUpFieldMaps.filter(x => x.isFieldFiltered);

        fieldMapsFiltered?.forEach(fm => {
          if (fm.dataSourceFieldIdTarget == dsfIdParentLookUp) {
            
            result.push({
              fieldName: getFieldKeyColumn(field),
              filters: setNewFiltersConditions(createNewFiltersConditions(field, data))

            })
          }
        })
      }
    })
    return result;
  }

  const createNewFiltersConditions = (field: IapDataSourceField, data: any): Dictionary<IapDataSourceField, String>[] => {

    let dic: Dictionary<IapDataSourceField, String>[] = ([]);

    const fieldMapsFiltered = field.dataSourceLookUp?.iapDataSourceLookUpFieldMaps.filter((fm: any) => fm.isFieldFiltered);

    if (data != null) {
      Object.keys(data).forEach(f => {

        if (f.includes(DataSourceConst.LOOKUPID + DataSourceConst.DELIMITERFIELDS) && !f.includes(DataSourceConst.DELIMITERFIELDFORMAT)) {

          const fieldTargetLookUp = f.replace((DataSourceConst.LOOKUPID + DataSourceConst.DELIMITERFIELDS), '');
          const fieldMap = fieldMapsFiltered?.find(x => x.dataSourceFieldIdTargetLookUp == fieldTargetLookUp);

          if (fieldMap) {
            dic.push({
              key: getFieldFilter(field, fieldMap.dataSourceFieldIdSource),
              value: data[f]
            } as any)
          }
        }
      });
    }

    return dic;
  }


  const getFieldFilter = (dsfTargetRelated: IapDataSourceField, dataSourceFieldIdTargetLookUp: string | null) => {

    if (dsfTargetRelated && dsfTargetRelated.dataSourceLookUp) {
      return dsfTargetRelated.dataSourceLookUp.dataSource.iapDataSourceFields.find(dsf => dsf.id == dataSourceFieldIdTargetLookUp);
    }

  }

  const setNewFiltersConditions = (dic: Dictionary<IapDataSourceField, String>[]) => {

    const newFilterConditions = ref<Partial<SearchData>[]>([]);

    dic.forEach(dicElement => {

      const filter = {
        //@ts-ignore:disable-next-line  
        id:HelperUtils.newGuid().toString().replaceAll('-',''),
        fieldId: dicElement.key.id,
        filter: FiltroBusquedaConst.FILTROBUSQUEDA_IGUAL,
        value: dicElement.value.toString(),
        valueList: (dicElement.key.catalogTypeId ? [dicElement.value.toString()] : null),
        valueBool: null,
        valueNumber: null,
        valueDateTime: null,
        rangeValue: null,
        rangeNumber: null,
        rangeDateTime: null

      }
      newFilterConditions.value.push(filter as any);
    })

    return newFilterConditions.value as any;

  }

  const cleanLookUpDependency = (fields: string[]): string[] => {

    let aux: string[] = [];

    fields.forEach(f => {
      const dsfIdParentLookUp = getFieldFromKeyCol(f,false,tablasData.value)?.id;

      tablasData.value.forEach(field => {
        if (field.dataSourceLookUpId != null && field.id != dsfIdParentLookUp) {
          const fieldMapsFiltered = field.dataSourceLookUp?.iapDataSourceLookUpFieldMaps.filter(x => x.isFieldFiltered);

          fieldMapsFiltered?.forEach(fm => {
            if (fm.dataSourceFieldIdTarget == dsfIdParentLookUp) {
              const fieldName = getFieldKeyColumn(field);
              cleanLookUp.value.push(fieldName);
              aux.push(fieldName);
            }
          })
        }
      })
    });
    if (aux.length > 0) {
      cleanLookUpDependency(aux);
    }

    return cleanLookUp.value;
  }



  const resolveFilterExpression = (field: IapDataSourceField, expressionsInput: IapExpression[] | null = null) => {
    const expData = expressionsInput ?? props.Component.expressions;
    const cds = dscInput ?? props.compDataSource

    //return resolveExpression(CatalogExpConst.EXP_DEFAULTVALUES,CatalogObjectTypeConst.DSF,[field.id, cds.id].join('#'))

    let resu: any
    const expressions = expData?.filter((x: IapExpression) => x.idTypeExpression == CatalogExpConst.EXP_DEFAULTVALUES
      && x.idObjeto == CatalogObjectTypeConst.DSF && x.objetoId == [field.id, cds.id].join('#'))
    expressions?.filter((x: IapExpression) => x.iapExpressionDetails?.length > 0).forEach((exp: IapExpression) => {
      const localData = LocalService.getValue(props?.Component?.formKey + LocalService.COMPONENTS_EXP + (props?.Component?.rootParentId ?? -1).toString());
      const data = HelperUtils.jsonParse(localData,[])
      resu = ExpresionEngine.resolveExpressions(exp.iapExpressionDetails, data as any, store)

      //resu = resu?.toString();s
      if (resu) {
        if (Object.keys(resu).length == 0) {
          resu = resu?.toString();
        }
      }
    });

    return resu;
  }


  const resolveExpression = (idTypeExpression: string, idObjeto: string, objetoId: string, expressionsInput: IapExpression[]) => {
    const expData = expressionsInput;
    let resu: any
    const expressions = expData?.filter((x: IapExpression) => x.idTypeExpression == idTypeExpression
      && x.idObjeto == idObjeto && x.objetoId == objetoId)
    expressions?.filter((x: IapExpression) => x.iapExpressionDetails?.length > 0).forEach((exp: IapExpression) => {
      const localData = LocalService.getValue(props?.Component?.formKey + LocalService.COMPONENTS_EXP + (props?.Component?.rootParentId ?? -1).toString());
      const data = HelperUtils.jsonParse(localData,[])
      resu = ExpresionEngine.resolveExpressions(exp.iapExpressionDetails, data as any, store)

      //resu = resu?.toString();s
      if (resu) {
        if (Object.keys(resu).length == 0) {
          resu = resu?.toString();
        }
      }
    });

    return resu;
  }

  const resolveFieldFormatExpression = (field: IapDataSourceField, expressionsInput: IapExpression[] | null = null,dictionary:any = null) => {
    const expData = expressionsInput ?? props.Component.expressions;
    const cds = dscInput ?? props.compDataSource
    let resu: any
    const expressions = expData?.filter((x: IapExpression) => x.idTypeExpression == CatalogExpConst.EXP_FORMAT_VALUES
      && x.idObjeto == CatalogObjectTypeConst.DSF && x.objetoId == [field.id, cds.id].join('#'))
    expressions?.filter((x: IapExpression) => x.iapExpressionDetails?.length > 0).forEach((exp: IapExpression) => {
      let data :any
      if (dictionary!= null){
         data = dictionary
      }
      else{
        const localData = LocalService.getValue(props?.Component?.formKey + LocalService.COMPONENTS_EXP + (props?.Component?.rootParentId ?? -1).toString());
         data = HelperUtils.jsonParse(localData,[])
      }
      
      resu = ExpresionEngine.resolveExpressions(exp.iapExpressionDetails, data as any, store)

      //resu = resu?.toString();s
      if (resu) {
        if (Object.keys(resu).length == 0) {
          resu = resu?.toString();
        }
      }
    });

    return resu;
  }

  const resolveRowFormatExpression = (attrId:string, expressionsInput: IapExpression[] | null = null,dictionary:any = null) => {
    
    const expData = expressionsInput ?? props.Component.expressions;
    let resu: any
    const expressions = expData?.filter((x: IapExpression) => x.idTypeExpression == CatalogExpConst.EXP_FORMAT_VALUES
      && x.idObjeto == CatalogObjectTypeConst.ATTRCOMP && x.objetoId ==attrId)
    expressions?.filter((x: IapExpression) => x.iapExpressionDetails?.length > 0).forEach((exp: IapExpression) => {
      let data :any
      if (dictionary!= null){
         data = dictionary
      }
      else{
        const localData = LocalService.getValue(props?.Component?.formKey + LocalService.COMPONENTS_EXP + (props?.Component?.rootParentId ?? -1).toString());
         data = HelperUtils.jsonParse(localData,[])
      }
      
      resu = ExpresionEngine.resolveExpressions(exp.iapExpressionDetails, data as any, store)

      //resu = resu?.toString();s
      if (resu) {
        if (Object.keys(resu).length == 0) {
          resu = resu?.toString();
        }
      }
    });

    return resu;
  }

  const resolveFilterLookUpExpression = (fieldName: string) => {
    const cds = dscInput ?? props.compDataSource;
    const field = getFieldFromKeyCol(fieldName,false,tablasData.value)
    const expData = props.Component.expressions;
    let resu: any

    const expressions = expData?.filter((x: IapExpression) => x.idTypeExpression == CatalogExpConst.EXP_FILTERLK
      && x.idObjeto == CatalogObjectTypeConst.DSF && x.objetoId == [field?.id, cds.id].join('#'))
    expressions?.filter((x: IapExpression) => x.iapExpressionDetails?.length > 0).forEach((exp: IapExpression) => {
      const localData = LocalService.getValue(props?.Component?.formKey + LocalService.COMPONENTS_EXP + (props?.Component?.rootParentId ?? -1).toString());
      const data = HelperUtils.jsonParse(localData,[])
      resu = ExpresionEngine.resolveExpressions(exp.iapExpressionDetails, data as any, store)

      if (resu) {
        if (Object.keys(resu).length == 0) {
          resu = resu?.toString();
        }
      }
    });
    return resu;
  }

  const resolveDefaultValueExpression = (field: IapDataSourceField, expressionsInput: IapExpression[] | null = null,dictionary:any = null) => {
    const expData = expressionsInput ?? props.Component.expressions;
    const cds = dscInput ?? props.compDataSource
    let resu: any
    const expressions = expData?.filter((x: IapExpression) => x.idTypeExpression == CatalogExpConst.EXP_DEFAULTVALUES_FILTER
      && x.idObjeto == CatalogObjectTypeConst.DSF && x.objetoId == [field.id, cds.id].join('#'))
    expressions?.filter((x: IapExpression) => x.iapExpressionDetails?.length > 0).forEach((exp: IapExpression) => {
      let data :any
      if (dictionary!= null){
         data = dictionary
      }
      else{
        const localData = LocalService.getValue(props?.Component?.formKey + LocalService.COMPONENTS_EXP + (props?.Component?.rootParentId ?? -1).toString());
         data = HelperUtils.jsonParse(localData,[])
      }
      
      resu = ExpresionEngine.resolveExpressions(exp.iapExpressionDetails, data as any, store)

      //resu = resu?.toString();s
      if (resu) {
        if (Object.keys(resu).length == 0) {
          resu = resu?.toString();
        }
      }
    });

    return resu;
  }

  
  const resolveCdsExpression = (idTypeExpression: string, idObjeto: string, objetoId: string, expressionsInput: IapExpression[] | null,clause:string) => {
    const expData = expressionsInput ?? props.Component?.expressions;
    let resu: any
    const expressions = expData?.filter((x: IapExpression) => x.idTypeExpression == idTypeExpression
      && x.idObjeto == idObjeto && x.objetoId ==  [objetoId, 'clause='+clause].join('#'))
    expressions?.filter((x: IapExpression) => x.iapExpressionDetails?.length > 0).forEach((exp: IapExpression) => {
      const localData = LocalService.getValue(props?.Component?.formKey + LocalService.COMPONENTS_EXP + (props?.Component?.rootParentId ?? -1).toString());
      const data = HelperUtils.jsonParse(localData,[])
      resu = ExpresionEngine.resolveExpressions(exp.iapExpressionDetails, data as any, store)

      //resu = resu?.toString();s
      if (resu) {
        if (Object.keys(resu).length == 0) {
          resu = resu?.toString();
        }
      }
    });

    return resu;
  }

    const adjustCdsAndClauses=(cds:IapComponentDataSource | undefined):IapComponentDataSource | undefined=>{
      
      if(cds){
        const cdsCopy = JSON.parse(JSON.stringify(cds));

        delete cdsCopy.iapComponentDataSourceFieldConfigurations;
        delete cdsCopy.iapComponentDataSourceServiceConfigurations;
        delete cdsCopy.iapObjectDataSourceFieldConfigurations;
        delete cdsCopy.dataSource;
    
        cdsCopy.queryWhere=resolveCdsExpression(CatalogExpConst.EXP_FILTER,CatalogObjectTypeConst.CDS,cdsCopy.id,null, SqlClauseConst.WHERE)??cdsCopy.queryWhere;
        cdsCopy.queryGroupBy=resolveCdsExpression(CatalogExpConst.EXP_FILTER,CatalogObjectTypeConst.CDS,cdsCopy.id,null, SqlClauseConst.GROUPBY)??cdsCopy.queryGroupBy;
        cdsCopy.queryHaving=resolveCdsExpression(CatalogExpConst.EXP_FILTER,CatalogObjectTypeConst.CDS,cdsCopy.id,null, SqlClauseConst.HAVING)??cdsCopy.queryHaving;
        cdsCopy.queryOrderBy=resolveCdsExpression(CatalogExpConst.EXP_FILTER,CatalogObjectTypeConst.CDS,cdsCopy.id,null, SqlClauseConst.ORDERBY)??cdsCopy.queryOrderBy;
  
        return cdsCopy;
      }
      return undefined;

    }

  

  const createFilterByExpConditions = (resultExp: string, fieldName: string) => {
    const filterConditions = ref<Partial<SearchData>[]>([]);
    
    if (resultExp) {
      const resultArr = resultExp.split('#');

      if (resultArr && isValidLength(resultArr))
        for (let i = 0; i < resultArr.length; i += 3) {
          const dsf = getDsfByTableAndField(resultArr[i], fieldName);
          if (dsf) {
            const filter = {
              //@ts-ignore:disable-next-line
              id:HelperUtils.newGuid().toString().replaceAll('-',''),
              fieldId: dsf.id,
              filter: resultArr[i + 1],
              value: resultArr[i + 2],
              valueList: (dsf.catalogTypeId ? [resultArr[i + 2]] : null),
              valueBool: null,
              valueNumber: null,
              valueDateTime: null,
              rangeValue: null,
              rangeNumber: null,
              rangeDateTime: null
            }
            // metemos nuevo filtro
            filterConditions.value.push(filter as any);
          }
        }
    }
    return filterConditions.value;
  }

  function isValidLength(arr: any[]): boolean {
    return arr.length % 3 === 0;
  }

  const getDsfByTableAndField = (tableField: string, fieldName: string): IapDataSourceField | undefined => {
    const field = getFieldFromKeyCol(fieldName,false,tablasData.value);
    const fields = tablasData.value.find(x => x.id == field?.id)?.dataSourceLookUp?.dataSource.iapDataSourceFields;
    const lookUpId = field?.dataSourceLookUpId;

    if (fields && !HelperCommon.isNullOrWhitespace(tableField)) {
      const dsf = fields.find(dsf => '[' + ExpressionNomenclatorConst.EXPNOM_DSLK + '.' + (lookUpId ? lookUpId : 0) + '.' + dsf.id + ']' == tableField);

      if (dsf) {
        return dsf;
      }
    }
  }

  const lookUpFilterExp=(fieldName:string)=>{
    const resolveExp = resolveFilterLookUpExpression(fieldName);
    const filterExprLookUp = createFilterByExpConditions(resolveExp, fieldName);
    return filterExprLookUp;
  }


  const getDefaultValueFromField = (data: IapDataSourceField, alternateDefaultValue: string | null = null,fromFillSearch=false) => {
    
    const defaultValue = !HelperCommon.isNullOrWhitespace(alternateDefaultValue??'')?alternateDefaultValue:null ?? !fromFillSearch?data.defaultValue:null;//No nos interesa que saque el valor por defecto iapdatasourcefield en algunos casos.
    if (defaultValue) {
      const i = defaultValue.startsWith('((') ? 2 : 1;
      let _valor = defaultValue.substring(i, defaultValue.length - i)
      //@ts-ignore:disable-next-line
      _valor = _valor.replaceAll('(', '').replaceAll(')', '')

      if((fromFillSearch && (_valor.toLocaleLowerCase() == 'newid' || defaultValue.toLocaleLowerCase() == 'newid()'))){
        return null;
      }

      if (_valor.toLocaleLowerCase() == 'getdate') {
        return new Date()
      }
      if (_valor.toLocaleLowerCase() == 'newid') {
        return HelperUtils.newGuid()
      }
      if (_valor.toLocaleLowerCase() == 'getcurrentuser') {
        return store.getters.currentUser.id
      }

      if (defaultValue.toLocaleLowerCase() == 'getdate()') {
        return new Date()
      }
      if (defaultValue.toLocaleLowerCase() == 'newid()') {
        return HelperUtils.newGuid()
      }
      if (defaultValue.toLocaleLowerCase() == 'getcurrentuser()') {
        return store.getters.getCurrentUser.id
      }

      if (isType(data, SqlTypesConst.BIT) && !fromFillSearch) {
          try {
            const boolData = Boolean(JSON.parse(alternateDefaultValue?defaultValue:_valor))
            return boolData;
            } catch (error) {
            }
      }
      else {
        return alternateDefaultValue?defaultValue:_valor;
      }
    }

    return null;
  }



  const getDefaultvalue = (data: IapDataSourceField, alternateDefaultValue: string | null = null, expressionsInput: IapExpression[] | null = null,fromFillSearch=false) => {
    // las expresiones de los filtros sobre los dsf estan por encima de los valores por defecto del dsf
    const expvalue =!fromFillSearch? resolveFilterExpression(data, expressionsInput):null;
    if (expvalue) {
      return expvalue;
    }

    return getDefaultValueFromField(data,alternateDefaultValue,fromFillSearch);

  }


  const getDefaultValueServiceCfg = (data: IapComponentDataSourceServiceConfiguration, expressionsInput: IapExpression[], alternateDefaultValue: string | null = null) => {
    // las expresiones de los filtros sobre los dsf estan por encima de los valores por defecto del dsf
    
    const expvalue = resolveExpression(CatalogExpConst.EXP_DEFAULTVALUES,CatalogObjectTypeConst.DSSC,[data.dataSourceServiceConfigurationId, data.componentDataSourceId].join('#'), expressionsInput);
    if (expvalue) {
      return expvalue;
    }

    return data.value ?? alternateDefaultValue;
  }


  const insertEmptyRowToDataSource = (expressionsInput: IapExpression[] | null = null,multCompDataSources:IapComponentDataSource[]=[]) => {   
      multCompDataSources.forEach(cds=>{
        cds.dataSource.items = [{}];
        cds.dataSource.iapDataSourceFields.forEach(x => {
  
          const alternateDefVal = cds.iapComponentDataSourceFieldConfigurations.find(c => c.dataSourceFieldId == x.id)?.defaultValue;
          cds.dataSource.items[0][getFieldKeyColumn(x)] = getDefaultvalue(x, alternateDefVal, expressionsInput)
        }        
        )
      })   
  }

  const clearRowsToDataSource = () => {
    dscInput.dataSource.items = null;
  }

  const adjustSearchRequest=(data:any)=>{
    
        if(data){
          data.forEach((item:any) => {

            const field=getFieldData(item.fieldId);

            //tratamiento especial para los lookups.
            if(field.dataSourceLookUpId!==null){
                if(field?.dataSourceLookUp?.idControlType==ControlTypeConst.CTDROPDOWN
                || field?.dataSourceLookUp?.idControlType==ControlTypeConst.CTSEARCH){
                    
                    item.filter=FiltroBusquedaConst.FILTROBUSQUEDA_IGUAL;
                    if(item.value && Array.isArray(item.value)){
                        item.value=item.value[0].value.toString();
                    }
                   
                }
                if(field?.dataSourceLookUp?.idControlType==ControlTypeConst.CTMULTISELECT){
                    
                    if(item.value && Array.isArray(item.value)){
                        const values:string[]=[];
                        item.value.forEach((x:any)=>{
                            x.lookUpModelValue.forEach((lkmv:any)=>{
                                values.push(lkmv.value.toString());
                            });

                        });
                        item.value=null;
                        item.valueList=values;
                        item.filter=FiltroBusquedaConst.FILTROBUSQUEDA_ENTRE;
                    }
                }
            
            }

            
        })
        }
  }

  const getDataKeys = (data: any, tabla: string): clave[] => {

    const _outputData: clave[] = [];


    const keys = tablasData.value.filter(x => x.tableName.toLowerCase() == tabla.toLowerCase() && x.primaryKey).map(x =>
        getFieldKeyColumn(x,( (tabla ?? '') == ''))
    );
    keys.forEach(k => {
        _outputData.push({
            fieldId: k,
            value: data[k]
        })
    })

    return _outputData;

}

const getDataKeyName = (): string => {
  let key = '';
  tablasData.value.filter(x => props.filterTablas.includes(x.tableName) && x.primaryKey).forEach(t =>
      key += getFieldKeyColumn(t)

  )
  return key;

}

const getDataKey = (data: any): string => {
  let key = '';
  tablasData.value.filter(x => props.filterTablas.includes(x.tableName) && x.primaryKey).forEach(t =>
      key += data[getFieldKeyColumn(t)]

  )
  return key;

}

const getFilterValueData=(filterValue:any,position:number)=>{
  
  if(filterValue){

    const resultArr = filterValue.split('#');

    if(resultArr && Array.isArray(resultArr) && resultArr.length>1){
       if(position==0 && resultArr[position]){
          return  resultArr[position];
       }
       if(position==1 && resultArr[position]){
        return  resultArr[position]
       }
       else{
        return null;
       }
       
    }else{
      if(position==0){
        return FiltroBusquedaConst.FILTROBUSQUEDA_IGUAL; 
      }
      if(position==1){
        return filterValue;
      }
      
    }

  }
    
}

  const getFieldData=(fieldId:string | undefined):IapDataSourceField=>{
          const fields=tablasData.value;
          return fields?.find(x=>x.id==fieldId)??{} as any;
  }

  const clearEmptyFiltersList = (groupSearch: GroupSearch) => {

    groupSearch.fields = groupSearch.fields.filter(x => getFieldData(x.fieldId) && ((getFieldData(x.fieldId)?.catalogTypeId == null) || getFieldData(x.fieldId)?.catalogTypeId !== null && (x.valueList?.length ?? 0) > 0));

    if (groupSearch.children.length > 0) {
      groupSearch.children.forEach(x => {
        clearEmptyFiltersList(x);
      });
    }

  }

  const adjustAllTreeSearchRequest = (groupSearch: GroupSearch) => {

    adjustSearchRequest(groupSearch.fields);

    if (groupSearch.children.length > 0) {
      groupSearch.children.forEach(x => {
        adjustAllTreeSearchRequest(x);
      });
    }
  }

  const getRequiredFilterFields = (groupSearch: GroupSearch):SearchData[] => {

    let fields:SearchData[]=[];

    if(groupSearch && groupSearch.fields){

    groupSearch.fields.forEach(x=>{
        if(isRequiredFilter(x)){
            fields.push(x);
        }
    });

    }
    return fields;

}

const isDefaultFilter = (fieldSearch: SearchData) => {
    return dscInput?.iapComponentDataSourceFieldConfigurations?.find(x => x.dataSourceFieldId == fieldSearch.fieldId)?.defaultFilterSearch;
}

const hasFilterExpression = (fieldIdSearch: string,ds:IapComponentDataSource | undefined=undefined) => {
    const cds= ds??dscInput;
    if(cds){
      const exps= props.Component?.expressions?.filter((x:any)=>x.idTypeExpression==CatalogExpConst.EXP_FILTER && x.idObjeto==CatalogObjectTypeConst.DSF && x.objetoId==[fieldIdSearch,cds.id].join('#') && x.iapExpressionDetails?.length > 0);
      return (exps?.length > 0) 
    }
   return false;
}

const isRequiredFilter=(fieldSearch: SearchData)=>{
    return !isDefaultFilter(fieldSearch) && hasFilterExpression(fieldSearch.fieldId);
}

const reorderSearchTree = (data: GroupSearch) => {
  
  const fieldsAndFilter = getRequiredFilterFields(data);
  const fieldsAndFilterIds = fieldsAndFilter.flatMap((x) => x.fieldId);

  if (fieldsAndFilter && fieldsAndFilter.length > 0) {
    data.fields = data.fields.filter(
      (x) => !fieldsAndFilterIds.includes(x.fieldId)
    );

    const _data=JSON.parse(JSON.stringify(data));

    data.operatorLogic=OperatorLogicConst.AND;
    data.fields=fieldsAndFilter;
    data.children=[_data];

  }
};

const filterCatalogsFields=(cds:IapComponentDataSource | undefined,catalogs:IapCatalog[]=[] )=>{
  if(cds){
    var catalogsTypesIds= cds?.dataSource.iapDataSourceFields.filter(x => x.catalogTypeId).map(x => x.catalogTypeId) ?? [];
    return catalogs.filter(x=>catalogsTypesIds.includes(x.typeId));
  }

}

const isStrictFilter=(f:IapDataSourceField ,cds:IapComponentDataSource | undefined )=>{
  const ds=cds??dscInput;
  return  (hasFilterExpression(f.id,ds) && !(ds?.iapComponentDataSourceFieldConfigurations?.find(x => x.dataSourceFieldId == f.id)?.defaultFilterSearch));
}



  return {
    catalogos,
    dataFilter,
    dataSearchResult,
    tablas,
    tablasData,
    dataBaseId,
    isType,
    isCatalogType,
    isCatalogTypeByFieldId,
    getCatalog,
    getCatalogs,
    existeCampoFiltrado,
    getCatalogData,
    isFilterVisible,
    getCatalogTypeByFieldId,
    isIdCampoType,
    isColumnType,
    isColumnIdtipoCampoType,
    formatData,
    generateFormatCol,
    mustFormatData,
    isTableType,
    hasLookUpByFieldId,
    getLookUpByFieldId,
    hasLookUpByFieldType,
    getDefaultvalue,
    getDefaultValueFromField,
    insertEmptyRowToDataSource,
    clearRowsToDataSource,
    addLookUpColumns,
    getNewFiltersConditions,
    cleanLookUpDependency,
    cleanLookUp,
    lookUpFilterExp,
    resolveFieldFormatExpression,
    getDefaultValueServiceCfg,
    getFieldValidationConfig,
    resolveDefaultValueExpression,

    adjustSearchRequest,
    getDataKeys,
    getDataKeyName,
    getDataKey,
    generateAllFormatColCrud,
    mustFormatDataColuns,
    getFilterValueData,
    adjustCdsAndClauses,
    getFieldData,
    getFieldFromKeyCol,
    getFieldKeyColumn,
    getFieldId,
    clearEmptyFiltersList,
    adjustAllTreeSearchRequest,
    getRequiredFilterFields,
    reorderSearchTree,
    filterCatalogsFields,
    isStrictFilter,
    resolveRowFormatExpression,
    moneyColumns

  };
}
