import gql from 'graphql-tag';


export const GET_BY_IDTYPE = gql(`
 query($typeId:String!,$lang:String){
     catalogQuery{
         catalogByTypeId(typeId:$typeId,lang:$lang){
             active
             additionalValue
             description
             id
             idIcon
             order
             parentId
             typeId
             value
         }
     }    
 }
`);

export const GET_BY_IDTYPES = gql(`
 query($typeIds:[String]!,$lang:String){
     catalogQuery{
         catalogByTypeIds(typeIds:$typeIds,lang:$lang){
             active
             additionalValue
             description
             id
             idIcon
             order
             parentId
             typeId
             value
         }
     }    
 }
`);





