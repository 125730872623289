import BaseControlTypeConst from "./BaseControlTypeConst"

export default class CalendarTypeConst extends BaseControlTypeConst {

    static SELECTIONMODE = 'selectionMode'
    static DATEFORMAT = 'dateFormat'
    static INLINE = 'inline'
    static SHOWICON = 'showIcon'
    static SHOWOTHERMONTHS = 'showOtherMonths'
    static SELECTOTHERMONTHS = 'selectOtherMonths'
    static NUMBEROFMONTHS = 'numberOfMonths'
    static VIEW = 'view'
    static TOUCHUI = 'touchUI'
    static MINDATE = 'minDate'
    static MAXDATE = 'maxDate'
    static SHOWBUTTONBAR = 'showButtonBar'
    static SHOWTIME = 'showTime'
    static SHOWSECONDS = 'showSeconds'
    static MANUALINPUT = 'manualInput'
    static READONLY = 'readonly'

}
