<template>
    <div :id="'searchComp_' + formKey">
        <Panel class="field col-12 md:col-12" :toggleable="true" :collapsed="false">
            <template #header>
                <div :class="{ 'flex flex-wrap gap-1': true }">
                    <Button v-if="showBtnSearch && dataFilter.fields.length > 0" id="buscar" :label="t('btn.buscar')"
                        icon="pi pi-search" class="mr-2 p-button-primary" @click="executeSearch()"
                        style="width:fit-content;" />
                    <Button v-if="showBtnClear && dataFilter.fields.length > 0" id="limpiarfiltros"
                        :label="t('btn.limpiar')" icon="pi pi-trash" class="mr-2 p-button-outlined p-button-secondary"
                        @click="clearFilterData()" style="width:fit-content;" />
                    <Button v-if="showBtnCloseTabs && dataFilter.fields.length > 0" id="cerrarpestanias"
                        v-tooltip="t('tooltip.cerrar')" :label="t('btn.cerrar')" icon="pi pi-times"
                        class="mr-2 p-button-outlined p-button-secondary" @click="$emit('closealltabs')"
                        style="width:fit-content;" />
                    <Button v-if="showSaveSearch && dataFilter.fields.length > 0" id="saveSearch"
                        v-tooltip="t('tooltip.saveSearch')" class="mr-2 p-button-outlined p-button-secondary"
                        :label="t('btn.saveSearch')" icon="pi pi-save" :model="savedSearchs" @click="openSaveSearch"
                        style="width:fit-content;" />
                    <ToggleButton v-model="advSearch" onLabel="Avanzada" offLabel="Avanzada" onIcon="pi pi-lock-open" 
                        offIcon="pi pi-lock" class="w-9rem" aria-label="" @update:modelValue="onChangeSearch"/>                        
                </div>
            </template>
            <div class="card">
                <ul class="tree">
                    <searchTree :container="container" v-model="dataFilter" :catalogosData="catalogosData"
                        v-model:allTree="dataFilter" :isRoot="true" :compDataSource="compDataSource" :advancedSearch="advSearch"></searchTree>
                </ul>
            </div>

        </Panel>
        <OverlayPanel ref="opSaveSearch"  :dismissable="false" :showCloseIcon="true">
            <div class="card" style="height: 100%">
                <TabView>
                    <TabPanel header="Búsquedas propias">
                        <SaveSearch :container="container" :applicationId="applicationId"
                            :applicationVersion="applicationVersion" :componentId="componentId"
                            v-model:dataFilter="dataFilter" :compDataSource="compDataSource" :searchType="SearchConfigTypeConst.SEARCHCONFIGTYPE_CUS"
                            :columnFilters="columnFilters" :sortData="sortData" :catalogosData="catalogosData"
                            @click:setSearch="loadSearch" />

                    </TabPanel>
                    <TabPanel header="Últimas búsquedas">
                        <SaveSearch :container="container" :applicationId="applicationId"
                            :applicationVersion="applicationVersion" :componentId="componentId"
                            v-model:dataFilter="dataFilter" :compDataSource="compDataSource"
                            :catalogosData="catalogosData" @click:setSearch="loadSearch" :searchType="SearchConfigTypeConst.SEARCHCONFIGTYPE_NCUS"
                            :columnFilters="columnFilters" :sortData="sortData" :newAutomaticAdd="newAutomaticAdd" />

                    </TabPanel>
                    <TabPanel header="Listas">
                        <SaveSearch :container="container" :applicationId="applicationId"
                            :applicationVersion="applicationVersion" :componentId="componentId"
                            v-model:dataFilter="dataFilter" :compDataSource="compDataSource" :searchType="SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST"
                            :columnFilters="columnFilters" :sortData="sortData" :catalogosData="catalogosData"
                            @click:setSearch="loadSearch" />
                    </TabPanel>
                </TabView>

            </div>

        </OverlayPanel>
    </div>
</template>
<script lang="ts">

import {  defineComponent, onMounted, ref, watch,  onBeforeMount,  onUnmounted } from 'vue';
import { Container } from 'inversify';
import { useI18n } from 'vue-i18n';
import { messages } from './localization/MessagesBusqueda'
import { IapCatalog } from '../../../catalog/domain/iapCatalog';
import { SearchData, GroupSearch } from '../../../search/domain/search';
import DataSourceComp from '../functions/dataSourceComp';
import FiltroBusquedaConst from '../../../../../common/domain/constantes/FiltroBusquedaConst';
import CatalogTypeConst from '../../../../../common/domain/constantes/CatalogTypeConst';
import SqlTypesConst from '../../../../../common/domain/constantes/SqlTypesConst';
import { TYPES } from '../../../../../common/domain/types';
import HelperLoading from '../../../../../common/infrastructure/funciones/HelperLoading';
import { IapComponentDataSource } from '../../../component/domain/iapComponentDataSource';
import SaveSearch from './SaveSearch.vue'
import SearchTree from './SearchTree.vue'
import { IampSearchConfig } from '@ilinium/shared/src/entidades/builderMaster/user/domain/iapmSearchConfig';
import HelperUtils from '../../../../../common/infrastructure/funciones/HelperUtils';
import { useStore } from 'vuex';
import HelperCommon from '../../../../../common/infrastructure/funciones/HelperCommon';
import { IServiceSearchConfig } from '../../../../builderMaster/user/application/IServiceSearchConfig';
import OperatorLogicConst from '../../../search/domain/Const/OperatorLogicConst';
import SearchConfigTypeConst from '../../../../builderMaster/user/domain/const/SearchConfigTypeConst';

export default defineComponent({
    name: 'search',
    props: {
        container: {
            type: Object as () => Container
        },
        applicationId: {
            type: Number,
            default: -1
        },

        applicationVersion: {
            type: Number,
            default: -1
        },
        componentId: {
            type: Number,
            default: -1
        },
        modelValue: {
            type: Object as () => GroupSearch,
            default: () => ([])
        },
        dataSearch: {
            type: Object as () => any,
            default: () => ([])
        },
        compDataSource: {
            type: Object as () => IapComponentDataSource,
            default: () => ({})
        },
        catalogosData: {
            type: Object as () => IapCatalog[],
            default: () => ([])
        },
        showBtnSearch: {
            type: Boolean,
            default: true
        },
        showBtnClear: {
            type: Boolean,
            default: true
        },
        showBtnCloseTabs: {
            type: Boolean,
            default: false
        },
        showSaveSearch: {
            type: Boolean,
            default: true
        },
        maxRegs: {
            type: Number,
            default: 100
        },
        formKey: {
            type: String,
            default: ''
        },
        tabIndex: {
            type: Number,
            default: 0
        },
        columnFilters: {
            type: Object as () => any,
            default: {}
        },
        sortData: {
            type: Object as () => any[],
            default: []
        },
        advancedSearch: {
            type: Boolean,
            default: false
        },
  



    },
    emits: ['update:modelValue', 'update:catalogosData', 'update:dataSearch', 'closealltabs', 'buscarDatos', 'update:colFilters','update:sortData'],
    components: {

        SaveSearch,
        SearchTree

    },
    setup(props, { emit }) {
        const { t, locale } = useI18n(messages)
        const store = useStore();
        const { catalogos, dataFilter, isType,
            dataSearchResult,
            tablas,
            tablasData,
            getFieldData
        } = DataSourceComp(props.container as any, props, emit, t, props.compDataSource, props.catalogosData, store)


        const fields = ref<Partial<SearchData>[]>([]);
        const opSaveSearch = ref();
        const containerSize = ref();
        const initialTabIndex = ref();
        const newAutomaticAdd = ref<IampSearchConfig>();
        const lg = ref(false);
        const md = ref(false);
        const xl = ref(false);
        const sm = ref(false);
        const advSearch=ref(props.advancedSearch);
        let savedSearchs = ref([{ label: 'Búsqueda 1' }, { label: 'Búsqueda 2' }]);

        watch(locale, () => {

        });


        const initData = (forceInitData:boolean=false) => {
            
            if (!dataFilter.value || forceInitData) {
                if(fields.value.length==0){
                    const firstTabla = tablas.value[0]?.tableName ?? ''
                        tablasData.value.filter(x => x.tableName.toLocaleLowerCase() == firstTabla.toLocaleLowerCase() && props.compDataSource.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == x.id)?.defaultFilterSearch)
                            .sort((a, b) => { return (props.compDataSource.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == a.id)?.defaultFilterSearchOrder ?? 0) - (props.compDataSource.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == b.id)?.defaultFilterSearchOrder ?? 0); })
                            .forEach(c => {
                                    fields.value.push({
                                    id: HelperUtils.newGuid().toString().replaceAll('-', ''),
                                    fieldId: c.id as any,
                                    rangeDateTime: {
                                        from: null,
                                        to: null
                                    },
                                    rangeValue: {
                                        from: null,
                                        to: null
                                    },
                                    filter: isType(c, SqlTypesConst.DATETIME) || isType(c, SqlTypesConst.DATE) || isType(c, SqlTypesConst.INT) || isType(c, SqlTypesConst.MONEY)   ? FiltroBusquedaConst.FILTROBUSQUEDA_IGUAL : FiltroBusquedaConst.FILTROBUSQUEDA_CONTIENE,
                                } as any)
                            
                            })
                }

                dataFilter.value = {
                    operatorLogic: OperatorLogicConst.AND,
                    fields: !advSearch.value?fields.value:[],
                    children: []
                }
            }

        }


        const clearFilterData = () => {

            if (!dataFilter) {
                initData()
            }
            else {
                clearTreeData(dataFilter.value);
            }


        }

        const clearTreeData = (groupSearch: GroupSearch) => {

            if (groupSearch.fields.length > 0) {
                groupSearch.fields.forEach(x => {
                    if (!('filterValue' in getFieldData(x.fieldId)) || (('filterValue' in getFieldData(x.fieldId)) && props.compDataSource.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == x.fieldId)?.defaultFilterSearch)) {
                        x.value = null as any,
                            x.rangeDateTime = {
                                from: null,
                                to: null
                            },
                            x.rangeValue = {
                                from: null,
                                to: null
                            },
                            x.valueBool = undefined as any,
                            x.valueDateTime = undefined as any,
                            x.valueList = undefined as any,
                            x.valueNumber = undefined as any,
                            x.filter = isType(getFieldData(x.fieldId), SqlTypesConst.DATETIME) || isType(getFieldData(x.fieldId), SqlTypesConst.DATE) ||  isType(getFieldData(x.fieldId), SqlTypesConst.INT) ||  isType(getFieldData(x.fieldId), SqlTypesConst.MONEY)? FiltroBusquedaConst.FILTROBUSQUEDA_IGUAL : FiltroBusquedaConst.FILTROBUSQUEDA_CONTIENE
                    }

                })

            }

            if (groupSearch.children.length > 0) {
                groupSearch.children.forEach(x => {
                    clearTreeData(x);
                });
            }

        };

        const executeSearch = () => {

            if(dataFilter.value){
                emit('buscarDatos');
            }
            saveAutomaticSearchData();
        }

        const openSaveSearch = (event: any) => {
            opSaveSearch.value.toggle(event);

        }

        const closeSaveSearch = () => {
            opSaveSearch.value.hide();

        }

        const loadSearch = (data: IampSearchConfig) => {

            let savedData = JSON.parse(data.data)?.searchFilters as any;

            if (savedData) {

                HandleDatesTreeSearch(savedData);

                HelperLoading.showLoading();

                
                if(hasChildren(savedData)){//Si estoy cargando una busqueda guardada y tiene hijos se cambia automaticamente a busqueda avanzada.
                    advSearch.value=true;
                }


                dataFilter.value = savedData;

             
                emit('update:colFilters', JSON.parse(data.data)?.columnFilters);
                emit('update:sortData', JSON.parse(data.data)?.multiSortMeta);
                emit('buscarDatos');
              
            }
            else {
                    HelperLoading.hideLoading();
                }

                //cerramos ventana de busqueda
                closeSaveSearch();

            }

        const HandleDatesTreeSearch = (data: any) => {

            HelperUtils.handleDates(data.fields);

            if (data.children && data.children.length > 0) {
                data.children.forEach(x => {
                    HandleDatesTreeSearch(x.fields);
                })
            }
        }

        const hasChildren=(data:any)=>{
            return (data?.children && data.children.length > 0);
        }


        const saveAutomaticSearchData = () => {

            const formattedDate = HelperCommon.formateaFechaHora(new Date(), true);

            const data = ref<IampSearchConfig>({
                id:0,
                userId: store.getters.getCurrentUser.id,
                applicationId: props.applicationId,
                applicationVersion: props.applicationVersion,
                componentId: props.componentId,
                profileId: store.getters.getCurrentProfile != 0 ? store.getters.getCurrentProfile : null,
                name: formattedDate ? formattedDate.toString() : new Date().toString(),
                data: JSON.stringify(
                    {
                        searchFilters: dataFilter.value,
                        columnFilters: props.columnFilters,
                        multiSortMeta:[]

                    }),
                fcr: new Date(),
                idSearchConfigType:SearchConfigTypeConst.SEARCHCONFIGTYPE_NCUS,
                ucr:store.getters.getCurrentUser.id,
                group:null
            });
            

            if (props.container) {
                let dataInput = JSON.parse(JSON.stringify(data.value));
                const _srv = props.container.get<IServiceSearchConfig>(
                    TYPES.SEARCH_USR_REPOSITORY
                );
                HelperLoading.showSaving();
                _srv.add(dataInput as any)
                    .then((response) => {
                        newAutomaticAdd.value = response;
                    })
                    .finally(() => {
                        HelperLoading.hideSaving();
                    });
            }

        }

        const setSizeScreen = () => {

            sm.value = false;
            lg.value = false;
            md.value = false;
            xl.value = false;

            if (containerSize.value) {

                if (containerSize.value <= 641) {
                    sm.value = true;
                }
                else if (containerSize.value > 641 && containerSize.value <= 1007) {
                    md.value = true;
                }
                else if (containerSize.value > 1007 && containerSize.value <= 1200) {
                    lg.value = true;
                }
                else if (containerSize.value > 1200) {
                    xl.value = true;
                }

            }


        }



        const getContainerSize = () => {

            if (props.tabIndex == 1) {

                document?.getElementById('searchComp_' + props.formKey)?.parentNode?.parentNode?.parentNode?.firstElementChild?.removeAttribute('style');

                containerSize.value = document?.getElementById('searchComp_' + props.formKey)?.offsetWidth;

                document?.getElementById('searchComp_' + props.formKey)?.parentNode?.parentNode?.parentNode?.firstElementChild?.setAttribute('style', 'display: none;');

            }
            else if (props.tabIndex == 0 && initialTabIndex.value == 1) {
                containerSize.value = document?.getElementById('searchComp_' + props.formKey)?.offsetWidth;
            }


        }

        const handleWindowResize = () => {
            getContainerSize();
            setSizeScreen();

        }
        
        const onChangeSearch=(event:any)=>{
            advSearch.value=event;
            initData(true);
            
        }



        onMounted(() => {

            initialTabIndex.value = props.tabIndex;

            getContainerSize();
            setSizeScreen();

            //metemos evento
            window.addEventListener('resize', handleWindowResize);

        });

        onBeforeMount(() => {

            initData();

        });

        onUnmounted(() => {
            window.removeEventListener('resize', handleWindowResize);
        })



        return {
            t,
            locale,
            dataFilter,
            clearFilterData,
            SqlTypesConst,
            isType,
            catalogos,
            CatalogTypeConst,
            dataSearchResult,
            savedSearchs,
            tablas,
            tablasData,
            opSaveSearch,
            openSaveSearch,
            loadSearch,
            executeSearch,
            newAutomaticAdd,
            FiltroBusquedaConst,
            md,
            lg,
            xl,
            sm,
            containerSize,
            getFieldData,
            SearchConfigTypeConst,
            onChangeSearch,
            advSearch
        };
    },
});
</script>
<style scoped>
.tree {
    --spacing: 1.5rem;
    --radius: 10px;
}
</style>
<style lang="scss" scoped>
::v-deep(.p-fluid .p-button) {
    width: fit-content;
}
</style>
