import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';

import { IDataSourceRepository } from '../domain/IDataSourceRepository';

import { GET_ALL_DATASOURCE, GET_DATASOURCE_BY_ID } from './query/dataSourceQuery';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { IapDataSource } from '../domain/iapDataSource';
import { IapDataSourceDataBase } from "../domain/iapDataSourceDataBase";
import { IapDataSourceField } from "../domain/iapDataSourceField";
import { IapDataSourceService } from "../domain/iapDataSourceService";
import { IapDataSourceTableAlias } from "../domain/iapDataSourceTableAlias";
import { IapDataSourceServiceConfiguration } from "../domain/iapDataSourceServiceConfiguration"
import { ADD, UPDATE,REFRESH_FIELDS,DELETE,MODIFY } from './mutation/dataSourceMutation';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class DataSourceApolloClientRepository implements IDataSourceRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId=ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }

  async update (dataInput: IapDataSource): (Promise<IapDataSource>) {
    return new Promise<IapDataSource>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE,{data:dataInput},this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.dataSourceMutation.update)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async delete (dataSourceId: number): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(DELETE, { dataSourceId: dataSourceId }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.dataSourceMutation.deleteDataSource)
        }
        catch(e) {
          reject()
        }
      });
    });
  }
  
  


  async add (dataInput: IapDataSource, configurations: IapDataSourceServiceConfiguration[], tables:IapDataSourceTableAlias[], db:IapDataSourceDataBase, srv:IapDataSourceService,fields:IapDataSourceField[]): (Promise<IapDataSource>) {
    return new Promise<IapDataSource>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD, { data:dataInput,configurations:configurations,tables:tables,db:db,srv:srv,fields:fields }, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.dataSourceMutation.add)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async modify (dataInput: IapDataSource,tables:IapDataSourceTableAlias[], db:IapDataSourceDataBase, srv:IapDataSourceService,fields:IapDataSourceField[]): (Promise<IapDataSource>) {
    return new Promise<IapDataSource>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(MODIFY, { data:dataInput,tables:tables,db:db,srv:srv,fields:fields }, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.dataSourceMutation.modify)
        }
        catch(e) {
          reject()
        }
      });

    });
  }


  async refreshFields (dataInput: IapDataSource): (Promise<IapDataSource>) {
    return new Promise<IapDataSource>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(REFRESH_FIELDS, { data:dataInput }, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.dataSourceMutation.refreshFields)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async getAll (applicationId:number, applicationVersion:number): (Promise<IapDataSource[]>) {
    return new Promise<IapDataSource[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_ALL_DATASOURCE, { applicationId:applicationId,applicationVersion:applicationVersion }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.dataSourceQuery.getAll)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getById (applicationId:number, applicationVersion:number,datasourceId: number): (Promise<IapDataSource>) {
    return new Promise<IapDataSource>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_DATASOURCE_BY_ID, { applicationId:applicationId,applicationVersion:applicationVersion,datasourceId:datasourceId }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.dataSourceQuery.getById)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


}


