import { ClassicPreset } from 'rete';
import { AreaPlugin } from 'rete-area-plugin';
import { AreaExtra, Schemes } from '../rete/helperRete';
import { Router } from 'vue-router';
import { Container } from 'inversify';
import { IapComponent } from '../../../../../component/domain/iapComponent';
import { IapWorkFlowActivity } from '../../../../domain/service/iapWorkFlowActivity';
import { ValidateComponentControl } from '../controls/validateComponentControl';
import EventBusCustom from '../../../../../../../common/infrastructure/event-bus-custom';
import EventConst from '../../../../../../../common/domain/constantes/EventConst';
import InteractionConst from '../../../../../interaction/domain/interactionConst';
import { InteractionEvent } from '../../../../../interaction/domain/interactionEvent';
import BaseControlTypeConst from '../../../../../form/domain/Constants/BaseControlTypeConst';
import { DataflowEngine } from 'rete-engine';
import ComponentUtil from '../../../../../designer/infrastructure/component/util/componentUtil';
import WidgetComponentDataTree from '../../../../../designer/domain/widgetComponent';
import ControlTypeConst from  '../../../../../form/domain/Constants/ControlTypeConst';
import OperationDataTypeConst from '../../../../../../../common/domain/constantes/OperationDataTypeConst';

export class ValidateComponentNode extends ClassicPreset.Node<
  { ejecutar: ClassicPreset.Socket  },
  { true: ClassicPreset.Socket, false: ClassicPreset.Socket },
  { value: ValidateComponentControl }
> {
  height = 220;
  width = 420;


  private variableComponent: number;

  private area: AreaPlugin<Schemes, AreaExtra>;
  private updateNode: any;
  private getNodeInternalData: any;
  private showExpression: any;
  private rdControlId: '';
  private router: Router;
  private container: Container | undefined;
  private rootComponentId: number;
  private currentComponentId: number;
  private store: any;
  private activity: IapWorkFlowActivity | undefined;
  private componentData: IapComponent[];
  private dataflow: DataflowEngine<Schemes>
  private formKey:String;
  private currentElementKey:string;
  private applicationId: number;
  private applicationVersion: number;
  constructor(area: AreaPlugin<Schemes, AreaExtra>, socket: ClassicPreset.Socket,public formKeyInput: String,public currentElementKeyInput:string, public rootComponentInputId: number, public currentComponentInputId: number, public applicationIdInput: number, public applicationVersionInput: number, public variableComponentInput: number, updateNode: any = undefined, getNodeInternalData: any = undefined, showExpressionFunction: any = undefined, router: Router, container: Container | undefined, storeInput: any, itemActivity: IapWorkFlowActivity | undefined, dataflow: DataflowEngine<Schemes>,componentDataInput: IapComponent[]) {
    super("Validate Component");
    this.area = area;
    this.dataflow = dataflow;
    this.updateNode = updateNode;
    this.getNodeInternalData = getNodeInternalData;
    this.showExpression = showExpressionFunction;

    this.variableComponent = variableComponentInput;

    
    this.router = router;
    this.container = container;
    this.rootComponentId = rootComponentInputId;
    this.currentComponentId = currentComponentInputId;
    this.store = storeInput;
    this.activity = itemActivity;    
    this.componentData = componentDataInput;
    this.formKey = formKeyInput;
    this.currentElementKey = currentElementKeyInput;
    this.applicationId = applicationIdInput;
    this.applicationVersion = applicationVersionInput;

    const dsControl = new ValidateComponentControl(formKeyInput,rootComponentInputId, currentComponentInputId, applicationIdInput, applicationVersionInput, variableComponentInput,container, this.updateData, this.getNode, this.showExp);

    this.rdControlId = (dsControl as any).id;

    this.addInput("ejecutar", new ClassicPreset.Input(socket, "Ejecutar", true));
    this.addControl(
      "value",
      dsControl

      //new ClassicPreset.InputControl("text", { initial })
    );

    this.addOutput("true", new ClassicPreset.Output(socket, "True", true));
    this.addOutput("false", new ClassicPreset.Output(socket, "False", true));

  }

  showExp = (evt: any) => {
    if (this.showExpression) {
      return this.showExpression(evt)
    }
    return null;
  }

  getNode = (key: string) => {
    if (this.getNodeInternalData) {
      return this.getNodeInternalData(this.id, key, true, false)
    }
    return null;
  }
  updateData = (evt: any) => {

    //this.value = evt
    //@ts-ignore:disable-next-line
    this.controls.value[evt.key] = evt.data;
    //this.controls[evt.key].valueConnection = evt.data;

    this.area.update("control", this.rdControlId)


    if (this.updateNode) {
      this.updateNode(this.id, evt.key, JSON.stringify(evt.data), (evt?.operation ?? OperationDataTypeConst.UPDATE))
    }


  }


  createPromiseInteraction = async (element: any): Promise<InteractionEvent> => {

    return await new Promise<InteractionEvent>((resolve, reject) => {
      let typeid = element.typeId == 'attr' ? InteractionConst.SET_PROPERTY_VALUE : InteractionConst.CALL_FUNCTION;
      const objectname = element.typeId == 'attr' ? null : 'processEvent()';

      if (typeid == InteractionConst.SET_PROPERTY_VALUE && element.name == BaseControlTypeConst.MODELVALUE)
      {
        typeid = InteractionConst.SET_VMODEL;
      }

      var data: Partial<InteractionEvent> = {
        objectId: element.id,
        typeId: typeid,
        objectValue: element.value,
        objectName: objectname

      }

      const keyComponentEventBus = this.formKey +  this.rootComponentId.toString() + this.variableComponent.toString() + EventConst.INTERACTION;


      var doCallbackOk = (response: InteractionEvent) => {
        //console.log('respuesta:' + compId.toString())
        resolve(response);
      }

      EventBusCustom.emit(keyComponentEventBus, { data: data, callBackResponse: doCallbackOk });


    });


  };


  createPromiseValidation = async (compId: number): Promise<InteractionEvent> => {

    return await new Promise<InteractionEvent>((resolve, reject) => {
        var data: Partial<InteractionEvent> = {
            typeId: InteractionConst.CALL_FUNCTION,
            objectName: 'isValidData()'

        }
        const keyComponentEventBus = this.formKey + this.rootComponentId.toString() + compId.toString() + EventConst.INTERACTION;


        var doCallbackOk = (response: InteractionEvent) => {
            //console.log('respuesta:' + compId.toString())
            resolve(response);
        }

        EventBusCustom.emit(keyComponentEventBus, { data: data, callBackResponse: doCallbackOk });


    });


};


  async execute(_: never, forward: (output: "true" | "false") => void) {
    
    if (this.variableComponent > 0)
    {

      const { nest, getTreeFlat, searchTree } = ComponentUtil();
      

      const dataRep = nest(this.componentData as any, null, 'parentId') as WidgetComponentDataTree[]
      const compTree = searchTree(dataRep[0],this.variableComponent)
      const dsc = getTreeFlat(compTree, "loaded")


      var promises = new Array();
      let canDo = false;

      const validableControls=dsc.filter(x=>ControlTypeConst.VALIDABLE_CONTROLS.includes(x.idType));
      
      validableControls.forEach(comp => {
  
          promises.push(this.createPromiseValidation(comp.id));
      });
  
  
   
  
      await Promise.all(promises).then((responses: InteractionEvent[]) => {
          canDo = !(responses.filter(x => !x.interactionResult).length > 0)
      })
  
  
      if(canDo){
          forward("true"); 
      }
      else{
          forward("false"); 
      }
  

    }
    else{
      forward("true"); 
    }


  }


  data(): { value: string } {
    return {
      //@ts-ignore:disable-next-line
      value: this.controls.value['value'] || ""
    };
  }

}