import { ClassicPreset } from 'rete';
import { AreaPlugin } from 'rete-area-plugin';
import { AreaExtra, Schemes } from '../rete/helperRete';
import { StoreProcedureControl } from '../controls/storeProcedureControl';
import { LocalService } from '../../../../../../../common/infrastructure/servicios';
import { Router } from 'vue-router';
import { Container } from 'inversify';
import { IServiceSearch } from '../../../../../search/application/IServiceSearch';
import { TYPES } from '../../../../../../../common/domain/types';
import HelperLoading from '../../../../../../../common/infrastructure/funciones/HelperLoading';
import { ProcData } from '../../../../../search/domain/search';
import { IapComponent } from '../../../../../component/domain/iapComponent';
import CatalogObjectTypeConst from '../../../../../catalog/domain/const/CatalogObjectTypeConst';
import CatalogExpConst from '../../../../../catalog/domain/const/CatalogExpConst';
import { ProcedureData } from '../../../../../search/domain/procedureData';
import { ExpresionEngine } from '../../../../../expression/infrastructure/helper/expressionEngine';
import { IapWorkFlowActivity } from '../../../../domain/service/iapWorkFlowActivity';
import StoreProcedureNodeConst from '../constants/StoreProcedureNodeConst';
import { DataflowEngine } from 'rete-engine';
import HelperUtils from '../../../../../../../common/infrastructure/funciones/HelperUtils';
import OperationDataTypeConst from '../../../../../../../common/domain/constantes/OperationDataTypeConst';


export class StoreProcedureNode extends ClassicPreset.Node<
  { ejecutar: ClassicPreset.Socket, dataInput: ClassicPreset.Socket },
  { ejecutar: ClassicPreset.Socket, value: ClassicPreset.Socket },
  { value: StoreProcedureControl }
> {
  height = 500;
  width = 380;

  //private value: Number;
  private variableConnection: string;
  private variableProcName: string;
  private variableParamsInput: ProcedureData[];
  private area: AreaPlugin<Schemes, AreaExtra>;
  private updateNode: any;
  private getNodeInternalData: any;
  private showExpression: any;
  private rdControlId: '';
  private router: Router;
  private container: Container | undefined;
  private rootComponentId: number;
  private currentComponentId: number;
  private store: any;
  private activity: IapWorkFlowActivity | undefined;

  private componentData: IapComponent[];
  private formKey:String;
  private currentElementKey:string;
  private dataflow: DataflowEngine<Schemes>
  
  constructor(area: AreaPlugin<Schemes, AreaExtra>, socket: ClassicPreset.Socket,public formKeyInput: String,public currentElementKeyInput:string, public rootComponentInputId: number, public currentComponentInputId: number, public applicationId: number, public applicationVersion: number, public variableConnectionInput: string, public variableProcNameInput: string, public variableParametersInput: [], updateNode: any = undefined, getNodeInternalData: any = undefined, showExpressionFunction: any = undefined, router: Router, container: Container | undefined, storeInput: any, itemActivity: IapWorkFlowActivity | undefined, dataflow: DataflowEngine<Schemes>,componentDataInput: IapComponent[]) {
    super("Store Procedure");
    this.area = area;
    this.updateNode = updateNode;
    this.getNodeInternalData = getNodeInternalData;
    this.showExpression = showExpressionFunction;
    this.variableConnection = variableConnectionInput;
    this.variableProcName = variableProcNameInput;
    this.variableParamsInput = variableParametersInput;
    this.router = router;
    this.container = container;
    this.rootComponentId = rootComponentInputId;
    this.currentComponentId = currentComponentInputId;
    this.store = storeInput;
    this.activity = itemActivity;
    this.componentData = componentDataInput;
    this.formKey = formKeyInput;
    this.currentElementKey = currentElementKeyInput;
    this.dataflow = dataflow;

    const dsControl = new StoreProcedureControl(formKeyInput,rootComponentInputId, currentComponentInputId, applicationId, applicationVersion, variableConnectionInput, variableProcNameInput, variableParametersInput, container, this.updateData, this.getNode, this.showExp);

    this.rdControlId = (dsControl as any).id;

    this.addInput("ejecutar", new ClassicPreset.Input(socket, "Ejecutar", true));
    this.addControl(
      "value",
      dsControl

      //new ClassicPreset.InputControl("text", { initial })
    );

    this.addInput("dataInput", new ClassicPreset.Input(socket, "DataInput"));

    this.addOutput("ejecutar", new ClassicPreset.Output(socket, "Ejecutar"));


    //area.update("control",dsControl.id)
    this.addOutput("value", new ClassicPreset.Output(socket, "Valor"));





  }

  showExp = (evt: any) => {
    if (this.showExpression) {
      return this.showExpression(evt)
    }
    return null;
  }

  getNode = (key: string) => {
    if (this.getNodeInternalData) {
      return this.getNodeInternalData(this.id, key, true, false)
    }
    return null;
  }
  updateData = (evt: any) => {

    //this.value = evt
    //@ts-ignore:disable-next-line
    this.controls.value[evt.key] = evt.data;
    //this.controls[evt.key].valueConnection = evt.data;

    this.area.update("control", this.rdControlId)


    if (this.updateNode) {
      this.updateNode(this.id, evt.key, JSON.stringify(evt.data), (evt?.operation ?? OperationDataTypeConst.UPDATE))
    }


  }

  resolveExpressions = () =>{
  
   
    const currentComp = this.componentData.find(x => x.id == this.currentComponentId)

    const wcf = this.activity?.iapWorkFlowActivityControls.find(x => x.name == StoreProcedureNodeConst.VAR_PARAM_IN);

    if (currentComp && wcf) 
    {
      this.variableParamsInput.forEach(param => {
        const key = '#' + wcf.id.toString() + '#parameterName=' + param.parameterName;
        const exps = currentComp.expressions?.filter(x => x.idObjeto == CatalogObjectTypeConst.WFAC && x.idTypeExpression == CatalogExpConst.EXP_SET && x.objetoId.endsWith(key) && x.iapExpressionDetails?.length > 0);
        if (exps?.length > 0) {
          exps?.every(exp => {
            if (exp.iapExpressionDetails?.length > 0) {

              const localData = LocalService.getValue(this.formKey + LocalService.COMPONENTS_EXP + (this.rootComponentId ?? -1).toString());
              const data = HelperUtils.jsonParse(localData,[])
              let resu = ExpresionEngine.resolveExpressions(exp.iapExpressionDetails, data as any, this.store)
              //resu = resu?.toString();
              if (resu) {
                if (Object.keys(resu).length == 0) {
                  resu = resu?.toString();
                }
              }


              param.value = resu;


            }
          })

        }
      })

    }
 
    
  }

  async execute(input: "ejecutar", forward: (output: "ejecutar") => void) 
  {
    const inputs = (await this.dataflow.fetchInputs(this.id)) as {
      dataInput: any;
    };


    if (inputs && inputs?.dataInput && inputs.dataInput?.length >= 1) {

      
      inputs.dataInput.forEach( (data:any) =>{
        if (data) {
          //propValue
          this.variableParamsInput.filter((x: any) => x.value.startsWith('#') && x.value.endsWith('#')).forEach(param => {
            //@ts-ignore:disable-next-line
            const keyParam = param.value.replaceAll('#', '');
            param.value = HelperUtils.propValue(data, keyParam)?.toString() ?? ''
          });
  
        
        }
      })


    }
    


    // vamos a buscar las expresiones si las hubiera
    this.resolveExpressions();
    // fin de resolución de expresiones

    if (this.container) 
    {
      
      const requestData = this.variableParamsInput.map((x: ProcedureData) => ({
        procName: x.objectName,
        parameterName: x.parameterName,
        parameterType: x.parameterDataType,
        value: JSON.stringify(x.value)
      }) as ProcData)

      const data = JSON.parse(JSON.stringify(requestData))
      const _srv = this.container.get<IServiceSearch>(TYPES.SEARCH_REPOSITORY)
      HelperLoading.showLoading()
      
      _srv.executeProcedureOrFunction(this.applicationId, this.applicationVersion, this.variableConnection ?? '', '', data as any)
        .then(response => {
      
          //@ts-ignore:disable-next-line
          const aux = JSON.parse(response.items)
          HelperUtils.parseObjectJson(aux);   
           //@ts-ignore:disable-next-line      
           this.controls.value['value']= aux
          Object.keys(aux).forEach(k=>{
            const value = aux[k]
            Object.keys(value).forEach(j=>{
              if ( Array.isArray(value[j])){
                //@ts-ignore:disable-next-line  
                this.controls.value['value']= value[j][0]
              }
              else
              {
                Object.keys(value[j]).forEach(k=>{
                  if ( Array.isArray(value[j][k])){
                    //@ts-ignore:disable-next-line  
                    this.controls.value['value']=value[j][k][0]
                  }                 
                })
              }
            
            })            
          })
          
          
          forward("ejecutar");

        }).finally(()=>{
          HelperLoading.hideLoading()
        })



    }
  }


  data(): { value: string } {
    return {
      //@ts-ignore:disable-next-line
      value: this.controls.value['value'] || ""
    };
  }
  /*
  data(): { connection: String, procName: String, parametersInput:[] } {
    return {
      connection: this.controls.value['variableconnection'] ?? '',
      procName: this.controls.value['variableprocName'] ?? '',
      parametersInput: this.controls.value['variableparamsInput'] ?? '',

    };
    
  }
  */
}