import { ClassicPreset as Classic, NodeEditor } from 'rete';

import { AreaExtensions } from 'rete-area-plugin';

import { VuePlugin,  Presets as VuePresets } from 'rete-vue-plugin';


import {
  AutoArrangePlugin,
  Presets as ArrangePresets,
} from 'rete-auto-arrange-plugin';

import {
  ContextMenuPlugin

} from 'rete-context-menu-plugin';
import {  MinimapPlugin } from 'rete-minimap-plugin';
import {
  ReroutePlugin,
  RerouteExtensions,
} from 'rete-connection-reroute-plugin';
import {
  ConnectionPlugin,
  Presets as ConnectionPresets
} from 'rete-connection-plugin';
import { IapWorkFlow } from '../../../../domain/service/iapWorkFlow';
import { IapCatalogType } from '../../../../../catalog/domain/iapCatalogType';
import { StartNode } from '../nodes/startNode';
import { TextNode } from '../nodes/textNode';
import { Container } from 'inversify';
import { Dictionary } from '../../../../../expression/domain/dictionary';

import HelperRete, { AreaExtra, Connection, Schemes } from './helperRete';
import { DataSourceControl } from '../controls/dataSourceControl';
import { RedirectControl } from '../controls/redirectControl';
import {StoreProcedureControl} from '../controls/storeProcedureControl';
import  CustomDataSourceControl  from '../customization/CustomDataSourceControl.vue';
import  CustomRedirectControl  from '../customization/CustomRedirectControl.vue';
import CustomStoreProcedureControl from '../customization/CustomStoreProcedureControl.vue';
import { DataSourceNode } from '../nodes/dataSourceNode'
import { ValidateComponentNode } from '../nodes/validateComponentNode';
import { RedirectNode } from '../nodes/redirectNode'
import { IapComponent } from '../../../../../component/domain/iapComponent';
import { DelayNode } from '../nodes/delayNode'
import { StoreProcedureNode } from '../nodes/storeProcedureNode';
import { Router } from 'vue-router';
import {MessageNode} from '../nodes/messageNode';
import {MessageHideNode} from '../nodes/messageHideNode'
import { MessageControl } from '../controls/messageControl';
import CustomMessageControl from '../customization/CustomMessageControl.vue'
import { DisplayComponentNode } from '../nodes/displayComponentNode';
import { ChangeComponentNode } from '../nodes/changeComponentNode';
import { DisplayComponentControl } from '../controls/displayComponentControl';
import CustomDisplayComponentControl from '../customization/CustomDisplayComponentControl.vue';
import CustomChangeComponentControl from '../customization/CustomChangeComponentControl.vue';
import CustomValidateComponentControl from '../customization/CustomValidateComponentControl.vue';
import { ConditionNode } from '../nodes/conditionNode';
import { ConditionControl } from '../controls/conditionControl';
import CustomConditionControl from '../customization/CustomConditionControl.vue';
import { TextControl } from '../controls/textControl';
import CustomTextControl from '../customization/CustomTextControl.vue';
import { ChangeComponentControl } from '../controls/changeComponentControl';
import { EndNode } from '../nodes/endNode';
import { ValidateComponentControl } from '../controls/validateComponentControl';
import {UbmNode} from '../nodes/ubmNode';
import { UbmControl } from '../controls/ubmControl';
import CustomUbmControl from '../customization/CustomUbmControl.vue'
import {SendMailNode} from '../nodes/sendMailNode';
import { SendMailControl } from '../controls/sendMailControl';
import CustomSendMailControl from '../customization/CustomSendMailControl.vue'
import OperationDataTypeConst from '../../../../../../../common/domain/constantes/OperationDataTypeConst';



export async function createEditor(container: HTMLElement, wfData: IapWorkFlow, applicationId:number, applicationVersion:number,rootParentId:number,formKey:String,currentElementKey:string,componentId:number,componentData:IapComponent[], catalogTypes: IapCatalogType[], srvContainer: Container | undefined, store: any,router:Router,callbackShowExpressions: any = undefined,callBackEndFunction:any=undefined) {
  
  const editor = new NodeEditor<Schemes>();

  const  updateNode = (nodeId:string, variableName:string,variableValue:any,operation:number = OperationDataTypeConst.UPDATE) =>{
    const currentNode =  editor.getNode(nodeId);
    if (currentNode){
      switch(operation){
        case  OperationDataTypeConst.UPDATE:
          updateWorkFlowActivityControlFromContext(currentNode.id,variableName,variableValue)
          break;
       case  OperationDataTypeConst.INSERT:
            addWorkFlowActivityControlFromContext(currentNode.id,variableName,variableValue)
            break;
       case  OperationDataTypeConst.DELETE:
            deleteWorkFlowActivityControlFromContext(currentNode.id,variableName)
            break;

      }
      
      //else{
      //  updateWorkFlowActivityVariableFromContext(currentNode.id,variableName,variableValue,isInput)
     // }
      
    }

  }


  const  getNodeInternalData = (nodeId:string, variableName:string,isControl:boolean=false, isInput:boolean=false) =>{
    const currentNode =  editor.getNode(nodeId);
    if (currentNode){
      if (isControl){
        return getWorkFlowActivityControlFromContext(currentNode.id,variableName)
      }
      else{
        return getWorkFlowActivityVariableFromContext(currentNode.id,variableName,isInput)
      }
      
    }

  }


  const  showExpressionControl = (evt:{objetoId:string, idObjeto:string,description:string}) =>{
    if (callbackShowExpressions) 
    {   
      callbackShowExpressions(evt)
    }
  

  }



  const {    dataSources,wf,dataflow,area, socket,addDataToEditor, deleteWorkFlowActivity, 
            addWorkFlowActivityFromContext, addWorkFlowActivityConnectionFromContext, removeWorkFlowActivityConnectionFromContext, 
            updateWorkFlowActivityControlFromContext,
            addWorkFlowActivityControlFromContext,deleteWorkFlowActivityControlFromContext,getWorkFlowActivityControlFromContext,
            getWorkFlowActivityVariableFromContext
} = HelperRete(router,container,wfData, catalogTypes, srvContainer, store, applicationId, applicationVersion,rootParentId,formKey,currentElementKey,componentId,componentData,updateNode,getNodeInternalData,showExpressionControl )
  
  
  const connection = new ConnectionPlugin<Schemes, AreaExtra>();
  const vueRender = new VuePlugin<Schemes, AreaExtra>();


  editor.use(dataflow);

  
    const contextMenu = new ContextMenuPlugin<Schemes>({
      items(context) {
        
        if (context === 'root') {
          return {
            searchBar: true,
            list: [              
              { label: 'Message', key: '0', handler: () => editor.addNode(new MessageNode(area,socket,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion, '',1,true,false,updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,store,undefined, dataflow,componentData)) },              
              { label: 'Message Hide', key: '0.1', handler: () => editor.addNode(new MessageHideNode(socket)) },              
              { label: 'Condition', key: '1', handler: () => editor.addNode(new ConditionNode(area,socket,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion, '',updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,store,undefined,dataflow,componentData)) },              
              //{ label: 'Log', key: '1', handler: () =>  editor.addNode(new LogNode(socket, { text: '' } as any, dataflow)) },
              { label: 'Text', key: '2', handler: () => editor.addNode(new TextNode(area,socket,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion, '',updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,store,undefined,componentData)) },              
              { label: 'Redirect', key: '3', handler: () => editor.addNode(new RedirectNode(area,socket,formKey,currentElementKey,rootParentId,componentId,0,'','_blank','',-1,[] as any,updateNode,getNodeInternalData,showExpressionControl,router,store,componentData,undefined,dataflow)) },              
              { label: 'DataSource', key: '4', handler: () => editor.addNode(new DataSourceNode(area,socket
                ,dataflow
                ,updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,store,undefined
                ,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion,-1,-1,-1,-1,[] as any,'','',[] as any
                ,[] as any,dataSources,componentData))},          
              { label: 'Delay', key: '5', handler: () => editor.addNode(new DelayNode(socket, 2,updateNode)) },              
              { label: 'Store Proc.', key: '6', handler: () => editor.addNode(new StoreProcedureNode(area,socket,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion, '','',[] as any,updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,store,undefined,dataflow,componentData)) },
              { label: 'Display Comp.', key: '7', handler: () => editor.addNode(new DisplayComponentNode(area,socket,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion, -1,'',false,[] as any,false,updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,store,undefined,dataflow,componentData)) },
              { label: 'Change Comp.', key: '8', handler: () => editor.addNode(new ChangeComponentNode(area,socket,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion, -1,[] as any,[] as any,updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,store,undefined,dataflow,componentData)) },
              { label: 'Validate Comp.', key: '9', handler: () => editor.addNode(new ValidateComponentNode(area,socket,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion, -1,updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,store,undefined,dataflow,componentData)) },
              { label: 'Ubm Chat', key: '10', handler: () => editor.addNode(new UbmNode(area,socket,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion, '',updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,store,undefined, dataflow,componentData)) },
              { label: 'Send Mail', key: '11', handler: () => editor.addNode(new SendMailNode(area,socket,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion, '', '', '', '', updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,store,undefined, dataflow,componentData)) },
           
            ]
          }
        }

      if (!(context instanceof StartNode) && !(context instanceof EndNode)){
        return {
          searchBar: true,
          list: [
            //{ label: 'Expresiones', key: '1', handler: () => showExpressionOnNode(context) },
            { label: 'Eliminar', key: '1', handler: () => editor.removeNode(context.id) },
            /*{
              label: 'Collection', key: '1', handler: () => null,
              subitems: [
                { label: 'Subitem', key: '1', handler: () => console.log('Subitem') }
              ]
            }*/
          ]
        }
      }

        return {
          searchBar: false,
          list: []
        }
      }
    })
  

  const minimap = new MinimapPlugin<Schemes>();
  const reroutePlugin = new ReroutePlugin<Schemes>();

  connection.addPreset(ConnectionPresets.classic.setup());
  //editor.use(readonly.root);
  editor.use(area);
  //area.use(readonly.area);

  area.use(vueRender);

  area.use(contextMenu);
  area.use(minimap);
  area.use(connection);
  vueRender.use(reroutePlugin);

  vueRender.addPreset(VuePresets.classic.setup({
    customize: {
      control(data:any) {
        
        if (data.payload instanceof DataSourceControl) {
          return CustomDataSourceControl;
        }
         if (data.payload instanceof RedirectControl) {
          return CustomRedirectControl;
        }

        if (data.payload instanceof StoreProcedureControl) {
          return CustomStoreProcedureControl;
        }

        if (data.payload instanceof TextControl) {
          return CustomTextControl;
        }

        if (data.payload instanceof DisplayComponentControl) {
          return CustomDisplayComponentControl;
        }

        if (data.payload instanceof ChangeComponentControl) {
          return CustomChangeComponentControl;
        }

        if (data.payload instanceof ValidateComponentControl) {
          return CustomValidateComponentControl;
        }


        if (data.payload instanceof MessageControl) {
          return CustomMessageControl;
        }

        if (data.payload instanceof ConditionControl) {
          return CustomConditionControl;
        }

        if (data.payload instanceof UbmControl) {
          return CustomUbmControl;
        }
        
        if (data.payload instanceof SendMailControl) {
          return CustomSendMailControl;
        }
      
        
        if (data.payload instanceof Classic.InputControl) {
          return VuePresets.classic.Control;
        }
      
      }
    }
  }));


  

  vueRender.addPreset(VuePresets.contextMenu.setup());
  vueRender.addPreset(VuePresets.minimap.setup());
  vueRender.addPreset(
    VuePresets.reroute.setup({
      contextMenu(id) {
        reroutePlugin.remove(id);
      },
      translate(id, dx, dy) {
        reroutePlugin.translate(id, dx, dy);
      },
      pointerdown(id) {
        reroutePlugin.unselect(id);
        reroutePlugin.select(id);
      },
    })
  );


  /*
  const a = new NumberNode(socket,1, process);
  
  const b = new NumberNode(socket,1, process);
  const add = new AddNode(socket);

  await editor.addNode(a);
  await editor.addNode(b);
  await editor.addNode(add);

  await editor.addConnection(new Connection(a, 'value', add, 'a'));
  await editor.addConnection(new Connection(b, 'value', add, 'b'));

  */


  const arrange = new AutoArrangePlugin<Schemes>();

  arrange.addPreset(ArrangePresets.classic.setup());

  area.use(arrange);



  AreaExtensions.zoomAt(area, editor.getNodes());

  AreaExtensions.simpleNodesOrder(area);

  const selector = AreaExtensions.selector();
  const accumulating = AreaExtensions.accumulateOnCtrl();

  AreaExtensions.selectableNodes(area, selector, { accumulating });
  RerouteExtensions.selectablePins(reroutePlugin, selector, accumulating);


  /*
    async function process() {
      dataflow.reset();
  
      editor
        .getNodes()
        .filter((node) => node instanceof AddNode)
        .forEach(async (node) => {
          const sum = await dataflow.fetch(node.id);
  
          console.log(node.id, 'produces', sum);
  
          area.update(
            'control',
            (node.controls['result'] as Classic.InputControl<'number'>).id
          );
        });
    }
  
    */

  await editor.addPipe((context) => {

    
    
    const id = (context as any).data?.id ?? '';

    const iapnode = wf.iapWorkFlowActivities.find(x => x.name == id)
    const variables = wf.iapWorkFlowActivities.flatMap(x => x.iapWorkFlowActivityVariables)
    const iapconn = variables.flatMap(x => x.iapWorkFlowActivityConnectionWorkFlowActivityVariableIdInputNavigations ?? [])
      .concat(variables.flatMap(x => x.iapWorkFlowActivityConnectionWorkFlowActivityVariableIdOutputNavigations ?? [])).filter(z => z.externalId !== null).find(z => z.externalId == id)

    const hasBeginNode = editor.getNodes().some(n => n instanceof StartNode)
    const hasEndNode = editor.getNodes().some(n => n instanceof EndNode)

    switch (context.type) {
      case 'noderemove':

        // no se permite insertar el nodo de inicio mas de una vez   
        if (hasBeginNode && context.data instanceof StartNode) {
          return;
        }
        break;

      case 'noderemoved':
        if (iapnode) {
          deleteWorkFlowActivity(iapnode)
        }

        break;

      case 'nodecreate':

        if (iapnode) {
          return context;
        }

        // no se permite insertar el nodo de inicio mas de una vez   
        if (hasBeginNode && context.data instanceof StartNode) {
          return;
        }

      // no se permite insertar el nodo de fin mas de una vez   
      if (hasEndNode && context.data instanceof EndNode) {
        return;
      }


        break;
      case 'nodecreated':
        if (iapnode) {
          return context;
        }        
        addWorkFlowActivityFromContext(context);        
        break;

      case 'connectioncreate':
        if (iapconn) {
          return context;
        }
        break;

      case 'connectioncreated':
        
        if (iapconn) {
          return context;
        }
        addWorkFlowActivityConnectionFromContext(context);
        
        break;

      case 'connectionremove':
        if (iapconn) {
          return context;
        }
        break;
      case 'connectionremoved':
        removeWorkFlowActivityConnectionFromContext(iapconn as any,context)  
        break;


    }

    /*
     if (
       context.type === 'connectioncreated' ||
       context.type === 'connectionremoved'
     ) {
       //process();
     }
     */

    return context;
  });






  //process();

  //readonly.enable();
  // ejecuta el workflow
  /*dataflow.reset();
  engine.execute(start.id);
*/


  





  if (wf.iapWorkFlowActivities.length == 0) {

    const start = new StartNode(socket);
    await editor.addNode(start);

    const end = new EndNode(socket);
    await editor.addNode(end);

  }
  else {

    let wfComps: Dictionary<String, Object>[] = ([])

    wf.iapWorkFlowActivities.forEach(item => {
      wfComps.push({
        key: item.id,
        value: addDataToEditor(editor, item, dataflow)
      } as any)

    })


    const conns = wf.iapWorkFlowActivities.flatMap(x => x.iapWorkFlowActivityVariables).flatMap(y => y.iapWorkFlowActivityConnectionWorkFlowActivityVariableIdInputNavigations.concat(y.iapWorkFlowActivityConnectionWorkFlowActivityVariableIdOutputNavigations))
      .map(c => ({
        externalId: c.externalId,
        outputActivityId: wf?.iapWorkFlowActivities.find(x => x.iapWorkFlowActivityVariables.filter(v => v.id == c.workFlowActivityVariableIdOutput).length > 0)?.id,
        outputName: wf?.iapWorkFlowActivities.flatMap(x => x.iapWorkFlowActivityVariables).find(v => v.id == c.workFlowActivityVariableIdOutput)?.name,
        inputActivityId: wf?.iapWorkFlowActivities.find(x => x.iapWorkFlowActivityVariables.filter(v => v.id == c.workFlowActivityVariableIdInput).length > 0)?.id,
        inputName: wf?.iapWorkFlowActivities.flatMap(x => x.iapWorkFlowActivityVariables).find(v => v.id == c.workFlowActivityVariableIdInput)?.name,
      })).filter((v:any, i:any, a:any) => a.findIndex((v2:any) => ['outputActivityId', 'outputName', 'inputActivityId', 'inputName'].every((k:any) => v2[k] === v[k])) === i)


    for (let index = 0; index < wfComps.length; index++) {
      await editor.addNode(wfComps[index].value as any);

    }

    for (let index = 0; index < conns.length; index++) {
      const sourceComp = wfComps.find(x => x.key == (conns[index].inputActivityId ?? ''))?.value
      const targetComp = wfComps.find(x => x.key == (conns[index].outputActivityId ?? ''))?.value
      if (sourceComp && targetComp) {
        const cnn = new Connection(sourceComp as any, conns[index].inputName ?? '', targetComp as any, conns[index].outputName ?? '')
        cnn.id = conns[index].externalId ?? cnn.id;

        await editor.addConnection(cnn)
      }


    }



  }


/*
const showExpressionOnNode = (context:any) =>{
  if (callbackShowExpressions) 
  {   
    callbackShowExpressions(wf.iapWorkFlowActivities.find(x=> x.name == context.id) as any)
  }
}
*/

  await arrange.layout();

  return {
    destroy: () => area.destroy(),
  };
}






export async function executeWorkFlow(wfData: IapWorkFlow, applicationId:number, applicationVersion:number,rootParentId:number,formKey:String,currentElementKey:string, componentId:number,componentData:IapComponent[],catalogTypes: IapCatalogType[], srvContainer: Container | undefined, store: any,router:Router,callBackEndFunction:any=undefined) {
if (wfData == undefined || wfData == null){
  if (callBackEndFunction){
    callBackEndFunction(true)
  }
  return
}

  const { wf,dataflow, engine,socket,addDataToEditor} = HelperRete(router,undefined,wfData, catalogTypes, srvContainer, store, applicationId, applicationVersion,rootParentId,formKey,currentElementKey,componentId,componentData,undefined,undefined,undefined,callBackEndFunction)
  const editor = new NodeEditor<Schemes>();
    
  //const area = new AreaPlugin<Schemes, AreaExtra>(container);
  const connection = new ConnectionPlugin<Schemes, AreaExtra>();
  const vueRender = new VuePlugin<Schemes, AreaExtra>();
 
  editor.use(engine);
  editor.use(dataflow);

  
    


 
  
  const reroutePlugin = new ReroutePlugin<Schemes>();

  connection.addPreset(ConnectionPresets.classic.setup());
  //editor.use(readonly.root);
  //editor.use(area);
  //area.use(readonly.area);

  
  vueRender.use(reroutePlugin);

  vueRender.addPreset(VuePresets.classic.setup({
    customize: {
      control(data:any) {

        if (data.payload instanceof DataSourceControl) {
          return CustomDataSourceControl;
        }
         if (data.payload instanceof RedirectControl) {
          return CustomRedirectControl;
        }

        if (data.payload instanceof StoreProcedureControl) {
          return CustomStoreProcedureControl;
        }

        if (data.payload instanceof TextControl) {
          return CustomTextControl;
        }

        if (data.payload instanceof DisplayComponentControl) {
          return CustomDisplayComponentControl;
        }

        if (data.payload instanceof ChangeComponentControl) {
          return CustomChangeComponentControl;
        }

        if (data.payload instanceof ValidateComponentControl) {
          return CustomValidateComponentControl;
        }

        if (data.payload instanceof MessageControl) {
          return CustomMessageControl;
        }

        if (data.payload instanceof ConditionControl) {
          return CustomConditionControl;
        }
        
        if (data.payload instanceof UbmControl) {
          return CustomUbmControl;
        }
      
        if (data.payload instanceof SendMailControl) {
          return CustomSendMailControl;
        }
        
        if (data.payload instanceof Classic.InputControl) {
          return VuePresets.classic.Control;
        }

          return Classic.Control;
        
      }
    }
  }));
  vueRender.addPreset(VuePresets.contextMenu.setup());
  vueRender.addPreset(VuePresets.minimap.setup());
  vueRender.addPreset(
    VuePresets.reroute.setup({
      contextMenu(id) {
        reroutePlugin.remove(id);
      },
      translate(id, dx, dy) {
        reroutePlugin.translate(id, dx, dy);
      },
      pointerdown(id) {
        reroutePlugin.unselect(id);
        reroutePlugin.select(id);
      },
    })
  );






  if (wf.iapWorkFlowActivities.length == 0) {

    const start = new StartNode(socket);
    await editor.addNode(start);

  }
  else {

    let wfComps: Dictionary<String, Object>[] = ([])

    wf.iapWorkFlowActivities.forEach(item => {
      wfComps.push({
        key: item.id,
        value: addDataToEditor(editor, item, dataflow)
      } as any)

    })


    const conns = wf.iapWorkFlowActivities.flatMap(x => x.iapWorkFlowActivityVariables).flatMap(y => y.iapWorkFlowActivityConnectionWorkFlowActivityVariableIdInputNavigations.concat(y.iapWorkFlowActivityConnectionWorkFlowActivityVariableIdOutputNavigations))
      .map(c => ({
        externalId: c.externalId,
        outputActivityId: wf?.iapWorkFlowActivities.find(x => x.iapWorkFlowActivityVariables.filter(v => v.id == c.workFlowActivityVariableIdOutput).length > 0)?.id,
        outputName: wf?.iapWorkFlowActivities.flatMap(x => x.iapWorkFlowActivityVariables).find(v => v.id == c.workFlowActivityVariableIdOutput)?.name,
        inputActivityId: wf?.iapWorkFlowActivities.find(x => x.iapWorkFlowActivityVariables.filter(v => v.id == c.workFlowActivityVariableIdInput).length > 0)?.id,
        inputName: wf?.iapWorkFlowActivities.flatMap(x => x.iapWorkFlowActivityVariables).find(v => v.id == c.workFlowActivityVariableIdInput)?.name,
      })).filter((v:any, i:any, a:any) => a.findIndex((v2:any) => ['outputActivityId', 'outputName', 'inputActivityId', 'inputName'].every((k:any) => v2[k] === v[k])) === i)


    for (let index = 0; index < wfComps.length; index++) {
      await editor.addNode(wfComps[index].value as any);

    }

    for (let index = 0; index < conns.length; index++) {
      const sourceComp = wfComps.find(x => x.key == (conns[index].inputActivityId ?? ''))?.value
      const targetComp = wfComps.find(x => x.key == (conns[index].outputActivityId ?? ''))?.value
      if (sourceComp && targetComp) {
        const cnn = new Connection(sourceComp as any, conns[index].inputName ?? '', targetComp as any, conns[index].outputName ?? '')
        cnn.id = conns[index].externalId ?? cnn.id;

        await editor.addConnection(cnn)
      }


    }



  }

  const start = editor.getNodes().find(x=> x instanceof StartNode)

  if (start){
    dataflow.reset();
    engine.execute(start.id);
  
  }
  
  return {
    //destroy: () => 
  };
}



