import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import { IMenuRepository } from '../domain/IMenuRepository';

import { UPDATE, ADD, DELETE_WITH_CHILDS_BY_ID } from './mutation/menuMutation';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { GET_ALL_MENUS, GET_MENU_BY_APPLICATION_ID, GET_MENU_BY_ID } from './query/menuQuery';
import { IapMenu } from '../domain/iapMenu';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class MenuApolloClientRepository implements IMenuRepository {
  private apolloClient : MyApolloClient
  private apolloClientId: string
  constructor (apolloInstance: MyApolloClient, _apolloClientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.apolloClientId = _apolloClientId
  }
  

  async update (dataInput: IapMenu): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE, { data: dataInput }, this.apolloClientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.menuMutation?.updateMenu)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async add (dataInput: IapMenu): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD, { data:dataInput }, this.apolloClientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.menuMutation?.addMenu)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async deleteById (id: number): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(DELETE_WITH_CHILDS_BY_ID, { id: id }, this.apolloClientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.menuMutation?.deleteById)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async getAll (maxregs: number | undefined): (Promise<IapMenu[]>) {
    return new Promise<IapMenu[]>(async (resolve, reject) => {
      
      const result = this.apolloClient.executeQuery(GET_ALL_MENUS, { maxregs: maxregs }, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.menuQuery?.getAll)
        }
        catch(e) {
          reject()
        }
      });
      
    });

  }

  async getById (id: number): (Promise<IapMenu>) {
    return new Promise<IapMenu>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_MENU_BY_ID, { id: id}, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.menuQuery?.getById)
        }
        catch(e) {
          reject()
        }
      });
      
    });
  }

  async getByApplicationId (administrator: number, profileId: number, applicationId: number, applicationVersion: number): (Promise<IapMenu[]>) {
    return new Promise<IapMenu[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_MENU_BY_APPLICATION_ID, { administrator: administrator, profileId: profileId, applicationId: applicationId, version: applicationVersion }, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.menuQuery?.getByApplicationId)
        }
        catch(e) {
          reject()
        }
      });
      
    });
  }
}


