import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_Timeline = _resolveComponent("Timeline")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? _withDirectives((_openBlock(), _createBlock(_component_Timeline, {
          key: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.TimeLineTypeConst.ID),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.TimeLineTypeConst.STYLE)),
          class: _normalizeClass(_ctx.getPropertyValue(_ctx.TimeLineTypeConst.CLASS)),
          name: _ctx.getPropertyValue(_ctx.TimeLineTypeConst.NAME),
          disabled: _ctx.getPropertyBooleanValue(_ctx.TimeLineTypeConst.DISABLED),
          visible: _ctx.getPropertyBooleanValue(_ctx.TimeLineTypeConst.VISIBLE),
          placeholder: _ctx.getPropertyValue(_ctx.TimeLineTypeConst.PLACEHOLDER),
          value: _ctx.getPropertyValue(_ctx.TimeLineTypeConst.VALUE),
          align: _ctx.getCatalogValue(_ctx.TimeLineTypeConst.ALIGN),
          layout: _ctx.getCatalogValue(_ctx.TimeLineTypeConst.LAYOUT),
          datakey: _ctx.getPropertyValue(_ctx.TimeLineTypeConst.DATAKEY),
          modelValue: _ctx.vmodel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vmodel) = $event))
        }, {
          content: _withCtx((slotProps) => [
            _renderSlot(_ctx.$slots, "content", {
              slotProps: { data: slotProps.item, index: slotProps.index, control: _ctx.ControlTypeConst.CTTIMELINE }
            })
          ]),
          opposite: _withCtx((slotProps) => [
            _renderSlot(_ctx.$slots, "opposite", {
              slotProps: { data: slotProps.item, index: slotProps.index, control: _ctx.ControlTypeConst.CTTIMELINE }
            })
          ]),
          marker: _withCtx((slotProps) => [
            _renderSlot(_ctx.$slots, "marker", {
              slotProps: { data: slotProps.item, index: slotProps.index, control: _ctx.ControlTypeConst.CTTIMELINE }
            })
          ]),
          connector: _withCtx(() => [
            _renderSlot(_ctx.$slots, "connector", {
              slotProps: { data: _ctx.slotProps.item, index: _ctx.slotProps.index, control: _ctx.ControlTypeConst.CTTIMELINE }
            })
          ]),
          _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["id", "style", "class", "name", "disabled", "visible", "placeholder", "value", "align", "layout", "datakey", "modelValue"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.TimeLineTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}