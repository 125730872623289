export default class FiltroBusquedaConst {
  static FILTROBUSQUEDA_IGUAL = 'filterbusqueda-eq';
  static FILTROBUSQUEDA_CONTIENE = 'filterbusqueda-inc';

  static FILTROBUSQUEDA_NONULO = 'filterbusqueda-nonull';
  static FILTROBUSQUEDA_NULO = 'filterbusqueda-null';
  static FILTROBUSQUEDA_ENTRE = 'filterbusqueda-betw';
  static FILTROBUSQUEDA_DISTINTO = 'filterbusqueda-dist';
  static FILTROBUSQUEDA_STARTWITH = "filterbusqueda-sw";
  static FILTROBUSQUEDA_ENDWITH = "filterbusqueda-ew";
  static FILTROBUSQUEDA_NOTCONTAINS = "filterbusqueda-nc";
  static FILTROBUSQUEDA_LESSTHAN = "filterbusqueda-lt";
  static FILTROBUSQUEDA_LESSTHANEQTO = "filterbusqueda-ltet";
  static FILTROBUSQUEDA_GREATTHANEQTO = "filterbusqueda-gtet";
  static FILTROBUSQUEDA_GREATTHAN = "filterbusqueda-gt";
  static FILTROBUSQUEDA_PREVIOUSMONTH = "filterbusqueda-pm";
  static FILTROBUSQUEDA_PREVIOUSYEAR = "filterbusqueda-py";
  static FILTROBUSQUEDA_CURRENTYEAR = "filterbusqueda-cy";
  static FILTROBUSQUEDA_CURRENTMONTH = "filterbusqueda-cm";
  static FILTROBUSQUEDA_CURRENTWEEK = "filterbusqueda-cw";
  static FILTROBUSQUEDA_PREVIOUSWEEK = "filterbusqueda-pw";
  static FILTROBUSQUEDA_DAYSAGO = "filterbusqueda-dago";

}
