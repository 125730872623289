import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IDataSourceMigrationRepository } from '../domain/IDataSourceMigrationRepository';



import { IServiceDataSourceMigration } from './IServiceDataSourceMigration';
import { IapDataSourceMigration } from '../domain/iapDataSourceMigration';


@injectable()
export class DataSourceMigrationService implements IServiceDataSourceMigration {
  private readonly repo: IDataSourceMigrationRepository

  public constructor (@inject(TYPES.DATASOURCE_MIGRATION_REPOSITORY) _repo: IDataSourceMigrationRepository) {
    this.repo = _repo
  }

  async update (data: IapDataSourceMigration): (Promise<boolean>) {
    return await this.repo.update(data);
  }

  async add (data: IapDataSourceMigration): (Promise<boolean>) {
    return await this.repo.add(data);
  }

}
