import gql from 'graphql-tag';

export const GET_ALL_WORKFLOWACTIVITYCONTROL = gql(`
query($maxregs: Int) {
  menuQuery {
    getAll {
        id
        workFlowActivityId
        name
        value
        localizable
    }
  }
}
`);

export const GET_WORKFLOWACTIVITYCONTROL_BY_ID = gql(`
query($id: Int) {
  menuQuery {
      getById(id:$id) {
        id
        workFlowActivityId
        name
        value
        localizable
      }
    }
}
`);
