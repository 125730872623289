import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex justify-content-center" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_card = _resolveComponent("card")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_openBlock(), _createElementBlock("span", {
      class: "p-float-label wrap-input100 validate-input",
      key: _ctx.componentKey
    }, [
      _createCommentVNode("<Dropdown v-if=\"paths.length > 1\" v-model=\"selectedPathId\"  style=\"width: 250px;\" @change=\"onChangePath()\"\r\n                        :options=\"paths\" :showClear=\"true\" optionValue=\"id\" optionLabel=\"path\"\r\n                        placeholder=\"\" @before-show=\"loadHistory\" filter showClear/>"),
      (_ctx.paths.length > 1)
        ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
            key: 0,
            id: "showBrowsing",
            size: "small",
            class: "p-button p-component p-button-icon-only p-button-secondary p-button-rounded",
            icon: "pi pi-history",
            model: _ctx.paths,
            onClick: _ctx.openBrowsing
          }, null, 8 /* PROPS */, ["model", "onClick"])), [
            [_directive_tooltip, 'Historial de navegación']
          ])
        : _createCommentVNode("v-if", true),
      _createVNode(_component_OverlayPanel, {
        ref: "opBrowsing",
        dismissable: true,
        showCloseIcon: true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_card, null, {
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_DataTable, {
                  value: _ctx.paths,
                  paginator: true,
                  rows: 5,
                  resizableColumns: "",
                  columnResizeMode: "fit",
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      field: "path",
                      header: "Sitio",
                      sortable: ""
                    }),
                    _createVNode(_component_Column, {
                      field: "date",
                      header: "Fecha",
                      sortable: ""
                    }, {
                      body: _withCtx((slotProps) => [
                        _createTextVNode(_toDisplayString(_ctx.formatDate(slotProps.data.date)), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_Column, { header: "Link" }, {
                      body: _withCtx((slotProps) => [
                        _withDirectives(_createElementVNode("i", {
                          class: "pi pi-external-link",
                          onClick: ($event: any) => (_ctx.redirectToPath(slotProps.data.path))
                        }, null, 8 /* PROPS */, _hoisted_2), [
                          [_directive_tooltip, 'Abrir enlace']
                        ])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["value"])
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 512 /* NEED_PATCH */)
    ]))
  ], 64 /* STABLE_FRAGMENT */))
}