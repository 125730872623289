import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';

import { IDataSourceLookUpRepository } from '../domain/IDataSourceLookUpRepository';

import {  GET_DATASOURCE_LOOKUP_BY_ID } from './query/dataSourceLookUpQuery';

import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';

import { IapDataSourceLookUp } from '../domain/iapDataSourceLookUp';

import {UPDATE,ADD,DELETE} from './mutation/dataSourceLookUpMutation'

import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';

import { IapObjectDataSourceFieldConfiguration } from '../domain/iapObjectDataSourceFieldConfiguration';

@injectable()
export class DataSourceLookUpApolloClientRepository implements IDataSourceLookUpRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId=ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }

  async update (dataInput: IapDataSourceLookUp,objDsfConfigData:IapObjectDataSourceFieldConfiguration[]): (Promise<IapDataSourceLookUp>) {
    return new Promise<IapDataSourceLookUp>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE,{data:dataInput,objDsfConfigData},this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.dataSourceLookUpMutation.updateDataSourceLookUp)
        }
        catch(e) {
          reject()
        }
      });

    });
  }


  async getById (id: number): (Promise<IapDataSourceLookUp>) {
    return new Promise<IapDataSourceLookUp>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_DATASOURCE_LOOKUP_BY_ID, { id: id }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.dataSourceLookUpQuery.getById)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async delete (id: number): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(DELETE, { id: id }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.dataSourceLookUpMutation.deleteDataSourceLookUp)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


  async add (dataInput: IapDataSourceLookUp,objDsfConfigData:IapObjectDataSourceFieldConfiguration[]): (Promise<IapDataSourceLookUp>) {
    return new Promise<IapDataSourceLookUp>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD,{data:dataInput,objDsfConfigData:objDsfConfigData}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.dataSourceLookUpMutation.addDataSourceLookUp)
        }
        catch(e) {
          reject()
        }
      });

    });
  }
  


}


