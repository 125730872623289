<template>
        <TreeTable v-model:selectionKeys="selectedKeys" :filters="filters" :value="getTreeTableNodes" selectionMode="checkbox" columnResizeMode="fit" style="width:100%"  scrollable  scrollHeight="300px" responsiveLayout="scroll">
            <Column field="label"  expander>
                <template #header>
                    <div class="flex justify-content-between align-content-center w-full">
                    <span class="flex align-items-center justify-content-center">Asignar perfiles</span>
                    <span class=" mt-2 md:mt-0 p-input-icon-left">
                    <i class="pi pi-search " />
                    <InputText v-model="filters['global']" type="text" class="p-column-filter" placeholder="" />
                    <span class="p-input-icon-right" style="display: inline;">
                            <i class="pi pi-times" v-tooltip.top="'Borrar búsqueda'" @click="filters['global']=null"/>
                        </span>
                    </span>
                    </div>
            </template>
            </Column>
        </TreeTable>

</template>
<script lang="ts">

import { IampSearchConfig } from '@ilinium/shared/src/entidades/builderMaster/user/domain/iapmSearchConfig';
import { defineComponent,computed,ref,watch,onMounted} from 'vue';
import { UserEntityProfile } from '../../../../builderMaster/user/domain/userEntityProfile';
import { IapCatalog } from '../../../catalog/domain/iapCatalog';

export default defineComponent({
    name: 'save_search_user_profile',
    emits: ['update:selectionKeys'],
    props:{
        value: {
            type: Object as () => UserEntityProfile[],
            default: () => ([])
        },
        catalogosData: {
            type: Object as () => IapCatalog[],
            default: () => ([])
        },
        searchConfigAll: {
            type: Object as () => IampSearchConfig[],
            default: () => ([])
        },
        searchConfig: {
            type: Object as () => IampSearchConfig,
            default: () => ([])
        },
         isUpdate: {
            type: Boolean,
            default: false
        },

    },
    setup(props,{emit}) {
         const selectedKeys=ref();
         const filters = ref({});

         
        watch(() => selectedKeys.value, (newValue, oldValue) => {
            emit('update:selectionKeys', selectedKeys.value)
        }, { immediate: true, deep: true });

        const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
            arr.reduce((groups, item) => {
                (groups[key(item)] ||= []).push(item);
                return groups;
            }, {} as Record<K, T[]>);

        
        const getTreeTableNodes = computed(() => {

            const groupUserProfile=groupBy(props.value,x=>x.userId);

            return Object.keys(groupUserProfile).map(x => ({
                    key: x,
                    data:{label:props.value.find(z=>z.userId.toString()==x)?.userName},
                    children:groupUserProfile[x].map(c=>({key:[c.userId,c.entityProfileId].join('-'),data:{label:getCatalogDesc(c.profileType)}}))
                }))
     
        });

        const getCatalogDesc=(id:string)=>{
            return props.catalogosData.find(x=>x.id==id)?.description??id;
        }

        const buildSelectKeysModel=()=>{
            const searchConfigGroup=props.searchConfigAll.filter(x=>x.group==props.searchConfig?.group);
            let obj = {};

            searchConfigGroup.forEach(search=>{
               
                if(search.profileId ==null){
                    obj[search.userId]={checked:true,partialChecked:false};
                    const allUserProfile=props.value.filter(x=>x.userId==search.userId);
                    allUserProfile.forEach(p=>{
                        obj[search.userId+'-'+p.entityProfileId]={checked:true,partialChecked:false};
                    });
                }
                else{
                    obj[search.userId+'-'+search.profileId]={checked:true,partialChecked:false};

                    if(!obj[search.userId]){
                        obj[search.userId]={checked:false,partialChecked:true};
                    }
                }
            });

            if (Object.keys(obj).length > 0) {
                selectedKeys.value=obj;
            }

        }

        onMounted(() => {
            if(props.isUpdate){
                buildSelectKeysModel();
            }
        });

       

        return {
            getTreeTableNodes,
            selectedKeys,
            filters
        };
    },
});
</script>
<style scoped></style>
