import BaseControlTypeConst from "./BaseControlTypeConst"

export default class DividerTypeConst extends BaseControlTypeConst {
    
  static ALIGN = 'align'
  static LAYOUT = 'layout'
  static TYPE = 'type'
   

}
