import gql from 'graphql-tag';



export const GET_BY_ID = gql(`
query($id: Int!, $componentId:Int!, $source:String!) {
    documentQuery {
      getById(id:$id, componentId:$componentId, source:$source) {
            id
            idOld
            idObjeto
            objetoId
            fileName
            contentType
            entityMainIntegracionId
            documentTypeId
            externalStorageId
            storageConfigurationId
            urlLink
            markForDelete
            description
            visibility
            fcr
            ucr
            fum
            uum
            ucrName
            uumName
      }
    }
}
`);

export const GET_BY_OBJECT_ID = gql(`
query($idObjeto:String!,$objetoId: Int!, $componentId:Int!, $source:String!) {
    documentQuery {
        getByObjectId(idObjeto:$idObjeto,objetoId:$objetoId, componentId:$componentId, source:$source) {
            id
            idOld
            idObjeto
            objetoId
            fileName
            contentType
            entityMainIntegracionId
            documentTypeId
            externalStorageId
            storageConfigurationId
            urlLink
            markForDelete
            description
            visibility
            fcr
            ucr
            fum
            uum
            ucrName
            uumName
      }
    }
}
`);


export const GET_CONTENT_BY_ID = gql(`
query($id: Int!, $componentId:Int!, $source:String!) {
    documentQuery {
        getContentById(id:$id, componentId:$componentId, source:$source) 
    }
}
`);

export const GET_BY_ID_WITH_CONTENT = gql(`
query($id: Int!, $componentId:Int!, $source:String!) {
    documentQuery {
        getByIdWithContent(id:$id, componentId:$componentId, source:$source) {
            id
            idOld
            idObjeto
            objetoId
            fileName
            contentType
            entityMainIntegracionId
            documentTypeId
            externalStorageId
            storageConfigurationId
            urlLink
            markForDelete
            description
            visibility
            fcr
            ucr
            fum
            uum
            ucrName
            uumName
            content
      }
    }
}
`);




