import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';

import { IObjectDataSourceFieldRelationRepository } from '../domain/IObjectDataSourceFieldRelationRepository';

import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { IapObjectDataSourceFieldRelation } from '../domain/iapObjectDataSourceFieldRelation';
import { UPDATE,ADD,DELETE } from './mutation/objectDataSourceFieldRelationMutation';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class ObjectDataSourceFieldRelationApolloClientRepository implements IObjectDataSourceFieldRelationRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId=ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }

  async update (oldData: IapObjectDataSourceFieldRelation,newData:IapObjectDataSourceFieldRelation): (Promise<IapObjectDataSourceFieldRelation>) {
    return new Promise<IapObjectDataSourceFieldRelation>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE,{oldData:oldData,newData:newData},this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.objectDataSourceFieldRelationMutation.updateObjectDataSourceFieldRelation)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async add (data: IapObjectDataSourceFieldRelation): (Promise<IapObjectDataSourceFieldRelation>) {
    return new Promise<IapObjectDataSourceFieldRelation>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD,{data:data},this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.objectDataSourceFieldRelationMutation.addObjectDataSourceFieldRelation)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async delete (data: IapObjectDataSourceFieldRelation): (Promise<IapObjectDataSourceFieldRelation>) {
    return new Promise<IapObjectDataSourceFieldRelation>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(DELETE,{data:data},this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.objectDataSourceFieldRelationMutation.deleteObjectDataSourceFieldRelation)
        }
        catch(e) {
          reject()
        }
      });

    });
  }



}


