

export default class MsgQueue{
    static items = new Array();
    static frontIndex = 0
    static backIndex = 0

    static enqueue = (item:any) => {
        this.items[this.backIndex] = item
        this.backIndex++
        return item
    }
    static dequeue = () => {
        const item = this.items[this.frontIndex]
        delete this.items[this.frontIndex]
        this.frontIndex++
        this.chekCounters()
        return item
    }
    static peek = () => {
        return this.items[this.frontIndex]
    }
    static  printQueue = () => {
        return this.items;
    }

    static isEmpty = () =>{
        return (this.items.filter(x=> x).length == 0)
    }

    static chekCounters = () =>{
        const empty = this.isEmpty()        
        if (empty){
            this.frontIndex = 0
            this.backIndex = 0
            this.items = []
            
        }

    }

    static clearAll = () =>{
            this.frontIndex = 0
            this.backIndex = 0
            this.items = []

    }

}