

export default class SqlTypesConst {
  static IMAGE = 'image';
  static INT = 'int';
  static DATETIME = 'datetime';
  static VARCHAR = 'varchar';
  static BIT = 'bit';
  static NVARCHAR = 'nvarchar';
  static MONEY = 'money';
  static DATE = 'date';
  static VARBINARY = 'varbinary';
  static UNIQUEIDENTIFIER = 'uniqueidentifier';
  static DECIMAL = 'decimal';
  static TIME = 'time';
  static TEXT='text';
  static SMALLINT='smallint';
  static BIGINT='bigint';
  static TINYINT='tinyint';
  static NUMERIC='numeric';


}
