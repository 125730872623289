
import gql from 'graphql-tag';

export const UPDATE = gql(`
mutation($oldData: ObjectDataSourceFieldRelationInputType!,$newData: ObjectDataSourceFieldRelationInputType!){
    objectDataSourceFieldRelationMutation{
        updateObjectDataSourceFieldRelation(oldData:$oldData,newData:$newData){
            objetoId,
            idObjeto,
            dataSourceFieldIdFrom,
            dataSourceFieldIdTo
        }
    }
}
`);

export const ADD = gql(`
mutation($data: ObjectDataSourceFieldRelationInputType!){
    objectDataSourceFieldRelationMutation{
        addObjectDataSourceFieldRelation(data:$data){
            objetoId,
            idObjeto,
            dataSourceFieldIdFrom,
            dataSourceFieldIdTo
        }
    }
}
`);


export const DELETE = gql(`
mutation($data: ObjectDataSourceFieldRelationInputType!){
    objectDataSourceFieldRelationMutation{
        deleteObjectDataSourceFieldRelation(data:$data)
    }
}
`);

