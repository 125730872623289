import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { ref: "simpleTable" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_HtmlTable = _resolveComponent("HtmlTable")!
  const _component_Documento = _resolveComponent("Documento")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_Button, {
      id: "exportar",
      icon: "fa-solid fa-download",
      onClick: _ctx.toggle,
      "aria-haspopup": "true",
      "aria-controls": "overlay_menu",
      class: "p-button-outlined",
      style: {"margin-left":"1px"}
    }, null, 8 /* PROPS */, ["onClick"]), [
      [
        _directive_tooltip,
        'Exportar',
        void 0,
        { top: true }
      ]
    ]),
    _createVNode(_component_Menu, {
      id: "overlay_menu",
      ref: "menu",
      model: _ctx.getItems,
      popup: true
    }, null, 8 /* PROPS */, ["model"]),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      (_ctx.showSimpleTable)
        ? (_openBlock(), _createBlock(_component_HtmlTable, {
            key: 0,
            header: _ctx.getTableData()?.header,
            rows: _ctx.getTableData()?.rows,
            onInit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toPrinter()))
          }, null, 8 /* PROPS */, ["header", "rows"]))
        : _createCommentVNode("v-if", true)
    ], 512 /* NEED_PATCH */), [
      [_vShow, false]
    ]),
    _createVNode(_component_Documento, { ref: "documento" }, null, 512 /* NEED_PATCH */)
  ], 64 /* STABLE_FRAGMENT */))
}