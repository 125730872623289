<template>
    <Splitter style="height: 800px">
       
        <SplitterPanel :size="20">
            <Fieldset class="mt-2">
                <template #legend>
                    <div class="flex align-items-center text-primary">
                        <span class="pi pi-wrench mr-2"></span>
                        <span class="font-bold text-lg">Herramientas</span>
                        <i v-if="idObjeto!=CatalogObjectTypeConst.LOOKUP && !isExternal" class="pi pi-box ml-3" v-tooltip="'Recargar Componente'" style="color:#9C27B0;"
                            @click="updateCacheComp()"></i>
                    </div>
                </template>
                <ScrollPanel style="width: 100%; height: 800px" class="custombar1">
                    <div class="card">                        
                        <TreeTable v-model:expandedKeys="expandedKeys" selectionMode="single" :value="expressionTree"
                            :filters="filters" @nodeExpand="onNodeExpand" @nodeCollapse="onNodeCollapsed"
                            @nodeSelect="onNodeSelect">
                            <Column field="label" expander>
                                <template #filter>
                                    <!--
                                    <SelectButton v-model="selectedTools" :options="tools" optionvalue="id" optionLabel="description"  multiple aria-labelledby="multiple" />        
                                    -->
                                    <MultiSelect v-if="!isExternal" v-model="selectedTools" display="chip" :options="tools" filter
                                        optionLabel="description" optionValue="id" placeholder="Selecciona objetos"
                                        :maxSelectedLabels="3" class="p-column-filter" @change="buildTools" />
                                    <InputText v-model="filters['label']" type="text" class="p-column-filter"
                                        placeholder="Filtro" @update:modelValue="expandTreeNodes" />
                                </template>
                            </Column>
                        </TreeTable>
                    </div>
                </ScrollPanel>
            </Fieldset>
   

        </SplitterPanel>

        <SplitterPanel :size="80">
            <Fieldset class="mt-2">
                <template #legend>
                    <div class="flex align-items-center text-primary">
                        <span class="pi pi-bars mr-2"></span>
                        <span class="font-bold text-lg"
                            :class="{ 'p-invalid': v$.expression.$error && submitted, 'customrequired': true }">Fórmula</span>
                    </div>
                </template>
                <div>
                    <Textarea id="query" rows="5" class="custom-textarea" v-model="variable.expression"
                        @keyup="updateTranslation()" />
                    <span v-if="submitted">
                        <span v-for="error in v$.expression.$errors" :key="error.$uid">
                            <small class="p-error">{{ error.$message }}</small>
                        </span>
                    </span>
                </div>
            </Fieldset>
            <Fieldset class="mt-2">
                <template #legend>
                    <div class="flex align-items-center text-primary">
                        <span class="pi pi-comment mr-2"></span>
                        <span class="font-bold text-lg">Traducción</span>
                    </div>
                </template>
                <div>
                    <span>{{ variable.expressionTranslated }}</span>
                </div>
            </Fieldset>
            <Fieldset class="mt-2">
                <template #legend>
                    <div class="flex align-items-center text-primary">
                        <span class="pi pi-save mr-2"></span>
                        <span class="font-bold text-lg">Opciones de grabado</span>
                    </div>
                </template>
                <div class="p-fluid formgrid grid">
                    <div class="field col-12 lg:col-1">
                        <label for="orden" class="block mb-2"> Orden </label>
                        <InputNumber id="orden" v-model="variable.processOrder" inputId="integeronly" showButtons />

                    </div>
                    <div class="field col-12 lg:col-2">
                        <label for="variable" class="block mb-2"
                            :class="{ 'p-invalid': v$.variable.$error && submitted, 'customrequired': true }"> Variable
                        </label>
                        <AutoComplete v-model="variable.variable" :class="{ 'p-invalid': v$.variable.$error && submitted }"
                            :suggestions="filteredNameV" @complete="search" :disabled="id !== ''" />

                        <span v-if="submitted">
                            <span v-for="error in v$.variable.$errors" :key="error.$uid">
                                <small class="p-error">{{ error.$message }}</small>
                            </span>
                        </span>


                    </div>
                    <div class="field col-12 lg:col-1 flex align-content-end flex-wrap">
                        <Button v-tooltip="'Cancelar'" icon="pi pi-times" severity="danger" class="mr-2"
                            @click="$emit('close')" />
                        <Button v-tooltip="'Guardar'" icon="pi pi-save" severity="primary" @click="saveVariable()" />
                    </div>

                </div>
            </Fieldset>
        </SplitterPanel>
    </Splitter>
    <div v-if="!hasLoadExternalData" style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);">
    <ProgressSpinner style="width: 50px; height: 50px;"/>
   </div>

</template>
<script lang="ts">

import { Container } from 'inversify';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { TYPES } from '../../../../../common/domain/types';
import HelperLoading from '../../../../../common/infrastructure/funciones/HelperLoading';
import HelperUtils, { newGuid } from '../../../../../common/infrastructure/funciones/HelperUtils';
import { IapCatalogType } from '../../../catalog/domain/iapCatalogType';
import { IServiceExpressionDetail } from '../../application/IServiceExpressionDetail';
import { IapExpressionDetail } from '../../domain/iapExpressionDetail';
//import Expression from './Expression.vue';
import useVuelidate from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import HelperCommon from '../../../../../common/infrastructure/funciones/HelperCommon';
import { MessageService } from '@ilinium/shared/src/common/infrastructure/servicios';
import { MessageType } from '@ilinium/shared/src/common/infrastructure/servicios/MessageService';
import CatalogTypeConst from '../../../../builder/catalog/domain/const/CatalogTypeConst';
import { AnyAaaaRecord } from 'dns';
import WidgetComponentDataTree from '../../../designer/domain/widgetComponent';
import ExpressionTranslator from '../../domain/Functions/ExpressionTranslator';
import ExpressionNomenclatorConst from '../../domain/const/ExpressionNomenclatorConst';
import { IServiceExpression } from '../../application/IServiceExpression';
import { IapExpression } from '../../domain/iapExpression';
import { IapApplication } from '../../../application/domain/iapApplication';
import helperCatalog from '../../../catalog/infrastructure/helper/helperCatalog';
import ExpressionTreeNode from '../../domain/Functions/ExpressionTreeNode';
import ExpressionToolConst from '../../domain/const/ExpressionToolConst';
import ComponentUtil from '../../../designer/infrastructure/component/util/componentUtil';
import { IServiceComponent } from '../../../component/application/IServiceComponent';
import CatalogObjectTypeConst from '../../../catalog/domain/const/CatalogObjectTypeConst';
import { fetchWrapper } from '../../../../../common/infrastructure/funciones/helperFetch';
import Environment from '../../../../../common/infrastructure/funciones/environment';
import { IapComponent } from '../../../component/domain/iapComponent';
import CacheConst from '../../../cache/domain/cacheConst';
import CacheCommon from '../../../cache/infrastructure/CacheHelper';
import { onError } from '@apollo/client/link/error';
import { Expression } from 'ncalcjs';
import HelperCompress from '../../../../../common/infrastructure/funciones/HelperCompress';
import { IapDataSourceLookUp } from '../../../datasource/domain/iapDataSourceLookUp';

export default defineComponent({
    name: 'expressioneditor',
    emits: ['close', 'refresh', 'expressionsChanged','external:update','external:insert'],
    props: {
        container: {
            type: Object as () => Container
        },
        expressionId: {
            type: String,
            default: () => ('')
        },
        id: {
            type: String,
            default: () => ('')
        },
        order: {
            type: Number,
            default: () => (1)
        },
        applicationId: {
            type: Number,
            default: -1
        },
        applicationVersion: {
            type: Number,
            default: -1
        },

        idObjeto: {
            type: String,
            default: () => ('')
        },
        objetoId: {
            type: String,
            default: () => ('')
        },
        idObjetoRoot: {
            type: String,
            default: null
        },
        objetoIdRoot: {
            type: String,
            default: null
        },
        expType: {
            type: String,
            default: () => ('')
        },
        expressionDetail: {

            type: Object as () => IapExpressionDetail[],
            default: ([])
        },
        externalId: {
            type: String,
            default: ''
        },
        lookUp: {
            type: Object as () => IapDataSourceLookUp,
            default: null
        },
        isExternal: {
            type: Boolean,
            default: false
        },
        externalTree: {
            type: Object,
            default: undefined
        },
        externalTranslateFunction: {
            type: Function,
            default: undefined
        },
    },
    components: {
        //Expression

    },
    setup(props, { emit }) {
        const { nest } = ComponentUtil();

        const submitted = ref(false);
        const nodeExpanded = ref(false);
        const store = useStore()
        const selectedVariable = ref();
        const valueNameV = ref('');
        const itemsNameV = ['Prima total', 'Prima Neta', 'Impuestos', 'Consorcio'];
        const filteredNameV = ref();
        const expressionTree = ref();
        const variable = ref<Partial<IapExpressionDetail>>({
            id: props.isExternal? '' : HelperUtils.newGuid(),
            expressionId: props.expressionId,
            variable: '',
            expression: '',
            expressionTranslated: '',
            processOrder: props.order,
            localizable: false,
            fcr: new Date(),
            ucr: store.getters.getCurrentUser.id,
            uum: store.getters.getCurrentUser.id,
            fum: new Date()
        })
        const nodes = ref();
        const filters = ref({});
        let expandedKeys = ref({});
        const externalData = ref<WidgetComponentDataTree>()
        const { builtTree, setExternalData } = ExpressionTreeNode(helperCatalog.getAllCatalogApp(), props.expressionDetail, externalData.value as WidgetComponentDataTree, props.applicationId, props.applicationVersion, store,props.idObjeto,props.lookUp);
        const selectedTools = ref(new Array());
        let hasLoadExternalData = ref(false);
        let forceReloadTreeTools = false;
        function insertText(insertedText: string) {
            const textarea = document.getElementById('query') as HTMLTextAreaElement;
            const start = textarea.selectionStart!;
            const end = textarea.selectionEnd;
            const textBefore = variable.value.expression?.substring(0, start);
            const textAfter = variable.value.expression?.substring(end);
            variable.value.expression = textBefore + insertedText + textAfter;

            //mover el cursor en la posicion donde es insertado el texto
            const newCursorPos = start + insertedText.length;

            setTimeout(() => {

                textarea.focus();
                textarea.setSelectionRange(newCursorPos, newCursorPos);

            }, 1000)


        };


        const search = (event: any) => {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    filteredNameV.value = [...itemsNameV];
                } else {
                    filteredNameV.value = itemsNameV.filter((variableName) => { return variableName.toLowerCase().startsWith(event.query.toLowerCase()); });
                }
            }, 5);
        }




        const rules = computed(() => ({
            variable: {
                required: helpers.withMessage('El nombre de la variable es obligatorio', required),
            },
            expression: {
                required: helpers.withMessage('La fórmula es obligatoria.', required),
                validExp: helpers.withMessage('La expresión no tiene el formato correcto.', (...args) => {
                    
                    let error: boolean = true;

                    if (args[args.length - 2]) {
                        const e = new Expression(args[args.length - 2]);
                        error = !e.HasErrors()
                    }
                    return error;
                }),
            },
        }));

        const v$ = useVuelidate(rules, variable as any)


        const loadVariable = () => {

            if (props.id && props.container) {
                const _srv = props.container.get<IServiceExpressionDetail>(TYPES.EXPRESSION_DETAIL_REPOSITORY)
                HelperLoading.showLoading()
                _srv.getById(props.id).then(response => {
                    if (response) {
                        variable.value = response;
                    }
                })
                    .finally(() => {
                        HelperLoading.hideLoading()
                    })
            }
            else {
                return []
            }
        }


        const isValidData = (): boolean => {
            
            submitted.value = true;
            v$.value.$touch();
            if (v$.value.$invalid) {
                HelperCommon.setFocusOnFirstError();
                return false;
            }

            return true;
        }

        const saveIndividualProperty = () => {
            if (props.container) {
                const _srv = props.container.get<IServiceExpressionDetail>(TYPES.EXPRESSION_DETAIL_REPOSITORY)
                HelperLoading.showLoading()
                variable.value.uum = store.getters.getCurrentUser.id
                variable.value.fum = new Date()
                const dataToSave = JSON.parse(JSON.stringify(variable.value));

                if (HelperCommon.isNullOrWhitespace(props.id)) {
                    _srv.add(dataToSave as any).then(response => {
                        if (response) {
                            variable.value = response;
                            emit('refresh');
                            emit('close');
                            emit('expressionsChanged', null);
                            MessageService.showToast(MessageType.Correcto, '', "Se ha creado la variable correctamente")
                        }
                    }).finally(() => {
                        HelperLoading.hideLoading()
                    })
                }
                else {
                    _srv.update(dataToSave as any).then(response => {
                        if (response) {
                            variable.value = response;
                            MessageService.showToast(MessageType.Correcto, '', "Se ha actualizado la variable correctamente")
                            emit('refresh');
                            emit('close');
                        }
                    }).finally(() => {
                        HelperLoading.hideLoading()
                    })
                }

            }
        }

        const saveVariable = () => {
            selectedTools.value = tools.value.map(x => x.id) as any;
            //updateTranslation();



            if (isValidData()) {

                // no tiene cabecera
                if (props.expressionId == '') {
                    if (props.container && !props.isExternal) {
                        const _srv = props.container.get<IServiceExpression>(TYPES.EXPRESSION_REPOSITORY)
                        HelperLoading.showSaving()
                        const request: Partial<IapExpression> = {
                            id: HelperUtils.newGuid(),
                            applicationId: props.applicationId,
                            applicationVersion: props.applicationVersion,
                            idObjeto: props.idObjeto,
                            objetoId: props.objetoId,
                            idObjetoRoot: props.idObjetoRoot,
                            objetoIdRoot: props.objetoIdRoot,
                            idTypeExpression: props.expType,
                            group: HelperUtils.newGuid(),
                            fcr: new Date(),
                            ucr: store.getters.getCurrentUser.id
                        }


                        _srv.add(request as any).then(response => {
                            if (response) {
                                variable.value.expressionId = response.id
                                saveIndividualProperty();

                            }
                        })
                            .finally(() => {
                                HelperLoading.hideSaving()
                            })
                    }
                    else{
                        emit('external:insert',variable.value)
                    }
                }
                else {
                    if (!props.isExternal){
                        saveIndividualProperty();
                    }
                    else
                    {
                        if (variable.value['orignalRow']){
                            emit('external:update',variable.value)
                        }
                        else{
                            emit('external:insert',variable.value)
                        }
                        
                    }
                    
                }

            }

        };


        const onNodeExpand = (event: any) => {
            nodeExpanded.value = true;
        }

        const onNodeCollapsed = (event: any) => {
            nodeExpanded.value = true;
        }

        const isSelectable = (exp: string) => {
            return exp == ExpressionNomenclatorConst.EXPNOM_COMPONENT
                || exp == ExpressionNomenclatorConst.EXPNOM_MENU
                || exp == ExpressionNomenclatorConst.EXPNOM_DATASOURCE
                || exp == ExpressionNomenclatorConst.EXPNOM_DSF;
        }

        const onNodeSelect = (event: any) => {
            if (!props.isExternal){
                if (event.children.length == 0 || (isSelectable(event.data.nomenclator) && nodeExpanded.value == false)) 
                {
                    insertText(event.data.value);
                    updateTranslation();
                }
            }
            else
            {
                if (event.data.value){
                    insertText(event.data.value);
                    updateTranslation();                    
                }
            }

            nodeExpanded.value = false;

        };


        function expandTreeNodes(value: any) {
            if (value) {
                expandedKeys.value = getAllKeys(expressionTree.value);
            }
            else {
                expandedKeys.value = {};
            }

        }


        function getAllKeys(nodes: any) {

            let keys = {};

            nodes.forEach((node: any) => {

                keys[node.key] = true;

                if (node.children) {

                    keys = Object.assign({}, keys, getAllKeys(node.children));

                }

            });
            return keys;
        }



        const updateTranslation = () => {
            if (!props.isExternal){
                const { replaceValuesInString } = ExpressionTranslator(expressionTree.value);
                variable.value.expressionTranslated = replaceValuesInString(variable.value.expression ?? '')
            }
            else{
                if (props.externalTranslateFunction){
                    variable.value.expressionTranslated = props.externalTranslateFunction(variable.value.expression ?? '')   
                }
            }
            
        }


        const tools = computed(() => {
            const excludeIds = [ExpressionToolConst.EXPTOOL_FN,
            ExpressionToolConst.EXPTOOL_OP,
            ExpressionToolConst.EXPTOOL_ID
            ]
            const data = helperCatalog.getAllCatalogApp();
            return data.filter(x => x.id == CatalogTypeConst.EXPTOOL).flatMap(x => x.iapCatalogs).filter(x => x.parentId == null && !excludeIds.includes(x.id)).sort((a, b) => { return (a.order ?? 0) - (b.order ?? 0); });
        })







        const buildTools = async () => {
            if(selectedTools.value.includes(ExpressionToolConst.EXPTOOL_OT) && !hasLoadExternalData.value){
                const posicion: number = selectedTools.value.indexOf(ExpressionToolConst.EXPTOOL_OT);
                            if (posicion > -1) {
                                selectedTools.value.splice(posicion, 1);
                            }
            }
            expressionTree.value = builtTree(selectedTools.value);
            updateTranslation();
        }



        const loadExternalData = () => {

            if (props.container){
                HelperLoading.showLoading()
                const _srv = props.container.get<IServiceComponent>(TYPES.COMPONENT_REPOSITORY)
                _srv.getTreeAllObjectsById(props.applicationId ?? -1, props.applicationVersion ?? -1, Number(props.externalId) ?? -1,true).then(response => {

                        if (response) {
                        setExternalData(nest(response, null, 'parentId')?.find(x => x !== undefined) as WidgetComponentDataTree)
                        response = []
                        
                        hasLoadExternalData.value = true;
                        if (forceReloadTreeTools) {
                            expressionTree.value = builtTree(selectedTools.value);
                        }
                    }

                })
                    .finally(() => {
                        HelperLoading.hideLoading()
                    })

            }

               
        }

        const updateCacheComp = () => {

            forceReloadTreeTools = true;
            hasLoadExternalData.value = false;
            loadExternalData()

           
        }


        onMounted(() => {
            if (!props.isExternal){
                loadExternalData()
                expressionTree.value = builtTree(selectedTools.value);
                loadVariable();
            }
            else{
                if (props.id){
                    variable.value = JSON.parse(props.id) as any                    
                    updateTranslation()
                }

                expressionTree.value = props.externalTree
                
                hasLoadExternalData.value = true
            }
        });



        return {
            selectedVariable,
            valueNameV,
            itemsNameV,
            search,
            filteredNameV,
            variable,
            submitted,
            v$,
            saveVariable,
            nodes,
            filters,
            onNodeSelect,
            expandedKeys,
            expandTreeNodes,
            onNodeCollapsed,
            onNodeExpand,
            updateTranslation,
            selectedTools,
            tools,
            expressionTree,
            buildTools,
            updateCacheComp,
            CatalogObjectTypeConst,
            hasLoadExternalData


        };
    },
});
</script>
<style scoped>
.custom-textarea {
    width: 100%;
    /* Default width */
}
</style>
