import EventConst from '../../domain/constantes/EventConst'
import EventBusCustom from '../event-bus-custom'

const showLoading = () => {
  EventBusCustom.emit(EventConst.LOADING, true)
}

const hideLoading = () => {
  EventBusCustom.emit(EventConst.LOADING, false)
}

const showSaving = () => {
  EventBusCustom.emit(EventConst.SAVE, true)
}

const hideSaving = () => {
  EventBusCustom.emit(EventConst.SAVE, false)
}

export default { showLoading, hideLoading, showSaving, hideSaving }
