<template>
    <span>Conexión</span>
    <select @pointerdown.stop="" @dblclick.stop="" name="dslist" v-model="selectedDataConn"
        style="border: 1px solid #999;border-radius: 30px;    font-size: 110%;" @change="changeConnection()"
        class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full ">
        <option value="">Selecciona una conexión</option>
        <option v-for="(ds, index) in connectionData" v-bind:value="ds.id" :key="index">{{ ds.description }}

        </option>
    </select>
    <div v-if="selectedDataConn">
        <br />
        <span>Procedimiento</span>
        <select @pointerdown.stop="" @dblclick.stop="" name="dslistproc" v-model="selectedPropName"
            style="border: 1px solid #999;border-radius: 30px;    font-size: 110%;" @change="changeProcedureName()"
            class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full ">
            <option value="">Selecciona un procedimiento</option>
            <option v-for="(ds, index) in getProcedureNames" v-bind:value="ds.id" :key="index">{{ ds.description }}

            </option>
        </select>
    </div>
    <div v-if="selectedPropName" @pointerdown.stop="" @dblclick.stop=""
        style="overflow-y:auto; overflow-x:hidden; height:200px;">
        <br />


        <span>Parámetros</span>

        <table class="tableParameters">
            <thead>
                <tr>
                    <th v-for="(column, index) in ['Id', 'Nombre', 'Valor', '']" :key="index"> {{ column }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in dtProceduresVarInfo" :key="index">
                    <td v-for="(column, indexColumn) in ['parameterID', 'parameterName']" :key="indexColumn">
                        {{ item[column] }}
                    </td>
                    <td><input @pointerdown.stop="" @dblclick.stop="" type="text" @change="changeVariableValue()"
                            v-model="item['value']"
                            class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full " />

                    </td>
                    <td><i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" class="fa fa-subscript"
                            @click="showExpression(StoreProcedureNodeConst.VAR_PARAM_IN, item.parameterName, item)"></i>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script lang="ts">

import { computed, defineComponent, onMounted, ref } from 'vue';
import { IapComponentDataSource } from '../../../../../component/domain/iapComponentDataSource';
import Dropdown from 'primevue/dropdown';
import CatalogTypeConst from '../../../../../catalog/domain/const/CatalogTypeConst';
import helperCatalog from '../../../../../catalog/infrastructure/helper/helperCatalog';
import { Container } from 'inversify';
import { IServiceSearch } from '../../../../../search/application/IServiceSearch';
import { TYPES } from '../../../../../../../common/domain/types';
import HelperLoading from '../../../../../../../common/infrastructure/funciones/HelperLoading';
import CatalogDbType from '../../../../../catalog/domain/const/CatalogDbType';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';

import CatalogObjectTypeConst from '../../../../../catalog/domain/const/CatalogObjectTypeConst';
import { ProcedureData } from '../../../../../search/domain/procedureData';
import HelperCustomComponentControls from '../../../functions/helperCustomComponentControls';
import StoreProcedureNodeConst from '../constants/StoreProcedureNodeConst';


export default defineComponent({
    name: 'customstoreprocedurecontrol',

    props: {
        data: Object
    },
    setup(props) {

        const selectedDataConn = ref();
        const selectedPropName = ref();
        const datacatalog = helperCatalog.getAllCatalogApp();
        const dtProceduresInfo = ref<ProcedureData[]>([]);
        const dtProceduresVarInfo = ref<ProcedureData[]>([]);
        const { showExpression } = HelperCustomComponentControls(props.data);

        const connectionData = computed(() => {
            return datacatalog.filter(x => x.id == CatalogTypeConst.TIPOBD)?.find(x => x !== undefined)?.iapCatalogs.sort((a, b) => { return (a.order ?? 0) - (b.order ?? 0); });
        })

        const getProcedureNames = computed(() => {
            const aux = dtProceduresInfo.value.map((x: ProcedureData) => ({ id: x?.objectName, description: x?.objectName }))
            return aux.filter((a, i) => aux.findIndex((s) => a.id === s.id) === i)
        })

        const getProcedureVariables = computed(() => {
            const aux = dtProceduresInfo.value.filter((x: ProcedureData) => x.objectName == selectedPropName.value)
            return aux.filter((a: ProcedureData, i: number) => aux.findIndex((s: ProcedureData) => a.parameterID === s.parameterID) === i)
        })


        const changeConnection = () => {
            if (props.data) {
                getProcedures(selectedDataConn.value).then(response => {
                    if (response && props.data) {
                        selectedPropName.value = '';

                        props.data.onChange({ key: StoreProcedureNodeConst.VAR_CONN_IN, data: selectedDataConn.value })
                        props.data.onChange({ key: StoreProcedureNodeConst.VAR_PROC_IN, data: selectedPropName.value })
                        props.data.onChange({ key: StoreProcedureNodeConst.VAR_PARAM_IN, data: getProcedureVariables.value })
                    }

                });


            }


        }

        const changeProcedureName = () => {
            if (props.data) {
                dtProceduresVarInfo.value = getProcedureVariables.value;
                props.data.onChange({ key: StoreProcedureNodeConst.VAR_PROC_IN, data: selectedPropName.value })
                props.data.onChange({ key: StoreProcedureNodeConst.VAR_PARAM_IN, data: getProcedureVariables.value })
            }

        }

        const changeVariableValue = () => {
            if (props.data) {
                props.data.onChange({ key: StoreProcedureNodeConst.VAR_PARAM_IN, data: dtProceduresVarInfo.value })
            }

        }



        const getProcedures = async (typeDataBaseId: string): Promise<boolean> => {


            return await new Promise<boolean>((resolve, reject) => {

                if (props.data && props.data.container && !HelperCommon.isNullOrWhitespace(typeDataBaseId)) {

                    const _srv = (props.data.container as Container).get<IServiceSearch>(TYPES.SEARCH_REPOSITORY)
                    HelperLoading.showLoading()

                    _srv.searchProceduresAndFunctions(props.data.applicationId ?? -1, props.data.applicationVersion ?? -1, typeDataBaseId, CatalogDbType.SQLSERVER).then(response => {
                        dtProceduresInfo.value = JSON.parse(response.items) as ProcedureData[];

                    })
                        .finally(() => {
                            HelperLoading.hideLoading()
                            resolve(true)
                        })

                }
                else {
                    dtProceduresInfo.value = [];
                    resolve(true)
                }


            });


        };






        onMounted(() => {
            if (props.data) {
                selectedDataConn.value = props.data.variableConnectionInput
                selectedPropName.value = props.data.variableProcNameInput
                dtProceduresVarInfo.value = props.data.variableParametersInput

                if (selectedDataConn.value) {
                    getProcedures(selectedDataConn.value).then( (x:boolean)=> {                        
                        if (x && selectedPropName.value){
                            getProcedureVariables.value.forEach(x=> {
                                x.value = dtProceduresVarInfo.value.find(v=> v.parameterName === x.parameterName)?.value;
                            });                            
                            dtProceduresVarInfo.value = getProcedureVariables.value;
                            changeVariableValue();
                        }
                        
                    });
                }
            }
        })

        return {
            selectedDataConn,
            connectionData,
            selectedPropName,
            changeConnection,
            changeProcedureName,
            dtProceduresInfo,
            getProcedureNames,
            getProcedureVariables,
            changeVariableValue,
            CatalogObjectTypeConst,
            showExpression,
            dtProceduresVarInfo,
            StoreProcedureNodeConst
        };
    },
});
</script>
<style scoped></style>
