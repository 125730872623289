import BaseControlTypeConst from "./BaseControlTypeConst"

export default class TreeTableTypeConst extends BaseControlTypeConst {

    //static BINARY = 'binary'
    static READONLY = 'readonly'
    //static REQUIRED = 'required'
    static TABINDEX = 'tabIndex'

    static DATAVALUE = 'datavalue'
    static DATAFILEVALUE = 'datafilevalue'
    static DATASOURCEVALUE = 'datasourcevalue'

    static SELECTIONMODE = 'selectionMode'
    static METAKEYSELECTION = 'metaKeySelection'

    static ROWS = 'rows'
    static FIRST = 'first'
    static TOTALRECORDS = 'totalRecords'
    static PAGINATOR = 'paginator'
    static PAGINATORPOSITION = 'paginatorPosition'
    static ALWAYSSHOWPAGINATOR = 'alwaysShowPaginator'
    static PAGINATORTEMPLATE = 'paginatorTemplate'
    static PAGELINKSIZE = 'pageLinkSize'
    static ROWSPERPAGEOPTIONS = 'rowsPerPageOptions'
    static CURRENTPAGEREPORTTEMPLATE = 'currentPageReportTemplate'

    static LAZY = 'lazy'
    static LOADING = 'loading'
    static LOADINGICON = 'loadingIcon'
    static LOADINGMODE = 'loadingMode'

    static ROWHOVER = 'rowHover'
    static AUTOLAYOUT = 'autoLayout'
    static SORTFIELD = 'sortField'
    static SORTORDER = 'sortOrder'
    static DEFAULTSORTORDER = 'defaultSortOrder'
    static MULTISORTMETA = 'multiSortMeta'
    static SORTMODE = 'sortMode'
    static REMOVABLESORT = 'removableSort'

    static FILTERS = 'filters'
    static FILTERMODE = 'filterMode'
    static FILTERLOCALE = 'filterLocale'

    static COLUMNSDEF = 'columnsDef'
    static RESIZABLECOLUMNS = 'resizableColumns'
    static COLUMNRESIZEMODE = 'columnResizeMode'
    static INDENTATION = 'indentation'
    static SHOWGRIDLINES = 'showGridlines'
    static SCROLLABLE = 'scrollable'

    static SCROLLHEIGHT = 'scrollHeight'

    static SIZE = 'size'
    static TABLESTYLE = 'tableStyle'
    static TABLECLASS = 'tableClass'
    //static TABLEPROPS = 'tableProps'
}