import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: ModeloComponentDataSourceMigrationLogInputType!){
    componentDataSourceMigrationLogMutation{
        updateComponentDataSourceMigrationLog(data:$data)
    }
}
`);

export const ADD = gql(`
mutation($data: ModeloComponentDataSourceMigrationLogInputType!){
    componentDataSourceMigrationLogMutation{
        addComponentDataSourceMigrationLog(data:$data)
    }
}
`);



