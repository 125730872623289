import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withKeys as _withKeys, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-float-label wrap-input100 validate-input" }
const _hoisted_2 = {
  key: 0,
  class: "wrap-input100 validate-input"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = {
  key: 0,
  class: "p-error"
}
const _hoisted_8 = {
  key: 4,
  class: "wrap-input100 validate-input"
}
const _hoisted_9 = { key: 5 }
const _hoisted_10 = {
  key: 0,
  class: "p-error"
}
const _hoisted_11 = {
  key: 6,
  class: "wrap-input100 validate-input"
}
const _hoisted_12 = { key: 7 }
const _hoisted_13 = {
  key: 0,
  class: "p-error"
}
const _hoisted_14 = {
  key: 8,
  class: "p-float-label wrap-input100 validate-input"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_FloatLabel = _resolveComponent("FloatLabel")!
  const _component_Password = _resolveComponent("Password")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_openBlock(), _createElementBlock("div", { key: _ctx.componentKey }, [
      _createElementVNode("span", _hoisted_1, [
        (_ctx.options.length > 1)
          ? (_openBlock(), _createBlock(_component_Dropdown, {
              key: 0,
              modelValue: _ctx.selectedOptionId,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedOptionId) = $event)),
              style: {"width":"250px","margin-right":"200px"},
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChangeOption())),
              options: _ctx.options,
              filter: "",
              showClear: "",
              optionValue: "id",
              optionLabel: "description",
              placeholder: ""
            }, null, 8 /* PROPS */, ["modelValue", "options"]))
          : _createCommentVNode("v-if", true)
      ]),
      (_ctx.selectedOptionId != '' && _ctx.selectedOptionId != '2')
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
            _createVNode(_component_FloatLabel, null, {
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  type: "text",
                  placeholder: "Email",
                  modelValue: _ctx.passwordObject.userEmail,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.passwordObject.userEmail) = $event)),
                  style: {"width":"250px","margin-top":"10px"}
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            })
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.selectedOptionId != '' && _ctx.selectedOptionId != '2')
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            ((_ctx.v$.userEmail.$invalid && _ctx.submitted) || _ctx.v$.userEmail.$pending)
              ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.v$.userEmail.required.$message), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.selectedOptionId == '2')
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
            _createVNode(_component_FloatLabel, null, {
              default: _withCtx(() => [
                _createVNode(_component_Password, {
                  modelValue: _ctx.passwordObject.password,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.passwordObject.password) = $event)),
                  feedback: false,
                  class: _normalizeClass(["input100", { 'p-password': true, 'p-invalid': _ctx.v$.password.$invalid && _ctx.submitted }]),
                  name: "passwordInput",
                  style: {"width":"250px","margin-top":"10px"},
                  placeholder: "Contraseña",
                  onKeyup: _withKeys(_ctx.onSubmitResetPassword, ["enter"])
                }, null, 8 /* PROPS */, ["modelValue", "onKeyup", "class"])
              ]),
              _: 1 /* STABLE */
            })
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.selectedOptionId == '2')
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
            ((_ctx.v$.password.$invalid && _ctx.submitted) || _ctx.v$.password.$pending)
              ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.v$.password.required.$message), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.selectedOptionId == '2' || _ctx.selectedOptionId == '3')
        ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
            _createElementVNode("div", null, [
              _createVNode(_component_FloatLabel, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Password, {
                    modelValue: _ctx.passwordObject.newpassword,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.passwordObject.newpassword) = $event)),
                    feedback: false,
                    class: _normalizeClass(["input100", { 'p-password': true, 'p-invalid': _ctx.v$.newpassword.$invalid && _ctx.submitted }]),
                    name: "passwordInput",
                    style: {"width":"250px","margin-top":"10px"},
                    placeholder: "Nueva contraseña",
                    onKeyup: _withKeys(_ctx.onSubmitResetPassword, ["enter"])
                  }, null, 8 /* PROPS */, ["modelValue", "onKeyup", "class"])
                ]),
                _: 1 /* STABLE */
              })
            ])
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.selectedOptionId == '2' || _ctx.selectedOptionId == '3')
        ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
            ((_ctx.v$.newpassword.$invalid && _ctx.submitted) || _ctx.v$.newpassword.$pending)
              ? (_openBlock(), _createElementBlock("small", _hoisted_10, _toDisplayString(_ctx.v$.newpassword.required.$message), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.selectedOptionId == '3')
        ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
            _createVNode(_component_FloatLabel, null, {
              default: _withCtx(() => [
                _createVNode(_component_Password, {
                  modelValue: _ctx.passwordObject.confirmnewpassword,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.passwordObject.confirmnewpassword) = $event)),
                  feedback: false,
                  class: _normalizeClass(["input100", { 'p-password': true, 'p-invalid': _ctx.v$.confirmnewpassword.$invalid && _ctx.submitted }]),
                  name: "passwordInput",
                  style: {"width":"250px","margin-top":"10px"},
                  placeholder: "Confirmar nueva contraseña",
                  onKeyup: _withKeys(_ctx.onSubmitResetPassword, ["enter"])
                }, null, 8 /* PROPS */, ["modelValue", "onKeyup", "class"])
              ]),
              _: 1 /* STABLE */
            })
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.selectedOptionId == '3')
        ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
            ((_ctx.v$.confirmnewpassword.$invalid && _ctx.submitted) || _ctx.v$.confirmnewpassword.$pending)
              ? (_openBlock(), _createElementBlock("small", _hoisted_13, _toDisplayString(_ctx.v$.confirmnewpassword.required.$message), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.selectedOptionId != '')
        ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
            _createVNode(_component_Button, {
              id: "reestablecerpwd",
              label: "Restablecer contraseña",
              onClick: _ctx.onSubmitResetPassword,
              icon: "pi pi-key",
              style: {"width":"250px","margin-top":"10px"},
              class: "p-button p-component p-button-secondary mr-2 mb-2"
            }, null, 8 /* PROPS */, ["onClick"])
          ]))
        : _createCommentVNode("v-if", true)
    ]))
  ], 64 /* STABLE_FRAGMENT */))
}