import { RouteRecordRaw } from "vue-router";
import DirectiveConst from "../../domain/constantes/DirectiveConst";
import { CustomMenu } from "../../domain/modelos/menu/CustomMenu";
import { IapmUser } from "../../../entidades/builderMaster/user/domain/iapmUser";
import { IapObjectGroup } from "../../../entidades/builder/objectgroup/domain/iapObjectGroup";

export const checkAccess = (builtIn: string, contract: string, directives: string, user: IapmUser): boolean => {
    //Si la página está definida como builtin, sólo permitimos su acceso al usuario del corredor admin
    /*
        TODO 
        if (builtIn && builtIn != '') {
            if (builtIn == 'true') {
                if (!user?.broker?.isAdmin) {
                    return false;
                }
            }
        }

        //Si la página está definida como contract, sólo permitimos su acceso a usuarios de corredores con contrato
        if (contract && contract != '') {
            if (contract == 'true') {
                if (!user?.broker?.contractWillPlatine) {
                    return false;
                }
            }
        }
    */
    if (directives && directives != '') {
        let validDirective = true;
        const split = directives.split(';');
        // Vamos validando las directivas especificadas. Si alguna está denegada, rechazamos acceso
        split.forEach(function (s) {
            if (s.trim() != '') {
                if (!validateDirective(user, s)) {
                    validDirective = false;
                    return false;
                }
            }
        });

        // Si la directiva no ha sido denegada (O si no habían directivas a consultar), damos acceso.
        return validDirective;
    }

    return true;
};

/**
 * @description validate if can access to route
 */
export const canUserAccess = (_name: string, routes: RouteRecordRaw[], user: IapmUser): boolean => {
    //const user: user = store.getters.currentUser;
    const routeSearch = routes.filter(x => x.name == _name);
    // si no se encuentra la ruta no se tiene acceso
    if (routeSearch.length != 1) {
        return false;
    }

    const route = routeSearch[0];
    const builtIn = route?.meta?.builtin as string;
    const contract = route?.meta?.contrato as string;
    const directives = route?.meta?.directivas as string;

    return checkAccess(builtIn, contract, directives, user);
};

/**
 * @description validate if can access to route
 */

export const buildUserMenu = (user: IapmUser, menus: CustomMenu[]): CustomMenu[] => {
    const data: CustomMenu[] = [];

    menus.forEach(function (value) {
        const itemsFiltered = value.items?.filter((x) => {
            return checkAccess(x.builtin, x.contrato, x.directivas, user);
        });

        if (itemsFiltered && itemsFiltered.length > 0) {
            value.items = itemsFiltered;
            data.push(value);
        }
    });

    return data;
};

/**
 * @description validate user directive
 */
const validateDirective = (user: IapmUser, directive: string): boolean => {
    /*
    TODO
    if (user?.broker?.isAdmin) {
        return true;
    }
    

    let directiveAllowed = false;
    const deniedQuery = user?.directives?.filter((x) => x.name.toLowerCase() == directive.toLowerCase() && x.allowed == false);

    if (deniedQuery == null || deniedQuery.length == 0) {
        //Si la directiva no está denegada, buscamos si está permitida
        const allowedQuery = user?.directives?.filter((x) => x.name.toLowerCase() == directive.toLowerCase() && x.allowed == true);
        if (allowedQuery == null || allowedQuery.length == 0) {
            //Si la directiva no está definida (ni denegada ni permitida), denegamos el acceso a la página
            directiveAllowed = false;
        } else {
            //Si la directiva está permitida, damos acceso
            directiveAllowed = true;
        }
    }
    return directiveAllowed;
    */

    return true;
};

/**
 * @description validate if can access to route
 */

export const findMenu = (key: string, menus: CustomMenu[]): CustomMenu | null => {
    let menu: CustomMenu | null = null;
    menus.forEach(function (value) {
        if (menu == null) {
            const itemsFiltered = value.items?.filter((x) => {
                return x.to == key;
            });

            if (itemsFiltered && itemsFiltered.length > 0) {
                menu = itemsFiltered[0] as any;
            }
        }
    });

    return menu;
};

/// Parsea una query
const parseQuery = (queryString: string): any => {
    const query = {} as any;
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
};

const getQueryParameter = (key: string, queryString: string): string => {
    return parseQuery(queryString)[key];
};

/*
const doEncrypt = (sessionID: string, encrypt: boolean, queryString: string): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        if (sessionID == '') {
            resolve('');
        } else {
            const request: encryptRequest = {
                encrypt: encrypt,
                data: queryString,
                sessionID: sessionID,
            };
            ApiNostromo.post('/Encryption/doEncrypt', request as any)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(() => {
                    reject();
                });
        }
    });
};

const SetPassword = (request: setPasswordRequest): Promise<setPasswordResponse> => {
    return new Promise<setPasswordResponse>((resolve, reject) => {
        ApiNostromo.post('/security/setPassword', request as any)
            .then((response) => {
                resolve(response.data);
            })
            .catch(() => {
                reject();
            });
    });
};
const GetOptions = (sessionId, names: string[]): Promise<getOptionsResponse> => {
    return new Promise<getOptionsResponse>((resolve, reject) => {
        const request: Partial<getOptionsRequest> = {
            sessionID: sessionId,
            all: false,
            names: names,
        };
        ApiNostromo.post('/security/getOptions', request as any)
            .then((response) => {
                resolve(response.data);
            })
            .catch(() => {
                reject();
            });
    });
};

const logout = (sessionID): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
        const data: any = {
            sessionId: sessionID,
        };
        ApiNostromo.post('/security/logout', data)
            .then(() => {
                resolve(true);
            })
            .catch(() => {
                reject();
            });
    });
};

const getGroups = (sessionID, id = null, name = null, parentId = null): Promise<group[]> => {
    return new Promise<group[]>((resolve, reject) => {
        const request: Partial<getGroupsRequest> = {
            sessionID: sessionID,
            id: id,
            name: name as any,
            parentId: parentId,
        };
        ApiNostromo.post('/security/getGroups', request as any)
            .then((response) => {
                return resolve(response.data.groups ?? []);
            })
            .catch(() => {
                reject();
            });
    });
};
*/
const hasAuthorizeIssuancePermission = (user: IapmUser) => {
    return validateDirective(user, DirectiveConst.AUTHORIZE_ISSUANCE);
};




const canDoOperation = (user: IapmUser,objectGroups:IapObjectGroup[],action:string) => {    
    if (user?.isAdmin ?? false) {
        return true;
    }
    
    if ( (objectGroups?.length ?? 0) == 0){
        return false;
    }
    
    var userGroupsIds = user?.userEntityGroups?.map(x=> x.groupId) ?? []
    
    if (userGroupsIds.length == 0)
    {
        return false;
    }

    return objectGroups.find(x=> userGroupsIds.includes(x.groupId) && (x as any)[action] == true )
   
};



export default {
    canUserAccess,
    validateDirective,
    buildUserMenu,
    checkAccess,
    findMenu,
    parseQuery,
    getQueryParameter,
    hasAuthorizeIssuancePermission,
    canDoOperation

};
