/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { computed, shallowRef, triggerRef } from 'vue';
import HelperLoading from '../../../../../common/infrastructure/funciones/HelperLoading';
import MessageService, { MessageType } from '../../../../../common/infrastructure/servicios/MessageService';
import HelperCommon from '../../../../../common/infrastructure/funciones/HelperCommon';
import HelperUtils, { newGuid } from '../../../../../common/infrastructure/funciones/HelperUtils';
import TabData from '../tabData';

export default function multiTab (_store:any) {
    
    //const newKeys = ref<TabData[]>()
    let itemTabs = shallowRef<TabData[]>([]);
    let activeId = shallowRef('');
    let selectedTab = shallowRef(0);
   
    const changeTab = (event: any) => {

        const index = event.index;

        navigateTab(index);
    }
    const navigateTab = (index: number) => {
        selectedTab.value = index;
    }

    const getMaxTabs = () => {
       /*
        const key = store.getters.getKey(ConfigurationConst.NUMMAXTABSOPORTUNIDADES);
        if (key) {
            const converter = HelperCommon.toNumber(key);
            if (converter > 0) {
                return converter;
            }
        }
    */
        return 8;


    }
    const findTab = (key: any | null) => {
        if (itemTabs.value) {
            return itemTabs.value.findIndex(x => x.key == key);
        }
        return -1;
    }

    const addTab = (tabInput:TabData ) => {
       
        HelperLoading.showLoading();
        const key = tabInput.key ?? newGuid();
        var pos = findTab(tabInput.key );
        if (pos != -1) {
            HelperLoading.hideLoading();
            navigateTab(pos);            
            
            return;
        }
        const contador = itemTabs.value.length + 1;
        if (contador > getMaxTabs()) {
            MessageService.showMessage(MessageType.Aviso, '', 'Se ha superado el número máximo de pestañas abiertas ('+ getMaxTabs().toString() +').');
            HelperLoading.hideLoading();
            return;
        }

        tabInput.key = key;
        
        if (HelperCommon.isNullOrWhitespace(tabInput.keyInternal)){
            tabInput.keyInternal =  HelperUtils.newGuid();
        }
        

        if (contador == 1) {
            itemTabs.value = [tabInput];
        }
        else {
            //itemTabs.value?.push(tabInput);
            HelperUtils.addItemToShallowRefArray(itemTabs,tabInput);
        }
        activeId.value = key;
        navigateTab(contador - 1);
        HelperLoading.hideLoading();

    }

    

    const closeTab = (id:string): Promise<boolean> => {
        return new Promise<boolean>((resolve) => {

            if (itemTabs.value) {
                itemTabs.value = itemTabs.value.filter(x=> x.key !== id);
                        selectedTab.value -= 1;
                        HelperLoading.hideLoading();
                        if (selectedTab.value <= 0) {
                            selectedTab.value = 0;
                        }
                        triggerRef(itemTabs);
                        resolve(true);

            }
        })
    }

    const closeAllTabs = () => {
        if (itemTabs.value) {
            itemTabs.value.filter(x=> x.closable).forEach( (x:TabData) => {
                closeTab(x.key)
            })
        }
    }

    const currentTab = computed(() =>{
        
        return itemTabs.value[selectedTab.value]
    })

    const clearAllTabData = () =>{
         itemTabs.value = [];
        activeId.value ='';
        selectedTab.value  = 0;
    }

  return {    
    itemTabs,
    activeId,
    selectedTab,
    changeTab,
    navigateTab,
    addTab,
    findTab,
    closeAllTabs,
    closeTab,
    currentTab,
    clearAllTabData
    //newKeys
  }
}
