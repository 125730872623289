import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';

import { IObjectDataSourceFieldConfigurationRepository } from '../domain/IObjectDataSourceFieldConfigurationRepository';

import {GET_ALL_OBJECTDATASOURCEFIELDCONFIGURATION,GET_BY_OBJECTID} from './query/objectDataSourceFieldConfigurationQuery';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { IapObjectDataSourceFieldConfiguration } from '../domain/iapObjectDataSourceFieldConfiguration';
import { UPDATE,ADD } from './mutation/objectDataSourceFieldConfigurationMutation';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class ObjectDataSourceFieldConfigurationApolloClientRepository implements IObjectDataSourceFieldConfigurationRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId=ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }

  async update (dataInput: IapObjectDataSourceFieldConfiguration): (Promise<IapObjectDataSourceFieldConfiguration>) {
    return new Promise<IapObjectDataSourceFieldConfiguration>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE,{data:dataInput},this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.objectDataSourceFieldConfigurationMutation.updateObjectDataSourceFieldConfiguration)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async add (dataInput: IapObjectDataSourceFieldConfiguration): (Promise<IapObjectDataSourceFieldConfiguration>) {
    return new Promise<IapObjectDataSourceFieldConfiguration>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD,{data:dataInput},this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.objectDataSourceFieldConfigurationMutation.addObjectDataSourceFieldConfiguration)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async getAll (maxregs: number | undefined): (Promise<IapObjectDataSourceFieldConfiguration[]>) {
    return new Promise<IapObjectDataSourceFieldConfiguration[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_ALL_OBJECTDATASOURCEFIELDCONFIGURATION, { maxregs: maxregs }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.objectDataSourceFieldConfigurationQuery.getAll)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getByObjectId (idObject: string, objectId:number): (Promise<IapObjectDataSourceFieldConfiguration[]>) {
    return new Promise<IapObjectDataSourceFieldConfiguration[]>(async (resolve, reject) => {      
      const result = this.apolloClient.executeQuery(GET_BY_OBJECTID, { idObjeto: idObject,objetoId:objectId },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.objectDataSourceFieldConfigurationQuery.getByObjectId)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


}


