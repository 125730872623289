import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IMenuRepository } from '../domain/IMenuRepository';



import { IServiceMenu } from './IServiceMenu';
import { IapMenu } from '../domain/iapMenu';





@injectable()
export class MenuService implements IServiceMenu {
  private readonly repo: IMenuRepository

  public constructor (@inject(TYPES.MENU_REPOSITORY) _repo: IMenuRepository) {
    this.repo = _repo
  }

  async update (data: IapMenu): (Promise<boolean>) {
    return await this.repo.update(data);
  }

  async add (data: IapMenu): (Promise<boolean>) {
    return await this.repo.add(data);
  }

  async deleteById (id: number): (Promise<boolean>) {
    return await this.repo.deleteById(id);
  }

  async getAll(maxregs: number | undefined) :Promise<IapMenu[]> {
    return await this.repo.getAll(maxregs)
  }

  async getById(id: number) :Promise<IapMenu> {
    return await this.repo.getById(id)
  }

  async getByApplicationId(administrator: number, profileId: number, applicationId: number, applicationVersion: number) :Promise<IapMenu[]> {
    return await this.repo.getByApplicationId(administrator, profileId, applicationId, applicationVersion)
  }
}
