import BaseControlTypeConst from "./BaseControlTypeConst"

export default class ImageTypeConst extends BaseControlTypeConst {
    
  static SRC = 'src'
  static ALT = 'alt'
  static WIDTH = 'width'
  static HEIGHT = 'height'

}
