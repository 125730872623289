import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IDataSourceServiceRepository } from '../domain/IDataSourceServiceRepository';

import { IServiceDataSourceService } from './IServiceDataSourceService';
import { WSDL } from '../domain/wsdl';
import { Swagger } from '../domain/swagger';
import { ResultadSearch } from '../../search/domain/resultSearch';





@injectable()
export class DataSourceServiceService implements IServiceDataSourceService {
  private readonly repo: IDataSourceServiceRepository

  public constructor(@inject(TYPES.DATASOURCESERVICE_REPOSITORY) _repo: IDataSourceServiceRepository) {
    this.repo = _repo
  }
  

  async classFromWsdlUrl(applicationId: number, applicationVersion: number, datasourceId: number, url: string): Promise<WSDL> {
    return await this.repo.classFromWsdlUrl(applicationId, applicationVersion, datasourceId, url)
  }

  async classFromSwaggerUrl(applicationId: number, applicationVersion: number, datasourceId: number, url: string, jsonFile: string): Promise<Swagger> {
    return await this.repo.classFromSwaggerUrl(applicationId, applicationVersion, datasourceId, url, jsonFile)
  }

  async callWebServiceSOAP(applicationId: number, applicationVersion: number, datasourceId: number, url: string, method: string, request: string): Promise<string> {
    return await this.repo.callWebServiceSOAP(applicationId, applicationVersion, datasourceId, url, method, request)
  }

  async callWebServiceApiRest(token: string, applicationId: number, applicationVersion: number, datasourceId: number, userId: number, isAdmin: boolean | null, profileId: number | null, url: string, endPoint: string, type: string, body: string, params: string): Promise<ResultadSearch> {
    return await this.repo.callWebServiceApiRest(token, applicationId, applicationVersion, datasourceId, userId, isAdmin, profileId, url, endPoint, type, body, params)
  }
}