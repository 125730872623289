/* eslint-disable @typescript-eslint/no-extraneous-class */
export default class CatalogTypeConst {
  
  static FILTROBUSQUEDA = 'filterbusqueda'

  static TIPOOBJETOSQL ='TipoObjetoSql' // tablas o vistas
  static DOBLEFACTORAUTH='doblefactorauth' // doble factor de autenticación

  static SEXO='sexo'

  static TIPODOCUMENTOIDEN='identificacion'

  static ESTADOCIVIL='estadocivil'
  static PROFESION='profesiones'
  static ACTIVIDAD='actividad'
  static IDIOMA='idioma'
  static GESTION='gestion'
  static CANALCOBRO='canalcobro'



}



