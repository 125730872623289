<template>
    <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG, Component)" :data="Component"
        :vmodel="vmodel" />


    <div v-if="!hasLayouts" class="user-info" style="position: fixed;top: 10px;right: 10px;" @click="salir">
        <a href="#" class="layout-topbar-icon" v-tooltip.bottom="{ value: 'Salir' }">
            <i class="topbar-icon pi pi-sign-out"></i>
        </a>
    </div>

</template>
<script lang="ts">

import { defineComponent, onMounted, ref, onBeforeUnmount, watch, computed, onUnmounted, getCurrentInstance,shallowRef } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';
import SummaryDebug from '../../shared/SummaryDebug.vue';
import { useRouter } from 'vue-router';
import LayoutTypeConst from '../../../../domain/Constants/LayoutTypeConst';
import renderComponent from '../../../../../../../common/infrastructure/funciones/renderComponent';
import { useLayout } from '../../../../../../../common/infrastructure/componentes/base/common/composables/layout';
import ComponentRenderHelper from '../../../../domain/Functions/ComponentRenderHelper';
import ControlTypeConst from '../../../../domain/Constants/ControlTypeConst';
import CatalogCompConst from '../../../../../catalog/domain/const/CatalogCompConst';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';
import HelperUtils from '../../../../../../../common/infrastructure/funciones/HelperUtils';

export default defineComponent({
    name: 'dynamiclayout',
    components: {
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        slotProps: {
            type: Object,
            default: () => ({})
        },

    },
    setup(props, context) {
        const router = useRouter();
        const store = useStore();        
        const destroyComp = shallowRef([]);
        const appContext = getCurrentInstance()?.appContext ?? null        
        const { onMenuToggle, onProfileSidebarToggle, onConfigSidebarToggle } = useLayout();

        const { vmodel, canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded, resolveExpressions, baseOnUnmount
            , fieldKey, fieldKeyComponentDataSourceId, getLayoutComponent, componentKey
        } = ComponentCommonRender(props.Component, props.slotProps, props.container, store);


        const hasLayouts = computed(() => {
            return destroyComp.value.length > 0;
        });


        function salir() {
            HelperCommon.salir(props.container as any, router)
        }


        const insert = async (dataInput: ComponentDataForm, elInput: any) => {
            //destroyComp?.();            
            var _dataInput = Object.assign({}, dataInput);
            _dataInput.idType = ControlTypeConst.CTDIV
            const comps = dataInput.get(dataInput.id, 'allchildren') as any
            comps.unshift(_dataInput);            
            if (comps?.length > 0) {
                HelperUtils.addItemToShallowRefArray(destroyComp,
                [renderComponent({
                    el: elInput,// container.value,
                    component: ComponentRenderHelper.BuildComponent(dataInput.id, comps),
                    props: {
                        container: props.container,
                        rootParentId: dataInput.rootParentId,
                        comps: comps,
                        formKey: dataInput.formKey,
                        slotProps: props.slotProps,

                    },
                    appContext,
                }) as any])
                
            }

        }

        onMounted(() => {

            let retries = 0;
            const dataTopBar = getLayoutComponent(props.Component, LayoutTypeConst.LAYOUT_TOPBAR) as any
            if (dataTopBar) {
                const selector = getPropertyValue('selector', dataTopBar);
                if (selector) {
                    let timer = setInterval(function () {
                        const selectorHtml = document.evaluate(selector ?? '', document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue
                        if (selectorHtml) {
                            insert(dataTopBar, selectorHtml)
                        }
                        if (selectorHtml || retries > 100) {
                            clearInterval(timer)
                        }
                        else {
                            retries++;
                        }
                    }, 100);
                }

            }

            const dataFooter = getLayoutComponent(props.Component, LayoutTypeConst.LAYOUT_FOOTER) as any
            if (dataFooter) {

                const selector = getPropertyValue('selector', dataFooter);
                if (selector) {
                    retries = 0;
                    let timer = setInterval(function () {
                        const selectorHtml = document.evaluate(selector ?? '', document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue
                        if (selectorHtml) {
                            insert(dataFooter, selectorHtml)
                        }
                        if (selectorHtml || retries > 100) {
                            clearInterval(timer)
                        }
                        else {
                            retries++;
                        }
                    }, 100);
                }

            }

        })


        onUnmounted(() => {
            destroyComp.value.forEach((comp:any) => {
                comp?.destroy();                
            });
            destroyComp.value.length = 0;

            baseOnUnmount();

        })


        return {

            BaseControlTypeConst,
            vmodel,
            getPropertyBooleanValue,
            componentKey,
            loaded,
            salir,
            hasLayouts
        };
    },
});
</script>
<style scoped></style>
