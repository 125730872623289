import gql from 'graphql-tag';

export const GET_ALL_DATASOURCE_TABLEALIAS = gql(`
query($maxregs: Int) {
    dataSourceQuery {
      getAll(maxregs:$maxregs) {
        id,
        applicationId,
        applicationVersion,
        idDataSourceType,
        Name,
        Description,                  
        fcr,
        ucr,
        uum,
        fum
      }
    }
}
`);

export const GET_DATASOURCE_TABLEALIAS_BY_ID = gql(`
query($id: Int, $version: Int) {
    dataSourceQuery {
      getById(id:$id, version:$version) {
        id,
        applicationId,
        applicationVersion,
        idDataSourceType,
        Name,
        Description,        
        fcr,
        ucr,
        uum,
        fum
      }
    }
}
`);

