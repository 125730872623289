<template>
    <div class="p-inputgroup flex-1">
        <Dropdown v-if="dataSourceLookUp?.idControlType == ControlTypeConst.CTDROPDOWN" v-model="dataModelValue"
            :options="data" :optionLabel="DataSourceConst.LOOKUPDESC" :optionValue="DataSourceConst.LOOKUPMODELVALUE"
            :disabled="disabled" placeholder="Seleccione registro" filter @change="changeData"
            :style="{ 'width': controlWidth }"
            :showClear="showClear && canDoAction(ObjectGroupConst.EDIT) ? true : false" />
        <MultiSelect v-else-if="dataSourceLookUp?.idControlType == ControlTypeConst.CTMULTISELECT"
            v-model="dataModelValue" :options="data" optionLabel="LookUpDescription" placeholder="Seleccione registros"
            filter :showClear="showClear ? true : false" :disabled="disabled" />
        <div v-else-if="dataSourceLookUp?.idControlType == ControlTypeConst.CTSEARCH" class="p-inputgroup flex-1">
            <InputText v-model="dataModelValue" :value="looKUpDescription" readonly
                :placeholder="showSearch ? 'Buscar un registro...' : ''" />
            <span v-if="showClear && canDoAction(ObjectGroupConst.EDIT) && !disabled" class="p-input-icon-right"
                style="display: inline;">
                <i class="pi pi-times text-gray-500" style="font-size: 0.90rem" v-tooltip.top="'Borrar'"
                    @click="dataModelValue = null" />
            </span>
            <span v-if="showSearch && !disabled" class="p-inputgroup-addon" @click="searchTableVisible = true">
                <i class="pi pi-search"></i>
            </span>
        </div>
        <div v-else-if="dataSourceLookUp?.idControlType == ControlTypeConst.CTDOCUMENT" class="p-inputgroup flex-1">
            <InputText v-model="dataModelValue" :value="looKUpDescription" readonly
                :placeholder="showSearch ? 'Añadir un archivo...' : ''" />
            <span v-if="showClear && canDoAction(ObjectGroupConst.EDIT) && !disabled" class="p-input-icon-right"
                style="display: inline;">
                <i class="pi pi-times text-gray-500" style="font-size: 0.90rem" v-tooltip.top="'Borrar'"
                    @click="deleteDocument()" />
            </span>
            <span v-if="showSearch && !disabled" class="p-inputgroup-addon" @click="addDocument">
                <i class="pi pi-search"></i>
            </span>
        </div>
        <Dialog id="dialog" v-model:visible="searchTableVisible" modal header=" " maximizable draggable
            :style="{ width: dialogWidth + 'rem' }" :position="'top'"
            :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" @click.stop="" @show="setColSize"
            @maximize="changeActionValue('m')" @unmaximize="changeActionValue('u')">
            <template #header>
                <slot name="dialoghead"></slot>
            </template>
            <LookUpSearchTable :container="container" :dataSourceLookUp="dataSourceLookUp" :catalogsData="catalogsData"
                :filterConditions="filterConditions" @change:rowSelected="onRowSelected" :colSize="colSize">
            </LookUpSearchTable>
        </Dialog>


        <Dialog id="dialog" v-if="canDoAction(ObjectGroupConst.ADD) && !disabled && addNewDocument"
            v-model:visible="addNewDocument" modal header=" " maximizable draggable
            :style="{ width: dialogWidth + 'rem' }" :position="'top'"
            :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" @click.stop="" @show="setColSize"
            @maximize="changeActionValue('m')" @unmaximize="changeActionValue('u')">
            <template #header>
                <slot name="dialoghead"></slot>
            </template>
            <UploadFile :container="container" :Component="Component" :idObject="idObject" :objectId="objectId"
                :source="source" :documentId="documentId" :multiple="false"
                @document_added="searchLookUpData($event, true)" @document_modified="searchLookUpData($event, true)" />
        </Dialog>



        <span
            v-if="dataModelValue?.find(x => x !== undefined)?.value && (hasDetail || hasViewCommand || dataSourceLookUp?.idControlType == ControlTypeConst.CTDOCUMENT)"
            class="p-inputgroup-addon" @click="$emit('view:Details')">
            <i class="pi pi-eye"></i>
        </span>
        <span
            v-if="(isAddOnTheFly || hasSaveCommand) && !disabled && dataSourceLookUp?.idControlType !== ControlTypeConst.CTDOCUMENT"
            class="p-inputgroup-addon" @click="$emit('view:NewRegister')">
            <i class="pi pi-plus"></i>
        </span>
    </div>
</template>
<script lang="ts">

import { defineComponent, ref, onMounted, computed, watch, onUnmounted } from 'vue';
import { Container } from 'inversify';
import HelperLoading from '@ilinium/shared/src/common/infrastructure/funciones/HelperLoading';
import { TYPES } from '@ilinium/shared/src/common/domain/types';
import { IapDataSourceLookUp } from '../../../datasource/domain/iapDataSourceLookUp';
import { IServiceSearch } from '@ilinium/shared/src/entidades/builder/search/application/IServiceSearch';
import ControlTypeConst from '../../../form/domain/Constants/ControlTypeConst';
import { DropdownChangeEvent } from 'primevue/dropdown';
import DataSourceConst from '../functions/dataSourceConst';
import LookUpSearchTable from './LookUpSearchTable.vue';
import { GroupSearch, SearchData } from '../../../search/domain/search';
import { IapCatalog } from '../../../catalog/domain/iapCatalog';
import { Dictionary } from '../../../expression/domain/dictionary';
import HelperCommon from '@ilinium/shared/src/common/infrastructure/funciones/HelperCommon';
import HelperSecurity from '../../../../../common/infrastructure/funciones/HelperSecurity';
import ComponentDataForm from '../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../common/domain/constantes/ObjectGroupConst';
import OperatorLogicConst from '../../../search/domain/Const/OperatorLogicConst';
import { MessageService } from '../../../../../common/infrastructure/servicios';
import { MessageType } from '../../../../../common/infrastructure/servicios/MessageService';
import { IServiceDocument } from '../../../../builderDocument/document/application/IServiceDocument';
import DataBaseTypeConst from '../../../../../common/domain/constantes/DataBaseTypeConst';
import UploadFile from '../../../../builderDocument/document/infrastructure/component/UploadFile.vue';
import FiltroBusquedaConst from '../../../../../common/domain/constantes/FiltroBusquedaConst';
import HelperUtils from '../../../../../common/infrastructure/funciones/HelperUtils';

export default defineComponent({
    name: 'lookup_editor',
    components: {
        LookUpSearchTable,
        UploadFile
    },
    events: ['update:modelValue', 'change:SelectedLookUpData', 'view:Details', 'view:NewRegister'],
    props: {
        container: {
            type: Object as () => Container
        },
        modelValue: {
            default: () => ([])
        },
        controlWidth: {

            type: String,
            default: ('100%')
        },
        filterConditions: {
            type: Object as () => SearchData[],
            default: []
        },
        dataSourceLookUp: {
            type: Object as () => IapDataSourceLookUp,
            default: []
        },
        lookUpDesc: {
            type: String,
            default: ''
        },
        hasDetail: {
            type: Boolean,
            default: false
        },
        hasViewCommand: {
            type: Boolean,
            default: false
        },
        hasSaveCommand: {
            type: Boolean,
            default: false
        },
        isAddOnTheFly: {
            type: Boolean,
            default: false
        },
        showSearch: {
            type: Boolean,
            default: true
        },
        showClear: {
            type: Boolean,
            default: false
        },
        catalogsData: {
            type: Object as () => IapCatalog[],
            default: () => ([])
        },
        dialogWidth: {
            type: String,
            default: ('75')
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },

        disabled: {
            type: Boolean,
            default: false
        },

        rowData: {
            type: Object as () => any,
            default: () => (undefined)
        },

    },
    setup(props, { emit }) {

        const data = ref([])
        const searchTableVisible = ref(false);
        const addNewDocument = ref(false);
        const colSize = ref();
        const actionDialog = ref('');
        const store = useStore();
        const user = store.getters.getCurrentUser;

        const idObject = ref('');
        const objectId = ref(-1);
        const source = ref(DataBaseTypeConst.DOCUMENTOS_MODEL);
        const documentId = ref(-1);

        const dataModelValue = computed({
            get: () => props.modelValue,
            set: (val) => emit('update:modelValue', val),
        });

        const looKUpDescription = ref();

        watch(actionDialog, (value: any) => {
            setTimeout(function () {
                setColSize();
            }, 50);

        });

        const canDoAction = (action: string) => {
            return HelperSecurity.canDoOperation(user, props.Component.objectGroups, action)
        }




        const fillDataSource = (applicationId: number, applicationVersion: number, dataSourceLookUpId: number, customFields: SearchData[] = [], callbakOk: any = undefined) => {
            if (props.container && applicationId && applicationVersion && dataSourceLookUpId) {
                const _srv = props.container.get<IServiceSearch>(
                    TYPES.SEARCH_REPOSITORY
                );
                const searchData: GroupSearch = {
                    operatorLogic: OperatorLogicConst.AND,
                    fields: props.filterConditions,
                    children: []
                }

                if (customFields.length > 0) {
                    searchData.fields = searchData.fields.concat(customFields)
                }

                HelperLoading.showLoading();
                _srv.searchLookUp(applicationId, applicationVersion,
                    dataSourceLookUpId, searchData, props.dataSourceLookUp?.maxRowsReturned ?? 5)
                    .then((response) => {

                        if (response) {
                            data.value = setDataModelValue(JSON.parse(response?.items ?? '[]')) as any;
                            if (callbakOk) {
                                callbakOk({ data: JSON.parse(response?.items ?? '[]') })
                            }

                        }

                    })
                    .catch((err) => {

                    })
                    .finally(() => {

                        HelperLoading.hideLoading();
                    });

            }

        }


        const changeData = (event: DropdownChangeEvent) => {
            const eventData = data.value.find((x: any) => x[DataSourceConst.LOOKUPMODELVALUE] == event.value) as any;
            if (eventData) {
                emit('change:SelectedLookUpData', eventData);
            }

        }


        const onRowSelected = (event: any) => {

            dataModelValue.value = event.data[DataSourceConst.LOOKUPMODELVALUE];
            looKUpDescription.value = event.data.LookUpDescription;

            emit('change:SelectedLookUpData', event.data);
            searchTableVisible.value = false;

        };


        const addDocument = () => {
            if (props.rowData) {
                //@ts-ignore:disable-next-line
                const _keyDoc = (dataModelValue.value[0]['key'] ?? '')?.replaceAll('-', '')
                const cfg = JSON.parse(props.dataSourceLookUp.parameters ?? '[]') as Dictionary<string, string>[]

                documentId.value = dataModelValue.value[0]['value'] ?? undefined
                source.value = cfg.find(x => x.value == 'documentConnection')?.key ?? DataBaseTypeConst.DOCUMENTOS_MODEL

                const _objId = cfg.find(x => x.value == 'objectId')?.key
                if (_objId) {
                    //@ts-ignore:disable-next-line
                    objectId.value = props.rowData[_keyDoc + DataSourceConst.DELIMITERFIELDS + _objId?.split('.')?.at(-1)?.replaceAll('-', '')]
                }

                if (!objectId.value) {
                    const fieldMapsFiltered = props.dataSourceLookUp.iapDataSourceLookUpFieldMaps.filter((fm: any) => fm.isFieldLinked);
                    const keylk = fieldMapsFiltered.find(x => x.dataSourceFieldIdSource == _objId?.split('.').at(-1))?.dataSourceFieldIdTarget ?? ''
                    const dataKey = dataModelValue.value.find((x: any) => x.key == keylk);
                    if (dataKey) {
                        objectId.value = dataKey['value']
                    }

                }





                const _idObj = cfg.find(x => x.value == 'idObject')?.key
                if (_idObj) {
                    //@ts-ignore:disable-next-line
                    idObject.value = props.rowData[_keyDoc + DataSourceConst.DELIMITERFIELDS + _idObj?.split('.')?.at(-1)?.replaceAll('-', '')]
                }

                if (!idObject.value) {
                    const fieldMapsFiltered = props.dataSourceLookUp.iapDataSourceLookUpFieldMaps.filter((fm: any) => fm.isFieldLinked);
                    const keylk = fieldMapsFiltered.find(x => x.dataSourceFieldIdSource == _idObj?.split('.').at(-1))?.dataSourceFieldIdTarget ?? ''
                    const dataKey = dataModelValue.value.find((x: any) => x.key == keylk);
                    if (dataKey) {
                        idObject.value = dataKey['value']
                    }

                }



                addNewDocument.value = true;

            }

        }


        const getDataSearch = (event: any, documentSelected: boolean = false): Partial<SearchData>[] => {

            let filters: Partial<SearchData>[] = [];
            const fieldMapsFiltered = props.dataSourceLookUp.iapDataSourceLookUpFieldMaps.filter((fm: any) => fm.isFieldLinked);

            if (documentSelected) {


                const rowToFind: Partial<SearchData> = ({

                    //@ts-ignore:disable-next-line
                    id: HelperUtils.newGuid().toString().replaceAll('-', ''),
                    fieldId: fieldMapsFiltered.find(x => x.dataSourceFieldIdTarget == dataModelValue.value[0]['key'])?.dataSourceFieldIdSource ?? '',
                    filter: FiltroBusquedaConst.FILTROBUSQUEDA_IGUAL,
                    value: event.id.toString(),

                });


                filters.push(rowToFind);
            }
            else {
                dataModelValue.value.forEach((elm: any) => {
                    const rowToFind: Partial<SearchData> = ({

                        //@ts-ignore:disable-next-line
                        id: HelperUtils.newGuid().toString().replaceAll('-', ''),
                        fieldId: fieldMapsFiltered.find(x => x.dataSourceFieldIdTarget == elm.key)?.dataSourceFieldIdSource ?? '',
                        filter: FiltroBusquedaConst.FILTROBUSQUEDA_IGUAL,
                        value: elm.value?.toString(),

                    });

                    filters.push(rowToFind);
                })
            }
            return filters;
        }

        const searchLookUpData = (event: any, documentSelected: boolean = false) => {

            const rowToFind: Partial<SearchData>[] = getDataSearch(event, documentSelected);


            var doCallback = (response: any): void => {

                if (documentSelected) {
                    if (response?.data?.length > 0) {

                        const aux = setDataModelValue(response.data) as any
                        dataModelValue.value = aux[0][DataSourceConst.LOOKUPMODELVALUE];//response.data[0][DataSourceConst.LOOKUPMODELVALUE];
                        looKUpDescription.value = response.data[0].LookUpDescription;
                        emit('change:SelectedLookUpData', event.data);
                    }

                    idObject.value = '';
                    objectId.value = -1;
                    documentId.value = -1;
                    addNewDocument.value = false;

                }
                else {
                    if (response?.data?.length > 0) {
                        looKUpDescription.value = response.data[0].LookUpDescription;
                    }

                }

            }

            fillDataSource(props.dataSourceLookUp.dataSource.applicationId ?? 0, props.dataSourceLookUp?.dataSource.applicationVersion ?? 0, props.dataSourceLookUp.id, rowToFind as any, doCallback);


        }


        const setColSize = () => {

            const element = document.getElementById('dialog');
            let col: number = 12;

            if (element) {
                const elementWidth: number = element.offsetWidth;

                if (elementWidth > 641 && elementWidth <= 1007) {
                    col = 6;
                }
                else if (elementWidth > 1007 && elementWidth <= 1200) {
                    col = 4;
                }
                else if (elementWidth > 1200) {
                    col = 3;
                }

            }

            colSize.value = col;

        }

        const changeActionValue = (action: string) => {
            actionDialog.value = action;
        }


        const setDataModelValue = (data: any[]) => {

            const fieldMap = props.dataSourceLookUp.iapDataSourceLookUpFieldMaps?.filter(fm => fm.dataSourceFieldIdTarget != null && fm.isFieldLinked);


            data.forEach(obj => {

                let dic: Dictionary<String, String>[] = ([]);

                for (let prop in obj) {
                    if (prop.includes(DataSourceConst.LOOKUPID + DataSourceConst.DELIMITERFIELDS)) {

                        const dsfSourceId = prop.replace((DataSourceConst.LOOKUPID + DataSourceConst.DELIMITERFIELDS), '');
                        const dataSourceTargetId = fieldMap.find(fm => fm.dataSourceFieldIdSource == dsfSourceId)?.dataSourceFieldIdTarget;

                        if (dataSourceTargetId) {
                            dic.push({
                                key: dataSourceTargetId,
                                value: obj[prop]
                            } as any)
                        }

                        obj[DataSourceConst.LOOKUPMODELVALUE] = dic.sort((a, b) => { return (fieldMap.find(z => z.dataSourceFieldIdTarget == a.key && z.isFieldLinked)?.id ?? 0) - (fieldMap.find(z => z.dataSourceFieldIdTarget == b.key && z.isFieldLinked)?.id ?? 0); });
                    }

                }

            });

            return data ?? [];

        }


        const handleWindowResize = () => {
            setColSize();
        }

        const deleteDocument = () => {

            if (dataModelValue.value?.length > 0) {
                const docId = dataModelValue.value[0]['value']
                const cfg = JSON.parse(props.dataSourceLookUp.parameters ?? '[]') as Dictionary<string, string>[]
                const source = cfg.find(x => x.value == 'documentConnection')?.key ?? DataBaseTypeConst.DOCUMENTOS_MODEL
                var doCallback = (): void => {
                    if (props.container) {

                        const _srv = props.container.get<IServiceDocument>(TYPES.STORAGE_REPOSITORY)

                        HelperLoading.showLoading()

                        _srv.delete(docId, props.Component.id as any, source).then(response => {
                            if (response) {

                                MessageService.showToast(MessageType.Correcto, '', 'Se ha eliminado el documento correctamente.')
                                if (dataModelValue.value?.length > 0) {
                                    (dataModelValue.value[0] as any)['value'] = null
                                    looKUpDescription.value = ''
                                }


                            }
                        })
                            .finally(() => {
                                HelperLoading.hideLoading()
                            })

                    }
                }
                MessageService.showMessage(MessageType.Pregunta, '', 'Se va a proceder a eliminar el documento <b>' + looKUpDescription.value + ' </b>. <br/><b>¿Desea continuar?</b>', true, true, false, '', doCallback);
            }

        }



        onMounted(() => {

            looKUpDescription.value = props.lookUpDesc;
            if (props.dataSourceLookUp.idControlType !== ControlTypeConst.CTSEARCH && props.dataSourceLookUp.idControlType !== ControlTypeConst.CTDOCUMENT) {
                fillDataSource(props.dataSourceLookUp.dataSource.applicationId ?? 0, props.dataSourceLookUp?.dataSource.applicationVersion ?? 0, props.dataSourceLookUp.id);
            }

            watch(() => props.filterConditions, (newValue, oldValue) => {

                if (!HelperCommon.compareArrays(newValue, oldValue)) {

                    fillDataSource(props.dataSourceLookUp.dataSource.applicationId ?? 0, props.dataSourceLookUp?.dataSource.applicationVersion ?? 0, props.dataSourceLookUp.id);
                }


            }, { immediate: true });

            //metemos evento 
            window.addEventListener('resize', handleWindowResize);

            watch(() => dataModelValue.value, (newValue, oldValue) => {
                
                if (!newValue || newValue?.length==0 || !newValue?.some((x:any)=>x?.value)) {
                    looKUpDescription.value = '';
                }
                else {
                    if (props.dataSourceLookUp?.idControlType == ControlTypeConst.CTDROPDOWN) {
                        fillDataSource(props.dataSourceLookUp.dataSource.applicationId ?? 0, props.dataSourceLookUp?.dataSource.applicationVersion ?? 0, props.dataSourceLookUp.id);
                    }
                    if (props.dataSourceLookUp?.idControlType == ControlTypeConst.CTSEARCH && HelperCommon.isNullOrWhitespace(looKUpDescription.value)) {
                        searchLookUpData(undefined);
                    }
                }

            }, { immediate: true, deep: true });


            watch(() => props.lookUpDesc, (newValue, oldValue) => {

                if (newValue != oldValue) {
                    looKUpDescription.value = props.lookUpDesc;
                }
            }, { immediate: true, deep: true });


        })

        onUnmounted(() => {
            window.removeEventListener('resize', handleWindowResize);
        })



        return {
            ControlTypeConst,
            DataSourceConst,
            data,
            dataModelValue,
            changeData,
            searchTableVisible,
            onRowSelected,
            looKUpDescription,
            setColSize,
            colSize,
            changeActionValue,
            canDoAction,
            ObjectGroupConst,
            addNewDocument,
            deleteDocument,
            idObject,
            objectId,
            source,
            documentId,
            addDocument,
            searchLookUpData

        };
    },
});
</script>
<style scoped></style>
