<template>

    <span>Origen </span>
    <select @pointerdown.stop="" @dblclick.stop="" name="dslist" v-model="variableInception"
        style="border: 1px solid #999;border-radius: 30px;    font-size: 110%;" @change="changeInception()"
        class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full ">
        <option value="-1">Selecciona un valor</option>
        <option :value="0">Componentes actuales</option>
        <option :value="1">Otros</option>
    </select>
    <br />
    <div v-if="variableInception == 0">
        <span>Origen de Datos </span>
        <select @pointerdown.stop="" @dblclick.stop="" name="dslistIdsOrg" v-model="variableComponentDataSourceListId" multiple
            style="font-size: 110%;"
            @change="changeComponentDataSources()"
            class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full ">            
            <option v-for="(ds, index) in dataSources" v-bind:value="ds.id" :key="index"
                :title="ds.name + getComponentDescription(ds.componentId)">
                {{ ds.name + getComponentDescription(ds.componentId) }}

            </option>
        </select>
    </div>
    <div v-else-if="variableInception == 1">
        <span>Conexión </span>
        <select @pointerdown.stop="" @dblclick.stop="" name="dslistConn" v-model="variableConnection"
            style="border: 1px solid #999;border-radius: 30px;    font-size: 110%;" @change="changeConnection()"
            class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full ">
            <option value="">Selecciona una conexión</option>
            <option v-for="(ds, index) in connectionData" v-bind:value="ds.id" :key="index">{{ ds.description }}

            </option>
            <option :value="CatalogDataSourceType.SERVICE">Servicios Webs / Rest Apis</option>
        </select>


        <div v-if="variableConnection">
            <br />
            <span>Origen de Datos</span>
            <select @pointerdown.stop="" @dblclick.stop="" name="dslistproc" v-model="variableDsId"
                style="border: 1px solid #999;border-radius: 30px;    font-size: 110%;" @change="changeDataSources()"
                class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full ">
                <option value="">Selecciona un valor</option>
                <option v-for="(ds, index) in getDataSourcesByTypeConn()" v-bind:value="ds.id" :key="index">{{ ds.name
                    }}

                </option>
            </select>



            <div v-if="variableDsId" @pointerdown.stop="" @dblclick.stop=""
                style="overflow-y:auto; overflow-x:auto; height:200px;">
                <br />


                <span>Campos</span>

                <table class="tableParameters">
                    <thead>
                        <tr>
                            <th v-for="(column, index) in [' ', 'Nombre', 'Valor', '']" :key="index"> {{ column
                                }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in variableFieldsVisible" :key="index">
                            <td>
                                <input v-if="!isDataSourceService && (variableOperation == 1 || variableOperation == 3)" @pointerdown.stop=""
                                    @dblclick.stop="" type="checkbox" v-model="item.checked"
                                    @change="changeDataSourceFieldValue()"
                                    class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full " />
                            </td>
                            <td>{{ getdataSourceFieldDescription(item.key) }}</td>
                            <td><textarea  @pointerdown.stop="" @dblclick.stop="" rows="2" cols="50"
                                    @change="changeDataSourceFieldValue()" v-model="item['value']"
                                    class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full " />

                            </td>
                            <td><i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" class="fa fa-subscript"
                                    @click="showExpression(DataSourceNodeConst.VAR_FIELD_IN, getdataSourceFieldDescription(item.key), item)"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- Configuracion de los origenes de datos de los servicios-->
                <div v-if="hasDataSourceConfigurations && variableCfgs.length>0">
                    <br />


                    <span>Configuración</span>

                    <table class="tableParameters">
                        <thead>
                            <tr>
                                <th v-for="(column, index) in ['Clave', 'Valor', '']" :key="index"> {{ column }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in variableCfgs" :key="index">

                                <td>{{ getdataSourceconfigurationdDescription(item.key) }}</td>
                                <td><input @pointerdown.stop="" @dblclick.stop="" type="text"
                                        @change="changeDataSourceConfigValue()" v-model="item['value']"
                                        class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full " />

                                </td>
                                <td><i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" class="fa fa-subscript"
                                        @click="showExpression(DataSourceNodeConst.VAR_CFG_IN, getdataSourceconfigurationdDescription(item.key) ?? '', item)"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


        </div>

    </div>
    <div v-if="(variableComponentDataSourceId > 0 || variableComponentDataSourceListId.length > 0) || (variableDsId )">
        <br />
        <span>Operación </span>
        <select @pointerdown.stop="" @dblclick.stop="" name="dslistOp" v-model="variableOperation"
            :disabled="isDataSourceService"
            style="border: 1px solid #999;border-radius: 30px;    font-size: 110%;" @change="changeOperation()"
            class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full ">
            <option value="-1">Selecciona una operación</option>
            <option v-for="(ds, index) in getOperationsDataSource" v-bind:value="ds.id" :key="index">{{
        ds.description
    }}

            </option>

        </select>

    </div>
</template>

<script lang="ts">

import { computed, defineComponent, onMounted, ref } from 'vue';
import { IapComponentDataSource } from '../../../../../component/domain/iapComponentDataSource';

import DataSourceNodeConst from '../constants/DataSourceNodeConst';
import helperCatalog from '../../../../../catalog/infrastructure/helper/helperCatalog';
import HelperCustomComponentControls from '../../../functions/helperCustomComponentControls';
import CatalogTypeConst from '../../../../../catalog/domain/const/CatalogTypeConst';
import { IapDataSource } from '../../../../../datasource/domain/iapDataSource';
import { Container } from 'inversify';
import { IServiceDataSource } from '../../../../../datasource/application/IServiceDataSource';
import { TYPES } from '../../../../../../../common/domain/types';
import HelperLoading from '../../../../../../../common/infrastructure/funciones/HelperLoading';
import CatalogDataSourceType from '../../../../../catalog/domain/const/CatalogDataSourceType';
import DataModelInput from '../../../../domain/dataModelInput';
import { IapDataSourceField } from '../../../../../datasource/domain/iapDataSourceField';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';


export default defineComponent({
    name: 'customdatasourcecontrol',
    components: {

    },
    props: {
        data: Object
    },
    setup(props) {


        const variableInception = ref();
        const variableOperation = ref();
        const variableComponentId = ref();
        const variableComponentDataSourceId = ref();
        const variableComponentDataSourceListId = ref<Number[]>([]);
        const variableConnection = ref();
        const variableDsId = ref();
        const variableFields = ref<DataModelInput[]>([]);
        const variableCfgs = ref<DataModelInput[]>([]);
        let appDataSources= ref<IapDataSource[]>([])
        const operationsDataSource = [
            {
                id: 0,
                description: 'Insert'
            },
            {
                id: 1,
                description: 'Update'
            },
            {
                id: 2,
                description: 'Delete'
            },
            {
                id: 3,
                description: 'Buscar'
            },
            {
                id: 4,
                description: 'Refresh'
            },
           /* {
                id: 5,
                description: 'Reload'
            },
            */
        ]

        const datacatalog = helperCatalog.getAllCatalogApp();
        const { showExpression } = HelperCustomComponentControls(props.data);

        const connectionData = computed(() => {
            return datacatalog.filter(x => x.id == CatalogTypeConst.TIPOBD)?.find(x => x !== undefined)?.iapCatalogs.sort((a, b) => { return (a.order ?? 0) - (b.order ?? 0); });
        })


        const dataSources = computed(() => {

            if (props.data) {
                return props.data.dataSources as IapComponentDataSource[];
            }
            return []
        })


        const hasDataSourceConfigurations = computed(() => {
            const dsData = appDataSources.value.find(x => x.id == variableDsId.value)
            return (dsData?.idDataSourceType == CatalogDataSourceType.SERVICE && dsData?.iapDataSourceServiceConfigurations)


        })

        const isDataSourceService = computed(() => {
            const dsData = appDataSources.value.find(x => x.id == variableDsId.value)
            return (dsData?.idDataSourceType == CatalogDataSourceType.SERVICE)


        })

        const getOperationsDataSource = computed(() => {
            if (variableInception.value == 1) {
                return operationsDataSource.filter(x => x.id < 4)
            }
            else {
                return operationsDataSource
            }

        })


        const  variableFieldsVisible = computed(() =>{            
            const data = getFieldsByOperation() ?? [];
            return variableFields.value.filter(x=> !data.includes(x.key));
        })




        const getFieldsByOperation = () => {
            if (props.data) {

            
                const dsData = appDataSources.value.find(x => x.id == variableDsId.value)
                if (variableDsId.value && dsData) {
                    if (isDataSourceService.value) {

                        var requestInput = dsData.iapDataSourceTableAliases.filter(x => x.isInput).map(x => x.id)                        
                            return  dsData.iapDataSourceFields.sort((a, b) => { return (a.position ?? 0) - (b.position ?? 0); })
                            .filter(x => requestInput.includes(x.dataSourceTableAliasId ?? -1)
                            && ( ((variableOperation.value == 0 && x.primaryKey)                            
                            || (variableOperation.value == 1 || variableOperation.value == 2)  
                            )                          
                             ||  x.computed
                             ||  x.identityColumn)
                            ).map(x =>x.id)

                    }
                    else {

                        return dsData.iapDataSourceFields.sort((a, b) => { return (a.position ?? 0) - (b.position ?? 0); }).filter(
                            x=> 
                            ((variableOperation.value == 0 && (x.primaryKey ||  x.computed
                             ||  x.identityColumn))                            
                            || (variableOperation.value == 1  && (  x.computed
                             ||  x.identityColumn))
                            || (variableOperation.value == 2 && !x.primaryKey  )  
                            )                          
                             
                        ).map(x => x.id )

                    }
                   
                }
                else {
                    return [];
                }


            }
        }



        const getDataSourcesByTypeConn = () => {
            if (variableConnection.value == CatalogDataSourceType.SERVICE) {
                return appDataSources.value.filter(x => x.idDataSourceType == CatalogDataSourceType.SERVICE)
            }
            else {
                return appDataSources.value.filter(x => x.iapDataSourceDataBase?.idBaseDatos == variableConnection.value)
            }

        }

        const getdataSourceFieldAdditional = (field: IapDataSourceField) => {
            let aux = ''
            if (field.primaryKey) {
                aux += ' PK '
            }
            if (field.identityColumn) {
                aux += ' I '
            }
            if (field.nullable) {
                aux += ' null '
            }
            if (aux) {
                aux = '(' + aux + ')'
            }
            return aux;
        }

        const getdataSourceFieldDescription = (fieldId: string) => {
            const dsData = appDataSources.value.find(x => x.id == variableDsId.value)

            if (dsData) {
                const field = dsData.iapDataSourceFields.find(x => x.id == fieldId)
                if (field) {
                    //return (field.tableNameAlias ?? field.tableName) + '.' + (field.fieldAlias ?? field.field)
                    if (isDataSourceService.value) {
                        return field.field
                    }
                    else {
                        return (!HelperCommon.isNullOrWhitespace(field.fieldAlias ?? '') ? field.fieldAlias : field.field) + getdataSourceFieldAdditional(field)
                    }
                }
            }

            return fieldId;


        }

        const getdataSourceconfigurationdDescription = (cfgId: string) => {
            const dsData = appDataSources.value.find(x => x.id == variableDsId.value)

            if (dsData && cfgId) {
                return dsData.iapDataSourceServiceConfigurations.find(x => x.id.toString() == cfgId)?.key
            }

            return cfgId;


        }


        const changeInception = () => {
            if (props.data) {


                variableOperation.value = 0;//props.data[DataSourceNodeConst.VAR_DS_OPERATION]
                variableComponentId.value = -1; // props.data[DataSourceNodeConst.VAR_COMPONENTID_IN]
                variableComponentDataSourceId.value = -1; // props.data[DataSourceNodeConst.VAR_COMPONENTDATASOURCEID_IN]
                variableComponentDataSourceListId.value = [];
                variableConnection.value = ''; // props.data[DataSourceNodeConst.VAR_CONN_IN]
                variableDsId.value = '';  //props.data[DataSourceNodeConst.VAR_DS_IN]
                variableFields.value = []; // props.data[DataSourceNodeConst.VAR_FIELD_IN]
                variableCfgs.value = []; // props.data[DataSourceNodeConst.VAR_CFG_IN]
                props.data.onChange({ key: DataSourceNodeConst.VAR_INCEPTION_IN, data: variableInception.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_DS_OPERATION, data: variableOperation.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_COMPONENTID_IN, data: variableComponentId.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_COMPONENTDATASOURCEID_IN, data: variableComponentDataSourceId.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_COMPONENTDATASOURCELISTID_IN, data: variableComponentDataSourceListId.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_CONN_IN, data: variableConnection.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_DS_IN, data: variableDsId.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_FIELD_IN, data: variableFields.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_CFG_IN, data: variableCfgs.value })

            }
        }


        const changeComponentDataSources = () => {
            if (props.data) {

                const ds = dataSources.value.find(x => x != undefined);

                variableComponentId.value = ds?.componentId ?? -1; // props.data[DataSourceNodeConst.VAR_COMPONENTID_IN]
                variableConnection.value = ''; // props.data[DataSourceNodeConst.VAR_CONN_IN]
                variableDsId.value = '';  //props.data[DataSourceNodeConst.VAR_DS_IN]
                variableFields.value = []; // props.data[DataSourceNodeConst.VAR_FIELD_IN]
                variableCfgs.value = []; // props.data[DataSourceNodeConst.VAR_CFG_IN]
                variableComponentDataSourceId.value = -1

                props.data.onChange({ key: DataSourceNodeConst.VAR_COMPONENTID_IN, data: variableComponentId.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_COMPONENTDATASOURCEID_IN, data: variableComponentDataSourceId.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_COMPONENTDATASOURCELISTID_IN, data: variableComponentDataSourceListId.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_CONN_IN, data: variableConnection.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_DS_IN, data: variableDsId.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_FIELD_IN, data: variableFields.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_CFG_IN, data: variableCfgs.value })

            }
        }


        const changeDataSources = () => {
            if (props.data) {

                //const ds = dataSources.value.find(x => x.id == variableComponentDataSourceId.value);

                //variableComponentId.value = ds?.componentId ?? -1; // props.data[DataSourceNodeConst.VAR_COMPONENTID_IN]
                //variableConnection.value = ''; // props.data[DataSourceNodeConst.VAR_CONN_IN]
                //variableDsId.value = '';  //props.data[DataSourceNodeConst.VAR_DS_IN]
                const dsData = appDataSources.value.find(x => x.id == variableDsId.value)
                if (variableDsId.value && dsData) {
                    if (isDataSourceService.value) {

                        var requestInput = dsData.iapDataSourceTableAliases.filter(x => x.isInput).map(x => x.id)                        
                            variableFields.value = dsData.iapDataSourceFields.sort((a, b) => { return (a.position ?? 0) - (b.position ?? 0); }).filter(x => requestInput.includes(x.dataSourceTableAliasId ?? -1)).map(x => ({
                                checked: false,
                                key: x.id,
                                value: ''
                            }))

                    }
                    else {

                        variableFields.value = dsData.iapDataSourceFields.sort((a, b) => { return (a.position ?? 0) - (b.position ?? 0); }).map(x => ({
                            checked: false,
                            key: x.id,
                            value: ''
                        }))

                    }

                    variableCfgs.value = dsData.iapDataSourceServiceConfigurations.map(x => ({
                        checked: false,
                        key: x.id.toString(),
                        value: x.value ?? ''
                    }))
                }
                else {
                    variableFields.value = []; // props.data[DataSourceNodeConst.VAR_FIELD_IN]
                    variableCfgs.value = []; // props.data[DataSourceNodeConst.VAR_CFG_IN]
                }


                //props.data.onChange({ key: DataSourceNodeConst.VAR_COMPONENTID_IN, data: variableComponentId.value })
                //props.data.onChange({ key: DataSourceNodeConst.VAR_COMPONENTDATASOURCEID_IN, data: variableComponentDataSourceId.value })
                //props.data.onChange({ key: DataSourceNodeConst.VAR_CONN_IN, data: variableConnection.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_DS_IN, data: variableDsId.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_FIELD_IN, data: variableFields.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_CFG_IN, data: variableCfgs.value })

            }
        }


        const checkChangeDataSources = () => {
            if (props.data) {

                //const ds = dataSources.value.find(x => x.id == variableComponentDataSourceId.value);

                //variableComponentId.value = ds?.componentId ?? -1; // props.data[DataSourceNodeConst.VAR_COMPONENTID_IN]
                //variableConnection.value = ''; // props.data[DataSourceNodeConst.VAR_CONN_IN]
                //variableDsId.value = '';  //props.data[DataSourceNodeConst.VAR_DS_IN]
                const dsData = appDataSources.value.find(x => x.id == variableDsId.value)
                if (variableDsId.value && dsData) {
                    if (isDataSourceService.value) {

                        var requestInput = dsData.iapDataSourceTableAliases.filter(x => x.isInput).map(x => x.id)                        
                        variableFields.value  = dsData.iapDataSourceFields.sort((a, b) => { return (a.position ?? 0) - (b.position ?? 0); }).filter(x => requestInput.includes(x.dataSourceTableAliasId ?? -1)).map(x => ({
                                checked: variableFields.value.find(v => v.key == x.id)?.checked ?? false,
                                key: x.id,
                                value: variableFields.value.find(v => v.key == x.id)?.value ?? ''
                            }))

                            

                    }
                    else {

                        variableFields.value = dsData.iapDataSourceFields.sort((a, b) => { return (a.position ?? 0) - (b.position ?? 0); }).map(x => ({
                            checked: variableFields.value.find(v => v.key == x.id)?.checked ?? false,
                            key: x.id,
                            value: variableFields.value.find(v => v.key == x.id)?.value ?? ''
                        }))

                    }

                    variableCfgs.value = dsData.iapDataSourceServiceConfigurations.map(x => ({
                        checked: variableCfgs.value.find(v => v.key == x.id.toString())?.checked ?? false,
                        key: x.id.toString(),
                        value: variableCfgs.value.find(v => v.key == x.id.toString())?.value ?? ''
                    }))
                }
                else {
                    variableFields.value = []; // props.data[DataSourceNodeConst.VAR_FIELD_IN]
                    variableCfgs.value = []; // props.data[DataSourceNodeConst.VAR_CFG_IN]
                }


                //props.data.onChange({ key: DataSourceNodeConst.VAR_COMPONENTID_IN, data: variableComponentId.value })
                //props.data.onChange({ key: DataSourceNodeConst.VAR_COMPONENTDATASOURCEID_IN, data: variableComponentDataSourceId.value })
                //props.data.onChange({ key: DataSourceNodeConst.VAR_CONN_IN, data: variableConnection.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_DS_IN, data: variableDsId.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_FIELD_IN, data: variableFields.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_CFG_IN, data: variableCfgs.value })

            }
        }


        const changeDataSourceFieldValue = () => {
            if (props.data) {

                //const ds = dataSources.value.find(x => x.id == variableComponentDataSourceId.value);

                //variableComponentId.value = ds?.componentId ?? -1; // props.data[DataSourceNodeConst.VAR_COMPONENTID_IN]
                //variableConnection.value = ''; // props.data[DataSourceNodeConst.VAR_CONN_IN]
                //variableDsId.value = '';  //props.data[DataSourceNodeConst.VAR_DS_IN]



                //props.data.onChange({ key: DataSourceNodeConst.VAR_COMPONENTID_IN, data: variableComponentId.value })
                //props.data.onChange({ key: DataSourceNodeConst.VAR_COMPONENTDATASOURCEID_IN, data: variableComponentDataSourceId.value })
                //props.data.onChange({ key: DataSourceNodeConst.VAR_CONN_IN, data: variableConnection.value })
                //props.data.onChange({ key: DataSourceNodeConst.VAR_DS_IN, data: variableDsId.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_FIELD_IN, data: variableFields.value })
                //props.data.onChange({ key: DataSourceNodeConst.VAR_CFG_IN, data: variableCfgs.value })

            }
        }


        const changeDataSourceConfigValue = () => {
            if (props.data) {

                //const ds = dataSources.value.find(x => x.id == variableComponentDataSourceId.value);

                //variableComponentId.value = ds?.componentId ?? -1; // props.data[DataSourceNodeConst.VAR_COMPONENTID_IN]
                //variableConnection.value = ''; // props.data[DataSourceNodeConst.VAR_CONN_IN]
                //variableDsId.value = '';  //props.data[DataSourceNodeConst.VAR_DS_IN]



                //props.data.onChange({ key: DataSourceNodeConst.VAR_COMPONENTID_IN, data: variableComponentId.value })
                //props.data.onChange({ key: DataSourceNodeConst.VAR_COMPONENTDATASOURCEID_IN, data: variableComponentDataSourceId.value })
                //props.data.onChange({ key: DataSourceNodeConst.VAR_CONN_IN, data: variableConnection.value })
                //props.data.onChange({ key: DataSourceNodeConst.VAR_DS_IN, data: variableDsId.value })
                //props.data.onChange({ key: DataSourceNodeConst.VAR_FIELD_IN, data: variableFields.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_CFG_IN, data: variableCfgs.value })

            }
        }

        const changeConnection = () => {
            if (props.data) {


                //variableOperation.value = 0;//props.data[DataSourceNodeConst.VAR_DS_OPERATION]
                //variableComponentId.value = -1; // props.data[DataSourceNodeConst.VAR_COMPONENTID_IN]
                //variableComponentDataSourceId.value = -1; // props.data[DataSourceNodeConst.VAR_COMPONENTDATASOURCEID_IN]
                //variableConnection.value =''; // props.data[DataSourceNodeConst.VAR_CONN_IN]
                variableDsId.value = '';  //props.data[DataSourceNodeConst.VAR_DS_IN]
                variableFields.value = []; // props.data[DataSourceNodeConst.VAR_FIELD_IN]
                variableCfgs.value = []; // props.data[DataSourceNodeConst.VAR_CFG_IN]
                //props.data.onChange({ key: DataSourceNodeConst.VAR_INCEPTION_IN, data: variableInception.value })
                //props.data.onChange({ key: DataSourceNodeConst.VAR_DS_OPERATION, data: variableOperation.value })
                //props.data.onChange({ key: DataSourceNodeConst.VAR_COMPONENTID_IN, data: variableComponentId.value })
                //props.data.onChange({ key: DataSourceNodeConst.VAR_COMPONENTDATASOURCEID_IN, data: variableComponentDataSourceId.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_CONN_IN, data: variableConnection.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_DS_IN, data: variableDsId.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_FIELD_IN, data: variableFields.value })
                props.data.onChange({ key: DataSourceNodeConst.VAR_CFG_IN, data: variableCfgs.value })

            }
        }

        const changeOperation = () => {

            if (props.data) {

                props.data.onChange({ key: DataSourceNodeConst.VAR_DS_OPERATION, data: variableOperation.value })


            }
        }



        const getComponentDescription = (componentId: number) => {

            if (props.data) {
                const comp = props.data?.componentDataInput?.find(x => x.id == componentId)
                if (comp) {
                    return ' (' + comp?.name + ')';
                }
            }
            return ''
        }



        const getAppDataSources = () => {
            
            if (props.data && props.data.container) {

                const _srv = (props.data.container as Container).get<IServiceDataSource>(TYPES.DATASOURCE_REPOSITORY)
                HelperLoading.showLoading()

                _srv.getAll(props.data.applicationId ?? -1, props.data.applicationVersion ?? -1,).then(response => {
                    appDataSources.value = response;
                    checkChangeDataSources();
                })
                    .finally(() => {
                        HelperLoading.hideLoading()

                    })

            }
            else {
                appDataSources.value = [];

            }


        };


        

        onMounted(() => {

            

            if (props.data) {
                variableInception.value = props.data[DataSourceNodeConst.VAR_INCEPTION_IN]
                variableOperation.value = props.data[DataSourceNodeConst.VAR_DS_OPERATION]
                variableComponentId.value = props.data[DataSourceNodeConst.VAR_COMPONENTID_IN]
                variableComponentDataSourceId.value = props.data[DataSourceNodeConst.VAR_COMPONENTDATASOURCEID_IN]
                variableComponentDataSourceListId.value = props.data[DataSourceNodeConst.VAR_COMPONENTDATASOURCELISTID_IN]
                variableConnection.value = props.data[DataSourceNodeConst.VAR_CONN_IN]
                variableDsId.value = props.data[DataSourceNodeConst.VAR_DS_IN]
                variableFields.value = props.data[DataSourceNodeConst.VAR_FIELD_IN]
                variableCfgs.value = props.data[DataSourceNodeConst.VAR_CFG_IN]

                getAppDataSources();
         
            }





        })

        return {
            variableInception,
            variableOperation,
            variableComponentId,
            variableComponentDataSourceId,
            variableComponentDataSourceListId,
            variableConnection,
            variableDsId,
            variableFields,
            variableCfgs,
            dataSources,
            connectionData,
            changeInception,
            changeConnection,
            changeComponentDataSources,
            changeDataSources,
            getComponentDescription,
            getDataSourcesByTypeConn,
            DataSourceNodeConst,
            CatalogDataSourceType,
            showExpression,
            getdataSourceFieldDescription,
            changeDataSourceFieldValue,
            changeDataSourceConfigValue,
            hasDataSourceConfigurations,
            getdataSourceconfigurationdDescription,
            changeOperation,
            isDataSourceService,
            getOperationsDataSource,
            appDataSources,
            variableFieldsVisible
        };
    },
});
</script>

<style scoped></style>
