export const TYPES = {
  AUTH_REPOSITORY: Symbol.for('AUTH_REPO'),
  AUTH_SERVICE: Symbol.for('AUTH'),
  AUTH_APP: Symbol.for('APP'),
  USER_REPOSITORY: Symbol.for('USER_REPO'),
  USER_SERVICE: Symbol.for('USER'),
  APPLICATION_REPOSITORY: Symbol.for('APPLICATION_REPO'),
  APPLICATION_SERVICE: Symbol.for('APPLICATION_SERVICE'),
  APPLICATIONCONNECTION_REPOSITORY: Symbol.for('APPLICATIONCONECTION_REPO'),
  APPLICATIONCONNECTION_SERVICE: Symbol.for('APPLICATIONCONNECTION_SERVICE'),
  APPLICATIONNOTE_REPOSITORY: Symbol.for('APPLICATIONNOTE_REPO'),
  APPLICATIONNOTE_SERVICE: Symbol.for('APPLICATIONNOTE_SERVICE'),
  APPLICATIONCONFIGURATION_REPOSITORY: Symbol.for('APPLICATIONCONFIGURATION_REPO'),
  APPLICATIONCONFIGURATION_SERVICE: Symbol.for('APPLICATIONCONFIGURATION_SERVICE'),
  DATASOURCE_REPOSITORY: Symbol.for('DATASOURCE_REPO'),
  DATASOURCE_SERVICE: Symbol.for('DATASOURCE_SERVICE'),
  DATASOURCEFIELD_REPOSITORY: Symbol.for('DATASOURCEFIELD_REPO'),
  DATASOURCEFIELD_SERVICE: Symbol.for('DATASOURCEFIELD_SERVICE'),
  DATASOURCE_ALIAS_REPOSITORY: Symbol.for('DATASOURCE_ALIAS_REPO'),
  DATASOURCE_ALIAS_SERVICE: Symbol.for('DATASOURCE_ALIAS_SERVICE'),
  DOBLE_FACTOR_USER_REPOSITORY: Symbol.for('DOBLEFACTOR_REPO'),
  DOBLE_FACTOR_USER_SERVICE: Symbol.for('DOBLEFACTOR_SERVICE'),


  DATASOURCEDATABASE_REPOSITORY: Symbol.for('DATASOURCEDATABASE_REPO'),
  DATASOURCEDATABASE_SERVICE: Symbol.for('DATASOURCEDATABASE_SERVICE'),

  DATASOURCELOOKUP_REPOSITORY: Symbol.for('DATASOURCELOOKUP_REPO'),
  DATASOURCELOOKUP_SERVICE: Symbol.for('DATASOURCELOOKUP_SERVICE'),

  DATASOURCELOOKUPFIELDMAP_REPOSITORY: Symbol.for('DATASOURCELOOKUPFIELDMAP_REPO'),
  DATASOURCELOOKUPFIELDMAP_SERVICE: Symbol.for('DATASOURCELOOKUPFIELDMAP_SERVICE'),
  
  DATASOURCESERVICE_REPOSITORY: Symbol.for('DATASOURCESERVICE_REPO'),
  DATASOURCESERVICE_SERVICE: Symbol.for('DATASOURCESERVICE_SERVICE'),

  DATASOURCESERVICECONFIGURATION_REPOSITORY: Symbol.for('DATASOURCESERVICECONFIGURATION_REPO'),
  DATASOURCESERVICECONFIGURATION_SERVICE: Symbol.for('DATASOURCESERVICECONFIGURATION_SERVICE'),

  DATASOURCESERVICECONFIGURATIONTEMPLATE_REPOSITORY: Symbol.for('DATASOURCESERVICECONFIGURATIONTEMPLATE_REPO'),
  DATASOURCESERVICECONFIGURATIONTEMPLATE_SERVICE: Symbol.for('DATASOURCESERVICECONFIGURATIONTEMPLATE_SERVICE'),
  OBJECTDATASOURCEFIELDCONFIGURATION_REPOSITORY: Symbol.for('OBJECTDATASOURCEFIELDCONFIGURATION_REPO'),
  OBJECTDATASOURCEFIELDCONFIGURATION_SERVICE: Symbol.for('OBJECTDATASOURCEFIELDCONFIGURATION_SERVICE'),

  OBJECTDATASOURCEFIELDRELATION_REPOSITORY: Symbol.for('OBJECTDATASOURCEFIELDRELATION_REPO'),
  OBJECTDATASOURCEFIELDRELATION_SERVICE: Symbol.for('OBJECTDATASOURCEFIELDRELATION_SERVICE'),

  COMPONENT_REPOSITORY: Symbol.for('COMPONENT_REPO'),
  COMPONENT_SERVICE: Symbol.for('COMPONENT_SERVICE'),
  COMPONENT_PROPERTY_REPOSITORY: Symbol.for('COMPONENT_PROPERTY_REPO'),
  COMPONENT_PROPERTY_SERVICE: Symbol.for('COMPONENT_PROPERTY_SERVICE'),
  HELP_REPOSITORY: Symbol.for('HELP_REPO'),
  HELP_SERVICE: Symbol.for('HELP_SERVICE'),
  COMPONENT_PARENT_REPOSITORY: Symbol.for('COMPONENT_PARENT_REPO'),
  COMPONENT_PARENT_SERVICE: Symbol.for('COMPONENT_PARENT_SERVICE'),
  COMPONENT_EVENT_REPOSITORY: Symbol.for('COMPONENT_EVENT_REPO'),
  COMPONENT_EVENT_SERVICE: Symbol.for('COMPONENT_EVENT_SERVICE'),

  CODE_REPOSITORY: Symbol.for('CODE_REPO'),
  CODE_SERVICE: Symbol.for('CODE_SERVICE'),

  COMPONENT_DATASOURCE_REPOSITORY: Symbol.for('COMPONENT_DATASOURCE_REPO'),
  COMPONENT_DATASOURCE_SERVICE: Symbol.for('COMPONENT_DATASOURCE_SERVICE'),
  COMPONENT_DATASOURCE_FIELD_REPOSITORY: Symbol.for('COMPONENT_DATASOURCE_FIELD_REPO'),
  COMPONENT_DATASOURCE_FIELD_SERVICE: Symbol.for('COMPONENT_DATASOURCE_FIELD_SERVICE'),
  DATASOURCE_MIGRATION_REPOSITORY: Symbol.for('DATASOURCE_MIGRATION_REPO'),
  DATASOURCE_MIGRATION_SERVICE: Symbol.for('DATASOURCE_MIGRATION_SERVICE'),
  DATASOURCE_MIGRATION_LOG_REPOSITORY: Symbol.for('DATASOURCE_MIGRATION_LOG_REPO'),
  DATASOURCE_MIGRATION_LOG_SERVICE: Symbol.for('DATASOURCE_MIGRATION_LOG_SERVICE'),
  COMPONENT_DATASOURCE_FIELD_CONF_REPOSITORY: Symbol.for('COMPONENT_DATASOURCE_FIELD_CONF_REPO'),
  COMPONENT_DATASOURCE_FIELD_CONF_SERVICE: Symbol.for('COMPONENT_DATASOURCE_FIELD_CONF_SERVICE'),
  COMPONENT_DATASOURCE_SERVICE_CONF_REPOSITORY: Symbol.for('COMPONENT_DATASOURCE_SERVICE_CONF_REPO'),
  COMPONENT_DATASOURCE_SERVICE_CONF_SERVICE: Symbol.for('COMPONENT_DATASOURCE_SERVICE_CONF_SERVICE'),

  COMPONENT_DATASOURCE_FIELD_FILTER_REPOSITORY: Symbol.for('COMPONENT_DATASOURCE_FIELD_FILTER_REPO'),
  COMPONENT_DATASOURCE_FIELD_FILTER_SERVICE: Symbol.for('COMPONENT_DATASOURCE_FIELD_FILTER_SERVICE'),

  FIELD_CONF_USR_REPOSITORY: Symbol.for('FIELD_CONF_USR_REPO'),
  FIELD_CONF_USR_SERVICE: Symbol.for('FIELD_CONF_USR_SERVICE'),

  SEARCH_USR_REPOSITORY: Symbol.for('SEARCH_USR_REPO'),
  SEARCH_USR_SERVICE: Symbol.for('SEARCH_USR_SERVICE'),

  USR_REPOSITORY: Symbol.for('USR_REPO'),
  USR_SERVICE: Symbol.for('USR_SERVICE'),

  USER_OPTION_REPOSITORY: Symbol.for('USER_OPTION_REPOSITORY'),
  USER_OPTION_SERVICE: Symbol.for('USER_OPTION_SERVICE'),

  MENU_REPOSITORY: Symbol.for('MENU_REPOSITORY'),
  MENU_SERVICE: Symbol.for('MENU_SERVICE'),
  OBJECTGROUP_DIRECTIVE_REPOSITORY: Symbol.for('OBJECTGROUP_DIRECTIVE_REPOSITORY'),
  OBJECTGROUP_DIRECTIVE_SERVICE: Symbol.for('OBJECTGROUP_DIRECTIVE_SERVICE'),
  COMPONENT_ATTRIBUTE_REPOSITORY: Symbol.for('COMPONENT_ATTRIBUTE_REPO'),
  COMPONENT_ATTRIBUTE_SERVICE: Symbol.for('COMPONENT_ATTRIBUTE_SERVICE'),
  COMPONENT_ATTRIBUTE_TEMPLATE_REPOSITORY: Symbol.for('COMPONENT_ATTRIBUTE_TEMPLATE_REPO'),
  COMPONENT_ATTRIBUTE_TEMPLATE_SERVICE: Symbol.for('COMPONENT_ATTRIBUTE_TEMPLATE_SERVICE'),
  EXPRESSION_REPOSITORY: Symbol.for('EXPRESSION_REPO'),
  EXPRESSION_SERVICE: Symbol.for('EXPRESSION_SERVICE'),
  EXPRESSION_DETAIL_REPOSITORY: Symbol.for('EXPRESSION_DETAIL_REPO'),
  EXPRESSION_DETAIL_SERVICE: Symbol.for('EXPRESSION_DETAIL_SERVICE'),
  COMPONENT_ATTRIBUTE_DATABINDING_REPOSITORY: Symbol.for('COMPONENT_ATTRIBUTE_DATABINDING_REPO'),
  COMPONENT_ATTRIBUTE_DATABINDING_SERVICE: Symbol.for('COMPONENT_ATTRIBUTE_DATABINDING_SERVICE'),
  COMPONENT_ATTRIBUTE_EVENT_REPOSITORY: Symbol.for('COMPONENT_ATTRIBUTE_EVENT_REPO'),
  COMPONENT_ATTRIBUTE_EVENT_SERVICE: Symbol.for('COMPONENT_ATTRIBUTE_EVENT_SERVICE'),
  CONFIG_REPOSITORY: Symbol.for('CONFIG_REPO'),
  CONFIG_UPDATEDATA: Symbol.for('CONFIG_UPDATEDATA'),
  BUSQUEDA_REPOSITORY: Symbol.for('BUSQUEDA_REPO'),
  DATAUPDATE_REPOSITORY: Symbol.for('UPDATE_REPO'),
  CONFIG_SERVICE: Symbol.for('CONFIG'),
  BUSQUEDA_SERVICE: Symbol.for('BUSQUEDA'),
  DATAUPDATE_SERVICE: Symbol.for('DATAUPDATE'),
  WHOAMI: Symbol.for('WHOAMI'),
  DIRECTIVA_SERVICE: Symbol.for('DIRECTIVA'),
  DIRECTIVA_REPOSITORY: Symbol.for('DIRECTIVA_REPO'),
  ESTRUCTURADB_SERVICE: Symbol.for('ESTRUCTURADB'),
  ESTRUCTURADB_REPOSITORY: Symbol.for('ESTRUCTURADB_REPO'),

  CATALOGO_SERVICE: Symbol.for('CATALOGO'),
  CATALOGO_REPOSITORY: Symbol.for('CATALOGO_REPO'),

  CATALOG_SERVICE: Symbol.for('CATALOG'),
  CATALOG_REPOSITORY: Symbol.for('CATALOG_REPO'),

  CATALOGTYPE_SERVICE: Symbol.for('CATALOGTYPE'),
  CATALOGTYPE_REPOSITORY: Symbol.for('CATALOGTYPE_REPO'),
  IDENTIDAD_MODELO_SERVICE: Symbol.for('IDENTIDADMODELO'),
  IDENTIDAD_MODELO_REPOSITORY: Symbol.for('IDENTIDADMODELO_REPO'),


  LOGBUSQUEDA_SERVICE: Symbol.for('LOGBUSQUEDA'),
  LOGBUSQUEDA_REPOSITORY: Symbol.for('LOGBUSQUEDA_REPO'),




  SEARCH_REPOSITORY: Symbol.for('SEARCHREPO'),
  SEARCH_SERVICE: Symbol.for('SEARCHSRV'),

  WORKFLOW_REPOSITORY: Symbol.for('WORKFLOW_REPOSITORY'),
  WORKFLOW_SERVICE: Symbol.for('WORKFLOW_SERVICE'),

  WORKFLOWACTIVITY_REPOSITORY: Symbol.for('WORKFLOWACTIVITY_REPOSITORY'),
  WORKFLOWACTIVITY_SERVICE: Symbol.for('WORKFLOWACTIVITY_SERVICE'),


  WORKFLOWACTIVITYCONTROL_REPOSITORY: Symbol.for('WORKFLOWACTIVITYCONTROL_REPOSITORY'),
  WORKFLOWACTIVITYCONTROL_SERVICE: Symbol.for('WORKFLOWACTIVITYCONTROL_SERVICE'),


  WORKFLOWACTIVITYVAR_REPOSITORY: Symbol.for('WORKFLOWACTIVITYVAR_REPOSITORY'),
  WORKFLOWACTIVITYVAR_SERVICE: Symbol.for('WORKFLOWACTIVITYVAR_SERVICE'),
  
  WORKFLOWACTIVITYCON_REPOSITORY: Symbol.for('WORKFLOWACTIVITYCON_REPOSITORY'),
  WORKFLOWACTIVITYCON_SERVICE: Symbol.for('WORKFLOWACTIVITYCON_SERVICE'),



  CACHE_SERVICE: Symbol.for('CACHE'),
  CACHE_REPOSITORY: Symbol.for('CACHE_REPO'),


  STORAGE_REPOSITORY: Symbol.for('DOCUMENT_REPO'),
  STORAGE_SERVICE: Symbol.for('STORAGE_SERVICE'),

  ENCRYPT_REPOSITORY: Symbol.for('ENCRYPT_REPO'),
  ENCRYPT_SERVICE: Symbol.for('ENCRYPT_SERVICE'),


  
  CONFIGURATION_REPOSITORY: Symbol.for('CFG_REPO'),
  CONFIGURATION_SERVICE: Symbol.for('CFG_SERVICE'),


  IMPORT_REPOSITORY: Symbol.for('IMP_REPO'),
  IMPORT_SERVICE: Symbol.for('IMP_SERVICE'),

}

