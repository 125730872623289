import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IComponentAttributeTemplateRepository } from '../domain/IComponentAttributeTemplateRepository';
import { IServiceComponentAttributeTemplate } from './IServiceComponentAttributeTemplate';
import { IapComponentAttributeTemplate } from '../domain/iapComponentAttributeTemplate';



@injectable()
export class ComponentAttributeTemplateService implements IServiceComponentAttributeTemplate {
  private readonly repo: IComponentAttributeTemplateRepository

  public constructor (@inject(TYPES.COMPONENT_ATTRIBUTE_TEMPLATE_REPOSITORY) _repo: IComponentAttributeTemplateRepository) {
    this.repo = _repo
  }

  async update (data: IapComponentAttributeTemplate): (Promise<boolean>) {
    return await this.repo.update(data);
  }

  async add (data: IapComponentAttributeTemplate): (Promise<boolean>) {
    return await this.repo.add(data);
  }

}
