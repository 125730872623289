<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
            
    <SplitButton  v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(SplitButtonTypeConst.ID)" v-tooltip="getPropertyValue(SplitButtonTypeConst.TOOLTIP)"
        :style="getPropertyValue(SplitButtonTypeConst.STYLE)"
        :class="{ [getPropertyValue(SplitButtonTypeConst.CLASS) ?? '']: true }"
        :name="getPropertyValue(SplitButtonTypeConst.NAME)" 
        :disabled="getPropertyBooleanValue(SplitButtonTypeConst.DISABLED)"
        :placeholder="getPropertyValue(SplitButtonTypeConst.PLACEHOLDER)" 
        :label="getPropertyValue(SplitButtonTypeConst.LABEL)"
        :icon="getPropertyValue(SplitButtonTypeConst.ICON)"        
        :raised="getPropertyBooleanValue(SplitButtonTypeConst.RAISED)"
        :rounded="getPropertyBooleanValue(SplitButtonTypeConst.ROUNDED)" 
        :outlined ="getPropertyBooleanValue(SplitButtonTypeConst.OUTLINED)"
        :text="getPropertyBooleanValue(SplitButtonTypeConst.TEXT)"
        :plain="getPropertyValue(SplitButtonTypeConst.PLAIN)" 
        :size="getCatalogValue(SplitButtonTypeConst.SIZE)"    
        :model="menuItems"    
        @click="processEventComponent(CatalogEventConst.CLICK)">
    </SplitButton >
</template>
<script lang="ts">


import { defineComponent, onMounted, ref,  onUnmounted } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import SplitButtonTypeConst from '../../../../domain/Constants/SplitButtonTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_split_button',
    components: {
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
      
        slotProps: {
            type: Object,
            default: () => ({})
        },
       

    },
    setup(props, context) {
        //
        const store = useStore();
        const router = useRouter();
        const {getLayoutComponent,canDoOperation,buildMenu, vmodel,baseOnMounted, isValidData, getPropertyValue,
             getPropertyBooleanValue, loaded,  resolveExpressions, getCatalogValue, baseOnUnmount,fieldKey,
             fieldKeyComponentDataSourceId,processEventComponent ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);


        const menuItems = ref([])
        const layoutMenu = getLayoutComponent(props.Component, SplitButtonTypeConst.LAYOUT_SPLITBTN_MENU);
        if (layoutMenu && layoutMenu?.children?.length > 0) {
            menuItems.value = buildMenu(layoutMenu.get(layoutMenu.id,'allchildren') ?? []) as any;          
        }


        onMounted(() => {
            baseOnMounted();
           
        })

        onUnmounted(() => {
            baseOnUnmount();
        })

        return {            
            getPropertyValue,
            getPropertyBooleanValue,
            SplitButtonTypeConst,
            loaded,
            BaseControlTypeConst,
            processEventComponent,
            CatalogEventConst,
            getCatalogValue,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            menuItems,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
            ,vmodel

        };
    },
});
</script>
<style scoped></style>
