import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IComponentAttributeRepository } from '../domain/IComponentAttributeRepository';
import { IServiceComponentAttribute } from './IServiceComponentAttribute';
import { IapComponentAttribute } from '../domain/iapComponentAttribute';
import { ComponentPropertyData } from '../domain/componentPropertyData';



@injectable()
export class ComponentAttributeService implements IServiceComponentAttribute {
  private readonly repo: IComponentAttributeRepository

  public constructor (@inject(TYPES.COMPONENT_ATTRIBUTE_EVENT_REPOSITORY) _repo: IComponentAttributeRepository) {
    this.repo = _repo
  }

  async update (data: IapComponentAttribute): (Promise<IapComponentAttribute>) {
    return await this.repo.update(data);
  }

  async delete(id: string) :Promise<boolean> {
    return await this.repo.delete(id)
  }


  async add (data: IapComponentAttribute): (Promise<IapComponentAttribute>) {
    return await this.repo.add(data);
  }

  async getByComponentId(componentId: number,componentTypeId:string) :Promise<ComponentPropertyData> {
    return await this.repo.getByComponentId(componentId,componentTypeId)
  }


}
