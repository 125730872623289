<template>

    <template v-if="templateConfig && false">
        <button class="layout-config-button p-link" type="button" @click="onConfigButtonClick()">
            <i class="pi pi-cog"></i>
        </button>
    </template>

    <Sidebar v-model:visible="layoutState.configSidebarVisible.value" position="right" class="layout-config-sidebar w-20rem">

        <template v-if="!templateSimple">
            <h5>Themes</h5>
            <div class="flex flex-wrap row-gap-3">
                <div v-for="(theme, i) in componentThemes" :key="i" class="w-3">
                    <Button 
                        :autoFocus="layoutConfig.componentTheme.value === theme.name"
                        class="cursor-pointer p-link w-2rem h-2rem border-circle flex-shrink-0 flex align-items-center justify-content-center"
                        @click="() => changeTheme(theme.name)"
                        :style="{ 'background-color': theme.color }">

                        <i v-if="theme.name === 'aunna' && theme.name != layoutConfig.componentTheme.value" style="margin-bottom:5px;" class="pi text-white">a</i>
                        <i v-if="theme.name === 'peris' && theme.name != layoutConfig.componentTheme.value" style="margin-bottom:5px;" class="pi text-white">p</i>
                        <i v-if="theme.name === layoutConfig.componentTheme.value" class="pi pi-check text-white" style="z-index:1;"></i>
                    </Button>
                    <!--<i v-if="theme.name === 'peris'" v-badge.secondary="'p'" class="p-overlay-badge" style="font-size:1em"></i>-->
                    <!--<i v-if="theme.name === 'aunna'" v-badge.secondary="'a'" class="p-overlay-badge" style="font-size:-1em"></i>-->
                </div>
            </div>
        </template>

        <template v-if="true">
            <!--<h5>Scale</h5>-->
            <h5>Tamaño</h5>
            <div class="flex align-items-center">
                <Button icon="pi pi-minus" type="button" @click="decrementScale()" class="p-button-text p-button-rounded w-2rem h-2rem mr-2" :disabled="layoutConfig.scale.value === scales[0]"></Button>
                <div class="flex gap-2 align-items-center">
                    <i @click="() => scaleClick(s)" style="cursor:pointer;" class="pi pi-circle-fill text-300" v-for="s in scales" :key="s" :class="{ 'text-primary-500': s === layoutConfig.scale.value }" v-tooltip.top.hover="s.toString()"></i>
                </div>
                <Button icon="pi pi-plus" type="button" pButton @click="incrementScale()" class="p-button-text p-button-rounded w-2rem h-2rem ml-2" :disabled="layoutConfig.scale.value === scales[scales.length - 1]" ></Button>
            </div>
        </template>

        <template v-if="!templateSimple">
            <h5>Menu Type</h5>
            <div class="flex flex-wrap row-gap-3">
                <div class="flex align-items-center gap-2 w-6">
                    <RadioButton name="menuMode" value="static" v-model="layoutConfig.menuMode.value" inputId="mode1"></RadioButton>
                    <label for="mode1">Static</label>
                </div>

                <div class="flex align-items-center gap-2 w-6">
                    <RadioButton name="menuMode" value="overlay" v-model="layoutConfig.menuMode.value" inputId="mode2"></RadioButton>
                    <label for="mode2">Overlay</label>
                </div>
                <div class="flex align-items-center gap-2 w-6">
                    <RadioButton name="menuMode" value="slim" v-model="layoutConfig.menuMode.value" inputId="mode3"></RadioButton>
                    <label for="mode2">Slim</label>
                </div>
                <div class="flex align-items-center gap-2 w-6">
                    <RadioButton name="menuMode" value="slim-plus" v-model="layoutConfig.menuMode.value" inputId="mode4"></RadioButton>
                    <label for="mode3">Slim +</label>
                </div>
                <div class="flex align-items-center gap-2 w-6">
                    <RadioButton name="menuMode" value="reveal" v-model="layoutConfig.menuMode.value" inputId="mode5"></RadioButton>
                    <label for="mode4">Reveal</label>
                </div>
                <div class="flex align-items-center gap-2 w-6">
                    <RadioButton name="menuMode" value="drawer" v-model="layoutConfig.menuMode.value" inputId="mode6"></RadioButton>
                    <label for="mode5">Drawer</label>
                </div>
                <div class="flex align-items-center gap-2 w-6">
                    <RadioButton name="menuMode" value="horizontal" v-model="layoutConfig.menuMode.value" inputId="mode2"></RadioButton>
                    <label for="mode2">Horizontal</label>
                </div>
            </div>
        </template>

        <template v-if="!templateSimple">
            <h5>Menu Theme</h5>
            <div class="field-radiobutton">
                <RadioButton :checked="layoutConfig.menuTheme === 'colorScheme'" name="menuTheme" value="colorScheme" v-model="layoutConfig.menuTheme.value" inputId="mode1" @change="() => changeMenuTheme('colorScheme')"></RadioButton>
                <label for="mode1">Color Scheme</label>
            </div>

            <div class="field-radiobutton">
                <RadioButton :checked="layoutConfig.menuTheme === 'primaryColor'" name="menuTheme" value="primaryColor" v-model="layoutConfig.menuTheme.value" inputId="mode2" @change="() => changeMenuTheme('primaryColor')"></RadioButton>
                <label for="mode2">Primary Color</label>
            </div>
            <div class="field-radiobutton">
                <RadioButton :checked="layoutConfig.menuTheme === 'transparent'" name="menuTheme" value="transparent" v-model="layoutConfig.menuTheme.value" inputId="mode3" @change="() => changeMenuTheme('transparent')"></RadioButton>
                <label for="mode2">Transparent</label>
            </div>
        </template>

        <template v-if="!templateSimple">
            <h5>Color Scheme</h5>
            <div class="field-radiobutton flex-1">
                <RadioButton v-model="colorScheme" name="colorScheme" value="light" @change="() => changeColorScheme('light')" inputId="outlined_input"></RadioButton>
                <label for="outlined_input">Light</label>
            </div>
            <div class="field-radiobutton flex-1">
                <RadioButton v-model="colorScheme" name="colorScheme" value="dim" @change="() => changeColorScheme('dim')" inputId="filled_input"></RadioButton>
                <label for="filled_input">Dim</label>
            </div>
            <div class="field-radiobutton flex-1">
                <RadioButton v-model="colorScheme" name="colorScheme" value="dark" @change="() => changeColorScheme('dark')" inputId="filled_input"></RadioButton>
                <label for="filled_input">Dark</label>
            </div>
        </template>

        <template v-if="true">
            <!--<h5>Input Style</h5>-->
            <h5>Sombreado de los inputs</h5>
            <div class="flex">
                <div class="field-radiobutton flex-1">
                    <RadioButton name="inputStyle" value="outlined" v-model="layoutConfig.inputStyle.value" inputId="outlined_input" @change="() => changeInputStyle('outlined')"></RadioButton>
                    <label for="outlined_input">Outlined</label>
                </div>
                <div class="field-radiobutton flex-1">
                    <RadioButton name="inputStyle" value="filled" v-model="layoutConfig.inputStyle.value" inputId="filled_input" @change="() => changeInputStyle('filled')"></RadioButton>
                    <label for="filled_input">Filled</label>
                </div>
            </div>
        </template>

        <template v-if="!templateSimple">
            <h5>Ripple Effect</h5>
            <InputSwitch v-model="layoutConfig.ripple.value" @change="onChangeRipple"></InputSwitch>
        </template>

    </Sidebar>
</template>

<script setup>
import RadioButton from 'primevue/radiobutton';
import Button from 'primevue/button';
import InputSwitch from 'primevue/inputswitch';
import Sidebar from 'primevue/sidebar';
import PrimeVue from 'primevue/config';

import { ref, watch, onMounted } from 'vue';
import { useLayout } from '../composables/layout';

const componentThemes = ref([
    { name: 'indigo', color: '#00594f' },
    { name: 'blue', color: '#3B82F6' },
    { name: 'purple', color: '#8B5CF6' },
    { name: 'teal', color: '#14B8A6' },
    { name: 'cyan', color: '#06b6d4' },
    { name: 'green', color: '#10b981' },
    { name: 'orange', color: '#f59e0b' },
    { name: 'pink', color: '#d946ef' },
    { name: 'red', color: '#ff3d32' },
    { name: 'peris', color: '#025A50' },
    { name: 'aunna', color: '#00263E' }
]);

defineProps({
    templateConfig: {
        type: Boolean,
        default: false
    },
    templateSimple: {
        type: Boolean,
        default: true
    }
});

const scales = ref([9, 10, 11, 12, 13, 14, 15, 16, 17]);
const { setScale, layoutConfig, layoutState, onConfigSidebarToggle, setLayoutConfig, layoutProperty } = useLayout();

watch(layoutConfig.menuMode, (newVal) => {
    if (newVal === 'static') {
        layoutState.staticMenuDesktopInactive.value = false;
    }
    setLayoutConfig({name:'menuMode',value:newVal});
});

onMounted(() => {
});

const onConfigButtonClick = () => {
    onConfigSidebarToggle();
};

const colorScheme = ref(layoutConfig.colorScheme.value);

const changeColorScheme = (colorScheme) => {
    const themeLink = document.getElementById('theme-css');
    const themeLinkHref = themeLink.getAttribute('href');
    const currentColorScheme = 'theme-' + layoutConfig.colorScheme.value.toString();
    const newColorScheme = 'theme-' + colorScheme;
    const newHref = themeLinkHref.replace(currentColorScheme, newColorScheme);

    replaceLink(themeLink, newHref, () => {
        layoutConfig.colorScheme.value = colorScheme;
    });

    setLayoutConfig({name:'colorScheme',value:colorScheme});
};

const changeTheme = (theme) => {
    //const themeLink = document.getElementById('theme-link');
    const themeLink = document.getElementById('theme-css');
    const themeHref = themeLink.getAttribute('href');
    const newHref = themeHref.replace(layoutConfig.componentTheme.value, theme);

    replaceLink(themeLink, newHref, () => {
        layoutConfig.componentTheme.value = theme;
    });

    setLayoutConfig({name:'componentTheme',value:theme});
};

const replaceLink = (linkElement, href, onComplete) => {
    if (!linkElement || !href) {
        return;
    }

    const id = linkElement.getAttribute('id');
    const cloneLinkElement = linkElement.cloneNode(true);

    cloneLinkElement.setAttribute('href', href);
    cloneLinkElement.setAttribute('id', id + '-clone');

    linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

    cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();

        const element = document.getElementById(id); // re-check
        element && element.remove();

        cloneLinkElement.setAttribute('id', id);
        onComplete && onComplete();
    });
};

const scaleClick = (scale) => {
    setScale(scale);
    applyScale();
};

const decrementScale = () => {
    setScale(layoutConfig.scale.value - 1);
    applyScale();
};
const incrementScale = () => {
    setScale(layoutConfig.scale.value + 1);
    applyScale();
};
const applyScale = () => {
    document.documentElement.style.fontSize = layoutConfig.scale.value + 'px';
    setLayoutConfig({name:'scale',value:layoutConfig.scale.value});
};

const changeMenuTheme = (menuTheme) => {
    setLayoutConfig({name:'menuTheme',value:menuTheme});
}

const changeInputStyle = (inputStyle) => {
    setLayoutConfig({name:'inputStyle',value:inputStyle});
}

const onChangeRipple = () => {
    PrimeVue.ripple = !PrimeVue.ripple;
    
    const ripple = PrimeVue.ripple;
    setLayoutConfig({name:'ripple',value: ripple==true ? 'false' : 'true'});
};
</script>



<style lang="scss" scoped></style>
