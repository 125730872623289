import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { ICodeRepository } from '../domain/ICodeRepository';



import { IServiceCode } from './IServiceCode';
import { IapCode } from '../domain/iapCode';



@injectable()
export class CodeService implements IServiceCode {
  private readonly repo: ICodeRepository

  public constructor (@inject(TYPES.CODE_REPOSITORY) _repo: ICodeRepository) {
    this.repo = _repo
  }

  async update (data: IapCode): (Promise<IapCode>) {
    return await this.repo.update(data);
  }

  async add (data: IapCode): (Promise<IapCode>) {
    return await this.repo.add(data);
  }

  async getAll(applicationId: Number,applicationVersion: Number,idObject:String,objectId:String) :Promise<IapCode[]> {
    return await this.repo.getAll(applicationId,applicationVersion,idObject,objectId)
  }

  async getById(id: string) :Promise<IapCode> {
    return await this.repo.getById(id)
  }
}
