import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { style: {"border":"1px solid black","border-collapse":"collapse"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.header, (name, index) => {
          return (_openBlock(), _createElementBlock("th", {
            key: 'h_' + index,
            scope: "col",
            style: {"border":"1px solid black","border-collapse":"collapse"}
          }, _toDisplayString(name), 1 /* TEXT */))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, index) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: 'tr_' + index
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (r, index) => {
            return (_openBlock(), _createElementBlock("td", {
              key: 'td_' + index,
              style: {"border":"1px solid black","border-collapse":"collapse"}
            }, _toDisplayString(r), 1 /* TEXT */))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}