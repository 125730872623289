import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import { IHelpRepository } from '../domain/IHelpRepository';

import { UPDATE, ADD } from './mutation/helpMutation';
import { IapHelp } from '../domain/iapHelp';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class HelpApolloClientRepository implements IHelpRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }
  
  

  async update (dataInput: IapHelp): (Promise<IapHelp>) {
    return new Promise<IapHelp>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.HelpMutation.updateHelp)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async add (dataInput: IapHelp): (Promise<IapHelp>) {
    return new Promise<IapHelp>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.HelpMutation.addHelp)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  

}


