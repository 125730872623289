import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import { IComponentAttributeTemplateRepository } from '../domain/IComponentAttributeTemplateRepository';

import { UPDATE, ADD } from './mutation/componentAttributeTemplateMutation';
import { IapComponentAttributeTemplate } from '../domain/iapComponentAttributeTemplate';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class ComponentAttributeTemplateApolloClientRepository implements IComponentAttributeTemplateRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }
  

  async update (dataInput: IapComponentAttributeTemplate): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentAttributeTemplateMutation)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async add (dataInput: IapComponentAttributeTemplate): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentAttributeTemplateMutation)
        }
        catch(e) {
          reject()
        }
      });

    });
  }


}


