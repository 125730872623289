import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createSlots as _createSlots, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_DataView = _resolveComponent("DataView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.getPropertyBooleanValue(_ctx.DataViewTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.DataViewTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? (_openBlock(), _createBlock(_component_DataView, {
          key: _ctx.componentKey,
          value: _ctx.getPropertyValue(_ctx.DataViewTypeConst.VALUE),
          layout: _ctx.getCatalogValue(_ctx.DataViewTypeConst.LAYOUT),
          rows: _ctx.getPropertyNumberValue(_ctx.DataViewTypeConst.ROWS),
          first: _ctx.getPropertyNumberValue(_ctx.DataViewTypeConst.FIRST),
          totalRecords: _ctx.getPropertyNumberValue(_ctx.DataViewTypeConst.TOTALRECORDS),
          paginator: _ctx.getPropertyBooleanValue(_ctx.DataViewTypeConst.PAGINATOR),
          paginatorPosition: _ctx.getCatalogValue(_ctx.DataViewTypeConst.PAGINATORPOSITION),
          alwaysShowPaginator: _ctx.getPropertyBooleanValue(_ctx.DataViewTypeConst.ALWAYSSHOWPAGINATOR),
          paginatorTemplate: _ctx.getPropertyValue(_ctx.DataViewTypeConst.PAGINATORTEMPLATE),
          pageLinkSize: _ctx.getPropertyNumberValue(_ctx.DataViewTypeConst.PAGELINKSIZE),
          rowsPerPageOptions: _ctx.getPropertyNumberValue(_ctx.DataViewTypeConst.ROWSPERPAGEOPTIONS),
          currentPageReportTemplate: _ctx.getPropertyValue(_ctx.DataViewTypeConst.CURRENTPAGEREPORTTEMPLATE),
          sortField: _ctx.getPropertyValue(_ctx.DataViewTypeConst.SORTFIELD),
          sortOrder: _ctx.getPropertyNumberValue(_ctx.DataViewTypeConst.SORTORDER),
          lazy: _ctx.getPropertyBooleanValue(_ctx.DataViewTypeConst.LAZY),
          dataKey: _ctx.getPropertyValue(_ctx.DataViewTypeConst.DATAKEY),
          pt: _ctx.getPropertyValue(_ctx.DataViewTypeConst.PT),
          unstyled: _ctx.getPropertyBooleanValue(_ctx.DataViewTypeConst.UNSTYLED)
        }, _createSlots({
          list: _withCtx((slotProps) => [
            _renderSlot(_ctx.$slots, "list", {
              slotProps: { data: slotProps.data, index: slotProps.index, control: _ctx.ControlTypeConst.CTDATAVIEW }
            })
          ]),
          _: 2 /* DYNAMIC */
        }, [
          (_ctx.getLayoutComponent(_ctx.Component,_ctx.DataViewTypeConst.LAYOUT_HEADER))
            ? {
                name: "header",
                fn: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "header")
                ]),
                key: "0"
              }
            : undefined,
          (_ctx.getLayoutComponent(_ctx.Component,_ctx.DataViewTypeConst.LAYOUT_FOOTER))
            ? {
                name: "footer",
                fn: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "footer")
                ]),
                key: "1"
              }
            : undefined,
          (_ctx.getLayoutComponent(_ctx.Component,_ctx.DataViewTypeConst.LAYOUT_PAGINATORSTART))
            ? {
                name: "paginatorstart",
                fn: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "paginatorstart")
                ]),
                key: "2"
              }
            : undefined,
          (_ctx.getLayoutComponent(_ctx.Component,_ctx.DataViewTypeConst.LAYOUT_PAGINATOREND))
            ? {
                name: "paginatorend",
                fn: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "paginatorend")
                ]),
                key: "3"
              }
            : undefined,
          (_ctx.getLayoutComponent(_ctx.Component,_ctx.DataViewTypeConst.LAYOUT_EMPTY))
            ? {
                name: "empty",
                fn: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "empty")
                ]),
                key: "4"
              }
            : undefined,
          (_ctx.getLayoutComponent(_ctx.Component,_ctx.DataViewTypeConst.LAYOUT_GRID))
            ? {
                name: "grid",
                fn: _withCtx((slotProps) => [
                  _renderSlot(_ctx.$slots, "grid", {
                    slotProps: { data: slotProps.data, index: slotProps.index, control: _ctx.ControlTypeConst.CTDATAVIEW }
                  })
                ]),
                key: "5"
              }
            : undefined
        ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "layout", "rows", "first", "totalRecords", "paginator", "paginatorPosition", "alwaysShowPaginator", "paginatorTemplate", "pageLinkSize", "rowsPerPageOptions", "currentPageReportTemplate", "sortField", "sortOrder", "lazy", "dataKey", "pt", "unstyled"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}