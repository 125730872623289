export default class ExpressionNomenclatorConst {
    static EXPNOM_ATTRIBUTES='attr'
    static EXPNOM_COMPONENT='comp'
    static EXPNOM_DATASOURCE='ds'
    static EXPNOM_EVENT='evt' 
    static EXPNOM_CATALOG='cat' 
    static EXPNOM_USER='usr' 

    static EXPNOM_MENU='menu'

    static EXPNOM_DSF='dsf'
    static EXPNOM_DSSC='dssc'
    static EXPNOM_DSLK='dslk'
    static SEPARATOR='.'
    
    
    }
  
  
  
    
    