
export default class BaseControlTypeConst {

  static ID = 'id'
  static STYLE = 'style'
  static CLASS = 'class'
  static TOOLTIP = 'tooltip'
  static NAME = 'name'
  static DISABLED = 'disabled'
  static PLACEHOLDER = 'placeholder'
  static MODELVALUE = 'modelValue'
  static REQUIRED = 'required'
  static VISIBLE = 'visible'
  static DATAVALUE = 'dataValue'
  static DEBUG = 'debug'
  
  }
  