import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'
import { IServiceWorkFlowActivity } from './interface/IServiceWorkFlowActivity'
import type { IWorkFlowActivityRepository } from '../domain/repositorry/IWorkFlowActivityRepository'
import { IapWorkFlowActivity } from '../domain/service/iapWorkFlowActivity'





@injectable()
export class WorkFlowActivityService implements IServiceWorkFlowActivity {
  private readonly repo: IWorkFlowActivityRepository

  public constructor (@inject(TYPES.WORKFLOWACTIVITY_REPOSITORY) _repo: IWorkFlowActivityRepository) {
    this.repo = _repo
  }

  async update (data: IapWorkFlowActivity): (Promise<IapWorkFlowActivity>) {
    return await this.repo.update(data);
  }

  async add (data: IapWorkFlowActivity): (Promise<IapWorkFlowActivity>) {
    return await this.repo.add(data);
  }

  async delete (data: IapWorkFlowActivity): (Promise<boolean>) {
    return await this.repo.delete(data);
  }
  async getAll(maxregs: number | undefined) :Promise<IapWorkFlowActivity[]> {
    return await this.repo.getAll(maxregs)
  }

  async getById(id: number) :Promise<IapWorkFlowActivity> {
    return await this.repo.getById(id)
  }

  
}
