import BaseControlTypeConst from "./BaseControlTypeConst"

export default class SplitButtonTypeConst extends BaseControlTypeConst {
  
    static LABEL='label'
    static SEVERITY='severity'
    static RAISED='raised'
    static ROUNDED='rounded'
    static TEXT='text'
    static OUTLINED='outlined'
    static SIZE='size'
    static PLAIN='plain'
    static ICON='icon'

    static LAYOUT_SPLITBTN_MENU= 'layouttemp-splitbtn-menu'
    
  }
  