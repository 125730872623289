import BaseControlTypeConst from "./BaseControlTypeConst"

export default class DropDownCascadeTypeConst extends BaseControlTypeConst {

    static OPTIONS = 'options'
    static OPTIONLABEL = 'optionLabel'
    static OPTIONVALUE = 'optionValue'
    static OPTIONDISABLED = 'optionDisabled'
    static OPTIONGROUPLABEL = 'optionGroupLabel'
    static OPTIONGROUPCHILDREN = 'optionGroupChildren'
    
    static DATAKEY = 'dataKey'  
    static READONLY = 'readonly'  

    static OPTION_FILE = 'optionsFile'
    static DATASOURCEVALUE = 'datasourcevalue'

}
