import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-12"
}
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "p-fluid formgrid grid" }
const _hoisted_4 = { key: 6 }
const _hoisted_5 = { class: "p-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_Message = _resolveComponent("Message")!

  return (_ctx.dataInput.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataInput, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.fieldId + index.toString(),
                class: "field col-12 md:col-3"
              }, [
                _createElementVNode("div", null, [
                  _createVNode(_component_Checkbox, {
                    modelValue: _ctx.checked,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checked) = $event)),
                    inputId: item.fieldId + index,
                    name: _ctx.getColumnName(item.fieldId),
                    value: item.fieldId
                  }, null, 8 /* PROPS */, ["modelValue", "inputId", "name", "value"]),
                  _createElementVNode("label", {
                    for: "question",
                    class: _normalizeClass({ 'p-invalid': _ctx.v$.items.$each.$response.$errors[index].value.length > 0 && _ctx.submitted, 'customrequired': item.required })
                  }, _toDisplayString(_ctx.getColumnName(item.fieldId)), 3 /* TEXT, CLASS */),
                  (_ctx.isColumnType(item.fieldId, _ctx.SqlTypesConst.BIT))
                    ? (_openBlock(), _createBlock(_component_InputSwitch, {
                        key: 0,
                        style: {"display":"block"},
                        modelValue: item.value,
                        "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                        trueValue: true,
                        falseValue: false,
                        disabled: _ctx.checkDisabledControl(item.field)
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled"]))
                    : (_ctx.isColumnType(item.fieldId, _ctx.SqlTypesConst.DATE) || _ctx.isColumnType(item.fieldId, _ctx.SqlTypesConst.DATETIME))
                      ? (_openBlock(), _createBlock(_component_Calendar, {
                          key: 1,
                          modelValue: item.value,
                          "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                          disabled: _ctx.checkDisabledControl(item.fieldId)
                        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled"]))
                      : (_ctx.isColumnType(item.fieldId, _ctx.SqlTypesConst.INT))
                        ? (_openBlock(), _createBlock(_component_InputNumber, {
                            key: 2,
                            modelValue: item.value,
                            "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                            disabled: _ctx.checkDisabledControl(item.fieldId)
                          }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled"]))
                        : (_ctx.isColumnType(item.fieldId, _ctx.SqlTypesConst.DECIMAL))
                          ? (_openBlock(), _createBlock(_component_InputNumber, {
                              key: 3,
                              modelValue: item.value,
                              "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                              mode: "currency",
                              disabled: _ctx.checkDisabledControl(item.fieldId)
                            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled"]))
                          : (_ctx.isCatalogTypeValue(item.fieldId))
                            ? (_openBlock(), _createBlock(_component_Dropdown, {
                                key: 4,
                                modelValue: item.value,
                                "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                options: _ctx.getCatalogData(item.fieldId),
                                optionLabel: "description",
                                optionValue: "id",
                                disabled: _ctx.checkDisabledControl(item.fieldId),
                                filter: ""
                              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options", "disabled"]))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                                _createCommentVNode("\r\n                        {}\r\n                     \r\n"),
                                _createCommentVNode("<crudTabla v-else-if=\"isTableTypeValue(item.field)\" :container=\"container\" :baseDatos=\"baseDatos\" :tablas=\"tablas\" :mostrarBotonCerrarPestañas=\"false\"  />"),
                                _createVNode(_component_InputText, {
                                  modelValue: item.value,
                                  "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                  disabled: _ctx.checkDisabledControl(item.fieldId)
                                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled"])
                              ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */)),
                  (_ctx.submitted)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.items.$each.$response.$errors[index].value, (error) => {
                          return (_openBlock(), _createElementBlock("span", { key: error }, [
                            _createElementVNode("small", _hoisted_5, _toDisplayString(error.$message), 1 /* TEXT */)
                          ]))
                        }), 128 /* KEYED_FRAGMENT */))
                      ]))
                    : _createCommentVNode("v-if", true)
                ])
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _createElementVNode("div", null, [
            (_ctx.checked.length > 0 && _ctx.selectedItems.length > 0)
              ? (_openBlock(), _createBlock(_component_Toolbar, { key: 0 }, {
                  end: _withCtx(() => [
                    _createVNode(_component_Button, {
                      id: "update",
                      label: "Actualizar",
                      icon: "pi pi-save",
                      class: "mr-2 p-button-rounded p-button-primary p-button-outlined",
                      onClick: _ctx.saveData
                    }, null, 8 /* PROPS */, ["onClick"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ]))
    : (_openBlock(), _createBlock(_component_Message, {
        key: 1,
        severity: "warn",
        closable: false
      }, {
        default: _withCtx(() => [
          _createTextVNode("El origen de datos no tiene ninguna configuración para actualizar registros masivamente")
        ]),
        _: 1 /* STABLE */
      }))
}