import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: ModeloComponentAttributeEventInputType!){
    componentAttributeEventMutation{
        updateComponentAttributeEvent(data:$data)
    }
}
`);

export const ADD = gql(`
mutation($data: ModeloComponentAttributeEventInputType!){
    componentAttributeEventMutation{
        addComponentAttributeEvent(data:$data)
    }
}
`);



