import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id", "name", "placeholder", "label", "icon", "to", "command", "url", "items", "disabled", "visible", "target", "separator"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.getPropertyBooleanValue(_ctx.MenuItemTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.MenuItemTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? _withDirectives((_openBlock(), _createElementBlock("div", {
          id: _ctx.getPropertyValue(_ctx.MenuItemTypeConst.ID),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.MenuItemTypeConst.STYLE)),
          class: _normalizeClass({ [_ctx.getPropertyValue(_ctx.MenuItemTypeConst.CLASS) ?? '']: true }),
          name: _ctx.getPropertyValue(_ctx.MenuItemTypeConst.NAME),
          placeholder: _ctx.getPropertyValue(_ctx.MenuItemTypeConst.PLACEHOLDER),
          label: _ctx.getPropertyValue(_ctx.MenuItemTypeConst.LABEL),
          icon: _ctx.getPropertyValue(_ctx.MenuItemTypeConst.ICON),
          to: _ctx.getPropertyValue(_ctx.MenuItemTypeConst.TO),
          command: _ctx.getPropertyValue(_ctx.MenuItemTypeConst.COMMAND),
          url: _ctx.getPropertyValue(_ctx.MenuItemTypeConst.URL),
          items: _ctx.getPropertyValue(_ctx.MenuItemTypeConst.ITEMS),
          disabled: _ctx.getPropertyBooleanValue(_ctx.MenuItemTypeConst.DISABLED),
          visible: _ctx.getPropertyBooleanValue(_ctx.MenuItemTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW),
          target: _ctx.getPropertyValue(_ctx.MenuItemTypeConst.TARGET),
          separator: _ctx.getPropertyBooleanValue(_ctx.MenuItemTypeConst.SEPARATOR),
          key: _ctx.getPropertyValue(_ctx.MenuItemTypeConst.KEY)
        }, null, 14 /* CLASS, STYLE, PROPS */, _hoisted_1)), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.MenuItemTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}