/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
import {  ref } from "vue";
import { IapCatalogType } from "../../../catalog/domain/iapCatalogType";
import { IapExpressionDetail } from "../iapExpressionDetail";
import WidgetComponentDataTree from "../../../designer/domain/widgetComponent";
import CatalogTypeConst from "../../../catalog/domain/const/CatalogTypeConst";
import ExpressionToolConst from "../const/ExpressionToolConst";
import ExpressionNomenclatorConst from "../const/ExpressionNomenclatorConst";
import { CustomMenu } from "../../../../../common/domain/modelos/menu/CustomMenu";
import { IapComponentDataSourceFieldConfiguration } from '../../../component/domain/iapComponentDataSourceFieldConfiguration';
import { searchMenuTree } from "../../../menu/infrastructure/HelperMenu";
import CatalogObjectTypeConst from "../../../catalog/domain/const/CatalogObjectTypeConst";
import { IapDataSourceLookUp } from "../../../datasource/domain/iapDataSourceLookUp";
import { IapObjectDataSourceFieldConfiguration } from '../../../datasource/domain/iapObjectDataSourceFieldConfiguration';
import ComponentUtil from "../../../designer/infrastructure/component/util/componentUtil";

export default function ExpressionTreeNode(
    catalogTypes: IapCatalogType[],
    expressionDetail: IapExpressionDetail[],
    externalData: WidgetComponentDataTree,
    applicationId: Number,
    applicationVersion: Number,    
    store: any,
    idObjeto:string,
    lookUp:IapDataSourceLookUp | null
) {

    const tree = ref();

    const data = store.getters.getApplicationMenus as CustomMenu[];
    const { searchTree } = ComponentUtil();


    const expToolcatalog = () => {
        return catalogTypes.filter(x => x.id == CatalogTypeConst.EXPTOOL).flatMap(x => x.iapCatalogs);
    }

    const getTreeFromExpToolCatalog = () => {

        var data = [] as any;
        var items = expToolcatalog().filter(x => x.parentId == null).sort((a, b) => { return (a.order ?? 0) - (b.order ?? 0); });

        if (items.length > 0) {
            items.forEach(x => {
                const aux = {
                    key: x.id,
                    label: x.description,
                    icon: {
                        'pi pi-fw pi-cog': x.id == ExpressionToolConst.EXPTOOL_FN,
                        'pi pi-percentage': x.id == ExpressionToolConst.EXPTOOL_OP,
                        'pi pi-user': x.id == ExpressionToolConst.EXPTOOL_ID,
                        'pi-book': x.id == ExpressionToolConst.EXPTOOL_CAT,
                        'pi pi-question': x.id == ExpressionToolConst.EXPTOOL_OT || x.id == ExpressionToolConst.EXPTOOL_VAR
                    },
                    data: { value: x.id, label: x.description },
                    children:
                        expToolcatalog().filter(y => y.parentId == x.id).map(
                            z => ({
                                key: z.id, label: z.description, icon: '', data: { value: z.id, label: z.description }, children: expToolcatalog().filter(a => a.parentId == z.id).
                                    map(b => ({ key: b.id, label: b.description, icon: '', data: { value: x.id == ExpressionToolConst.EXPTOOL_ID ? '[' + ExpressionNomenclatorConst.EXPNOM_USER + '.' + b.id + ']' : b.additionalValue, label: b.description, path: x.id == ExpressionToolConst.EXPTOOL_ID ? 'Entidades --> Usuario --> ' + b.description : b.description }, children: [] }))

                            }))
                }
                data.push(aux);
            })
        };

        return data as any[];
    };


    const getTreeFromVariable = () => {

        var items = [] as any;
        items = expressionDetail.map(x => (
            { key: x.id, label: x.variable, icon: '', data: { value: '[' + x.variable + ']', label: x.variable }, children: [] }));

        return items;

    };


    const getItemCatalog = (catalogId: string, newPath: string | null) => {
        return catalogTypes.find(cat => cat.id == catalogId)?.iapCatalogs.map(x => ({ key: x.id, label: x.description, icon: '', data: { value: '[' + ExpressionNomenclatorConst.EXPNOM_CATALOG +'.' + x.id + ']', label: x.description, path: 'Catálogo' + ' --> ' + newPath + ' --> ' + x.description }, children: [] }))
    }

    const getTreeFromEntirelyCatalog = () => {

        var items = [] as any;

        items = catalogTypes.map(x => (
            {
                key: x.id, label: x.description, icon: '', data: { value: x.id, label: x.description, path: x.description },
                children: getItemCatalog(x.id, x.description)
            }))

        return items;

    };

    const isSubcomponentNodeWithoutChildren = (obj: any) => {
        return obj.label === "Subcomponentes" && obj.children.length === 0;
    };

    const isAttributeNodeWithoutChildren = (obj: any) => {
        return obj.label === "Atributos" && obj.children.length === 0;
    };

    const isDataSourceNodeWithoutChildren = (obj: any) => {
        return obj.label === "Origen Datos" && obj.children.length === 0;
    };

    const isEventNodeWithoutChildren = (obj: any) => {
        return obj.label === "Eventos" && obj.children.length === 0;
    };


   


    const getPathFromChild = (component: WidgetComponentDataTree, newPath: string | null): string => {

        const name = component.name ?? ''

        if (newPath == null) {
            newPath = name;
        }
        else {
            newPath = name + ' --> ' + newPath;
        }

        if (component.parentId) {
            const parent = searchTree(externalData, component.parentId, 'id');
            if (parent != null) {
                return getPathFromChild(parent, newPath);
            }
        }

        return newPath;

    };




    const getPathFromChildMenu = (menuCustom: CustomMenu, newPath: string | null): string | null => {

        const label = menuCustom.label as string;

        if (newPath == null) {
            newPath = label;
        }
        else {
            newPath = label + ' --> ' + newPath;
        }

        if (menuCustom.parentId) {
            const parent = searchMenuTree(data, menuCustom.parentId);
            if (parent != null) {
                return getPathFromChildMenu(parent, newPath);
            }
        }

        return newPath;

    }


    function groupObjectsByTableName(originalArray: any[]): any[] {
        const groupedMap = new Map<string, any[]>();

        for (const obj of originalArray) {
            if (!groupedMap.has(obj.tableName)) {
                groupedMap.set(obj.tableName, []);
            }
            groupedMap.get(obj.tableName)?.push(obj);
        }


        const groupedArray: any[] = [];
        for (const [tableName, objects] of groupedMap) {
            groupedArray.push({
                tableName,
                objects,
            });
        }

        return groupedArray;
    }







    const getDataSourceChildrens = (configurations: IapComponentDataSourceFieldConfiguration[] | IapObjectDataSourceFieldConfiguration[], path: string) => {

        if (Array.isArray(configurations) && configurations.length > 0) {

            
            const dataSourceFields = configurations.flatMap(x => x.dataSourceField);
            let objectId:number=0;

                if('objetoId' in configurations[0]){
                    objectId=configurations[0].objetoId;
                }
                else{
                    objectId=configurations[0].componentDataSourceId;
                }

            const groupedData = groupObjectsByTableName(dataSourceFields);            
            return groupedData.map(x => ({
                key: x.tableName ?? 'Propios', label: x.tableName ?? 'Propios', icon: '', data: { value: x.tableName?? 'Propios', label: x.tableName?? 'Propios', nomenclator: '', path: path + ' --> ' + (x.tableName?? 'Propios') }
                , children: x.objects.map((z:any) => ({
                    key: z.id, label: z.field, icon: '', data: { value: '[' + ExpressionNomenclatorConst.EXPNOM_DSF + '.'+(objectId)+'.' + z.id + ']', label: z.field, nomenclator: ExpressionNomenclatorConst.EXPNOM_DSF, path: path + ' --> ' + (x.tableName ?? 'Propios') + ' --> ' + z.field }
                    , children: z.dataSourceLookUp?.dataSource?.iapDataSourceFields.map((l:any) => ({
                        key: l.id, label: l.field, icon: '', data: { value: '[' + ExpressionNomenclatorConst.EXPNOM_DSLK +'.'+ z.dataSourceLookUpId+ '.'+ l.id + ']', label: l.field, nomenclator: ExpressionNomenclatorConst.EXPNOM_DSF, path: path + ' --> ' + (x.tableName ?? 'Propios')+' --> ' + 'LookUp'+' --> ' + l.field }
                        , children: []
                    })) ?? []
                }))
            }));


        }

    };




    const getTreeFromComponentData = (children: WidgetComponentDataTree[]) => {

        var componentTree = children.map(x => ({
            key: x.id, label: x.name, icon: '', data: { value: '[' + ExpressionNomenclatorConst.EXPNOM_COMPONENT + '.' + x.id + '.' + x.applicationId + '.' + x.applicationVersion + ']', label: x.name, nomenclator: ExpressionNomenclatorConst.EXPNOM_COMPONENT, path: getPathFromChild(x, null) }, children:
                [{ key: ExpressionNomenclatorConst.EXPNOM_COMPONENT + x.id, label: 'Subcomponentes', icon: '', data: { value: 'sub', label: 'Subcomponentes' }, children: getTreeFromComponentData(x.children) },
                { key: ExpressionNomenclatorConst.EXPNOM_ATTRIBUTES + x.id, label: 'Atributos', icon: '', data: { value: 'attr', label: 'Atributos' }, children: x.iapComponentAttributes.map(y => ({ key: y.id, label: y.name, icon: '', data: { value: '[' + ExpressionNomenclatorConst.EXPNOM_ATTRIBUTES + '.' + y.id + ']', label: y.name, nomenclator: ExpressionNomenclatorConst.EXPNOM_ATTRIBUTES, path: getPathFromChild(x, null) + ' --> Atributo -->' + y.name }, children: [] })) },
                { key: ExpressionNomenclatorConst.EXPNOM_DATASOURCE + x.id, label: 'Origen Datos', icon: '', data: { value: 'ds', label: 'Origen Datos' }, children: x.iapComponentDataSources.map(z => ({ key: z.id.toString(), label: z.name, icon: '', data: { value: '[' + ExpressionNomenclatorConst.EXPNOM_DATASOURCE + '.' + z.id.toString() + '.' + z.dataSourceId + '.' + z.componentId + ']', label: z.name, nomenclator: ExpressionNomenclatorConst.EXPNOM_DATASOURCE, path: getPathFromChild(x, null) + ' --> Origen Dato -->' + z.name }, children: getDataSourceChildrens(z.iapComponentDataSourceFieldConfigurations, getPathFromChild(x, null) + ' --> Origen Dato -->' + z.name) })) },
                { key: ExpressionNomenclatorConst.EXPNOM_EVENT + x.id, label: 'Eventos', icon: '', data: { value: 'evt', label: 'Eventos' }, children: x.iapComponentEvents.map(z => ({ key: z.id.toString(), label: z.name, icon: '', data: { value: '[' + ExpressionNomenclatorConst.EXPNOM_EVENT + '.' + z.id.toString() + ']', label: z.name, nomenclator: ExpressionNomenclatorConst.EXPNOM_EVENT, path: getPathFromChild(x, null) + ' --> Evento -->' + z.name }, children: [] })) },
                ].filter(obj => !isSubcomponentNodeWithoutChildren(obj)).filter(obj => !isAttributeNodeWithoutChildren(obj)).filter(obj => !isDataSourceNodeWithoutChildren(obj)).filter(obj => !isEventNodeWithoutChildren(obj))
        })) as any[]
        return componentTree;

    };


    const getTreeFromLookUp=()=>{

        let treeLookUp:any[]=[];

        if(lookUp && lookUp!=null){
            treeLookUp=[lookUp.dataSource].map(x=>({key:  x.id,label:x.name,icon:'',data:{value:'[' + ExpressionNomenclatorConst.EXPNOM_DATASOURCE + '.'+x.id+'.'+lookUp.id+']',label:x.name, nomenclator: ExpressionNomenclatorConst.EXPNOM_DATASOURCE,path:'Origen Dato --> '+x.name},
            children:getDataSourceChildrens(lookUp.dataSource.iapDataSourceFields.flatMap(x=>x.iapObjectDataSourceFieldConfigurations).filter(cf=>cf.idObjeto==CatalogObjectTypeConst.LOOKUP && cf.objetoId==lookUp.id),'Origen Dato -->'+x.name)}));
        }
        return treeLookUp;

    }



    const getTreeFromMenu = (data: CustomMenu[]): any[] => {

        var treeMenu = data.map(x => ({
            key: x.id, label: x.label, icon: '',
            data: { value: '[' + ExpressionNomenclatorConst.EXPNOM_MENU + '.' + x.id + '.' + applicationId + '.' + applicationVersion + ']', label: x.label, nomenclator: ExpressionNomenclatorConst.EXPNOM_MENU, path: 'Menu--> ' + getPathFromChildMenu(x, null) }
            , children: x.items ? getTreeFromMenu(x.items as CustomMenu[]) : []
        }))

        return treeMenu;

    };

    const clearData = (filters:string[] | null = null) =>{
        if (filters != null){
            [ExpressionToolConst.EXPTOOL_VAR,ExpressionToolConst.EXPTOOL_CAT,ExpressionToolConst.EXPTOOL_OT,ExpressionToolConst.EXPTOOL_MENU]
            .filter(x=> !filters.includes(x))
            .forEach(key =>{
                var indexVar = tree.value.findIndex((x:any) => x.key == key);
                if (indexVar > -1  ) {
                    tree.value[indexVar].children=[]
                }
            })
        }
        
    }

    const builtTree = (filters:string[] | null = null) => {

        //const tree  = getTreeFromExpToolCatalog();

        clearData(filters);
        

        if (filters?.length  ?? 0> 0){
            filters?.forEach(key=> {
                var indexVar = tree.value.findIndex((x:any) => x.key == key);
                if (indexVar > -1  ) {
                    switch (key) {
                        case ExpressionToolConst.EXPTOOL_VAR:
                            tree.value[indexVar].children = getTreeFromVariable();
                          break;
                          case ExpressionToolConst.EXPTOOL_CAT:
                            tree.value[indexVar].children = getTreeFromEntirelyCatalog();
                          break;
                        case ExpressionToolConst.EXPTOOL_OT:
                            try{
                                if(idObjeto==CatalogObjectTypeConst.LOOKUP){
                                    tree.value[indexVar].children = getTreeFromLookUp();
                                } 
                                else{
                                    tree.value[indexVar].children =  getTreeFromComponentData([externalData]);
                                }
                   
                            }catch(e){                                
                                alert(e);
                            }
                          break;
                          case ExpressionToolConst.EXPTOOL_MENU:
                            tree.value[indexVar].children =getTreeFromMenu(data);
                          break;                       
                      }
                   
                    
                }
            })
        }
        /*
        var indexVar = tree.findIndex(x => x.key == ExpressionToolConst.EXPTOOL_VAR);

        if (indexVar > -1) {
            tree[indexVar].children = getTreeFromVariable();
        }

        var indexCat = tree.findIndex(x => x.key == ExpressionToolConst.EXPTOOL_CAT);

        if (indexCat > -1) {
            tree[indexCat].children = getTreeFromEntirelyCatalog();
        }

        var indexComp = tree.findIndex(x => x.key == ExpressionToolConst.EXPTOOL_OT);

        if (indexComp > -1) {

            tree[indexComp].children = getTreeFromComponentData([externalData]);

        }

        var indexMenu = tree.findIndex(x => x.key == ExpressionToolConst.EXPTOOL_MENU);

        if (indexMenu > -1) {

            tree[indexMenu].children = getTreeFromMenu(data);

        }
*/
        return tree.value;

    };

    const setExternalData = (externalDataNew: WidgetComponentDataTree) =>{
        externalData = externalDataNew;
    }


     tree.value  = getTreeFromExpToolCatalog();

    return {

        builtTree,
        setExternalData

    };
}
