import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IDataSourceServiceConfigurationRepository } from '../domain/IDataSourceServiceConfigurationRepository';

import { IServiceDataSourceServiceConfiguration } from './IServiceDataSourceServiceConfiguration';
import { IapDataSourceServiceConfiguration } from '../domain/iapDataSourceServiceConfiguration';





@injectable()
export class DataSourceServiceConfigurationService implements IServiceDataSourceServiceConfiguration {
  private readonly repo: IDataSourceServiceConfigurationRepository

  public constructor (@inject(TYPES.DATASOURCESERVICECONFIGURATION_REPOSITORY) _repo: IDataSourceServiceConfigurationRepository) {
    this.repo = _repo
  }
    
  async update (data: IapDataSourceServiceConfiguration): (Promise<IapDataSourceServiceConfiguration>) {
    return await this.repo.update(data);
  }

  async delete (dataSourceId: number): (Promise<boolean>) {
    return await this.repo.delete(dataSourceId);
  }


  async add (data: IapDataSourceServiceConfiguration): (Promise<IapDataSourceServiceConfiguration>) {
    return await this.repo.add(data);
  }

  async getById(applicationId:number, applicationVersion:number,datasourceId: number) :Promise<IapDataSourceServiceConfiguration> {
    return await this.repo.getById(applicationId, applicationVersion,datasourceId)
  }


}