/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
import CatalogObjectTypeConst from "../../../catalog/domain/const/CatalogObjectTypeConst";


export default function CrudTableComp(
  props: any,
) {

    const getLayoutComponent = (idType: string) => {        
        //@ts-ignore:disable-next-line
        let data = props.Component.children.find(x => x.idType == idType);
        if (data) {
            const _obj = props.Component.get(data.id,'component');

            data = Object.assign({}, _obj)
             
        }
        return data;

    }


    const hasLayoutComponent = (idType: string) => {

        let data = props.Component.children.find((x:any) => x.idType == idType);
        return data !== undefined;

    }

    const hasLayoutWorkFlow = (idTypeLayout:string, attributteName:string) => {
        
        if (props.showTabMode) {
            const layoutDetail = getLayoutComponent(idTypeLayout);

        if (layoutDetail) {
            const propsIdCmd = layoutDetail.iapComponentAttributes.find((x:any)=> x.name == attributteName)?.id ?? '';
            const hasWf = layoutDetail.workFlows?.find((x:any) => x.idObjeto == CatalogObjectTypeConst.ATTRCOMP && x.objetoId == propsIdCmd && x.active == true)
            if (propsIdCmd && hasWf) {
                return true;
            }
        }
        }

        return false;
    }

  return {
    getLayoutComponent,
    hasLayoutComponent,
    hasLayoutWorkFlow

  };
}
