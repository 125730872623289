import BaseControlTypeConst from "./BaseControlTypeConst"

export default class DataViewTypeConst extends BaseControlTypeConst {

    static VALUE = 'value'
    static LAYOUT = 'layout'
    static ROWS = 'rows'
    static FIRST = 'first'
    static TOTALRECORDS = 'totalRecords'
    static PAGINATOR = 'paginator'
    static PAGINATORPOSITION = 'paginatorPosition'
    static ALWAYSSHOWPAGINATOR = 'alwaysShowPaginator'
    static PAGINATORTEMPLATE = 'paginatorTemplate'
    static PAGELINKSIZE = 'pageLinkSize'
    static ROWSPERPAGEOPTIONS = 'rowsPerPageOptions'
    static CURRENTPAGEREPORTTEMPLATE = 'currentPageReportTemplate'
    static SORTFIELD = 'sortField'
    static SORTORDER = 'sortOrder'
    static LAZY = 'lazy'
    static DATAKEY = 'dataKey'
    static PT = 'pt'
    static UNSTYLED = 'unstyled'
    static LAYOUT_FOOTER = 'layouttemp-dview-footer'
    static LAYOUT_HEADER = 'layouttemp-dview-header'
    static LAYOUT_PAGINATORSTART = 'layouttemp-dview-paginatorstart'
    static LAYOUT_PAGINATOREND = 'layouttemp-dview-paginatorend'
    static LAYOUT_EMPTY = 'layouttemp-dview-empty'
    static LAYOUT_LIST = 'layouttemp-dview-list'
    static LAYOUT_GRID = 'layouttemp-dview-grid'


}
