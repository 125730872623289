import DataSourceConst from "../../../entidades/builder/crud/infrastructure/functions/dataSourceConst";

export const getVNodeProp = (vnode:any, prop:any) => {
    const props = vnode.props;
    if (props) {
        const kebapProp = prop.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
        const propName = Object.prototype.hasOwnProperty.call(props, kebapProp) ? kebapProp : prop;

        return ((vnode.type.props[prop].type === Boolean && props[propName] === '') ? true : props[propName]);
    }

    return null;
}

export const columnProp = (col:any, prop:any) => {
    return getVNodeProp(col, prop);
}

export const isFunction = (obj:any) => {
    return !!(obj && obj.constructor && obj.call && obj.apply);
}

export const resolveFieldData = (data:any, field:any,moneyColumns:string[]=[]) => {
    if (data && Object.keys(data).length && field) {
        if (isFunction(field)) {
            return field(data);
        }
        else if (field.indexOf('.') === -1) {
            if(moneyColumns.includes(field.replace(DataSourceConst.DELIMITERFIELDFORMAT,''))){
                return data[field.replace(DataSourceConst.DELIMITERFIELDFORMAT,'')]
            }
            return data[field];
        }
        else {
            const fields = field.split('.');
            let value = data;
            for (let i = 0, len = fields.length; i < len; ++i) {
                if (value == null) {
                    return null;
                }
                value = value[fields[i]];
            }
            return value;
        }
    }
    else {
        return null;
    }
}



export default{
    getVNodeProp,
    columnProp,
    isFunction,
    resolveFieldData
}