import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';

import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { IWorkFlowRepository } from '../domain/repositorry/IWorkFlowRepository';
import { IapWorkFlow } from '../domain/service/iapWorkFlow';
import { GET_BY_OBJECTID, GET_WORKFLOW_BY_ID } from './query/workFlowQuery';
import { ADD, UPDATE, DELETE_BY_OBJECTID } from './mutation/workFlowMutation';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';





@injectable()
export class WorkFlowApolloClientRepository implements IWorkFlowRepository {
  private apolloClient : MyApolloClient
  private apolloClientId: string
  constructor (apolloInstance: MyApolloClient, _apolloClientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.apolloClientId = _apolloClientId
  }

  
   async delete (id: number): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE, { id:id }, this.apolloClientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.workFlowMutation.deleteWorkFlow)
        }
        catch(e) {
          reject()
        }
      });

    });
  }


  async update (dataInput: IapWorkFlow): (Promise<IapWorkFlow>) {
    return new Promise<IapWorkFlow>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE, { data:dataInput }, this.apolloClientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.workFlowMutation.updateWorkFlow)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async add (dataInput: IapWorkFlow): (Promise<IapWorkFlow>) {
    return new Promise<IapWorkFlow>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD, { data:dataInput }, this.apolloClientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value)
          resolve(value.workFlowMutation.addWorkFlow)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async getByObjectId (idObject: string, objectId: string): (Promise<IapWorkFlow>) {
    return new Promise<IapWorkFlow>(async (resolve, reject) => {
      
      const result = this.apolloClient.executeQuery(GET_BY_OBJECTID, { idObjeto: idObject,objetoId:objectId }, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.workFlowQuery.getByObjectId)
        }
        catch(e) {
          reject()
        }
      });
      
    });

  }

  async deleteWorkFlowByObjectId (applicationId: number, applicationVersion: number, idObject: string, objectId: string) : (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      
      const result = this.apolloClient.executeQuery(DELETE_BY_OBJECTID, {applicationId:applicationId,applicationVersion:applicationVersion, idObject: idObject,objectId:objectId }, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.workFlowMutation.deleteWorkFlowByObjectId)
        }
        catch(e) {
          reject()
        }
      });
      
    });

  }

  async getById (id: number): (Promise<IapWorkFlow>) {
    return new Promise<IapWorkFlow>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_WORKFLOW_BY_ID, { id: id}, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value)
          resolve(value.workFlowQuery.getById)
        }
        catch(e) {
          reject()
        }
      });
      
    });
  }

}


