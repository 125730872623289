import BaseControlTypeConst from "./BaseControlTypeConst"

export default class SelectButtonTypeConst extends BaseControlTypeConst {

    //static BINARY = 'binary'
    static READONLY = 'readonly'
    //static REQUIRED = 'required'

    static TABINDEX = 'tabIndex'
    static OPTIONS = 'options'
    static OPTIONLABEL = 'optionLabel'
    static OPTIONVALUE = 'optionValue'
    static OPTIONDISABLED = 'optionDisabled'
    static MULTIPLE = 'multiple'
    static INVALID = 'invalid'
}