<template>
    
</template>
<script lang="ts">

import { defineComponent, onMounted, onUnmounted} from 'vue';
import { useStore } from 'vuex';
import { Container } from 'inversify'
import HelperLoading from '../../../../common/infrastructure/funciones/HelperLoading';
import { IServiceConfiguration } from '../../../builderModel/configuration/application/IServiceConfiguration';
import { TYPES } from '../../../../common/domain/types';
import { IapmoConfiguration } from '../../../builderModel/configuration/domain/iapmoConfiguration';
import { Mutations } from '../../../../common/infrastructure/almacen/enums/StoreEnums';
import CatalogCfgUbmChatConst from '../../catalog/domain/const/CatalogCfgUbmChatConst';
import EventConst from '../../../../common/domain/constantes/EventConst';
import { ContactChat } from '../domain/contactChat';
import { email } from '@vuelidate/validators';
import EventBusCustom from '../../../../common/infrastructure/event-bus-custom';

export default defineComponent({
    name: 'ubmchat',
    props: {
        container: {
            type: Object as () => Container
        },
    },
    setup(props) {
        const store = useStore()
        
        const loadCfg = () => {
            if (props.container){

            
                    const _srv = props.container.get<IServiceConfiguration>(TYPES.CONFIGURATION_REPOSITORY)
                    HelperLoading.showLoading()
                   

                    _srv.all().then(response => {
                        store.commit(Mutations.SET_ENTITYMAIN_CFG,response);
                        
                    })
                        .finally(() => {                            
                            HelperLoading.hideLoading()
                            initializeChat();
                            eventChat();
                        })
        }
        }


const doEventChat = (data:ContactChat) => {
                        try{
                            initializeChat(data)                
                        }
                        finally{
                            HelperLoading.hideLoading()
                        }
                        
                }



        const eventChat = () =>
        {
            if (store.getters.entityMainCfgValue(CatalogCfgUbmChatConst.CFGUBMCHAT_ENABLE)?.toLowerCase().trim() == 'true'){
                EventBusCustom.on(EventConst.SHOWCHATUBM,doEventChat);
            }

        }

        const initializeChat = (data: ContactChat| null = null) => {

            if (store.getters.entityMainCfgValue(CatalogCfgUbmChatConst.CFGUBMCHAT_ENABLE)?.toLowerCase().trim() == 'true'){
                var
                    var2init = {
                        assessor_first_name: store.getters.entityMainCfgValue(CatalogCfgUbmChatConst.CFGUBMCHAT_ASNAME) ?? '',
                        assessor_last_name: store.getters.entityMainCfgValue(CatalogCfgUbmChatConst.CFGUBMCHAT_ASLASTNAME) ?? '',
                        assessor_email:store.getters.entityMainCfgValue(CatalogCfgUbmChatConst.CFGUBMCHAT_ASEMAIL) ?? '',
                        source:store.getters.entityMainCfgValue(CatalogCfgUbmChatConst.CFGUBMCHAT_SOURCE) ?? '',
                        company:store.getters.entityMainCfgValue(CatalogCfgUbmChatConst.CFGUBMCHAT_CIA) ?? '',
                        unit:store.getters.entityMainCfgValue(CatalogCfgUbmChatConst.CFGUBMCHAT_UNIT) ?? '',
                        developer:false,
                        show_userlist:store.getters.entityMainCfgValue(CatalogCfgUbmChatConst.CFGUBMCHAT_SUL)?.toLowerCase().trim() == 'true',
                        minimized:store.getters.entityMainCfgValue(CatalogCfgUbmChatConst.CFGUBMCHAT_MIN)?.toLowerCase().trim() == 'true',
                        /*phone : "+34XXXXX",// string no Formato internacional
                        client_name : "XXXXX" , //string si Nombre del cliente
                        client_lastname: "XXX", // string si Apellidos del cliente
                        client_email: "XXXX@intrasoft.es" 
                        */

                    } as any;

                    if (data != null){
                        var2init['phone']=data.phone
                        var2init['client_name']=data.client_name
                        var2init['client_lastname']=data.client_lastname
                        var2init['client_email']=data.client_email
                        var2init.minimized=false
                    }

                
                //@ts-ignore:disable-next-line
                initChat(var2init);
                
                
            }
               
        }


        onMounted(() => {
            if (store.getters.isUserAuthenticated) {
                loadCfg();
            }

        })
        
        onUnmounted(() => {            
            if (store.getters.entityMainCfgValue(CatalogCfgUbmChatConst.CFGUBMCHAT_ENABLE)?.toLowerCase().trim() == 'true')
            {
                EventBusCustom.off(EventConst.SHOWCHATUBM,doEventChat);

                //@ts-ignore:disable-next-line
                closeChat()

                
               
            }
            
        })

        return {
            
        };
    },
});
</script>
<style scoped></style>
