import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import { IComponentEventRepository } from '../domain/IComponentEventRepository';

import { UPDATE, ADD,DELETE } from './mutation/componentEventMutation';
import { IapComponentEvent } from '../domain/iapComponentEvent';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { GET_ALL_BY_COMPONENTID, GET_EVENT_BY_ID } from './query/componentEventQuery';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class ComponentEventApolloClientRepository implements IComponentEventRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }

  async delete (id: string): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(DELETE, { id: id }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentEventMutation.deleteComponentEvent)
        }
        catch(e) {
          reject()
        }
      });
    });
  }
  
  
  

  async update (dataInput: IapComponentEvent): (Promise<IapComponentEvent>) {
    return new Promise<IapComponentEvent>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentEventMutation.updateComponentEvent)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async add (dataInput: IapComponentEvent): (Promise<IapComponentEvent>) {
    return new Promise<IapComponentEvent>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentEventMutation.addComponentEvent)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

 

  async getAllByComponentId(componentId: number): (Promise<IapComponentEvent[]>) {
    return new Promise<IapComponentEvent[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_ALL_BY_COMPONENTID, { componentId: componentId }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentEventQuery.getAllByComponentId)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getById (id: number): (Promise<IapComponentEvent>) {
    return new Promise<IapComponentEvent>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_EVENT_BY_ID, { eventId: id }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentEventQuery.getById)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


}


