import gql from 'graphql-tag';

export const UPDATE = gql(`
mutation($data: ObjectDataSourceFieldConfigurationInputType!){
    objectDataSourceFieldConfigurationMutation{
        updateObjectDataSourceFieldConfiguration(data:$data){
            idObjeto,
            objetoId,
            dataSourceFieldId,
            defaultFilterSearch,
            defaultFilterSearchOrder,
            edit,
            add,
            editMassive,
            import,
            fcr,
            filterResult,
            filterResultOrder,
            filterSearch,
            filterSearchOrder,
            fum,
            list,
            view,
            mandatory,
            sortOrder,
            idSortType,
            aggregateOperation
            idFilterType
            idFilterControlType
            idDefaultMatchMode
            defaultFilterValue,
            cellStyle,
            displayContentMaxlength,
            aggregateOperation,            
            autoUpdate,
            defaultValue,
            defaultValidation
        }
    }
}
`);

export const ADD = gql(`
mutation($data: ObjectDataSourceFieldConfigurationInputType!){
    objectDataSourceFieldConfigurationMutation{
        addObjectDataSourceFieldConfiguration(data:$data){
            idObjeto,
            objetoId,
            dataSourceFieldId,
            defaultFilterSearch,
            defaultFilterSearchOrder,
            edit,
            add,
            editMassive,
            import,
            fcr,
            filterResult,
            filterResultOrder,
            filterSearch,
            filterSearchOrder,
            fum,
            list,
            view,
            mandatory,
            sortOrder,
            idSortType,
            aggregateOperation
            idFilterType
            idFilterControlType,
            idDefaultMatchMode
            defaultFilterValue, 
            cellStyle, 
            displayContentMaxlength,
            aggregateOperation,          
            autoUpdate,
            defaultValue,
            defaultValidation
        }
    }
}
`);

