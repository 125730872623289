import BaseControlTypeConst from "./BaseControlTypeConst"

export default class ChipTypeConst extends BaseControlTypeConst {
    static MAX='max'
    static ADDONBLUR='addOnBlur'
    static ALLOWDUPLICATE='allowDuplicate'
    static SEPARATOR='separator'
    static INPUTID='inputId'
    static READONLY = 'readonly'

}
