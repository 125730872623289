<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <img v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(ImageTypeConst.ID)" v-tooltip="getPropertyValue(ImageTypeConst.TOOLTIP)"
        :style="getPropertyValue(ImageTypeConst.STYLE)" :class="getPropertyValue(ImageTypeConst.CLASS)"
        :name="getPropertyValue(ImageTypeConst.NAME)" :disabled="getPropertyBooleanValue(ImageTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(ImageTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :placeholder="getPropertyValue(ImageTypeConst.PLACEHOLDER)" :src="getPropertyValue(ImageTypeConst.SRC)"
        :alt="getPropertyValue(ImageTypeConst.ALT)" :width="getPropertyValue(ImageTypeConst.WIDTH)"
        :height="getPropertyValue(ImageTypeConst.HEIGHT)" @click="processEventComponent(CatalogEventConst.CLICK)" />
</template>
<script lang="ts">


import { defineComponent, onMounted, onUnmounted } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import ImageTypeConst from '../../../../domain/Constants/ImageTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_image',
    components: {
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },      
        slotProps: {
            type: Object,
            default: () => ({})
        },      
    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded,  resolveExpressions, baseOnUnmount 
            ,fieldKey,fieldKeyComponentDataSourceId, processEventComponent,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);


        
        onMounted(() => {
           
        })

        onUnmounted(() => {
            baseOnUnmount();
        })


        return {          
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            ImageTypeConst,
            loaded,
            BaseControlTypeConst,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,processEventComponent
            ,CatalogEventConst
            ,componentKey
        };
    },
});
</script>
<style scoped></style>
