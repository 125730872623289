import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: ComponentDataSourceServiceConfigurationInputType!){
    componentDataSourceServiceConfigurationMutation{
        updateComponentDataSourceServiceConfiguration(data:$data){
            componentDataSourceId,
            dataSourceServiceConfigurationId,
            idServiceConfigurationType,
            key,
            value,
            description,
            fcr,
            ucr,
            uum,
            fum
        }
    }
}
`);

export const ADD = gql(`
mutation($data: ComponentDataSourceServiceConfigurationInputType!){
    componentDataSourceServiceConfigurationMutation{
        addComponentDataSourceServiceConfiguration(data:$data)
    }
}
`);



