import gql from 'graphql-tag';

export const GET_ALL = gql(`
query {
    configurationQuery {
        getAll {
            id
            idKey
            value
            fcr
            fum
            ucr
            uum
    }
  }
}
`);

