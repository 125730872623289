import gql from 'graphql-tag';

export const GET_ALL_DATASOURCEFIELD = gql(`
query($dataSourceId: Int!) {
    dataSourceFieldQuery {
      getAllByDataSourceId(dataSourceId:$dataSourceId) {
        id,
        dataSourceId,
        tableName,
        field,
        tableNameAlias,
        fieldAlias,
        primaryKey,
        foreignKey,
        identityColumn,
        dataSourceTableAliasId,
        dataSourceLookUpId
        position,
        defaultValue,
        nullable,
        encrypted,
        sqlType,
        length,
        precision,
        idDataType,
        catalogTypeId,
        foreignTableName,
        foreignTableFieldId,
        foreignTableFieldDescription,
        shortDescription,
        description,
        localizable,
        computed,
      }
    }
}
`);

export const GET_DATASOURCEFIELD_BY_ID = gql(`
query($dataSourceId: Int!) {
    dataSourceFieldQuery {
      getById(dataSourceId:$dataSourceId) {
        id,
        applicationId,
        applicationVersion,
        idDataSourceType,
        Name,
        Description,        
        fcr,
        ucr,
        uum,
        fum
      }
    }
}
`);


export const GET_MAXALL_DATASOURCEFIELD = gql(`
query($maxregs: Int!) {
    dataSourceFieldQuery {
      getAll(maxregs:$maxregs) {
        id,
        dataSourceId,
        tableName,
        field,
        tableNameAlias,
        fieldAlias,
        primaryKey,
        foreignKey,
        identityColumn,
        dataSourceTableAliasId
        dataSourceLookUpId
        position,
        defaultValue,
        nullable,
        encrypted,
        sqlType,
        length,
        precision,
        idDataType,
        catalogTypeId,
        foreignTableName,
        foreignTableFieldId,
        foreignTableFieldDescription
        shortDescription
        description
        localizable
        computed
        fcr
        ucr
        fum
        uum
    }
}
}
`);

