import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_CustomValidate = _resolveComponent("CustomValidate")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? _withDirectives((_openBlock(), _createBlock(_component_RadioButton, {
          key: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.RadioButtonTypeConst.ID),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.RadioButtonTypeConst.STYLE)),
          class: _normalizeClass({ [_ctx.getPropertyValue(_ctx.RadioButtonTypeConst.CLASS) ?? '']: true, 'p-invalid': _ctx.v$.vmodel.$error && _ctx.submitted,'p-readonly': _ctx.getPropertyBooleanValue(_ctx.InputTextTypeConst.READONLY) && !_ctx.canDoOperation(_ctx.ObjectGroupConst.EDIT) }),
          name: _ctx.getPropertyValue(_ctx.RadioButtonTypeConst.NAME),
          disabled: _ctx.getPropertyBooleanValue(_ctx.RadioButtonTypeConst.DISABLED) || (!_ctx.canEdit),
          visible: _ctx.getPropertyBooleanValue(_ctx.RadioButtonTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW),
          placeholder: _ctx.getPropertyValue(_ctx.RadioButtonTypeConst.PLACEHOLDER),
          value: _ctx.getPropertyValue(_ctx.RadioButtonTypeConst.VALUE),
          inputId: _ctx.getPropertyValue(_ctx.RadioButtonTypeConst.INPUTID),
          modelValue: _ctx.vmodel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vmodel) = $event))
        }, null, 8 /* PROPS */, ["id", "style", "class", "name", "disabled", "visible", "placeholder", "value", "inputId", "modelValue"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.RadioButtonTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true),
    _createVNode(_component_CustomValidate, {
      submitted: _ctx.submitted,
      "onUpdate:submitted": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.submitted) = $event)),
      vObject: _ctx.v$,
      "onUpdate:vObject": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$) = $event))
    }, null, 8 /* PROPS */, ["submitted", "vObject"])
  ], 64 /* STABLE_FRAGMENT */))
}