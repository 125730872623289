<template>
    <Dialog position="center" v-model:visible="visibleModal" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '75vw' }" :modal="true" :maximizable="true" :closable="true" :closeOnEscape="true">
        <template #header>
            <h5><i class="pi pi-file" aria-hidden="true"></i> Vista previa del documento</h5>
        </template>

        <Toolbar>
            <template #end>
                <!--
                <Button icon="pi pi-plus-circle" v-tooltip="'Ampliar'" class="p-button-rounded p-button-secondary mr-2" @click="widthComponent = widthComponent-10"/>
                <Button icon="pi pi-minus-circle" v-tooltip="'Alejar'" class="p-button-rounded p-button-secondary mr-2" @click="widthComponent = widthComponent+10"/>
                -->
                <Button id="imprimir" icon="pi pi-print" v-tooltip="'Imprimir fichero'" class="p-button p-component p-button-icon-only p-button-rounded p-button-outlined" @click="printFile"/>
                <Button id="descargar" icon="pi pi-download" v-tooltip="'Descargar fichero'" class="p-button p-component p-button-icon-only p-button-rounded p-button-outlined ml-2" @click="downloadFile"/>
            </template>
        </Toolbar>

        
        <!--<iframe v-if="isVueDoc" :src="buildOfficeUrl()" width='100%' height='100%' frameborder='0'>This is an embedded <a target='_blank' href='http://office.com'>Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps'>Office Online</a>.</iframe> -->

        <!--<VueDocPreview v-if="isVueDoc" :value="internalBase64ToArrayBuffer(document.content)" :type="getDoctype" />-->        
        <vue-pdf-embed ref="pdfembed" v-if="isPdf" :source="getUrl(document)"></vue-pdf-embed>
        <div ref="dvCanvas" v-else-if="isCanvas"></div>
        <Message v-else severity="info">Descargando fichero...</Message>
    </Dialog>
</template>
<script lang="ts">

import { computed, defineComponent, onMounted, ref, watch } from 'vue';
//import VueDocPreview from 'vue-doc-preview';
import VuePdfEmbed from 'vue-pdf-embed'


export default defineComponent({
    name: 'documentviewer',
    props: ['document','canvas'],
    components: {
        //VueDocPreview,
        VuePdfEmbed
    },
    setup(props, { emit }) {
        const widthComponent = ref(400);
        const dvCanvas = ref();
        const pdfembed = ref();
        const visibleModal = ref(true);
        const extensionsText = ['txt'];
        const extensionsOffice = ['csv', 'xls', 'doc', 'docx', 'pptx', 'xlsx'];
        const extensionsCode = ['js', 'html', 'css', 'java', 'json', 'ts', 'cpp', 'xml', 'bash', 'less', 'nginx', 'php', 'powershell',
            'python', 'scss', 'shell', 'sql', 'yaml', 'ini'];



        watch(
            visibleModal, () => {
                emit('update:Visibility', visibleModal.value)
            },
            {
                flush: 'post',
                //immediate: true, 
                deep: false
            }
        )

        const isVueDoc = computed(() => {

            const type = docType.value ?? '';
            return extensionsText.includes(type)
                || extensionsOffice.includes(type)
                || extensionsCode.includes(type);

        })

        const getDoctype = () => {
            const type = docType.value ?? '';
            if (extensionsText.includes(type)) {
                return 'text';
            }

            if (extensionsOffice.includes(type)) {
                return 'office';
            }

            if (extensionsCode.includes(type)) {
                return 'code';
            }

            return 'md';
        }

        const isPdf = computed(() => {
            return (docType.value == 'pdf');
        })

        const isCanvas = computed(() => {
            return props.canvas;
        })

        const docType = computed(() => {
            if (props.document.fileName) {
                const fileExt = props.document.fileName.split('.').pop();
                return fileExt?.toLocaleLowerCase();
            }
            return '';

        })

        const getUrl = (doc:any) =>{
            const type = (doc.contentType ?? (doc.contentType ?? '' == '' ? 'application/octet-stream' : doc.contentType));
            const fileURL = "data:" + type + ";base64," + doc.content;
            return fileURL;
        }

        const  internalBase64ToArrayBuffer = (base64:any) => {
            var binary_string = window.atob(base64);
            var len = binary_string.length;
            var bytes = new Uint8Array(len);
            for (var i = 0; i < len; i++) {
                bytes[i] = binary_string.charCodeAt(i);
            }
            return bytes.buffer;
        }


const downloadFile = () =>
{
    var type = '';
    var fileURL = '';
    var fileName ='';

    if (isCanvas.value){
        fileName='Documento.png';
        type = 'application/octet-stream';
        fileURL = props.canvas.toDataURL('img/png');// "data:" + type + ";base64," + props.document.content;
    }
    else{
        fileName=props.document.fileName;
        type = (props.document.contentType || props.document.contentType == '' ? 'application/octet-stream' : props.document.contentType);
        fileURL = "data:" + type + ";base64," + props.document.content;
    }

            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
}

const printFile = ()=>{
    if (pdfembed.value){
        pdfembed.value.print(800,props.document.fileName);
    }
    else
    {
        if (isCanvas.value ){
            printCanvas();
        }
    }
}

function printCanvas()  
{  
    var dataUrl =props.canvas.toDataURL();// document.getElementById('anycanvas').toDataURL(); //attempt to save base64 string to server using this var  
    var windowContent = '<!DOCTYPE html>';
    windowContent += '<html>'
    windowContent += '<head><title>Imprimir documento</title></head>';
    windowContent += '<body>'
    windowContent += '<img src="' + dataUrl + '">';
    windowContent += '</body>';
    windowContent += '</html>';
    var printWin = window.open('', '', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no');
    if (printWin){
        printWin.document.open();
        printWin.document.write(windowContent);
        printWin.document.close();
        printWin.focus();
        printWin.print();
        //printWin.close();
    }    
}

const buildOfficeUrl = () =>{
    //return 'https://view.officeapps.live.com/op/embed.aspx?src="data:application/msword;base64,' + props.document.content + '"';
    return 'https://view.officeapps.live.com/op/embed.aspx?src=https://willplatine.intrasoft.es/portal/rfps/rfp_document.aspx?query=aUOxfdtLiQ1huW7ai62UUuE7RIbiPiN9l6sZMlBXAleJz2RL0IIfjCdzc7oZ1QQH8Ap7oepd3Ou7n0qLfxI6sKlC1SoMzvJJJpmFO9XNQsZ8nzIA2LMRwuhpd9uXthVhClaYZm2vlNd7ixDSNtz8FwLSzgvAiINVcrA9mx4Ldhi%2bLxR8KeVlqwnDZjPCltHYQCzA4CmF5bH%2bSxhcEIUH8Qe1YeUGdlQLClNBG%2fvQoLU%3d';
}

const getWidthComponent = computed(() =>{
    return widthComponent.value.toString() + 'px';
})

    onMounted(()=>{
        if (isCanvas.value){
            dvCanvas.value.appendChild(props.canvas);
        }
        else{
            if (!isPdf.value ){
                downloadFile();
                visibleModal.value= false;
            }
        }        
    })

        return {
            docType,
            extensionsText,
            extensionsOffice,
            extensionsCode,
            visibleModal,
            isVueDoc,
            getDoctype,
            isPdf,
            getUrl,
            internalBase64ToArrayBuffer,
            downloadFile,
            printFile,
            buildOfficeUrl,
            widthComponent,
            getWidthComponent,
            pdfembed,
            isCanvas,
            dvCanvas
        
        };
    },
});
</script>

