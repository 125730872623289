import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'
import { IServiceWorkFlowActivityControl } from './interface/IServiceWorkFlowActivityControl'
import type { IWorkFlowActivityControlRepository } from '../domain/repositorry/IWorkFlowActivityControlRepository'
import { IapWorkFlowActivityControl } from '../domain/service/iapWorkFlowActivityControl'



@injectable()
export class WorkFlowActivityControlService implements IServiceWorkFlowActivityControl {
  private readonly repo: IWorkFlowActivityControlRepository

  public constructor (@inject(TYPES.WORKFLOWACTIVITYCONTROL_REPOSITORY) _repo: IWorkFlowActivityControlRepository) {
    this.repo = _repo
  }

  async update (data: IapWorkFlowActivityControl): (Promise<IapWorkFlowActivityControl>) {
    return await this.repo.update(data);
  }

  async add (data: IapWorkFlowActivityControl): (Promise<IapWorkFlowActivityControl>) {
    return await this.repo.add(data);
  }

  async delete (data: IapWorkFlowActivityControl): (Promise<boolean>) {
    return await this.repo.delete(data);
  }
  
  async getAll(maxregs: number | undefined) :Promise<IapWorkFlowActivityControl[]> {
    return await this.repo.getAll(maxregs)
  }

  async getById(id: number) :Promise<IapWorkFlowActivityControl> {
    return await this.repo.getById(id)
  }

  
}
