import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IUserOptionRepository } from '../domain/IUserOptionRepository';
import { IServiceUserOption } from './IServiceUserOption';
import { IapmUserOption } from '../domain/iapmUserOption';



@injectable()
export class ServiceUserOption implements IServiceUserOption {
  private readonly repo: IUserOptionRepository

  public constructor (@inject(TYPES.USER_OPTION_REPOSITORY) _repo: IUserOptionRepository) {
    this.repo = _repo
  }
   
  async getUserOption (applicationId:number, applicationVersion:number, userId:number, optionId:string) : Promise<IapmUserOption> {
    return await this.repo.getUserOption(applicationId, applicationVersion, userId, optionId);
  }

  async getUserOptions (applicationId:number, applicationVersion:number, userId:number) : Promise<IapmUserOption[]> {
    return await this.repo.getUserOptions(applicationId, applicationVersion, userId);
  }

  async getCurrentUserOptions () : Promise<IapmUserOption[]> {
    return await this.repo.getCurrentUserOptions();
  }

  async getFilteredUserOptions (applicationId:number, applicationVersion:number, userId:number, filter:string) : Promise<IapmUserOption[]> {
    return await this.repo.getFilteredUserOptions(applicationId, applicationVersion, userId, filter);
  }

  async setUserOption (applicationId:number, applicationVersion:number, userId:number, optionId:string, value:string) : Promise<IapmUserOption> {
    return await this.repo.setUserOption(applicationId, applicationVersion, userId, optionId, value);
  }

  async setUserOptionData (applicationId:number, applicationVersion:number, data:IapmUserOption) : Promise<IapmUserOption> {
    return await this.repo.setUserOptionData(applicationId, applicationVersion, data);
  }

  async setUserOptionsData (applicationId:number, applicationVersion:number, data:IapmUserOption[]) : Promise<IapmUserOption[]> {
    return await this.repo.setUserOptionsData(applicationId, applicationVersion, data);
  }

  async deleteUserOptionData (applicationId:number, applicationVersion:number, data:IapmUserOption) : Promise<boolean> {
    return await this.repo.deleteUserOptionData(applicationId, applicationVersion, data);
  }

  async deleteUserOption (applicationId:number, applicationVersion:number, userId:number, optionId:string) : Promise<boolean> {
    return await this.repo.deleteUserOption(applicationId, applicationVersion, userId, optionId);
  }

  async deleteUserOptionsData (applicationId:number, applicationVersion:number, data:IapmUserOption[]) : Promise<boolean> {
    return await this.repo.deleteUserOptionsData(applicationId, applicationVersion, data);
  }

  async resetUserOptionsData (applicationId:number, applicationVersion:number, data:IapmUserOption[]) : Promise<boolean> {
    return await this.repo.resetUserOptionsData(applicationId, applicationVersion, data);
  }
}