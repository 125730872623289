export default class DataSourceConst {
  static CAT = "Catalogo";
  static DELIMITER = ".-->.";
  static DELIMITERFIELDS = "_";
  static DELIMITERFIELDFORMAT = "_colF";
  static DELIMITERFIELDLOOKUPID = "_colIdLookUp";
  static DELIMITERFIELDLOOKUPDESCRIPTION = "_colDescLookUp";
  static AGGREGATE_TABLE = "CUSTOMAGGREGATTE";

  static LOOKUPID = "LookUpId";
  static LOOKUPDESC = "LookUpDescription";

  static LOOKUPMODELVALUE="lookUpModelValue";

  static DELIMITERFIELDLKFILTER="_lookUpFilter"


}