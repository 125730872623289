import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';

import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import { UPDATE, ADD, DELETE } from './mutation/expressionDetailMutation';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { IapExpressionDetail } from '../domain/iapExpressionDetail';
import { IExpressionDetailRepository } from '../domain/IExpressionDetailRepository';
import { GET_ALL, GET_BY_ID } from './query/expressionDetailQuery';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class ExpressionDetailApolloClientRepository implements IExpressionDetailRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }
  
  

  async update (dataInput: IapExpressionDetail): (Promise<IapExpressionDetail>) {
    return new Promise<IapExpressionDetail>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.expressionDetailMutation.updateVariable)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async add (dataInput: IapExpressionDetail): (Promise<IapExpressionDetail>) {
    return new Promise<IapExpressionDetail>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.expressionDetailMutation.addVariable)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async delete (id: string): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(DELETE, { id: id }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.expressionDetailMutation.deleteVariable)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getAll (expressionId: string): (Promise<IapExpressionDetail[]>) {
    return new Promise<IapExpressionDetail[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_ALL, { expressionId: expressionId },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.expressionDetailQuery.getAll)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getById (id: string): (Promise<IapExpressionDetail>) {
    return new Promise<IapExpressionDetail>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_BY_ID, { id: id }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.expressionDetailQuery.getById)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


}


