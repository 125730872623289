import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-12"
}
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "p-fluid formgrid grid" }
const _hoisted_4 = { key: 7 }
const _hoisted_5 = { class: "p-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_LookUpEditor = _resolveComponent("LookUpEditor")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_Message = _resolveComponent("Message")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" {{ dataInput}} "),
    (_ctx.dataInput?.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataInput, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.fieldId + index.toString(),
                  class: "field col-12 md:col-3"
                }, [
                  _createElementVNode("div", null, [
                    _createElementVNode("label", {
                      for: "question",
                      class: _normalizeClass({ 'p-invalid': _ctx.v$.items.$each.$response.$errors[index].value.length > 0 && _ctx.submitted, 'customrequired': item.required })
                    }, _toDisplayString(_ctx.getColumnName(item.fieldId)), 3 /* TEXT, CLASS */),
                    (_ctx.isColumnType(item.fieldId, _ctx.SqlTypesConst.BIT))
                      ? (_openBlock(), _createBlock(_component_InputSwitch, {
                          key: 0,
                          modelValue: item.value,
                          "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                          trueValue: true,
                          falseValue: false
                        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
                      : (_ctx.hasLookUpByFieldId(item.fieldId))
                        ? (_openBlock(), _createBlock(_component_LookUpEditor, {
                            key: 1,
                            dataSourceLookUp: _ctx.getLookUpByFieldId(item.fieldId),
                            modelValue: item.value,
                            "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                            container: _ctx.container,
                            "onChange:SelectedLookUpData": ($event: any) => (_ctx.selectedLookUpData(item.fieldId, $event)),
                            catalogsData: _ctx.catalogsData,
                            filterConditions: item.lookUpFilter.lookUpFilter,
                            Component: _ctx.Component
                          }, null, 8 /* PROPS */, ["dataSourceLookUp", "modelValue", "onUpdate:modelValue", "container", "onChange:SelectedLookUpData", "catalogsData", "filterConditions", "Component"]))
                        : (_ctx.isColumnType(item.fieldId, _ctx.SqlTypesConst.DATE) || _ctx.isColumnType(item.fieldId, _ctx.SqlTypesConst.DATETIME))
                          ? (_openBlock(), _createBlock(_component_Calendar, {
                              key: 2,
                              modelValue: item.value,
                              "onUpdate:modelValue": ($event: any) => ((item.value) = $event)
                            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
                          : (_ctx.isColumnType(item.fieldId, _ctx.SqlTypesConst.INT))
                            ? (_openBlock(), _createBlock(_component_InputNumber, {
                                key: 3,
                                modelValue: item.value,
                                "onUpdate:modelValue": ($event: any) => ((item.value) = $event)
                              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
                            : (_ctx.isColumnType(item.fieldId, _ctx.SqlTypesConst.DECIMAL))
                              ? (_openBlock(), _createBlock(_component_InputNumber, {
                                  key: 4,
                                  modelValue: item.value,
                                  "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                  mode: "currency"
                                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
                              : (_ctx.isCatalogTypeValue(item.fieldId))
                                ? (_openBlock(), _createBlock(_component_Dropdown, {
                                    key: 5,
                                    modelValue: item.value,
                                    "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                    options: _ctx.getCatalogData(item.fieldId),
                                    optionLabel: "description",
                                    optionValue: "id",
                                    filter: "",
                                    showClear: ""
                                  }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options"]))
                                : (_openBlock(), _createBlock(_component_InputText, {
                                    key: 6,
                                    modelValue: item.value,
                                    "onUpdate:modelValue": ($event: any) => ((item.value) = $event)
                                  }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])),
                    (_ctx.submitted)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.items.$each.$response.$errors[index].value, (error) => {
                            return (_openBlock(), _createElementBlock("span", { key: error }, [
                              _createElementVNode("small", _hoisted_5, _toDisplayString(error.$response.$message), 1 /* TEXT */)
                            ]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]))
                      : _createCommentVNode("v-if", true)
                  ])
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_Toolbar, null, {
                end: _withCtx(() => [
                  _createVNode(_component_Button, {
                    id: "guardar",
                    label: "Guardar",
                    icon: "pi pi-save",
                    class: "mr-2 p-button-rounded p-button-primary p-button-outlined",
                    onClick: _ctx.saveData
                  }, null, 8 /* PROPS */, ["onClick"])
                ]),
                _: 1 /* STABLE */
              })
            ])
          ])
        ]))
      : (_openBlock(), _createBlock(_component_Message, {
          key: 1,
          severity: "warn",
          closable: false
        }, {
          default: _withCtx(() => [
            _createTextVNode("El origen de datos no tiene ninguna configuración para añadir registros")
          ]),
          _: 1 /* STABLE */
        }))
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}