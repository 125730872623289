
import gql from 'graphql-tag';

/*export const ADD = gql(`
mutation(
    $data: DataSourceInputType!
    $tables: [DataSourceTableAliasInputType]!
    $db: DataSourceDataBaseInputType
    $srv: DataSourceServiceInputType
    $fields: [DataSourceFieldInputType]!
 ){
    dataSourceMutation{
        add(data:$data, tables:$tables,db:$db,srv:$srv,fields:$fields){
            applicationId,
            applicationVersion,
            name,
            description,            
            idDataSourceType,
            fcr,
            ucr
        }
    }
}
`);

export const MODIFY = gql(`
mutation(
    $data: DataSourceInputType!
    $tables: [DataSourceTableAliasInputType]!
    $db: DataSourceDataBaseInputType
    $srv: DataSourceServiceInputType
    $fields: [DataSourceFieldInputType]!
 ){
    dataSourceMutation{
        modify(data:$data, tables:$tables,db:$db,srv:$srv,fields:$fields){
            applicationId,
            applicationVersion,
            name,
            description,            
            idDataSourceType,
            fcr,
            ucr
        }
    }
}
`);


export const UPDATE = gql(`
mutation($data: DataSourceInputType!){
    dataSourceMutation{
        update(data:$data){
            id,
            applicationId,
            applicationVersion,            
            name,
            description,            
            idDataSourceType,
            fcr,
            ucr
        }
    }
}
`);

export const UPDATE_ENTIRE = gql(`
mutation($data: DataSourceInputType!,
    $tables: [DataSourceTableAliasInputType]!,
    $db: DataSourceDataBaseInputType,
    $srv: DataSourceServiceInputType,
    $fields: [DataSourceFieldInputType]!
){
    dataSourceMutation{
        update(data:$data, tables:$tables,db:$db,src:$src,fields:$fields){
            id,
            applicationId,
            applicationVersion,            
            name,
            description,            
            idDataSourceType,
            fcr,
            ucr
        }
    }
}
`);




export const REFRESH_FIELDS = gql(`
mutation($data: DataSourceInputType!){
    dataSourceMutation{
        refreshFields(data:$data){
            id,
            applicationId,
            applicationVersion,
            name,
            description,
            idDataSourceType,
            fcr,
            ucr
        }
    }
}
`);

export const DELETE = gql(`
mutation($dataSourceId: Int!){
    dataSourceMutation{
        deleteDataSource(dataSourceId:$dataSourceId)
    }
}
`);*/

export const CLASS_FROM_SWAGGER_URL = gql(`
mutation($applicationId: Int!
        $applicationVersion: Int!
        $dataSourceId: Int!
        $url: String!
        $jsonFile: String) {
    dataSourceServiceMutation{
        classFromSwaggerUrl(applicationId: $applicationId, applicationVersion: $applicationVersion, dataSourceId: $dataSourceId, url: $url, jsonFile: $jsonFile){
            host,
            paths {
                name,
                endPoint,
                operations {
                    type,
                    requestBodyReferences,
                    responseBodyReferences,
                    parameters
                }
            },
            tables {
                id,
                dataSourceId,
                tableName,
                tableAlias,
                shortDescription,
                idIcon,                
                join,
                isInput,
                fcr,
                ucr,
                uum,
                fum,
                iapDataSourceFields {
                    id,    
                    dataSourceId,
                    dataSourceTableAliasId,
                    tableName,
                    field,
                    tableNameAlias,
                    fieldAlias,
                    primaryKey,
                    foreignKey,
                    identityColumn,
                    position,
                    defaultValue,
                    nullable,
                    encrypted,
                    sqlType,
                    length,
                    precision,
                    idDataType,
                    catalogTypeId,
                    foreignTableName,
                    foreignTableFieldId,
                    foreignTableFieldDescription,
                    dataSourceLookUpId
                    shortDescription,
                    description,
                    localizable,
                    computed
                    fcr,
                    ucr,
                    uum,
                    fum,
                    dataSourceLookUpId
                },
                nameSpaces {
                    name,
                    value
                }
            }
        }
    }
}
`);

export const CLASS_FROM_WSDL_URL = gql(`
mutation($applicationId: Int!
        $applicationVersion: Int!
        $dataSourceId: Int!
        $url: String!){
    dataSourceServiceMutation{
        classFromWsdlUrl(applicationId: $applicationId, applicationVersion: $applicationVersion, dataSourceId: $dataSourceId, url: $url){
            url,
            binding {
                operationName,
                inputName,
                inputNameSpace,
                inputEncodingStyle,
                outputName,
                outputNameSpace,
                outputEncodingStyle
            },
            nameSpaces {
                name,
                value
            },
            messages {
                name,
                parts {
                    name,
                    type,
                    element
                }
            },
            methods {
                name,
                input,
                output,
                inputMessage,
                outputMessage,
                inputMessageNameSpace {
                    name,
                    value
                }
                outputMessageNameSpace {
                    name,
                    value
                }
            },
            tables {
                id,
                dataSourceId,
                tableName,
                tableAlias,
                shortDescription,
                idIcon,
                join,
                isInput,
                fcr,
                ucr,
                uum,
                fum,
                iapDataSourceFields {
                    id,    
                    dataSourceId,
                    dataSourceTableAliasId,
                    tableName,
                    field,
                    tableNameAlias,
                    fieldAlias,
                    primaryKey,
                    foreignKey,
                    identityColumn,
                    position,
                    defaultValue,
                    nullable,
                    encrypted,
                    sqlType,
                    length,
                    precision,
                    idDataType,
                    catalogTypeId,
                    foreignTableName,
                    foreignTableFieldId,
                    foreignTableFieldDescription,
                    dataSourceLookUpId
                    shortDescription,
                    description,
                    localizable,
                    computed,
                    fcr,
                    ucr,
                    uum,
                    fum,
                    dataSourceLookUpId
                },
                nameSpaces {
                    name,
                    value
                }
            }
        }
    }
}
`);


export const CALL_WS_SOAP = gql(`
mutation($applicationId: Int!
        $applicationVersion: Int!
        $dataSourceId: Int!
        $url: String!
        $method: String!
        $request: String!){
    dataSourceServiceMutation{
        callWebServiceSOAP(applicationId: $applicationId, applicationVersion: $applicationVersion, dataSourceId: $dataSourceId, url: $url, method: $method, request: $request)
    }
}
`);

export const CALL_WS_REST = gql(`
mutation($token: String!
        $applicationId: Int!
        $applicationVersion: Int!
        $dataSourceId: Int!
        $userId: Int!
        $isAdmin: Boolean
        $profileId: Int
        $url: String!
        $endPoint: String!
        $type: String!
        $body: String!
        $params: String!){
    dataSourceServiceMutation{
        callWebServiceApiRest(token: $token, applicationId: $applicationId, applicationVersion: $applicationVersion, dataSourceId: $dataSourceId, userId: $userId, isAdmin: $isAdmin, profileId: $profileId, url: $url, endPoint: $endPoint, type: $type, body: $body, params: $params) {
            items
        }
    }
}
`);



