<template>
    <span>Descripción</span>
    <div style="display: flex;">        
        <textarea style="resize: none;" @pointerdown.stop="" @dblclick.stop="" id="w3review" name="msgText" rows="2" cols="50"
            v-model="selectedText" 
            class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full "></textarea>
        <i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" class="fa fa-subscript"
            @click="showExpression(ConditionNodeConst.VAR_COND_IN, 'Condición')"></i>
    </div>

   
</template>
<script lang="ts">

import { computed, defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { IapComponentDataSource } from '../../../../../component/domain/iapComponentDataSource';
import Dropdown from 'primevue/dropdown';
import CatalogTypeConst from '../../../../../catalog/domain/const/CatalogTypeConst';
import helperCatalog from '../../../../../catalog/infrastructure/helper/helperCatalog';
import { Container } from 'inversify';
import { IServiceSearch } from '../../../../../search/application/IServiceSearch';
import { TYPES } from '../../../../../../../common/domain/types';
import HelperLoading from '../../../../../../../common/infrastructure/funciones/HelperLoading';
import CatalogDbType from '../../../../../catalog/domain/const/CatalogDbType';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';
import debounce from 'lodash.debounce';
import HelperCustomComponentControls from '../../../functions/helperCustomComponentControls';
import MessageNodeConst from '../constants/MessageNodeConst';
import Textarea from 'primevue/textarea';
import ConditionNodeConst from '../constants/ConditionNodeConst';
//import PrimeVue from "primevue/config";
export default defineComponent({
    name: 'customconditioncontrol',
    components: {
        Dropdown,
        Textarea,
        //PrimeVue
    },
    props: {
        data: Object
    },
    setup(props) {
        const { showExpression } = HelperCustomComponentControls(props.data);


        const selectedText = ref('');


        let debounceUpdateData: any;
        const debounceTime = 1200;



        const initDebounce = () => {
            debounceUpdateData = debounce((data: any, start: boolean = false) => {
                if (props.data) {
                    props.data.onChange({ key: ConditionNodeConst.VAR_COND_IN, data: selectedText.value })
                }
            }, debounceTime);
        }

        watch(
            selectedText, (newvalue, oldvalue) => {
                if (oldvalue !== newvalue && oldvalue !== undefined) {
                    debounceUpdateData(newvalue, true);
                }
            },
            {
                flush: 'post',
                //immediate: true, 
                deep: true
            }
        )


        onMounted(() => {
            if (props.data) {
                selectedText.value = props.data.variableConditionInput
            }
            initDebounce();
        })


        onBeforeUnmount(() => {
            debounceUpdateData.cancel();
        })

        return {
            showExpression,
            ConditionNodeConst,
            selectedText
        };
    },
});
</script>
<style scoped>
.socket {
    display: inline-block;
    cursor: pointer;
    border: 1px solid grey;
    /*width: $socket-size;
  height: $socket-size * 2;
  vertical-align: middle;
  */
    background: #fff;
    z-index: 2;
    box-sizing: border-box;
}
</style>
