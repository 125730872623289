<template>
    <div>
        <InputGroup v-if="!showForm">
            <Dropdown v-model="cfgSelected" :options="data" placeholder="Selecciona una configuración" filter
                optionLabel="name" :showClear="true" class="w-full md:w-20rem" @change="changeCfgSelected">
                <template #value="slotProps">
                <div v-if="slotProps.value.id > 0" class="flex align-items-center">            
                    <div>{{ formatImportCaption(slotProps.value) }}</div>
                </div>
                <span v-else>
                    {{ slotProps.placeholder }}
                </span>                    
                </template>

                <template #option="slotProps">
                    {{ formatImportCaption(slotProps.option) }}
                </template>

            </Dropdown>
            <Button icon="pi pi-plus" @click="addOrEditOrDelete(OperationDataTypeConst.INSERT)" />
            <Button v-if="hasSelectedData" icon="pi pi-pencil" severity="warning"
                @click="addOrEditOrDelete(OperationDataTypeConst.UPDATE)" />
            <Button v-if="hasSelectedData" icon="pi pi-trash" severity="danger"
                @click="addOrEditOrDelete(OperationDataTypeConst.DELETE)" />
        </InputGroup>

        <div v-else class="flex flex-column gap-3 w-25rem">
            <Card>
                <template #title>{{ tittle }}</template>
                <template #content>

                    <div class="p-fluid">
                        <div class="field">
                            <label for="name"
                                :class="{ 'p-invalid': v$.$error && submitted, 'customrequired': true }">Nombre</label>
                            <InputText id="name" v-model.trim="cfgSelected.name"
                                :class="{ 'p-invalid': v$.$error && submitted }" autofocus />
                            <span v-if="submitted">
                                <span v-for="error in v$.name.$errors" :key="error.$uid">
                                    <small class="p-error">{{ error.$message }}</small>
                                </span>
                            </span>
                        </div>
                        <div class="field">
                            <label for="description" :class="{ 'p-invalid': v$.$error && submitted, 'customrequired': true }">Clave</label>
                            <InputText id="description"
                            :class="{ 'p-invalid': v$.$error && submitted }"
                            v-model="cfgSelected.fieldKey" />
                            <span v-if="submitted">
                                <span v-for="error in v$.fieldKey.$errors" :key="error.$uid">
                                    <small class="p-error">{{ error.$message }}</small>
                                </span>
                            </span>
                        </div>
                        <div class="field">
                            <label for="numLine">Número de linea que contiene la cabecera</label>
                            <InputNumber class="ml-2" v-model="cfgSelected.numLineHeader" inputId="integeronly"
                                showButtons @update:modelValue="$emit('change:numLinHeader')" :min="0" />
                        </div>
                    </div>
                </template>
                <template #footer>
                    <Button id="guardar" class="mr-2" label="Guardar" icon="pi pi-save" severity="info"
                        @click="doOperationData()" />
                    <Button id="cancelar" class="mr-2" label="Cancelar" icon="pi pi-save" severity="danger"
                        @click="showForm = false" />
                </template>
            </Card>
        </div>
    </div>
</template>
<script lang="ts">

import { defineComponent, shallowRef, onMounted, computed } from 'vue';
import { Container } from 'inversify';
import { IapmImportConfig } from '../../../../builderMaster/user/domain/iapmImportConfig';
import HelperLoading from '../../../../../common/infrastructure/funciones/HelperLoading';
import { IServiceImportConfig } from '../../../../builderMaster/user/application/IServiceImportConfig';
import { TYPES } from '../../../../../common/domain/types';
import HelperCommon from '../../../../../common/infrastructure/funciones/HelperCommon';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import OperationDataTypeConst from '../../../../../common/domain/constantes/OperationDataTypeConst';
import { MessageService } from '../../../../../common/infrastructure/servicios';
import { MessageType } from '../../../../../common/infrastructure/servicios/MessageService';
import HelperUtils from '../../../../../common/infrastructure/funciones/HelperUtils';
import { DropdownChangeEvent } from 'primevue/dropdown';
import { Dictionary } from '../../../expression/domain/dictionary';

export default defineComponent({
    name: 'emptypage',
    props: {
        container: {
            type: Object as () => Container
        },

        setupImport: {
            type: String,
            default: ''
        },
        cfg: {
            type: Object as () => IapmImportConfig,
            default: {}
        },

        originalKeysFile: {
            type: Object as () => Dictionary<String, String>[],
            default: []
        },

    },
    setup(props, { emit }) {

        const data = shallowRef<IapmImportConfig[]>([])
        const showForm = shallowRef(false)
        const submitted = shallowRef(false)
        const tittle = shallowRef('Nueva Configuración')
        let operationSeleceted = OperationDataTypeConst.INSERT

        const cfgSelected = computed({
            get: () => props.cfg,
            set: (val) => emit('update:cfg', val),
        });




        const rules = computed(() => ({
            name: {
                required: helpers.withMessage('El nombre es obligatorio', required),
            },
            fieldKey:{
                required: helpers.withMessage('la clave es obligatorio', required),
            }
        }))


        const hasSelectedData = computed(() => {
            return cfgSelected.value.id > 0
        })

        const v$ = useVuelidate(rules, cfgSelected)


        const loadImports = () => {
            data.value = []
            if (props.container) {
                HelperLoading.showLoading();
                const _srv = props.container.get<IServiceImportConfig>(
                    TYPES.IMPORT_REPOSITORY
                );

                _srv.getByFieldKey(props.setupImport ?? '').then(result => {
                    
                 
                   if (props.setupImport && result.find (x=> x.fieldKey?.toLowerCase() == props.setupImport?.toLowerCase()))
                   {
                        cfgSelected.value  = result.find (x=> x.fieldKey == props.setupImport) as any

                        //data.value =[]
                        HelperUtils.addItemToShallowRefArray(data,  result.filter (x=> x.fieldKey == props.setupImport))

                        emit('update:params')
                        //emit('change:numLinHeader')
                   }
                   /*
                   else{
                        HelperUtils.addItemToShallowRefArray(data, result)
                   }
                   */

                })
                    .finally(() => {
                        HelperLoading.hideLoading();
                    })


            }
        }

        const isValidData = (): boolean => {
            submitted.value = true;
            v$.value.$touch();
            if (v$.value.$invalid) {
                HelperCommon.setFocusOnFirstError();
                return false;
            }

            return true;
        }

        const initCfg = () => {
            return {
                id: 0,
                name: '',
                fieldKey: '',
                numLineHeader: 0,
                data: JSON.stringify(props.originalKeysFile)
            }
        }


        const doOperationData = () => {
            if (isValidData()) {
                if (props.container) {
                    HelperLoading.showLoading();
                    const _srv = props.container.get<IServiceImportConfig>(
                        TYPES.IMPORT_REPOSITORY
                    );

                    if (operationSeleceted == OperationDataTypeConst.INSERT) {
                        _srv.add(JSON.parse(JSON.stringify(cfgSelected.value))).then(result => {
                            cfgSelected.value = result

                            data.value = [...data.value, result]

                        })
                            .finally(() => {
                                HelperLoading.hideLoading();
                                showForm.value = false
                            })
                    }

                    if (operationSeleceted == OperationDataTypeConst.UPDATE) {
                        _srv.update(JSON.parse(JSON.stringify(cfgSelected.value))).then(result => {
                            cfgSelected.value = result


                            const index = data.value.findIndex(x => x.id == result.id)
                            data.value = [
                                {
                                    ...data.value[index],
                                    name: result.name,
                                    fieldKey: result.fieldKey

                                },
                                ...data.value.slice(index + 1)
                            ]

                        })
                            .finally(() => {
                                HelperLoading.hideLoading();
                                showForm.value = false
                            })
                    }

                    if (operationSeleceted == OperationDataTypeConst.DELETE) {
                        _srv.delete(JSON.parse(JSON.stringify(cfgSelected.value)).id).then(result => {
                            if (result) {
                                cfgSelected.value = initCfg()
                                loadImports()
                            }

                        })
                            .finally(() => {
                                HelperLoading.hideLoading();
                                showForm.value = false
                            })
                    }

                }
            }
        }

        const newCfg = () =>{
            cfgSelected.value.id = 0;
            cfgSelected.value.name = '';
            cfgSelected.value.fieldKey =  props.setupImport ?? '';
            cfgSelected.value.numLineHeader =  0;
            cfgSelected.value.data = JSON.stringify(props.originalKeysFile)
        }

        const addOrEditOrDelete = (operation: number) => {
            operationSeleceted = operation
            switch (operationSeleceted) {
                case OperationDataTypeConst.INSERT:
                    tittle.value = 'Nueva Configuración'
                    newCfg()
                    break;
                case OperationDataTypeConst.UPDATE:
                    tittle.value = 'Edición de Configuración'
                    break;
                case OperationDataTypeConst.DELETE:
                    var doCallback = (): void => {
                        doOperationData()
                    }
                    MessageService.showMessage(MessageType.Pregunta, '', 'Se va a proceder a eliminar la configuración "' + cfgSelected.value.name + '"". <br/><b>¿Desea continuar?</b>', true, true, false, '', doCallback);
                    break;

            }
            showForm.value = (operation < 2)
        }

        const formatImportCaption = (data: IapmImportConfig) => {

            return data.name + (!HelperCommon.isNullOrWhitespace(data.fieldKey ?? '') ? ' (' + data.fieldKey + ')' : '')
        }


        const changeCfgSelected = (event: DropdownChangeEvent) => {
            if (!event.value) {
                cfgSelected.value = initCfg()
            }
            else {
                emit('update:params')
            }
        }

        onMounted(() => {
            loadImports();
        })


        return {
            cfgSelected,
            data,
            tittle,
            addOrEditOrDelete,
            showForm,
            submitted,
            v$,
            doOperationData,
            OperationDataTypeConst,
            hasSelectedData,
            rules,
            formatImportCaption,
            changeCfgSelected
        };
    },
});
</script>
<style scoped></style>
