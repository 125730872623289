<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <Avatar v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" :key="componentKey"
        :id="getPropertyValue(AvatarTypeConst.ID)" v-tooltip="getPropertyValue(AvatarTypeConst.TOOLTIP)"
        :style="getPropertyValue(AvatarTypeConst.STYLE)"
        :class="{ [getPropertyValue(AvatarTypeConst.CLASS) ?? '']: true, 'customrequired': getPropertyBooleanValue(AvatarTypeConst.REQUIRED) }"
        :name="getPropertyValue(AvatarTypeConst.NAME)" :disabled="getPropertyBooleanValue(AvatarTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(AvatarTypeConst.VISIBLE)"
        :placeholder="getPropertyValue(AvatarTypeConst.PLACEHOLDER)" :label="getPropertyValue(AvatarTypeConst.LABEL)"
        :icon="getPropertyValue(AvatarTypeConst.ICON)" :size="getCatalogValue(AvatarTypeConst.SIZE)"
        :image="getPropertyValue(AvatarTypeConst.IMAGE)" :shape="getCatalogValue(AvatarTypeConst.SHAPE)" v-model="vmodel"
        @click="processEventComponent(CatalogEventConst.CLICK)">
    </Avatar>
</template>

<script lang="ts">

import { defineComponent, onMounted, onUnmounted } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import AvatarTypeConst from '../../../../domain/Constants/AvatarTypeConst';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';
import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';

export default defineComponent({
    name: 'dynamic_avatar',
    components: {
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },      
        slotProps: {
            type: Object,
            default: () => ({})
        },
    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded, 
             resolveExpressions, getCatalogValue, baseOnUnmount,fieldKey,fieldKeyComponentDataSourceId,processEventComponent 
             ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store)


        onMounted(() => {

        })

        onUnmounted(() => {
            baseOnUnmount();
        })

        return {
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            AvatarTypeConst,
            BaseControlTypeConst,
            loaded,
            getCatalogValue,
            fieldKey,
            fieldKeyComponentDataSourceId ,
            ObjectGroupConst
            ,canDoOperation
            ,processEventComponent
            ,CatalogEventConst
            ,componentKey
        };
    },
});
</script>
<style scoped></style>
