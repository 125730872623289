import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import { IFieldConfigUserRepository } from '../domain/IFieldConfigUserRepository';

import { UPDATE, ADD } from './mutation/fieldConfigUserMutation';
import { IapmFieldConfigUser } from '../domain/iapmFieldConfigUser';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class FieldConfigUserApolloClientRepository implements IFieldConfigUserRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }
  
  

  async update (dataInput: IapmFieldConfigUser): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.fieldConfigurationUserMutation)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async add (dataInput: IapmFieldConfigUser): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.fieldConfigurationUserMutation)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async getAll (maxregs: number | undefined): (Promise<IapmFieldConfigUser[]>) {
    return new Promise<IapmFieldConfigUser[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD,{data:maxregs}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.fieldConfigurationUserMutation)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async getById (id: number): (Promise<IapmFieldConfigUser>) {
    return new Promise<IapmFieldConfigUser>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD,{data:id}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.fieldConfigurationUserMutation)
        }
        catch(e) {
          reject()
        }
      });

    });
  }


}


