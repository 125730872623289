import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';
import { GET_BY_IDTYPE, GET_BY_IDTYPES } from './query/catalogoQuery';

import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import { IapCatalog } from '../domain/iapCatalog';
import { ICatalogRepository } from '../domain/ICatalogRepository';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';


@injectable()
export class CatalogApolloClientRepository implements ICatalogRepository {
  private apolloClient : MyApolloClient
  private apolloClientId : String
  constructor (apolloInstance: MyApolloClient, _apolloClientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.apolloClientId = _apolloClientId
  }
  async getCatalogByType (tipoCatalogoId: string) {
    return new Promise<IapCatalog[]>(async (resolve, reject) => {
      
      const result = this.apolloClient.executeQuery(GET_BY_IDTYPE, { typeId: tipoCatalogoId }, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          
          HelperUtils.handleDatesApollo(value) 
          resolve(value.catalogQuery.catalogByTypeId)          
        }
        catch(e) {
          
          reject()
        }
      });

    });
  } 

  async getCatalogByTypes (tipoCatalogoId: string[]) {
    return new Promise<IapCatalog[]>(async (resolve, reject) => {
      
      const result = this.apolloClient.executeQuery(GET_BY_IDTYPES,{typeIds:tipoCatalogoId}, this.apolloClientId)
      
      watch(result, (value: any) => {
        
        try {
          
          HelperUtils.handleDatesApollo(value) 
          resolve(value.catalogQuery.catalogByTypeIds)          
        }
        catch(e) {
          
          reject()
        }
      });

    });
  } 
  
}


