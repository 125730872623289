import gql from 'graphql-tag';


export const GETUSEROPTION = gql (`
    query ($applicationId: Int!, $applicationVersion: Int!, $userId: Int!, $optionId: String!) {
        userOptionQuery {
            getUserOption (applicationId:$applicationId, applicationVersion:$applicationVersion, userId:$userId, optionId: $optionId)
            {            
                userId
                optionId
                value
            }
        }
    }
`);

export const GETUSEROPTIONS = gql (`
    query ($applicationId: Int!, $applicationVersion: Int!, $userId: Int!) {
        userOptionQuery {
            getUserOptions (applicationId:$applicationId, applicationVersion:$applicationVersion, userId:$userId)
            {            
                userId
                optionId
                value
            }
        }
    }
`);

export const GETCURRENTUSEROPTIONS = gql (`
    query ($data: IapmUserOption!) {
        userOptionQuery {
            getCurrentUserOptions (data:$data)
            {            
                userId
                optionId
                value
            }
        }
    }
`);

export const GETFILTEREDUSEROPTIONS = gql (`
    query ($applicationId:Int!, $applicationVersion:Int!, $userId:Int!, $filter:String!) {
        userOptionQuery {
            getFilteredUserOptions (applicationId:$applicationId, applicationVersion:$applicationVersion, userId:$userId, filter:$filter)
            {            
                userId
                optionId
                value
            }
        }
    }
`);

