import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: MenuInputType!){
    menuMutation{
        updateMenu(data:$data) {
            applicationId,
            applicationVersion,
            builtin,
            componentId
            contract,
            active,
            order,
            fcr,
            fum,
            id,
            idIcon,
            parentId,
            title,
            ucr,
            uum,
            urlComponentStatic,
            urlRouteComponentStatic,
            keepAlive
        }
    }
}
`);

export const ADD = gql(`
mutation($data: MenuInputType!){
    menuMutation{
        addMenu(data:$data)
        {
            applicationId,
            applicationVersion,
            builtin,
            componentId
            contract,
            active,
            order,
            fcr,
            fum,
            id,
            idIcon,
            parentId,
            title,
            ucr,
            uum,
            urlComponentStatic,
            urlRouteComponentStatic,
            keepAlive
        }
    }
}
`);


export const DELETE_WITH_CHILDS_BY_ID = gql(`
mutation($id: Int!) {
    menuMutation {
        deleteById(id:$id)
    }
}
`);


