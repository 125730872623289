<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
            
        <Button icon="pi pi-users" style="color:var(--primary-color);" text rounded severity="secondary" @click="getWhoAmIProfiles();visibleRight = true"></Button>
        <AppProfileSidebar :key="componentKey" :container="container" :visibleRight="visibleRight" :whoAmIProfilesResponse="whoAmIProfilesResponse" @update:modelValue="visibleRight = !visibleRight" />
</template>
<script lang="ts">

import { defineComponent, onUnmounted, ref } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';
import AppProfileSidebar from '../../../../../../../common/infrastructure/componentes/base/common/profiles/AppProfileSidebar.vue';
import SummaryDebug from '../../shared/SummaryDebug.vue';
import { useRouter } from 'vue-router';
import HelperLoading from '../../../../../../../common/infrastructure/funciones/HelperLoading';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';

export default defineComponent({
    name: 'dynamicwhoamiprofiles',
    components: {
        SummaryDebug,
        AppProfileSidebar
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },

        slotProps: {
            type: Object,
            default: () => ({})
        }
      
    },
    setup(props) {
                
        const store = useStore();
        const whoAmIProfilesResponse = ref(null);
        let visibleRight = ref(false)

        const { vmodel, getPropertyBooleanValue, loaded, baseOnUnmount
            ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);

        const getWhoAmIProfiles = () => {
            if (!whoAmIProfilesResponse.value) {
                HelperLoading.showLoading()
                HelperCommon.getWhoAmIProfiles(props.container as Container).then((profilesResponse) => {
                    whoAmIProfilesResponse.value = profilesResponse.data;
                    
                    HelperLoading.hideLoading()
                    visibleRight.value = true;
                }).catch(e => {
                    console.log(e);
                });
            }
            else {
                visibleRight.value = true;
            }
        };

        onUnmounted(() => {
            baseOnUnmount();
        })
     
        return {
   
            BaseControlTypeConst,
            getWhoAmIProfiles,
            whoAmIProfilesResponse,
            visibleRight,
            vmodel,
            getPropertyBooleanValue,
            componentKey,
            loaded
        };
    },
});
</script>
<style scoped></style>
