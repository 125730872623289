import BaseControlTypeConst from "./BaseControlTypeConst"

export default class ButtonTypeConst extends BaseControlTypeConst {
  
  static BADGE='badge'
  static LINK='link'
  static SEVERITY='severity'
  static RAISED='raised'
  static ROUNDED='rounded'
  static OUTLINED='outlined'
  static PLAIN='plain'
  static SIZE='size'
  static LABEL='label'
  static ICON='icon'
  static ICONPOS='iconPos'
  static ICONCLASS='iconClass'
  
    
  }
  