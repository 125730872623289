/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
// import mainApp from '../funciones/HelperMainWrapper';
import { app } from '../funciones/HelperMainWrapper'
import Swal from 'sweetalert2'
import MsgQueue from '../funciones/msgQueue';
// import { App as Application, Component } from "vue";
// const app = HelperMainWrapper.app;


export enum MessageType {
  Info = 1,
  Error,
  Aviso,
  Correcto,
  Pregunta,
}

// const $cookies = VueCookies(),

/**
 * @description get value cookie
 */
export  const showMessage = async (
  tipo: MessageType,
  title: string,
  text: string,
  showConfirmButton = true,
  showCancelButton = false,
  allowOutsideClick = false,
  imageUrl = '',
  callbackOk: any = undefined,
  callbackCancel: any = undefined,
  callBackOkTimeOut = -1,
  callBackCancelTimeOut = -1
) => {  
    MsgQueue.enqueue({ 
    tipo: tipo,
    title: title,
    text: text,
    showConfirmButton : showConfirmButton,
    showCancelButton : showCancelButton,
    allowOutsideClick : allowOutsideClick,
    imageUrl : imageUrl,
    callbackOk: callbackOk,
    callbackCancel: callbackCancel,
    callBackOkTimeOut : callBackOkTimeOut,
    callBackCancelTimeOut : callBackCancelTimeOut
    })


    let _data  = MsgQueue.peek();

do{
  
    if (_data){

      if (_data.callbackOk && _data.callBackOkTimeOut && _data.callBackOkTimeOut !== -1) {
        setTimeout(() => {
          _data.callbackOk()
        }, _data.callBackOkTimeOut)
      }
    
      if (_data.callbackCancel && _data.callBackCancelTimeOut && _data.callBackCancelTimeOut !== -1) {
        setTimeout(() => {
          _data.callbackCancel()          
        }, _data.callBackCancelTimeOut)
      }  
  

      await   Swal.fire({
        //Swal.fire({
          customClass: {
            container: 'custom-swal'
          },
          icon: getType(_data.tipo),
          title: _data.title,
          html: _data.text,
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          imageUrl:_data.imageUrl,
          showConfirmButton:_data.showConfirmButton,
          showCloseButton: false,
          showCancelButton:_data.showCancelButton,
          allowOutsideClick:_data.allowOutsideClick,
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        }).then((result: { isConfirmed: any, isDenied: any }) => {       
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          if (_data.callbackOk) {
            _data.callbackOk()
          }
        } else 
        {
          if (_data.callbackCancel) {
            _data.callbackCancel()
          }
        }
      })
    
      MsgQueue.dequeue()
      
    }

    _data  = MsgQueue.peek();

}while(_data)


  
 
}



export  const showMessageCounter = (
  tipo: MessageType,
  title: string,
  text: string,
  showConfirmButton = false,
  showCancelButton = false,
  allowOutsideClick = false,
  imageUrl = '',
  callbackOk: any = undefined,
  callbackCancel: any = undefined,
  callBackOkTimeOut = -1,
  callBackCancelTimeOut = -1
) => {  
 



      if (callbackOk && callBackOkTimeOut && callBackOkTimeOut !== -1) {
        setTimeout(() => {
          callbackOk()
        }, callBackOkTimeOut)
      }
    
      if (callbackCancel && callBackCancelTimeOut && callBackCancelTimeOut !== -1) {
        setTimeout(() => {
          callbackCancel()          
        }, callBackCancelTimeOut)
      }  
      
      let timerInterval :any;
      var start = Date.now();
       Swal.fire({
        //Swal.fire({
          customClass: {
            container: 'custom-swal'
          },
          icon: getType(tipo),
          title: title,
          html: text ,
          footer: '<div> <i class="pi pi-clock" ></i> <b ml-2>0</b> <span class="ml-1"> segundos </span> </div>',
          timer:28800000,
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          imageUrl:imageUrl,
          showConfirmButton:showConfirmButton,
          showCloseButton: false,
          showCancelButton:showCancelButton,
          allowOutsideClick:allowOutsideClick,
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          },
          didOpen: () => {
            //@ts-ignore:disable-next-line
            Swal.showLoading();
            //@ts-ignore:disable-next-line
            const timer = Swal.getFooter().querySelector("b");
            if (timer){
              timerInterval = setInterval(() => {
                timer.textContent = `${Math.floor((Date.now()-start)/1000).toString().padStart(2,"0")}`;
              }, 1000);
  
            }
          },
          willClose: () => {
            //@ts-ignore:disable-next-line
            clearInterval(timerInterval);
          }
        }).then((result: { isConfirmed: any, isDenied: any }) => {       
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          if (callbackOk) {
            callbackOk()
          }
        } else 
        {
          if (callbackCancel) {
            callbackCancel()
          }
        }
      })
}




const getType = (tipo: MessageType) => {
  if (tipo === MessageType.Error) return 'error'
  if (tipo === MessageType.Aviso) return 'warning'
  if (tipo === MessageType.Info) return 'info'
  if (tipo === MessageType.Correcto) return 'success'
  if (tipo === MessageType.Pregunta) return 'question'

  return 'info'
}

const getSeverity = (tipo: MessageType) => {
  if (tipo === MessageType.Error) return 'error'
  if (tipo === MessageType.Aviso) return 'warn'
  if (tipo === MessageType.Info) return 'info'
  if (tipo === MessageType.Correcto) return 'success'

  return 'info'
}

export const showToast = (tipo: MessageType, title: string, text: string): void => {
  const _tipo = getSeverity(tipo)
  app.config.globalProperties.$toast.add({ severity: _tipo, summary: title, detail: text, life: 3000, position: 'bottom-right' } as any)
}

export const hideAllMessages = (): void => {  
  Swal.close()
  MsgQueue.clearAll()
}

export default { MessageType, showMessage, showToast, hideAllMessages, showMessageCounter }
