import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import { IComponentDataSourceFieldConfigurationRepository } from '../domain/IComponentDataSourceFieldConfigurationRepository';

import { UPDATE, ADD } from './mutation/componentDataSourceFieldConfigurationMutation';
import { IapComponentDataSourceFieldConfiguration } from '../domain/iapComponentDataSourceFieldConfiguration';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class ComponentDataSourceFieldConfigurationApolloClientRepository implements IComponentDataSourceFieldConfigurationRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }
  
  

  async update (dataInput: IapComponentDataSourceFieldConfiguration): (Promise<IapComponentDataSourceFieldConfiguration>) {
    return new Promise<IapComponentDataSourceFieldConfiguration>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentDataSourceFieldConfigurationMutation.updateComponentDataSourceFieldConfiguration)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async add (dataInput: IapComponentDataSourceFieldConfiguration): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentDataSourceFieldConfigurationMutation)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

 


}


