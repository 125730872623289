<template>
<!-- {{ dataInput}} -->
    <div v-if="dataInput?.length > 0" class="col-12">
        <div class="card">
            <div class="p-fluid formgrid grid">
                <div v-for="(item, index) of dataInput" :key="item.fieldId + index.toString()" class="field col-12 md:col-3">
                    <div>
                        <label for="question"
                            :class="{ 'p-invalid': v$.items.$each.$response.$errors[index].value.length > 0 && submitted, 'customrequired': item.required }">
                            {{ getColumnName(item.fieldId) }}
                        </label>

                        <InputSwitch v-if="isColumnType(item.fieldId, SqlTypesConst.BIT)" v-model="item.value"
                            :trueValue="true" :falseValue="false" />
                        <LookUpEditor v-else-if="hasLookUpByFieldId(item.fieldId)"
                            :dataSourceLookUp="getLookUpByFieldId(item.fieldId)" v-model="item.value" :container="container"
                            @change:SelectedLookUpData="selectedLookUpData(item.fieldId, $event)"
                            :catalogsData="catalogsData" :filterConditions="item.lookUpFilter.lookUpFilter" :Component="Component"></LookUpEditor>
                        <Calendar
                            v-else-if="isColumnType(item.fieldId, SqlTypesConst.DATE) || isColumnType(item.fieldId, SqlTypesConst.DATETIME)"
                            v-model="item.value" />
                        <InputNumber v-else-if="isColumnType(item.fieldId, SqlTypesConst.INT)" v-model="item.value" />
                        <InputNumber v-else-if="isColumnType(item.fieldId, SqlTypesConst.DECIMAL)" v-model="item.value"
                            mode="currency" />
                        <Dropdown v-else-if="isCatalogTypeValue(item.fieldId)" v-model="item.value"
                            :options="getCatalogData(item.fieldId)" optionLabel="description" optionValue="id" filter showClear />
                        
                         <InputText v-else v-model="item.value" />

                        <span v-if="submitted">
                            <span v-for="error in v$.items.$each.$response.$errors[index].value" :key="error">
                                <small class="p-error">{{  error.$response.$message }}</small>
                            </span>
                        </span>

                    </div>


                </div>

            </div>
            <div>
                <Toolbar>
                    <template #end>
                        <Button id="guardar" label="Guardar" icon="pi pi-save"
                            class="mr-2  p-button-rounded p-button-primary p-button-outlined" @click="saveData" />
                    </template>
                </Toolbar>
            </div>
        </div>
    </div>
    <Message v-else severity="warn" :closable="false">El origen de datos no tiene ninguna configuración para añadir
        registros</Message>
</template>
<script lang="ts">

import { defineComponent, ref, computed, onMounted } from 'vue';
import { Container, id } from 'inversify';
import { useI18n } from 'vue-i18n';
import { messages } from './localization/MessagesBusqueda';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { IapCatalog } from '../../../catalog/domain/iapCatalog';
import { clave, claveNew } from '../../../dataupdate/domain/clave';
import DataSourceComp from '../functions/dataSourceComp';
import HelperCommon from '../../../../../common/infrastructure/funciones/HelperCommon';
import OperationDataTypeConst from '../../../../../common/domain/constantes/OperationDataTypeConst';
import { IServiceDataUpdate } from '../../../dataupdate/application/IServiceDataUpdate';
import { TYPES } from '../../../../../common/domain/types';
import HelperLoading from '../../../../../common/infrastructure/funciones/HelperLoading';
import { LocalService, MessageService } from '../../../../../common/infrastructure/servicios';
import { MessageType } from '../../../../../common/infrastructure/servicios/MessageService';
import SqlTypesConst from '../../../../../common/domain/constantes/SqlTypesConst';
import { dataUpdate } from '../../../dataupdate/domain/dataUpdate';
import { IapDataSourceField } from '../../../datasource/domain/iapDataSourceField';
import { IapComponentDataSource } from '../../../component/domain/iapComponentDataSource';
import DataSourceConst from '../functions/dataSourceConst';
import { useStore } from 'vuex';
import { IapComponent } from '../../../component/domain/iapComponent';
import CatalogObjectTypeConst from '../../../catalog/domain/const/CatalogObjectTypeConst';
import CatalogExpConst from '../../../catalog/domain/const/CatalogExpConst';
import { ExpresionEngine } from '../../../expression/infrastructure/helper/expressionEngine';
import HelperUtils from '../../../../../common/infrastructure/funciones/HelperUtils';
import HelperDataUpdate from '../../../dataupdate/infrastructure/helper/HelperDataUpdate';
import { Dictionary } from '../../../expression/domain/dictionary';
import { IapDataSourceLookUp } from '@ilinium/shared/src/entidades/builder/datasource/domain/iapDataSourceLookUp';
import CatalogValidationTypeConst from '@ilinium/shared/src/entidades/builder/catalog/domain/const/CatalogValidationTypeConst';
import { DataUpdateOperation } from '../../../dataupdate/domain/dataUpdateOperation';


export default defineComponent({
    name: 'new_data_source_look_up',
    components: {
        
    },
    props: {

        container: {
            type: Object as () => Container
        },
        dataSourceLookUp: {
            type: Object as () => IapDataSourceLookUp,
            default: null
        },
        idObjeto: {
            type: String,
            default: () => ('')
        },
        rootParentId:
        {
            type: Number,
            default: (-1)
        },
        Component: {
            type: Object as () => IapComponent,
            default: () => ({})
        },
        catalogsData: {
            type: Object as () => IapCatalog[],
            default: () => ([])
        },

        objetoId: {
            type: Number,
            default: 0,
        },
        formKey: {
            type: String,
            default: ''
        },
        compDataSource: {
            type: Object as () => IapComponentDataSource,
            default: () => ({})
        }
    },
    emits: ['refreshData'],
    setup(props, { emit }) {

        const dataInput = ref<claveNew[]>([]);
        const store = useStore();
        const { t, locale } = useI18n(messages)
        const submitted = ref(false);
        const lookUpData = ref();
        const dsData = computed(() => {
            if (props.idObjeto == CatalogObjectTypeConst.LOOKUP) {
                return props.dataSourceLookUp.dataSource;
            }

        })

        const objDsfConfiguration = computed(() => {

            return tablasData.value.flatMap(x => x.iapObjectDataSourceFieldConfigurations).filter(cf => cf.idObjeto == props.idObjeto && cf.objetoId == props.objetoId);

        })

        const { isType, isCatalogType, isTableType,  tablasData, hasLookUpByFieldId, getLookUpByFieldId, getDefaultvalue, getNewFiltersConditions, cleanLookUpDependency, cleanLookUp, lookUpFilterExp,getFieldValidationConfig,getFieldKeyColumn,getFieldFromKeyCol,getFieldId } = DataSourceComp(props.container as any, props, emit, t, props.compDataSource, props.catalogsData, store, dsData.value)


        const reglas = {
            items: {
                $each: helpers.forEach({
                    value: {
                        required:  (value, helpers) => {
                            if( helpers.required
                                && !(value)){
                                 return {
                                    $valid:false,
                                    $message: 'El campo es obligatorio',
                                    extraParams: {}
                                  }
                                }
                            
                                return { $valid: true }

                        },
                        maxLengthValue:(value,helpers)=>{

                            const allConfig=getFieldValidationConfig(helpers.field,props.objetoId);
                            const actualConfig=allConfig?.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.MAXL)
                            if(value && actualConfig && !HelperCommon.evalMaxLength(value,actualConfig.value1)){
                                return {
                                $valid: false,
                                $message: "El campo debe tener como máximo "+(actualConfig.value1)+" caracteres",
                                extraParams: {}
                            }
                            }
                            
                             return { $valid: true }
                           
                        },
                        
                        minLengthValue:(value,helpers)=>{

                            const allConfig=getFieldValidationConfig(helpers.field,props.objetoId);
                            const actualConfig=allConfig?.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.MINL)
                            if(value && actualConfig && !HelperCommon.evalMinLength(value,actualConfig.value1)){
                                return {
                                $valid: false,
                                $message: "El campo debe tener como mínimo "+(actualConfig.value1)+" caracteres",
                                extraParams: {}
                            }
                            }

                            return { $valid: true }

                        },
                        maxValueValue:(value,helpers)=>{

                            const allConfig=getFieldValidationConfig(helpers.field,props.objetoId);
                            const actualConfig=allConfig?.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.MAXV)
                            if(value && actualConfig && !HelperCommon.evalMaxValue(parseInt(value),parseInt(actualConfig.value1))){
                                return {
                                $valid: false,
                                $message: "El campo debe tener un valor máximo de "+(actualConfig.value1),
                                extraParams: {}
                            }
                            }

                            return { $valid: true }

                        },
                        minValueValue:(value,helpers)=>{

                            const allConfig=getFieldValidationConfig(helpers.field,props.objetoId);
                            const actualConfig=allConfig?.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.MINV)
                            if(value && actualConfig && !HelperCommon.evalMinValue(parseInt(value),parseInt(actualConfig.value1))){
                                return {
                                $valid: false,
                                $message: "El campo debe tener un valor mínimo de "+(actualConfig.value1),
                                extraParams: {}
                            }
                            }

                            return { $valid: true }

                        },
                     
                        email:(value,helpers)=>{

                            const allConfig=getFieldValidationConfig(helpers.field,props.objetoId);
                            const actualConfig=allConfig?.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.EM)
                            if(value && actualConfig && !HelperCommon.validarEmail(value)){
                                return {
                                $valid: false,
                                $message: "El email introducido no es correcto",
                                extraParams: {}
                            }
                            }

                            return { $valid: true }

                        },
                        url:(value,helpers)=>{

                            const allConfig=getFieldValidationConfig(helpers.field,props.objetoId);
                            const actualConfig=allConfig?.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.URL)
                            if(value && actualConfig && !HelperCommon.evalUrl(value)){
                                return {
                                $valid: false,
                                $message: "La url introducida no es correcta",
                                extraParams: {}
                            }
                            }

                            return { $valid: true }

                        },
                        alpha:(value,helpers)=>{

                            const allConfig=getFieldValidationConfig(helpers.field,props.objetoId);
                            const actualConfig=allConfig?.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.ALPH)
                            if(value && actualConfig && !HelperCommon.evalAlpha(value)){
                                return {
                                $valid: false,
                                $message: "El campo solo acepta caracteres del alfabeto",
                                extraParams: {}
                            }
                            }

                            return { $valid: true }

                        },
                        alphaNum:(value,helpers)=>{

                            const allConfig=getFieldValidationConfig(helpers.field,props.objetoId);
                            const actualConfig=allConfig?.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.ALPHNUM)
                            if(value && actualConfig && !HelperCommon.evalAlphaNum(value)){
                                return {
                                $valid: false,
                                $message: "El campo solo acepta alfanuméricos",
                                extraParams: {}
                            }
                            }

                            return { $valid: true }

                        },
                        numeric:(value,helpers)=>{

                            const allConfig=getFieldValidationConfig(helpers.field,props.objetoId);
                            const actualConfig=allConfig?.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.NUM)
                            if(value && actualConfig && !HelperCommon.evalNumeric(value)){
                                return {
                                $valid: false,
                                $message: "El campo solo acepta valores numéricos",
                                extraParams: {}
                            }
                            }

                            return { $valid: true }

                        },
                        integer:(value,helpers)=>{

                            const allConfig=getFieldValidationConfig(helpers.field,props.objetoId);
                            const actualConfig=allConfig?.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.INTEG)
                            if(value && actualConfig && !HelperCommon.evalInteger(value)){
                                return {
                                $valid: false,
                                $message: "El campo solo admite enteros",
                                extraParams: {}
                            }
                            }

                            return { $valid: true }

                        },
                        decimal:(value,helpers)=>{

                            const allConfig=getFieldValidationConfig(helpers.field,props.objetoId);
                            const actualConfig=allConfig?.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.DEC)
                            if(value && actualConfig && !HelperCommon.evalDecimal(value)){
                                return {
                                $valid: false,
                                $message: "El campo solo admite valores decimales",
                                extraParams: {}
                            }
                            }

                            return { $valid: true }

                        },
                        ipAddress:(value,helpers)=>{

                            const allConfig=getFieldValidationConfig(helpers.field,props.objetoId);
                            const actualConfig=allConfig?.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.IPADDR)
                            if(value && actualConfig && !HelperCommon.isValidIpAddress(value)){
                                return {
                                $valid: false,
                                $message: "El campo solo admite formato de direccion IP",
                                extraParams: {}
                            }
                            }

                            return { $valid: true }

                        },
                        sameAsRawValue:(value,helpers)=>{

                            const allConfig=getFieldValidationConfig(helpers.field,props.objetoId);
                            const actualConfig=allConfig?.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.SAMEAS)
                            if(value && actualConfig && !HelperCommon.evalSameAs(value,actualConfig.value1)){
                                return {
                                $valid: false,
                                $message: "El campo debe ser igual a "+(actualConfig.value1),
                                extraParams: {}
                            }
                            }

                            return { $valid: true }

                        },
                        betweenValue:(value,helpers)=>{

                            const allConfig=getFieldValidationConfig(helpers.field,props.objetoId);
                            const actualConfig=allConfig?.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.BEETW)
                            if(value && actualConfig && !HelperCommon.evalBetween(parseInt(value),parseInt(actualConfig.value1),parseInt(actualConfig.value2))){
                                return {
                                $valid: false,
                                $message: "El valor debe estar entre "+(actualConfig.value1)+" y "+(actualConfig.value2),
                                extraParams: {}
                            }
                            }

                            return { $valid: true }

                        },
                        iban:(value,helpers)=>{
                            
                            const allConfig=getFieldValidationConfig(helpers.field,props.objetoId);
                            const actualConfig=allConfig?.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.IBAN)
                            if(value && actualConfig && !HelperCommon.validarIban(value)){
                                return {
                                $valid: false,
                                $message: "El iban no es correcto",
                                extraParams: {}
                            }
                            }

                            return { $valid: true }

                        },
                     
                        
                       
                    },
                })
            }
        }


        const v$ = useVuelidate(reglas, { items: dataInput });

        const isValidData = (): boolean => {
            submitted.value = true;
            v$.value.$touch();
            if (v$.value.$invalid) {
                HelperCommon.setFocusOnFirstError();
                return false;
            }

            return true;
        }



        const isColumnType = (columna: string, tipo: string) => {

            var item = getFieldFromKeyCol(columna,false,tablasData.value);
            if (item) {
                return isType(item, tipo);
            }

            return false;
        }

        const isCatalogTypeValue = (columna: string) => {
            var item = getFieldFromKeyCol(columna,false,tablasData.value);
            if (item) {
                const resu = isCatalogType(item)
                return resu
            }
            else {
                return false;
            }
        }


        const isTableTypeValue = (columna: string) => {
            var item = getFieldFromKeyCol(columna,false,tablasData.value);
            if (item) {
                const resu = isTableType(item)
                return resu
            }
            else {
                return false;
            }
        }

        const getCatalogData = (columna: string) => {
            var item = getFieldFromKeyCol(columna,false,tablasData.value);
            if (item) {
                const tipoCatalogoid = item.catalogTypeId
                return props.catalogsData.filter(x => x.typeId == tipoCatalogoid).sort((a, b) => { return a.order - b.order; })
            }
            return []
        }

        const getColumnName = (columna: string) => {
            var item = getFieldFromKeyCol(columna,false,tablasData.value);
            return item?.shortDescription ?? item?.field ?? columna
        }


        const saveData = () => {
            if (isValidData()) {

                const valuesNoAdd = tablasData.value.filter(x => ((!x.identityColumn && !x.primaryKey) || (!x.identityColumn && x.primaryKey) || !x.computed)
                    && !objDsfConfiguration.value.find(c => c.dataSourceFieldId == x.id)?.add
                )
                    .sort((a, b) => { return (a.position ?? 0) - (b.position ?? 0); })
                    .map(x => ({
                        fieldId: getFieldKeyColumn(x),
                        value: getDefaultvalue(x, objDsfConfiguration.value.find(c => c.dataSourceFieldId == x.id)?.defaultValue),
                        required: !x.nullable || objDsfConfiguration.value.find(c => c.dataSourceFieldId == x.id)?.mandatory
                    })) as any


                //Por cada LookUp tiene que actualizar una o más claves...
                let valueLookUp: claveNew[] = [];

                dataInput.value.forEach((d, index) => {

                    if (hasLookUpByFieldId(d.fieldId)) {

                        const lookUpDic = d.value as any;

                        const fieldId = getFieldFromKeyCol(d.fieldId,false,tablasData.value)?.id;

                        const lk = lookUpDic.find(x => x.key == fieldId);

                        if (lk) {

                            d.value = lk.value;

                            const keyNewArray = getLookUpKeyNew(lookUpDic.filter(x => x.key != lk.key));

                            if (valueLookUp.length > 0) {
                                valueLookUp.concat(keyNewArray);
                            }
                            else {
                                valueLookUp = keyNewArray;
                            }

                        }


                    }
                });

                const dataInputRequest = JSON.parse(JSON.stringify(dataInput.value.concat(valuesNoAdd).concat(valueLookUp)));

                const dataUpdate: dataUpdate = {
                    valores: dataInputRequest.map(x => ({
                        fieldId:  getFieldId(x.fieldId,tablasData.value),
                        value: isColumnType(x.fieldId, SqlTypesConst.BIT) ? JSON.stringify(x.value) : x.value
                    })) as any,
                    claves: [],
                    tipoOperacion: OperationDataTypeConst.INSERT,
                    id: HelperUtils.newGuid()
                };
                if (props.container) {

                    const AppId=props.idObjeto==CatalogObjectTypeConst.DSF?props.Component.applicationId:props.dataSourceLookUp.dataSource.applicationId as any;
                    const AppVer=props.idObjeto==CatalogObjectTypeConst.DSF?props.Component.applicationVersion:props.dataSourceLookUp.dataSource.applicationVersion as any;
                    const comp=props.idObjeto==CatalogObjectTypeConst.DSF?props.Component.id:0;
                    const compDs=props.idObjeto==CatalogObjectTypeConst.DSF?props.compDataSource.id:0;
                    const params=props.idObjeto==CatalogObjectTypeConst.DSF?props.compDataSource.dataSource.iapDataSourceServiceConfigurations:[];
                    const ds=props.idObjeto==CatalogObjectTypeConst.DSF?props.compDataSource.dataSourceId:0;

                    const _srv = props.container.get<IServiceDataUpdate>(TYPES.DATAUPDATE_REPOSITORY)
                    HelperLoading.showSaving()
                    const requestData = JSON.parse(JSON.stringify(dataUpdate))
                   
                    const dataInputRequest:DataUpdateOperation={
                        componentId:comp,
                        componentDataSourceId:compDs,
                        dataSourceId:ds,
                        parameters:params??[],
                        data:[requestData]
                    }

                    _srv.update(AppId,AppVer,[dataInputRequest], false, props.dataSourceLookUp).then(response => {
                        if (response) {
                            if (HelperDataUpdate.hasErrorResponse(response)) {
                                HelperDataUpdate.formatErrorResponse(response);
                            }
                            else {
                                MessageService.showToast(MessageType.Correcto, "", 'Registro creado correctamente.')
                                emit('refreshData',response);
                            }

                        }
                    })
                        .finally(() => {
                            HelperLoading.hideSaving()
                        })
                }



            }

        }

        const getLookUpKeyNew = (dic: Dictionary<String, String>[]): claveNew[] => {

            let result: claveNew[] = [];

            dic.forEach((dicElement: any) => {

                const dsf = tablasData.value.find(dsf => dsf.id == dicElement.key);

                if (dsf) {
                    result.push({
                        fieldId: dsf.id,
                        value: dicElement.value,
                        required: true
                    } as any);
                }

            });

            return result;
        }


        const selectedLookUpData = (campo: string, data: any) => {
            lookUpData.value = data;

            const dsLk = getFieldFromKeyCol(campo,false,tablasData.value);
            // se actualizan el resto de campos
            Object.keys(lookUpData.value).filter(x => x != DataSourceConst.LOOKUPDESC
                && !x.includes(DataSourceConst.LOOKUPID + DataSourceConst.DELIMITERFIELDS)
            ).forEach(_field => {
                const newValue = lookUpData.value[_field]
                const dslnkFields = dsLk?.dataSourceLookUp?.dataSource.iapDataSourceFields.map(x => ({ id: x.id, field: getFieldKeyColumn(x) }));
                const dataSourceFieldIdSource = dslnkFields?.find((x: any) => x.field == _field)?.id;
                const dataSourceFieldIdTarget = dsLk?.dataSourceLookUp?.iapDataSourceLookUpFieldMaps.find(x => x.dataSourceFieldIdSource == dataSourceFieldIdSource && x.isFieldLinked == false && x.isFieldFiltered == false)?.dataSourceFieldIdTarget;

                const _fieldToUpdate = tablasData.value.find(x => x.id == dataSourceFieldIdTarget)

                const _fieldNew = getFieldKeyColumn(_fieldToUpdate);

                const item = dataInput.value.find(x => x.fieldId == _fieldNew)
                if (item) {
                    item.value = newValue
                }

            })

            //actualizamos los filtros de los lookUps
            const lookUpFilters = getNewFiltersConditions(campo, data);

            lookUpFilters.forEach(lkf => {
                const index = dataInput.value.findIndex(x => x.fieldId == lkf.fieldName);

                if (index > -1) {
                    const value = dataInput.value[index] as any
                    value.lookUpFilter.lookUpFilter = value.lookUpFilter.initialFilterlk.concat(lkf.filters);
                    dataInput.value[index] = value as any;
                }
            });


            //limpiamos filtros de lookUp
            const cleanLookUps = cleanLookUpDependency([campo]);

            cleanLookUps.forEach(f => {

                const index = dataInput.value.findIndex(x => x.fieldId == f);

                if (index > -1) {
                    if (dataInput.value[index].value != null) {
                        const datInp = dataInput.value[index] as any
                        datInp.value = null;
                        dataInput.value[index] = datInp as any;
                    }

                }

            });

            cleanLookUp.value = [];

        }


        onMounted(() => {
            
            
            dataInput.value  = tablasData.value.filter(x => ((!x.identityColumn && !x.primaryKey) || (!x.identityColumn && x.primaryKey))
                && objDsfConfiguration.value.find(c => c.dataSourceFieldId == x.id)?.add
            ).sort((a, b) => { return (a.position ?? 0) - (b.position ?? 0); }).map(x => ({
                fieldId: getFieldKeyColumn(x),
                value: getDefaultvalue(x),
                required: !x.nullable || objDsfConfiguration.value.find(c => c.dataSourceFieldId == x.id)?.mandatory,
                lookUpFilter:{initialFilterlk:lookUpFilterExp(getFieldKeyColumn(x))??[], lookUpFilter:lookUpFilterExp(getFieldKeyColumn(x))??[]}
            })) as any

            


        })




        return {
            t,
            v$,
            submitted,
            SqlTypesConst,
            dataInput,
            isColumnType,
            isCatalogTypeValue,
            isTableTypeValue,
            getCatalogData,
            getColumnName,
            saveData,
            hasLookUpByFieldId,
            getLookUpByFieldId,
            selectedLookUpData
        };
    },
});
</script>
<style scoped></style>
