import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_Badge = _resolveComponent("Badge")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? _withDirectives((_openBlock(), _createBlock(_component_Badge, {
          key: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.BadgeTypeConst.ID),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.BadgeTypeConst.STYLE)),
          class: _normalizeClass({ [_ctx.getPropertyValue(_ctx.BadgeTypeConst.CLASS) ?? '']: true, 'customrequired': _ctx.getPropertyBooleanValue(_ctx.BadgeTypeConst.REQUIRED) }),
          name: _ctx.getPropertyValue(_ctx.BadgeTypeConst.NAME),
          disabled: _ctx.getPropertyBooleanValue(_ctx.BadgeTypeConst.DISABLED),
          visible: _ctx.getPropertyBooleanValue(_ctx.BadgeTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW),
          placeholder: _ctx.getPropertyValue(_ctx.BadgeTypeConst.PLACEHOLDER),
          severity: _ctx.getCatalogValue(_ctx.BadgeTypeConst.SEVERITY),
          size: _ctx.getCatalogValue(_ctx.BadgeTypeConst.SIZE),
          value: _ctx.vmodel
        }, null, 8 /* PROPS */, ["id", "style", "class", "name", "disabled", "visible", "placeholder", "severity", "size", "value"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.BadgeTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}