<template>  
   <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />  
    <Fieldset v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(FieldsetTypeConst.ID)" v-tooltip="getPropertyValue(FieldsetTypeConst.TOOLTIP)"
        :style="getPropertyValue(FieldsetTypeConst.STYLE)" :class="getPropertyValue(FieldsetTypeConst.CLASS)"
        :name="getPropertyValue(FieldsetTypeConst.NAME)" :disabled="getPropertyBooleanValue(FieldsetTypeConst.DISABLED)"
        :visible="getPropertyValue(FieldsetTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :placeholder="getPropertyValue(FieldsetTypeConst.PLACEHOLDER)" :legend="getPropertyValue(FieldsetTypeConst.LEGEND)"
        :toggleable="getPropertyBooleanValue(FieldsetTypeConst.TOGGLEABLE)"
        :collapsed="getPropertyBooleanValue(FieldsetTypeConst.COLLAPSED)" v-model="vmodel">
        <template #legend>
            <slot name="legend"></slot>
        </template>
        <slot name="content"></slot>

    </Fieldset>
</template>
<script lang="ts">


import { defineComponent, onMounted, ref, onBeforeUnmount, onUnmounted } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import FieldsetTypeConst from '../../../../domain/Constants/FieldsetTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';	

export default defineComponent({
    name: 'dynamic_fieldset',
    components: {
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
       
        slotProps: {
            type: Object,
            default: () => ({})
        },

    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, loaded, getPropertyValue, getPropertyBooleanValue, resolveExpressions, baseOnUnmount ,
            fieldKey,fieldKeyComponentDataSourceId,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);


        onMounted(() => {
        })

        onUnmounted(() => {
            baseOnUnmount();
        })


        return {
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            FieldsetTypeConst,
            loaded,
            BaseControlTypeConst,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
        };
    },
});
</script>
<style scoped></style>
