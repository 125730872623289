import { ClassicPreset } from "rete";
import { Dictionary } from "../../../../../expression/domain/dictionary";

export class RedirectControl extends ClassicPreset.Control {
  constructor(
    public variableRedirectTypeInput: Number,
    public variableParamsRedirectUrlInput: String,
    public variableParamsRedirectUrlTargetInput: String,
    public variableParamsRedirectUrlParamsInput: String,
    public variableMenuInput: Number,
    public variableParamsRedirectInput: Dictionary<String,Dictionary<String,String>>[], 
    public onChange: (evt: any) => void,
    public getNode: (evt: any) => void, 
    public showExpression:(evt:any) => void) {
    super();
  }
}


