import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_Password = _resolveComponent("Password")!
  const _component_CustomValidate = _resolveComponent("CustomValidate")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? _withDirectives((_openBlock(), _createBlock(_component_Password, {
          key: 1,
          KEY: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.PasswordTypeConst.ID),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.PasswordTypeConst.STYLE)),
          class: _normalizeClass({ [_ctx.getPropertyValue(_ctx.PasswordTypeConst.CLASS) ?? '']: true, 'p-invalid': _ctx.v$.vmodel.$error && _ctx.submitted,'p-readonly': _ctx.getPropertyBooleanValue(_ctx.PasswordTypeConst.READONLY) }),
          name: _ctx.getPropertyValue(_ctx.PasswordTypeConst.NAME),
          disabled: _ctx.getPropertyBooleanValue(_ctx.PasswordTypeConst.DISABLED) || (!_ctx.canEdit),
          visible: _ctx.getPropertyBooleanValue(_ctx.PasswordTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW),
          placeholder: _ctx.getPropertyValue(_ctx.PasswordTypeConst.PLACEHOLDER),
          promptLabel: _ctx.getPropertyValue(_ctx.PasswordTypeConst.PROMPTLABEL),
          mediumRegex: _ctx.getPropertyValue(_ctx.PasswordTypeConst.MEDIUMREGEX),
          strongRegex: _ctx.getPropertyValue(_ctx.PasswordTypeConst.STRONGREGEX),
          weakLabel: _ctx.getPropertyValue(_ctx.PasswordTypeConst.WEAKLABEL),
          mediumLabel: _ctx.getPropertyValue(_ctx.PasswordTypeConst.MEDIUMLABEL),
          strongLabel: _ctx.getPropertyValue(_ctx.PasswordTypeConst.STRONGLABEL),
          feedback: _ctx.getPropertyBooleanValue(_ctx.PasswordTypeConst.FEEDBACK),
          toggleMask: _ctx.getPropertyBooleanValue(_ctx.PasswordTypeConst.TOGGLEMASK),
          modelValue: _ctx.vmodel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vmodel) = $event)),
          readonly: _ctx.getPropertyBooleanValue(_ctx.PasswordTypeConst.READONLY)
        }, null, 8 /* PROPS */, ["KEY", "id", "style", "class", "name", "disabled", "visible", "placeholder", "promptLabel", "mediumRegex", "strongRegex", "weakLabel", "mediumLabel", "strongLabel", "feedback", "toggleMask", "modelValue", "readonly"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.PasswordTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE))
      ? (_openBlock(), _createBlock(_component_CustomValidate, {
          key: 2,
          submitted: _ctx.submitted,
          "onUpdate:submitted": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.submitted) = $event)),
          vObject: _ctx.v$,
          "onUpdate:vObject": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$) = $event))
        }, null, 8 /* PROPS */, ["submitted", "vObject"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}