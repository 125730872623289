export default class DataSourceNodeConst {
    static VAR_INCEPTION_IN='variableInceptionInput'; // origen componente datasource  o datasource sin componente
    static VAR_COMPONENTID_IN='variableComponentIdInput';
    static VAR_COMPONENTDATASOURCEID_IN='variableComponentDataSourceIdInput';

    static VAR_COMPONENTDATASOURCELISTID_IN='variableComponentDataSourceListIdInput';
    static VAR_CFG_IN='variableCfgsInput';
    static VAR_DS_OPERATION='variableOperationInput';
    static VAR_FIELD_IN='variableFieldsInput';
    static VAR_CONN_IN='variableConnectionInput';
    static VAR_DS_IN='variableDsIdInput';
    
  }