<!-- eslint-disable no-self-compare -->
<template>
  <div>
      <form @submit.prevent="handleSubmit(!v$.$invalid)">
          <div class="limiter">
              <div class="container-login100">
                  <div class="wrap-login100">

                      <span class="login100-form-logo">
                          <i class="zmdi zmdi-landscape"></i>
                          <img :src="getLogoUrl" style="width: 100px" />
                          <!--Logo Base64 -->
                          <!--<img v-bind:src="'data:image/png;base64,'+ appCfg.logopng" style="width: 100px" />-->
                      </span>
                      <span class="login100-form-title p-b-34 p-t-27 mb-5">

                      </span>
                      <div class="txt1">
                          Contraseña
                      </div>
                      <span class="p-float-label wrap-input100 validate-input">
                          <input id="password" class="input100" type="password" name="passwordInput"
                                placeholder="Contraseña" v-model="request.password" v-on:keyup.enter="onSubmitResetPass"
                                toggle-mask
                                :class="{ 'p-password': true, 'p-invalid': v$.password.$invalid && submitted }" />
                          <label style="color:white;font-size: 1.5rem" class="pi pi-lock" for="password" />
                      </span>
                      <span>
                          <small v-if="(v$.password.$invalid && submitted) || v$.password.$pending.$response"
                              class="p-error">{{ v$.password.required.$message }}</small>
                      </span>
                      <div class="txt1">
                          Repita la contraseña
                      </div>
                      <span class="p-float-label wrap-input100 validate-input">
                        <input id="confirmpass" class="input100" type="password" name="confirmpassdInput"
                                placeholder="Contraseña" v-model="request.confirmpass" v-on:keyup.enter="onSubmitResetPass"
                                toggle-mask
                                :class="{ 'p-confirmpass': true, 'p-invalid': v$.confirmpass.$invalid && submitted }" />
                          <label style="color:white;font-size: 1.5rem" class="pi pi-lock" for="confirmpass" />
                      </span>
                      <span>
                          <small v-if="(v$.confirmpass.$invalid && submitted) || v$.confirmpass.$pending.$response"
                              class="p-error">{{ v$.confirmpass.required.$message }}</small>
                      </span>
                      <div class="container-login100-form-btn">
                          <button class="login100-form-btn" id="submitButton" type="submit" @click="onSubmitResetPass">
                              Restablecer
                          </button>
                      </div>
                      <div class="text-center p-t-90 mt-3">
                          <a class="txt1" href="#" @click="back">
                              Volver a la página de acceso.
                          </a>
                      </div>
                  </div>
              </div>
          </div>
      </form>
  </div>

        
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-unused-vars */
import { computed, ref, inject } from 'vue'
import { useStore } from 'vuex'
import { Container } from 'inversify';
import Environment from '../../../../../common/infrastructure/funciones/environment'
import { appConfig } from "../../domain/appConfig";
import Button from 'primevue/button'
import MessageService, { MessageType } from '@ilinium/shared/src/common/infrastructure/servicios/MessageService';
import HelperCommon from '@ilinium/shared/src/common/infrastructure/funciones/HelperCommon';
import { helpers, required } from "@vuelidate/validators";
import { resetPass } from "../../domain/resetPass";
import useVuelidate from "@vuelidate/core";
import HelperLoading from "../../../../../common/infrastructure/funciones/HelperLoading";
import {
  Actions,
  Mutations,
} from "../../../../../common/infrastructure/almacen/enums/StoreEnums";

export default {
  components: { Button },
  name: 'ResetPass',
  props:
    {
        container: {
            type: Object as () => Container
        },
        getLogoUrl: {
            type: String,
            default: ''
        },
        appCfg: {
          type: Object as () => appConfig
        },
        rid: {
            type: String,
            default: '0'
        },
    },
  emits: ['isResetPassChild'],
  setup (props, { emit }) {
    
    const store = useStore()
    const passwordControl = ref < HTMLElement | null > (null)
    const passwordConfirmControl = ref < HTMLElement | null > (null)
    const password = ref('')
    const confirmpass = ref('')
    const ipublicPath = inject('publicPath')
    const submitted = ref(false);
    
    let my_color1 = props.appCfg.color1;
    let my_color2 = props.appCfg.color2;
    let rid = computed(() => props.rid.replace('rid=', ''));
    
    /* const user_req: Partial<Usuario> = {
            email: ''
        };

        const request = ref<forgetPasswordRequest>({
            user: user_req as any,
            remoteApplication: 'Portal',
            remoteIp: '',
            requestedUrl: '',
        }); */

    const back = (event: any) => {
      
      emit('isResetPassChild', false);
    }

    const isValidResetPassData = (): boolean => {
      submitted.value = true;
      v$.value.$touch();
      if (v$.value.$invalid) {
        HelperCommon.setFocusOnFirstError();
        return false;
      }

      return true;
    };

    const onSubmitResetPass = () => {
      if (isValidResetPassData()) {
        store.dispatch(Actions.RESET_PASSWORD, [request.value.password, rid.value, Environment.APPID, Environment.APPVERSION, props.container]).then(() => {
            MessageService.showToast(MessageType.Correcto, '', 'Ha sido modificada la contraseña de la cuenta en nuestro sistema correctamente.');
            emit('isResetPassChild', false);
            //router.push({ name: 'forgotpassword' });
        }).finally(() => {
            HelperLoading.hideLoading();
        });
      }
    }

    const getLogoUrl = computed(() => {
      return Environment.URL_LOGO_PNG;      
    })

    const request = ref<resetPass>({
      email: "",
      emailconfirm: "",
      password: "",
      confirmpass: ""
    });

    const customPassword = (value: string) => {
      if (value.length < 5) {
        return false;
      }
      // Todo:
      return true;
    };

    const rules = {
      password: {
        required: helpers.withMessage("La contraseña es obligatoria", required),
        customPassword: helpers.withMessage("Contraseña no válida", customPassword)
      },
      confirmpass: {
        required: helpers.withMessage("Confirmar la contraseña es obligatorio", required),
        customPassword: helpers.withMessage("Contraseña no válida", customPassword),
      },
    };

    const v$ = useVuelidate(rules, request);

    return {
      passwordControl,
      passwordConfirmControl,
      confirmpass,
      password,
      onSubmitResetPass,
      getLogoUrl,
      ipublicPath,
      my_color1,
      my_color2,
      back,
      v$,
      submitted,
      request,
    }
  }
}
</script>

<style lang="scss" scoped src="./css/login.scss"></style>
<style lang="css" scoped src="./css/theme.css"></style>
<style lang="css" scoped >
    .wrap-login100 {
        width: 500px;
        border-radius: 10px;
        overflow: hidden;
        padding: 55px 55px 37px;
        background: #9152f8;
        background: -webkit-linear-gradient(bottom, v-bind(my_color1), v-bind(my_color2));
        background: -o-linear-gradient(bottom, v-bind(my_color1), v-bind(my_color2));
        background: -moz-linear-gradient(bottom, v-bind(my_color1), v-bind(my_color2));
        background: linear-gradient(v-bind(my_color1), v-bind(my_color2));
        opacity: 0.8;
    }
</style>
