export default class DynamicControlNameConst {
    
static DYNAMIC_CALENDAR = 'DynamicCalendar';
static DYNAMIC_CRUD_TABLA = 'DynamicCrudTabla';
static DYNAMIC_EMAIL = 'DynamicEmail';
static DYNAMIC_SET_PASSWORD = 'DynamicSetPassword';
static DYNAMIC_BROWSING = 'DynamicBrowsing';
static DYNAMIC_SIGNINWITH = 'DynamicSignInWith';
static DYNAMIC_LOGOUT = 'DynamicLogout';
static DYNAMIC_WHO_AM_I_PROFILES = 'DynamicWhoAmIProfiles';
static DYNAMIC_LANGUAGE = 'DynamicLanguage';
static DYNAMIC_SETUP = 'DynamicSetup';
static DYNAMIC_LAYOUT = 'DynamicLayout';
static DYNAMIC_BUTTON = 'DynamicButton';
static DYNAMIC_PANEL = 'DynamicPanel';
static DYNAMIC_CHECK_BOX = 'DynamicCheckBox';
static DYNAMIC_TRI_STATE_CHECK_BOX = 'DynamicTriStateCheckBox';
static DYNAMIC_SELECT_BUTTON = 'DynamicSelectButton';
static DYNAMIC_TREE = 'DynamicTree';
static DYNAMIC_TREE_SELECT = 'DynamicTreeSelect';
static DYNAMIC_TREE_TABLE = 'DynamicTreeTable';
static DYNAMIC_DROP_DOWN = 'DynamicDropDown';
static DYNAMIC_DROP_DOWN_CASCADE = 'DynamicDropDownCascade';
static DYNAMIC_INPUT_SWITCH = 'DynamicInputSwitch';
static DYNAMIC_INPUT_TEXT = 'DynamicInputText';
static DYNAMIC_INPUT_NUMBER = 'DynamicInputNumber';
static DYNAMIC_LABEL = 'DynamicLabel';
static DYNAMIC_RADIO_BUTTON = 'DynamicRadioButton';
static DYNAMIC_MULTI_SELECT = 'DynamicMultiSelect';
static DYNAMIC_CHIP = 'DynamicChip';
static DYNAMIC_DIVIDER = 'DynamicDivider';
static DYNAMIC_IMAGE = 'DynamicImage';
static DYNAMIC_HTML_A = 'DynamicHtmlA';
static TAB_PANEL = 'TabPanel';
static DYNAMIC_TAB_VIEW = 'DynamicTabView';
static DYNAMIC_TEXT_AREA = 'DynamicTextArea';
static DYNAMIC_HTML_SPAN = 'DynamicHtmlSpan';
static DYNAMIC_HTML_B = 'DynamicHtmlB';
static DYNAMIC_HTML_BR = 'DynamicHtmlBr';
static DYNAMIC_HTML_EM = 'DynamicHtmlEm';
static DYNAMIC_HTML_H1 = 'DynamicHtmlH1';
static DYNAMIC_HTML_H2 = 'DynamicHtmlH2';
static DYNAMIC_HTML_H3 = 'DynamicHtmlH3';
static DYNAMIC_HTML_H4 = 'DynamicHtmlH4';
static DYNAMIC_HTML_H5 = 'DynamicHtmlH5';
static DYNAMIC_HTML_H6 = 'DynamicHtmlH6';
static DYNAMIC_HTML_HR = 'DynamicHtmlHr';
static DYNAMIC_HTML_I = 'DynamicHtmlI';
static DYNAMIC_HTML_P = 'DynamicHtmlP';
static DYNAMIC_HTML_SMALL = 'DynamicHtmlSmall';
static DYNAMIC_HTML_STRONG = 'DynamicHtmlStrong';
static DYNAMIC_HTML_DIV = 'DynamicHtmlDiv';
static DYNAMIC_HTML_UL = 'DynamicHtmlUl';
static DYNAMIC_HTML_LI = 'DynamicHtmlLi';
static DYNAMIC_CARD = 'DynamicCard';
static DYNAMIC_TIME_LINE = 'DynamicTimeLine';
static DYNAMIC_AVATAR = 'DynamicAvatar';
static DYNAMIC_TOOL_BAR = 'DynamicToolBar';
static DYNAMIC_TAG = 'DynamicTag';
static DYNAMIC_SCROLL_PANEL = 'DynamicScrollPanel';
static DYNAMIC_DATA_TABLE = 'DynamicDataTable';
static DYNAMIC_COLUMN = 'DynamicColumn';
static DYNAMIC_HTML_INPUT = 'DynamicHtmlInput';
static DYNAMIC_HTML_IFRAME = 'DynamicHtmlIframe';
static DYNAMIC_DATA_VIEW = 'DynamicDataView';
static DYNAMIC_MENU = 'DynamicMenu';
static DYNAMIC_MENU_ITEM = 'DynamicMenuItem';
static DYNAMIC_RATING = 'DynamicRating';
static DYNAMIC_FIELDSET = 'DynamicFieldset';
static DYNAMIC_LOOK_UP = 'DynamicLookUp';
static DYNAMIC_PASSWORD = 'DynamicPassword';
static DYNAMIC_BADGE = 'DynamicBadge';
static DYNAMIC_KNOB = 'DynamicKnob';
static DYNAMIC_COLOR_PICKER = 'DynamicColorPicker';
static DYNAMIC_TOOGLE_BUTTON = 'DynamicToogleButton';
static DYNAMIC_EDITOR = 'DynamicEditor';
static DYNAMIC_SPLIT_BUTTON = 'DynamicSplitButton';
static DYNAMIC_DIALOG = 'DynamicDialog';
static DYNAMIC_AUTOCOMPLETE = 'DynamicAutocomplete';
static DYNAMIC_CHART_BAR = 'DynamicChartBar';
static DYNAMIC_CHART_CIRC = 'DynamicChartCirc';
static DYNAMIC_CRUD_DOCUMENT = 'DynamicCrudDocument';
static DYNAMIC_FULL_CALENDAR = 'DynamicFullCalendar';
static DYNAMIC_INPUT_MASK = 'DynamicInputMask';
static DYNAMIC_STRUCTURED_FIELD = 'DynamicStructuredField';
static DYNAMIC_EXPRESSION = 'DynamicExpression';

}
    