<template>
    <Toolbar class="stickyStepsCss">
               <template #start>
                   <Button id="anterior" label="Anterior" icon="pi pi-arrow-left" class="p-button-primary"
                       v-if="(d_activeIndex ?? 0 > 0)" @click="$emit('previous', $event)" />
               </template>
               <template #end >
                   <slot name="customcontrolsendfirst"></slot>              
                   <Button id="siguiente" label="Siguiente" icon="pi pi-arrow-right" class="p-button-primary"
                       v-if="d_activeIndex >= 0 && (tabs.length - 1) !== d_activeIndex" @click="$emit('next', $event)" />  
                   <slot name="customcontrolsend"></slot>              
                   <div v-if="isVisibleEndButton">
                       <Button id="finalizar" label="Finalizar" icon="pi pi-pi-check" class="p-button-primary"
                           v-if="d_activeIndex >= 0 && (tabs.length - 1) == d_activeIndex"
                           @click="$emit('finish', $event)" />
                   </div>
               </template>
       </Toolbar>
       <div :id="id" :class="containerClass">
           <ul ref="nav" role="tablist">
               <template v-for="(item, index) of tabs" :key="item.to">
                   <li :class="getItemClass(index, item)" :style="item.style" role="tab" :aria-selected="isActive(index)"
                       :aria-expanded="isActive(index)" @click="doClickItem(index)">
                       <template v-if="!$slots.item">
                           <span :class="linkClass()" role="presentation">
                               <span class="p-steps-number">{{ index + 1 }}</span>
                               <span class="p-steps-title">{{ label(item) }}</span>
                               <span class="p-steps-title" v-if="item.props && item.props.header">{{ item.props.header
                               }}</span>
                               <component :is="item.children.header" v-if="item.children && item.children.header">
                               </component>
                           </span>
                       </template>
   
                   </li>
                   <li ref="inkbar" class="p-tabview-ink-bar"></li>
               </template>
           </ul>
           <div class="p-tabview-panels">
               <template v-for="(tab, i) of tabs" :key="getKey(tab,i)">
                   <div class="p-tabview-panel" role="tabpanel" v-if="lazy ? (d_activeIndex === i) : true"
                       v-show="lazy ? true : (d_activeIndex === i)">
                       <component :is="tab">
                       </component>
                   </div>
               </template>
           </div>
          
       </div>
   </template>
   
   <script>
   import { UniqueComponentId } from 'primevue/utils';
   
   
   export default {
       name: 'CustomSteps',
       props: {
           id: {
               type: String,
               default: UniqueComponentId()
           },
           model: {
               type: Array,
               default: null
           },
           readonly: {
               type: Boolean,
               default: true
           },
           exact: {
               type: Boolean,
               default: true
           },
           activeIndex: {
               type: Number,
               default: 0
           },
           lazy: {
               type: Boolean,
               default: false
           },
           scrollable: {
               type: Boolean,
               default: false
           },
           showEndButton: {
               type: Boolean,
               default: true
           }
       },
       emits: ['previous', 'next', 'finish','navigateTo'],
       data() {
           return {
               d_activeIndex: this.activeIndex,
               isVisibleEndButton: this.showEndButton,
           }
       },
       watch: {
           activeIndex(newValue) {
               this.d_activeIndex = newValue;
   
               this.updateScrollBar(newValue);
           }
       },
       methods: {
   
           doClickItem(index) {
               if(!this.readonly){
                   this.$emit('navigateTo',index);
               }
           },
           updateScrollBar(index) {
               let tabHeader = this.$refs.nav.children[index];
               tabHeader.scrollIntoView({ block: 'nearest' })
           },
           onItemClick(event, item, navigate) {
               if (this.disabled(item) || this.readonly) {
                   event.preventDefault();
                   return;
               }
   
               if (item.command) {
                   item.command({
                       originalEvent: event,
                       item: item
                   });
               }
   
               if (item.to && navigate) {
                   navigate(event);
               }
           },
           isActive(index) {
               return (index === this.activeIndex);
               //return this.activeRoute === item.to || this.activeRoute === item.to + '/' ;
           },
           getItemClass(index, item) {
               return ['p-steps-item', item.class, {
                   'p-highlight p-steps-current': this.isActive(index),
                   'p-disabled': this.isItemDisabled(item)
               }];
           },
           linkClass(routerProps) {
               return ['p-menuitem-link', {
                   'router-link-active': routerProps && routerProps.isActive,
                   'router-link-active-exact': this.exact && routerProps && routerProps.isExactActive
               }];
           },
           isItemDisabled(item) {
               return (this.disabled(item) || (this.readonly && !this.isActive(item)));
           },
           visible(item) {
               //return (typeof item.visible === 'function' ? item.visible() : item.visible !== false);
               if (item.visible == false) {
                return false;
              }
           },
           disabled(item) {
               return (typeof item.disabled === 'function' ? item.disabled() : item.disabled);
           },
           label(item) {
               return (typeof item.label === 'function' ? item.label() : item.label);
           },
           getKey(tab, i) {
               if (tab.props && tab.props.header) {
                   return tab.props.header;
               }
               if (tab.props && tab.props.key) {
                   return tab.props.key;
               }
               return i;
           },
           isTabDisabled(tab) {
               return (tab.props && tab.props.disabled);
           },
           isTabPanel(child) {
               return child.type.name === 'TabPanel'
           },
   
       },
       computed: {
   
   
           activeRoute() {
               return this.$route.path;
           },
           containerClass() {
               return ['p-steps p-component', { 'p-readonly': this.readonly }];
           },
           tabs() {
               const tabs = []
               this.$slots.default().forEach(child => {
                   if (this.isTabPanel(child)) {
                       tabs.push(child);
                   }
                   else if (child.children && child.children instanceof Array) {
                       child.children.forEach(nestedChild => {
                           if (this.isTabPanel(nestedChild)) {
                               tabs.push(nestedChild)
                           }
                       });
                   }
               }
               )
               return tabs;
           }
       }
   }
   </script>
   
   <style>
   .p-steps {
       position: relative;
   }
   
   .p-steps ul {
       padding: 0;
       margin: 0;
       list-style-type: none;
       display: flex;
   }
   
   .p-steps-item {
       position: relative;
       display: flex;
       justify-content: center;
       flex: 1 1 auto;
   }
   
   .p-steps-item .p-menuitem-link {
       display: inline-flex;
       flex-direction: column;
       align-items: center;
       overflow: hidden;
       text-decoration: none;
   }
   
   .p-steps.p-steps-readonly .p-steps-item {
       cursor: auto;
   }
   
   .p-steps-item.p-steps-current .p-menuitem-link {
       cursor: default;
   }
   
   .p-steps-title {
       white-space: nowrap;
   }
   
   .p-steps-number {
       display: flex;
       align-items: center;
       justify-content: center;
   }
   
   .p-steps-title {
       display: block;
   }
   </style>
   