import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IDataSourceRepository } from '../domain/IDataSourceRepository';

import { IServiceDataSource } from './IServiceDataSource';
import { IapDataSource } from '../domain/iapDataSource';
import { IapDataSourceDataBase } from "../domain/iapDataSourceDataBase";
import { IapDataSourceField } from "../domain/iapDataSourceField";
import { IapDataSourceService } from "../domain/iapDataSourceService";
import { IapDataSourceTableAlias } from "../domain/iapDataSourceTableAlias";
import { IapDataSourceServiceConfiguration } from "../domain/iapDataSourceServiceConfiguration"



@injectable()
export class DataSourceService implements IServiceDataSource {
  private readonly repo: IDataSourceRepository

  public constructor (@inject(TYPES.DATASOURCE_REPOSITORY) _repo: IDataSourceRepository) {
    this.repo = _repo
  }

  async update (data: IapDataSource): (Promise<IapDataSource>) {
    return await this.repo.update(data);
  }

  async delete(dataSourceId: number) :Promise<boolean> {
    return await this.repo.delete(dataSourceId)
  }

  async add (data: IapDataSource, configurations: IapDataSourceServiceConfiguration[], tables:IapDataSourceTableAlias[], db:IapDataSourceDataBase, srv:IapDataSourceService,fields:IapDataSourceField[]): (Promise<IapDataSource>) {
    return await this.repo.add(data,configurations,tables,db,srv,fields);
  }

  async modify (data: IapDataSource, tables:IapDataSourceTableAlias[], db:IapDataSourceDataBase, srv:IapDataSourceService,fields:IapDataSourceField[]): (Promise<IapDataSource>) {
    return await this.repo.modify(data,tables,db,srv,fields);
  }
  
  async refreshFields (data: IapDataSource): (Promise<IapDataSource>) {
    return await this.repo.refreshFields(data);
  }


  async getAll(applicationId:number, applicationVersion:number) :Promise<IapDataSource[]> {
    return await this.repo.getAll(applicationId, applicationVersion)
  }

  async getById(applicationId:number, applicationVersion:number, datasourceId: number) :Promise<IapDataSource> {
    return await this.repo.getById(applicationId, applicationVersion, datasourceId)
  }
}
