import { IServiceDobleAuthenticationUser } from './IServiceDobleAuthenticationUser'
import type { IDobleAuthenticationUserRepository } from '../domain/IDobleAuthenticationUserRepository'
import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'
import { uploadFile } from '@ilinium/shared/src/entidades/builderMaster/auth/domain/uploadFile'

@injectable()
export class DobleAuthenticationUserService implements IServiceDobleAuthenticationUser {
  private readonly dobleAuthenticationUserRepo: IDobleAuthenticationUserRepository

  public constructor (@inject(TYPES.DOBLE_FACTOR_USER_REPOSITORY) _dobleAuthenticationUserRepo: IDobleAuthenticationUserRepository) {
    this.dobleAuthenticationUserRepo = _dobleAuthenticationUserRepo
  }
  async sendMail (userId: number, to: string, cc: string, subject: string, body: string, attachments: [uploadFile]): (Promise<boolean>) {
    return await this.dobleAuthenticationUserRepo.sendMail(userId, to, cc, subject, body, attachments)
  }
}