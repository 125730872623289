import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import { IImportConfigRepository } from '../domain/IImportConfigRepository';


import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { GETALL,GETBYID,GETBYFIELDKEY } from './query/importConfigQuery';
import { ADD, DELETE, UPDATE } from './mutation/importConfigMutation';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';
import { IapmImportConfig } from "../domain/iapmImportConfig";


@injectable()
export class ImportConfigApolloClientRepository implements IImportConfigRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }
     
 

  async update (dataInput: IapmImportConfig): (Promise<IapmImportConfig>) {
    return new Promise<IapmImportConfig>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.importConfigMutation.updateImportConfig)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async add (dataInput: IapmImportConfig): (Promise<IapmImportConfig>) {
    return new Promise<IapmImportConfig>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.importConfigMutation.addImportConfig)
        }
        catch(e) {
          reject()
        }
      });

    });
  }


  async delete (Id: number): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
     
        const result = this.apolloClient.executeQuery(DELETE,{id: Id}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.importConfigMutation.deleteImportConfig)
        }
        catch(e) {
          reject()
        }
      });
    
    });
  }


  async getAll (): (Promise<IapmImportConfig[]>) {
    return new Promise<IapmImportConfig[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GETALL, { },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.importConfigQuery.getAll)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


  async getById (Id: number): (Promise<IapmImportConfig[]>) {
    return new Promise<IapmImportConfig[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GETBYID, { id: Id },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.importConfigQuery.getById)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getByFieldKey (fieldKey: string): (Promise<IapmImportConfig[]>) {
    return new Promise<IapmImportConfig[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GETBYFIELDKEY, { fieldKey: fieldKey },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.importConfigQuery.getByFieldKey)
        }
        catch(e) {
          reject()
        }
      });
    });
  }



}


