<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <div v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && !getPropertyBooleanValue(InputSwitchTypeConst.READONLY) && canDoOperation(ObjectGroupConst.VIEW)">
        <InputSwitch 
            :key="componentKey"
            :id="getPropertyValue(InputSwitchTypeConst.ID)" v-tooltip="getPropertyValue(InputSwitchTypeConst.TOOLTIP)"
            :style="getPropertyValue(InputSwitchTypeConst.STYLE)"
            :class="{ [getPropertyValue(InputSwitchTypeConst.CLASS) ?? '']: true, 'p-invalid': v$.vmodel.$error && submitted, 'p-readonly': getPropertyBooleanValue(InputSwitchTypeConst.READONLY) }"
            :name="getPropertyValue(InputSwitchTypeConst.NAME)"
            :disabled="getPropertyBooleanValue(InputSwitchTypeConst.DISABLED) || (!canEdit)"
            :visible="getPropertyBooleanValue(InputSwitchTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
            :placeholder="getPropertyValue(InputSwitchTypeConst.PLACEHOLDER)"
            :trueValue="getPropertyBooleanValue(InputSwitchTypeConst.TRUEVALUE)"
            :falseValue="getPropertyBooleanValue(InputSwitchTypeConst.FALSEVALUE)"
            :inputId="getPropertyValue(InputSwitchTypeConst.INPUTID)"
            :readonly="getPropertyBooleanValue(InputSwitchTypeConst.READONLY) || !canDoOperation(ObjectGroupConst.EDIT)" 
            v-model="vmodel"
            @change="processEventComponent(CatalogEventConst.CHANGE)">
        </InputSwitch>
        <CustomValidate v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE)" v-model:submitted="submitted"
            v-model:vObject="v$" />
    </div>
    <span :key="componentKey" v-else-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && getPropertyBooleanValue(InputSwitchTypeConst.READONLY)"><i 
        
        :id="getPropertyValue(InputSwitchTypeConst.ID)" v-tooltip="getPropertyValue(InputSwitchTypeConst.TOOLTIP)"
            :style="getPropertyValue(InputSwitchTypeConst.STYLE)"
            :class="{ [getPropertyValue(InputSwitchTypeConst.CLASS) ?? '']: true, 'p-invalid': v$.vmodel.$error && submitted,'pi-times-circle':vmodel?.toString()?.toLowerCase() !== 'true', 'text-red-500': vmodel?.toString()?.toLowerCase() !== 'true', 'pi-check-circle': vmodel?.toString()?.toLowerCase() == 'true' ,'text-green-500': vmodel?.toString()?.toLowerCase() == 'true' }"
            :name="getPropertyValue(InputSwitchTypeConst.NAME)"

            class="pi  "></i></span>
</template>
<script lang="ts">


import { defineComponent, onMounted, onBeforeUnmount, onUnmounted } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import InputSwitchTypeConst from '../../../../domain/Constants/InputSwitchTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import CustomValidate from '../../shared/CustomValidate.vue';
import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_input_switch',
    components: {
        CustomValidate,
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        slotProps: {
            type: Object,
            default: () => ({})
        },      
    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded,  resolveExpressions,
            baseOnUnmount, baseOnMounted, baseOnBeforeUnmount, v$, submitted, canEdit, isValidData
            , fieldKey, fieldKeyComponentDataSourceId, processEventComponent ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);


        onMounted(() => {

            baseOnMounted();

        })

        onBeforeUnmount(() => {

            baseOnBeforeUnmount();
        })


        onUnmounted(() => {

            baseOnUnmount();

        })


        return {

            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            InputSwitchTypeConst,
            loaded,
            BaseControlTypeConst,
            v$,
            submitted,
            canEdit,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            CatalogEventConst,
            processEventComponent,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
        };
    },
});
</script>
<style scoped></style>
