import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IComponentEventRepository } from '../domain/IComponentEventRepository';



import { IServiceComponentEvent } from './IServiceComponentEvent';
import { IapComponentEvent } from '../domain/iapComponentEvent';



@injectable()
export class ComponentEventService implements IServiceComponentEvent {
  private readonly repo: IComponentEventRepository

  public constructor (@inject(TYPES.COMPONENT_EVENT_REPOSITORY) _repo: IComponentEventRepository) {
    this.repo = _repo
  }

  async delete(id: string) :Promise<boolean> {
    return await this.repo.delete(id)
  }

  async update (data: IapComponentEvent): (Promise<IapComponentEvent>) {
    return await this.repo.update(data);
  }

  async add (data: IapComponentEvent): (Promise<IapComponentEvent>) {
    return await this.repo.add(data);
  }

  async getAllByComponentId(componentId: number) :Promise<IapComponentEvent[]> {
    return await this.repo.getAllByComponentId(componentId)
  }

  async getById(id: number) :Promise<IapComponentEvent> {
    return await this.repo.getById(id)
  }
}
