import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: ModeloComponentDataSourceMigrationInputType!){
    componentDataSourceMigrationMutation{
        updateComponentDataSourceMigration(data:$data)
    }
}
`);

export const ADD = gql(`
mutation($data: ModeloComponentDataSourceMigrationInputType!){
    componentDataSourceMigrationMutation{
        addComponentDataSourceMigration(data:$data)
    }
}
`);



