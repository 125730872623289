<template>
          <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"

            />
   <a  v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
            :key="componentKey"
            :id="getPropertyValue(AnchorTypeConst.ID)" v-tooltip="getPropertyValue(AnchorTypeConst.TOOLTIP)"
            style="cursor:pointer;"
            :style="getPropertyValue(AnchorTypeConst.STYLE)"
            :class="{ [getPropertyValue(AnchorTypeConst.CLASS) ?? '']: true, 'customrequired': getPropertyBooleanValue(AnchorTypeConst.REQUIRED),'disabled-link':getPropertyBooleanValue(AnchorTypeConst.DISABLED) }"
            :name="getPropertyValue(AnchorTypeConst.NAME)" 
            :visible="getPropertyBooleanValue(AnchorTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
            :placeholder="getPropertyValue(AnchorTypeConst.PLACEHOLDER)" 
            :href="getPropertyValue(AnchorTypeConst.HREF)"
            :target="getCatalogValue(AnchorTypeConst.TARGET)"
            @click="processEventComponent(CatalogEventConst.CLICK)"
            >
            {{ getPropertyValue(AnchorTypeConst.TEXT) }}        
    </a>
    
    
</template>
<script lang="ts">


import { defineComponent, onMounted, ref, onBeforeUnmount, onUnmounted } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import AnchorTypeConst from '../../../../domain/Constants/AnchorTypeConst';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_html_a',
    
    components: {
            SummaryDebug
        },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },      
        slotProps: {
            type: Object,
            default: () => ({})
        },     
      
    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded, 
             resolveExpressions, baseOnUnmount,fieldKey,fieldKeyComponentDataSourceId,getCatalogValue,
              processEventComponent ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);


        onMounted(() => {
           
        })

        onUnmounted(() => {
            baseOnUnmount();
        })


        return {
            
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            AnchorTypeConst,
            loaded,
            BaseControlTypeConst,
            fieldKey,
            fieldKeyComponentDataSourceId,
            getCatalogValue,
            ObjectGroupConst
            ,canDoOperation
            ,processEventComponent
            ,CatalogEventConst
            ,componentKey
        };
    },
});
</script>
<style scoped>
.disabled-link {
  pointer-events: none;
  cursor: default;
  opacity: 0.7;
}
</style>
