import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Mic = _resolveComponent("Mic")!
  const _component_CustomValidate = _resolveComponent("CustomValidate")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_openBlock(), _createElementBlock("div", {
      class: _normalizeClass({ 'p-inputgroup flex-1': _ctx.hasGroupControls }),
      key: _ctx.componentKey
    }, [
      (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
        ? _withDirectives((_openBlock(), _createBlock(_component_InputText, {
            key: 0,
            id: _ctx.getPropertyValue(_ctx.InputTextTypeConst.ID),
            style: _normalizeStyle(_ctx.getPropertyValue(_ctx.InputTextTypeConst.STYLE)),
            class: _normalizeClass({ [_ctx.getPropertyValue(_ctx.InputTextTypeConst.CLASS) ?? '']: true, 'p-invalid': _ctx.v$.vmodel.$error && _ctx.submitted,'p-readonly': _ctx.getPropertyBooleanValue(_ctx.InputTextTypeConst.READONLY)}),
            name: _ctx.getPropertyValue(_ctx.InputTextTypeConst.NAME),
            disabled: _ctx.getPropertyBooleanValue(_ctx.InputTextTypeConst.DISABLED) || (!_ctx.canEdit),
            visible: _ctx.getPropertyBooleanValue(_ctx.InputTextTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW),
            placeholder: _ctx.getPropertyValue(_ctx.InputTextTypeConst.PLACEHOLDER),
            readonly: _ctx.getPropertyBooleanValue(_ctx.InputTextTypeConst.READONLY) || !_ctx.canDoOperation(_ctx.ObjectGroupConst.EDIT),
            type: _ctx.getPropertyValue(_ctx.InputTextTypeConst.TYPE),
            modelValue: _ctx.vmodel,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vmodel) = $event))
          }, null, 8 /* PROPS */, ["id", "style", "class", "name", "disabled", "visible", "placeholder", "readonly", "type", "modelValue"])), [
            [_directive_tooltip, _ctx.getPropertyValue(_ctx.InputTextTypeConst.TOOLTIP)]
          ])
        : _createCommentVNode("v-if", true),
      (_ctx.hasGroupControls)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass({ 'p-inputgroup-addon': _ctx.hasGroupControls })
          }, [
            (_ctx.getPropertyBooleanValue(_ctx.InputTextTypeConst.MIC))
              ? (_openBlock(), _createBlock(_component_Mic, {
                  key: 0,
                  modelValue: _ctx.vmodel,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.vmodel) = $event))
                }, null, 8 /* PROPS */, ["modelValue"]))
              : _createCommentVNode("v-if", true),
            (_ctx.getPropertyBooleanValue(_ctx.InputTextTypeConst.COPYTOCLIPBOARDB))
              ? _withDirectives((_openBlock(), _createElementBlock("i", {
                  key: 1,
                  class: "ml-1 pi pi-copy",
                  style: {"color":"rgb(0, 128, 128)"},
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.HelperCommon.copyToClipBoard(_ctx.vmodel)))
                }, null, 512 /* NEED_PATCH */)), [
                  [_directive_tooltip, 'Copiar al portapapeles']
                ])
              : _createCommentVNode("v-if", true),
            (_ctx.getPropertyBooleanValue(_ctx.InputTextTypeConst.CHAT) && _ctx.hasUbmChat)
              ? _withDirectives((_openBlock(), _createElementBlock("i", {
                  key: 2,
                  class: "ml-1 pi pi-whatsapp",
                  style: {"color":"rgb(37,211,102)"},
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.sendUbmChat()))
                }, null, 512 /* NEED_PATCH */)), [
                  [_directive_tooltip, 'Chat']
                ])
              : _createCommentVNode("v-if", true),
            (_ctx.getPropertyBooleanValue(_ctx.InputTextTypeConst.EMAIL))
              ? _withDirectives((_openBlock(), _createElementBlock("i", {
                  key: 3,
                  class: "ml-1 pi pi-envelope",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.sendMail()))
                }, null, 512 /* NEED_PATCH */)), [
                  [_directive_tooltip, 'Enviar correo']
                ])
              : _createCommentVNode("v-if", true)
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */)),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? (_openBlock(), _createBlock(_component_CustomValidate, {
          key: 1,
          submitted: _ctx.submitted,
          "onUpdate:submitted": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.submitted) = $event)),
          vObject: _ctx.v$,
          "onUpdate:vObject": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.v$) = $event))
        }, null, 8 /* PROPS */, ["submitted", "vObject"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}