import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import { IComponentDataSourceServiceConfigurationRepository } from '../domain/IComponentDataSourceServiceConfigurationRepository';

import { UPDATE, ADD } from './mutation/componentDataSourceServiceConfigurationMutation';
import { IapComponentDataSourceServiceConfiguration } from '../domain/iapComponentDataSourceServiceConfiguration';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class ComponentDataSourceServiceConfigurationApolloClientRepository implements IComponentDataSourceServiceConfigurationRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }
  
  

  async update (dataInput: IapComponentDataSourceServiceConfiguration): (Promise<IapComponentDataSourceServiceConfiguration>) {
    return new Promise<IapComponentDataSourceServiceConfiguration>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentDataSourceServiceConfigurationMutation.updateComponentDataSourceServiceConfiguration)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async add (dataInput: IapComponentDataSourceServiceConfiguration): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentDataSourceServiceConfigurationMutation)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  

}


