<template>
    <span v-if="submitted">
        <span v-for="(error, index) in vObject.vmodel.$errors" :key="error.$uid">
            <small class="p-error">{{ error.$message}}</small>
            <br v-if="(index+1)<vObject.vmodel.$errors?.length">
        </span>
    </span>    
</template>    
<script lang="ts">


import { defineComponent } from 'vue';

export default defineComponent({
    name: 'custom_validate',
    props:
    {

        submitted: {
            type: Boolean
        },
        vObject: {
            type: Object as any,
            default:({})
            
        },
        

    },
    setup() {
        

        return {
          

        };
    },
});
</script>
<style scoped></style>
