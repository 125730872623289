import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: WorkFlowInputType!){
    workFlowMutation{
        updateWorkFlow(data:$data){
            id
            applicationId
            applicationVersion
            active
            description
            fcr
            fum
            idObjeto
            name
            objetoId
            objetoIdRoot
            idObjetoRoot            
            ucr
            uum
            description
        }
    }
}
`);

export const ADD = gql(`
mutation($data: WorkFlowInputType!){
    workFlowMutation{
        addWorkFlow(data:$data){
            id
            applicationId
            applicationVersion
            active
            description
            fcr
            fum
            idObjeto
            name
            objetoId
            objetoIdRoot
            idObjetoRoot            
            group
            ucr
            uum
            description
            iapWorkFlowActivities{
                id
            workFlowId
            typeWorkFlowId
            name
            label
            color
            left
            top
            isInvalid
            canDelete
            group
            fcr
            ucr
            uum
            fum
                iapWorkFlowActivityVariables{
                    id
                    workFlowActivityId
                    name
                    isInput
                    defaultValue
                    fcr
                    ucr
                    uum
                    fum
                    group
                    iapWorkFlowActivityConnectionWorkFlowActivityVariableIdInputNavigations{
                        fcr
                        fum
                        id
                        ucr
                        uum
                        workFlowActivityVariableIdInput
                        workFlowActivityVariableIdOutput
                        externalId
                    }
                    iapWorkFlowActivityConnectionWorkFlowActivityVariableIdOutputNavigations{
                        fcr
                        fum
                        id
                        ucr
                        uum
                        workFlowActivityVariableIdInput
                        workFlowActivityVariableIdOutput
                        externalId
                    }
                } 
                iapWorkFlowActivityControls{
                        id
                        workFlowActivityId
                        name
                        value
                        localizable
                        group
                    }                    

            }
        }
    }
}
`);

export const DELETE = gql(`
mutation($id: Int!){
    workFlowMutation{
        deleteWorkFlow(id:$id)
    }
}
`);

export const DELETE_BY_OBJECTID = gql(`
mutation($applicationId: Int!,$applicationVersion: Int!,$idObject:String!,$objectId:String!){
    workFlowMutation{
        deleteWorkFlowByObjectId(applicationId:$applicationId,applicationVersion:$applicationVersion,idObject:$idObject,objectId:$objectId,)
    }
}
`);


