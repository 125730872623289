import gql from "graphql-tag";

export const UPDATE = gql(`
mutation($applicationId: Int!
        $applicationVersion: Int!
        $data: [DataUpdateOperationInputType]!
        $transactionOperation:Boolean
        $lookUp: DataSourceLookUpInputType){
    dataMutation{
        updateData(applicationId:$applicationId,applicationVersion:$applicationVersion,data:$data,transactionOperation:$transactionOperation,lookUp:$lookUp){
            id
            result
            error
            resultData
        }
    }
}
`);
