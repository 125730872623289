<template>
    <Button id="exportar" v-tooltip.top="'Exportar'" icon="fa-solid fa-download" @click="toggle" aria-haspopup="true"
        aria-controls="overlay_menu" class="p-button-outlined" style="margin-left:1px" />
    <Menu id="overlay_menu" ref="menu" :model="getItems" :popup="true" />
    <div ref="simpleTable" v-show="false">
        <HtmlTable v-if="showSimpleTable" :header="getTableData()?.header" :rows="getTableData()?.rows"
            @init="toPrinter()"></HtmlTable>
    </div>
    <Documento ref="documento" />
</template>
<script lang="ts">

import { defineComponent, ref, computed } from 'vue';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { columnProp, resolveFieldData } from '../../../../funciones/HelperPrimeUtils';
import MessageService, { MessageType } from '../../../../servicios/MessageService';
import HtmlTable from './HtmlTable.vue';
import { useStore } from 'vuex';
import HelperLoading from '../../../../funciones/HelperLoading';
import Documento from '../visor/Documento.vue';

export default defineComponent({
    name: 'tableexport',
    emits: ['selectAll'],
    props: {
        rfpId: {
            type: String,
            default: '',
        },
        table: {
            type: Object,
            default: () => ({}),
        },
        filename: {
            type: String,
            default: 'document',
        },
        pdf: {
            type: Boolean,
            default: true,
        },
        csv: {
            type: Boolean,
            default: true,
        },
        excel: {
            type: Boolean,
            default: true,
        },
        copyClipboard: {
            type: Boolean,
            default: true,
        },
        print: {
            type: Boolean,
            default: true,
        },
        lazy: {
            type: Boolean,
            default: false
        },
        moneyColumns: {
            type: Object as () => string[],
            default: () => ([])
        }


    },
    components: {
        HtmlTable,
        Documento
    },
    setup(props,{emit}) {
        const store = useStore();
        const showSimpleTable = ref(false);
        const simpleTable = ref<HTMLElement | null>(null);
        const menu = ref();
        const documento = ref();

        const getItems = computed(() => {
            const items = [
                {
                    label: 'WORD',
                    icon: 'fa fa-file-word',
                    visible: props.rfpId,
                    command: () => {
                        tableToWord();
                    }
                },
                {
                    label: 'PDF',
                    icon: 'fa fa-file-pdf',
                    visible: props.pdf,
                    command: () => {
                        tableToPdf();
                    }
                },

                {
                    label: 'CSV',
                    icon: 'fa-solid fa-file-csv',
                    visible: props.csv,
                    command: () => {
                        tableToCsv();

                    }
                }
                ,

                {
                    label: 'Excel',
                    icon: 'fa-solid fa-file-excel',
                    visible: props.excel,
                    command: () => {
                        toXLS();

                    }
                },

                {
                    label: 'Copiar',
                    icon: 'fa-solid fa-clipboard',
                    visible: props.copyClipboard,
                    command: () => {
                        toClipBoard();
                    }
                },
                {
                    label: 'Imprimir',
                    icon: 'fa-solid fa-print',
                    visible: props.print,
                    command: () => {
                        showSimpleTable.value = true;
                    }
                }

            ];

            return items.filter(x => x.visible);

        })


        const toggle = (event:any) => {
            if(props.lazy){
                emit('selectAll');
            }
            menu.value.toggle(event);
        };

        const getTableData = () => {
            //headers
            let header: Array<string> = [];

            for (let i = 0; i < props.table.columns.length; i++) {
                let column = props.table.columns[i];

                if (columnProp(column, 'exportable') !== false && columnProp(column, 'field')) {

                    const columnName = (columnProp(column, 'header') || columnProp(column, 'field'));
                    header.push(columnName);
                }
            }

            let rows: Array<[]> = [];
            if (props.table.processedData) {
                props.table.processedData.forEach((record: any)  => {
                    let row: Array<string> = [];
                    for (let i = 0; i < props.table.columns.length; i++) {
                        let column = props.table.columns[i];
                        if (columnProp(column, 'exportable') !== false && columnProp(column, 'field')) {

                            let cellData = resolveFieldData(record, columnProp(column, 'field'),props.moneyColumns);

                            if (cellData != null) {
                                cellData = String(cellData).replace(/"/g, '""');
                            }
                            else
                                cellData = '';

                            row.push(cellData);
                        }
                    }

                    rows.push(row as any);
                });

                return {
                    header: header,
                    rows: rows
                }

            }
        }



        const tableToCsv = () => {
            props.table.exportCSV();
        }

        const tableToPdf = () => {

            const data = getTableData();

            if (data) {

                var doc = new jsPDF('l', 'pt');
                //doc.text('Listado', 30, 20)
/*
                autoTable(doc, {
                    head: [data.header],
                    body: data.rows
                })
*/
                autoTable(doc, {
                    head: [data.header],
                    body: data.rows,                  
                    styles: {
                        overflow: 'linebreak',
                        cellWidth: 'auto',
                        //columnWidth: 'wrap',
                        font: 'arial',
                        fontSize: 9,
                        cellPadding: 2,
                        //overflowColumns: 'linebreak'
                    }
                })


                doc.save(props.filename + '.pdf');
            }

        }

        const autofitColumns = (json: any[], worksheet: XLSX.WorkSheet) => {

            let objectMaxLength: number[] = [];

            json.map(jsonData => {
                Object.entries(jsonData)
                    .map(([, v], idx) => {
                        let columnValue = v as string
                        objectMaxLength[idx] = objectMaxLength[idx] >= columnValue.length ? objectMaxLength[idx] : columnValue.length
                    })
            })

            const wscols = objectMaxLength.map((w: number) => ({ width: w }))
            worksheet["!cols"] = wscols;

        }

        const toXLS = () => {
            const data = getTableData();
            const header = data?.header ?? [];
            const rows = data?.rows ?? [];

            if (header && rows) {

                let dataToExport: Array<string> = [];
                for (let i = 0; i < rows.length; i++) {
                    const aux = {} as any;
                    const row = rows[i];
                    for (let j = 0; j < row.length; j++) {
                        aux[header[j]] = row[j];
                    }
                    dataToExport.push(aux as any);

                }

                let sheet = XLSX.utils.json_to_sheet(dataToExport);
                const workbook = XLSX.utils.book_new();
                const filename = props.filename;
                XLSX.utils.book_append_sheet(workbook, sheet, filename);
                autofitColumns(dataToExport, sheet);
                XLSX.writeFile(workbook, props.filename + '.xlsx');
            }
            //let csv = '\ufeff';
            /*
             //headers
             let headerInitiated = false;
             for (let i = 0; i < props.table.columns.length; i++) {
                 let column = props.table.columns[i];
 
                 if (columnProp(column, 'exportable') !== false && columnProp(column, 'field')) {
                     if (headerInitiated)
                         csv += props.table.csvSeparator;
                     else
                         headerInitiated = true;
 
                     csv += '"' + (columnProp(column, 'header') || columnProp(column, 'field')) + '"';
                 }
             }
 
             const data = props.table.proce
             //body
             if (data) {
                 data.forEach(record => {
                     csv += '\n';
                     let rowInitiated = false;
                     for (let i = 0; i < this.columns.length; i++) {
                         let column = this.columns[i];
                         if (this.columnProp(column, 'exportable') !== false && this.columnProp(column, 'field')) {
                             if (rowInitiated)
                                 csv += this.csvSeparator;
                             else
                                 rowInitiated = true;
 
                             let cellData = utils.ObjectUtils.resolveFieldData(record, this.columnProp(column, 'field'));
 
                             if (cellData != null) {
                                 if (this.exportFunction) {
                                     cellData = this.exportFunction({
                                         data: cellData,
                                         field: this.columnProp(column, 'field')
                                     });
                                 }
                                 else
                                     cellData = String(cellData).replace(/"/g, '""');
                             }
                             else
                                 cellData = '';
 
                             csv += '"' + cellData + '"';
                         }
                     }
                 });
             }
 
             utils.DomHandler.exportCSV(csv, this.exportFilename);
             */
        }


        /*
 
        const tableToCSV = (table) => {
            // We'll be co-opting `slice` to create arrays
            var slice = Array.prototype.slice;
 
            return slice
                .call(table.rows)
                .map(function (row) {
                    return slice
                        .call(row.cells)
                        .map(function (cell) {
                            return '"t"'.replace("t", cell.textContent);
                        })
                        .join(",");
                })
                .join("\r\n");
        }
 
        const toXLS = (tableId) => {
 
            if ((getMsieVersion() || isFirefox())) {
                return toCSV(tableId);
            } else if (getMsieVersion() || isFirefox()) {
                MessageService.showToast(MessageType.Error, '', 'Navegador no soportado');
            }
 
            //Other Browser can download xls
            var htmltable = document.getElementById(tableId);
            var html = htmltable?.outerHTML;
 
            downloadAnchor(
                "data:application/vnd.ms-excel" + encodeURIComponent(html ?? ''),
                "xls"
            );
        }
 
        const toCSV = (tableId, filename) => {
            // Generate our CSV string from out HTML Table
            var csv = tableToCSV(document.getElementById(tableId));
            // Create a CSV Blob
            var blob = new Blob([csv], { type: "text/csv" });
 
            // Determine which approach to take for the download
            if (navigator.msSaveOrOpenBlob) {
                // Works for Internet Explorer and Microsoft Edge
                navigator.msSaveOrOpenBlob(blob, props.filename + ".csv");
            } else {
                downloadAnchor(URL.createObjectURL(blob), "csv");
            }
        }
 
        const getMsieVersion = () => {
            var ua = window.navigator.userAgent;
 
            var msie = ua.indexOf("MSIE ");
            if (msie > 0) {
                // IE 10 or older => return version number
                return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
            }
 
            var trident = ua.indexOf("Trident/");
            if (trident > 0) {
                // IE 11 => return version number
                var rv = ua.indexOf("rv:");
                return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
            }
 
            var edge = ua.indexOf("Edge/");
            if (edge > 0) {
                // Edge (IE 12+) => return version number
                return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
            }
 
            // other browser
            return false;
        }
 
        const downloadAnchor = (content, ext) => {
            var anchor = document.createElement("a");
            //anchor.style = "display:none !important";
            anchor.id = "downloadanchor";
            document.body.appendChild(anchor);
 
            // If the [download] attribute is supported, try to use it
 
            if ("download" in anchor) {
                anchor.download = props.filename + "." + ext;
            }
            anchor.href = content;
            anchor.click();
            anchor.remove();
        }
 
        const isFirefox = () => {
            if (navigator.userAgent.indexOf("Firefox") > 0) {
                return 1;
            }
 
            return 0;
        }
       */

        const downloadAnchor = (content:any, ext:any) => {
            var anchor = document.createElement("a");
            //anchor.style = "display:none !important";
            anchor.id = "downloadanchor";
            document.body.appendChild(anchor);

            // If the [download] attribute is supported, try to use it

            if ("download" in anchor) {
                anchor.download = props.filename + "." + ext;
            }
            anchor.href = content;
            anchor.click();
            anchor.remove();
        }


        const toClipBoard = () => {
            const data = getTableData();
            const header = data?.header ?? [];
            const rows = data?.rows ?? [];
            let aux = '';
            if (header && rows) {

                aux += header.join('\t') + '\n';

                for (let i = 0; i < rows.length; i++) {
                    const row = rows[i];
                    aux += '\t\t' + row.join('\t') + '\n';
                }
                navigator.clipboard.writeText(aux);
                MessageService.showToast(MessageType.Correcto, '', 'Se han copiado los registros al portapapeles.');
            }
        }

        const toPrinter = () => {

            const data = getTableData();
            const header = data?.header ?? [];
            const rows = data?.rows ?? [];
            if (header && rows && simpleTable.value) {


                const mywindow = window.open('', '_blank', 'PRINT');
                if (mywindow) {


                    mywindow.document.write('<html><head><title>' + props.filename + '</title>');
                    mywindow.document.write('</head><body >');
                    mywindow.document.write(simpleTable.value.innerHTML);
                    mywindow.document.write('</body></html>');

                    mywindow.document.close(); // necessary for IE >= 10
                    mywindow.focus(); // necessary for IE >= 10*/

                    mywindow.print();
                }

            }

            showSimpleTable.value = false;

        }

        const tableToWord = () => {
/*
            if (props.rfpId) {
                HelperLoading.showLoading();
                HelperWord.rfpToWord(store.getters.sessionID, props.rfpId).then((response) => {
                    if (!response.error) {


                        const document = {
                            content: response.base64File,
                            contentType: 'application/msword',
                            fileName: response.filename + '.doc'

                        };

                        documento.value.descargaFichero(document);

                    }

                }).finally(() => {
                    HelperLoading.hideLoading();
                })

            }
            */
        }

        


        return {
            //doc,
            toggle,
            getItems,
            menu,
            tableToPdf,
            tableToCsv,
            tableToWord,
            toXLS,
            downloadAnchor,
            toClipBoard,
            toPrinter,
            getTableData,
            simpleTable,
            showSimpleTable,
            documento


        };
    },
});
</script>
