import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: DataSourceLookUpInputType!,$objDsfConfigData:[ObjectDataSourceFieldConfigurationInputType]!){
    dataSourceLookUpMutation{
        updateDataSourceLookUp(data:$data,objDsfConfigData:$objDsfConfigData){
            id,
            dataSourceId,
            idFieldDescription,
            tableAlias,
            maxRowsReturned
            queryDisplay,
            queryWhere,
            queryOrder,        
            addNewvaluesOnTheFly,
            filterDistinct,
            additionaFieldsOnSearch
            fieldsOnReturn
            idControlType,
            componentId,
            parameters
        }
    }
}
`);



export const ADD = gql(`
mutation($data: DataSourceLookUpInputType!,$objDsfConfigData:[ObjectDataSourceFieldConfigurationInputType]!){
    dataSourceLookUpMutation{
        addDataSourceLookUp(data:$data,objDsfConfigData:$objDsfConfigData){
            id,
            dataSourceId,
            idFieldDescription,
            tableAlias,
            maxRowsReturned
            queryDisplay,
            queryWhere,
            queryOrder,        
            addNewvaluesOnTheFly,
            filterDistinct,
            additionaFieldsOnSearch
            fieldsOnReturn
            idControlType,
            componentId,
            parameters
        }
    }
}
`);

export const DELETE = gql(`
mutation($id: Int!){
    dataSourceLookUpMutation{
        deleteDataSourceLookUp(id:$id)
    }
}
`);

