import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border-round border-1 surface-border p-4 surface-card" }
const _hoisted_2 = { class: "flex mb-3" }
const _hoisted_3 = { class: "flex justify-content-between mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeleton = _resolveComponent("Skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Skeleton, {
        shape: "circle",
        size: "4rem",
        class: "mr-2"
      }),
      _createElementVNode("div", null, [
        _createVNode(_component_Skeleton, {
          width: "10rem",
          class: "mb-2"
        }),
        _createVNode(_component_Skeleton, {
          width: "5rem",
          class: "mb-2"
        }),
        _createVNode(_component_Skeleton, { height: ".5rem" })
      ])
    ]),
    _createVNode(_component_Skeleton, {
      width: "100%",
      height: "150px"
    }),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Skeleton, {
        width: "4rem",
        height: "2rem"
      }),
      _createVNode(_component_Skeleton, {
        width: "4rem",
        height: "2rem"
      })
    ])
  ]))
}