import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: WorkFlowActivityControlInputType!){
    workFlowActivityControlMutation{
        updateWorkFlowActivityControl(data:$data){
            id
            workFlowActivityId
            name
            value
            localizable
        }
    }
}
`);

export const ADD = gql(`
mutation($data: WorkFlowActivityControlInputType!){
    workFlowActivityControlMutation{
        addWorkFlowControl(data:$data){
            id
            workFlowActivityId
            name
            value
            localizable
        }
    }
}
`);


export const DELETE = gql(`
mutation($data: WorkFlowActivityControlInputType!){
    workFlowActivityControlMutation{
        deleteWorkFlowControl(data:$data)
    }
}
`);

