/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { computed } from 'vue'
import Environment from '../funciones/environment'

export default function logoComp () {
  const getLogoPanelUrl = computed(() => {
    return  Environment.URL_LOGO_PNG
  })

  const getLogoUrl = computed(() => {
    return  Environment.URL_LOGO_PNG
  })

  const getFaviconUrl = computed(() => {
    return  Environment.URL_FAVICON
  })

  return {
    
    getLogoPanelUrl,
    getLogoUrl,
    getFaviconUrl
  }
}
