
import gql from 'graphql-tag';

export const ADD = gql(`
mutation(
    $data: DataSourceInputType!
    $configurations: [DataSourceServiceConfigurationInputType]!
    $tables: [DataSourceTableAliasInputType]!
    $db: DataSourceDataBaseInputType
    $srv: DataSourceServiceInputType
    $fields: [DataSourceFieldInputType]!
 ){
    dataSourceMutation{
        add(data:$data, configurations:$configurations, tables:$tables,db:$db,srv:$srv,fields:$fields){
            applicationId,
            applicationVersion,
            name,
            description,
            idDataSourceType,
            fcr,
            ucr
        }
    }
}
`);

export const MODIFY = gql(`
mutation(
    $data: DataSourceInputType!
    $tables: [DataSourceTableAliasInputType]!
    $db: DataSourceDataBaseInputType
    $srv: DataSourceServiceInputType
    $fields: [DataSourceFieldInputType]!
 ){
    dataSourceMutation{
        modify(data:$data, tables:$tables,db:$db,srv:$srv,fields:$fields){
            applicationId,
            applicationVersion,
            name,
            description,            
            idDataSourceType,
            fcr,
            ucr
        }
    }
}
`);


export const UPDATE = gql(`
mutation($data: DataSourceInputType!){
    dataSourceMutation{
        update(data:$data){
            id,
            applicationId,
            applicationVersion,            
            name,
            description,            
            idDataSourceType,
            fcr,
            ucr
        }
    }
}
`);

export const UPDATE_ENTIRE = gql(`
mutation($data: DataSourceInputType!,
    $tables: [DataSourceTableAliasInputType]!,
    $db: DataSourceDataBaseInputType,
    $srv: DataSourceServiceInputType,
    $fields: [DataSourceFieldInputType]!
){
    dataSourceMutation{
        update(data:$data, tables:$tables,db:$db,src:$src,fields:$fields){
            id,
            applicationId,
            applicationVersion,            
            name,
            description,            
            idDataSourceType,
            fcr,
            ucr
        }
    }
}
`);




export const REFRESH_FIELDS = gql(`
mutation($data: DataSourceInputType!){
    dataSourceMutation{
        refreshFields(data:$data){
            id,
            applicationId,
            applicationVersion,
            name,
            description,
            idDataSourceType,
            fcr,
            ucr
        }
    }
}
`);

export const DELETE = gql(`
mutation($dataSourceId: Int!){
    dataSourceMutation{
        deleteDataSource(dataSourceId:$dataSourceId)
    }
}
`);






