import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IObjectDataSourceFieldRelationRepository } from '../domain/IObjectDataSourceFieldRelationRepository';

import { IServiceObjectDataSourceFieldRelation } from './IServiceObjectDataSourceFieldRelation';
import { IapObjectDataSourceFieldRelation } from '../domain/iapObjectDataSourceFieldRelation';


@injectable()
export class ObjectDataSourceFieldRelationService implements IServiceObjectDataSourceFieldRelation {
    private readonly repo: IObjectDataSourceFieldRelationRepository

    public constructor(@inject(TYPES.OBJECTDATASOURCEFIELDRELATION_REPOSITORY) _repo: IObjectDataSourceFieldRelationRepository) {
        this.repo = _repo
    }

    async update(oldData: IapObjectDataSourceFieldRelation,newData:IapObjectDataSourceFieldRelation): (Promise<IapObjectDataSourceFieldRelation>) {
        return await this.repo.update(oldData,newData);
    }
    async add(data: IapObjectDataSourceFieldRelation): (Promise<IapObjectDataSourceFieldRelation>) {
        return await this.repo.add(data);
    }

    async delete(data: IapObjectDataSourceFieldRelation): (Promise<IapObjectDataSourceFieldRelation>) {
        return await this.repo.delete(data);
    }

   

}
