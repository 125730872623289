<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
            
    <Badge v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" :key="componentKey"
        :id="getPropertyValue(BadgeTypeConst.ID)" v-tooltip="getPropertyValue(BadgeTypeConst.TOOLTIP)"
        :style="getPropertyValue(BadgeTypeConst.STYLE)"
        :class="{ [getPropertyValue(BadgeTypeConst.CLASS) ?? '']: true, 'customrequired': getPropertyBooleanValue(BadgeTypeConst.REQUIRED) }"
        :name="getPropertyValue(BadgeTypeConst.NAME)" :disabled="getPropertyBooleanValue(BadgeTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(BadgeTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" :placeholder="getPropertyValue(BadgeTypeConst.PLACEHOLDER)"
        :severity="getCatalogValue(BadgeTypeConst.SEVERITY)"
        :size="getCatalogValue(BadgeTypeConst.SIZE)"
        :value="vmodel">
    </Badge>
</template>
<script lang="ts">


import { defineComponent, onMounted, ref, onBeforeUnmount, onUnmounted } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import BadgeTypeConst from '../../../../domain/Constants/BadgeTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_chip',
    components: {
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
              slotProps: {
            type: Object,
            default: () => ({})
        },

    },
    setup(props, context) {
        //
        const store = useStore();
        const { vmodel,canDoOperation, loaded, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue, 
            getCatalogValue, resolveExpressions, baseOnUnmount, fieldKey, fieldKeyComponentDataSourceId 
            ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);


       

        onMounted(() => {
       

        })

        onUnmounted(() => {
            baseOnUnmount();
        })

        return {           
            getPropertyValue,
            getPropertyBooleanValue,
            getCatalogValue,
            vmodel,
            BadgeTypeConst,
            getPropertyNumberValue,
            loaded,
            BaseControlTypeConst,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey

        };
    },
});
</script>
<style scoped></style>
