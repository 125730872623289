import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_Button = _resolveComponent("Button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
          key: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.ButtonTypeConst.ID),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.ButtonTypeConst.STYLE)),
          class: _normalizeClass({ [_ctx.getPropertyValue(_ctx.ButtonTypeConst.CLASS) ?? '']: true, 'customrequired': _ctx.getPropertyBooleanValue(_ctx.ButtonTypeConst.REQUIRED) }),
          name: _ctx.getPropertyValue(_ctx.ButtonTypeConst.NAME),
          disabled: _ctx.getPropertyBooleanValue(_ctx.ButtonTypeConst.DISABLED),
          visible: _ctx.getPropertyBooleanValue(_ctx.ButtonTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW),
          placeholder: _ctx.getPropertyValue(_ctx.ButtonTypeConst.PLACEHOLDER),
          label: _ctx.getPropertyValue(_ctx.ButtonTypeConst.LABEL),
          icon: _ctx.getPropertyValue(_ctx.ButtonTypeConst.ICON),
          iconPos: _ctx.getCatalogValue(_ctx.ButtonTypeConst.ICONPOS),
          iconClass: _ctx.getPropertyValue(_ctx.ButtonTypeConst.ICONCLASS),
          badge: _ctx.getPropertyValue(_ctx.ButtonTypeConst.BADGE),
          link: _ctx.getPropertyBooleanValue(_ctx.ButtonTypeConst.LINK),
          severity: _ctx.getCatalogValue(_ctx.ButtonTypeConst.SEVERITY),
          raised: _ctx.getPropertyBooleanValue(_ctx.ButtonTypeConst.RAISED),
          rounded: _ctx.getPropertyBooleanValue(_ctx.ButtonTypeConst.ROUNDED),
          outlined: _ctx.getPropertyBooleanValue(_ctx.ButtonTypeConst.OUTLINED),
          plain: _ctx.getPropertyBooleanValue(_ctx.ButtonTypeConst.PLAIN),
          size: _ctx.getCatalogValue(_ctx.ButtonTypeConst.SIZE),
          modelValue: _ctx.vmodel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vmodel) = $event)),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.processEventComponent(_ctx.CatalogEventConst.CLICK)))
        }, null, 8 /* PROPS */, ["id", "style", "class", "name", "disabled", "visible", "placeholder", "label", "icon", "iconPos", "iconClass", "badge", "link", "severity", "raised", "rounded", "outlined", "plain", "size", "modelValue"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.ButtonTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}