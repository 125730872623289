import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IComponentDataSourceServiceConfigurationRepository } from '../domain/IComponentDataSourceServiceConfigurationRepository';
import { IServiceComponentDataSourceServiceConfiguration } from './IServiceComponentDataSourceServiceConfiguration';
import { IapComponentDataSourceServiceConfiguration } from '../domain/iapComponentDataSourceServiceConfiguration';



@injectable()
export class ComponentDataSourceServiceConfigurationService implements IServiceComponentDataSourceServiceConfiguration {
  private readonly repo: IComponentDataSourceServiceConfigurationRepository

  public constructor (@inject(TYPES.COMPONENT_DATASOURCE_SERVICE_CONF_REPOSITORY) _repo: IComponentDataSourceServiceConfigurationRepository) {
    this.repo = _repo
  }

  async update (data: IapComponentDataSourceServiceConfiguration): (Promise<IapComponentDataSourceServiceConfiguration>) {
    return await this.repo.update(data);
  }

  async add (data: IapComponentDataSourceServiceConfiguration): (Promise<boolean>) {
    return await this.repo.add(data);
  }
}
