<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
            
    <div v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)">
        <div v-if="loaded && viewDetail && getPropertyValue(CrudTableTypeConst.SELECTEDROW)">
            <DetailCrud :container="container" :componentId="componentInputId" :applicationId="Component.applicationId"
                :applicationVersion="Component.applicationVersion" :attrsInput="componentAttributesList"
                :tabMode="getPropertyBooleanValue(CrudTableTypeConst.SHOWTABMODE)" @backToList="backToList()">
                <template #header>
                    <slot name="header"></slot>
                </template>
                <template #buttons>
                    <slot name="buttons"></slot>
                </template>
                <template #detail>
                    <slot name="detail"></slot>
                </template>
            </DetailCrud>
        </div>
        <CrudTable ref="crudTableControl"
            v-show="loaded && !viewDetail && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE)"
            :key="componentKey"
            :container="container" :Component="Component"
            :id="getPropertyValue(CrudTableTypeConst.ID)" v-tooltip="getPropertyValue(CrudTableTypeConst.TOOLTIP)"
            :style="getPropertyValue(CrudTableTypeConst.STYLE)" :class="getPropertyValue(CrudTableTypeConst.CLASS)"
            :name="getPropertyValue(CrudTableTypeConst.NAME)"
            :disabled="getPropertyBooleanValue(CrudTableTypeConst.DISABLED)"
            :visible="getPropertyBooleanValue(CrudTableTypeConst.VISIBLE)"
            :placeholder="getPropertyValue(CrudTableTypeConst.PLACEHOLDER)"
            :showBtnSearch="getPropertyBooleanValue(CrudTableTypeConst.SHOWBTNSEARCH)"
            :showBtnClear="getPropertyBooleanValue(CrudTableTypeConst.SHOWBTNCLEAR)"
            :showBtnCloseTabs="getPropertyBooleanValue(CrudTableTypeConst.SHOWBTNCLOSETABS)"
            :showPaginator="getPropertyBooleanValue(CrudTableTypeConst.SHOWPAGINATE)"
            :showSaveSearch="getPropertyBooleanValue(CrudTableTypeConst.SHOWSAVESEARCH)"
            :advancedSearch="getPropertyBooleanValue(CrudTableTypeConst.ADVANCEDSEARCH)"
            :lazy="getPropertyBooleanValue(CrudTableTypeConst.LAZY)"
            :lazyScroll="getPropertyBooleanValue(CrudTableTypeConst.LAZYSCROLL)"
            :editAllExceptKeys="getPropertyBooleanValue(CrudTableTypeConst.EDITALLEXCEPTKEYS)"
            :maxRegs="getPropertyNumberValue(CrudTableTypeConst.MAXREGS)"
            :canAddNewItem="getPropertyBooleanValue(CrudTableTypeConst.CANADDNEWITEM) && canDoOperation(ObjectGroupConst.ADD)"
            :canImport="getPropertyBooleanValue(CrudTableTypeConst.CANIMPORT) && canDoOperation(ObjectGroupConst.ADD)"
            :canExport="getPropertyBooleanValue(CrudTableTypeConst.CANEXPORT) && canDoOperation(ObjectGroupConst.EXPORT)"
            :canUpdateMassive="getPropertyBooleanValue(CrudTableTypeConst.CANUPDATEMASSIVE)  && canDoOperation(ObjectGroupConst.EDIT)"
            :canSelectMultiple="getPropertyBooleanValue(CrudTableTypeConst.CANSELECTMULTIPLE)"
            :showActionMenu="getPropertyBooleanValue(CrudTableTypeConst.SHOWACTIONMENU) && canDoOperation(ObjectGroupConst.DETAIL)"
            :showSearch="getPropertyBooleanValue(CrudTableTypeConst.SHOWSEARCH)"
            :showList="canDoOperation(ObjectGroupConst.VIEW)"
            :showActionDetail="getPropertyBooleanValue(CrudTableTypeConst.SHOWACTIONDETAIL) && canDoOperation(ObjectGroupConst.DETAIL)"
            :showPaginate="getPropertyBooleanValue(CrudTableTypeConst.SHOWPAGINATE)"
            :showHeaderDetail="getPropertyBooleanValue(CrudTableTypeConst.SHOWHEADERDETAIL)"
            :canDeleteItem="getPropertyBooleanValue(CrudTableTypeConst.CANDELETEITEM)  && canDoOperation(ObjectGroupConst.DELETE)" :customAddNew="customAddNew"
            :canClearAllFilters="getPropertyBooleanValue(CrudTableTypeConst.CANCLEARALLFILTER)"
            :showTabMode="getPropertyBooleanValue(CrudTableTypeConst.SHOWTABMODE)"
            :showResultTabMode="getPropertyBooleanValue(CrudTableTypeConst.SHOWTABMODERESULT)"
            :tabHeader="getPropertyValue(CrudTableTypeConst.TABHEADER)"
            :layoutDetailComponentId="(getLayoutDetailComponentId ?? -1)"
            :filterDisplay="getCatalogValue(CrudTableTypeConst.FILTERDISPLAY)"
            :slotProps="slotProps"
            :setupImport="getPropertyValue(CrudTableTypeConst.SETUP_IMP)"
            :rowGroupMode="getCatalogValue(CrudTableTypeConst.ROWGROUPMODE )" 
            :groupRowsBy="getPropertyValue(CrudTableTypeConst.GROUPROWSBY)" 
            :expandableRowGroups="getPropertyBooleanValue(CrudTableTypeConst.EXPANDABLEROWGROUPS )"
            @click:viewDetail="updateSelectedRow($event, true)" @click:SelectRow="updateSelectedRow($event, false)"
            @update:SelectMultipleRow = "updateSelectMultipleRow($event, true)"
            @click:addCustom="openCustomNew" 
            @end:import="raiseEndImportCommand"
            @begin:import="raiseStartImportCommand"
            :menuActionItems="menuItems">
            <template #header>
                <slot name="header"></slot>
            </template>
            <template #buttons>
                <slot name="buttons"></slot>
            </template>
            <template #detail>
                <slot name="detail"></slot>
            </template>
            <template #buttonsresult>
                <slot name="buttonsresult"></slot>
            </template>
        </CrudTable>
    </div>

    <Dialog v-if="opNewRegister && canDoOperation(ObjectGroupConst.ADD)" ref="slotControls" v-model:visible="opNewRegister" header="Añadir registro" maximizable
        modal styleClass="mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6"
        contentStyleClass="border-round-bottom border-top-1 surface-border p-0" @hide="hideCustomNew">
        <slot v-if="opNewRegister" name="new"></slot>
        <template #footer>
            <Button id="guardar" label="Guardar" icon="pi pi-save"
                class="mr-2  p-button-rounded p-button-primary p-button-outlined" @click="saveCustomForm" />
        </template>
    </Dialog>
</template>
<script lang="ts">


import { defineComponent, onMounted, ref, onBeforeUnmount, watch, computed, onUnmounted } from 'vue';
import { MessageService } from '../../../../../../../common/infrastructure/servicios';
import { MessageType } from '../../../../../../../common/infrastructure/servicios/MessageService';
import { IapComponentDataSource } from '../../../../../component/domain/iapComponentDataSource';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import CrudTableTypeConst from '../../../../domain/Constants/CrudTableTypeConst';
import CrudTable from '../../../../../crud/infrastructure/CrudTable.vue'
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { IapComponentAttribute } from '../../../../../component/domain/iapComponentAttribute';
import { IapCatalogType } from '../../../../../catalog/domain/iapCatalogType';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';
import ExpressionNomenclatorConst from '../../../../../expression/domain/const/ExpressionNomenclatorConst';
import DataSourceComp from '../../../../../crud/infrastructure/functions/dataSourceComp';
import { clave } from '../../../../../dataupdate/domain/clave';
import DataSourceConst from '../../../../../crud/infrastructure/functions/dataSourceConst';
import DynamicButton from './DynamicButton.vue';
import EventConst from '../../../../../../../common/domain/constantes/EventConst';
import EventBusCustom from '../../../../../../../common/infrastructure/event-bus-custom';
import DataSourceUtilComp from '../../../../../crud/infrastructure/functions/dataSourceUtilComp';
import { Dictionary } from '../../../../../expression/domain/dictionary';
import { useStore } from 'vuex';
import { InteractionEvent } from '../../../../../interaction/domain/interactionEvent';
import InteractionConst from '../../../../../interaction/domain/interactionConst';
import { forEachDiagnostic } from '@codemirror/lint';
import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';
import CatalogObjectTypeConst from '../../../../../catalog/domain/const/CatalogObjectTypeConst';
import DetailCrud from '../../../../../crud/infrastructure/search/DetailCrud.vue'
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import ControlTypeConst from '../../../../domain/Constants/ControlTypeConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';
import OperationDataTypeConst from '../../../../../../../common/domain/constantes/OperationDataTypeConst';

export default defineComponent({
    name: 'dynamic_crud_tabla',
    components: {
        CrudTable,
        DetailCrud,
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
   
        slotProps: {
            type: Object,
            default: () => ({})
        },
         customAddNew: {
            type: Boolean,
            default: false
        },

    },
    setup(props, context) {
        //
        const store = useStore();
        const { catalogTypes,canDoOperation, vmodel, loaded, getPropertyValue, getPropertyId, getPropertyBooleanValue, 
            getPropertyNumberValue, setPropertyValueByName, getProperty, updateDictionary,updateDictionaryFromRow, 
            dictionaryKeys, resolveExpressions, markUnloadComponents, baseOnUnmount, buildMenu, getLayoutComponent, 
            doOperationDataMassive, fieldKey, fieldKeyComponentDataSourceId, processEventComponent, 
            processEvent,getCatalogValue ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);
        const opNewRegister = ref();
        const menuItems = ref([]);
        const layoutMenu = getLayoutComponent(props.Component, CrudTableTypeConst.LAYOUT_ACTIONS_MENU);
        if (layoutMenu && layoutMenu.get(layoutMenu.id,'children')?.length > 0) {
            menuItems.value = buildMenu(layoutMenu.get(layoutMenu.id,'allchildren') ?? []) as any;
            
        }

        const slotControls = ref();
        const crudTableControl = ref();
        const executeWfCommandDetail = false;
        const viewDetail = ref(false);
        const componentInputId = ref(-1);
        const componentAttributesList = ref<IapComponentAttribute[]>([]);




        const findSelectedRowByKeys = (data: any, keys: clave[]) => {
            const result = Array<Boolean>();
            keys.forEach(k => {
                result.push(data[k.fieldId] === k.value);
            })

            return result.filter(x => x == false) === undefined;

        }

        

        const updateSelectMultipleRow = (data: any) =>{
            setPropertyValueByName(CrudTableTypeConst.SELECTEDMULTIPLEROW, data?.items)                           
         }

        const updateSelectedRow = (data: any, _viewDetail: boolean = true) => {
            const cdsId = props.Component.iapComponentDataSources.find(x => x !== undefined)?.id ?? -1
            if (data?.keys?.length > 0) {
                setPropertyValueByName(CrudTableTypeConst.SELECTEDROW, data.keys)
                
                updateDictionaryFromRow(cdsId,data?.item)

            }
            else {
                setPropertyValueByName(CrudTableTypeConst.SELECTEDROW, null)
                updateDictionaryFromRow(cdsId,null)
            }

            //processEventComponent(CatalogEventConst.ONSELECTED)

            // si no estoy en modo tab el lo va a mostrar dentro del crud
            if (!getPropertyBooleanValue(CrudTableTypeConst.SHOWTABMODE)) {
                viewDetail.value = _viewDetail
            }

            if (_viewDetail) {
                executeLayoutDetailCommand();
            }

            if (data?.callBackOk){
                data?.callBackOk(true);
            }

        }

        const backToList = () => {
            // se limpian los valores de lo que se ha llamado
            componentInputId.value = -1;
            componentAttributesList.value = [];

            updateSelectedRow(null, false);
            markUnloadComponents(props.Component.get(props.Component.id,'allchildren') as ComponentDataForm[]);            
      

            // se recargan los datos al volver de ver el detalle del registro seleccionado
            if (crudTableControl.value) {
                crudTableControl.value.doRefreshData();
            }
            viewDetail.value = false;



        }

        const getLayoutDetailComponentId = computed(() => {
            const layoutDetail = getLayoutComponent(props.Component, CrudTableTypeConst.LAYOUT_DETAIL);
            return layoutDetail?.id ?? null;
        })


        const openCustomNew = () => {
            const layoutNew = getLayoutComponent(props.Component, CrudTableTypeConst.LAYOUT_ADDNEW);
            if (layoutNew && layoutNew.get(layoutNew.id,'children')?.length > 0) {
                const dsToInsert = layoutNew.get(layoutNew.id,'children')[0]?.iapComponentDataSources.filter(x => x !== undefined);
                if (dsToInsert) {
                    const filteredAddInsert = dsToInsert.filter(cds =>
                            cds.iapComponentDataSourceFieldConfigurations.some(field => field.add === true)
                        );
                    if (filteredAddInsert.length == 0) {
                        MessageService.showMessage(MessageType.Error, "", "El origen de datos no tiene ninguna configuración para añadir registros");
                    }
                    else {
                        const { insertEmptyRowToDataSource } = DataSourceComp(props.container as any, props, {}, {}, dsToInsert[0], catalogTypes().flatMap(x => x.iapCatalogs), store)
                        insertEmptyRowToDataSource(layoutNew.get(layoutNew.id,'children')[0].expressions,filteredAddInsert);
                        opNewRegister.value = true
                    }
                }
            }

        }


        const createPromiseValidation = async (compId: number): Promise<InteractionEvent> => {

            return await new Promise<InteractionEvent>((resolve, reject) => {
                var data: Partial<InteractionEvent> = {
                    typeId: InteractionConst.CALL_FUNCTION,
                    objectName: 'isValidData()'

                }
                const keyComponentEventBus = props.Component.formKey + props.Component.rootParentId.toString() + compId.toString() + EventConst.INTERACTION;


                var doCallbackOk = (response: InteractionEvent) => {
                    //console.log('respuesta:' + compId.toString())
                    resolve(response);
                }

                EventBusCustom.emit(keyComponentEventBus, { data: data, callBackResponse: doCallbackOk });


            });


        };





        const saveCustomForm = async () => {

            const layoutNew = getLayoutComponent(props.Component, CrudTableTypeConst.LAYOUT_ADDNEW);
            if (layoutNew && layoutNew.get(layoutNew.id,'children')?.length > 0) {
                

                
                var promises = new Array();
                let canDo = false;
                const validableControls=layoutNew.get(layoutNew.id,'children').filter(x=>ControlTypeConst.VALIDABLE_CONTROLS.includes(x.idType));
                //let validate = Array<boolean>();
                validableControls.forEach(comp => {

                    promises.push(createPromiseValidation(comp.id));
                });


                /*
                Promise.race(promises).then((responses)=>{
                    console.log('responses');
                    console.log(responses);
                })
                */

                await Promise.all(promises).then((responses: InteractionEvent[]) => {
                    canDo = !(responses.filter(x => !x.interactionResult).length > 0)
                })



                if (canDo) {
                    const dsToInsert = layoutNew.get(layoutNew.id,'children')[0]?.iapComponentDataSources.filter(x => x !== undefined);
                    if (dsToInsert) {
                        const filteredAddInsert = dsToInsert.filter(cds =>
                            cds.iapComponentDataSourceFieldConfigurations.some(field => field.add === true)
                        );
                        if (filteredAddInsert.length == 0) {
                            MessageService.showMessage(MessageType.Error, "", "El origen de datos no tiene ninguna configuración para añadir registros");
                        }
                        else {
                            doOperationDataMassive(filteredAddInsert, layoutNew.get(layoutNew.id,'children')[0].expressions,OperationDataTypeConst.INSERT,filteredAddInsert.length >0).then(response => {
                                if (response) {
                                    MessageService.showToast(MessageType.Correcto, "", 'Registro creado correctamente.')
                                    // se recargan los datos al volver de ver el detalle del registro seleccionado
                                    if (crudTableControl.value) {
                                        crudTableControl.value.doRefreshData();
                                    }
                                    opNewRegister.value = false
                                }
                            })
                        }

                    }
                }

            }
        }

        const hideCustomNew = () => {
            const layoutNew = getLayoutComponent(props.Component, CrudTableTypeConst.LAYOUT_ADDNEW);
            if (layoutNew && layoutNew.get(layoutNew.id,'children')?.length > 0) {
                const dsToInsert = layoutNew.get(layoutNew.id,'children')[0]?.iapComponentDataSources.find(x => x !== undefined);
                if (dsToInsert) {
                    const { clearRowsToDataSource } = DataSourceComp(props.container as any, props, {}, {}, dsToInsert, catalogTypes().flatMap(x => x.iapCatalogs), store)
                    clearRowsToDataSource();
                }
            }

        }


        const executeLayoutDetailCommand = () => {
            const layoutDetail = getLayoutComponent(props.Component, CrudTableTypeConst.LAYOUT_DETAIL);

            if (layoutDetail) {
                const propsIdCmd = getPropertyId(CrudTableTypeConst.LAYOUT_DETAIL_COMMAND_DETAIL, layoutDetail) ?? '';
                const hasWf = layoutDetail.workFlows?.find(x => x.idObjeto == CatalogObjectTypeConst.ATTRCOMP && x.objetoId == propsIdCmd && x.active == true)
                if (propsIdCmd && hasWf) {

                    // parche para ocultar el detalle en el modo no tab
                    const viewInDetail = hasWf?.iapWorkFlowActivities?.flatMap(x => x.iapWorkFlowActivityControls)?.find(x => x.name == 'variableComponentCurrInput')?.value ?? 'false';
                    if (viewInDetail == 'false') {
                        viewDetail.value = false;
                    }
                    processEvent(CatalogObjectTypeConst.ATTRCOMP, propsIdCmd, layoutDetail);
                }
            }

        }



        const keyEventComponent = 'current_' + props.Component.formKey + (props.Component.rootParentId ?? -1).toString() + '_' + (getLayoutDetailComponentId.value ?? -1).toString() + '_' + EventConst.SHOWCOMPONENT;        
        const keyComponentEventInteraction ='current_' + props.Component.formKey + props.Component.rootParentId.toString() + props.Component.id.toString() + EventConst.INTERACTION;                


const doEventComponent = (evt: { componentId: number, header: string, modal: boolean, attrs: IapComponentAttribute[], callBackResponse: any }) =>{
    componentInputId.value = evt.componentId;
                    componentAttributesList.value = evt.attrs;                    
                    if (evt.callBackResponse) {
                        evt.callBackResponse(true)
                    }
}

const doEventComponentInteraction = (evt: { data:Partial<InteractionEvent>, callBackResponse: any }) =>{
    switch(evt.data.typeId){
                        case CatalogEventConst.ONCRUDREFRESHACTIVETAB:
                        if (getPropertyBooleanValue(CrudTableTypeConst.SHOWTABMODE) && crudTableControl.value) {
                            const curTab = crudTableControl.value.currentTab
                            crudTableControl.value.reloadTab(curTab.key); 
                        }    
                        break; 
                        case CatalogEventConst.ONCRUDCLEAR: 
                        if (crudTableControl.value) {
                            crudTableControl.value.clearFilter();
                        }    
                        break;
                        case CatalogEventConst.ONCRUDCLOSEACTIVETAB: 

                        if (getPropertyBooleanValue(CrudTableTypeConst.SHOWTABMODE) &&  crudTableControl.value) {
                            const curTab = crudTableControl.value.currentTab
                            if ((curTab?.closable ?? false)){
                                crudTableControl.value.customCloseTab(curTab.key,true); // para cerrar el detalle
                                crudTableControl.value.customCloseTab(curTab.key); // para cerrar el tab
                            }                            
                        }                        
                        

                        break;
                        case CatalogEventConst.ONCRUDCLOSEALLTAB: 
                        if (getPropertyBooleanValue(CrudTableTypeConst.SHOWTABMODE) &&  crudTableControl.value) {
                            crudTableControl.value.closeAllTabs();
                        }                        
                        break;
                        case CatalogEventConst.ONCRUDSEACH: 
                        if (crudTableControl.value) {
                            crudTableControl.value.doRefreshData();
                        }
                        break;
                        case CatalogEventConst.ONCRUDLOADFROMDS: 
                        if (crudTableControl.value) {
                            crudTableControl.value.doSearch()
                        }
                        break;
                        
                    }

                    
                    if (evt.callBackResponse) {
                        evt.callBackResponse(true)
                    }
}

        //const functions = ref([]);
        onMounted(() => {
            if (!getPropertyBooleanValue(CrudTableTypeConst.SHOWTABMODE)) {                
                EventBusCustom.on(keyEventComponent, doEventComponent);

               
            }
            
            EventBusCustom.on(keyComponentEventInteraction, doEventComponentInteraction);
        })

        const clearVars = () =>{


            opNewRegister.value = null as any;
            menuItems.value = null as any;
            slotControls.value = null as any;
            crudTableControl.value = null as any;
            componentAttributesList.value = [];


        }

        onUnmounted(() => {
            baseOnUnmount();
            if (!getPropertyBooleanValue(CrudTableTypeConst.SHOWTABMODE)) {
                EventBusCustom.off(keyEventComponent, doEventComponent);
            }

            // eventos propios para cerrar tabs, currenttab,etc
            EventBusCustom.off(keyComponentEventInteraction,doEventComponentInteraction);

            if (getPropertyValue(CrudTableTypeConst.SELECTEDROW)) {
                updateSelectedRow(null);
                markUnloadComponents(props.Component.get(props.Component.id,'allchildren') as ComponentDataForm[]);            
            }

            if (getPropertyValue(CrudTableTypeConst.SELECTEDMULTIPLEROW)) {
                updateSelectMultipleRow(null)
            }
            
            clearVars();
        })

        const raiseEndImportCommand = () =>{            
            const propsIdCmd = getPropertyId(CrudTableTypeConst.CMD_AFTER_IMP) ?? '';
                const hasWf = props.Component.workFlows?.find(x => x.idObjeto == CatalogObjectTypeConst.ATTRCOMP && x.objetoId == propsIdCmd && x.active == true)
                if (propsIdCmd && hasWf) {

                    // parche para ocultar el detalle en el modo no tab
                    processEvent(CatalogObjectTypeConst.ATTRCOMP, propsIdCmd);
                }
        }

        const raiseStartImportCommand = (doImportData:any) =>{  
            const propsIdCmd = getPropertyId(CrudTableTypeConst.CMD_BEFORE_IMP) ?? '';
                const hasWf = props.Component.workFlows?.find(x => x.idObjeto == CatalogObjectTypeConst.ATTRCOMP && x.objetoId == propsIdCmd && x.active == true)
                if (propsIdCmd && hasWf) {

                    // parche para ocultar el detalle en el modo no tab
                    processEvent(CatalogObjectTypeConst.ATTRCOMP, propsIdCmd,null,'',doImportData)
                }
                else
                {
                    if (doImportData){
                            doImportData()
                        }
                }
        }

        return {
            MessageService,
            MessageType,
            getPropertyValue,
            getPropertyBooleanValue,
            getPropertyNumberValue,
            vmodel,
            CrudTableTypeConst,
            loaded,
            BaseControlTypeConst,
            updateSelectedRow,
            backToList,
            menuItems,
            crudTableControl,
            opNewRegister,
            openCustomNew,
            saveCustomForm,
            slotControls,
            hideCustomNew,
            fieldKey,
            fieldKeyComponentDataSourceId,
            viewDetail,
            componentInputId,
            componentAttributesList,
            getLayoutDetailComponentId,
            getCatalogValue,
            ObjectGroupConst
            ,canDoOperation
            ,updateSelectMultipleRow
            ,raiseEndImportCommand
            ,raiseStartImportCommand
            ,componentKey
        };
    },
});
</script>
<style scoped></style>
