export default class DisplayComponentNodeConst {
    static VAR_PARAM_IN='variableComponentParametersInput';
    static VAR_COMP_IN='variableComponentInput';
    static VAR_HEADER_IN='variableComponentHeaderInput';
    static VAR_COMPMODAL_IN='variableComponentModalInput';

    static VAR_COMPCURR_IN='variableComponentCurrInput';
    
  }

