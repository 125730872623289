import BaseControlTypeConst from "./BaseControlTypeConst"

export default class ExpressionControlTypeConst extends BaseControlTypeConst {

    /*
    static OPTIONS = 'options'
    static OPTIONLABEL = 'optionLabel'
    static OPTIONVALUE = 'optionValue'
    static OPTIONDISABLED = 'optionDisabled'
    static OPTIONGROUPLABEL = 'optionGroupLabel'
    static OPTIONGROUPCHILDREN = 'optionGroupChildren'
    
    static DATAKEY = 'dataKey'  
    static READONLY = 'readonly'  
    */

    static DSEXP = 'dataSourceExpression'
    static DSEXPTREE = 'dataSourceTreeTools'

    static FIELDS_DEF = 'fieldsDefinition'
    static DSEXP_MAP = 'dataSourceExpMapping'
    
    static TITLE = 'title'

    static DIC_TRANS = 'dictionaryTranslates'

    static DIC_TREE_OBJECT = 'dictionaryTreeObject'

    static CAT_FILTER ='catalogList'

    

}
