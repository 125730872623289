import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IUserRepository } from '../domain/IUserRepository';
import { IServiceUser } from './IServiceUser';
import { IapmUser } from '../domain/iapmUser';



@injectable()
export class ServiceUser implements IServiceUser {
  private readonly repo: IUserRepository

  public constructor (@inject(TYPES.USR_REPOSITORY) _repo: IUserRepository) {
    this.repo = _repo
  }

  async getAllUsersWithProfiles () :Promise<IapmUser[]> {
    return await this.repo.getAllUsersWithProfiles()
  }

  async updateMicrosoftAccount (userId: number, microsoftAccount: string | null) :Promise<Boolean> {
    return await this.repo.updateMicrosoftAccount(userId, microsoftAccount)
  }

  async updateGoogleAccount (userId: number, googleAccount: string | null) :Promise<Boolean> {
    return await this.repo.updateGoogleAccount(userId, googleAccount)
  }
}
