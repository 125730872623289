import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import { ICodeRepository } from '../domain/ICodeRepository';


import { IapCode } from '../domain/iapCode';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';

import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';
import { ADD, UPDATE } from './mutation/codeMutation';
import { GET_ALL, GET_BY_ID } from './query/codeQuery';



@injectable()
export class CodeApolloClientRepository implements ICodeRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }
  
  

  async update (dataInput: IapCode): (Promise<IapCode>) {
    return new Promise<IapCode>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.codeMutation.updateCode)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async add (dataInput: IapCode): (Promise<IapCode>) {
    return new Promise<IapCode>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.codeMutation.addCode)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

 

  async getAll(applicationId: Number,applicationVersion: Number,idObject:String,objectId:String): (Promise<IapCode[]>) {
    return new Promise<IapCode[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_ALL, { applicationId:applicationId,applicationVersion:applicationVersion, idObjeto:idObject,objetoId:objectId }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.codeQuery.getAll)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getById (id: string): (Promise<IapCode>) {
    return new Promise<IapCode>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_BY_ID, { id: id }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.codeQuery.getById)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


}


