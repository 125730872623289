import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: SearchConfigInputType!,$dataToUpdate:[SearchConfigInputType]!){
    searchConfigMutation{
        updateSearchConfig(data:$data,dataToUpdate:$dataToUpdate)
        {         
            id   
            userId
            applicationId
            applicationVersion
            componentId
            profileId
            name
            data
            fcr
            idSearchConfigType
            ucr
            group
        }
    }
}
`);

export const ADD = gql(`
mutation($data: SearchConfigInputType!){
    searchConfigMutation{
        addSearchConfig(data:$data)
        {        
            id    
            userId
            applicationId
            applicationVersion
            componentId
            profileId
            name
            data
            fcr
            idSearchConfigType
            ucr
            group
        }
    }
}
`);

export const DELETE = gql(`
mutation($id: Int! ){
    searchConfigMutation{
        deleteSearchConfig(id: $id)
    }
}
`);





