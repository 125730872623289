<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
            
    <ToggleButton  v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(ToogleButtonTypeConst.ID)" 
        v-tooltip="getPropertyValue(ToogleButtonTypeConst.TOOLTIP)"
        :style="getPropertyValue(ToogleButtonTypeConst.STYLE)"
        :class="{ [getPropertyValue(ToogleButtonTypeConst.CLASS) ?? '']: true }"
        :name="getPropertyValue(ToogleButtonTypeConst.NAME)" 
        :disabled="getPropertyBooleanValue(ToogleButtonTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(ToogleButtonTypeConst.VISIBLE)"
        :placeholder="getPropertyValue(ToogleButtonTypeConst.PLACEHOLDER)" 
        :onLabel="getPropertyValue(ToogleButtonTypeConst.ONLABEL)"
        :offLabel="getPropertyValue(ToogleButtonTypeConst.OFFLABEL)"
        :iconPos="getCatalogValue(ToogleButtonTypeConst.ICONPOS)"
        :tabindex="getPropertyValue(ToogleButtonTypeConst.TABINDEX)"
        :onIcon="getPropertyValue(ToogleButtonTypeConst.ONICON)"
        :offIcon="getPropertyValue(ToogleButtonTypeConst.OFFICON)"
        v-model="vmodel" 
        @change="processEventComponent(CatalogEventConst.CHANGE)">
    </ToggleButton >
</template>
<script lang="ts">


import { defineComponent, onMounted,  onUnmounted } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';
import { useStore } from 'vuex';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import ToogleButtonTypeConst from '../../../../domain/Constants/ToogleButtonTypeConst';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_toogle_button',
    components: {
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },

        slotProps: {
            type: Object,
            default: () => ({})
        },
 

    },
    setup(props, context) {
        //
        const store = useStore();
        const { vmodel,canDoOperation,baseOnMounted, isValidData, getPropertyValue, getPropertyBooleanValue, loaded,
              resolveExpressions, getCatalogValue, baseOnUnmount,fieldKey,fieldKeyComponentDataSourceId,processEventComponent
              ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);


 
       
        onMounted(() => {
            baseOnMounted();
         
        })

        onUnmounted(() => {
            baseOnUnmount();
        })

        return {
   
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            ToogleButtonTypeConst,
            BaseControlTypeConst,
            loaded,
            processEventComponent,
            CatalogEventConst,
            getCatalogValue,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey

        };
    },
});
</script>
<style scoped></style>
