import gql from 'graphql-tag';


export const DELETE = gql(`
mutation($id: Int!){
    componentMutation{
        deleteComponent(id:$id)
    }
}
`);

export const UPDATE = gql(`
mutation($data: ComponentInputType!){
    componentMutation{
        updateComponent(data:$data){
            id
            applicationId
            applicationVersion
            parentId
            name
            description
            reusable
            reusableComponentId
            reusableApplicationId
            reusableApplicationVersion
            active
            idType
            idSubType
            order,
            fcr,
            ucr,
            uum,
            fum,
        }
    }
}
`);

export const ADD = gql(`
mutation($data: ComponentInputType!){
    componentMutation{
        addComponent(data:$data){
            id
            applicationId
            applicationVersion
            parentId
            name
            description
            active
            idType
            idSubType
            reusable
            reusableComponentId,
            reusableApplicationId
            reusableApplicationVersion
            order,
            fcr,
            ucr,
            uum,
            fum,
            iapComponentAttributes{
                    id
                    idDataType
                    idAttributeType
                    idType
                    idModelValueType
                    name
                    value
                    order
                    valueBinaryFileName
                    valueBinaryFileType
                    valueBinaryString
                }
        }
    }
}
`);

export const SORT = gql(`
mutation($data: [ComponentInputType]!){
    componentMutation{
        sortComponent(data:$data)
    }
}
`);


export const ADD_COMPONENT_FILE = gql(`
    mutation($data: [ComponentFileInputType]!){
        componentMutation{
            addComponentFile(data:$data){
                componentId
                fileName
            }
        }
    }
    `);
    


