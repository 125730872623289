import BaseControlTypeConst from "./BaseControlTypeConst"

export default class TreeSelectTypeConst extends BaseControlTypeConst {

    //static BINARY = 'binary'
    static READONLY = 'readonly'
    //static REQUIRED = 'required'
    static TABINDEX = 'tabIndex'

    static DATAVALUE = 'datavalue'
    static DATAFILEVALUE = 'datafilevalue'
    static DATASOURCEVALUE = 'datasourcevalue'

    static SCROLLHEIGHT = 'scrollHeight'
    static VARIANT = 'variant'
    static SELECTIONMODE = 'selectionMode'
    static FLUID = 'fluid'
    static EMPTYMESSAGE = 'emptyMessage'
    static DISPLAY = 'display'
    static METAKEYSELECTION = 'metaKeySelection'
}