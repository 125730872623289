import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';

import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { GET_ALL_WORKFLOWACTIVITYCONTROL, GET_WORKFLOWACTIVITYCONTROL_BY_ID } from './query/workFlowActivityControlQuery';
import { ADD, UPDATE, DELETE } from './mutation/workFlowActivityControlMutation';
import { IWorkFlowActivityControlRepository } from '../domain/repositorry/IWorkFlowActivityControlRepository';

import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';
import { IapWorkFlowActivityControl } from '../domain/service/iapWorkFlowActivityControl';








@injectable()
export class WorkFlowActivityControlApolloClientRepository implements IWorkFlowActivityControlRepository {
  private apolloClient : MyApolloClient
  private apolloClientId: string
  constructor (apolloInstance: MyApolloClient, _apolloClientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.apolloClientId = _apolloClientId
  }
  

  async update (dataInput: IapWorkFlowActivityControl): (Promise<IapWorkFlowActivityControl>) {
    return new Promise<IapWorkFlowActivityControl>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE, { data:dataInput }, this.apolloClientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.workFlowActivityControlMutation.updateWorkFlowActivityControl)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async add (dataInput: IapWorkFlowActivityControl): (Promise<IapWorkFlowActivityControl>) {
    return new Promise<IapWorkFlowActivityControl>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD, { data:dataInput }, this.apolloClientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.workFlowActivityControlMutation.addWorkFlowActivityControl)
        }
        catch(e) {
          reject()
        }
      });

    });
  }


  async delete (dataInput: IapWorkFlowActivityControl): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(DELETE, { data:dataInput }, this.apolloClientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.workFlowActivityControlMutation.deleteWorkFlowActivityControl)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async getAll (maxregs: number | undefined): (Promise<IapWorkFlowActivityControl[]>) {
    return new Promise<IapWorkFlowActivityControl[]>(async (resolve, reject) => {
      
      const result = this.apolloClient.executeQuery(GET_ALL_WORKFLOWACTIVITYCONTROL, { maxregs: maxregs }, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.workFlowActivityControlQuery.getAll)
        }
        catch(e) {
          reject()
        }
      });
      
    });

  }

  async getById (id: number): (Promise<IapWorkFlowActivityControl>) {
    return new Promise<IapWorkFlowActivityControl>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_WORKFLOWACTIVITYCONTROL_BY_ID, { id: id}, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.workFlowActivityControlQuery.getById)
        }
        catch(e) {
          reject()
        }
      });
      
    });
  }

}


