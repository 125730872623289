import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: ImportConfigInputType!){
    importConfigMutation{
        updateImportConfig(data:$data)
        {            
            id
            name
            fieldKey
            numLineHeader
            data
        }
    }
}
`);

export const ADD = gql(`
mutation($data: ImportConfigInputType!){
    importConfigMutation{
        addImportConfig(data:$data)
        {            
            id
            name
            fieldKey
            numLineHeader
            data
        }
    }
}
`);

export const DELETE = gql(`
mutation($id: Int!){
    importConfigMutation{
        deleteImportConfig(id: $id)
    }
}
`);





