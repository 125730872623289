export default class ExpressionToolConst {
  static EXPTOOL_ABS='exptool-abs'
  static EXPTOOL_CEIL='exptool-ceil'
  static EXPTOOL_DIV='exptool-div'
  static EXPTOOL_FN='exptool-fn'
  static EXPTOOL_FNI='exptool-fni'
  static EXPTOOL_FNP='exptool-fnp'
  static EXPTOOL_ID='exptool-id'
  static EXPTOOL_IF='exptool-if'
  static EXPTOOL_IN='exptool-in'
  static EXPTOOL_LO='exptool-lo'
  static EXPTOOL_LY='exptool-ly'
  static EXPTOOL_MIN='exptool-min'
  static EXPTOOL_MINUS='exptool-minus'
  static EXPTOOL_MULT='exptool-mult'
  static EXPTOOL_OADI='exptool-oadi'
  static EXPTOOL_OLOG='exptool-olog'
  static EXPTOOL_OMULT='exptool-omult'
  static EXPTOOL_OP='exptool-op'
  static EXPTOOL_OREL='exptool-orel'
  static EXPTOOL_OT='exptool-ot'
  static EXPTOOL_PAY='exptool-pay'
  static EXPTOOL_PCM='exptool-pcm'
  static EXPTOOL_PCN='exptool-pcn'
  static EXPTOOL_PCS='exptool-pcs'
  static EXPTOOL_PCU='exptool-pcu'
  static EXPTOOL_PDD='exptool-pdd'
  static EXPTOOL_PE='exptool-pe'
  static EXPTOOL_PEA='exptool-pea'
  static EXPTOOL_PF='exptool-pf'
  static EXPTOOL_PGD='exptool-pgd'
  static EXPTOOL_PGM='exptool-pgm'
  static EXPTOOL_PHOY='exptool-phoy'
  static EXPTOOL_PLFTF='exptool-plftf'
  static EXPTOOL_PLUS='exptool-plus'
  static EXPTOOL_PMD='exptool-pmd'
  static EXPTOOL_PMSJ='exptool-pmsj'
  static EXPTOOL_PR='exptool-pr'
  static EXPTOOL_PS='exptool-ps'
  static EXPTOOL_PSV='exptool-psv'
  static EXPTOOL_PV='exptool-pv'
  static EXPTOOL_PVPT='exptool-pvpt'
  static EXPTOOL_PYD='exptool-pyd'
  static EXPTOOL_RDIST='exptool-rdist'
  static EXPTOOL_REQ='exptool-req'
  static EXPTOOL_RG='exptool-rg'
  static EXPTOOL_RGEQ='exptool-rgeq'
  static EXPTOOL_ROUND='exptool-round'
  static EXPTOOL_RS='exptool-rs'
  static EXPTOOL_RSEQ='exptool-rseq'
  static EXPTOOL_TRUNC='exptool-trunc'
  static EXPTOOL_NOMB='exptool-nomb'
  static EXPTOOL_EM='exptool-em'
  static EXPTOOL_PROFILE='exptool-perf'
  static EXPTOOL_PROFILETYPE='exptool-pt'
  static EXPTOOL_LASTSESSION='exptool-lastses'
  static EXPTOOL_TRYFAIL='exptool-tryfail'
  static EXPTOOL_MAINENTITYID = 'exptool-mainentityid'
  static EXPTOOL_USU='exptool-usu'
  static EXPTOOL_VAR='exptool-var'
  static EXPTOOL_CAT='exptool-cat'
  static EXPTOOL_USRID='exptool-uid'
  static EXPTOOL_MENU='exptool-menu'
  static EXPTOOL_GETOBJPROP='exptool-getpropertyobject'
  
  
  }



  
  