import ChartTypeConst from "./ChartTypeConst"

export default class ChartBarTypeConst extends ChartTypeConst {

    static USEPOINTSTYLE= 'usePointStyle'
    static COLOR= 'color'
    static BACKGROUNDCOLOR= 'backgroundColor'
    static HOVERBACKGROUNDCOLOR= 'hoverBackgroundColor'
    static CUTOUT= 'cutout'
    static LABELDATA= 'labelData'
    
  }