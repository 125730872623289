import gql from 'graphql-tag';


export const SENDMAIL = gql(`
mutation($userId: Int!, $to: String!, $cc: String!, $subject: String!, $body: String!, $attachments: [UploadFileInputType]!){
    sendMailMutation {
        sendMail(userId:$userId, to:$to, cc:$cc, subject:$subject, body:$body, attachments:$attachments)
    }
}
`);



