import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentViewer = _resolveComponent("DocumentViewer")!

  return (_ctx.showViewer && _ctx.doc)
    ? (_openBlock(), _createBlock(_component_DocumentViewer, {
        key: 0,
        visible: _ctx.showViewer,
        "onUpdate:Visibility": _cache[0] || (_cache[0] = ($event: any) => (_ctx.showViewer=false)),
        document: _ctx.doc,
        canvas: _ctx.canvas
      }, null, 8 /* PROPS */, ["visible", "document", "canvas"]))
    : _createCommentVNode("v-if", true)
}