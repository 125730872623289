import { createVNode, render,createApp, h } from 'vue'

/*
export default function renderComponent({ el, component, props, appContext }) {
  
  let vnode = createVNode(component.default, props)
  vnode.appContext = { ...appContext } // must spread new object here
  render(vnode, el)

  return () => {
    // destroy component
    render(null, el)
    vnode = undefined
  }
}
*/

/*
export default function renderComponent({ el, component, props, appContext }) {
  
  let app = createApp(component.default, props)
  Object.assign(app._context, appContext) // must use Object.assign on _context
  app.mount(el)

  return () => {
    // destroy app/component
    app?.unmount()
    app = undefined
  }
}
*/

export default function renderComponent({ el, component, props, appContext }) {
   // instantiate my Foo.vue, and pass it the props it needs   
   const vueComponent = h(component.default ?? component, props);
   vueComponent.appContext = { ...appContext }; // must spread new object here
   // Append it to p (which is an HTML Element)
   render(vueComponent, el);
   
   vueComponent.destroy = () => {
    if (el) render(null, el)
    el = null
}

   return vueComponent;
}

