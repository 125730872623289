<template>
    <span>Mensaje</span>

    <!--
    <Textarea  @pointerdown.stop="" @dblclick.stop="" v-model="selectedText" rows="5" cols="30" />
    <Dropdown @pointerdown.stop="" @dblclick.stop=""  v-model="selectedMsgType" :options="selectedMsgTypeData" optionLabel="description" optionValue="id" placeholder="Select a City" class="w-full md:w-14rem" />
-->
    <div style="display: flex;">
        <textarea style="resize: none;" @pointerdown.stop="" @dblclick.stop="" id="w3review" name="msgText" rows="4" cols="50"
            v-model="selectedText"
            class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full "></textarea>
        <i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" class="fa fa-subscript"
            @click="showExpression(TextNodeConst.VAR_TEXT_IN, 'Texto a mostrar')"></i>
    </div>
</template>
<script lang="ts">

import { computed, defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import debounce from 'lodash.debounce';
import Dropdown from 'primevue/dropdown';
import HelperCustomComponentControls from '../../../functions/helperCustomComponentControls';
import Textarea from 'primevue/textarea';
import TextNodeConst from '../constants/TextNodeConst';
//import PrimeVue from "primevue/config";
export default defineComponent({
    name: 'custotextcontrol',
    components: {
        Dropdown,
        Textarea,
        //PrimeVue
    },
    props: {
        data: Object
    },
    setup(props) {
        const { showExpression } = HelperCustomComponentControls(props.data);
        const selectedText = ref('');


        let debounceUpdateData: any;
        const debounceTime = 1200;
       
    
        
  const initDebounce = () => {
    debounceUpdateData = debounce((data: any, start: boolean = false) => {
        if (props.data){
            props.data.onChange({ key: TextNodeConst.VAR_TEXT_IN, data: selectedText.value })        
        }
    }, debounceTime);
  }

  watch(
    selectedText, (newvalue, oldvalue) => {
          if (oldvalue !== newvalue && oldvalue !== undefined) {
              debounceUpdateData(newvalue, true);            
          }
        },
        {
          flush: 'post',
          //immediate: true, 
          deep: true
        }
      )


        onMounted(() => {
            if (props.data) {
                selectedText.value = props.data.variableTextInput
            }
            initDebounce();
        })


        onBeforeUnmount(() => {
            debounceUpdateData.cancel();
        })



      
        return {
            selectedText,
            showExpression,
            TextNodeConst
        };
    },
});
</script>
<style scoped></style>
