import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';

import { IComponentAttributeRepository } from '../domain/IComponentAttributeRepository';

import { UPDATE, ADD,DELETE } from './mutation/componentAttributeMutation';
import { IapComponentAttribute } from '../domain/iapComponentAttribute';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { GET_BY_COMPONENTID } from './query/componentAttributeQuery';
import { ComponentPropertyData } from '../domain/componentPropertyData';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class ComponentAttributeApolloClientRepository implements IComponentAttributeRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }
  
  async delete (id: string): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(DELETE, { id: id }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentAttributeMutation.deleteComponentAttribute)
        }
        catch(e) {
          reject()
        }
      });
    });
  }
  

  async update (dataInput: IapComponentAttribute): (Promise<IapComponentAttribute>) {
    return new Promise<IapComponentAttribute>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE, { data: dataInput }, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentAttributeMutation.updateComponentAttribute)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async add (dataInput: IapComponentAttribute): (Promise<IapComponentAttribute>) {
    return new Promise<IapComponentAttribute>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentAttributeMutation.addComponentAttribute)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async getByComponentId (componentId: number,componentTypeId:string): (Promise<ComponentPropertyData>) {
    return new Promise<ComponentPropertyData>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_BY_COMPONENTID, { componentId: componentId,componentTypeId:componentTypeId },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.componentAttributeQuery.getByComponentId)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


}


