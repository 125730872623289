import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IApplicationConnectionRepository } from '../domain/IApplicationConnectionRepository';



import { IServiceApplicationConnection } from './IServiceApplicationConnection';
import { IapApplicationConnection } from '../domain/iapApplicationConnection';





@injectable()
export class ApplicationConnectionService implements IServiceApplicationConnection {
  private readonly repo: IApplicationConnectionRepository

  public constructor (@inject(TYPES.APPLICATIONCONNECTION_REPOSITORY) _repo: IApplicationConnectionRepository) {
    this.repo = _repo
  }
  
  async getAll(maxregs: number | undefined) :Promise<IapApplicationConnection[]> {
    return await this.repo.getAll(maxregs)
  }

  async getById(id: number, version: number) :Promise<IapApplicationConnection> {
    return await this.repo.getById(id, version)
  }
}
