<template>
    <div>
        <div class="card">
            <Toolbar class="mb-4">
                <template #start>
                    <Button label="Nuevo" icon="pi pi-plus" severity="success" class="mr-2" @click="openNew" />
                    <Button v-if="!isExternal" label="Borrar" icon="pi pi-trash" severity="danger" @click="confirmDeleteSelected"
                        :disabled="!selectedVariables || !selectedVariables.length" />
                </template>

                <template #end>
                    <!-- <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" /> -->
                    <!-- <Button label="Export" icon="pi pi-upload" severity="help" @click="exportCSV($event)"  /> -->
                    <Button  icon="pi pi-refresh" severity="success" class="mr-2" @click="doRefresh()" />
                </template>
            </Toolbar>
            <DataTable ref="dt" :value="variables" v-model:selection="selectedVariables" dataKey="id" :paginator="true"
                :rows="10" :filters="filters" editMode="cell" @cell-edit-complete="onCellEditComplete"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[5, 10, 25]"
                currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} variables">
                <template #header>
                    <div class="flex flex-wrap gap-2 align-items-center justify-content-between">
                        <h4 class="m-0">Variables</h4>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Search..." />
                        </span>
                    </div>
                </template>

                <Column  v-if="!isExternal" selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>
                <Column field="variable" header="Nombre" sortable style="min-width:12rem"></Column>
                <Column field="expressionTranslated" header="Expresión" sortable style="min-width:16rem">

                    <template #body="{ data }">
                        {{ data.expressionTranslated }}
                    </template>

                </Column>

                <Column field="processOrder" sortable header="Orden" :style="{ 'text-align': 'center' }">
                    <template #header>
                        <i class="fa-solid fa-pen-to-square mr-2"></i>
                    </template>
                    <template #editor="{ data }">
                        <InputNumber :id="data.processOrder + '_order_' + data.id" mode="decimal"
                            v-model="data.processOrder" showButtons :inputStyle="'width: 50px'" />
                    </template>
                    <template #body="{ data }">
                        {{ data.processOrder }}
                    </template>
                </Column>
                <Column v-if="!isExternal" field="localizable" sortable header="Localizable" :style="{ 'text-align': 'center' }">
                    <template #header>
                        <i class="fa-solid fa-pen-to-square mr-2"></i>
                    </template>
                    <template #editor="{ data }">
                        <InputSwitch :id="data.localizable + '_localizable_' + data.id" v-model="data.localizable" rows="5"
                            cols="30" aria-describedby="text-error" maxlength="100" />
                    </template>
                    <template #body="{ data }">
                        <i class="pi"
                            :class="{ 'pi-check-circle text-green-500': data.localizable, 'pi-times-circle text-red-500': !data.localizable }"></i>
                    </template>
                </Column>



                <Column :exportable="false" style="min-width:8rem">
                    <template #body="slotProps">
                        <Button icon="pi pi-pencil" outlined rounded class="mr-2" @click="editVariable(slotProps.data)" />
                        <Button icon="pi pi-trash" outlined rounded severity="danger"
                            @click="confirmDeleteVariable(slotProps.data)" />
                    </template>
                </Column>
            </DataTable>
        </div>

        <Dialog v-model:visible="visible" modal maximizable :style="{ width: '100%' }">
            <template #header>
                <h5><i class="fa fa-subscript " aria-hidden="true" /> Editor de Expresiones</h5>
            </template>
            <ExpressionEditor :container="container" :catalogTypes="catalogTypes" :expressionId="getExistingExpressionId"
                :id="selectedVariable" :order="getOrder" :expressionDetail="variables" :externalData="externalData"
                :applicationId="applicationId" :applicationVersion="applicationVersion"  
                :idObjeto="idObjeto" :objetoId="objetoId" 
                :idObjetoRoot="idObjetoRoot" :objetoIdRoot="objetoIdRoot" 
                :expType="expType"
                @close="visible = false" @refresh="doRefresh()"
                :externalId="externalId"
                :isExternal="isExternal"
                :externalTree="externalTree"
                :externalTranslateFunction="externalTranslateFunction"
                @external:update="$emit('external:update',$event)"
                @external:insert="$emit('external:insert',$event)"
                @expressionsChanged="expressionsChanged" :lookUp="lookUp"/>
        </Dialog>

    </div>
</template>

<script lang="ts">

import { defineComponent, ref, onMounted, computed, watch } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import { useToast } from 'primevue/usetoast';
import ExpressionEditor from './ExpressionEditor.vue';
import { Container } from 'inversify';
import { IapCatalogType } from '../../../catalog/domain/iapCatalogType';
import { IapExpression } from '../../domain/iapExpression';
import HelperLoading from '../../../../../common/infrastructure/funciones/HelperLoading';
import { IServiceExpression } from '@ilinium/shared/src/entidades/builder/expression/application/IServiceExpression';
import { TYPES } from '../../../../../common/domain/types';
import CatalogTypeConst from '../../../catalog/domain/const/CatalogTypeConst';
import CatalogExpConst from '../../../catalog/domain/const/CatalogExpConst';
import { IapExpressionDetail } from '../../domain/iapExpressionDetail';
import { IServiceExpressionDetail } from '@ilinium/shared/src/entidades/builder/expression/application/IServiceExpressionDetail';
import { useStore } from 'vuex';
import HelperUtils from '../../../../../common/infrastructure/funciones/HelperUtils';
import { MessageService } from '../../../../../common/infrastructure/servicios';
import { MessageType } from '../../../../../common/infrastructure/servicios/MessageService';
import { IapApplication } from '../../../application/domain/iapApplication';
import { IServiceComponent } from '../../../component/application/IServiceComponent';
import WidgetComponentDataTree from '../../../designer/domain/widgetComponent';
import ComponentUtil from '../../../designer/infrastructure/component/util/componentUtil';
import CatalogObjectTypeConst from '../../../catalog/domain/const/CatalogObjectTypeConst';
import ExpressionTreeNode from '../../domain/Functions/ExpressionTreeNode';
import ExpressionTranslator from '../../domain/Functions/ExpressionTranslator';
import { IapDataSourceLookUp } from '../../../datasource/domain/iapDataSourceLookUp';

export default defineComponent({
    name: 'expression_list',
    props: {
        container: {
            type: Object as () => Container
        },
        idObjeto: {
            type: String,
            default: () => ('')
        },
        objetoId: {
            type: String,
            default: () => ('')
        },
        idObjetoRoot: {
            type: String,
            default: null
        },
        objetoIdRoot: {
            type: String,
            default: null
        },

        catalogTypes: {
            type: Object as () => IapCatalogType[],
            default: () => ([])
        },
        expressionTypes: {
            type: Object as () => [],
            default: () => ([CatalogExpConst.EXP_SET, CatalogExpConst.EXP_VAL])
        },
        applicationId: {
            type: Number,
            default: -1
        },
        applicationVersion: {
            type: Number,
            default: -1
        },
        externalId: {
            type: String,
            default: () => ('')
        },
        expType: {
            type: String,
            default: () => ('')
        },
        expressions: {
            type: Object as () => IapExpression[],
            default: () => ([])
        },
        lookUp: {
            type: Object as () => IapDataSourceLookUp,
            default: null
        },
        isExternal: {
            type: Boolean,
            default: false
        },
        externalTree: {
            type: Object,
            default: undefined
        },
        externalTranslateFunction: {
            type: Function,
            default: undefined
        },
    },
    components: {
        ExpressionEditor
    },
    emits: ['expressionsChanged','load:Expressions','external:update','external:delete','external:insert'],
    setup(props,{ emit }) {
        
        const store = useStore();
        const visible = ref(false);
        const selectedVariable = ref('');
        
        const toast = useToast();
        const dt = ref();
        const variables = ref<IapExpressionDetail[]>([])
        const variableDialog = ref(false);
        const deletedVariableDialog = ref(false);
        const deletedVariablesDialog = ref(false);
        const variable: any = ref({});
        const selectedVariables = ref();
        const filters = ref({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        });
        const submitted = ref(false);
        const expressionTraslation = ref();
        const expressionTree = ref();


        const getExistingExpressionId = (computed(() => {
            return props.expressions.find(x => x.idTypeExpression == props.expType)?.id
        }))

        const getOrder = (computed(() => {
            return (variables.value.length + 1)
        }))

        const openNew = () => {
            selectedVariable.value = ''
            visible.value = true;
        };
        const hideDialog = () => {
            variableDialog.value = false;
            submitted.value = false;
        };
        const saveVariable = () => {
            submitted.value = true;
        };
        const editVariable = (prod: IapExpressionDetail) => {
            selectedVariable.value = (props.isExternal ? JSON.stringify(prod) : prod.id)
            visible.value = true;
        };
        const confirmDeleteVariable = (prod: IapExpressionDetail) => {
            var doCallbackOk = () => {
                if (!props.isExternal){
                    const dataTodelete = JSON.parse(JSON.stringify(prod));
                    deleteVariable(dataTodelete.id)
                }
                else{
                    emit('external:delete',prod)
                }
                

            }
            MessageService.showMessage(MessageType.Pregunta, '', '¿Eliminar variable "' + prod.variable + '" ?', true, true, false, '', doCallbackOk, null);

        };
        const deleteVariable = (id: string) => {
            if (props.container) {
                const _srv = props.container.get<IServiceExpressionDetail>(TYPES.EXPRESSION_DETAIL_REPOSITORY)
                HelperLoading.showLoading()
                _srv.delete(id).then(response => {
                    if (response) {
                        variables.value = variables.value.filter(x => x.id !== id)

                        if (variables.value.length == 0 && getExistingExpressionId.value) {
                            deleteExpression(getExistingExpressionId.value as any, false)
                        }

                        emit('expressionsChanged', null);
                        emit('load:Expressions', null);



                        MessageService.showToast(MessageType.Info, '', 'Se ha eliminado la variable correctamente');
                    }
                    else {
                        MessageService.showToast(MessageType.Error, '', 'No se ha eliminado la variable');
                    }
                })
                    .finally(() => {
                        HelperLoading.hideLoading()
                    })
            }
            else {
                return []
            }
        };

        const deleteExpression = (id: string, showMsg = true) => {
            if (props.container) {
                const _srv = props.container.get<IServiceExpression>(TYPES.EXPRESSION_REPOSITORY)
                HelperLoading.showLoading()
                _srv.delete(id).then(response => {
                    if (response) {
                        //expressions.value = expressions.value.filter(x => x.id !== id)
                        //variables.value = [] as any
                        emit('expressionsChanged', null);
                        emit('load:Expressions', null);
                        if (showMsg) {
                            MessageService.showToast(MessageType.Info, '', 'Se han eliminado todas las variables');
                        }

                    }
                    else {
                        MessageService.showToast(MessageType.Error, '', 'No se han eliminado todas las variables');
                    }
                })
                    .finally(() => {
                        HelperLoading.hideLoading()
                    })
            }
            else {
                return []
            }
        };


        const exportCSV = () => {
            dt.value.exportCSV();
        };
        const confirmDeleteSelected = () => {
            var doCallbackOk = () => {
                if (selectedVariables.value.length !== variables.value.length) {
                    selectedVariables.value.forEach((prod: any) => {
                        if (!props.isExternal){
                            const dataTodelete = JSON.parse(JSON.stringify(prod));
                            deleteVariable(dataTodelete.id)
                        }
                        else{
                            emit('external:delete',prod)
                        }                       
                    });
                }
                else {                    
                    if (getExistingExpressionId.value && !props.isExternal) {
                        deleteExpression(getExistingExpressionId.value as any)
                    }
                }


            }
            const lstVariables = selectedVariables.value.map((x: { variable: any; }) => x.variable).join(',')
            MessageService.showMessage(MessageType.Pregunta, '', '¿Eliminar las variables "' + lstVariables + '" ?', true, true, false, '', doCallbackOk, null);
        };
        const deleteSelectedVariables = () => {
            deletedVariablesDialog.value = false;
            toast.add({ severity: 'success', summary: 'Successful', detail: 'variables Deleted', life: 3000 });
        };

      

        const loadVariables = () => {
            const expressionId = getExistingExpressionId.value
            if (expressionId && props.container) {
                const _srv = props.container.get<IServiceExpressionDetail>(TYPES.EXPRESSION_DETAIL_REPOSITORY)
                HelperLoading.showLoading()
                _srv.getAll(expressionId).then(response => {
                    if (response) {
                        variables.value = response;
                    }
                })
                    .finally(() => {
                        HelperLoading.hideLoading()
                    })
            }
            else {
                variables.value = [] as any
            }

        }

        const onCellEditComplete = (event: any) => {


            let { data, newValue, field, newData } = event;
            const oldVariable = data as any as IapExpressionDetail;
            const variable = newData as any as IapExpressionDetail;
            const oldValue: string = field as string;


            if (oldVariable[oldValue] !== newValue) {

                if (props.container && !props.isExternal) {
                    const _srv = props.container.get<IServiceExpressionDetail>(TYPES.EXPRESSION_DETAIL_REPOSITORY)
                    variable.uum = store.getters.getCurrentUser.id
                    variable.fum = new Date()
                    const dataToSave = JSON.parse(JSON.stringify(variable));
                    HelperLoading.showLoading()
                    _srv.update(dataToSave as any).then(response => {
                        if (response) {
                            const _varAux = variables.value.find(x => x.id == variable.id)
                            if (_varAux) {
                                Object.assign(_varAux, response)
                            }
                        }
                    }).finally(() => {
                        HelperLoading.hideLoading()
                    })

                }
                else{
                    emit('external:update',variable)
                }
            }
        }

        
        const expressionsChanged = () => {
            emit('expressionsChanged', null);
        };


    const doRefresh = () =>{        
        emit('load:Expressions');
    }

        onMounted(() => {
            variables.value = props.expressions.flatMap(x=> x.iapExpressionDetails);
        });



        return {
            deletedVariablesDialog,
            deleteSelectedVariables,
            openNew,
            confirmDeleteSelected
            , selectedVariables
            , exportCSV
            , variables
            , filters
            , variableDialog
            , editVariable
            , confirmDeleteVariable
            , variable
            , hideDialog
            , deletedVariableDialog
            , submitted
            , visible
            , loadVariables
            , getExistingExpressionId
            , selectedVariable
            , getOrder
            , onCellEditComplete
            //, externalData
            , expressionTraslation
            , expressionTree
            ,expressionsChanged
            //,loadExpressions
            ,doRefresh

        };
    },
});
</script>
<style scoped></style>
    