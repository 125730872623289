import gql from 'graphql-tag';

export const GET_ALL_WORKFLOWACTIVITY = gql(`
query($maxregs: Int) {
  menuQuery {
    getAll {
      applicationId,
      applicationVersion,
      builtin,
      componentId
      contract,
      active,
      fcr,
      fum,
      id,
      idIcon,
      parentId,
      title,
      ucr,
      uum,
      urlComponentStatic,
      urlRouteComponentStatic
    }
  }
}
`);

export const GET_WORKFLOWACTIVITY_BY_ID = gql(`
query($id: Int) {
  menuQuery {
      getById(id:$id) {
        applicationId,
        applicationVersion,
        builtin,
        componentId
        contract,
        active,
        fcr,
        fum,
        id,
        idIcon,
        parentId,
        title,
        ucr,
        uum,
        urlComponentStatic,
        urlRouteComponentStatic
      }
    }
}
`);

