import { ClassicPreset } from 'rete';
import { DataflowEngine } from "rete-engine";
import { DataSourceControl } from '../controls/dataSourceControl';
import { IapComponent } from '../../../../../component/domain/iapComponent';
import { AreaPlugin } from 'rete-area-plugin';
import { AreaExtra, Schemes } from '../rete/helperRete';
import { IapComponentDataSource } from '../../../../../component/domain/iapComponentDataSource';
import { Container } from 'inversify';
import { Router } from 'vue-router';
import { IapWorkFlowActivity } from '../../../../domain/service/iapWorkFlowActivity';
import DataSourceNodeConst from '../constants/DataSourceNodeConst';
import { LocalService } from '../../../../../../../common/infrastructure/servicios';
import { ExpresionEngine } from '../../../../../expression/infrastructure/helper/expressionEngine';
import CatalogObjectTypeConst from '../../../../../catalog/domain/const/CatalogObjectTypeConst';
import CatalogExpConst from '../../../../../catalog/domain/const/CatalogExpConst';
import { IServiceSearch } from '../../../../../search/application/IServiceSearch';
import { TYPES } from '../../../../../../../common/domain/types';
import HelperLoading from '../../../../../../../common/infrastructure/funciones/HelperLoading';
import { IServiceDataSource } from '../../../../../datasource/application/IServiceDataSource';
import { IapDataSource } from '../../../../../datasource/domain/iapDataSource';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';
import CatalogDataSourceType from '../../../../../catalog/domain/const/CatalogDataSourceType';
import OperationDataTypeConst from '../../../../../../../common/domain/constantes/OperationDataTypeConst';
import SqlTypesConst from '../../../../../../../common/domain/constantes/SqlTypesConst';
import HelperUtils from '../../../../../../../common/infrastructure/funciones/HelperUtils';
import { dataUpdate } from '../../../../../dataupdate/domain/dataUpdate';
import { IServiceDataUpdate } from '../../../../../dataupdate/application/IServiceDataUpdate';
import HelperDataUpdate from '../../../../../dataupdate/infrastructure/helper/HelperDataUpdate';
import DataModelInput from '../../../../domain/dataModelInput';
import DataSourceComp from '../../../../../crud/infrastructure/functions/dataSourceComp';
import { DataUpdateOperation } from '../../../../../dataupdate/domain/dataUpdateOperation';
import FiltroBusquedaConst from '../../../../../../../common/domain/constantes/FiltroBusquedaConst';
import { InteractionEvent } from '@ilinium/shared/src/entidades/builder/interaction/domain/interactionEvent';
import InteractionConst from '@ilinium/shared/src/entidades/builder/interaction/domain/interactionConst';
import EventBusCustom from '../../../../../../../common/infrastructure/event-bus-custom';
import { IServiceComponentDataSource } from '@ilinium/shared/src/entidades/builder/component/application/IServiceComponentDataSource';
import EventConst from '../../../../../../../common/domain/constantes/EventConst';
import { GroupSearch } from '../../../../../search/domain/search';
import OperatorLogicConst from '../../../../../search/domain/Const/OperatorLogicConst';

export class DataSourceNode extends ClassicPreset.Node<
  { ejecutar: ClassicPreset.Socket, dataInput: ClassicPreset.Socket },
  { true: ClassicPreset.Socket,false: ClassicPreset.Socket, value: ClassicPreset.Socket },
  { value: DataSourceControl }
> {
  height = 650;
  width = 500;

  private area: AreaPlugin<Schemes, AreaExtra>;
  private updateNode: any;
  private getNodeInternalData: any;
  private showExpression: any;
  private rdControlId: '';
  private router: Router;
  private container: Container | undefined;
  private rootComponentId: number;
  private currentComponentId: number;
  private store: any;
  private activity: IapWorkFlowActivity | undefined;

  private variableInception: number;
  private variableOperation: number;
  private variableComponentId: number;
  private variableComponentDataSourceId: number;
  private variableComponentDataSourceListId: number[];
  private variableConnection: string;
  private variableDsId: string;
  private variableFields: DataModelInput[];
  private variableCfgs: DataModelInput[];
  private dataSources: IapComponentDataSource[];
  private dataflow: DataflowEngine<Schemes>;
  private applicationId: number;
  private applicationVersion: number;

  private componentData: IapComponent[];
  private formKey: String;
  private currentElementKey:string;

  constructor(area: AreaPlugin<Schemes, AreaExtra>, socket: ClassicPreset.Socket
    , dataflow: DataflowEngine<Schemes>
    , updateNode: any = undefined
    , getNodeInternalData: any = undefined
    , showExpressionFunction: any = undefined
    , router: Router
    , container: Container | undefined
    , storeInput: any
    , itemActivity: IapWorkFlowActivity | undefined
    , formKeyInput: String
    , currentElementKeyInput:string
    , rootComponentInputId: number
    , currentComponentInputId: number
    , applicationInputId: number
    , applicationInputVersion: number
    , variableInceptionInput: number
    , variableOperationInput: number
    , variableComponentIdInput: number
    , variableComponentDataSourceIdInput: number
    ,variableComponentDataSourceListIdInput:number[]
    , variableConnectionInput: string
    , variableDsIdInput: string
    , variableFieldsInput: []
    , variableCfgsInput: []
    , dataSources: IapComponentDataSource[]
    , componentDataInput: IapComponent[]) {
    super("Data Source");


    this.area = area;
    this.dataflow = dataflow;
    this.updateNode = updateNode;
    this.getNodeInternalData = getNodeInternalData;
    this.showExpression = showExpressionFunction;
    this.router = router;
    this.container = container;
    this.rootComponentId = rootComponentInputId;
    this.currentComponentId = currentComponentInputId;
    this.store = storeInput;
    this.activity = itemActivity;
    this.formKey = formKeyInput;
    this.currentElementKey = currentElementKeyInput;

    this.variableInception = variableInceptionInput;
    this.variableOperation = variableOperationInput;
    this.variableComponentId = variableComponentIdInput;
    this.variableComponentDataSourceId = variableComponentDataSourceIdInput;
    this.variableComponentDataSourceListId = variableComponentDataSourceListIdInput;
    this.variableConnection = variableConnectionInput;
    this.variableDsId = variableDsIdInput;
    this.variableFields = variableFieldsInput;
    this.variableCfgs = variableCfgsInput;
    this.dataSources = dataSources;
    this.componentData = componentDataInput;
    this.applicationId = applicationInputId;
    this.applicationVersion = applicationInputVersion;

    const dsControl = new DataSourceControl(formKeyInput
      , rootComponentInputId
      , currentComponentInputId
      , applicationInputId
      , applicationInputVersion
      , variableInceptionInput
      , variableOperationInput
      , variableComponentIdInput
      , variableComponentDataSourceIdInput
      ,variableComponentDataSourceListIdInput
      , variableConnectionInput
      , variableDsIdInput
      , variableFieldsInput
      , variableCfgsInput
      , dataSources
      , componentDataInput
      , container
      , this.updateData
      , this.getNode
      , this.showExp
    );


    this.rdControlId = (dsControl as any).id;




    this.addInput("ejecutar", new ClassicPreset.Input(socket, "Ejecutar", true));
    this.addControl(
      "value",
      dsControl

      //new ClassicPreset.InputControl("text", { initial })
    );

    this.addInput("dataInput", new ClassicPreset.Input(socket, "DataInput"));

    
    this.addOutput("true", new ClassicPreset.Output(socket, "Ok"));
    this.addOutput("false", new ClassicPreset.Output(socket, "Error"));

    //area.update("control",dsControl.id)
    this.addOutput("value", new ClassicPreset.Output(socket, "DataOutput"));



  }


  showExp = (evt: any) => {
    if (this.showExpression) {
      return this.showExpression(evt)
    }
    return null;
  }

  getNode = (key: string) => {
    if (this.getNodeInternalData) {
      return this.getNodeInternalData(this.id, key, true, false)
    }
    return null;
  }
  updateData = (evt: any) => {
    
    //this.value = evt
    //@ts-ignore:disable-next-line
    this.controls.value[evt.key] = evt.data;
    //this.controls[evt.key].valueConnection = evt.data;

    this.area.update("control", this.rdControlId)


    if (this.updateNode) {
      this.updateNode(this.id, evt.key, JSON.stringify(evt.data), (evt?.operation ?? OperationDataTypeConst.UPDATE))
    }


  }

  resolveExpressions = () => {


    const currentComp = this.componentData.find(x => x.id == this.currentComponentId)
    
    let wcf = this.activity?.iapWorkFlowActivityControls.find(x => x.name == DataSourceNodeConst.VAR_FIELD_IN);

    if (currentComp && wcf) {
      
      this.variableFields.forEach(param => {
        const key = '#' + wcf?.id.toString() + '#parameterId=' + param.key;
      
        const exps = currentComp.expressions?.filter(x => x.idObjeto == CatalogObjectTypeConst.WFAC && x.idTypeExpression == CatalogExpConst.EXP_SET && x.objetoId.endsWith(key) && x.iapExpressionDetails?.length > 0);
        if (exps?.length > 0) {
          exps?.every(exp => {
            if (exp.iapExpressionDetails?.length > 0) {
              const localData = LocalService.getValue(this.formKey + LocalService.COMPONENTS_EXP + (this.rootComponentId ?? -1).toString());
              const data = HelperUtils.jsonParse(localData,[])
              let resu = ExpresionEngine.resolveExpressions(exp.iapExpressionDetails, data as any, this.store)
              //resu = resu?.toString();
              if (resu) {
                if (Object.keys(resu).length == 0) {
                  resu = resu?.toString();
                }
              }


              param.value = resu;


            }
          })

        }
      })

    }




    // las configuraciones
    wcf = this.activity?.iapWorkFlowActivityControls.find(x => x.name == DataSourceNodeConst.VAR_CFG_IN);

    if (currentComp && wcf) {
      this.variableCfgs.forEach(param => {

        const key = '#' + wcf?.id.toString() + '#parameterId=' + param.key;
        const exps = currentComp.expressions?.filter(x => x.idObjeto == CatalogObjectTypeConst.WFAC && x.idTypeExpression == CatalogExpConst.EXP_SET && x.objetoId.endsWith(key) && x.iapExpressionDetails?.length > 0);
        if (exps?.length > 0) {
          exps?.every(exp => {
            if (exp.iapExpressionDetails?.length > 0) {

              const localData = LocalService.getValue(this.formKey + LocalService.COMPONENTS_EXP + (this.rootComponentId ?? -1).toString());
              const data = HelperUtils.jsonParse(localData,[])
              let resu = ExpresionEngine.resolveExpressions(exp.iapExpressionDetails, data as any, this.store)
              //resu = resu?.toString();
              if (resu) {
                if (Object.keys(resu).length == 0) {
                  resu = resu?.toString();
                }
              }


              param.value = resu;


            }
          })

        }
      })

    }





  }

  getDataSource = async (dataSourceId: number) => {
    return await new Promise<IapDataSource | null>((resolve) => {
      if (this.container) {
        const _srv = this.container.get<IServiceDataSource>(TYPES.DATASOURCE_REPOSITORY)
        HelperLoading.showLoading()
        _srv.getById(this.applicationId, this.applicationVersion, dataSourceId).then(response => {
          resolve(response)
        })
          .finally(() => {
            HelperLoading.hideLoading()
          })
      }
    })
  }


  async execute(input: "ejecutar", forward: (output: "true" | "false") => void) {

    let dataSearch : any
    const inputs = (await this.dataflow.fetchInputs(this.id)) as {
      dataInput: any;
    };

    if (inputs && inputs?.dataInput && inputs.dataInput?.length >= 1) {

      
      inputs.dataInput.forEach( (data:any) =>{
        if (data) {
          //propValue
          this.variableFields.filter((x: any) => x.value.startsWith('#') && x.value.endsWith('#')).forEach(param => {
            //@ts-ignore:disable-next-line
            const keyParam = param.value.replaceAll('#', '');
            param.value = HelperUtils.propValue(data, keyParam)?.toString() ?? ''
          });
  
          this.variableCfgs.filter((x: any) => x.value.startsWith('#') && x.value.endsWith('#')).forEach(param => {
            //@ts-ignore:disable-next-line
            const keyParam = param.value.replaceAll('#', '');
            param.value = HelperUtils.propValue(data, keyParam)?.toString() ?? ''
          });
        }
      })


    }



    // vamos a buscar las expresiones si las hubiera
    this.resolveExpressions();
    // fin de resolución de expresiones




    // se llaman a las operaciones sobre los datasources
    if (!HelperCommon.isNullOrWhitespace(this.variableDsId)) {
      await this.getDataSource(parseInt(this.variableDsId)).then(dsData => {

        if (dsData) {
          //const tabledata = dsData.iapDataSourceTableAliases.filter(x=> x.isInput == true)

          let fieldList = this.variableFields.map(x => x.key)
          let dataToUpdate = new Array<dataUpdate>()
          if (dsData?.idDataSourceType == CatalogDataSourceType.SERVICE) {

            // datos de los servicios
            dataToUpdate.push({
              valores: dsData.iapDataSourceFields?.sort((a, b) => { return (a.position ?? 0) - (b.position ?? 0); }).filter(c => fieldList.includes(c.id)).map(x => ({
                fieldId: x.id,
                value: x.sqlType == SqlTypesConst.BIT  && typeof((this.variableFields.find(v => v.key == x.id)?.value ?? '')) == 'boolean' ? JSON.stringify(this.variableFields.find(v => v.key == x.id)?.value ?? '') : (this.variableFields.find(v => v.key == x.id)?.value ?? '')
              })) as any,
              claves: [],
              tipoOperacion: OperationDataTypeConst.UPDATE,
              id: HelperUtils.newGuid()
            } as dataUpdate)
          }
          else {
            // datos sobre la bbdd
            
            fieldList = this.variableFields.filter(x=> ((x.checked && this.variableOperation == OperationDataTypeConst.UPDATE) || (this.variableOperation !== OperationDataTypeConst.UPDATE))).map(x => x.key)
            const { isType, isCatalogType, isTableType,  tablasData, hasLookUpByFieldId, getLookUpByFieldId, getDefaultValueFromField, getNewFiltersConditions, cleanLookUpDependency, cleanLookUp, lookUpFilterExp,getFieldFromKeyCol,getFieldKeyColumn,getFieldId } = DataSourceComp(this.container as any, {} as any, {} as any, {} as any, {} as any, {} as any, this.store, dsData)
            
            // lanzamos búsquedas
            if (this.variableOperation == OperationDataTypeConst.SEARCH){   
              fieldList = this.variableFields.filter(x=> (x.checked)).map(x => x.key)           
              dataSearch = dsData.iapDataSourceFields.filter(x => fieldList.includes(x.id)).map(x =>
                ({
                  //@ts-ignore:disable-next-line
                  id:HelperUtils.newGuid().toString().replaceAll('-',''),
                  fieldId: x.id,
                  filter: FiltroBusquedaConst.FILTROBUSQUEDA_IGUAL,
                  value: x.sqlType == SqlTypesConst.BIT  && typeof((this.variableFields.find(v => v.key == x.id)?.value ?? '')) == 'boolean' ? JSON.stringify(this.variableFields.find(v => v.key == x.id)?.value ?? '') : (this.variableFields.find(v => v.key == x.id)?.value ?? ''),
                  valueList: (x.catalogTypeId ? [x.filterValue] : null),
                  valueBool: null,
                  valueNumber: null,
                  valueDateTime: null,
                  rangeValue: null,
                  rangeNumber: null,
                  rangeDateTime: null
          
                }));
            }
            else // resto de operaciones
            {
              
              const dataInput = dsData.iapDataSourceFields.filter(x => fieldList.includes(x.id) && (x.primaryKey== false || (x.primaryKey && !x.identityColumn)))
              const pks = dsData.iapDataSourceFields.filter(x => x.primaryKey).map(x => ({
                fieldId: x.id,
                value: HelperCommon.isNullOrWhitespace((this.variableFields.find(v => v.key == x.id)?.value ?? '')) ?  getDefaultValueFromField(x): (this.variableFields.find(v => v.key == x.id)?.value ?? '')
              }))
  
           
              const dataInputRequest = JSON.parse(JSON.stringify(dataInput));
              dataInputRequest.forEach((x:any) =>{
                const vf = this.variableFields.find(v => v.key == x.id);
                if (vf){
                  vf.value = HelperCommon.isNullOrWhitespace(vf?.value ?? '') ?  getDefaultValueFromField(x): (vf?.value ?? '')
                  if (HelperCommon.isNullOrWhitespace(vf.value ?? '') && x.nullable)
                  {
                    vf.value = null;
                  }
                }
                
              })
  
              dataToUpdate.push({
                valores: 
                (this.variableOperation == OperationDataTypeConst.DELETE ? [] : 
                
                dataInputRequest.map( (x:any) => ({
                  fieldId: x.id,
                  value: x.sqlType == SqlTypesConst.BIT && typeof((this.variableFields.find(v => v.key == x.id)?.value ?? '')) == 'boolean' ? JSON.stringify(this.variableFields.find(v => v.key == x.id)?.value ?? '') : (this.variableFields.find(v => v.key == x.id)?.value ?? (x.nullable?null:''))
                
                }))) as any,
                claves: (this.variableOperation == OperationDataTypeConst.INSERT ? [] : pks) as any,
                tipoOperacion: this.variableOperation,
                id: HelperUtils.newGuid()
  
              } as dataUpdate) ;
            }

          }


          if (this.container) {

            if (this.variableOperation == OperationDataTypeConst.SEARCH)
            {
              // se lanzan las búsquedas
              
              const _srv = this.container.get<IServiceSearch>(TYPES.SEARCH_REPOSITORY);

              const searchData:GroupSearch={
                operatorLogic:OperatorLogicConst.AND,
                fields: JSON.parse(JSON.stringify(dataSearch)),
                children:[]
            }
              
            HelperLoading.showLoading();
            _srv.search(this.applicationId, this.applicationVersion, -1,
              searchData as any, [], 100,undefined, dsData.id ?? -1)
                .then((response) => {
                  
                    
                    const obj = JSON.parse(response?.items ?? '[]');
                    //@ts-ignore:disable-next-line  
                    this.controls.value['value'] = obj
                    forward("true");
                    
                })
                .catch(()=>{
                  forward("false");
                })
                .finally(() => {
                    HelperLoading.hideLoading();                    
                    


                });

            }
            else
            {
              // se lanzan el resto de operaciones
              const _srv = this.container.get<IServiceDataUpdate>(TYPES.DATAUPDATE_REPOSITORY)
              HelperLoading.showLoading()
              const requestData = JSON.parse(JSON.stringify(dataToUpdate))
              //applicationId:number, applicationVersion:number,componentId:number,data: dataUpdate[],transactionOperation:boolean,lookUp?:IapDataSourceLookUp, componentDataSourceId?: number, dataSourceId?: number
              const dataInputRequest:DataUpdateOperation={
                componentId:-1,
                componentDataSourceId:undefined,
                dataSourceId:dsData.id,
                parameters:this.variableCfgs.map(x=> ({key:x.key,value:x.value}))??[],
                data:requestData
            }
              _srv.update(this.applicationId, this.applicationVersion,  [dataInputRequest], false).then(response => {
                
                if (HelperDataUpdate.hasErrorResponse(response)) {
                  HelperDataUpdate.formatErrorResponse(response);
                  forward("false");
                }
                else {
                  const resultData = response.find(x => x != undefined)?.resultData ?? '{}'
                  if (resultData) {
                    //@ts-ignore:disable-next-line  
                    this.controls.value['value'] = JSON.parse(resultData)
                    forward("true");
                  }

                }
              })
              .finally(()=>{
                HelperLoading.hideLoading()
              })
            }
          }


        }

      })
    }
    // Operaciones sobre los Componentes
    else {
      //const currentComponentId = this.componentData.flatMap(cd => cd.iapComponentDataSources).find(cds => this.variableComponentDataSourceListId.includes(cds?.id))?.componentId ?? -1;
      const dsc = this.componentData.flatMap(cd => cd.iapComponentDataSources).filter(cds => this.variableComponentDataSourceListId.includes(cds?.id)).sort(x=> x.order)      
        //if (compDsData) {
          if (dsc.length > 0 && this.variableComponentDataSourceListId.length > 0){
            var promises = new Array();
            let canDo = false;

            switch(this.variableOperation) {
              case OperationDataTypeConst.REFRESH:
               
              //dsc.map(x=> x.componentId)
              dsc.map(item => item.componentId)
              .filter((value, index, self) => self.indexOf(value) === index).forEach(currentComponentId =>{
                promises.push(this.createPromiseInteractionRefresh(dsc.filter(x=> x.componentId == currentComponentId).map(x=> x.id), currentComponentId));
              })
                
               
                await Promise.all(promises).then((responses: InteractionEvent[]) => {
                  canDo = !(responses.filter(x => !x.interactionResult).length > 0)
                })
    
                if (canDo) {
                  forward("true");
                }
                else{
                  forward("false");
                }
                break;
                case OperationDataTypeConst.INSERT:
                case OperationDataTypeConst.UPDATE:
                case OperationDataTypeConst.DELETE:
                // code block
                dsc.map(item => item.componentId)
                .filter((value, index, self) => self.indexOf(value) === index).forEach(currentComponentId =>{
                  promises.push(this.createPromiseInteractionOperationMassive(dsc.filter(x=> x.componentId == currentComponentId).sort((a, b) => { return (a.order ?? 0) - (b.order ?? 0); })
                    ,this.variableOperation,this.componentData.find(x=> x.id == currentComponentId)?.expressions,
                    dsc.filter(x=> x.componentId == currentComponentId).length>1
                    ,currentComponentId));
                })

                
                await Promise.all(promises).then((responses: InteractionEvent[]) => {
                  canDo = !(responses.filter(x => !x.interactionResult).length > 0)
                })
    
                if (canDo) {
                  forward("true");
                }
                else{
                  forward("false");
                }
                break;
              default:
                forward("true");
            }
            if (this.variableOperation == OperationDataTypeConst.REFRESH) {
        
            }


          }
          else{
            forward("true");
          }
     
        //}
      //})
    }



  }

  createPromiseInteractionRefresh = async (componentDataSourceId: any, currentComponentId: number): Promise<InteractionEvent> => {

    return await new Promise<InteractionEvent>((resolve) => {
      let typeid = InteractionConst.CALL_FUNCTION;
      const objectname = 'resolvedatasource()';

      var data: Partial<InteractionEvent> = {
        objectId: componentDataSourceId,
        typeId: typeid,
        objectValue: '',//element.value,
        objectName: objectname
      }
      
      const keyComponentEventBus = this.formKey +  this.rootComponentId.toString() + currentComponentId.toString() + EventConst.INTERACTION;

      var doCallbackOk = (response: InteractionEvent) => {
        //console.log('respuesta:' + compId.toString())
        resolve(response);
      }
      
      EventBusCustom.emit(keyComponentEventBus, { data: data, callBackResponse: doCallbackOk });

    });


  };

  createPromiseInteractionOperationMassive = async (componentDataSources: any,operation: number,expressions:any,transactionOperation:boolean, currentComponentId: number): Promise<InteractionEvent> => {

    return await new Promise<InteractionEvent>((resolve) => {
      let typeid = InteractionConst.CALL_FUNCTION;
      const objectname = 'doOperationDataMassive()';

      var data: Partial<InteractionEvent> = {
        objectId: currentComponentId,
        typeId: typeid,
        objectValue: {
          cds:componentDataSources,
          operation:operation,
          expressions:expressions,
          trans:transactionOperation
        },
        objectName: objectname,

      }
      
      const keyComponentEventBus = this.formKey +  this.rootComponentId.toString() + currentComponentId.toString() + EventConst.INTERACTION;

      var doCallbackOk = (response: InteractionEvent) => {
        //console.log('respuesta:' + compId.toString())
        resolve(response);
      }
      
      EventBusCustom.emit(keyComponentEventBus, { data: data, callBackResponse: doCallbackOk });

    });


  };



  data(): { value: string, true: string,false: string} {
    return {
      //@ts-ignore:disable-next-line
      value: this.controls.value['value'] || "",
      true:'',
      false:''
    };
  }

}