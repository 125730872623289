<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
            
    <TabPanel v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(TabPanelTypeConst.ID)" v-tooltip="getPropertyValue(TabPanelTypeConst.TOOLTIP)"
        :style="getPropertyValue(TabPanelTypeConst.STYLE)" :class="getPropertyValue(TabPanelTypeConst.CLASS)"
        :name="getPropertyValue(TabPanelTypeConst.NAME)" :disabled="getPropertyBooleanValue(TabPanelTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(TabPanelTypeConst.VISIBLE)"
        :placeholder="getPropertyValue(TabPanelTypeConst.PLACEHOLDER)" :header="getPropertyValue(TabPanelTypeConst.HEADER)">

        <template #header>
            <slot name="header"></slot>
        </template>

        <template #default>
            <slot name="default">
                <FormBuilder v-if="componentId>0 "  :container="container" :componentId="componentId" :applicationId="Component.applicationId"
                :applicationVersion="Component.applicationVersion" :attrsInput="attrsInput" />     

            </slot>
        </template>

    </TabPanel>
</template>
<script lang="ts">


import { defineComponent, onUnmounted, onMounted, shallowRef,watchEffect } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import TabPanelTypeConst from '../../../../domain/Constants/TabPanelTypeConst';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';
import CatalogObjectTypeConst from '../../../../../catalog/domain/const/CatalogObjectTypeConst';
import EventConst from '../../../../../../../common/domain/constantes/EventConst';
import EventBusCustom from '../../../../../../../common/infrastructure/event-bus-custom';
import { IapComponentAttribute } from '../../../../../component/domain/iapComponentAttribute';


export default defineComponent({
    name: 'dynamic_tab_panel',
    components: {
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },

        slotProps: {
            type: Object,
            default: () => ({})
        },


    },
    setup(props) {
        //
        const keyEventComponent = 'currentabpnl_' + props.Component.formKey + (props.Component.rootParentId ?? -1).toString() + '_' + props.Component.id.toString() + '_' + EventConst.SHOWCOMPONENT;
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded,  baseOnUnmount
            ,fieldKey,fieldKeyComponentDataSourceId ,componentKey,processEvent,getPropertyId
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);


        const componentId = shallowRef(-1);
        const attrsInput = shallowRef<IapComponentAttribute[]>([]);
        let isLoaded = false;


        const doComponentEvent = (evt: { componentId: number, header: string, modal: boolean, attrs: IapComponentAttribute[], callBackResponse: any }) =>{
            componentId.value = evt.componentId;
            attrsInput.value  = evt.attrs;

                if (evt.callBackResponse) {
                    evt.callBackResponse(true)
                }
        }

        onMounted(()=>{
            
                const propsIdCmd = getPropertyId(TabPanelTypeConst.CMD_CONTENT) ?? '';
                const hasWf = props.Component?.workFlows?.find(x => x.idObjeto == CatalogObjectTypeConst.ATTRCOMP && x.objetoId == propsIdCmd && x.active == true)
                if (propsIdCmd && hasWf) { 
                    // esto es para mostrar los detalles
                    EventBusCustom.on(keyEventComponent,doComponentEvent);
                    
                    watchEffect(() => {
                    if (loaded.value == true && !isLoaded) {
                        isLoaded = true;
                        processEvent(CatalogObjectTypeConst.ATTRCOMP, propsIdCmd, props.Component,keyEventComponent);
                    }
                })
                   
                }
        })

        onUnmounted(() => {
            EventBusCustom.off(keyEventComponent,doComponentEvent);   
            attrsInput.value.length = 0;              
            baseOnUnmount();
        })

        return {
          
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            TabPanelTypeConst,
            loaded,
            BaseControlTypeConst,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
            ,componentId
            ,attrsInput
        };
    },
});
</script>
<style scoped></style>
