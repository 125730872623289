<template>
    <canvas ref="c" id="c" style="position: absolute;"></canvas>
    <Login :container=container :routes=router v-model:isResetPass="isResetPass" @isForgetPassChild="updateIsResetPass"></Login>
</template>

<script lang="ts" type="module">
/* eslint-disable @typescript-eslint/no-unused-vars */
import { defineComponent, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex'
import routes from '../../../router'
import { container } from '../../container'
import { useRouter, useRoute } from 'vue-router';
import  Login from '@ilinium/shared/src/entidades/builderMaster/auth/infrastructure/component/Login1.vue'
import Environment from '@ilinium/shared/src/common/infrastructure/funciones/environment';
import { Actions } from '@ilinium/shared/src/common/infrastructure/almacen/enums/StoreEnums';


export default defineComponent({
    components: { Login },
    name: 'login',
    setup(props) {
        const router = routes;
        const route = useRoute();
        const store = useStore()
        const isResetPass = ref('false') as any;
        isResetPass.value = router.currentRoute.value.query.isResetPass;
        let isLoading = ref<boolean>(false);

        const updateIsResetPass = (event: any): void => {
			isResetPass.value = event;
		};

        return {
            container,
            router,
            isResetPass,
            updateIsResetPass,
        };
    },

    mounted() {
        if (Environment.APPID == 1){

        
        var c = <HTMLCanvasElement>document.getElementById("c");
        if (c) {
            var ctx = c.getContext("2d");

            //making the canvas full screen
            c.height = window.innerHeight;
            c.width = window.innerWidth;

            //chinese characters - taken from the unicode charset
            var matrix = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789@#$%^&*()*&^%+-/~{[|`]}";
            //converting the string into an array of single characters
            var matrix2 = matrix.split("");

            var font_size = 12;
            var columns = c.width/font_size; //number of columns for the rain
            //an array of drops - one per column
            var drops = [columns];
            //x below is the x coordinate
            //1 = y co-ordinate of the drop(same for every drop initially)
            for(var x = 0; x < columns; x++)
                drops[x] = 1; 

            //drawing the characters
            function draw()
            {
                //Black BG for the canvas
                //translucent BG to show trail
                if (ctx) {
                    ctx.fillStyle = "rgba(0, 0, 0, 0.04)";
                    ctx.fillRect(0, 0, c.width, c.height);

                    ctx.fillStyle = '#0883C3';// Environment.APPAPPCOLOR1;//green text
                    ctx.font = font_size + "px arial";
                    //looping over drops
                    for(var i = 0; i < drops.length; i++)
                    {
                        //a random chinese character to print
                        var text = matrix2[Math.floor(Math.random()*matrix2.length)];
                        //x = i*font_size, y = value of drops[i]*font_size
                        ctx.fillText(text, i*font_size, drops[i]*font_size);

                        //sending the drop back to the top randomly after it has crossed the screen
                        //adding a randomness to the reset to make the drops scattered on the Y axis
                        if(drops[i]*font_size > c.height && Math.random() > 0.975)
                            drops[i] = 0;

                        //incrementing Y coordinate
                        drops[i]++;
                    }
                }
            }

            setInterval(draw, 35);
        }
    }
}
});
</script>

<style lang="scss" scoped>
            *{
                margin: 0;
                padding: 0;
            }
            
            body {background: black;}
            canvas {display:block;}
</style>
