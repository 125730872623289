<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <div :class="{ 'p-inputgroup flex-1': hasGroupControls }" :key="componentKey">
        <InputText v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"             
            :id="getPropertyValue(InputTextTypeConst.ID)" v-tooltip="getPropertyValue(InputTextTypeConst.TOOLTIP)"
            :style="getPropertyValue(InputTextTypeConst.STYLE)"
            :class="{ [getPropertyValue(InputTextTypeConst.CLASS) ?? '']: true, 'p-invalid': v$.vmodel.$error && submitted,'p-readonly': getPropertyBooleanValue(InputTextTypeConst.READONLY)}"
            :name="getPropertyValue(InputTextTypeConst.NAME)"
            :disabled="getPropertyBooleanValue(InputTextTypeConst.DISABLED) || (!canEdit)"
            :visible="getPropertyBooleanValue(InputTextTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
            :placeholder="getPropertyValue(InputTextTypeConst.PLACEHOLDER)"
            :readonly="getPropertyBooleanValue(InputTextTypeConst.READONLY) || !canDoOperation(ObjectGroupConst.EDIT)"
            :type="getPropertyValue(InputTextTypeConst.TYPE)" v-model="vmodel" >
        </InputText>
        <span v-if="hasGroupControls" :class="{ 'p-inputgroup-addon': hasGroupControls }">
            <Mic v-if="getPropertyBooleanValue(InputTextTypeConst.MIC)" v-model="vmodel"></Mic>
            <i v-if="getPropertyBooleanValue(InputTextTypeConst.COPYTOCLIPBOARDB)" class="ml-1 pi pi-copy"
                style="color: rgb(0, 128, 128)" @click="HelperCommon.copyToClipBoard(vmodel)" v-tooltip="'Copiar al portapapeles'"></i>
            <i v-if="getPropertyBooleanValue(InputTextTypeConst.CHAT) && hasUbmChat"  class="ml-1 pi pi-whatsapp"
                style="color: rgb(37,211,102)" @click="sendUbmChat()"
                v-tooltip="'Chat'"></i>
            <i v-if="getPropertyBooleanValue(InputTextTypeConst.EMAIL)" class="ml-1 pi pi-envelope"
            v-tooltip="'Enviar correo'"
                 @click="sendMail()"></i>
        </span>
    </div>
    <CustomValidate v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" v-model:submitted="submitted" v-model:vObject="v$" />
</template>
<script lang="ts">


import { defineComponent, onMounted, onBeforeUnmount, onUnmounted, computed } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import InputTextTypeConst from '../../../../domain/Constants/InputTextTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import CustomValidate from '../../shared/CustomValidate.vue';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';
import Mic from '../../../../../../../common/infrastructure/componentes/base/common/mic/Mic.vue';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import CatalogCfgUbmChatConst from '../../../../../catalog/domain/const/CatalogCfgUbmChatConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_input-text',
    components: {
        CustomValidate,
        Mic,
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },       
        slotProps: {
            type: Object,
            default: () => ({})
        },    
    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded, resolveExpressions, baseOnMounted, baseOnBeforeUnmount, baseOnUnmount, v$, submitted, canEdit,isValidData
            ,fieldKey,fieldKeyComponentDataSourceId,sendUbmChat ,sendMail
            ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);

    

        const hasUbmChat = computed(()=>{
        return store.getters.entityMainCfgValue(CatalogCfgUbmChatConst.CFGUBMCHAT_ENABLE)?.toLowerCase().trim() == 'true'
       })

        const hasGroupControls = computed(() => {
            return getPropertyBooleanValue(InputTextTypeConst.MIC) || getPropertyBooleanValue(InputTextTypeConst.COPYTOCLIPBOARDB)
            || (getPropertyBooleanValue(InputTextTypeConst.CHAT) && hasUbmChat.value) || getPropertyBooleanValue(InputTextTypeConst.EMAIL)
        })


        onMounted(() => {
            baseOnMounted();
        })

        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })

        onUnmounted(() => {
            baseOnUnmount();
        })

        return {

            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            InputTextTypeConst,
            loaded,
            BaseControlTypeConst,
            v$,
            submitted,
            canEdit,
            hasGroupControls,
            HelperCommon,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,hasUbmChat
            ,sendUbmChat
            ,sendMail
            ,componentKey

        };
    },
});
</script>
<style scoped></style>
