import BaseControlTypeConst from "./BaseControlTypeConst"

export default class TabViewTypeConst extends BaseControlTypeConst {

  static ACTIVEINDEX = 'activeIndex'
  static LAZY = 'lazy'
  static SCROLLABLE = 'scrollable'
  static TABINDEX = 'tabindex'
  static SELECTONFOCUS = 'selectOnFocus'
  static ISDYNAMIC = 'isDynamic'

}
