


const decompressFile = async (fileName: string, content: ArrayBuffer): Promise<string> => {
    return await new Promise<string>((resolve) => {
        var JSZip = require("jszip");
        const newzip = new JSZip();

        newzip.loadAsync(content)
            .then(function (zip: any) {
                // you now have every files contained in the loaded zip
                zip.file(fileName).async("string").then( (response: string) => {
                    resolve(response)
                }); // a promise of "Hello World\n"
            });

    })

}


export default { decompressFile }
