import BaseControlTypeConst from "./BaseControlTypeConst"

export default class LookUpEditorTypeConst extends BaseControlTypeConst {
  
    static LAYOUT_DETAIL ='layouttemp-lookupeditor-detail'
    static LAYOUT_ADDONTHEFLY ='layouttemp-lookupeditor-addonthefly'
    static LAYOUT_DIALOGHEAD='layouttemp-lookupeditor-dialoghead'
    static DIALOGWIDTH = 'dialogWidth'
    static CMDVIEW = 'commandView'
    static CMDSAVE = 'commandSave'
    static SHOWSEARCH = 'showSearch'
    static SHOWCLEAR = 'showClear'

  }
  