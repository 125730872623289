import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import store from '../almacen';
import HelperException from './HelperException';
import HelperUtils from './HelperUtils';

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  'Access-Control-Allow-Credentials': false,
  'X-Requested-With': 'XMLHttpRequest'
}


// We can use the following function to inject the JWT token through an interceptor
// We get the `accessToken` from the localStorage that we set when we authenticate
 const injectToken = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  try {
    //const token = localStorage.getItem('accessToken')
    const token = store.getters.getAcessToken;

    if (token != null && config != null && config.headers != null) {      
      const computerName = store.getters.getComputerName ?? '';
      const profileId = store.getters.getCurrentProfile ?? '';
  
      config.headers["ComputerName"] = computerName;
      config.headers["ProfileId"] = profileId;
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  } catch (error: any) {
    throw new Error(error)
  }
} 

class Http {
  private instance: AxiosInstance | null = null

  private url: string = ''
  /* constructor (_url: string) {
    this.url = _url
  } */

  private get http (): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp(this.url)
  }

  initHttp (_url: string): AxiosInstance {    
    this.url = _url
    const http = axios.create({
      baseURL: _url,
      headers,
      withCredentials: false
    })

     http.interceptors.request.use(injectToken,async (error) => await Promise.reject(error))
     http.interceptors.request.use(
      (config) => {
          return config;
      },
      (error) => {
          HelperException.logExceptionApiService(error);
          return Promise.reject(error);
      }
    );

    http.interceptors.response.use(
        (response) => {
            
            HelperException.logExceptionApiServiceData(response);
            HelperUtils.handleDates(response as any);
            return response;
        },
        (error) => {
            HelperException.logExceptionApiService(error);
            return Promise.reject(error);
        }
    );
    
    http.interceptors.response.use(
      (response) => response,
      async (error) => {
        const { response } = error
        return await this.handleError(response)
      }
    ) 
    
    this.instance = http
    return http
  }



  async request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return await this.http.request(config)
  }

  async get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return await this.http.get<T, R>(url, config)
  }

  async post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {

    return await this.http.post<T, R>(url, data, { httpsAgent: config })
  }

  async put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return await this.http.put<T, R>(url, data, config)
  }

  async delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return await this.http.delete<T, R>(url, config)
  }

  // Handle global app errors
  // We can handle generic app errors depending on the status code
  private async handleError (error: { status: any }): Promise<any> {
    const { status } = error

    switch (status) {
      case StatusCode.InternalServerError: {
        // Handle InternalServerError
        break
      }
      case StatusCode.Forbidden: {
        // Handle Forbidden
        break
      }
      case StatusCode.Unauthorized: {
        // Handle Unauthorized
        break
      }
      case StatusCode.TooManyRequests: {
        // Handle TooManyRequests
        break
      }
    }

    return await Promise.reject(error)
  }
}

export const http = new Http()
