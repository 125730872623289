import BaseControlTypeConst from "./BaseControlTypeConst"

export default class IframeTypeConst extends BaseControlTypeConst {
    
    static SRC='src'
    static WIDTH='width'
    static HEIGHT='height'
    static FRAMEBORDER='frameborder'
    static SCROLLING='scrolling'
    static ALLOWFULLSCREEN='allowfullscreen'
    static ENCRYPT ='encryptSrc'

    static ENCRYPT_PAR ='encryptParameters'
    static ENCRYPT_VAR ='encryptVariable'


    
    

}
