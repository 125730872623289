import gql from 'graphql-tag';

export const GET_ALL_APPLICATIONCONNECTION = gql(`
query($maxregs: Int) {
    applicationQuery {
      getAll(maxregs:$maxregs) {
        id,
        version,
        name,
        active,
        logoPng,
        description,
        fcr,
        ucr,
        uum,
        fum
      }
    }
}
`);

export const GET_APPLICATIONCONNECTION_BY_ID = gql(`
query($id: Int, $version: Int) {
    applicationQuery {
      getById(id:$id, version:$version) {
        id,
        version,
        name,
        active,
        logoPng,
        description,
        fcr,
        ucr,
        uum,
        fum
      }
    }
}
`);

