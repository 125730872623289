import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_CustomValidate = _resolveComponent("CustomValidate")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.getPropertyBooleanValue(_ctx.CalendarTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.CalendarTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? _withDirectives((_openBlock(), _createBlock(_component_Calendar, {
          ref: "calendarComponentRef",
          key: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.CalendarTypeConst.ID),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.CalendarTypeConst.STYLE)),
          class: _normalizeClass({ [_ctx.getPropertyValue(_ctx.CalendarTypeConst.CLASS) ?? '']: true, 'p-invalid': _ctx.v$.vmodel.$error && _ctx.submitted, 'p-readonly': _ctx.getPropertyBooleanValue(_ctx.CalendarTypeConst.READONLY) }),
          name: _ctx.getPropertyValue(_ctx.CalendarTypeConst.NAME),
          disabled: _ctx.getPropertyBooleanValue(_ctx.CalendarTypeConst.DISABLED) || (!_ctx.canEdit),
          visible: _ctx.getPropertyBooleanValue(_ctx.CalendarTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW),
          placeholder: _ctx.getPropertyValue(_ctx.CalendarTypeConst.PLACEHOLDER),
          showOtherMonths: _ctx.getPropertyBooleanValue(_ctx.CalendarTypeConst.SHOWOTHERMONTHS),
          selectOtherMonths: _ctx.getPropertyBooleanValue(_ctx.CalendarTypeConst.SELECTOTHERMONTHS),
          showButtonBar: _ctx.getPropertyBooleanValue(_ctx.CalendarTypeConst.SHOWBUTTONBAR),
          showTime: _ctx.getPropertyBooleanValue(_ctx.CalendarTypeConst.SHOWTIME),
          showSeconds: _ctx.getPropertyBooleanValue(_ctx.CalendarTypeConst.SHOWSECONDS),
          manualInput: _ctx.getPropertyBooleanValue(_ctx.CalendarTypeConst.MANUALINPUT),
          inline: _ctx.getPropertyBooleanValue(_ctx.CalendarTypeConst.INLINE),
          showIcon: _ctx.getPropertyBooleanValue(_ctx.CalendarTypeConst.SHOWICON),
          touchUI: _ctx.getPropertyBooleanValue(_ctx.CalendarTypeConst.TOUCHUI),
          minDate: _ctx.getPropertyValue(_ctx.CalendarTypeConst.MINDATE),
          maxDate: _ctx.getPropertyValue(_ctx.CalendarTypeConst.MAXDATE),
          readonly: _ctx.getPropertyBooleanValue(_ctx.CalendarTypeConst.READONLY) || !_ctx.canDoOperation(_ctx.ObjectGroupConst.EDIT),
          numberOfMonths: _ctx.getPropertyNumberValue(_ctx.CalendarTypeConst.NUMBEROFMONTHS),
          view: _ctx.getCatalogValue(_ctx.CalendarTypeConst.VIEW),
          selectionMode: _ctx.getCatalogValue(_ctx.CalendarTypeConst.SELECTIONMODE),
          dateFormat: _ctx.getPropertyValue(_ctx.CalendarTypeConst.DATEFORMAT),
          modelValue: _ctx.vmodel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vmodel) = $event))
        }, null, 8 /* PROPS */, ["id", "style", "class", "name", "disabled", "visible", "placeholder", "showOtherMonths", "selectOtherMonths", "showButtonBar", "showTime", "showSeconds", "manualInput", "inline", "showIcon", "touchUI", "minDate", "maxDate", "readonly", "numberOfMonths", "view", "selectionMode", "dateFormat", "modelValue"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.CalendarTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.CalendarTypeConst.VISIBLE))
      ? (_openBlock(), _createBlock(_component_CustomValidate, {
          key: 2,
          submitted: _ctx.submitted,
          "onUpdate:submitted": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.submitted) = $event)),
          vObject: _ctx.v$,
          "onUpdate:vObject": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$) = $event))
        }, null, 8 /* PROPS */, ["submitted", "vObject"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}