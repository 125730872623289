import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "row"
}
const _hoisted_2 = { class: "col-md-12" }
const _hoisted_3 = {
  style: {"color":"red","padding-left":"5px"},
  for: "checkbox"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_TriStateCheckBox = _resolveComponent("TriStateCheckBox")!
  const _component_CustomValidate = _resolveComponent("CustomValidate")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.getPropertyBooleanValue(_ctx.TriStateCheckBoxTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && (_ctx.getPropertyBooleanValue(_ctx.TriStateCheckBoxTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW)))
      ? _withDirectives((_openBlock(), _createBlock(_component_TriStateCheckBox, {
          key: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.TriStateCheckBoxTypeConst.ID),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.TriStateCheckBoxTypeConst.STYLE)),
          class: _normalizeClass({ [_ctx.getPropertyValue(_ctx.TriStateCheckBoxTypeConst.CLASS) ?? '']: true, 'p-invalid': _ctx.v$.vmodel.$error && _ctx.submitted ,'p-readonly': _ctx.getPropertyBooleanValue(_ctx.TriStateCheckBoxTypeConst.READONLY)}),
          name: _ctx.getPropertyValue(_ctx.TriStateCheckBoxTypeConst.NAME),
          disabled: _ctx.getPropertyBooleanValue(_ctx.TriStateCheckBoxTypeConst.DISABLED) || (!_ctx.canEdit),
          visible: _ctx.getPropertyBooleanValue(_ctx.TriStateCheckBoxTypeConst.VISIBLE),
          placeholder: _ctx.getPropertyValue(_ctx.TriStateCheckBoxTypeConst.PLACEHOLDER),
          binary: _ctx.getPropertyBooleanValue(_ctx.TriStateCheckBoxTypeConst.BINARY),
          readonly: _ctx.getPropertyBooleanValue(_ctx.TriStateCheckBoxTypeConst.READONLY) || !_ctx.canDoOperation(_ctx.ObjectGroupConst.EDIT),
          required: _ctx.getPropertyBooleanValue(_ctx.TriStateCheckBoxTypeConst.REQUIRED),
          tabIndex: _ctx.getPropertyNumberValue(_ctx.TriStateCheckBoxTypeConst.TABINDEX),
          modelValue: _ctx.vmodel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vmodel) = $event)),
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.processEventComponent(_ctx.CatalogEventConst.CHANGE)))
        }, null, 8 /* PROPS */, ["id", "style", "class", "name", "disabled", "visible", "placeholder", "binary", "readonly", "required", "tabIndex", "modelValue"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.TriStateCheckBoxTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.vmodel == null ? 'null' : _ctx.vmodel), 1 /* TEXT */)
          ])
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.TriStateCheckBoxTypeConst.VISIBLE))
      ? (_openBlock(), _createBlock(_component_CustomValidate, {
          key: 3,
          submitted: _ctx.submitted,
          "onUpdate:submitted": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.submitted) = $event)),
          vObject: _ctx.v$,
          "onUpdate:vObject": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.v$) = $event))
        }, null, 8 /* PROPS */, ["submitted", "vObject"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}