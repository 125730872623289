import { MessageService } from '@ilinium/shared/src/common/infrastructure/servicios';
import { IapComponent } from '../../../component/domain/iapComponent';
import ComponentRenderAux from '../ComponentRenderAux'
import { MessageType } from '@ilinium/shared/src/common/infrastructure/servicios/MessageService';
import PrefixControlConst from '../Constants/PrefixControlConst';
import ControlTypeConst from '../Constants/ControlTypeConst';
import CatalogTypeConst from '@ilinium/shared/src/entidades/builder/catalog/domain/const/CatalogTypeConst';
import HelperCommon from '@ilinium/shared/src/common/infrastructure/funciones/HelperCommon';
import CrudTableTypeConst from '../Constants/CrudTableTypeConst';

export default function ComponentRender(store:any) {

  const { slotData,
    controlContent,
    getValueName,getVueFileImports,getVueFileCode,crudTablCond,specialControlContent } = ComponentRenderAux(store); 

  const getComponentCode = (component: IapComponent,index:number, close: boolean = false, prefix: string[] = [],isChildTempData:boolean=false,tabPanelProps?: { tabIndex: number, TabViewParentId: number }): string => {
    const template = isTemplate(component);
    const controlName = !template ? getControlDynamicName(component) : undefined;

    if (close) {
      return template ? `</template>` : `</${controlName}>`;
    }

    if (template) {
      const slotName = getSlotName(component);
      return `<template ${getSlotConditions(component)} v-slot:${slotData.includes(component.idType) ? slotName + '="data"' : slotName}>`;
    }
    
     return `<${controlName} ${getIfCondition(component,index,tabPanelProps)}  ${getPrefix(prefix,false)}container="container" ${getPrefix(prefix,true)}Component="comps[${index}]" ${getPrefix(prefix,true)}slotProps="${isChildTempData?'data':'slotProps'}" ${getCustomProps(component,prefix)}>`;
  }

  const getIfCondition=(component:any,index:number,tabPanelProps?: { tabIndex: number,TabViewParentId: number })=>{

      if(tabPanelProps){
        return `v-if="${tabPanelProps?.tabIndex}==getPropertyNumberValue(TabViewTypeConst.ACTIVEINDEX,  comps.find(c=>c.id==${tabPanelProps?.TabViewParentId}))"`
      }
      if(component.idType == ControlTypeConst.CTTABPANEL){
        return `v-if="isVisibleTabPanel(comps[${index}])"`;
      }
      return ``;
  }

  const getPrefix=(prefix: string[] = [],vModelProp:boolean)=>{
        if(vModelProp){
          return `${prefix.includes(PrefixControlConst.PREFIX_V_MODEL) ? PrefixControlConst.PREFIX_V_MODEL : ""}${prefix.includes(PrefixControlConst.PREFIX_PROP) ? PrefixControlConst.PREFIX_PROP : ""}`;
        }
        return `${prefix.includes(PrefixControlConst.PREFIX_PROP) ? PrefixControlConst.PREFIX_PROP : ""}`;
  }

  const getCustomProps=(component:IapComponent,prefix:string[]=[]):string=>{
        if(component.idType==ControlTypeConst.CTLOOKUPEDITOR ){
          return ` ${getPrefix(prefix,false)}lookUpComps="lookUpComps"  ${getPrefix(prefix,false)}hasDetail="getLayoutComponent( comps.find(c=>c.id==${component.id}), LookUpEditorTypeConst.LAYOUT_DETAIL) != undefined"`
        }
        else if(component.idType==ControlTypeConst.CTCRUD ){
          return ` ${getPrefix(prefix,false)}customAddNew="getLayoutComponent( comps.find(c=>c.id==${component.id}), CrudTableTypeConst.LAYOUT_ADDNEW) != undefined"`
        }
        return '';
  }

  const getSlotConditions = (component:IapComponent) => {
      if(component.idType==ControlTypeConst.CTCRUD && crudTablCond.includes(component.idType)){
        return ` v-if="!getPropertyBooleanValue(${CrudTableTypeConst.SHOWTABMODE}, comps.find(c=>c.id==${component.id}))"`;
      }
      return '';

  };

  const getControlDynamicName = (component: IapComponent): string | void => {
    const ctrlName = getValueName(CatalogTypeConst.TIPOCONTROL,component.idType);
    if ( ctrlName && !HelperCommon.isNullOrWhitespace(ctrlName)) {
      return ctrlName;
    }
    else {
      MessageService.showMessage(MessageType.Error, 'Error', `Conversión componente id:${component.id} no encontrado`);
    }
  }

  const getSlotName = (component: IapComponent): string | void => {
    const slotName =  getValueName(CatalogTypeConst.LAYOUTTEMP,component.idType);
    if (slotName && !HelperCommon.isNullOrWhitespace(slotName)) {
      return slotName;
    }
    else {
      MessageService.showMessage(MessageType.Error, 'Error', `Conversión slot id:${component.id} no encontrado`);
    }
  }

  const isTemplate = (comp: IapComponent) => {
    const tmp = comp.idType.split('-');
    return tmp.length > 0 ? (tmp[0].toLocaleLowerCase() == CatalogTypeConst.LAYOUTTEMP) : false;
  }

  const printCode = (component: IapComponent,index:number, close: boolean = false, prefix: string[] = [],isChildTempData:boolean=false,tabPanelProps?: { tabIndex: number, TabViewParentId: number }): string => {
    return getComponentCode(component,index, close,prefix,isChildTempData,tabPanelProps);
  }

  const printSlotContentCode = (component: IapComponent, close: boolean,forceContentSlot:boolean=false): string => {
    
    if (controlContent.includes(component?.idType) || forceContentSlot) {
      return !close ? `<template v-slot:content>` : `</template>`
    }
    return '';
  }

  //En el id hay que pasar inicialmente el del padre absoluto
  const printDynamicCode = (id: number, comps: IapComponent[],result: string, prefix: string[] = [],isChildTempData:boolean=false,tabPanelProps?: { tabIndex: number, TabViewParentId: number }): string => {

    const comp = comps.find(c => c.id === id);
    const index = comps.findIndex(c => c.id === id);
    if (comp) {
      //etiqueta inicio        
      result += printCode(comp, index,false, prefix,isChildTempData,tabPanelProps);


      const childs = comps.filter(x => x.parentId === comp.id);

      if (childs.length > 0) {
        result += printSlotContentCode(comp, false);
        childs.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
        result = getChildCode(childs, comp, comps, result, prefix, isChildTempData);
        result += printSlotContentCode(comp, true);
      }
      //etiqueta de cierre
      result += printCode(comp,index, true, prefix);
    }
    return result;
  }

  const getChildCode = (compsChilds: IapComponent[], compParent: IapComponent, compsAll: IapComponent[], result: string, prefix: string[] = [], isChildTempData: boolean = false) => {

    if (specialControlContent.includes(compParent.idType)) {
      const tempChild = compsChilds.filter(c => isTemplate(c));
      const notTempChild = compsChilds.filter(c => !isTemplate(c));
      tempChild.forEach(x => {
        result = printDynamicCode(x.id, compsAll, result, prefix, (slotData.includes(compParent.idType) || isChildTempData));
      });
      result += printSlotContentCode({} as IapComponent, false, true);
      notTempChild.forEach(x => {
        result = printDynamicCode(x.id, compsAll, result, prefix, (slotData.includes(compParent.idType) || isChildTempData));
      });
      result += printSlotContentCode({} as IapComponent, true, true);

    }
    else if(compParent.idType==ControlTypeConst.CTTABPANEL){
      const tempChild = compsChilds.filter(c => isTemplate(c));
      const notTempChild = compsChilds.filter(c => !isTemplate(c));
      tempChild.forEach(x => {
        result = printDynamicCode(x.id, compsAll, result, prefix, (slotData.includes(compParent.idType) || isChildTempData));
      });

      const allTabs=compsAll.filter(c=>c.parentId==compParent.parentId).sort((a, b) => (a.order ?? 0) - (b.order ?? 0)); //.sort((a, b) => (a.id ?? 0) - (b.id ?? 0))

      notTempChild.forEach(x => {
        result = printDynamicCode(x.id, compsAll, result, prefix, (slotData.includes(compParent.idType) || isChildTempData),{ tabIndex: allTabs.findIndex(x=>x.id==compParent.id), TabViewParentId: compParent.parentId??-1 });
      });

    }
    else {
      compsChilds.forEach(x => {
        result = printDynamicCode(x.id, compsAll, result, prefix, (slotData.includes(compParent.idType) || isChildTempData));
      });
    }
    return result;
  };

  const generateVueFile=(id: number, comps: IapComponent[],result: string, prefix: string[] = []):string=>{
    return `<template>${printDynamicCode(id,comps,result,prefix)}</template>
            ${getVueFileImports(comps)}
            ${getVueFileCode(comps.find(c=>c.parentId==null)?.id??0,comps)}
     `;
}



  return {
    printDynamicCode,
    generateVueFile
  };

}
