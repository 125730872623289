import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IFieldConfigUserRepository } from '../domain/IFieldConfigUserRepository';
import { IServiceFieldConfigUser } from './IServiceFieldConfigUser';
import { IapmFieldConfigUser } from '../domain/iapmFieldConfigUser';



@injectable()
export class FieldConfigUserService implements IServiceFieldConfigUser {
  private readonly repo: IFieldConfigUserRepository

  public constructor (@inject(TYPES.FIELD_CONF_USR_REPOSITORY) _repo: IFieldConfigUserRepository) {
    this.repo = _repo
  }

  async update (data: IapmFieldConfigUser): (Promise<boolean>) {
    return await this.repo.update(data);
  }

  async add (data: IapmFieldConfigUser): (Promise<boolean>) {
    return await this.repo.add(data);
  }

  async getAll(maxregs: number | undefined) :Promise<IapmFieldConfigUser[]> {
    return await this.repo.getAll(maxregs)
  }

  async getById(id: number) :Promise<IapmFieldConfigUser> {
    return await this.repo.getById(id)
  }
}
