import BaseControlTypeConst from "./BaseControlTypeConst"

export default class FullCalendarTypeConst extends BaseControlTypeConst {
 
    //static FORMATS = 'formats'
    //static EDITORSTYLE = 'editorStyle'  
    
    //static READONLY = 'readonly'
    static FIELDS_DEF = 'fieldsDefinition'
    static DS_MAP = 'dataSourceMapping'
    static DV='defaultView'

    static CMD_EDIT = 'commandEdit'
    static CMD_ADD = 'commandAdd'

}
