import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';

import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import { IUserOptionRepository } from '../domain/IUserOptionRepository';


import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { IapmUserOption } from '../domain/iapmUserOption';
import { GETUSEROPTION, GETUSEROPTIONS, GETCURRENTUSEROPTIONS, GETFILTEREDUSEROPTIONS } from './query/UserOptionQuery';
import { SETUSEROPTION, SETUSEROPTIONDATA, SETUSEROPTIONSDATA, DELETEUSEROPTIONDATA, DELETEUSEROPTION, DELETEUSEROPTIONSDATA, RESETUSEROPTIONSDATA } from './mutation/UserOptionMutation';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class UserOptionApolloClientRepository implements IUserOptionRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }
     
  async getUserOption (applicationId:number, applicationVersion:number, userId:number, optionId: string): (Promise<IapmUserOption>) {
    return new Promise<IapmUserOption>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GETUSEROPTION, { applicationId:applicationId, applicationVersion:applicationVersion, userId:userId, optionId:optionId },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.userOptionQuery.getUserOption)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getUserOptions (applicationId:number, applicationVersion:number, userId:number): (Promise<IapmUserOption[]>) {
    return new Promise<IapmUserOption[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GETUSEROPTIONS, { applicationId:applicationId, applicationVersion:applicationVersion, userId:userId },this.clientId)
         
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.userOptionQuery.getUserOptions)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getCurrentUserOptions (): (Promise<IapmUserOption[]>) {
    return new Promise<IapmUserOption[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GETCURRENTUSEROPTIONS, { },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.userOptionQuery.getCurrentUserOptions)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getFilteredUserOptions (applicationId:number, applicationVersion:number, userId:number, filter:string): (Promise<IapmUserOption[]>) {
    return new Promise<IapmUserOption[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GETFILTEREDUSEROPTIONS, { applicationId:applicationId, applicationVersion:applicationVersion, userId:userId, filter:filter },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.userOptionQuery.getFilteredUserOptions)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  // SET
  // ------------------------------------------------------------------------------------------------------------------------------------------------
  async setUserOption (applicationId:number, applicationVersion:number, userId:number, optionId:string, value:string): (Promise<IapmUserOption>) {
    return new Promise<IapmUserOption>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(SETUSEROPTION,{applicationId:applicationId, applicationVersion:applicationVersion, userId:userId, optionId:optionId, value:value}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.userOptionMutation.setUserOption)
        }
        catch(e) {
          reject()
        }
      });

    });
  }  

  async setUserOptionData (applicationId:number, applicationVersion:number, dataInput: IapmUserOption): (Promise<IapmUserOption>) {
    return new Promise<IapmUserOption>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(SETUSEROPTIONDATA,{applicationId:applicationId, applicationVersion:applicationVersion, data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.userOptionMutation.setUserOptionData)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async setUserOptionsData (applicationId:number, applicationVersion:number, dataInput: IapmUserOption[]): (Promise<IapmUserOption[]>) {
    return new Promise<IapmUserOption[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(SETUSEROPTIONSDATA,{applicationId:applicationId, applicationVersion:applicationVersion, data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.userOptionMutation.setUserOptionsData)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  // DELETE
  // ------------------------------------------------------------------------------------------------------------------------------------------------
  async deleteUserOptionData (applicationId:number, applicationVersion:number, dataInput: IapmUserOption): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
     
        const result = this.apolloClient.executeQuery(DELETEUSEROPTIONDATA,{applicationId:applicationId, applicationVersion:applicationVersion, data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.searchConfigMutation.deleteUserOptionData)
        }
        catch(e) {
          reject()
        }
      });
    
    });
  }

  async deleteUserOption (applicationId:number, applicationVersion:number, userId:number, optionId:string): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
     
        const result = this.apolloClient.executeQuery(DELETEUSEROPTION,{applicationId:applicationId, applicationVersion:applicationVersion, userId:userId, optionId:optionId}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.searchConfigMutation.deleteUserOption)
        }
        catch(e) {
          reject()
        }
      });
    
    });
  }

  async deleteUserOptionsData (applicationId:number, applicationVersion:number, dataInput: IapmUserOption[]): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
     
        const result = this.apolloClient.executeQuery(DELETEUSEROPTIONSDATA,{applicationId:applicationId, applicationVersion:applicationVersion, data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.searchConfigMutation.deleteUserOptionsData)
        }
        catch(e) {
          reject()
        }
      });
    
    });
  }

  // RESET
  // ------------------------------------------------------------------------------------------------------------------------------------------------
  async resetUserOptionsData (applicationId:number, applicationVersion:number, dataInput: IapmUserOption[]): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
     
        const result = this.apolloClient.executeQuery(RESETUSEROPTIONSDATA,{applicationId:applicationId, applicationVersion:applicationVersion, data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.searchConfigMutation.resetUserOptionsData)
        }
        catch(e) {
          reject()
        }
      });
    
    });
  }


}


