import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withCtx as _withCtx, createSlots as _createSlots, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_Menu = _resolveComponent("Menu")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.MenuTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? _withDirectives((_openBlock(), _createBlock(_component_Menu, {
          key: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.MenuTypeConst.ID),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.MenuTypeConst.STYLE)),
          class: _normalizeClass({ [_ctx.getPropertyValue(_ctx.MenuTypeConst.CLASS) ?? '']: true }),
          name: _ctx.getPropertyValue(_ctx.MenuTypeConst.NAME),
          disabled: _ctx.getPropertyBooleanValue(_ctx.MenuTypeConst.DISABLED),
          visible: _ctx.getPropertyBooleanValue(_ctx.MenuTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW),
          placeholder: _ctx.getPropertyValue(_ctx.MenuTypeConst.PLACEHOLDER),
          model: _ctx.items,
          popup: _ctx.getPropertyBooleanValue(_ctx.MenuTypeConst.POPUP),
          appendTo: _ctx.getPropertyValue(_ctx.MenuTypeConst.APPENDTO),
          autoZIndex: _ctx.getPropertyBooleanValue(_ctx.MenuTypeConst.AUTOZINDEX),
          baseZIndex: _ctx.getPropertyNumberValue(_ctx.MenuTypeConst.BASEZINDEX),
          exact: _ctx.getPropertyBooleanValue(_ctx.MenuTypeConst.EXACT),
          tabindex: _ctx.getPropertyValue(_ctx.MenuTypeConst.TABINDEX),
          "aria-label": _ctx.getPropertyValue(_ctx.MenuTypeConst.ARIA_LABEL),
          "aria-labelledby": _ctx.getPropertyValue(_ctx.MenuTypeConst.ARIA_LABELLEDBY),
          ptOptions: _ctx.getPropertyValue(_ctx.MenuTypeConst.PTOPTIONS),
          unstyled: _ctx.getPropertyBooleanValue(_ctx.MenuTypeConst.UNSTYLED),
          modelValue: _ctx.vmodel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vmodel) = $event))
        }, _createSlots({
          start: _withCtx(() => [
            _renderSlot(_ctx.$slots, "start")
          ]),
          end: _withCtx(() => [
            _renderSlot(_ctx.$slots, "end")
          ]),
          itemicon: _withCtx((slotProps) => [
            _renderSlot(_ctx.$slots, "itemicon", {
              slotProps: { item: slotProps.item, class: slotProps.class, control: _ctx.ControlTypeConst.CTMENU }
            })
          ]),
          _: 2 /* DYNAMIC */
        }, [
          (_ctx.getLayoutComponent(_ctx.Component, _ctx.MenuTypeConst.LAYOUT_ITEM))
            ? {
                name: "item",
                fn: _withCtx((slotProps) => [
                  _renderSlot(_ctx.$slots, "item", {
                    slotProps: { item: slotProps.item, control: _ctx.ControlTypeConst.CTMENU }
                  })
                ]),
                key: "0"
              }
            : undefined
        ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["id", "style", "class", "name", "disabled", "visible", "placeholder", "model", "popup", "appendTo", "autoZIndex", "baseZIndex", "exact", "tabindex", "aria-label", "aria-labelledby", "ptOptions", "unstyled", "modelValue"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.MenuTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}