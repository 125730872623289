/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

// import { AuthAxiosRepository } from '../../../casosUso/auth/infrastructure/authAxiosRepository'
import MessageService, { MessageType } from '../servicios/MessageService'
import { newGuid } from './HelperUtils'
import HelperCommon from './HelperCommon'
import { useRouter } from 'vue-router'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ErrorResponse } from '@apollo/client/link/error'
import ErrorCodesConst from '../../domain/constantes/ErrorCodes/ErrorCodesConst'



function setError (data: AxiosRequestConfig<any> | any) {
  if (data) {
    // AuthAxiosRepository.post('/error/logErrWithoutSession', data)
  }
}

// err: error trace
// vm: component in which error occured
// info: Vue specific error information such as lifecycle hooks, events etc.
const logException = (err: { message: string, stack: any }, vm: any, info: any): void => {
  if (err && (ErrorCodesConst.ERROR_LIST_CONTROLS.includes(err.message))) {
    return
  }

  //const lastErrorRefId = newGuid()

  /* if (process.env.VUE_APP_DEBUGGING === 'true') {
    MessageService.showMessage(MessageType.Error, 'Error en la aplicación', JSON.stringify(err.stack))
  } else {
    const msg = 'ReferenciaId:' + lastErrorRefId + ' . Consulte el log para ver el detalle'
    MessageService.showMessage(MessageType.Error, 'Error en la aplicación', msg)
  } */

  /*
  const data = {
    userAgent: navigator.userAgent,
    errorType: 'Component Error',
    message: 'Error en el componente',
    errmessage: err.message,
    stack: err.stack,
    commponent: vm,
    info
  }

  const trace = {
    error: JSON.stringify(data),
    errorRefId: lastErrorRefId
  }

  setError(trace)
  */
}

const logExceptionW = (message: string, source: { toString: () => string | string[] }, lineno: any, colno: any, error: any): void => {
  // error en los calendar
  /* if (source && source.toString().includes('utils.esm.js')) {
    return
  } */

  // error en los calendar
  if (message && ErrorCodesConst.ERROR_LIST_CONTROLS.includes(message)) {
    return
  }

  const lastErrorRefId = newGuid()
  const msg = 'ReferenciaId:' + lastErrorRefId + ' .' + message + ' . Consulte el log para ver el detalle'
  MessageService.showMessage(MessageType.Error, 'Error en la aplicación', msg)

  const data = {
    userAgent: navigator.userAgent,
    errorType: 'Window Error',
    message,
    source,
    lineno,
    colno,
    error

  }

  const trace = {
    error: JSON.stringify(data),
    errorRefId: lastErrorRefId
  }
  setError(trace)
}

const logExceptionApiService = (error: { request: { responseURL: string }, response: { data: any, status: number } }): void => {
  const router = useRouter()
  if (error.request.responseURL.endsWith('/security/logout')) {
    return
  }
  let title = 'Error'

  // sesión no activa
  const response = error.response.data
  if (response.msgCode === 'bllSecurity.login.001' || response.msgCode === 'bllSecurity.checkSecurity.002') {
    // const router = useRouter();
    if (router && router.currentRoute.value.name === 'login') {
      title = 'Error de acceso al sistema'
    }
    if (router && router.currentRoute.value.name !== 'login' && response.msgCode === 'bllSecurity.checkSecurity.002') {
      MessageService.showMessage(MessageType.Error, title, response.message.toString(), true, false, false, '', HelperCommon.salir)
    } else {
      MessageService.showMessage(MessageType.Error, title, response.Message.toString())
    }
  } else {
    if (error.response.status.toString() !== ErrorCodesConst.NEED_MULTIFACTOR_CODE) {
      MessageService.showMessage(MessageType.Error, title, error.response.data.Message.toString())
    }
  }
}

const logExceptionApiServiceData = (data: AxiosResponse<any, any>): void => {
  const response = data.data
  const msgs = response.messages ? [response.message] :  []

  if (response.error || response.failed  ) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    var msgStr = response.error ?  msgs.map((value: { description: any }) => value.description).join(',') : null
    if (!msgStr){
      msgStr = msgs.map((value: { description: any }) => value.description).join(',')
    }
    MessageService.showMessage(MessageType.Error, 'Error', msgStr)
  } else {
    if (msgs.filter((x: null) => x !== null).length > 0) {
      var msgStr = response.error ?  msgs.map((value: { description: any }) => value.description).join(',') : null
      if (!msgStr){
        msgStr = msgs.map((value: { description: any }) => value.description).join(',')
      }      
        MessageService.showMessage(MessageType.Info, 'Aviso', msgStr)      
    }
  }

  
}

const logExceptionApiGraphService = (data:ErrorResponse): void => {  
  
  
  let messageType=MessageType.Error;
  //const msgStr = data.graphQLErrors?.map((x: { message: any }) => x.message).join(',') ?? '';
  let msgStr =     data.graphQLErrors?.map(({ message, locations, path }) =>
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        
      ).join()

      const customMessage=msgStr?.split('#999#')??[];

      if(customMessage?.length==3){
 
        msgStr=customMessage[1];
        messageType=MessageType.Aviso;

      }

      if (msgStr){
        MessageService.showMessage(messageType, 'Error', msgStr)
      }
}


export default { logException, logExceptionW, logExceptionApiService, logExceptionApiServiceData,logExceptionApiGraphService }
