
import { CustomMenu } from '../../../../common/domain/modelos/menu/CustomMenu';
import { IapMenu } from '../domain/iapMenu';
import { IapmUser } from '../../../builderMaster/user/domain/iapmUser';

const buildMenu = (menusBuilder: IapMenu[], parentId = -1): CustomMenu[] => {
  let menus: CustomMenu[] = [];

  if (menusBuilder?.length > 0) {
    menusBuilder.filter(x => (parentId == -1 && x.parentId == null) || (parentId !== -1 && x.parentId == parentId)).sort(x => (x.order ?? 0)).forEach(x => {
      menus.push({
        id: x.id,
        parentId: x.parentId,
        order: x.order,
        builtin: x.builtin,
        contrato: x.contract,
        keepAlive:x.keepAlive ?? false,
        label: x.title,
        visible: x.active,
        to: x.urlComponentStatic,
        icon: x.idIcon,
        directivas: '',
        route: x.componentId == null ? x.urlRouteComponentStatic : x.urlComponentStatic,
        items: buildMenu(menusBuilder, x.id),
        componentId: x.componentId,
        applicationId: x.applicationId,
        applicationVersion: x.applicationVersion,
        localizations: x.localizations,
        fcr: x.fcr,
        uum: x.uum,
        ucr: x.ucr,
        fum: x.fum,
      } as any)
    });
  }

  return ((menus.length == 0 ? null : menus.sort((a, b) => { return (a.order ?? 0) - (b.order ?? 0); })) as any);
}

const customMenuToIapMenu = (x: CustomMenu): Partial<IapMenu> => {
    var response: Partial<IapMenu> = {
    id: x.id,
    parentId: x.parentId,
    order: x.order,
    
    builtin: x.builtin ? x.builtin == "true" : null,
    contract: x.contrato ? x.contrato == "true" : null,
    title: typeof x.label === "string" ? x.label : null,
    active: typeof x.visible === "boolean" ? x.visible : false,
    keepAlive: typeof x.keepAlive === "boolean" ? x.keepAlive : false,
    urlComponentStatic: typeof x.to === "string" ? x.to : null,
    idIcon: typeof x.icon === "string" ? x.icon : null,
    urlRouteComponentStatic: typeof x.route === "string" ? x.route : null,
    componentId: x.componentId,
    applicationId: x.applicationId,
    applicationVersion: x.applicationVersion,
    fcr: x.fcr,
    ucr: x.ucr,
    uum: x.uum,
    fum: x.fum
  }
  return response
}


const addMenuBuilder = (usr: IapmUser,
  applicationId: number,
  applicationVersion: number,
  menusBuilder: IapMenu[]) => {

  if (   applicationId !== 1 
      //&& applicationVersion !== 1 
      && usr?.isAdmin 
      && usr?.userName?.toLowerCase().includes('intrasoft.es')) 
    {
      if (menusBuilder == null){
        menusBuilder=[] 
      }
    let sysMnu = menusBuilder?.find(x => x?.title?.toLowerCase().includes('sistema'));
    if (sysMnu == null) {
      sysMnu =
        {
          id: -999998,
          applicationId: applicationId,
          applicationVersion: applicationVersion,
          componentId: null,
          urlComponentStatic: null,
          urlRouteComponentStatic: null,
          title: 'Sistema',
          idIcon: 'pi pi-share-alt',
          parentId: null,
          contract: null,
          builtin: null,
          order: 99999999,
          active: true,
          fcr: new Date(),
          ucr: usr.id,
          uum: null,
          fum: null,
          application: null,
          component: null,
          idIconNavigation: null,
          inverseParent: null,
          parent: null,
          localizations: null,
          posibleComponents: [],
          possibleIcons: [],
          groups: [],
          objectGroups: [],
        } as any

      menusBuilder.push(sysMnu as any)
    }
    if (sysMnu) {
      menusBuilder.push({

        id: -999999,
        applicationId: applicationId,
        applicationVersion: applicationVersion,
        componentId: null,
        urlComponentStatic: '/aplicaciones/gestion',
        urlRouteComponentStatic: '../../../../builder/application/infrastructure/component/Applications.vue',
        title: 'Builder',
        idIcon: 'pi pi-box',
        parentId: sysMnu.id,
        contract: null,
        builtin: null,
        order: 99999999,
        active: true,
        keepAlive:true,
        fcr: new Date(),
        ucr: usr.id,
        uum: null,
        fum: null,
        application: sysMnu.application,
        component: null,
        idIconNavigation: null,
        inverseParent: null,
        parent: null,
        localizations: null,
        posibleComponents: [],
        possibleIcons: [],
        groups: [],
        objectGroups: [],
      } as any)
    }

  }
}

export const searchMenuTree = (element: any[], value: any): any  => {

  for (const item of element) {
      if (item.id === value) {
          return item;
      }
      if (item.items && item.items.length > 0) {
          const nestedItem = searchMenuTree(item.items, value);
          if (nestedItem) {
              return nestedItem;
          }
      }
  }
  return null;

}


export default {
  buildMenu,
  customMenuToIapMenu,
  addMenuBuilder,
  searchMenuTree
}