import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = ["id", "name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_CustomValidate = _resolveComponent("CustomValidate")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && !_ctx.getPropertyBooleanValue(_ctx.InputSwitchTypeConst.READONLY) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _withDirectives((_openBlock(), _createBlock(_component_InputSwitch, {
            key: _ctx.componentKey,
            id: _ctx.getPropertyValue(_ctx.InputSwitchTypeConst.ID),
            style: _normalizeStyle(_ctx.getPropertyValue(_ctx.InputSwitchTypeConst.STYLE)),
            class: _normalizeClass({ [_ctx.getPropertyValue(_ctx.InputSwitchTypeConst.CLASS) ?? '']: true, 'p-invalid': _ctx.v$.vmodel.$error && _ctx.submitted, 'p-readonly': _ctx.getPropertyBooleanValue(_ctx.InputSwitchTypeConst.READONLY) }),
            name: _ctx.getPropertyValue(_ctx.InputSwitchTypeConst.NAME),
            disabled: _ctx.getPropertyBooleanValue(_ctx.InputSwitchTypeConst.DISABLED) || (!_ctx.canEdit),
            visible: _ctx.getPropertyBooleanValue(_ctx.InputSwitchTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW),
            placeholder: _ctx.getPropertyValue(_ctx.InputSwitchTypeConst.PLACEHOLDER),
            trueValue: _ctx.getPropertyBooleanValue(_ctx.InputSwitchTypeConst.TRUEVALUE),
            falseValue: _ctx.getPropertyBooleanValue(_ctx.InputSwitchTypeConst.FALSEVALUE),
            inputId: _ctx.getPropertyValue(_ctx.InputSwitchTypeConst.INPUTID),
            readonly: _ctx.getPropertyBooleanValue(_ctx.InputSwitchTypeConst.READONLY) || !_ctx.canDoOperation(_ctx.ObjectGroupConst.EDIT),
            modelValue: _ctx.vmodel,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vmodel) = $event)),
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.processEventComponent(_ctx.CatalogEventConst.CHANGE)))
          }, null, 8 /* PROPS */, ["id", "style", "class", "name", "disabled", "visible", "placeholder", "trueValue", "falseValue", "inputId", "readonly", "modelValue"])), [
            [_directive_tooltip, _ctx.getPropertyValue(_ctx.InputSwitchTypeConst.TOOLTIP)]
          ]),
          (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE))
            ? (_openBlock(), _createBlock(_component_CustomValidate, {
                key: 0,
                submitted: _ctx.submitted,
                "onUpdate:submitted": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.submitted) = $event)),
                vObject: _ctx.v$,
                "onUpdate:vObject": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.v$) = $event))
              }, null, 8 /* PROPS */, ["submitted", "vObject"]))
            : _createCommentVNode("v-if", true)
        ]))
      : (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && _ctx.getPropertyBooleanValue(_ctx.InputSwitchTypeConst.READONLY))
        ? (_openBlock(), _createElementBlock("span", { key: _ctx.componentKey }, [
            _withDirectives(_createElementVNode("i", {
              id: _ctx.getPropertyValue(_ctx.InputSwitchTypeConst.ID),
              style: _normalizeStyle(_ctx.getPropertyValue(_ctx.InputSwitchTypeConst.STYLE)),
              class: _normalizeClass([{ [_ctx.getPropertyValue(_ctx.InputSwitchTypeConst.CLASS) ?? '']: true, 'p-invalid': _ctx.v$.vmodel.$error && _ctx.submitted,'pi-times-circle':_ctx.vmodel?.toString()?.toLowerCase() !== 'true', 'text-red-500': _ctx.vmodel?.toString()?.toLowerCase() !== 'true', 'pi-check-circle': _ctx.vmodel?.toString()?.toLowerCase() == 'true' ,'text-green-500': _ctx.vmodel?.toString()?.toLowerCase() == 'true' }, "pi"]),
              name: _ctx.getPropertyValue(_ctx.InputSwitchTypeConst.NAME)
            }, null, 14 /* CLASS, STYLE, PROPS */, _hoisted_2), [
              [_directive_tooltip, _ctx.getPropertyValue(_ctx.InputSwitchTypeConst.TOOLTIP)]
            ])
          ]))
        : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}