import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';

import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { GET_WORKFLOWACTIVITYCONNECTION_BY_ID } from './query/workFlowActivityConnectionQuery';
import { ADD, DELETE } from './mutation/workFlowActivityConnectionMutation';
import { IWorkFlowActivityConnectionRepository } from '../domain/repositorry/IWorkFlowActivityConnectionRepository';
import { IapWorkFlowActivityConnection } from '../domain/service/iapWorkFlowActivityConnection';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';





@injectable()
export class WorkFlowActivityConnectionApolloClientRepository implements IWorkFlowActivityConnectionRepository {
  private apolloClient : MyApolloClient
  private apolloClientId: string
  constructor (apolloInstance: MyApolloClient, _apolloClientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.apolloClientId = _apolloClientId
  }
  
  


  async add (workflowActivityId:number, dataInput: IapWorkFlowActivityConnection[]): (Promise<IapWorkFlowActivityConnection[]>) {
    return new Promise<IapWorkFlowActivityConnection[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD, { workflowActivityId:workflowActivityId, data:dataInput }, this.apolloClientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.workFlowActivityConnectionMutation.addWorkFlowActivityConnection)
        }
        catch(e) {
          reject()
        }
      });

    });
  }


  
  async delete (dataInput: IapWorkFlowActivityConnection): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(DELETE, { data:dataInput }, this.apolloClientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.workFlowActivityConnectionMutation.deleteWorkFlowActivityConnection)
        }
        catch(e) {
          reject()
        }
      });

    });
  }


  async getById (id: number): (Promise<IapWorkFlowActivityConnection>) {
    return new Promise<IapWorkFlowActivityConnection>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_WORKFLOWACTIVITYCONNECTION_BY_ID, { id: id}, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.WorkFlowQuery.getById)
        }
        catch(e) {
          reject()
        }
      });
      
    });
  }

}


