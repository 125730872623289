import BaseControlTypeConst from "./BaseControlTypeConst"

export default class LayoutTypeConst extends BaseControlTypeConst {
  
   
    static LAYOUT_TOPBAR ='layouttemp-layout-topbar'
    static LAYOUT_FOOTER ='layouttemp-layout-footer'
   

  }
  