import gql from 'graphql-tag';


export const DECRYPT = gql(`
mutation($data: String!){
    encryptMutation{
        decrypt(data:$data)
    }
}
`);


export const ENCRYPT = gql(`
mutation($data: String!){
    encryptMutation{
        encrypt(data:$data)
    }
}
`);


