import BaseControlTypeConst from "./BaseControlTypeConst"

export default class EmailTypeConst extends BaseControlTypeConst {
    static CMD_MAIL_SENDED='commandMailSended'
    static TO='to'
    static CC='cc'
    static SUBJECT='subject'

    static MSG='message'
    static CAPTION='caption'

    static IDOBJ='idObjectAdditionalDocs'
    static OBJID='objectIdAdditionalDocs'
    static DOCSOURCE='documentAdditionalConnection'
    
    static ENT_MAIN_INT_ID ='entityMainIntegrationIdAdditionalDocs'


  }
  