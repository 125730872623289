import gql from 'graphql-tag';

export const GET_ALL_MENUS = gql(`
query($maxregs: Int!) {
  menuQuery {
     getAll(maxregs:$maxregs) {
      applicationId,
      applicationVersion,
      builtin,
      componentId
      contract,
      active,
      order,
      fcr,
      fum,
      id,
      idIcon,
      parentId,
      title,
      ucr,
      uum,
      urlComponentStatic,
      urlRouteComponentStatic,
      keepAlive
    }
  }
}
`);

export const GET_MENU_BY_ID = gql(`
query($id: Int) {
  menuQuery {
      getById(id:$id) {
        applicationId,
        applicationVersion,
        builtin,
        componentId
        contract,
        active,
        order,
        fcr,
        fum,
        id,
        idIcon,
        parentId,
        title,
        ucr,
        uum,
        urlComponentStatic,
        urlRouteComponentStatic,
        keepAlive
      }
    }
}
`);


export const GET_MENU_BY_APPLICATION_ID = gql(`
query($administrator: Int!, $profileId: Int!, $applicationId: Int!, $version: Int!) {
  menuQuery {
    getByApplicationId(administrator: $administrator, profileId: $profileId, applicationId: $applicationId, version: $version) {
      applicationId,
      applicationVersion,
      builtin,
      componentId
      contract,
      active,
      order,
      fcr,
      fum,
      id,
      idIcon,
      parentId,
      title,
      ucr,
      uum,
      urlComponentStatic,
      urlRouteComponentStatic,
      keepAlive,
      localizations {
          idObjeto,
          objetoId,
          objetoIdRoot
          idObjetoRoot            

          idCulture,
          applicationId,
          applicationVersion,
          description,
          title,
          fcr,
          fum,
          ucr,
          uum
      },
      
    }
  }
}
`);