<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"

            />
    <Menu v-if="loaded && getPropertyBooleanValue(MenuTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(MenuTypeConst.ID)" v-tooltip="getPropertyValue(MenuTypeConst.TOOLTIP)"
        :style="getPropertyValue(MenuTypeConst.STYLE)" :class="{ [getPropertyValue(MenuTypeConst.CLASS) ?? '']: true }"
        :name="getPropertyValue(MenuTypeConst.NAME)" :disabled="getPropertyBooleanValue(MenuTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(MenuTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" :placeholder="getPropertyValue(MenuTypeConst.PLACEHOLDER)"
        :model="items" :popup="getPropertyBooleanValue(MenuTypeConst.POPUP)"
        :appendTo="getPropertyValue(MenuTypeConst.APPENDTO)" :autoZIndex="getPropertyBooleanValue(MenuTypeConst.AUTOZINDEX)"
        :baseZIndex="getPropertyNumberValue(MenuTypeConst.BASEZINDEX)" :exact="getPropertyBooleanValue(MenuTypeConst.EXACT)"
        :tabindex="getPropertyValue(MenuTypeConst.TABINDEX)" :aria-label="getPropertyValue(MenuTypeConst.ARIA_LABEL)"
        :aria-labelledby="getPropertyValue(MenuTypeConst.ARIA_LABELLEDBY)"
        :ptOptions="getPropertyValue(MenuTypeConst.PTOPTIONS)" :unstyled="getPropertyBooleanValue(MenuTypeConst.UNSTYLED)"
        v-model="vmodel" >

        <template #start>
            <slot name="start"></slot>
        </template>

        <template #end>
            <slot name="end"></slot>
        </template>

        <template v-if="getLayoutComponent(Component, MenuTypeConst.LAYOUT_ITEM)" #item="slotProps">
            <slot name="item" :slotProps="{ item: slotProps.item, control: ControlTypeConst.CTMENU }"></slot>
        </template>

        <template #itemicon="slotProps">
            <slot name="itemicon"
                :slotProps="{ item: slotProps.item, class: slotProps.class, control: ControlTypeConst.CTMENU }"></slot>
        </template>

        <!-- <template #submenuheader="slotProps">
             <slot name="submenuheader" :slotProps="{item:slotProps,class:slotProps.class, control:ControlTypeConst.CTMENU }"></slot> 
        </template> -->

    </Menu>
</template>
<script lang="ts">


import { defineComponent, onMounted, ref, onBeforeUnmount, onUnmounted, Component } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import MenuTypeConst from '../../../../domain/Constants/MenuTypeConst';
import ControlTypeConst from '../../../../domain/Constants/ControlTypeConst';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_menu',
    components: {
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },

        slotProps: {
            type: Object,
            default: () => ({})
        },     
         

    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue, loaded, 
            baseOnMounted, baseOnBeforeUnmount, baseOnUnmount,
            buildMenu, getLayoutComponent ,fieldKey,fieldKeyComponentDataSourceId,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);
        const items = buildMenu();

        

     

        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })
    

        return {

            getPropertyValue,
            getPropertyBooleanValue,
            getPropertyNumberValue,
            vmodel,
            MenuTypeConst,
            loaded,
            BaseControlTypeConst,
            items,
            ControlTypeConst,
            getLayoutComponent,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey

        };
    },
});
</script>
<style scoped></style>
