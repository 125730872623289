import gql from 'graphql-tag';

export const SETMICROSOFTACCOUNT = gql(`
    mutation ($userId:Int!,$microsoftAccount:String) {
        userMutation {
            updateMicrosoftAccount (userId:$userId,microsoftAccount:$microsoftAccount)
        }
    }
`);

export const SETGOOGLEACCOUNT = gql(`
    mutation ($userId:Int!,$googleAccount:String) {
        userMutation {
            updateGoogleAccount (userId:$userId,googleAccount:$googleAccount)
        }
    }
`);

