import {  injectable } from 'inversify'
import 'reflect-metadata'
import { IDobleAuthenticationUserRepository } from '../domain/IDobleAuthenticationUserRepository';
import {  watch } from 'vue';
import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import { SENDMAIL } from './mutation/dobleAuthenticationUserMutation';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';
import { uploadFile } from '@ilinium/shared/src/entidades/builderMaster/auth/domain/uploadFile'

@injectable()
export class DobleAuthenticationUserApolloClientRepository implements IDobleAuthenticationUserRepository {
  private apolloClient : MyApolloClient
  constructor (apolloInstance: MyApolloClient) {
    this.apolloClient = apolloInstance
  }
  async sendMail (userId: number, to: string, cc: string, subject: string, body: string, attachments: [uploadFile]): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve) => {
      const result = this.apolloClient.executeQuery(SENDMAIL, { userId: userId, to: to, cc: cc, subject: subject, body: body, attachments: attachments })
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.sendMailMutation.sendMail)
        }
        catch(e) {
          resolve(false)
        }
      });
    });
  }

}