import { InMemoryCache, ApolloClient, HttpLink, DefaultOptions } from "@apollo/client/core";
import { onError } from '@apollo/client/link/error'

import {
  provideApolloClients,  
  useQuery,
} from '@vue/apollo-composable';
import { Ref, ref } from "vue";
import HelperException from "./HelperException";
import store from '../almacen'; //  @ilinium/shared/src/common/infrastructure/almacen
import { Actions } from "../almacen/enums/StoreEnums";
import { authRequest } from '../../../entidades/builderMaster/auth/domain/authRequest'
import { Container } from "inversify";
import HelperLoading from "./HelperLoading";
import { getCatalogsFromService } from '@ilinium/shared/src/entidades/builder/catalog/infrastructure/helper/helperCatalog';
import CatalogConfigurationConst from "../../../entidades/builder/catalog/domain/const/CatalogConfigurationConst";
import { IapApplicationConfiguration } from "../../../entidades/builder/application/domain/iapApplicationConfiguration";
import { useRouter } from 'vue-router'
import HelperCommon from "./HelperCommon";
import HelperLogin from "../../../entidades/builderMaster/auth/infrastructure/component/HelperLogin";
import Environment from "@ilinium/shared/src/common/infrastructure/funciones/environment";

export default class MyApolloClient {
  public instance: ApolloClient<any> | null = null

  cache = new InMemoryCache({
    addTypename: false
  })
  private url: string = ''
  private container: Container = new Container;


  getHeaders() {
    const headers: { Authorization?: string; "Content-Type"?: string , Ip?: string, ComputerName?: string, ProfileId?: string, accept?: string } = {};
    const token = store.getters.getAcessToken;    
    const computerName = store.getters.getComputerName ?? '';
    const profileId = store.getters.getCurrentProfile ?? '';
    
    headers["ComputerName"] = computerName;
    headers["ProfileId"] = profileId;
    headers["accept"] = 'application/json';
    

    if (token) {
      console.log(token) // NO BORRAR!!!!! PARA POSTMAN
      headers["Authorization"] = `Bearer ${token}`;
    }



    headers["Content-Type"] = "application/json";
    return headers;
  }

  constructor (_urls: string[], _clientIds: string[], container :Container) {
    this.container = container;
    const createClient = (url: string) => {
      
      
      const uri = url
      const httpLink = new HttpLink({
        uri,
        //@ts-ignore:disable-next-line
        fetch: async (uri: RequestInfo, options: RequestInit) => {
          // where we inject the headers
          options.headers = this.getHeaders()
          
          return fetch(url, options)
        },
      })
      return new ApolloClient({
        // where we set an InMemoryCache
        cache: this.cache,
        link: errorLink.concat(httpLink),
        defaultOptions
      })
    }

    
    var errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
      HelperLoading.hideSaving()
      const oldHeaders = operation.getContext().headers;
    
      if (networkError){
        var _error = JSON.stringify(networkError, [ 'statusCode' ]);
        if (JSON.parse(_error).statusCode === 401) {
  
         const request = ref<authRequest>({
          grant_type: 'refresh_token',
          username: store.getters.getCurrentUser.userName,
          password: '',
          microsoftEmail: null,
          googleEmail: null,
          client_id: '',
          client_secret: '',
          refresh_token: store.getters.getRefreshToken,
          brokerId: null,
          profileId: '',
          applicationId: Environment.APPID ? +Environment.APPID : null,
          applicationVersion: Environment.APPVERSION ? +Environment.APPVERSION : null,
          external: (store.getters.getAppConfig?.filter( (x:IapApplicationConfiguration) => x.idKeyType == CatalogConfigurationConst.ISAPPEXTERNAL)[0].keyValue === "true") ? true : false,
          sessionId:store.getters.getSessionId
        });
  
        const router = useRouter();

        store
            .dispatch(Actions.AUTH, [request, this.container])
            .then(() => {                            
                /*operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: getNewToken(),
                },
              });
  */
              getCatalogsFromService(container, request.value.applicationId ? request.value.applicationId : 0,request.value.applicationVersion ? request.value.applicationVersion : 0).then(response => {})
              HelperLogin.getAppLayoutComponent(request.value.applicationId ? request.value.applicationId : 0, request.value.applicationVersion ? request.value.applicationVersion : 0, container).then(() => {
                HelperLogin.getApplicationMenus(true, router, request.value.applicationId ? request.value.applicationId : 0, request.value.applicationVersion ? request.value.applicationVersion : 0, container, 'mipanel', true).then(() => {
                  router.push({ path: router.currentRoute.value.fullPath }).then(() => {
                    // Aquí podrías recargar componentes específicos si fuera necesario
                  });
                });
              });
            })
            .catch(() => {
              HelperCommon.salir(this.container ,router);
            })
  
        }
        else if (graphQLErrors){
          HelperException.logExceptionApiGraphService({graphQLErrors:graphQLErrors} as any)
       } 
      }
     else if (graphQLErrors){
        HelperException.logExceptionApiGraphService({graphQLErrors:graphQLErrors} as any)
     }

    })

    const defaultOptions: DefaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },      
      mutate: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      }
    }

    var i = 0;
    const clientList = {};
    
    _urls.forEach(url => {
      if (i == 0) {
        Object.assign(clientList, {default: createClient(url)})
      }
      else {
        const myPropertyName = _clientIds[i];
        Object.assign(clientList, { [myPropertyName]: createClient(url)})
      }
      i++;
    });
    const clients = clientList;

    provideApolloClients(clients);

  }




   executeQuery(query: any, variables: any = null, clientId: any = null): Ref<any> {    

    //const query64 =  HelperCrypto.Encrypt(this.url, query, store) as any    
    //const variables64 = HelperCrypto.Encrypt(this.url, variables, store) as any
    /*
    if (variables ){
      Object.keys(variables).forEach(k =>{
        variables[k] = HelperCrypto.Encrypt(this.url, variables[k] , store) as any
      })
    }
    */
    
    const httpLink = new HttpLink({
      // You should use an absolute URL here
      uri: this.url,
      //@ts-ignore:disable-next-line
      fetch: (uri: RequestInfo, options: RequestInit) => {        
        options.headers = this.getHeaders();        
        const outFetch = fetch(uri, options);
        return outFetch;
      },
    })
    
    const { result, loading, error } = useQuery(query, variables, { clientId: clientId })
    return result
  }
 

  /*
executeMutation(query: any, variables:any= null): Ref<any> {
  const httpLink = new HttpLink({
    // You should use an absolute URL here
    uri: this.url,
    fetch: (uri: RequestInfo, options: RequestInit) => {        
      options.headers = this.getHeaders();        
      const outFetch = fetch(uri, options);
      return outFetch;
    },
  })
  
  if (this.instance != null) {
    this.instance.link = httpLink
  }

  const { onDone, loading, error } = useMutation(query,variables)
  
  onDone((res) => {
    return res
});
  //return result
}
*/
}

//export let apolloClient = new MyApolloClient()
