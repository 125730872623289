import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import { DataUpdateOperation } from '../domain/dataUpdateOperation';
import { IDataUpdateRepository } from '../domain/IDataUpdateRepository';

import { UPDATE } from './mutation/updateDataMutation';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';
import { DataUpdateResult } from '../domain/dataUpdateResult';
import { IapDataSourceLookUp } from '../../datasource/domain/iapDataSourceLookUp';



@injectable()
export class DataUpdateApolloClientRepository implements IDataUpdateRepository {
  private apolloClient : MyApolloClient
  private clientId: string;
  constructor (apolloInstance: MyApolloClient, clientId:string) {
    this.apolloClient = apolloInstance
    this.clientId=clientId
  }
  
  

  async update (applicationId:number, applicationVersion:number, data: DataUpdateOperation[], transactionOperation:boolean, lookUp?:IapDataSourceLookUp): (Promise<DataUpdateResult[]>) {
    return new Promise<DataUpdateResult[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE,{applicationId: applicationId,applicationVersion:applicationVersion,data:data,transactionOperation:transactionOperation,lookUp:lookUp},this.clientId)      
      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.dataMutation.updateData)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

}


