import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IDataUpdateRepository } from '../domain/IDataUpdateRepository';



import { IServiceDataUpdate } from './IServiceDataUpdate';
import { DataUpdateOperation } from '../domain/dataUpdateOperation';
import { DataUpdateResult } from '../domain/dataUpdateResult';
import { IapDataSourceLookUp } from '../../datasource/domain/iapDataSourceLookUp';





@injectable()
export class DataUpdateService implements IServiceDataUpdate {
  private readonly repo: IDataUpdateRepository

  public constructor (@inject(TYPES.CONFIG_UPDATEDATA) _repo: IDataUpdateRepository) {
    this.repo = _repo
  }

  async update (applicationId:number, applicationVersion:number, data: DataUpdateOperation[], transactionOperation:boolean, lookUp?:IapDataSourceLookUp): (Promise<DataUpdateResult[]>) {
    return await this.repo.update(applicationId,applicationVersion,  data, transactionOperation, lookUp);
  }

}
