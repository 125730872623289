import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_FormBuilder = _resolveComponent("FormBuilder")!
  const _component_Panel = _resolveComponent("Panel")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? _withDirectives((_openBlock(), _createBlock(_component_Panel, {
          key: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.PanelTypeConst.ID),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.PanelTypeConst.STYLE)),
          class: _normalizeClass(_ctx.getPropertyValue(_ctx.PanelTypeConst.CLASS)),
          name: _ctx.getPropertyValue(_ctx.PanelTypeConst.NAME),
          disabled: _ctx.getPropertyValue(_ctx.PanelTypeConst.DISABLED),
          visible: _ctx.getPropertyValue(_ctx.PanelTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW),
          placeholder: _ctx.getPropertyValue(_ctx.PanelTypeConst.PLACEHOLDER),
          header: _ctx.getPropertyValue(_ctx.PanelTypeConst.HEADER),
          toggleable: _ctx.getPropertyBooleanValue(_ctx.PanelTypeConst.TOGGLEABLE),
          collapsed: _ctx.getPropertyBooleanValue(_ctx.PanelTypeConst.COLLAPSED),
          modelValue: _ctx.vmodel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vmodel) = $event))
        }, {
          icons: _withCtx(() => [
            _renderSlot(_ctx.$slots, "icons")
          ]),
          header: _withCtx(() => [
            _renderSlot(_ctx.$slots, "header")
          ]),
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "content", {}, () => [
              (_ctx.componentId>0 )
                ? (_openBlock(), _createBlock(_component_FormBuilder, {
                    key: 0,
                    container: _ctx.container,
                    componentId: _ctx.componentId,
                    applicationId: _ctx.Component.applicationId,
                    applicationVersion: _ctx.Component.applicationVersion,
                    attrsInput: _ctx.attrsInput
                  }, null, 8 /* PROPS */, ["container", "componentId", "applicationId", "applicationVersion", "attrsInput"]))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["id", "style", "class", "name", "disabled", "visible", "placeholder", "header", "toggleable", "collapsed", "modelValue"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.PanelTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}