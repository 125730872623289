import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'
import type { IApplicationConfigurationRepository } from '../domain/IApplicationConfigurationRepository';
import { IServiceApplicationConfiguration } from './IServiceApplicationConfiguration';
import { IapApplicationConfiguration } from '../domain/iapApplicationConfiguration';



@injectable()
export class ApplicationConfigurationService implements IServiceApplicationConfiguration {
  private readonly repo: IApplicationConfigurationRepository

  public constructor (@inject(TYPES.APPLICATIONCONFIGURATION_REPOSITORY) _repo: IApplicationConfigurationRepository) {
    this.repo = _repo
  }

  async delete(applicationId:number, applicationVersion:number,idKeyType:string) :Promise<boolean> {
    return await this.repo.delete(applicationId,applicationVersion,idKeyType)
  }
  

  async add (data: IapApplicationConfiguration): (Promise<IapApplicationConfiguration>) {
    return await this.repo.add(data);
  }


  async update (data: IapApplicationConfiguration): (Promise<IapApplicationConfiguration>) {
    return await this.repo.update(data);
  }

  async getAll(maxregs: number | undefined) :Promise<IapApplicationConfiguration[]> {
    return await this.repo.getAll(maxregs)
  }

  
  async getByApplicationId( applicationId: number, applicationVersion: number) :Promise<IapApplicationConfiguration[]> {
    return await this.repo.getByApplicationId( applicationId, applicationVersion)
  }


  async getByAppAndTypes( applicationId: number, applicationVersion: number,types:string[]) :Promise<IapApplicationConfiguration[]> {
    return await this.repo.getByAppAndTypes( applicationId, applicationVersion,types)
  }


}
