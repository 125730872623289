import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';

import {GET_ALL } from './query/configurationQuery';

import { IapmoConfiguration } from '../domain/iapmoConfiguration';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { IConfigurationRepository } from '../domain/IConfigurationRepository';




@injectable()
export class ConfigurationApolloClientRepository implements IConfigurationRepository {
  private apolloClient : MyApolloClient
  private apolloClientId: string
  constructor (apolloInstance: MyApolloClient, _apolloClientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.apolloClientId = _apolloClientId
  }
  
  
  
  async all (): (Promise<IapmoConfiguration[]>) {
    return new Promise<IapmoConfiguration[]>(async (resolve, reject) => {
      
      const result = this.apolloClient.executeQuery(GET_ALL, null, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          
          HelperUtils.handleDatesApollo(value) 
          resolve(value.configurationQuery.getAll)
        }
        catch(e) {
          
          reject()
        }
      });
    });
  }



}




