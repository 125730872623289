import gql from 'graphql-tag';




export const ADD = gql(`
mutation($workflowActivityId:Int!, $data: [WorkFlowActivityConnectionInputType]!){
    workFlowActivityConnectionMutation{
        addWorkFlowActivityConnection(workflowActivityId:$workflowActivityId,data:$data){
            id
            workFlowActivityVariableIdInput
            workFlowActivityVariableIdOutput
            externalId
        }
    }
}
`);



export const DELETE = gql(`
mutation( $data: WorkFlowActivityConnectionInputType!){
    workFlowActivityConnectionMutation{
        deleteWorkFlowActivityConnection(data:$data)
    }
}
`);