import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_Button = _resolveComponent("Button")!
  const _component_AppProfileSidebar = _resolveComponent("AppProfileSidebar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_Button, {
      icon: "pi pi-users",
      style: {"color":"var(--primary-color)"},
      text: "",
      rounded: "",
      severity: "secondary",
      onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.getWhoAmIProfiles();_ctx.visibleRight = true})
    }),
    (_openBlock(), _createBlock(_component_AppProfileSidebar, {
      key: _ctx.componentKey,
      container: _ctx.container,
      visibleRight: _ctx.visibleRight,
      whoAmIProfilesResponse: _ctx.whoAmIProfilesResponse,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.visibleRight = !_ctx.visibleRight))
    }, null, 8 /* PROPS */, ["container", "visibleRight", "whoAmIProfilesResponse"]))
  ], 64 /* STABLE_FRAGMENT */))
}