<template>
    <Dialog v-model:visible="isDialogVisible" header="Importar datos" modal @hide="eraseData"
            styleClass="mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6" style="width:50%" maximizable
            contentStyleClass="border-round-bottom border-top-1 surface-border p-0">
            <Card>
                <template #content> 
                    <CustomSteps v-model:activeIndex="selectedTab" :showEndButton="false" @next="nextPage"
                        @previous="previousPage">
                        <template v-slot:customcontrolsend>
                            <div v-if="isLastTab && myData.length > 0">
                                <Button id="importar" class="mr-2" label="Importar"
                            icon="pi pi-file-import"  severity="help" @click="$emit('begin:import',doImportData)" />                                
                            </div>
                        </template>
                        <TabPanel v-for="tab in itemsMenu" :key="tab.id">
                            <template #header>
                                <div>
                                    <i class="pi pi-list"></i> <span style="margin-right:10px"> {{ tab.label }}</span>
                                </div>
                            </template>
                            <div v-if="tab.id == 0 && selectedTab === tab.id">
                                <FileUpload accept=".csv,.xlsx,.xls" :maxFileSize="1000000" label="UploadFile"
                                    chooseLabel="Adjuntar archivo a importar" class="mr-2 inline-block p-button-secondary"
                                    @select="onSelectedFiles">
                                    <template #empty>
                                        <p>Arrastre y suelte aquí el fichero tipo CSV o EXCEL que desea importar.</p>
                                    </template>
                                </FileUpload>
                            </div>
                            <div v-if="tab.id == 1 && selectedTab === tab.id">
                                <Card class="mt-1">
                                    <template #title>Configuración</template>
                                    <template #content>
                                        <ImportConfig :container="container" v-model:cfg="cfg"
                                            :setupImport="setupImport"
                                            :originalKeysFile="myFileCfgColumnsOriginal"
                                            @change:numLinHeader="matchFieldNames()" @update:params="updateParamsFromCfg" />
                                    </template>
                                </Card>
                                <Card v-if="cfg.id > 0" class="mt-1">
                                    <template #title>Mapeos de campos</template>
                                    <template #content>
                                        <DataTable :key="keyCfgColumns"  :value="myFileCfgColumns" paginator :rows="100" :filters="filtersMapeos">
                                            <template #header>
                                                <div class="flex flex-column md:flex-row  md:align-items-center">
                                                    <span class="block mt-2 md:mt-0 p-input-icon-left">
                                                        <i class="pi pi-search" />
                                                        <InputText v-model="filtersMapeos['global'].value"
                                                            placeholder="Buscar..." />
                                                        <span class="p-input-icon-right" style="display: inline;">
                                                            <i class="pi pi-times" v-tooltip.top="'Borrar búsqueda'"
                                                                @click="filtersMapeos['global'].value = ''" />
                                                        </span>
                                                    </span>
                                                </div>
    
                                            </template>
    
                                            <template #empty> No se han encontrado datos para los filtros indicados
                                            </template>
    
    
                                            <Column frozen header="Campo Origen" field="key">
                                                <template #body="slotProps">
                                                    <i class="pi pi-eye mr-2" style="font-size: 2rem"
                                                    v-tooltip="{ value: 'Ver el contenido del campo ' +  slotProps.data.key }"
                                                            @click="showColumnData(slotProps.data.key, $event)"></i>
                                                    {{ slotProps.data.key }}
                                                </template>
    
                                            </Column>
    
                                            <Column frozen header="Campo Destino" field="value">
                                                <template #body="slotProps">
                                                    <div style=" margin: 0 auto;">
                                                        
                                                        <Dropdown style="margin-right: 10px;" v-model="slotProps.data.value"
                                                            :options="options" optionLabel="desc" optionValue="id" filter
                                                            showClear />
                                                    </div>
                                                </template>
    
                                            </Column>
    
                                        </DataTable>
                                    </template>
                                </Card>
                            </div>
                            <div v-if="tab.id == 2 && selectedTab === tab.id">
                                <DataTable :value="uploadFiles" v-model:selection="selectedItems" paginator :rows="10"
                                    responsiveLayout="scroll" :filters="filters">
                                    <template #header>
                                        <div class="flex flex-column md:flex-row  md:align-items-center">
                                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                                <i class="pi pi-search" />
                                                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                                                <span class="p-input-icon-right" style="display: inline;">
                                                    <i class="pi pi-times" v-tooltip.top="'Borrar búsqueda'"
                                                        @click="filters['global'].value = ''" />
                                                </span>
                                            </span>
                                            
                                            <MultiSelect v-model="selectedColumns" :options="myFileCfgColumns" filter optionLabel="key"
                                                style="margin-left: 10px;" optionValue="key"
                                                :maxSelectedLabels="5" @update:modelValue="onToggleSelectedColumns"
                                                display="chip" placeholder="Selecciona un filtro" class="w-full md:w-20rem">
                                                <template #optiongroup="slotProps">
                                                    <div class="flex align-items-center">
                                                        <i :class="slotProps.option.icon"></i>
                                                        <div class="ml-2">{{ slotProps.option.label }}</div>
                                                    </div>
                                                </template>
                                            </MultiSelect>                                            
                                        </div>
    
                                    </template>
    
                                    <template #empty> No se han encontrado datos para los filtros indicados </template>
    
                                    <Column selectionMode="multiple" headerStyle="width: 3rem" :exportable="false" frozen>
                                    </Column>
    
                                    <Column v-for="(col, index) of myFileCfgColumns.filter(x=> selectedColumns.includes(x.key))" :key="col.key + '_' + index" field="key"
                                        frozen>
                                        <template #header>
                                            <div style="display: block">
                                                <label style="display: block;">{{ col.key }}</label>
                                                <Dropdown style="margin-right: 10px;" v-model="myFileCfgColumns[findMyFileCfgColumnIndex(col.key)].value"
                                                    :options="options" optionLabel="desc" optionValue="id" filter
                                                    showClear />
                                            </div>
                                        </template>
                                        <template #body="{ data }">
                                            {{ data[col.key] }}
                                        </template>
                                      
                                    </Column>
                                </DataTable>
                            </div>
                            <div v-if="tab.id == 3 && (selectedTab === tab.id || selectedTab === (tab.id - 1))">
           
                                <Card class="mt-5">
                                    <template #title>Resumen de operaciones</template>
                                    <template #content>
                                        <p class="m-0">
                                            <div v-html="buildSummaryOperations"></div>                                          
                                        </p>
                                    </template>
                                </Card>
                            </div>
                        </TabPanel>
                    </CustomSteps>
                </template>
            </Card>
        </Dialog>
      
     <OverlayPanel ref="opPreview" appendTo="body" :showCloseIcon=true>
            <div class="flex flex-column gap-3 w-25rem">
                <DataTable :value="uploadFiles" paginator :rows="5" responsiveLayout="scroll" :filters="filtersPreview">
    
                    <template #empty> No se han encontrado datos para los filtros indicados </template>
    
                    <Column :field="selectedColumnPreview" frozen>
                        <template #header>
                            <div class="flex flex-column md:flex-row  md:align-items-center">
                                <span class="mr-2">{{ selectedColumnPreview }}</span>
                                <span class="block mt-2 md:mt-0 p-input-icon-left">
    
                                    <i class="pi pi-search" />
                                    <InputText v-model="filtersPreview['global'].value" placeholder="Buscar..." />
                                    <span class="p-input-icon-right" style="display: inline;">
                                        <i class="pi pi-times" v-tooltip.top="'Borrar búsqueda'"
                                            @click="filtersPreview['global'].value = ''" />
                                    </span>
                                </span>
                            </div>
                        </template>
                        <template #body="{ data }">
                            {{ data[selectedColumnPreview] }}
                        </template>                                      
                    </Column>
                </DataTable>
            </div>
        </OverlayPanel>
    </template>
    
    <script lang="ts">
    
    import { defineComponent, computed, onMounted, ref, watch, shallowRef } from 'vue';
    import { Container } from 'inversify';
    import HelperCommon from '../../../../../common/infrastructure/funciones/HelperCommon';
    import CustomSteps from '../../../../../common/infrastructure/componentes/base/common/controles/CustomSteps.vue';
    import { IapComponentDataSource } from '../../../component/domain/iapComponentDataSource';
    import { IapDataSourceField } from '../../domain/iapDataSourceField';
    import * as XLSX from 'xlsx';
    import MessageService, { MessageType } from '../../../../../common/infrastructure/servicios/MessageService';
    import { FilterMatchMode, FilterOperator } from 'primevue/api';
    import OperationDataTypeConst from '../../../../../common/domain/constantes/OperationDataTypeConst';
    import { dataUpdate } from '../../../dataupdate/domain/dataUpdate';
    import HelperOperationData from '../../../../../common/infrastructure/funciones/HelperOperationData';
    import DataSourceUtilComp from "../../../crud/infrastructure/functions/dataSourceUtilComp";
    import SqlTypesConst from '@ilinium/shared/src/common/domain/constantes/SqlTypesConst';
    import DataSourceConst from '../../../crud/infrastructure/functions/dataSourceConst';
    import TypeScriptTypesConst from '../../../../../common/domain/constantes/TypeScriptTypesConst';
    import { validate as isValidUUID } from 'uuid';
    import { useI18n } from 'vue-i18n';
    import { TYPES } from '@ilinium/shared/src/common/domain/types';
    import HelperLoading from '@ilinium/shared/src/common/infrastructure/funciones/HelperLoading';
    import CatalogDbType from '../../../catalog/domain/const/CatalogDbType';
    import { IServiceSearch } from '../../../search/application/IServiceSearch';
    import { clave } from '../../../dataupdate/domain/clave';
    import HelperUtils from '../../../../../common/infrastructure/funciones/HelperUtils';
    import { useStore } from 'vuex';
    import DataSourceComp from '../../../crud/infrastructure/functions/dataSourceComp';
    import { IapCatalog } from '../../../catalog/domain/iapCatalog';
    import { messages } from '../../../crud/infrastructure/search/localization/MessagesBusqueda';
    import { IapComponent } from '../../../component/domain/iapComponent';
    import { DataUpdateOperation } from '../../../dataupdate/domain/dataUpdateOperation';
    import { IapDataSource } from '../../domain/iapDataSource';
    import ImportConfig from './ImportConfig.vue'
    import { IapmImportConfig } from '../../../../builderMaster/user/domain/iapmImportConfig';
    import { IServiceImportConfig } from '../../../../builderMaster/user/application/IServiceImportConfig';
    import { GroupSearch } from '../../../search/domain/search';
    import OperatorLogicConst from '../../../search/domain/Const/OperatorLogicConst';
    import { Dictionary } from '../../../expression/domain/dictionary';
    
    export default defineComponent({
        name: 'importdatathrowdatasource',
        emits: ['update:dialogVisible', 'end:import','begin:import'],
        props: {
            container: {
                type: Object as () => Container
            },
            dialogVisible: Boolean,
    
            compDataSource: {
                type: Object as () => IapComponentDataSource,
                default: () => ({})
            },
            catalogosData: {
                type: Object as () => IapCatalog[],
                default: () => ([])
            },
            Component: {
                type: Object as () => IapComponent,
                default: () => ({})
            },
            formKey: {
                type: String,
                default: ''
            },
            rootParentId:
            {
                type: Number,
                default: (-1)
            },
            setupImport: {
                type: String,
                default: ''
            },
        },
        components: {
            CustomSteps,
            ImportConfig
        },
        setup(props, { emit }) {
            const store = useStore();
            const selectedTab = shallowRef(0);
            let compDataSource =props.compDataSource;
            const keyCfgColumns = ref(1)
            const selectedItems = ref([]);
            let options: any = [];
            const { t, locale } = useI18n(messages)
            const opPreview = ref()
            let fileName = ''
            const selectedColumnPreview = shallowRef('')
            const selectedColumns = ref();// ref(columns.value.slice(0, 3));
            const cfg = ref<IapmImportConfig>({
                id: 0,
                name: '',
                fieldKey: props.setupImport,
                numLineHeader: 0,
                data: ''
            })
    
        
            let myData = shallowRef<dataUpdate[]>([]); 
    
            const { getDefaultvalue, getFieldFromKeyCol, getFieldKeyColumn, adjustCdsAndClauses } = DataSourceComp(props.container as any, props, emit, t, props.compDataSource, props.catalogosData, store)
            options.push({ id: 'unselected', desc: 'No sincronizar' });
            const _options = compDataSource.iapComponentDataSourceFieldConfigurations.filter(cdsfc => cdsfc.import == true).map(c => ({ id: c.dataSourceField?.id, desc: c.dataSourceField?.field }));
            options = options.concat(_options);        
    
            let myFileCfgColumnsOriginal = ref<Dictionary<String, String>[]>([]);
            let myFileCfgColumns = ref<Dictionary<String, String>[]>([]);
    
            const filters = ref({
                'global': { value: '', matchMode: FilterMatchMode.CONTAINS },
                'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            });
    
            const filtersPreview = ref({
                'global': { value: '', matchMode: FilterMatchMode.CONTAINS },
                'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            });

            const filtersMapeos = ref({
                'global': { value: '', matchMode: FilterMatchMode.CONTAINS },
                'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            });

            
    
    
            watch(myFileCfgColumns, () => {
                cfg.value.data = JSON.stringify(myFileCfgColumns.value)
                updateCfg()
                keyCfgColumns.value = keyCfgColumns.value +1
            },
                {
                    flush: 'post',
                    //immediate: true, 
                    deep: true
                })
    
    
    
    
            const updateCfg = () => {
    
                if (props.container && cfg.value.id > 0) {
                    HelperLoading.showSaving();
                    const _srv = props.container.get<IServiceImportConfig>(
                        TYPES.IMPORT_REPOSITORY
                    );
    
    
                    _srv.update(JSON.parse(JSON.stringify(cfg.value))).then(() => {
    
    
                    })
                        .finally(() => {
                            HelperLoading.hideSaving();
                        })
    
    
                }
    
            }
    
    
            const previousPage = () => {
                selectedTab.value--
                if (selectedTab.value == 0) {
                    eraseData()
                }
            }
    
            const nextPage = () => {
                let canDo = true;
                let validate: boolean[] = [];
                let messages = '';
                let selectedItemsTranslated: any[] = JSON.parse(JSON.stringify(selectedItems.value));
    
                if (selectedTab.value == 0 && !fileName) {
                    canDo = false
                    messages = 'Debe seleccionar un fichero.'
                }
    
                if (selectedTab.value == 1 && cfg.value.id == 0) {
                    canDo = false
                    messages = 'Debe crear o seleccionar una configuración.'
                }
    
                if (selectedTab.value == 2) {
                    if (compDataSource.dataSource.iapDataSourceFields.filter(cds => cds.primaryKey == true && cds.identityColumn == false && !myFileCfgColumns.value.find(f => f.value == cds.id)).length > 0) {
               
                        canDo = false;
                        messages += '\n El campo ' + compDataSource.dataSource.iapDataSourceFields.find(cds => cds.primaryKey == true && cds.identityColumn == false && myFileCfgColumns.value.filter(f => f.key == cds.field && f.value != ''))?.field + ' es clave primaria y debe sincronizarse.'

                    }
                    if (canDo) {
                        for (let i = 0; i < selectedItems.value.length; i++) {
                            for (let j = 0; j < myFileCfgColumns.value.length; j++) {
    
                                var fieldConf = compDataSource.iapComponentDataSourceFieldConfigurations.find(cdsfc => cdsfc.dataSourceFieldId == myFileCfgColumns.value[j].value);
                                if (fieldConf && fieldConf.mandatory && fieldConf.dataSourceField && !selectedItems.value[i][myFileCfgColumns.value[j].key as any]) {
                                    canDo = false;
                                    messages += '\n El campo ' + fieldConf.dataSourceField.field + ' es obligatorio y no puede estar vacío.'
                                }
    
                                var field = compDataSource.dataSource.iapDataSourceFields.find(dsfc => dsfc.id == myFileCfgColumns.value[j].value);
    
                                if (field) {
                                    var obj = selectedItemsTranslated[i];
                                    //var _delete = false;
                                    //if (obj[field.field] == undefined) {// Si ya existe un campo con ese nombre no podemos luego borrarlo pq se está sobreescribiendo en el mismo campo y no añadiendo uno
                                    //    _delete = true;
                                    //}
    
                                 
                                    obj[getFieldKeyColumn(field)] = obj[myFileCfgColumns.value[j].key as any];
                               
                                    
                                    obj['dataBase'] = compDataSource.dataSource?.iapDataSourceDataBase?.idBaseDatos;
                                    obj['table'] = field.tableName;
    
                                    //if (_delete) {
                                    delete (obj[myFileCfgColumns.value[j].key as any]);
                                    //}
                                    //selectedItemsTranslated.push(obj);
                                }
                               
                                
    
                                if (field && field.primaryKey && !selectedItems.value[i][myFileCfgColumns.value[j].key as any]) {
                                    canDo = false;
                                    messages += '\n El campo ' + field.field + ' es clave primaria y no puede estar vacío.'
                                }
    
                                //En campos tipo string se pueden insertar por ejemplo enteros
                                /*if (field && (field.sqlType == SqlTypesConst.VARCHAR || field.sqlType == SqlTypesConst.NVARCHAR || field.sqlType == SqlTypesConst.VARBINARY ||
                                    field.sqlType == SqlTypesConst.TEXT) && typeof selectedItems.value[i][myFileCfgColumns.value[j][0]] !== TypeScriptTypesConst.STRING) {
                                    canDo = false;
                                    messages += '\n El campo ' + field.field + ' es de tipo string y el dato ' + selectedItems.value[i][myFileCfgColumns.value[j][0]] + ' no es válido.'
                                }*/
    
                                if (field && field.sqlType == SqlTypesConst.INT && !Number.isInteger(+selectedItems.value[i][myFileCfgColumns.value[j].key as any]) && !(+selectedItems.value[i][myFileCfgColumns.value[j].key  as any] % 1)) {
                                    canDo = false;
                                    messages += '\n El campo ' + field.field + ' es de tipo entero y el dato ' + selectedItems.value[i][myFileCfgColumns.value[j].key  as any] + ' no es válido.'
                                }
    
                                if (field && (field.sqlType == SqlTypesConst.DATE || field.sqlType == SqlTypesConst.DATETIME) && isNaN(selectedItems.value[i][myFileCfgColumns.value[j].key  as any]) && !isNaN(Date.parse(selectedItems.value[i][myFileCfgColumns.value[j].key  as any]))) {
                                    canDo = false;
                                    messages += '\n El campo ' + field.field + ' es de tipo fecha y el dato ' + selectedItems.value[i][myFileCfgColumns.value[j].key  as any] + ' no es válido.'
                                }
    
                                if (field && field.sqlType == SqlTypesConst.BIT && !isBoolean(('' + selectedItems.value[i][myFileCfgColumns.value[j].key as any] ).toLowerCase())) {
                                    canDo = false;
                                    messages += '\n El campo ' + field.field + ' es de tipo booleano y el dato ' + selectedItems.value[i][myFileCfgColumns.value[j].key as any] + ' no es válido.'
                                }
    
                                if (field && field.sqlType == SqlTypesConst.UNIQUEIDENTIFIER && !isValidUUID(('' + selectedItems.value[i][myFileCfgColumns.value[j].key as any]))) {
                                    canDo = false;
                                    messages += '\n El campo ' + field.field + ' es de tipo GUID y el dato ' + selectedItems.value[i][myFileCfgColumns.value[j].key as any] + ' no es válido.'
                                }
    
                                if (field && field.sqlType == SqlTypesConst.DECIMAL && !isDecimal(+selectedItems.value[i][myFileCfgColumns.value[j].key as any]) && (+selectedItems.value[i][myFileCfgColumns.value[j].key as any] % 1)) {
                                    canDo = false;
                                    messages += '\n El campo ' + field.field + ' es de tipo decimal y el dato ' + selectedItems.value[i][myFileCfgColumns.value[j].key as any] + ' no es válido.'
                                }
    
                                if (field && field.sqlType == SqlTypesConst.MONEY) {                                    
                                    //if (HelperCommon.isNullOrWhitespace(selectedItems.value[i][myFileCfgColumns.value[j].key as any] ?? '')){
                                        //@ts-ignore:disable-next-line
                                        //selectedItems.value[i][myFileCfgColumns.value[j].key as any] = '0'
                                    //}
                                    //selectedItems.value[i][myFileCfgColumns.value[j].key as any] = selectedItems.value[i][myFileCfgColumns.value[j].key as any];
                                    if (!isDecimal(+selectedItems.value[i][myFileCfgColumns.value[j].key as any])) {
                                        canDo = false;
                                        messages += '\n El campo ' + field.field + ' es de tipo moneda y el dato ' + selectedItems.value[i][myFileCfgColumns.value[j].key as any] + ' no es válido.'
                                    }
                                }
    
                                // Todo: insertar o update
                                // Todo: borrado de cosas
                            }
                        }
                    }
    
                    if (selectedItemsTranslated.length == 0) {
                        canDo = false;
                        messages += '\n No se han seleccionado datos para sincronizar.'
                    }
                }
    
    
    
                if (canDo) {
                    selectedTab.value++;
                    if (isLastTab.value) {
                        loadPreviewData(selectedItemsTranslated);
                    }
                    
                }
                else {
                    MessageService.showMessage(MessageType.Error, 'Error de validación', messages);
                }
    
            };
    
            function isDecimal(num: number): boolean {
                // Verifica si el número no es NaN (no es un número) y si es un número finito.
                if (!isNaN(num) && isFinite(num)) {
                    // Convierte el número a un string y luego verifica si sigue siendo un número decimal válido.
                    return /^[+-]?\d*\.?\d+$/.test(num.toString());
                }
                return false;
            }
    
            const loadPreviewData = (selectedItemsTranslated: any[]) => {
                if (props.container) {

                 
                const searchData:GroupSearch={
                    operatorLogic:OperatorLogicConst.OR,
                    fields:[],
                    children:[]
                }

                

                    const _srv = props.container.get<IServiceSearch>(
                        TYPES.SEARCH_REPOSITORY
                    );
                    HelperLoading.showLoading();
    
                    const primaryKeyFields = compDataSource.dataSource.iapDataSourceFields.filter(dsf => dsf.primaryKey).map(f => ({id:f.id,  field: f.field }) );
                   
                    selectedItems.value.forEach(row => {
                        const searchDataItem:GroupSearch={
                            operatorLogic:OperatorLogicConst.AND,
                            fields:[],
                            children:[]
                        }
                        primaryKeyFields.forEach(keyField => {
                            if (myFileCfgColumns.value.filter(f => f.value == compDataSource.iapComponentDataSourceFieldConfigurations.filter(dsfc => dsfc.dataSourceField?.field == keyField.field)[0].dataSourceFieldId).length > 0) {
                            
                                //@ts-ignore:disable-next-line
                                const _field = myFileCfgColumns.value.filter(f => f.value == compDataSource.iapComponentDataSourceFieldConfigurations.filter(dsfc => dsfc.dataSourceField?.field == keyField.field)[0].dataSourceFieldId)[0]['key'] as any;
                                searchDataItem.fields.push({
                                    //@ts-ignore:disable-next-line
                                    id:HelperUtils.newGuid().toString().replaceAll('-',''),
                                    fieldId: keyField.id,
                                    filter:'',
                                    value:  row[_field],
                                    valueList: null,
                                    valueBool: false,
                                    valueNumber: 0,
                                    valueDateTime: new Date(),
                                    rangeValue: null,
                                    rangeNumber: null,
                                    rangeDateTime: null
                                } as any)
                                
                              
                            }

                        });
                      

                        searchData.children.push(searchDataItem)
                    });
    
                    
                    const cdsCopy=adjustCdsAndClauses(props.compDataSource);

                    _srv.search(props.Component.applicationId, props.Component.applicationVersion, props.Component.id,
                    searchData as any, [], -1 ,cdsCopy)
                        .then((response) => {
                            const results = JSON.parse(response?.items ?? '[]');
                            const { getPrimaryKeys, getValuesWithoutPrimaryKeys } = DataSourceUtilComp(props.compDataSource, store)
                            myData.value = [];
                            let canDo = true;
                            let notSincFieldsForInserts: any[] = [];
                            notSincFieldsForInserts = compDataSource.dataSource.iapDataSourceFields.filter(dsf => !selectedItemsTranslated.find(t => t[getFieldKeyColumn(dsf)]));
                            for (let i = 0; i < notSincFieldsForInserts.length; i++) {
                                for (let j = 0; j < selectedItemsTranslated.length; j++) {
                                    if (getTipoOperacion(props.compDataSource.dataSource, results, getPrimaryKeys(props.compDataSource.dataSource, selectedItemsTranslated[j], selectedItemsTranslated[j].table as any), selectedItemsTranslated[j]) == OperationDataTypeConst.INSERT) {
                                        var objDefaultValue = selectedItemsTranslated[j];
                                        var fieldConfig = compDataSource.iapComponentDataSourceFieldConfigurations.find(cdsfc => cdsfc.dataSourceFieldId == notSincFieldsForInserts[i].id);
                                        var defaultValue = '';
                                        if (fieldConfig && fieldConfig.dataSourceField) {
                                            defaultValue = getDefaultvalue(fieldConfig.dataSourceField, fieldConfig?.defaultValue)
                                        }
                                        if (defaultValue && defaultValue != '') {
                                            objDefaultValue[getFieldKeyColumn(notSincFieldsForInserts[i])] = defaultValue;
                                        }
                                        else if (fieldConfig && fieldConfig.mandatory) {
                                            canDo = false;
                                            MessageService.showMessage(MessageType.Error, 'Error de validación', 'El campo ' + notSincFieldsForInserts[i].field + ' es obligatorio y no puede estar vacío (INSERT).');
                                        }
                                        else {
                                            objDefaultValue[getFieldKeyColumn(notSincFieldsForInserts[i])] = undefined;//notSincFieldsForInserts[i].defaultValue;
                                        }
                                    }
                                }
                            }
    
                            if (canDo) {
                                //@ts-ignore:disable-next-line
                                myData.value = selectedItemsTranslated.map(item => ({
                                    valores: getValuesWithoutPrimaryKeys(props.compDataSource.dataSource, item, item.table as any, getTipoOperacion(props.compDataSource.dataSource, results, getPrimaryKeys(props.compDataSource.dataSource, item, item.table as any), item)),
                                    claves: getTipoOperacion(props.compDataSource.dataSource, results, getPrimaryKeys(props.compDataSource.dataSource, item, item.table as any), item) == OperationDataTypeConst.INSERT ? [] : getPrimaryKeys(props.compDataSource.dataSource, item, item.table as any),
                                    tipoOperacion: getTipoOperacion(props.compDataSource.dataSource, results, getPrimaryKeys(props.compDataSource.dataSource, item, item.table as any), item),
                                    id: HelperUtils.newGuid()
                                } as dataUpdate)) as dataUpdate[];
    
                               
                            }
                        })
                        .catch((err) => {
    
                        })
                        .finally(() => {
                            HelperLoading.hideLoading();
                        });
                }
            }

            const doImportData = () =>{

                HelperLoading.showLoading();
                const dataInputRequest: DataUpdateOperation = {
                                    componentId: props.compDataSource.componentId,
                                    componentDataSourceId: props.compDataSource.id,
                                    dataSourceId: props.compDataSource.dataSourceId,
                                    parameters: props.compDataSource.dataSource.iapDataSourceServiceConfigurations ?? [],
                                    data: JSON.parse(JSON.stringify(myData.value))
                                }
    
                                HelperOperationData.doOperationData(props.container, props.compDataSource.applicationId, props.compDataSource.applicationVersion, [dataInputRequest], true)
                                    .then(response => {
                                        if (response && response[0].result == true) {
                                            MessageService.showMessage(MessageType.Correcto, '', 'Datos importados correctamente', true, false, false, '', hideImportDialog());
                                            emit('end:import')
                                        }
                                        else {
                                            MessageService.showToast(MessageType.Error, 'Error al importar', 'No se han podido importar los datos.');
                                        }
                                    }).catch((err) => {
                                        MessageService.showToast(MessageType.Error, 'Error al importar', 'No se han podido importar los datos.');
                                    }) .finally(() => {
                            HelperLoading.hideLoading();
                        });
            }
    
            const isLastTab = computed(() => {
                if (selectedTab.value === (itemsMenu.value.length - 1)) {
                    return true;
                }
    
                return false;
            })
    
            const isDialogVisible = computed({
                get: () => props.dialogVisible,
                set: (val) => emit('update:dialogVisible', val),
            });
    
            const hideImportDialog = () => {
                isDialogVisible.value = false;
                eraseData();
            }
    
            const eraseData = () => {
                selectedTab.value = 0;
                selectedItems.value = [];
                myFileCfgColumns.value = [];
                myFileCfgColumnsOriginal.value = [];
                myData.value = [];
                selectedColumns.value = [];
                fileName = ''
                cfg.value = {
                    id: 0,
                    name: '',
                    fieldKey: '',
                    numLineHeader: 0,
                    data: ''
                }
            }
    
            const itemsMenu = computed(() => {
                const response = [
                    {
                        key: 'uploadFile',
                        id: 0,
                        label: 'Adjuntar fichero',
                        visible: true,
                    },
                    {
                        key: 'fileDataTableMap',
                        id: 1,
                        label: 'Mapeos de campos',
                        visible: true
                    },
                    {
                        key: 'fileDataTable',
                        id: 2,
                        label: 'Datos del fichero',
                        visible: true
                    },
    
                    {
                        key: 'syncData',
                        id: 3,
                        label: 'Sincronizar datos',
                        visible: true,
                    },
    
                ];
    
                return response.filter(x => x.visible);
            });
    
            const uploadFiles = ref();
    
    
    
            const getTipoOperacion = (dataSource: IapDataSource, results: any, primaryKeys: clave[], item: any) => {
                let filtered = JSON.parse(JSON.stringify(results));
                const resu = new Array()
                primaryKeys.forEach((element: clave) => {
                    const fieldpk = dataSource.iapDataSourceFields.find(x => x.id == element.fieldId)?.field ?? ''
                    //@ts-ignore:disable-next-line
                    const k = element.fieldId.replaceAll('-', '').toLowerCase()
                    const _kobj = k + '_' + fieldpk;//
                    const _value= item[_kobj]
    
                    
                    if (_value && filtered.find((r: any) => r[_kobj] === _value ))
                    {
                        resu.push(1)
                    }
                    
                    //filtered = filtered.filter((r: any) => r[fieldpk] == item[_kobj])
                });
    
                return resu.length  == primaryKeys.length ? OperationDataTypeConst.UPDATE : OperationDataTypeConst.INSERT
            }
    
    
            const addFileNameData = () =>{
                if (uploadFiles.value){
                    uploadFiles.value.forEach( (f:any) => {                       
                        if (!Object.keys(f).includes('NombreFichero')){
                            f['NombreFichero'] = fileName
                        }   
                       
                    })
                }                
            }
    
            const clearMatchFieldNames = () => {
    
                if (myFileCfgColumns.value.length > 0) {
                    myFileCfgColumns.value.forEach((f: any) => {
                        f.value = ''
                    })
                }
            }
    
            const matchFieldNames = () => {

                if (uploadFiles.value[cfg.value.numLineHeader]) {
                    
                addFileNameData()    
                    const _keys = Object.keys(uploadFiles.value[cfg.value.numLineHeader])
                    
                    _keys.forEach(key =>{
                        if (key?.startsWith('"') && key?.endsWith('"')){
                            key = key?.substring(1,key.length -1)
                        }
                    })
                    
                    myFileCfgColumnsOriginal.value = arrToObj(_keys);
                    myFileCfgColumns.value = arrToObj(_keys);
                    
    
                    clearMatchFieldNames()
                    if (myFileCfgColumns.value.length > 0) {
                        Object.keys(uploadFiles.value[cfg.value.numLineHeader]).forEach((k: string, index: number) => {
    
                            const _fieldCfg = props.compDataSource.dataSource.iapDataSourceFields.find(x => [x.shortDescription?.toLowerCase(), '"' + x.shortDescription?.toLowerCase() + '"', x.fieldAlias?.toLowerCase(), '"' + x.fieldAlias?.toLowerCase() + '"', x.field.toLowerCase(), '"' + x.field.toLowerCase() + '"'].includes(k.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase()))
                            if (_fieldCfg && myFileCfgColumns.value[index]) {
                                //@ts-ignore:disable-next-line
                                myFileCfgColumns.value[index].value = _fieldCfg.id
                            }
                        })

                        selectedColumns.value = myFileCfgColumns.value.map(x=> x.key)
                    }
                }
                else {
                    myFileCfgColumnsOriginal.value = arrToObj([]);
                    myFileCfgColumns.value = arrToObj([]);
                    selectedColumns.value = []
                }
    
    
    
            }
    
            const onSelectedFiles = (event: any) => {
    
                fileName = event.files[0].name
                if (event.files[0].type == "text/csv") {
    
                    HelperCommon.toText(event.files[0]).then((value) => {
    
                        const fileText = value as any;
                        let separator = csvDelimiter(fileText);
                        uploadFiles.value = csvJSON(fileText, separator);
    
                        matchFieldNames();
                        selectedTab.value = 1;
                    });
                }
                else {
                    HelperCommon.toArrayBuffer(event.files[0]).then((value) => {
    
                        uploadFiles.value = excelJSON(value);
    
                        matchFieldNames();
                        selectedTab.value = 1;
                    });
                }
            };
    
            const csvJSON = (csv: any, separator = ',') => {
                var lines = csv.split("\n");
                var result: any = [];
    
                var headers = lines[0].split(separator);
    
                for (var i = 1; i < lines.length; i++) {
                    var obj: any = {};
                    var currentline = lines[i].split(separator);
    
                    for (var j = 0; j < headers.length; j++) {
                        let header = headers[j]
                        let cLine = currentline[j]
                        
                        if (header?.startsWith('"') && header?.endsWith('"')){
                            header = header?.substring(1,header.length -1)
                        }
                        if (cLine?.startsWith('"') && cLine?.endsWith('"')){
                            cLine = cLine?.substring(1,cLine.length -1)
                        }

                        obj[header] = cLine;
                    }
    
                    result.push(obj);
                }
    
                return result;
            }
    
            function isBoolean(text: string) {
                //@ts-ignore:disable-next-line
                text = text.replaceAll('\r', '').replaceAll('\n', '').replaceAll('"', '');
                return (text === 'true' || text === '1' || text === 'false' || text === '0'  || text.toLowerCase() === 'si' || text.toLowerCase() === 'sí' || text.toLowerCase() === 'no');
            }
            function arrToObj(arr: string[]) { //converting function
                let obj: any = {}
                for (let i = 0; i < arr.length; i++) {
                    obj[arr[i]] = ''
                }
    
                
                const result: Dictionary<String, String>[] = ([]);
                for (var i in obj)
                {
                    result.push(
                {
                    key:i,
                    value : obj[i] as any
                }
                    );

                }
    
                return result;
            }
    
    
    
            const excelJSON = (e: any) => { 
                             
                var data = new Uint8Array(<ArrayBuffer>e);
                var workbook = XLSX.read(data, { type: 'array', cellDates:true });
                let sheetname = workbook.SheetNames[0]
                let worksheet = workbook.Sheets[sheetname];
    
                // Convertir hoja de cálculo a JSON
                var jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: true, defval:""});
                // Iterar sobre cada fila y columna para formatear las fechas
                jsonData.forEach((row: any) => {
                    for (const key in row) {
                        if (Object.prototype.hasOwnProperty.call(row, key)) {
                            const cellValue = row[key];                            
                            if (Object.prototype.toString.call(cellValue) == '[object Date]')
                            {
                                
                                //cellValue.setHours(0);
                                //cellValue.setMinutes(0);
                                //cellValue.setSeconds(0);
                                row[key] = cellValue?.toLocaleDateString() // `${(cellValue.getUTCMonth() + 1).toString().padStart(2, "0")}/${cellValue.getUTCDay().toString().padStart(2, "0")}/${cellValue.getUTCFullYear()}`
                                //cellValue.getMonth().toString().padStart(2, "0")
                            }
                          else if (typeof cellValue === 'string') { // Verificar si es una cadena
                                // Verificar si la cadena representa una fecha en el formato 'dd/mm/yyyy'
                                const dateRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{2,4})$/;
                                if (dateRegex.test(cellValue)) {
                                    const match = dateRegex.exec(cellValue);
                                    const day = ('0' + match![1]).slice(-2); // Agregar ceros a la izquierda si es necesario
                                    const month = ('0' + match![2]).slice(-2); // Agregar ceros a la izquierda si es necesario
                                    const year = match![3].length === 2 ? '20' + match![3] : match![3]; // Asegurar que el año tenga 4 dígitos
                                    row[key] = `${month}/${day}/${year}`; // Formatear la fecha como 'mm/dd/yyyy'
                                }
                                else if (!isNaN(parseFloat(cellValue)) && cellValue.includes(',')) { // Verificar si es un número decimal con coma                                    
                                    //@ts-ignore:disable-next-line
                                    const decimalValue = parseFloat(cellValue.replaceAll('.', '').replaceAll(',', '.')); // Reemplazar comas por puntos y convertir a número
                                    row[key] = parseFloat(decimalValue.toLocaleString('es-ES', { minimumFractionDigits: 2 })); // Formatear el número con comas y 2 decimales
                                }
    
    
                            }
                        }
                    }
                });
    
                return jsonData;
            }
    
            const csvDelimiter = (t: any) => {
                t = t.split("\n")[0];
                let delArray = [',', ';', '|', '    '];
                let comma = 0;
                let samiclon = 0;
                let pipe = 0;
                let tab = 0;
                delArray.forEach((e, i) => {
                    if (i === 0) {
                        comma = t.split(e).length;
                    } else if (i === 1) {
                        samiclon = t.split(e).length;
                    } else if (i === 2) {
                        pipe = t.split(e).length;
                    } else if (i === 3) {
                        tab = t.split(e).length;
                    }
                });
                let tmpArray1 = [comma, samiclon, pipe, tab]
                let tmpArray = [comma, samiclon, pipe, tab];
                let maxLen = tmpArray.sort((a, b) => b - a)[0];
                let delimiter_i = 0;
                tmpArray1.forEach((e, i) => {
                    if (maxLen === e) {
                        delimiter_i = i;
                    }
                });
                if (delimiter_i === 0) {
                    return ',';
                } else if (delimiter_i === 1) {
                    return ';'
                } else if (delimiter_i === 2) {
                    return '|'
                } else if (delimiter_i === 3) {
                    return '    '
                }
            }
    
            const showColumnData = (columnName: string, event: any) => {
                selectedColumnPreview.value = columnName
                opPreview.value.toggle(event);
    
            }
    
            const updateParamsFromCfg = () => {                
                myFileCfgColumns.value = JSON.parse(cfg.value.data) as []
            }

            const buildSummaryOperations = computed(() =>{
                let outPut = ''
                outPut +='Nuevos registros:<b>' + myData.value.filter(x=> x.tipoOperacion == OperationDataTypeConst.INSERT ).length.toString() + "</b></br>"
                outPut +='Actualizaciones:<b>' + myData.value.filter(x=> x.tipoOperacion == OperationDataTypeConst.UPDATE ).length.toString()+  "</b></br>"
                outPut +='Total de operaciones:<b>' + myData.value.length.toString()+ "</b></br>"

                return outPut;

            })

            
        const onToggleSelectedColumns = (val: any) => {
            selectedColumns.value = val as any;
        };

        const findMyFileCfgColumnIndex = (key:string) =>{
            return myFileCfgColumns.value.findIndex(x => x.key == key)
        }
    
            return {
                selectedTab,
                nextPage,
                isLastTab,
                itemsMenu,
                onSelectedFiles,
                uploadFiles,
                isDialogVisible,
                compDataSource,
                arrToObj,
                myFileCfgColumns,
                options,
                filters,
                selectedItems,
                eraseData,
                matchFieldNames,
                opPreview,
                showColumnData,
                selectedColumnPreview,
                filtersPreview,
                cfg,
                updateParamsFromCfg,
                updateCfg,
                previousPage,
                doImportData,
                buildSummaryOperations,
                myData,
                filtersMapeos,
                selectedColumns,
                onToggleSelectedColumns,
                findMyFileCfgColumnIndex,
                myFileCfgColumnsOriginal,
                keyCfgColumns
            };
        },
    });
    </script>
    <style scoped>
</style>