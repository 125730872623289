<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <Button v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(ButtonTypeConst.ID)" v-tooltip="getPropertyValue(ButtonTypeConst.TOOLTIP)"
        :style="getPropertyValue(ButtonTypeConst.STYLE)"
        :class="{ [getPropertyValue(ButtonTypeConst.CLASS) ?? '']: true, 'customrequired': getPropertyBooleanValue(ButtonTypeConst.REQUIRED) }"
        :name="getPropertyValue(ButtonTypeConst.NAME)" :disabled="getPropertyBooleanValue(ButtonTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(ButtonTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :placeholder="getPropertyValue(ButtonTypeConst.PLACEHOLDER)" :label="getPropertyValue(ButtonTypeConst.LABEL)"
        :icon="getPropertyValue(ButtonTypeConst.ICON)" :iconPos="getCatalogValue(ButtonTypeConst.ICONPOS)"
        :iconClass="getPropertyValue(ButtonTypeConst.ICONCLASS)" :badge="getPropertyValue(ButtonTypeConst.BADGE)"
        :link="getPropertyBooleanValue(ButtonTypeConst.LINK)" :severity="getCatalogValue(ButtonTypeConst.SEVERITY)"
        :raised="getPropertyBooleanValue(ButtonTypeConst.RAISED)"
        :rounded="getPropertyBooleanValue(ButtonTypeConst.ROUNDED)" 
        :outlined ="getPropertyBooleanValue(ButtonTypeConst.OUTLINED)"
        :plain="getPropertyBooleanValue(ButtonTypeConst.PLAIN)" :size="getCatalogValue(ButtonTypeConst.SIZE)"
        v-model="vmodel" 
        @click="processEventComponent(CatalogEventConst.CLICK)">
    </Button>
</template>
<script lang="ts">


import { defineComponent, onMounted, onUnmounted } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import ButtonTypeConst from '../../../../domain/Constants/ButtonTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';
import { useStore } from 'vuex';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_button',
    components: {
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        slotProps: {
            type: Object,
            default: () => ({})
        },

    },
    setup(props, context) {
        //

        const store = useStore();        
        const { vmodel,canDoOperation,baseOnMounted, isValidData, getPropertyValue, getPropertyBooleanValue, loaded, resolveExpressions, getCatalogValue, baseOnUnmount,fieldKey,fieldKeyComponentDataSourceId,processEventComponent, componentKey } = ComponentCommonRender(props.Component,props.slotProps, props.container,store)

       
      


        onUnmounted(() => {
            baseOnUnmount();
        })

        return {
            resolveExpressions,
           
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            ButtonTypeConst,
            loaded,
            BaseControlTypeConst,
            processEventComponent,
            CatalogEventConst,
            getCatalogValue,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey

        };
    },
});
</script>
<style scoped></style>
