import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'


import { IServiceExpression } from './IServiceExpression';
import { IapExpression } from '../domain/iapExpression';
import type { IExpressionRepository } from '../domain/IExpressionRepository';




@injectable()
export class ExpressionService implements IServiceExpression {
  private readonly repo: IExpressionRepository

  public constructor (@inject(TYPES.EXPRESSION_REPOSITORY) _repo: IExpressionRepository) {
    this.repo = _repo
  }

  async update (data: IapExpression): (Promise<IapExpression>) {
    return await this.repo.update(data);
  }

  async add (data: IapExpression): (Promise<IapExpression>) {
    return await this.repo.add(data);
  }

  async getByObjectId (idObject: string, objectId:string):Promise<IapExpression[]> {
    return await this.repo.getByObjectId(idObject,objectId)
  }

  
  async getByObjectType (idObject: string[],idObjetoRoot: string,objetoIdRoot: string):Promise<IapExpression[]> {
    return await this.repo.getByObjectType(idObject,idObjetoRoot,objetoIdRoot)
  }

  async getById(id: string) :Promise<IapExpression> {
    return await this.repo.getById(id)
  }

  async delete(id: string) :Promise<boolean> {
    return await this.repo.delete(id)
  }
}
