import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IComponentDataSourceRepository } from '../domain/IComponentDataSourceRepository';

import { IServiceComponentDataSource } from './IServiceComponentDataSource';
import { IapComponentDataSource } from '../domain/iapComponentDataSource';




@injectable()
export class ComponentDataSourceService implements IServiceComponentDataSource {
  private readonly repo: IComponentDataSourceRepository

  public constructor (@inject(TYPES.COMPONENT_DATASOURCE_REPOSITORY) _repo: IComponentDataSourceRepository) {
    this.repo = _repo
  }
  

  async delete(componentDataSourceId: number) :Promise<boolean> {
    return await this.repo.delete(componentDataSourceId)
  }
  
  async update (data: IapComponentDataSource): (Promise<IapComponentDataSource>) {
    return await this.repo.update(data);
  }

  async add (data: IapComponentDataSource): (Promise<IapComponentDataSource>) {
    return await this.repo.add(data);
  }

  async getAll(maxregs: number | undefined) :Promise<IapComponentDataSource[]> {
    return await this.repo.getAll(maxregs)
  }

  async getAllByComponentId( componentId: number) :Promise<IapComponentDataSource[]> {
    return await this.repo.getAllByComponentId(componentId)
  }
  async getAllAscendancyFromComponent( componentId: number) :Promise<IapComponentDataSource[]> {
    return await this.repo.getAllAscendancyFromComponent(componentId)
  }


}


