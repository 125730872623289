<template>
    <span>Tipo</span>
    <select @pointerdown.stop="" @dblclick.stop="" name="dslist" v-model="variableRedirectTypeSelected"
        style="border: 1px solid #999;border-radius: 30px;    font-size: 110%;" @change="changeType()"
        class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full ">        
        <option :value="0">Url</option>
        <option :value="1">Aplicación</option>
    </select>

    <div v-if="variableRedirectTypeSelected === 0"> 
        <span>Url</span>
        <div style="display: flex;">
            <textarea style="resize: none;" @pointerdown.stop="" @dblclick.stop="" id="w3review" name="msgText" rows="2" cols="50" v-model="variableParamsRedirectUrlSelected"
                class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full "    
                @change="changeVariableUrlValue()" 
            ></textarea>
            <i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" class="fa fa-subscript"
                @click="showExpression(RedirectNodeConst.VAR_PARURL,'Url',{parameterName:RedirectNodeConst.VAR_PARURL} as any)"></i>
        </div>

        <br />

        <span>Mostrar en</span>
        <div style="display: flex;">
            <select @pointerdown.stop="" @dblclick.stop="" name="dslist" v-model="variableParamsRedirectUrlTargetSelected"
                style="border: 1px solid #999;border-radius: 30px;    font-size: 110%;" @change="changeVariableUrlTargetValue()"
                class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full ">        
                <option :value="'_blank'">Nueva Ventana</option>
                <option :value="'_parent'">Ventana padre del actual frame</option>
                <option :value="'_self'">Ventana Actual</option>
                <option :value="'_top'">Ventana Actual reemplaza todos los frames</option>
                <option :value="'name'">Nombre de la ventana</option>
            </select>

            <i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" class="fa fa-subscript"
                @click="showExpression(RedirectNodeConst.VAR_PARURLTARGET,'Url Target',{parameterName:RedirectNodeConst.VAR_PARURLTARGET} as any)"></i>
        </div>

        <br />

        <span>Parámetros</span>
        <div style="display: flex;">
            <textarea style="resize: none;" @pointerdown.stop="" @dblclick.stop="" id="w3review" name="msgText" rows="2" cols="50" v-model="variableParamsRedirectUrlParamsSelected"
                class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full "    
                @change="changeVariableUrlParamsValue()" 
            ></textarea>
            <i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" class="fa fa-subscript"
                @click="showExpression(RedirectNodeConst.VAR_PARURLPARAMS,'Url',{parameterName:RedirectNodeConst.VAR_PARURLPARAMS} as any)"></i>
        </div>

        <br />

    </div>
    <div v-else>
        <span>Menú</span> 
    <select @pointerdown.stop="" @dblclick.stop="" name="mnlist" v-model="selectedData"
        style="border: 1px solid #999;border-radius: 30px;font-size: 110%;"
        @change="data.onChange({ key: RedirectNodeConst.VAR_MENUID, data: selectedData })"
        class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full ">
        <option value="-1">Selecciona un valor</option>
        <option v-for="(mn, index) in getMenuWithPath()" :value="mn.id" :key="index">{{ mn.path }} </option>
    </select>
    <br />
    <span>Parámetros</span>     
    <div @pointerdown.stop="" @dblclick.stop="" style="display: flex;overflow-y:auto; overflow-x:hidden; height:200px;">
        
        <table class="tableParameters">
            <thead>
                <tr>
                    <th v-for="(column, index) in ['Id', 'Nombre', 'Valor', '']" :key="index"> {{ column }}</th>
                    <th><button @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" class="fa fa-plus" style="color:green"
                            @click="addNewItem()"></button></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in selectedParameters" :key="index">
                    <td><input @pointerdown.stop="" @dblclick.stop="" type="text" 
                            v-model="item.key"
                            readonly
                            class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full " />

                    </td>
                    <td><input @pointerdown.stop="" @dblclick.stop="" type="text" @change="changeVariableValue()"
                            v-model="item.value.key"
                            class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full " />

                    </td>
                    <td><textarea style="resize: none;" @pointerdown.stop="" @dblclick.stop="" id="w3review" name="msgText" rows="3" cols="50" v-model="item.value.value"
                        class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full "   
                        @change="changeVariableValue()" 
                        ></textarea>
                    </td>
                    <td><i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" class="fa fa-subscript"
                            @click="showExpression(RedirectNodeConst.VAR_PAR, 'Parámetro ' + item.key.toString(), item.value)"></i>
                    </td>
                    <td><i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" class="fa fa-trash" style="color:red"
                            @click="deleteItem(item.key)"></i>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>


    <br />
    </div>    
    
</template>
<script lang="ts">

import { CustomMenu } from '@ilinium/shared/src/common/domain/modelos/menu/CustomMenu';
import store from '@ilinium/shared/src/common/infrastructure/almacen';
import { computed, defineComponent, onMounted,onBeforeUnmount, watch, ref,shallowRef } from 'vue';
import { searchMenuTree } from '../../../../../menu/infrastructure/HelperMenu';
import RedirectNodeConst from '../constants/RedirectNodeConst';
import HelperCustomComponentControls from '../../../functions/helperCustomComponentControls';
import { Dictionary } from '../../../../../expression/domain/dictionary';
import HelperUtils from '../../../../../../../common/infrastructure/funciones/HelperUtils';

export default defineComponent({
    name: 'customredirectcontrol',
    props: {
        data: Object
    },
    setup(props) {

        const { showExpression } = HelperCustomComponentControls(props.data);
        const selectedData = ref();
        const selectedParameters = ref<Dictionary<String,Dictionary<String,String>>[]>([]);
        const cusMenu = store.getters.getApplicationMenus as CustomMenu[];
        
        const variableRedirectTypeSelected = shallowRef(0);
        const variableParamsRedirectUrlSelected = shallowRef('');
        const variableParamsRedirectUrlTargetSelected = shallowRef('_blank');
        const variableParamsRedirectUrlParamsSelected = shallowRef('');
        
        
        



       

        const getPathFromChildMenu = (menuCustom: CustomMenu, newPath: string | null): string | null => {

            const label = menuCustom.label as string;

            if (newPath == null) {
                newPath = label;
            }
            else {
                newPath = label + ' --> ' + newPath;
            }

            if (menuCustom.parentId) {
                const parent = searchMenuTree(cusMenu, menuCustom.parentId);
                if (parent != null) {
                    return getPathFromChildMenu(parent, newPath);
                }
            }

            return newPath;

        }


        const getAllMenu = (data: CustomMenu[]) => {

            let extractedItems: CustomMenu[] = [];

            for (const element of data) {

                extractedItems.push(element);

                if (element.items && element?.items.length > 0) {

                    const subItems = getAllMenu(element.items as CustomMenu[]);
                    extractedItems = extractedItems.concat(subItems);
                }
            }

            return extractedItems;

        };

        const getMenuWithPath = () => {

            var data = getAllMenu(cusMenu);

            var menu = data.map(x => ({
                id: x.id,
                label: x.label,
                path: getPathFromChildMenu(x, null),
                parentId: x.parent

            })).sort((a, b) => { return (a.parentId ?? 0) - (b.parentId ?? 0); }) as any[]


            return menu;

        };


        const deleteItem  = (key:String) =>{
            selectedParameters.value = selectedParameters.value.filter(x=> x.key !== key)
            changeVariableValue()

        }

        const addNewItem = () =>{
            selectedParameters.value.push({
                key:HelperUtils.newGuid(),
                value :{
                    key:'nuevo parámetro',
                    value:''
                }
            } as any) 
            changeVariableValue()
        }
        
        const changeVariableValue = () => {
            if (props.data) {
                props.data.onChange({ key: RedirectNodeConst.VAR_PAR, data: selectedParameters.value })
            }

        }

        const changeVariableUrlValue = () =>{
            if (props.data) {
                props.data.onChange({ key: RedirectNodeConst.VAR_PARURL, data: variableParamsRedirectUrlSelected.value })
            }
        }

        const changeVariableUrlTargetValue = () =>{
            if (props.data) {
                props.data.onChange({ key: RedirectNodeConst.VAR_PARURLTARGET, data: variableParamsRedirectUrlTargetSelected.value })
            }
        }

        const changeVariableUrlParamsValue = () =>{
            if (props.data) {
                props.data.onChange({ key: RedirectNodeConst.VAR_PARURLPARAMS, data: variableParamsRedirectUrlParamsSelected.value })
            }
        }

        const changeType = () => {
            
            if (props.data) {




                selectedData.value = -1;
                selectedParameters.value = [];

                
                variableParamsRedirectUrlSelected.value = '';
                variableParamsRedirectUrlTargetSelected.value = '_blank';
                variableParamsRedirectUrlParamsSelected.value = '';
        


                props.data.onChange({ key: RedirectNodeConst.VAR_PAR, data: selectedParameters.value })
                props.data.onChange({ key: RedirectNodeConst.VAR_MENUID, data: selectedData.value })
              

                props.data.onChange({ key: RedirectNodeConst.VAR_TIPO, data: variableRedirectTypeSelected.value })
                props.data.onChange({ key: RedirectNodeConst.VAR_PARURL, data: variableParamsRedirectUrlSelected.value })
                props.data.onChange({ key: RedirectNodeConst.VAR_PARURLTARGET, data: variableParamsRedirectUrlTargetSelected.value })
                props.data.onChange({ key: RedirectNodeConst.VAR_PARURLPARAMS, data: variableParamsRedirectUrlParamsSelected.value })

            }
        }


        onMounted(() => {
            if (props.data) {
                selectedData.value = props.data[RedirectNodeConst.VAR_MENUID];
                selectedParameters.value = props.data[RedirectNodeConst.VAR_PAR] ?? [];

                variableRedirectTypeSelected.value = props.data[RedirectNodeConst.VAR_TIPO] ?? 0;
                variableParamsRedirectUrlSelected.value = props.data[RedirectNodeConst.VAR_PARURL];
                variableParamsRedirectUrlTargetSelected.value = props.data[RedirectNodeConst.VAR_PARURLTARGET] ?? '_blank';
                variableParamsRedirectUrlParamsSelected.value = props.data[RedirectNodeConst.VAR_PARURLPARAMS];
        


            }
            
        })

        
        return {
            selectedData,
            selectedParameters,
            getMenuWithPath,
            RedirectNodeConst,
            showExpression,
            deleteItem,
            changeVariableValue,
            addNewItem,            
            variableRedirectTypeSelected,
            variableParamsRedirectUrlSelected,
            variableParamsRedirectUrlTargetSelected,
            variableParamsRedirectUrlParamsSelected,
            changeType,
            changeVariableUrlValue,
            changeVariableUrlTargetValue,
            changeVariableUrlParamsValue


        };
    },
});
</script>
<style scoped></style>
