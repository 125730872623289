import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../common/domain/types'
import type { IEncryptRepository } from '../domain/IEncryptRepository';
import { IServiceEncrypt } from './IServiceEncrypt';



@injectable()
export class ServiceEncrypt implements IServiceEncrypt {
  private readonly repo: IEncryptRepository

  public constructor (@inject(TYPES.ENCRYPT_REPOSITORY) _repo: IEncryptRepository) {
    this.repo = _repo
  }
  async decrypt (data: string) : Promise<string>{
    return await this.repo.decrypt(data)
  }
  async encrypt (data: string) : Promise<string>{
    return await this.repo.encrypt(data)
  }
}
