import gql from 'graphql-tag';


export const SEARCH = gql(`
query(
    $applicationId: Int!
    $applicationVersion: Int!
    $componentId: Int!
    $componentDataSource: ComponentDataSourceInputType= null
    $dataSourceId: Int= null
    $data: GroupSearchInputType!
    $parameters: [ParameterInputType]!
    $maxregs: Int = 100
    $count: Boolean = false
    $lazyParams: LazyParamsInputType=null
    $lang: String = "es-ES"
    )
{
    searchQuery{
        search(applicationId:$applicationId,applicationVersion:$applicationVersion,componentId:$componentId,componentDataSource:$componentDataSource,dataSourceId:$dataSourceId,data:$data,params:$parameters,maxregs:$maxregs,count:$count,lazyParams:$lazyParams,lang:$lang){
            items,
            totalRecords,
            lazyData{
                fieldId,
                operation,
                items
            }
        }
    }
}
`);


export const SEARCHLOOKUP = gql(`
query(
    $applicationId: Int!
    $applicationVersion: Int!
    $lookUpId: Int!    
    $data:GroupSearchInputType!
    $maxregs: Int = 100
    $lang: String = "es-ES"
    )
{
    searchQuery{
        searchLookUpData(applicationId:$applicationId,applicationVersion:$applicationVersion,lookUpId:$lookUpId,data:$data,maxregs:$maxregs,lang:$lang){
            items
        }
    }
}
`);

export const SEARCH_TABLESVIEWS = gql(`
query(
    $applicationId: Int!
    $applicationVersion: Int!
    $databaseId: String!
    $databaseTypeId: String!
    )
{
    searchQuery{
        getTablesAndViews(applicationId:$applicationId,applicationVersion:$applicationVersion,databaseId:$databaseId,databaseTypeId:$databaseTypeId){
            items
        }
    }
}
`);



export const EXECUTE_PROC = gql(`
query(
    $applicationId: Int!
    $applicationVersion: Int!
    $databaseId: String!
    $databaseTypeId: String!,
    $data: [ProcDataInputType]!
    )
{
    searchQuery{
        executeProc(applicationId:$applicationId,applicationVersion:$applicationVersion,databaseId:$databaseId,databaseTypeId:$databaseTypeId, data:$data){
            items
        }
    }
}
`);


export const SEARCH_PROCEDURES_AND_FUNCTIONS = gql(`
query(
    $applicationId: Int!
    $applicationVersion: Int!
    $databaseId: String!
    $databaseTypeId: String!
    )
{
    searchQuery{
        getProceduresAndFunctions(applicationId:$applicationId,applicationVersion:$applicationVersion,databaseId:$databaseId,databaseTypeId:$databaseTypeId){
            items
        }
    }
}
`);

export const SEARCH_FIELDSTABLE = gql(`
query(
    $applicationId: Int!
    $applicationVersion: Int!
    $databaseTypeId: String!
    $databaseId: String!
    $tableNames: [String]!
    )
{
    searchQuery{
        getFieldsTable(applicationId:$applicationId,applicationVersion:$applicationVersion,databaseTypeId:$databaseTypeId,databaseId:$databaseId,tableNames:$tableNames){
            items
        }
    }
}
`);


export const PREVIEW_DATA = gql(`
query(
    $applicationId: Int!
    $applicationVersion: Int!
    $databaseTypeId: String!
    $databaseId: String!
    $querySelect: String!,
    $queryFrom: String!,
    $queryWhere: String,
    $queryGroupBy: String,
    $queryHaving: String,
    $queryOrderBy: String,
    $maxRegs: Int
    )
{
    searchQuery{
        getPreviewData(applicationId:$applicationId,applicationVersion:$applicationVersion,databaseTypeId:$databaseTypeId,databaseId:$databaseId,
            querySelect:$querySelect,queryFrom:$queryFrom,queryWhere:$queryWhere,queryGroupBy:$queryGroupBy,queryHaving:$queryHaving,queryOrderBy:$queryOrderBy,maxRegs:$maxRegs){
            items
        }
    }
}
`);

