import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import { ICatalogTypeRepository } from '../domain/ICatalogTypeRepository';

import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { GET_ALL_CATALOGTYPE, GET_CATALOGTYPE_BY_ID } from './query/catalogTypeQuery';
import { IapCatalogType } from '../domain/iapCatalogType';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class CatalogTypeApolloClientRepository implements ICatalogTypeRepository {
  private apolloClient : MyApolloClient
  private apolloClientId: string
  constructor (apolloInstance: MyApolloClient, _apolloClientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.apolloClientId = _apolloClientId
  }
  
 
  async getAll (maxregs: number | undefined): (Promise<IapCatalogType[]>) {
    return new Promise<IapCatalogType[]>(async (resolve, reject) => {
      
      const result = this.apolloClient.executeQuery(GET_ALL_CATALOGTYPE, { maxregs: maxregs }, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.CatalogTypeQuery.getAll)
        }
        catch(e) {
          reject()
        }
      });
      
    });

  }

  async getById (id: number): (Promise<IapCatalogType>) {
    return new Promise<IapCatalogType>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_CATALOGTYPE_BY_ID, { id: id}, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.CatalogTypeQuery.getById)
        }
        catch(e) {
          reject()
        }
      });
      
    });
  }
}


