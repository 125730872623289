import gql from 'graphql-tag';




export const GET_ALL_USERS_WITH_PROFILES = gql(`
    query {
        userQuery {
            getAllUserWithProfiles {            
                id
                fullName 
                userEntityProfile{
                    userId
                    entityProfileId
                    userName
                    profileType
                }
            }
        }
    }
    `);
