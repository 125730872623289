export default class CatalogDataTypeConst {
  static ANY='tipodato-any'
  static BOOL='tipodato-bool'
  static DATASOURCE='tipodato-datasource'
  static DATE='tipodato-date'
  static DATETIME='tipodato-datetime'
  static EXTERNALLINK='tipodato-elink'
  static FILE='tipodato-file'
  static FUNCTION='tipodato-func'
  static GUID='tipodato-guid'
  static IMG='tipodato-img'
  static MONEY='tipodato-money'
  static NUMB='tipodato-numb'
  static LIST ='tipodato-list'
  static PERCENT='tipodato-percent'
  static RPTLAUNCHERLINK='tipodato-rlink'
  static STRING='tipodato-string'
  static TEXT='tipodato-text'
  static TIME='tipodato-time'
  static FILEXML='tipodato-xml'
  static URL_IMG='tipodato-urlimg'
  static URL_IMG_EXT='tipodato-urlimgext'

}
