import gql from 'graphql-tag';

export const GET_ALL_BY_COMPONENTID = gql(`
 query($componentId: Int!) {
  componentEventQuery {
        getAllByComponentId(componentId:$componentId) {
            id
            name
            description
            idEventType
            componentId
            group
            active
            fcr
            ucr
            uum
            fum      
      }
    }
  }
`);


export const GET_EVENT_BY_ID = gql(`
query($eventId: Int!) {
  componentEventQuery {
        getById(eventId:$eventId) {
            id
            name
            description
            idEventType
            componentId
            group
            active
            fcr
            ucr
            uum
            fum      
      }
    }
  }
`);
