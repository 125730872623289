import { ClassicPreset  } from 'rete';
import { DataflowEngine } from "rete-engine";
import { Schemes } from '../../rete/helperRete';
import { MessageService } from '../../../../../../../../common/infrastructure/servicios';
import { MessageType } from '../../../../../../../../common/infrastructure/servicios/MessageService';

export class LogNode extends ClassicPreset.Node<
  { exec: ClassicPreset.Socket; message: ClassicPreset.Socket },
  { exec: ClassicPreset.Socket },
  {}
> {
  width = 180;
  height = 150;

  constructor(
    private socket:ClassicPreset.Socket,
    private log: (text: string) => void,
    private dataflow: DataflowEngine<Schemes>
  ) {
    super("Log");

    this.addInput("exec", new ClassicPreset.Input(socket, "Exec", true));
    this.addInput("message", new ClassicPreset.Input(socket, "Text"));
    this.addOutput("exec", new ClassicPreset.Output(socket, "Exec"));
  }

  async execute(input: "exec", forward: (output: "exec") => void) {
    const inputs = (await this.dataflow.fetchInputs(this.id)) as {
      message: string[];
    };

    MessageService.showToast(MessageType.Info, '', (inputs.message && inputs.message[0]) || "");
    //this.log((inputs.message && inputs.message[0]) || "");

    forward("exec");
  }

  data() {
    return {};
  }
}