/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
import CatalogFnAggregateType from '../../../catalog/domain/const/CatalogFnAggregateType';


export default function CrudTableAggregateOperationsComp(
) {

    /*
            static AVG='fnaggregate-avg'
            static COUNT='fnaggregate-count'
            static MAX='fnaggregate-max'
            static MIN='fnaggregate-min'
            static SUM='fnaggregate-sum'
    */
    const resolveAggregate = (idType: string, data: any, columnName: any) => {
        
        const _data = data.map((x:any) => x[columnName])
        switch (idType) {
            case CatalogFnAggregateType.COUNT:
                
                return [...new Set(_data)].length
                break;
            case CatalogFnAggregateType.MAX:
                return Math.max(_data)
                break;
            case CatalogFnAggregateType.MIN:
                return Math.min(_data)
                break;
            case CatalogFnAggregateType.SUM:
            //@ts-ignore:disable-next-line    
                return _data.flatMap((num:any) => num).reduce((a, b) => (a ?? 0) + (b ?? 0))
                break;
            case CatalogFnAggregateType.AVG:
            //@ts-ignore:disable-next-line    
            const total = [...new Set(_data)].length;
            //@ts-ignore:disable-next-line    
            const sum = _data.flatMap(num => num).reduce((a, b) => (a ?? 0) + (b ?? 0));
                return sum / total
                break;
            default:
                break;
        }

        return '';

    }




    return {
        resolveAggregate,
    };
}