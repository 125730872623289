import gql from 'graphql-tag';

export const GET_ALL_APPLICATIONS = gql(`
query($maxregs: Int) {
    applicationQuery {
      getAll(maxregs:$maxregs) {
        id,
        version,
        name,
        active,
        description,
        appVersion,
        shared,
        fcr,
        ucr,
        uum,
        fum
      }
    }
}
`);

export const GET_APPLICATION_BY_ID = gql(`
query($id: Int, $version: Int) {
    applicationQuery {
      getById(id:$id, version:$version) {
        id,
        version,
        name,
        active,
        description,
        appVersion,
        shared,
        fcr,
        ucr,
        uum,
        fum
      }
    }
}
`);


export const GET_INITIAL_DATA = gql(`
query{
    applicationQuery{
        getApplicationData{
            applications{
                  id,
                  version,
                  name,
                  active,
                  description,
                  appVersion,
                  shared,
                  fcr,
                  ucr,
                  uum,
                  fum
            },             
            componentAttributeTemplates{
                id
                idDataType
                idAttributeType
                idType
                localizable
                name
            },
            componentEventTemplates{
                defaultValue
                description
                id
                idDataType
                idType
                name
                order
            }
        }
    
    }
}
`);

export const GET_APPLICATION_CATALOGS = gql(`
query($id: Int, $version: Int) {
    applicationQuery {
      getApplicationCatalogs(id:$id, version:$version) {
                description
                editable
                id
                shortDescription
                iapCatalogs
                {
                  id
                  active
                  additionalValue
                  description
                  idIcon
                  order
                  parentId
                  typeId
                  value
                }
      }
    }
}
`);

export const GET_APPLICATION_CATALOG_TYPES = gql(`
query($id: Int, $version: Int) {
    applicationQuery {
      getApplicationCatalogTypes(id:$id, version:$version) {
                description
                editable
                id
                shortDescription
                localizable
               
      }
    }
}
`);

export const GET_APPLICATION_CATALOG_TYPES_BY_TYPES = gql(`
query($id: Int, $version: Int,$typeIds:[String]!) {
    applicationQuery {
      getApplicationCatalogTypes(id:$id, version:$version, typeIds:$typeIds) {
                description
                editable
                id
                shortDescription
                localizable
                {
                  id
                  active
                  additionalValue
                  description
                  idIcon
                  order
                  parentId
                  typeId
                  value
                }
               
      }
    }
}
`);



