import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';

import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { GET_ALL_WORKFLOWACTIVITY, GET_WORKFLOWACTIVITY_BY_ID } from './query/workFlowActivityQuery';
import { ADD, UPDATE, DELETE } from './mutation/workFlowActivityMutation';
import { IWorkFlowActivityRepository } from '../domain/repositorry/IWorkFlowActivityRepository';
import { IapWorkFlowActivity } from '../domain/service/iapWorkFlowActivity';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';





@injectable()
export class WorkFlowActivityApolloClientRepository implements IWorkFlowActivityRepository {
  private apolloClient : MyApolloClient
  private apolloClientId: string
  constructor (apolloInstance: MyApolloClient, _apolloClientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.apolloClientId = _apolloClientId
  }
  
  

  async update (dataInput: IapWorkFlowActivity): (Promise<IapWorkFlowActivity>) {
    return new Promise<IapWorkFlowActivity>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE, { data:dataInput }, this.apolloClientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.workFlowActivityMutation.updateWorkFlowActivity)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async delete (dataInput: IapWorkFlowActivity): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(DELETE, { data:dataInput }, this.apolloClientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.workFlowActivityMutation.deleteWorkFlowActivity)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async add (dataInput: IapWorkFlowActivity): (Promise<IapWorkFlowActivity>) {
    return new Promise<IapWorkFlowActivity>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD, { data:dataInput }, this.apolloClientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.workFlowActivityMutation.addWorkFlowActivity)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async getAll (maxregs: number | undefined): (Promise<IapWorkFlowActivity[]>) {
    return new Promise<IapWorkFlowActivity[]>(async (resolve, reject) => {
      
      const result = this.apolloClient.executeQuery(GET_ALL_WORKFLOWACTIVITY, { maxregs: maxregs }, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.WorkFlowQuery.getAll)
        }
        catch(e) {
          reject()
        }
      });
      
    });

  }

  async getById (id: number): (Promise<IapWorkFlowActivity>) {
    return new Promise<IapWorkFlowActivity>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_WORKFLOWACTIVITY_BY_ID, { id: id}, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.WorkFlowQuery.getById)
        }
        catch(e) {
          reject()
        }
      });
      
    });
  }

}


