
<template>
    <table style=" border: 1px solid black; border-collapse: collapse;" >
        <thead>
            <tr>
                <th v-for="(name,index) in header" :key="'h_' + index" scope="col" style=" border: 1px solid black; border-collapse: collapse;">
                    {{name }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(row,index) in rows" :key="'tr_' + index">
                <td v-for="(r,index) in row" :key="'td_' + index" style=" border: 1px solid black; border-collapse: collapse;">{{r}}</td>
            </tr>
        </tbody>
    </table>

</template>
<script lang="ts">

import { defineComponent, onMounted } from 'vue';

export default defineComponent({
    name: 'htmltable',
    props: ['header', 'rows'],
    emits: ['init'],
    setup(props, context) {

        onMounted(() => {
            context.emit('init');
        })
        return {

        };
    },
});
</script>
<style scoped >
</style>
