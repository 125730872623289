<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
            
        <Dropdown :key="componentKey" v-model="$i18n.locale" :options="languages" optionValue="id" optionLabel="description" filter showClear/>
</template>
<script lang="ts">

import { defineComponent, onMounted, computed, onUnmounted } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';
import SummaryDebug from '../../shared/SummaryDebug.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
    name: 'dynamiclanguage',
    components: {
        SummaryDebug    
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },       
        slotProps: {
            type: Object,
            default: () => ({})
        },
    },
    setup(props, context) {
        const router = useRouter();
        const store = useStore();

        const languages = [
            {
                id: 'es',
                description: 'ES'
            },
            {
                id: 'en',
                description: 'EN'
            }
        ]

        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded, resolveExpressions, baseOnUnmount
            ,fieldKey,fieldKeyComponentDataSourceId ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);

      
        onUnmounted(() => {
            baseOnUnmount();
        })


        return {
            BaseControlTypeConst,
            vmodel,
            getPropertyBooleanValue,
            languages
            ,componentKey
            ,loaded
        };
    },
});
</script>
<style scoped></style>
