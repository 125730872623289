import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_Chart = _resolveComponent("Chart")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.getPropertyBooleanValue(_ctx.ChartCircTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.ChartCircTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? _withDirectives((_openBlock(), _createBlock(_component_Chart, {
          key: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.ChartCircTypeConst.ID),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.ChartCircTypeConst.STYLE)),
          class: _normalizeClass({ [_ctx.getPropertyValue(_ctx.ChartCircTypeConst.CLASS) ?? '']: true }),
          name: _ctx.getPropertyValue(_ctx.ChartCircTypeConst.NAME),
          disabled: _ctx.getPropertyBooleanValue(_ctx.ChartCircTypeConst.DISABLED),
          visible: _ctx.getPropertyBooleanValue(_ctx.ChartCircTypeConst.VISIBLE),
          placeholder: _ctx.getPropertyValue(_ctx.ChartCircTypeConst.PLACEHOLDER),
          type: _ctx.getCatalogValue(_ctx.ChartCircTypeConst.TYPE),
          plugins: _ctx.getPropertyValue(_ctx.ChartCircTypeConst.PLUGINS),
          width: _ctx.getPropertyNumberValue(_ctx.ChartCircTypeConst.WIDTH),
          height: _ctx.getPropertyNumberValue(_ctx.ChartCircTypeConst.HEIGHT),
          data: _ctx.chartData,
          options: _ctx.chartOptions,
          modelValue: _ctx.vmodel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vmodel) = $event))
        }, {
          default: _withCtx(() => [
            _createCommentVNode(" :usePointStyle=\"getPropertyBooleanValue(ChartCircTypeConst.USEPOINTSTYLE)\"\r\n        :color=\"getPropertyValue(ChartCircTypeConst.COLOR)\"\r\n        :backgroundColor=\"getPropertyValue(ChartCircTypeConst.BACKGROUNDCOLOR)\"\r\n        :hoverBackgroundColor=\"getPropertyValue(ChartCircTypeConst.HOVERBACKGROUNDCOLOR)\"\r\n        :cutout=\"getPropertyValue(ChartCircTypeConst.CUTOUT)\" \r\n        :labelData=\"getCatalogValue(ChartTypeConst.LABELDATA)\"\r\n        ")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["id", "style", "class", "name", "disabled", "visible", "placeholder", "type", "plugins", "width", "height", "data", "options", "modelValue"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.ChartCircTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}