import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: [WorkFlowActivityVariableInputType]!){
    workFlowActivityVariableMutation{
        updateWorkFlowVariable(data:$data){
            id
            workFlowActivityId
            name
            isInput
            defaultValue
            fcr
            ucr
            uum
            fum
        }
    }
}
`);

export const ADD = gql(`
mutation($data: WorkFlowActivityVariableInputType!){
    workFlowActivityVariableMutation{
        addWorkFlowVariable(data:$data){
            id
            workFlowActivityId
            name
            isInput
            defaultValue
            fcr
            ucr
            uum
            fum
        }
    }
}
`);

