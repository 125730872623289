<template>
       <SummaryDebug v-if="getPropertyBooleanValue(CheckBoxTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <Checkbox v-if="loaded && getPropertyBooleanValue(CheckBoxTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" :key="componentKey"
        :id="getPropertyValue(CheckBoxTypeConst.ID)" v-tooltip="getPropertyValue(CheckBoxTypeConst.TOOLTIP)"
        :style="getPropertyValue(CheckBoxTypeConst.STYLE)"
        :class="{ [getPropertyValue(CheckBoxTypeConst.CLASS) ?? '']: true, 'p-invalid': v$.vmodel.$error && submitted ,'p-readonly': getPropertyBooleanValue(CheckBoxTypeConst.READONLY)}"
        :name="getPropertyValue(CheckBoxTypeConst.NAME)"
        :disabled="getPropertyBooleanValue(CheckBoxTypeConst.DISABLED) || (!canEdit)"
        :visible="getPropertyBooleanValue(CheckBoxTypeConst.VISIBLE)"
        :placeholder="getPropertyValue(CheckBoxTypeConst.PLACEHOLDER)"
        :binary="getPropertyBooleanValue(CheckBoxTypeConst.BINARY)"
        :readonly="getPropertyBooleanValue(CheckBoxTypeConst.READONLY) || !canDoOperation(ObjectGroupConst.EDIT)"
        :required="getPropertyBooleanValue(CheckBoxTypeConst.REQUIRED)"
        :tabIndex="getPropertyNumberValue(CheckBoxTypeConst.TABINDEX)" v-model="vmodel" 
        @change="processEventComponent(CatalogEventConst.CHANGE)"        
        >
    </Checkbox>
    <CustomValidate v-if="loaded && getPropertyBooleanValue(CheckBoxTypeConst.VISIBLE)" v-model:submitted="submitted" v-model:vObject="v$" />
</template>
<script lang="ts">


import { defineComponent, onMounted, ref, onBeforeUnmount, onUnmounted } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import CheckBoxTypeConst from '../../../../domain/Constants/CheckBoxTypeConst';
import { Container } from 'inversify';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import CustomValidate from '../../shared/CustomValidate.vue';
import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_check_box',
    components: {
        CustomValidate,
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },      
        slotProps: {
            type: Object,
            default: () => ({})
        },       
       
    },
    setup(props, context) {
        //

        
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue, 
            loaded, resolveExpressions, baseOnUnmount, baseOnMounted, baseOnBeforeUnmount, v$, submitted, 
            canEdit,isValidData,fieldKey,fieldKeyComponentDataSourceId,processEventComponent 
            ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);



        onMounted(() => {

            baseOnMounted();

        })

        onBeforeUnmount(() => {

            baseOnBeforeUnmount();
        })


        onUnmounted(() => {

            baseOnUnmount();

        })

        return {         
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            CheckBoxTypeConst,
            getPropertyNumberValue,
            loaded,
            v$,
            submitted,
            canEdit,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            processEventComponent,
            CatalogEventConst,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
        };
    },
});
</script>
<style scoped></style>
