import gql from 'graphql-tag';


export const DELETE = gql(`
mutation($id: Int!){
    applicationMutation{
        deleteApplication(id:$id)
    }
}
`);

export const UPDATE = gql(`
mutation($data: ApplicationInputType!){
    applicationMutation{
        updateApplication(data:$data){
            id,
            version,
            name,
            description,
            active,
            offline,
            production,
            appVersion,
            shared,
            fcr,
            ucr
        }
    }
}
`);

export const ADD = gql(`
mutation($data: ApplicationInputType!){
    applicationMutation{
        addApplication(data:$data){
            version,
            name,
            description,
            active,
            offline,
            production,
            appVersion,
            shared,
            fcr,
            ucr
        }
    }
}
`);



