import gql from "graphql-tag";

export const GET_BY_OBJECTID = gql(`
query($idObjeto: String!,$objetoId: String!) {
    expressionQuery {
        getByObjectId(idObjeto:$idObjeto,objetoId:$objetoId) {            
            id
            applicationId
            applicationVersion
            objetoId
            idObjeto
            idObjetoRoot
            objetoIdRoot
            idTypeExpression
            group
            fcr
            ucr
            uum
            fum
            iapExpressionDetails{
              id
              expressionId
              variable
              expression
              expressionTranslated
              processOrder
              localizable
              fcr
              ucr
              uum
              fum
            }
    }
  }
}
`);

export const GET_BY_ID = gql(`
query($id: ID) {
    expressionQuery {
        getById(id:$id) {
            id
            applicationId
            applicationVersion
            objetoId
            idObjeto
            objetoIdRoot
            idObjetoRoot            
            idTypeExpression
            group
            fcr
            ucr
            uum
            fum
            iapExpressionDetails{
              id
              expressionId
              variable
              expression
              expressionTranslated
              processOrder
              localizable
              fcr
              ucr
              uum
              fum
            }
    }
  }
}
`);



export const GET_BY_OBJECTTYPE = gql(`
query($idObjeto: [String!],
        $idObjetoRoot: String,
        $objetoIdRoot: String
) {
    expressionQuery {
        getByObjectType(idObjeto:$idObjeto, idObjetoRoot:$idObjetoRoot,objetoIdRoot:$objetoIdRoot) {            
            id
            applicationId
            applicationVersion
            objetoId
            idObjeto
            objetoIdRoot
            idObjetoRoot                        
            idTypeExpression
            group
            fcr
            ucr
            uum
            fum
            iapExpressionDetails{
               id
            }
    }
  }
}
`);
