import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IObjectDataSourceFieldConfigurationRepository } from '../domain/IObjectDataSourceFieldConfigurationRepository';

import { IServiceObjectDataSourceFieldConfiguration } from './IServiceObjectDataSourceFieldConfiguration';
import { IapObjectDataSourceFieldConfiguration } from '../domain/iapObjectDataSourceFieldConfiguration';


@injectable()
export class ObjectDataSourceFieldConfigurationService implements IServiceObjectDataSourceFieldConfiguration {
    private readonly repo: IObjectDataSourceFieldConfigurationRepository

    public constructor(@inject(TYPES.OBJECTDATASOURCEFIELDCONFIGURATION_REPOSITORY) _repo: IObjectDataSourceFieldConfigurationRepository) {
        this.repo = _repo
    }

    async update(data: IapObjectDataSourceFieldConfiguration): (Promise<IapObjectDataSourceFieldConfiguration>) {
        return await this.repo.update(data);
    }

    async getAll(maxregs: number | undefined): Promise<IapObjectDataSourceFieldConfiguration[]> {
        return await this.repo.getAll(maxregs)
    }

    async add(data: IapObjectDataSourceFieldConfiguration): (Promise<IapObjectDataSourceFieldConfiguration>) {
        return await this.repo.add(data);
    }

    async getByObjectId (idObject: string, objectId:number):Promise<IapObjectDataSourceFieldConfiguration[]> {
        return await this.repo.getByObjectId(idObject,objectId)
      }


}
