import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_CustomValidate = _resolveComponent("CustomValidate")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? _withDirectives((_openBlock(), _createBlock(_component_InputNumber, {
          key: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.InputNumberTypeConst.ID),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.InputNumberTypeConst.STYLE)),
          class: _normalizeClass({ [_ctx.getPropertyValue(_ctx.InputNumberTypeConst.CLASS) ?? '']: true, 'p-invalid': _ctx.v$.vmodel.$error && _ctx.submitted,'p-readonly': _ctx.getPropertyBooleanValue(_ctx.InputNumberTypeConst.READONLY) }),
          name: _ctx.getPropertyValue(_ctx.InputNumberTypeConst.NAME),
          disabled: _ctx.getPropertyBooleanValue(_ctx.InputNumberTypeConst.DISABLED) || (!_ctx.canEdit),
          visible: _ctx.getPropertyBooleanValue(_ctx.InputNumberTypeConst.VISIBLE),
          placeholder: _ctx.getPropertyValue(_ctx.InputNumberTypeConst.PLACEHOLDER),
          format: _ctx.getPropertyBooleanValue(_ctx.InputNumberTypeConst.FORMAT),
          showButtons: _ctx.getPropertyBooleanValue(_ctx.InputNumberTypeConst.SHOWBUTTONS),
          buttonLayout: _ctx.getCatalogValue(_ctx.InputNumberTypeConst.BUTTONLAYOUT),
          incrementButtonClass: _ctx.getPropertyValue(_ctx.InputNumberTypeConst.INCREMENTBUTTONCLASS),
          decrementButtonClass: _ctx.getPropertyValue(_ctx.InputNumberTypeConst.DECREMENTBUTTONCLASS),
          readonly: _ctx.getPropertyBooleanValue(_ctx.InputNumberTypeConst.READONLY) || !_ctx.canDoOperation(_ctx.ObjectGroupConst.EDIT),
          mode: _ctx.getCatalogValue(_ctx.InputNumberTypeConst.MODE),
          prefix: _ctx.getPropertyValue(_ctx.InputNumberTypeConst.PREFIX),
          suffix: _ctx.getPropertyValue(_ctx.InputNumberTypeConst.SUFFIX),
          minFractionDigits: _ctx.getPropertyNumberValue(_ctx.InputNumberTypeConst.MINFRACTIONDIGITS),
          maxFractionDigits: _ctx.getPropertyNumberValue(_ctx.InputNumberTypeConst.MAXFRACTIONDIGITS),
          locale: _ctx.getPropertyValue(_ctx.InputNumberTypeConst.LOCALE),
          localeMatcher: _ctx.getCatalogValue(_ctx.InputNumberTypeConst.LOCALEMATCHER),
          currency: _ctx.getPropertyValue(_ctx.InputNumberTypeConst.CURRENCY),
          currencyDisplay: _ctx.getCatalogValue(_ctx.InputNumberTypeConst.CURRENCYDISPLAY),
          useGrouping: _ctx.getPropertyBooleanValue(_ctx.InputNumberTypeConst.USEGROUPING),
          roundingMode: _ctx.getPropertyValue(_ctx.InputNumberTypeConst.ROUNDINGMODE),
          min: !_ctx.HelperCommon.isNullOrWhitespace(_ctx.getPropertyValue(_ctx.InputNumberTypeConst.MIN))?_ctx.getPropertyNumberValue(_ctx.InputNumberTypeConst.MIN):null,
          max: !_ctx.HelperCommon.isNullOrWhitespace(_ctx.getPropertyValue(_ctx.InputNumberTypeConst.MAX))?_ctx.getPropertyNumberValue(_ctx.InputNumberTypeConst.MAX):null,
          step: _ctx.getPropertyNumberValue(_ctx.InputNumberTypeConst.STEP),
          allowEmpty: _ctx.getPropertyBooleanValue(_ctx.InputNumberTypeConst.ALLOWEMPTY),
          highlightOnFocus: _ctx.getPropertyBooleanValue(_ctx.InputNumberTypeConst.HIGHLIGHTONFOCUS),
          inputId: _ctx.getPropertyValue(_ctx.InputNumberTypeConst.INPUTID),
          inputClass: _ctx.getPropertyValue(_ctx.InputNumberTypeConst.INPUTCLASS),
          inputStyle: _ctx.getPropertyValue(_ctx.InputNumberTypeConst.INPUTSTYLE),
          inputProps: _ctx.getPropertyValue(_ctx.InputNumberTypeConst.INPUTPROPS),
          incrementButtonProps: _ctx.getPropertyValue(_ctx.InputNumberTypeConst.INCREMENTBUTTONPROPS),
          decrementButtonProps: _ctx.getPropertyValue(_ctx.InputNumberTypeConst.DECREMENTBUTTONPROPS),
          ariaLabel: _ctx.getPropertyValue(_ctx.InputNumberTypeConst.ARIALABEL),
          pt: _ctx.getPropertyValue(_ctx.InputNumberTypeConst.PT),
          ptOptions: _ctx.getPropertyValue(_ctx.InputNumberTypeConst.PTOPTIONS),
          unstyled: _ctx.getPropertyBooleanValue(_ctx.InputNumberTypeConst.UNSTYLED),
          ariaLabelledby: _ctx.getPropertyValue(_ctx.InputNumberTypeConst.ARIALABELLEDBY),
          modelValue: _ctx.vmodel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vmodel) = $event))
        }, null, 8 /* PROPS */, ["id", "style", "class", "name", "disabled", "visible", "placeholder", "format", "showButtons", "buttonLayout", "incrementButtonClass", "decrementButtonClass", "readonly", "mode", "prefix", "suffix", "minFractionDigits", "maxFractionDigits", "locale", "localeMatcher", "currency", "currencyDisplay", "useGrouping", "roundingMode", "min", "max", "step", "allowEmpty", "highlightOnFocus", "inputId", "inputClass", "inputStyle", "inputProps", "incrementButtonProps", "decrementButtonProps", "ariaLabel", "pt", "ptOptions", "unstyled", "ariaLabelledby", "modelValue"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.InputNumberTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? (_openBlock(), _createBlock(_component_CustomValidate, {
          key: 2,
          submitted: _ctx.submitted,
          "onUpdate:submitted": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.submitted) = $event)),
          vObject: _ctx.v$,
          "onUpdate:vObject": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$) = $event))
        }, null, 8 /* PROPS */, ["submitted", "vObject"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}