export default class FilterControlTypeConst {

    static FILTERCONTROLTYPE_CB='filtercontroltype-cb'
    static FILTERCONTROLTYPE_DATE='filtercontroltype-date'
    static FILTERCONTROLTYPE_DD='filtercontroltype-dd'
    static FILTERCONTROLTYPE_IT='filtercontroltype-it'
    static FILTERCONTROLTYPE_MS='filtercontroltype-ms'
    static FILTERCONTROLTYPE_IN='filtercontroltype-in'
    static FILTERCONTROLTYPE_TS='filtercontroltype-ts'


}