<template>
     
</template>
<script lang="ts">

import { defineComponent} from 'vue';

export default defineComponent({
    name: 'search_details_header',
    props:{
   
    },
    setup() {
        
        return {
            
        };
    },
});
</script>
<style scoped></style>
