import gql from 'graphql-tag';


export const REFRESH = gql(`
mutation($applicationId: Int!, $applicationVersion: Int!,$typeId: String!,$id:String){
    cacheMutation{
        updateCache(applicationId:$applicationId,applicationVersion:$applicationVersion,typeId:$typeId,id:$id)
    }
}
`);



