<template>
       <SummaryDebug v-if="getPropertyBooleanValue(MenuItemTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"

            />
    <div v-if="loaded && getPropertyBooleanValue(MenuItemTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"         
        :id="getPropertyValue(MenuItemTypeConst.ID)" v-tooltip="getPropertyValue(MenuItemTypeConst.TOOLTIP)"
        :style="getPropertyValue(MenuItemTypeConst.STYLE)" :class="{ [getPropertyValue(MenuItemTypeConst.CLASS) ?? '']: true }"
        :name="getPropertyValue(MenuItemTypeConst.NAME)" :placeholder="getPropertyValue(MenuItemTypeConst.PLACEHOLDER)"
        :label="getPropertyValue(MenuItemTypeConst.LABEL)" :icon="getPropertyValue(MenuItemTypeConst.ICON)"
        :to="getPropertyValue(MenuItemTypeConst.TO)" :command="getPropertyValue(MenuItemTypeConst.COMMAND)"
        :url="getPropertyValue(MenuItemTypeConst.URL)" :items="getPropertyValue(MenuItemTypeConst.ITEMS)"
        :disabled="getPropertyBooleanValue(MenuItemTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(MenuItemTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" :target="getPropertyValue(MenuItemTypeConst.TARGET)"
        :separator="getPropertyBooleanValue(MenuItemTypeConst.SEPARATOR)" 
        :key="getPropertyValue(MenuItemTypeConst.KEY)"
        >
    </div>
</template>
<script lang="ts">


import { defineComponent, onMounted, ref, onBeforeUnmount, onUnmounted } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import MenuItemTypeConst from '../../../../domain/Constants/MenuItemTypeConst';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_menu_item',
    components: {
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },       
        slotProps: {
            type: Object,
            default: () => ({})
        },
    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue, loaded, baseOnMounted, baseOnBeforeUnmount, baseOnUnmount
            ,fieldKey,fieldKeyComponentDataSourceId
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);


    

        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })

        onUnmounted(() => {
            baseOnUnmount();
        })

        return {

            getPropertyValue,
            getPropertyBooleanValue,
            getPropertyNumberValue,
            vmodel,
            MenuItemTypeConst,
            loaded,
            BaseControlTypeConst,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            

        };
    },
});
</script>
<style scoped></style>
