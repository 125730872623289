import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { ICatalogTypeRepository } from '../domain/ICatalogTypeRepository';

import { IServiceCatalogType } from './IServiceCatalogType';
import { IapCatalogType } from '../domain/iapCatalogType';





@injectable()
export class CatalogTypeService implements IServiceCatalogType {
  private readonly repo: ICatalogTypeRepository

  public constructor (@inject(TYPES.CATALOGTYPE_REPOSITORY) _repo: ICatalogTypeRepository) {
    this.repo = _repo
  }

   async getAll(maxregs: number | undefined) :Promise<IapCatalogType[]> {
    return await this.repo.getAll(maxregs)
  }

  async getById(id: number, version: number) :Promise<IapCatalogType> {
    return await this.repo.getById(id, version)
  }
}
