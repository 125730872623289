import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IDataSourceMigrationLogRepository } from '../domain/IDataSourceMigrationLogRepository';

import { IServiceDataSourceMigrationLog } from './IServiceDataSourceMigrationLog';
import { IapDataSourceMigrationLog } from '../domain/iapDataSourceMigrationLog';





@injectable()
export class DataSourceMigrationLogService implements IServiceDataSourceMigrationLog {
  private readonly repo: IDataSourceMigrationLogRepository

  public constructor (@inject(TYPES.DATASOURCE_MIGRATION_LOG_REPOSITORY) _repo: IDataSourceMigrationLogRepository) {
    this.repo = _repo
  }

  async update (data: IapDataSourceMigrationLog): (Promise<boolean>) {
    return await this.repo.update(data);
  }

  async add (data: IapDataSourceMigrationLog): (Promise<boolean>) {
    return await this.repo.add(data);
  }

}
