import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_Knob = _resolveComponent("Knob")!
  const _component_CustomValidate = _resolveComponent("CustomValidate")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? _withDirectives((_openBlock(), _createBlock(_component_Knob, {
          key: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.KnobTypeConst.ID),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.KnobTypeConst.STYLE)),
          class: _normalizeClass({ [_ctx.getPropertyValue(_ctx.KnobTypeConst.CLASS) ?? '']: true, 'p-invalid': _ctx.v$.vmodel.$error && _ctx.submitted, 'p-readonly': _ctx.getPropertyBooleanValue(_ctx.KnobTypeConst.READONLY) }),
          name: _ctx.getPropertyValue(_ctx.KnobTypeConst.NAME),
          disabled: _ctx.getPropertyBooleanValue(_ctx.KnobTypeConst.DISABLED) || (!_ctx.canEdit),
          visible: _ctx.getPropertyBooleanValue(_ctx.KnobTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW),
          placeholder: _ctx.getPropertyValue(_ctx.KnobTypeConst.PLACEHOLDER),
          size: _ctx.getPropertyNumberValue(_ctx.KnobTypeConst.SIZE),
          readonly: _ctx.getPropertyBooleanValue(_ctx.KnobTypeConst.READONLY) || !_ctx.canDoOperation(_ctx.ObjectGroupConst.EDIT),
          step: _ctx.getPropertyNumberValue(_ctx.KnobTypeConst.STEP),
          min: _ctx.getPropertyNumberValue(_ctx.KnobTypeConst.MIN),
          max: _ctx.getPropertyNumberValue(_ctx.KnobTypeConst.MAX),
          valueColor: _ctx.getPropertyValue(_ctx.KnobTypeConst.VALUECOLOR),
          rangeColor: _ctx.getPropertyValue(_ctx.KnobTypeConst.RANGECOLOR),
          textColor: _ctx.getPropertyValue(_ctx.KnobTypeConst.TEXTCOLOR),
          strokeWidth: _ctx.getPropertyNumberValue(_ctx.KnobTypeConst.STROKEWIDTH),
          showValue: _ctx.getPropertyBooleanValue(_ctx.KnobTypeConst.SHOWVALUE),
          valueTemplate: _ctx.getPropertyValue(_ctx.KnobTypeConst.VALUETEMPLATE),
          tabindex: _ctx.getPropertyNumberValue(_ctx.KnobTypeConst.TABINDEX),
          "aria-labelledby": _ctx.getPropertyValue(_ctx.KnobTypeConst.ARIA_LABELLEDBY),
          "aria-label": _ctx.getPropertyValue(_ctx.KnobTypeConst.ARIA_LABEL),
          unstyled: _ctx.getPropertyBooleanValue(_ctx.KnobTypeConst.UNSTYLED),
          modelValue: _ctx.vmodel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vmodel) = $event))
        }, null, 8 /* PROPS */, ["id", "style", "class", "name", "disabled", "visible", "placeholder", "size", "readonly", "step", "min", "max", "valueColor", "rangeColor", "textColor", "strokeWidth", "showValue", "valueTemplate", "tabindex", "aria-labelledby", "aria-label", "unstyled", "modelValue"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.KnobTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE))
      ? (_openBlock(), _createBlock(_component_CustomValidate, {
          key: 2,
          submitted: _ctx.submitted,
          "onUpdate:submitted": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.submitted) = $event)),
          vObject: _ctx.v$,
          "onUpdate:vObject": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$) = $event))
        }, null, 8 /* PROPS */, ["submitted", "vObject"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}