export default class CatalogCfgUbmChatConst {
 
    static CFGUBMCHAT_ASEMAIL='cfgubmchat-asemail'
    static CFGUBMCHAT_ASLASTNAME='cfgubmchat-aslastname'
    static CFGUBMCHAT_ASNAME='cfgubmchat-asname'
    static CFGUBMCHAT_CIA='cfgubmchat-cia'
    static CFGUBMCHAT_ENABLE='cfgubmchat-enable'
    static CFGUBMCHAT_MIN='cfgubmchat-min'
    static CFGUBMCHAT_SOURCE='cfgubmchat-source'
    static CFGUBMCHAT_SUL='cfgubmchat-sul'
    static CFGUBMCHAT_UNIT='cfgubmchat-unit'
    
    }
    