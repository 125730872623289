
/* eslint-disable @typescript-eslint/no-unused-vars */
import { computed, ref, inject } from 'vue'
import { useStore } from 'vuex'
import { Container } from 'inversify';
import Environment from '../../../../../common/infrastructure/funciones/environment'
import { appConfig } from "../../domain/appConfig";
import Button from 'primevue/button'
import MessageService, { MessageType } from '@ilinium/shared/src/common/infrastructure/servicios/MessageService';
import HelperCommon from '@ilinium/shared/src/common/infrastructure/funciones/HelperCommon';
import { helpers, required } from "@vuelidate/validators";
import { resetPass } from "../../domain/resetPass";
import useVuelidate from "@vuelidate/core";
import HelperLoading from "../../../../../common/infrastructure/funciones/HelperLoading";
import {
  Actions,
  Mutations,
} from "../../../../../common/infrastructure/almacen/enums/StoreEnums";

const __default__ = {
  components: { Button },
  name: 'ResetPass',
  props:
    {
        container: {
            type: Object as () => Container
        },
        getLogoUrl: {
            type: String,
            default: ''
        },
        appCfg: {
          type: Object as () => appConfig
        },
        rid: {
            type: String,
            default: '0'
        },
    },
  emits: ['isResetPassChild'],
  setup (props, { emit }) {
    
    const store = useStore()
    const passwordControl = ref < HTMLElement | null > (null)
    const passwordConfirmControl = ref < HTMLElement | null > (null)
    const password = ref('')
    const confirmpass = ref('')
    const ipublicPath = inject('publicPath')
    const submitted = ref(false);
    
    let my_color1 = props.appCfg.color1;
    let my_color2 = props.appCfg.color2;
    let rid = computed(() => props.rid.replace('rid=', ''));
    
    /* const user_req: Partial<Usuario> = {
            email: ''
        };

        const request = ref<forgetPasswordRequest>({
            user: user_req as any,
            remoteApplication: 'Portal',
            remoteIp: '',
            requestedUrl: '',
        }); */

    const back = (event: any) => {
      
      emit('isResetPassChild', false);
    }

    const isValidResetPassData = (): boolean => {
      submitted.value = true;
      v$.value.$touch();
      if (v$.value.$invalid) {
        HelperCommon.setFocusOnFirstError();
        return false;
      }

      return true;
    };

    const onSubmitResetPass = () => {
      if (isValidResetPassData()) {
        store.dispatch(Actions.RESET_PASSWORD, [request.value.password, rid.value, Environment.APPID, Environment.APPVERSION, props.container]).then(() => {
            MessageService.showToast(MessageType.Correcto, '', 'Ha sido modificada la contraseña de la cuenta en nuestro sistema correctamente.');
            emit('isResetPassChild', false);
            //router.push({ name: 'forgotpassword' });
        }).finally(() => {
            HelperLoading.hideLoading();
        });
      }
    }

    const getLogoUrl = computed(() => {
      return Environment.URL_LOGO_PNG;      
    })

    const request = ref<resetPass>({
      email: "",
      emailconfirm: "",
      password: "",
      confirmpass: ""
    });

    const customPassword = (value: string) => {
      if (value.length < 5) {
        return false;
      }
      // Todo:
      return true;
    };

    const rules = {
      password: {
        required: helpers.withMessage("La contraseña es obligatoria", required),
        customPassword: helpers.withMessage("Contraseña no válida", customPassword)
      },
      confirmpass: {
        required: helpers.withMessage("Confirmar la contraseña es obligatorio", required),
        customPassword: helpers.withMessage("Contraseña no válida", customPassword),
      },
    };

    const v$ = useVuelidate(rules, request);

    return {
      passwordControl,
      passwordConfirmControl,
      confirmpass,
      password,
      onSubmitResetPass,
      getLogoUrl,
      ipublicPath,
      my_color1,
      my_color2,
      back,
      v$,
      submitted,
      request,
    }
  }
}

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "606035fc-my_color1": (_ctx.my_color1),
  "606035fc-my_color2": (_ctx.my_color2)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__