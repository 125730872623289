/* eslint-disable @typescript-eslint/no-extraneous-class */

export default class DataBaseTypeConst {
  static AUDITORIA = "tipobd-AU";
  static DOCUMENTOS_MODEL = "tipobd-DO";
  static DOCUMENTOS_MASTER = "tipobd-DOMA";
  static LOG = "tipobd-LO";
  static MAESTRO = "tipobd-MA";
  static MODELO = "tipobd-MO";
  static SOLICITUDES = "tipobd-SO";
  static PASS = "tipobd-pa";
  static EIAC = "tipobd-EI";
  static MOTORWEB = "tipobd-MW";
  static BUILDER = "tipobd-BU";
}
