import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_Button = _resolveComponent("Button")!
  const _component_AppConfig = _resolveComponent("AppConfig")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_Button, {
      icon: "pi pi-cog",
      style: {"color":"var(--primary-color)"},
      text: "",
      rounded: "",
      severity: "secondary",
      onClick: _ctx.onConfigButtonClick
    }, null, 8 /* PROPS */, ["onClick"]),
    (_openBlock(), _createBlock(_component_AppConfig, {
      key: _ctx.componentKey,
      templateConfig: _ctx.templateConfig,
      templateSimple: _ctx.templateSimple
    }, null, 8 /* PROPS */, ["templateConfig", "templateSimple"]))
  ], 64 /* STABLE_FRAGMENT */))
}