<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <DataTable v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" :key="componentKey"
        :id="getPropertyValue(DataTableTypeConst.ID)" v-tooltip="getPropertyValue(DataTableTypeConst.TOOLTIP)"
        :style="getPropertyValue(DataTableTypeConst.STYLE)" :class="getPropertyValue(DataTableTypeConst.CLASS)"
        :name="getPropertyValue(DataTableTypeConst.NAME)" :disabled="getPropertyBooleanValue(DataTableTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(DataTableTypeConst.VISIBLE)"
        :placeholder="getPropertyValue(DataTableTypeConst.PLACEHOLDER)" :value="getPropertyValue(DataTableTypeConst.VALUE)"
        :dataKey="getPropertyValue(DataTableTypeConst.DATAKEY)" :rows="getPropertyNumberValue(DataTableTypeConst.ROWS)"
        :first="getPropertyNumberValue(DataTableTypeConst.FIRST)"
        :totalRecords="getPropertyNumberValue(DataTableTypeConst.TOTALRECORDS)"
        :paginator="getPropertyBooleanValue(DataTableTypeConst.PAGINATOR)"
        :paginatorPosition="getCatalogValue(DataTableTypeConst.PAGINATORPOSITION)"
        :alwaysShowPaginator="getPropertyBooleanValue(DataTableTypeConst.ALWAYSSHOWPAGINATOR)"
        :paginatorTemplate="getPropertyValue(DataTableTypeConst.PAGINATORTEMPLATE)"
        :pageLinkSize="getPropertyNumberValue(DataTableTypeConst.PAGELINKSIZE)"
        :rowsPerPageOptions="getPropertyNumberValue(DataTableTypeConst.ROWSPERPAGEOPTIONS)"
        :currentPageReportTemplate="getPropertyValue(DataTableTypeConst.CURRENTPAGEREPORTTEMPLATE)"
        :lazy="getPropertyBooleanValue(DataTableTypeConst.LAZY)"
        :loading="getPropertyBooleanValue(DataTableTypeConst.LOADING)"
        :loadingIcon="getPropertyValue(DataTableTypeConst.LOADINGICON)"
        :sortField="getPropertyValue(DataTableTypeConst.SORTFIELD)"
        :sortOrder="getPropertyNumberValue(DataTableTypeConst.SORTORDER)"
        :defaultSortOrder="getPropertyNumberValue(DataTableTypeConst.DEFAULTSORTORDER)"
        :multiSortMeta="getPropertyValue(DataTableTypeConst.MULTISORTMETA)"
        :sortMode="getCatalogValue(DataTableTypeConst.SORTMODE)"
        :removableSort="getPropertyBooleanValue(DataTableTypeConst.REMOVABLESORT)"
        :filters="getPropertyValue(DataTableTypeConst.FILTERS)"
        :filterDisplay="getCatalogValue(DataTableTypeConst.FILTERDISPLAY)"
        :globalFilterFields="getPropertyValue(DataTableTypeConst.GLOBALFILTERFIELDS)"
        :filterLocale="getPropertyValue(DataTableTypeConst.FILTERLOCALE)"
        :selection="getPropertyValue(DataTableTypeConst.SELECTION)"
        :selectionMode="getCatalogValue(DataTableTypeConst.SELECTIONMODE)"
        :compareSelectionBy="getCatalogValue(DataTableTypeConst.COMPARESELECTIONBY)"
        :metaKeySelection="getPropertyBooleanValue(DataTableTypeConst.METAKEYSELECTION)"
        :contextMenu="getPropertyBooleanValue(DataTableTypeConst.CONTEXTMENU)"
        :contextMenuSelection="getPropertyValue(DataTableTypeConst.CONTEXTMENUSELECTION)"
        :selectAll="getPropertyValue(DataTableTypeConst.SELECTALL)"
        :rowHover="getPropertyBooleanValue(DataTableTypeConst.ROWHOVER)"
        :csvSeparator="getPropertyValue(DataTableTypeConst.CSVSEPARATOR)"
        :exportFilename="getPropertyValue(DataTableTypeConst.EXPORTFILENAME)"
        :resizableColumns="getPropertyBooleanValue(DataTableTypeConst.RESIZABLECOLUMNS)"
        :columnResizeMode="getCatalogValue(DataTableTypeConst.COLUMNRESIZEMODE)"
        :reorderableColumns="getPropertyBooleanValue(DataTableTypeConst.REORDERABLECOLUMNS)"
        :expandedRows="getPropertyValue(DataTableTypeConst.EXPANDEDROWS)"
        :expandedRowIcon="getPropertyValue(DataTableTypeConst.EXPANDEDROWICON)"
        :collapsedRowIcon="getPropertyValue(DataTableTypeConst.COLLAPSEDROWICON)"
        :rowGroupMode="getCatalogValue(DataTableTypeConst.ROWGROUPMODE)"
        :groupRowsBy="getPropertyValue(DataTableTypeConst.GROUPROWSBY)"
        :expandableRowGroups="getPropertyBooleanValue(DataTableTypeConst.EXPANDABLEROWGROUPS)"
        :expandedRowGroups="getPropertyValue(DataTableTypeConst.EXPANDEDROWGROUPS)"
        :stateStorage="getCatalogValue(DataTableTypeConst.STATESTORAGE)"
        :stateKey="getPropertyValue(DataTableTypeConst.STATEKEY)" :editMode="getCatalogValue(DataTableTypeConst.EDITMODE)"
        :editingRows="getPropertyValue(DataTableTypeConst.EDITINGROWS)"
        :rowClass="getPropertyValue(DataTableTypeConst.ROWCLASS)" :rowStyle="getPropertyValue(DataTableTypeConst.ROWSTYLE)"
        :scrollable="getPropertyBooleanValue(DataTableTypeConst.SCROLLABLE)"
        :scrollHeight="getPropertyValue(DataTableTypeConst.SCROLLHEIGHT)"
        :virtualScrollerOptions="getPropertyValue(DataTableTypeConst.VIRTUALSCROLLEROPTIONS)"
        :frozenValue="getPropertyValue(DataTableTypeConst.FROZENVALUE)"
        :breakpoint="getPropertyValue(DataTableTypeConst.BREAKPOINT)"
        :showGridlines="getPropertyBooleanValue(DataTableTypeConst.SHOWGRIDLINES)"
        :stripedRows="getPropertyBooleanValue(DataTableTypeConst.STRIPEDROWS)"
        :size="getCatalogValue(DataTableTypeConst.SIZE)" :tableStyle="getPropertyValue(DataTableTypeConst.TABLESTYLE)"
        :tableClass="getPropertyValue(DataTableTypeConst.TABLECLASS)"
        :tableProps="getPropertyValue(DataTableTypeConst.TABLEPROPS)"
        :filterInputProps="getPropertyValue(DataTableTypeConst.FILTERINPUTPROPS)"
        :pt="getPropertyValue(DataTableTypeConst.PT)" :unstyled="getPropertyBooleanValue(DataTableTypeConst.UNSTYLED)"
        v-model="vmodel">
    </DataTable>
</template>
<script lang="ts">


import { defineComponent, onMounted,onUnmounted } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import DataTableTypeConst from '../../../../domain/Constants/DataTableTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_datatable',
    components: {
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
      
        slotProps: {
            type: Object,
            default: () => ({})
        },
      

    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, loaded, getPropertyValue, getPropertyBooleanValue, 
            getPropertyNumberValue, resolveExpressions, getCatalogValue, baseOnUnmount,
            fieldKey,fieldKeyComponentDataSourceId,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);



        onMounted(() => {
            // resolveExpressions();  

        })

        onUnmounted(() => {
            baseOnUnmount();
        })


        return {
            getPropertyValue,
            getPropertyBooleanValue,
            getPropertyNumberValue,
            vmodel,
            DataTableTypeConst,
            loaded,
            BaseControlTypeConst,
            getCatalogValue,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
        };
    },
});
</script>
<style scoped></style>
