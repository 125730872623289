<template>
       <SummaryDebug v-if="getPropertyBooleanValue(DataViewTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <DataView v-if="loaded && getPropertyBooleanValue(DataViewTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" :key="componentKey"
        :value="getPropertyValue(DataViewTypeConst.VALUE)" :layout="getCatalogValue(DataViewTypeConst.LAYOUT)"
        :rows="getPropertyNumberValue(DataViewTypeConst.ROWS)" :first="getPropertyNumberValue(DataViewTypeConst.FIRST)"
        :totalRecords="getPropertyNumberValue(DataViewTypeConst.TOTALRECORDS)"
        :paginator="getPropertyBooleanValue(DataViewTypeConst.PAGINATOR)"
        :paginatorPosition="getCatalogValue(DataViewTypeConst.PAGINATORPOSITION)"
        :alwaysShowPaginator="getPropertyBooleanValue(DataViewTypeConst.ALWAYSSHOWPAGINATOR)"
        :paginatorTemplate="getPropertyValue(DataViewTypeConst.PAGINATORTEMPLATE)"
        :pageLinkSize="getPropertyNumberValue(DataViewTypeConst.PAGELINKSIZE)"
        :rowsPerPageOptions="getPropertyNumberValue(DataViewTypeConst.ROWSPERPAGEOPTIONS)"
        :currentPageReportTemplate="getPropertyValue(DataViewTypeConst.CURRENTPAGEREPORTTEMPLATE)"
        :sortField="getPropertyValue(DataViewTypeConst.SORTFIELD)"
        :sortOrder="getPropertyNumberValue(DataViewTypeConst.SORTORDER)"
        :lazy="getPropertyBooleanValue(DataViewTypeConst.LAZY)" :dataKey="getPropertyValue(DataViewTypeConst.DATAKEY)"
        :pt="getPropertyValue(DataViewTypeConst.PT)" :unstyled="getPropertyBooleanValue(DataViewTypeConst.UNSTYLED)">

        <template #list="slotProps">
            <slot name="list"
                :slotProps="{ data: slotProps.data, index: slotProps.index, control: ControlTypeConst.CTDATAVIEW }"></slot>
        </template>

        <template v-if="getLayoutComponent(Component,DataViewTypeConst.LAYOUT_HEADER)" #header>
            <slot name="header"></slot>
        </template>

        <template v-if="getLayoutComponent(Component,DataViewTypeConst.LAYOUT_FOOTER)" #footer>
            <slot name="footer"></slot>
        </template>

        <template v-if="getLayoutComponent(Component,DataViewTypeConst.LAYOUT_PAGINATORSTART)"  #paginatorstart>
            <slot name="paginatorstart"></slot>
        </template>

        <template v-if="getLayoutComponent(Component,DataViewTypeConst.LAYOUT_PAGINATOREND)" #paginatorend>
            <slot name="paginatorend"></slot>
        </template>

        <template v-if="getLayoutComponent(Component,DataViewTypeConst.LAYOUT_EMPTY)" #empty>
            <slot name="empty"></slot>
        </template>

        <template v-if="getLayoutComponent(Component,DataViewTypeConst.LAYOUT_GRID)" #grid="slotProps">
            <slot name="grid"
                :slotProps="{ data: slotProps.data, index: slotProps.index, control: ControlTypeConst.CTDATAVIEW }"></slot>
        </template>

    </DataView>
</template>
<script lang="ts">


import { defineComponent, onMounted, onUnmounted } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import DataViewTypeConst from '../../../../domain/Constants/DataViewTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import ControlTypeConst from '../../../../domain/Constants/ControlTypeConst';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_data_view',
    components: {
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        slotProps: {
            type: Object,
            default: () => ({})
        },
 
    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue, loaded, 
             resolveExpressions, getCatalogValue, baseOnUnmount,fieldKey,fieldKeyComponentDataSourceId,getLayoutComponent
             ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);





        onMounted(() => {
            // resolveExpressions();  
        })

        onUnmounted(() => {
            baseOnUnmount();
        })


        return {        
            getPropertyValue,
            getPropertyBooleanValue,
            getPropertyNumberValue,
            vmodel,
            loaded,
            DataViewTypeConst,
            BaseControlTypeConst,
            ControlTypeConst,
            getCatalogValue,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,getLayoutComponent
            ,componentKey

        };
    },
});
</script>
<style scoped></style>
