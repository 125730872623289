import BaseControlTypeConst from "./BaseControlTypeConst"

export default class InputMaskTypeConst extends BaseControlTypeConst {

    //static MODELVALUE = 'modelValue'  // Type:string;  Default: null  -> Value of the component.
    static SLOTCHAR = 'slotChar'      // Type:string;  Default: _     -> Placeholder character in mask, default is underscore.
    static MASK = 'mask'              // Type:string;  Default: null  -> Mask pattern.
    static AUTOCLEAR = 'autoClear'    // Type:boolean; Default: true  -> Clears the incomplete value on blur.
    static UNMASK = 'unmask'          // Type:boolean; Default: false -> Defines if model sets the raw unmasked value to bound value or the formatted mask value.
    static READONLY = 'readonly'      // Type:boolean; Default: false -> Whether the items are clickable or not.
}