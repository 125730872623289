export default class CatalogValidationTypeConst
{
    static ALPH='validation-alph'
    static ALPHNUM='validation-alphnum'
    static BEETW='validation-beetw'
    static DEC='validation-dec'
    static EM='validation-em'
    static IBAN='validation-iban'
    static INTEG='validation-integ'
    static IPADDR='validation-ipaddr'
    static MACADDR='validation-macaddr'
    static MAXL='validation-maxl'
    static MAXV='validation-maxv'
    static MINL='validation-minl'
    static MINV='validation-minv'
    static NUM='validation-num'
    static SAMEAS='validation-sameas'
    static URL='validation-url'

}