import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';

import { IDataSourceServiceRepository } from '../domain/IDataSourceServiceRepository';

import { GET_ALL_DATASOURCE, GET_DATASOURCE_BY_ID } from './query/dataSourceQuery';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { IapDataSourceService } from "../domain/iapDataSourceService";
import { CALL_WS_SOAP, CALL_WS_REST, CLASS_FROM_SWAGGER_URL, CLASS_FROM_WSDL_URL } from './mutation/dataSourceServiceMutation';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';
import { WSDL } from '../domain/wsdl';
import { Swagger } from '../domain/swagger';
import { ResultadSearch } from '../../search/domain/resultSearch';



@injectable()
export class DataSourceServiceApolloClientRepository implements IDataSourceServiceRepository {
  private apolloClient: MyApolloClient
  private clientId: String
  constructor(apolloInstance: MyApolloClient, clientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }

  
   async getAll(applicationId: number, applicationVersion: number): (Promise<IapDataSourceService[]>) {
     return new Promise<IapDataSourceService[]>(async (resolve, reject) => {
       const result = this.apolloClient.executeQuery(GET_ALL_DATASOURCE, { applicationId: applicationId, applicationVersion: applicationVersion }, this.clientId)
 
       watch(result, (value: any) => {
         try {
           HelperUtils.handleDatesApollo(value)
           resolve(value.dataSourceQuery.getAll)
         }
         catch (e) {
           reject()
         }
       });
     });
   }
 
   async getById(applicationId: number, applicationVersion: number, datasourceId: number): (Promise<IapDataSourceService>) {
     return new Promise<IapDataSourceService>(async (resolve, reject) => {
       const result = this.apolloClient.executeQuery(GET_DATASOURCE_BY_ID, { applicationId: applicationId, applicationVersion: applicationVersion, datasourceId: datasourceId }, this.clientId)
 
       watch(result, (value: any) => {
         try {
           HelperUtils.handleDatesApollo(value)
           resolve(value.dataSourceQuery.getById)
         }
         catch (e) {
           reject()
         }
       });
     });
   }
 
  async classFromWsdlUrl(applicationId: number, applicationVersion: number, dataSourceId: number, url: string): (Promise<WSDL>) {
    return new Promise<WSDL>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(CLASS_FROM_WSDL_URL, { applicationId: applicationId, applicationVersion: applicationVersion, dataSourceId: dataSourceId, url: url }, this.clientId)

      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value)
          resolve(value.dataSourceServiceMutation.classFromWsdlUrl)
        }
        catch (e) {
          reject()
        }
      });
    });
  }

  async classFromSwaggerUrl(applicationId: number, applicationVersion: number, dataSourceId: number, url: string, jsonFile: string): (Promise<Swagger>) {
    return new Promise<Swagger>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(CLASS_FROM_SWAGGER_URL, { applicationId: applicationId, applicationVersion: applicationVersion, dataSourceId: dataSourceId, url: url, jsonFile: jsonFile }, this.clientId)

      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value)
          resolve(value.dataSourceServiceMutation.classFromSwaggerUrl)
        }
        catch (e) {
          reject()
        }
      });
    });
  }

  async callWebServiceSOAP(applicationId: number, applicationVersion: number, dataSourceId: number, url: string, method: string, request: string): (Promise<string>) {
    return new Promise<string>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(CALL_WS_SOAP, { applicationId: applicationId, applicationVersion: applicationVersion, dataSourceId: dataSourceId, url: url, type: method, request: request }, this.clientId)
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value)
          resolve(value.dataSourceServiceMutation.callWebServiceSOAP)
        }
        catch (e) {
          reject()
        }
      });
    });
  }

  async callWebServiceApiRest(token: string, applicationId: number, applicationVersion: number, dataSourceId: number, userId: number, isAdmin: boolean | null, profileId: number | null, url: string, endPoint: string, type: string, body: string, params: string): (Promise<ResultadSearch>) {
    return new Promise<ResultadSearch>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(CALL_WS_REST, { token: token, applicationId: applicationId, applicationVersion: applicationVersion, dataSourceId: dataSourceId, userId: userId, isAdmin: isAdmin, profileId: profileId, url: url, endPoint: endPoint, type: type, body: body, params: params }, this.clientId)
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value)
          resolve(value.dataSourceServiceMutation.callWebServiceApiRest)
        }
        catch (e) {
          reject()
        }
      });
    });
  }

}
