import { CacheData } from '../../domain/cacheData';

export default class AppCache{
    static HOURS_CACHE:number = 8;
    static CACHE_DATA : CacheData[]=[];


    static clearCache = () =>{
       this.CACHE_DATA = [];
    }

    static generateExpiredDate = () => {
        var today = new Date();
        today.setHours(today.getHours() + this.HOURS_CACHE);
        return today;
    }

    static getCache = ( idObject:string,objectId: number) :CacheData | undefined =>{
        const _data = this.CACHE_DATA.find(x=> x.idObject == idObject && x.objectId == objectId);
        return _data;
    }
    static updateCache = ( idObject:string,objectId: number,data:Object) =>{
        const _data = this.CACHE_DATA.find(x=> x.idObject == idObject && x.objectId == objectId);
        if (_data){
            _data.data = data;
            _data.expiredDate=this.generateExpiredDate();
        }
    }
    static setCache = ( idObject:string,objectId: number,data:Object) =>{
        const _currentData = this.CACHE_DATA.find(x=> x.idObject == idObject && x.objectId == objectId);
        if (_currentData){
            this.updateCache(idObject,objectId,data);
        }
        else{
            const _data:CacheData={
                idObject:idObject,
                objectId:objectId,
                data:data,
                expiredDate:this.generateExpiredDate()
        
               }
                this.CACHE_DATA.push(_data)
        }
       
    }

    static deleteCache = ( idObject:string,objectId: number) =>{
        this.CACHE_DATA = this.CACHE_DATA.filter(x=> x.idObject != idObject && x.objectId != objectId);
    }
}