import { ClassicPreset } from 'rete';
import { AreaPlugin } from 'rete-area-plugin';
import { AreaExtra, Schemes } from '../rete/helperRete';

import { LocalService } from '../../../../../../../common/infrastructure/servicios';
import { Router } from 'vue-router';
import { Container } from 'inversify';
import { TextControl } from '../controls/textControl';
import { IapComponent } from '../../../../../component/domain/iapComponent';
import CatalogObjectTypeConst from '../../../../../catalog/domain/const/CatalogObjectTypeConst';
import { IapWorkFlowActivityControl } from '../../../../domain/service/iapWorkFlowActivityControl';
import CatalogExpConst from '../../../../../catalog/domain/const/CatalogExpConst';
import { ExpresionEngine } from '../../../../../expression/infrastructure/helper/expressionEngine';
import { IapWorkFlowActivity } from '../../../../domain/service/iapWorkFlowActivity';
import EventConst from '../../../../../../../common/domain/constantes/EventConst';
import TextNodeConst from '../constants/TextNodeConst';
import OperationDataTypeConst from '../../../../../../../common/domain/constantes/OperationDataTypeConst';
import HelperUtils from '../../../../../../../common/infrastructure/funciones/HelperUtils';

export class TextNode extends ClassicPreset.Node<
  { },
  { value: ClassicPreset.Socket },
  { value: TextControl }
> {
  height = 200;
  width = 200;

  private keyEventComponent: string;
  private variableText: string;
  private variebleTextExpression:string = '';

  private area: AreaPlugin<Schemes, AreaExtra>;
  private updateNode: any;
  private rdControlId: '';

  private getNodeInternalData: any;
  private showExpression: any;
  private router: Router;
  private container: Container | undefined;
  private rootComponentId: number;
  private currentComponentId: number;
  private store: any;
  private activity: IapWorkFlowActivity | undefined;
  private componentData: IapComponent[]
  private formKey:String;
  private currentElementKey:string;
  //constructor(area: AreaPlugin<Schemes, AreaExtra>, socket: ClassicPreset.Socket,  public applicationId: number, public applicationVersion: number, public variableComponentInput: number, public variableComponentHeaderInput: string, public variableComponentModalInput: boolean, public variableComponentParametersInput: [], updateNode: any = undefined) {
  constructor(area: AreaPlugin<Schemes, AreaExtra>, socket: ClassicPreset.Socket,public formKeyInput: String,public currentElementKeyInput:string,public rootComponentInputId: number, public currentComponentInputId: number, public applicationId: number, public applicationVersion: number, public variableTextInput: string,updateNode: any = undefined, getNodeInternalData: any = undefined, showExpressionFunction: any = undefined, router: Router, container: Container | undefined, storeInput: any, itemActivity: IapWorkFlowActivity | undefined,componentDataInput: IapComponent[]) {
    super("Text");

    this.area = area;
    this.updateNode = updateNode;
    this.variableText = variableTextInput;

    this.router = router;

    this.getNodeInternalData = getNodeInternalData;
    this.showExpression = showExpressionFunction;
    this.container = container;
    this.rootComponentId = rootComponentInputId;
    this.currentComponentId = currentComponentInputId;
    this.store = storeInput;
    this.activity = itemActivity;
    this.keyEventComponent = (rootComponentInputId ?? -1).toString() + '_' + EventConst.SHOWCOMPONENT;
    this.componentData = componentDataInput;
    this.formKey = formKeyInput;
    this.currentElementKey = currentElementKeyInput;

    const dsControl = new TextControl(formKeyInput,rootComponentInputId,currentComponentInputId,applicationId, applicationVersion, variableTextInput,container,storeInput,itemActivity, this.updateData, this.getNode, this.showExp);

    this.rdControlId = (dsControl as any).id;

    
    this.addControl(
      "value",
      dsControl
    );
    

    this.addOutput("value", new ClassicPreset.Output(socket, "Value"));

 
    this.resolveExpressions();
  }

  showExp = (evt: any) => {
    if (this.showExpression) {
      return this.showExpression(evt)
    }
    return null;
  }

  getNode = (key: string) => {
    if (this.getNodeInternalData) {
      return this.getNodeInternalData(this.id, key, true, false)
    }
    return null;
  }

  updateData = (evt: any) => {

    //this.value = evt
    //@ts-ignore:disable-next-line
    this.controls.value[evt.key] = evt.data;
    //this.controls[evt.key].valueConnection = evt.data;

    this.area.update("control", this.rdControlId)


    if (this.updateNode) {
      this.updateNode(this.id, evt.key, JSON.stringify(evt.data), (evt?.operation ?? OperationDataTypeConst.UPDATE))
    }


  }

resolveExpressions = () =>{
  
  const currentComp = this.componentData.find(x => x.id == this.currentComponentId)
  const WfData = currentComp?.workFlows?.find(w => w.id == this.activity?.workFlowId)

  this.activity?.iapWorkFlowActivityControls?.forEach((wcf: IapWorkFlowActivityControl) => {

    if (currentComp && wcf) {


      const keyControl = CatalogObjectTypeConst.EVTCOMP + '#' + WfData?.objetoId + '#' + wcf.id.toString();

      const exps = currentComp.expressions?.filter(x =>
        x.idObjeto == CatalogObjectTypeConst.WFAC
        && x.idTypeExpression == CatalogExpConst.EXP_SET
        && x.iapExpressionDetails?.length > 0
        && x.objetoId == keyControl);
      if (exps?.length > 0) {
        exps?.every(exp => {
          if (exp.iapExpressionDetails?.length > 0) {

            const localData = LocalService.getValue(this.formKey + LocalService.COMPONENTS_EXP + (this.rootComponentId ?? -1).toString());
            const data = HelperUtils.jsonParse(localData,[])
            
            let resu = ExpresionEngine.resolveExpressions(exp.iapExpressionDetails, data as any, this.store)
            //resu = resu?.toString();
            if (resu) {
              if (Object.keys(resu).length == 0) {
                resu = resu?.toString();
              }
            }

            switch (wcf.name) {
              case TextNodeConst.VAR_TEXT_IN:
                // code block
                this.variebleTextExpression = resu
                break;
  
              default:
              // code block
            }

          }
        })

      }


    }
    

  })

  //const wcf =  this.activity?.iapWorkFlowActivityControls.find(x=> x.name == DisplayComponentNodeConst.VAR_PARAM_IN);
  
}


  async execute() {
      

  }


  data(): { value: string } {
   
    return {
      value: this.variebleTextExpression || this.variableText || ""
    };
  }
}