import { ClassicPreset } from "rete";
import OperationDataTypeConst from '../../../../../../../common/domain/constantes/OperationDataTypeConst';
export class DelayNode extends ClassicPreset.Node<
  { ejecutar: ClassicPreset.Socket },
  { ejecutar: ClassicPreset.Socket, value: ClassicPreset.Socket },
  { value: ClassicPreset.InputControl<"number"> }
> {
  width = 180;
  height = 180;

  private updateNode: any;

  constructor(socket:ClassicPreset.Socket,seconds: number,updateNode: any= undefined) {
    super("Delay");
    this.updateNode = updateNode;

    this.addInput("ejecutar", new ClassicPreset.Input(socket, "Ejecutar", true));
    this.addControl(
      "value",
      new ClassicPreset.InputControl("number", { initial: seconds, change:this.updateCurrentNode })
    );
    this.addOutput("ejecutar", new ClassicPreset.Output(socket, "Ejecutar"));
    this.addOutput("value", new ClassicPreset.Output(socket, "Valor",));
  }

 
  updateCurrentNode =(newValue:any)=>{
    if (this.updateNode){
        this.updateNode(this.id,'value',newValue, OperationDataTypeConst.UPDATE)
      }   
  }

  execute(input: "ejecutar", forward: (output: "ejecutar") => void) {
    const value = this.controls.value.value;
    setTimeout(
      () => {
        forward("ejecutar");
      },
      value ? value * 1000 : 1000
    );
  }

  data(): { value: Number } {
    return {
      value: this.controls.value.value ?? -1
    };
  }
}