import gql from 'graphql-tag';


export const GETALL = gql(`
query{
    importConfigQuery{
        getAll
        {            
            id
            name
            fieldKey
            numLineHeader
            data
        }
    }
}
`);


export const GETBYID = gql(`
query($id: Int!){
    importConfigQuery{
        getById(id: $id)
        {            
            id
            name
            fieldKey
            numLineHeader
            data
        }
    }
}
`);



export const GETBYFIELDKEY = gql(`
query($fieldKey: String!){
    importConfigQuery{
        getByFieldKey(fieldKey: $fieldKey)
        {            
            id
            name
            fieldKey
            numLineHeader
            data
        }
    }
}
`);
