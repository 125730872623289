import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IDataSourceLookUpRepository } from '../domain/IDataSourceLookUpRepository';

import { IServiceDataSourceLookUp } from './IServiceDataSourceLookUp';
import { IapDataSourceLookUp } from '../domain/iapDataSourceLookUp';
import { IapObjectDataSourceFieldConfiguration } from '../domain/iapObjectDataSourceFieldConfiguration';





@injectable()
export class DataSourceLookUpService implements IServiceDataSourceLookUp {
  private readonly repo: IDataSourceLookUpRepository

  public constructor (@inject(TYPES.DATASOURCELOOKUP_REPOSITORY) _repo: IDataSourceLookUpRepository) {
    this.repo = _repo
  }
    
  async update (data: IapDataSourceLookUp,objDsfConfigData:IapObjectDataSourceFieldConfiguration[]): (Promise<IapDataSourceLookUp>) {
    return await this.repo.update(data,objDsfConfigData);
  }

  async delete (dataSourceId: number): (Promise<boolean>) {
    return await this.repo.delete(dataSourceId);
  }


  async add (data: IapDataSourceLookUp,objDsfConfigData:IapObjectDataSourceFieldConfiguration[]): (Promise<IapDataSourceLookUp>) {
    return await this.repo.add(data,objDsfConfigData);
  }

  async getById(DataSourceLookUpId: number) :Promise<IapDataSourceLookUp> {
    return await this.repo.getById(DataSourceLookUpId)
  }


}