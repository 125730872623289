import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "p-error" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.submitted)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vObject.vmodel.$errors, (error, index) => {
          return (_openBlock(), _createElementBlock("span", {
            key: error.$uid
          }, [
            _createElementVNode("small", _hoisted_2, _toDisplayString(error.$message), 1 /* TEXT */),
            ((index+1)<_ctx.vObject.vmodel.$errors?.length)
              ? (_openBlock(), _createElementBlock("br", _hoisted_3))
              : _createCommentVNode("v-if", true)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ]))
    : _createCommentVNode("v-if", true)
}