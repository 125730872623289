import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = ["src", "width", "height", "frameborder", "scrolling", "allowfullscreen"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.getPropertyBooleanValue(_ctx.IframeTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (!_ctx.encrypted)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Inicalizando entorno por favor espere..... "))
      : (_ctx.loaded && _ctx.encrypted && _ctx.getPropertyBooleanValue(_ctx.IframeTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
        ? (_openBlock(), _createElementBlock("iframe", {
            key: _ctx.componentKey,
            src: _ctx.url,
            width: _ctx.getPropertyNumberValue(_ctx.IframeTypeConst.WIDTH),
            height: _ctx.getPropertyNumberValue(_ctx.IframeTypeConst.HEIGHT),
            frameborder: _ctx.getPropertyNumberValue(_ctx.IframeTypeConst.FRAMEBORDER),
            scrolling: _ctx.getPropertyValue(_ctx.IframeTypeConst.SCROLLING),
            allowfullscreen: _ctx.getPropertyBooleanValue(_ctx.IframeTypeConst.ALLOWFULLSCREEN),
            onload: "javascript:(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+\"px\";}(this));",
            style: _normalizeStyle(_ctx.getPropertyValue(_ctx.IframeTypeConst.STYLE))
          }, null, 12 /* STYLE, PROPS */, _hoisted_2))
        : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}