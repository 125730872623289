import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import { ICacheRepository } from '../domain/ICacheRepository';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';
import { REFRESH } from './mutation/cacheMutation';


@injectable()
export class CacheApolloClientRepository implements ICacheRepository {
  private apolloClient : MyApolloClient
  private clientId : String
  constructor (apolloInstance: MyApolloClient, _clientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = _clientId
  }
    
  async refresh (applicationId: number, applicationVersion: number, typeId: string, id: string): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(REFRESH,{applicationId: applicationId,applicationVersion:applicationVersion,typeId:typeId,id:id}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.cacheMutation.updateCache)
        }
        catch(e) {
          reject()
        }
      });

    });
  }
  
}


