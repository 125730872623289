import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IImportConfigRepository } from '../domain/IImportConfigRepository';
import { IServiceImportConfig } from './IServiceImportConfig';
import { IapmImportConfig } from "../domain/iapmImportConfig";


@injectable()
export class ServiceImportConfig implements IServiceImportConfig {
  private readonly repo: IImportConfigRepository

  public constructor (@inject(TYPES.IMPORT_REPOSITORY) _repo: IImportConfigRepository) {
    this.repo = _repo
  }
   

  async update (data: IapmImportConfig): (Promise<IapmImportConfig>) {
    return await this.repo.update(data);
  }

  async add (data: IapmImportConfig): (Promise<IapmImportConfig>) {
    return await this.repo.add(data);
  }

  async delete (Id: number):Promise<boolean> {
    return await this.repo.delete(Id)
  }


  async getAll () :Promise<IapmImportConfig[]> {
    return await this.repo.getAll()
  }

  async getById (Id: number) :Promise<IapmImportConfig[]> {
    return await this.repo.getById(Id)
  }

  async  getByFieldKey (fieldKey: string) :Promise<IapmImportConfig[]> {
    return await this.repo.getByFieldKey(fieldKey)
  }

}
